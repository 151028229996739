import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import { FAQCategoryService } from '@app/modules/faq-categories/services/faq-category.service';
import * as FAQActions from '@app/modules/faq/actions/faq.actions';
import * as FAQ from '@app/modules/faq/reducers';
import { FAQService } from '@app/modules/faq/services/faq.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, switchMap, take, tap } from 'rxjs';

@Injectable()
export class FAQEffects {
  constructor(
    private actions$: Actions,
    private faqService: FAQService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<FAQ.State>,
    private faqCategoryService: FAQCategoryService
  ) {}

  /**
   * Get effects
   */
  loadFAQs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(FAQ.selectFAQOverviewPagination),
        this.store.select(FAQ.selectFAQOverviewSorting),
      ]),
      switchMap(([, pagination, sorting]) =>
        this.store.select(FAQ.selectFAQOverviewFAQCategoryId).pipe(
          take(1),
          map((categoryId) => {
            return { pagination, sorting, categoryId };
          })
        )
      ),
      exhaustMap(({ pagination, sorting, categoryId }) => {
        return this.faqService
          .getAll(sorting, pagination, categoryId ?? 0)
          .pipe(
            map((response) => FAQActions.GetOverviewSuccess(response)),
            catchError((response: { error: ErrorResponse }) =>
              of(FAQActions.GetOverviewError({ response: response.error }))
            )
          );
      })
    )
  );

  loadFAQsFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.FilterOverview),
      map(() => FAQActions.GetOverview())
    )
  );

  loadFAQ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.Get),
      exhaustMap((action) =>
        this.faqService.get(action.id).pipe(
          map((response) => FAQActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(FAQActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  loadFAQCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.GetFAQCategories),
      exhaustMap(() =>
        this.faqCategoryService.getAll().pipe(
          map((faqCategories) =>
            FAQActions.GetFAQCategoriesSuccess({ faqCategories })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(FAQActions.GetFAQCategoriesError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createFAQ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.Create),
      exhaustMap((action) =>
        this.faqService.create(action).pipe(
          map((faq) => FAQActions.CreateSuccess(faq)),
          catchError((response: { error: ErrorResponse }) =>
            of(FAQActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createFAQSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FAQActions.CreateSuccess),
        tap((faq) =>
          this.translate
            .get('notification.add', { item: faq.question })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateFAQ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.Update),
      exhaustMap((action) =>
        this.faqService.update(action).pipe(
          map((faq) => FAQActions.UpdateSuccess(faq)),
          catchError((response: { error: ErrorResponse }) =>
            of(FAQActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateFAQSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FAQActions.UpdateSuccess),
        tap((faq) =>
          this.translate
            .get('notification.save', { item: faq.question })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateFAQSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FAQActions.CreateSuccess, FAQActions.UpdateSuccess),
        tap(() => this.router.navigate(['faq']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteFAQ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.Delete),
      exhaustMap((action) =>
        this.faqService.delete(action.id).pipe(
          map(() => FAQActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(FAQActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteFAQSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.DeleteSuccess),
      map(() => FAQActions.GetOverview())
    )
  );

  deleteFAQSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FAQActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change sequence effects
   */
  changeSequenceFAQ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.ChangeSequence),
      exhaustMap((action) =>
        this.faqService.changeSequence(action).pipe(
          map(() => FAQActions.ChangeSequenceSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(FAQActions.ChangeSequenceError({ response: response.error }))
          )
        )
      )
    )
  );

  changeSequenceSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.ChangeSequenceSuccess),
      map(() => FAQActions.GetOverview())
    )
  );

  /**
   * Update online effects
   */
  updateOnlineFAQ$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.UpdateOnline),
      exhaustMap((action) =>
        this.faqService.online(action.id, action.online).pipe(
          map((faq) => FAQActions.UpdateOnlineSuccess(faq)),
          catchError((response: { error: ErrorResponse }) =>
            of(FAQActions.UpdateOnlineError({ response: response.error }))
          )
        )
      )
    )
  );

  updateOnlineFAQSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FAQActions.UpdateOnlineSuccess),
      map(() => FAQActions.GetOverview())
    )
  );

  updateOnlineFAQSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FAQActions.UpdateOnlineSuccess),
        tap((faq) =>
          this.translate
            .get('notification.save', { item: faq.question })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );
  faqErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          FAQActions.GetOverviewError,
          FAQActions.ChangeSequenceError,
          FAQActions.DeleteError,
          FAQActions.GetFAQCategoriesError,
          FAQActions.ChangeSequenceError,
          FAQActions.UpdateOnlineError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
