import { ErrorResponse } from "@app/core/models/error-response.model";
import { CountryTableItemModel, CountryUpdateModel, CountryChangeSequenceModel, CountryCreateModel } from "@app/modules/countries/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[Country] GetOverview");
export const GetOverviewError = createAction("[Country] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Country] GetOverviewSuccess", props<TableDataModel<CountryTableItemModel>>());
export const ResetOverview = createAction("[Country] ResetOverview");

export const FilterOverview = createAction("[Country] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[Country] Clear");

export const Get = createAction("[Country] Get", props<{ id: number }>());
export const GetError = createAction("[Country] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Country] GetSuccess", props<CountryUpdateModel>());

export const Update = createAction("[Country] Update", props<CountryUpdateModel>());
export const UpdateError = createAction("[Country] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Country] UpdateSuccess", props<CountryUpdateModel>());

export const ChangeSequence = createAction("[Country] ChangeSequence", props<CountryChangeSequenceModel>());
export const ChangeSequenceError = createAction("[Country] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[Country] ChangeSequenceSuccess");

export const Create = createAction("[Country] Create", props<CountryCreateModel>());
export const CreateError = createAction("[Country] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Country] CreateSuccess", props<CountryUpdateModel>());

export const Delete = createAction("[Country] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Country] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Country] DeleteSuccess");