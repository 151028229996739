import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as RelationViewPage from './relation-view-page.reducer';
import * as RelationDetailPage from './relation-detail-page.reducer';
import * as RelationOverview from './relation-overview.reducer';

export const relationFeatureKey = 'relation';

export interface RelationState {
    relationOverview: RelationOverview.State;
    relationDetail: RelationDetailPage.State;
    relationView: RelationViewPage.State;
}

export interface State {
    relation: RelationState;
}

const map: ActionReducerMap<RelationState, Action> = {
    relationOverview: RelationOverview.reducer,
    relationDetail: RelationDetailPage.reducer,
    relationView: RelationViewPage.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: RelationState, action: Action) => combineReducers(map)(state, action);

const selectRelationState = createFeatureSelector<RelationState>(relationFeatureKey);
export const selectState = createSelector(selectRelationState, (state) => state);

export const selectRelationOverviewState = createSelector(selectState, (state) => state.relationOverview);
export const selectRelationOverviewLoading = createSelector(selectRelationOverviewState, RelationOverview.getLoading);
export const selectRelationOverviewList = createSelector(selectRelationOverviewState, RelationOverview.getList);
export const selectRelationOverviewPagination = createSelector(selectRelationOverviewState,RelationOverview.getPagination);
export const selectRelationOverviewSorting = createSelector(selectRelationOverviewState, RelationOverview.getSorting);

export const selectRelationDetailState = createSelector(selectState, state => state.relationDetail);
export const selectRelationViewState = createSelector(selectState, state => state.relationDetail);

export const selectDetailLoading = createSelector(selectRelationDetailState, RelationDetailPage.getLoading);
export const selectDetailRelation = createSelector(selectRelationDetailState, RelationDetailPage.getRelation);
export const selectDetailErrors = createSelector(selectRelationDetailState, RelationDetailPage.getErrors);
export const selectDetailRelationTypes = createSelector(selectRelationDetailState, RelationDetailPage.getRelationTypes);
export const selectDetailCountries = createSelector(selectRelationDetailState, RelationDetailPage.getCountries);

export const selectViewLoading = createSelector(selectRelationViewState, RelationViewPage.getLoading)
export const selectViewRelation = createSelector(selectRelationViewState, RelationViewPage.getRelation)
export const selectViewErrors = createSelector(selectRelationViewState, RelationViewPage.getErrors)

export const selectDuplicatedRelation = createSelector(selectRelationDetailState, RelationDetailPage.getDuplicatedRelation);
export const selectRelationId = createSelector(selectRelationViewState, RelationViewPage.getRelationId);
