<nz-card nzTitle="{{ 'activity.sidebar.toolbox.title' | translate }}" class="w-100 text-color overflow-hidden">
    <p>
        <button
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Relation,
                    claimValues: [ClaimValues.Update]
                }
            ]"
            nzType="link"
            (click)="resendWelcomeMail.emit()"
            nz-button>
            <i class="fa-regular fa-envelope"></i>
            {{ 'activity.sidebar.toolbox.send-welcome-mail' | translate }}
        </button>
    </p>
    <p>
        <button
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Relation,
                    claimValues: [ClaimValues.Read]
                }
            ]"
            nzType="link"
            (click)="loginAsRelation.emit()"
            nz-button>
            <i class="fa-solid fa-arrow-right-to-bracket"></i>
            {{ 'activity.sidebar.toolbox.login-as-relation' | translate }}
        </button>
    </p>
    <p>
        <button
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Relation,
                    claimValues: [ClaimValues.Delete]
                }
            ]"
            nzType="link"
            [nzDanger]="true"
            nz-button
            nzShape="round"
            nz-popconfirm
            nzOkDanger
            [nzPopconfirmTitle]="'notification.delete' | translate"
            [nzOkText]="'common.delete' | translate"
            [nzCancelText]="'common.cancel' | translate"
            [nzIcon]="templateIconDelete"
            nzPopconfirmPlacement="left"
            (nzOnConfirm)="delete.emit()">
            <i class="fa-regular fa-trash-can"></i>
            {{ 'activity.sidebar.toolbox.delete-relation' | translate }}
        </button>
    </p>
</nz-card>

<ng-template #templateIconDelete>
    <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
