import { NgModule } from '@angular/core';
import { ActivityScheduleRoutingModule } from '@app/mobile/modules/activity-schedule/activity-schedule-routing.module';
import { ActivityScheduleDetailFormComponent } from '@app/mobile/modules/activity-schedule/components/activity-schedule-detail-form/activity-schedule-detail-form.component';
import { ActivityScheduleOverviewListComponent } from '@app/mobile/modules/activity-schedule/components/activity-schedule-overview-list/activity-schedule-overview-list.component';
import { ActivityScheduleEffects } from '@app/mobile/modules/activity-schedule/effects/activity-schedule.effects';
import { ActivityScheduleDetailPageComponent } from '@app/mobile/modules/activity-schedule/pages/detail/activity-schedule-detail-page.component';
import { ActivityScheduleOverviewPageComponent } from '@app/mobile/modules/activity-schedule/pages/overview/activity-schedule-overview-page.component';
import {
  activityScheduleFeatureKey,
  reducers,
} from '@app/mobile/modules/activity-schedule/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

const COMPONENTS = [
  ActivityScheduleOverviewPageComponent,
  ActivityScheduleOverviewListComponent,
  ActivityScheduleDetailPageComponent,
  ActivityScheduleDetailFormComponent,
];

const IMPORTS = [
  SharedModule,
  ActivityScheduleRoutingModule,
  StoreModule.forFeature(activityScheduleFeatureKey, reducers),
  EffectsModule.forFeature([ActivityScheduleEffects]),
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [IMPORTS],
  providers: [],
})
export class ActivityScheduleModule {}
