import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { userRoleClearGuard, userRoleExistsGuard } from '@app/modules/user-roles/guards';
import { UserRoleDetailComponent } from '@app/modules/user-roles/pages/detail/user-role-detail.component';
import { UserRoleOverviewComponent } from '@app/modules/user-roles/pages/overview/user-role-overview.component';

const routes: Routes = [
	{ path: '', redirectTo: 'overzicht', pathMatch: 'full' },
	{ path: 'overzicht', component: UserRoleOverviewComponent },
	{ path: 'toevoegen', component: UserRoleDetailComponent, canActivate: [userRoleClearGuard] },
	{ path: 'bewerken/:id', component: UserRoleDetailComponent, canActivate: [userRoleExistsGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class UserRoleRoutingModule {}
