<div>
    <nz-card>
        <div *ngIf="relation.id">
            <strong>{{"relation.card.relation-id" | translate}}:</strong>
            {{ relation.id }}
        </div>
        <br>
        <div *ngIf="relation.salutation || relation.firstName || relation.lastName" style="display: flex;">
            <strong>{{"relation.card.name" | translate}}:&nbsp;</strong>
            <div *ngIf="relation.salutation">
                {{ ('common.enums.salutation.' + relation.salutation | translate) }}&nbsp;
            </div>
            {{ relation.firstName }}&nbsp;
            <div *ngIf="relation.middleName">
                {{ relation.middleName }}&nbsp;
            </div>
            {{ relation.lastName }}
        </div>
        <div *ngIf="relation.address">
            <strong>{{"relation.card.address" | translate }}:</strong>
            {{ relation.address }}
        </div>
        <div *ngIf="relation.phone1">
            <strong>{{ "relation.card.phone-number-one" | translate }}:</strong>
            +{{ relation.phone1CountryCode + relation.phone1 }}
        </div>
        <div *ngIf="relation.phone2">
            <strong>{{ "relation.card.phone-number-two" | translate }}:</strong>
            +{{ relation.phone2CountryCode + relation.phone2 }}
        </div>
        <div *ngIf="relation.email">
            <strong>{{ "relation.card.email" | translate }}:</strong>
            <a [href]="'mailto:' + relation.email">
                {{ relation.email }}
            </a>
        </div>
    </nz-card>
</div>
