import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivityRelationCreateModalComponent } from '@app/modules/activity-relation/components/activity-relation-create-modal/activity-relation-create-modal.component';
import { ApplicationType } from '@app/modules/activity-relation/enum/application-type.enum';
import { ActivityRelationCreateModel } from '@app/modules/activity-relation/models/activity-relation-create.model';
import { ActivityRelationTableItemModel } from '@app/modules/activity-relation/models/activity-relation-table.model';
import { ActivityRelationUpdateModel } from '@app/modules/activity-relation/models/activity-relation-update.model';
import { ActivityAvailableParticipantsLabels } from '@app/modules/activity/enums/activity-available-participants';
import { ParticipantStatus, ParticipantStatusLabels } from '@app/modules/activity/enums/participant-status.enum';
import { PaymentStatus, PaymentStatusIcons } from '@app/modules/activity/enums/payment-status.enum';
import { ActivityUpdateModel } from '@app/modules/activity/models/activity-update.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-activity-relation-overview',
    templateUrl: './activity-relation-overview.component.html',
    styleUrls: ['./activity-relation-overview.component.less'],
})
export class ActivityRelationTableComponent implements OnChanges {
    @Input({ required: true }) data: TableStateModel<ActivityRelationTableItemModel>;
    @Input({ required: true }) dataWaitlist: TableStateModel<ActivityRelationTableItemModel>;
    @Input({ required: true }) backgroundList: KeyValueModel[];
    @Input({ required: true }) activity: ActivityUpdateModel;

    @Input({ required: true }) relations$: Observable<KeyValueModel[]>;
    @Input({ required: true }) activityRelation: ActivityRelationUpdateModel;

    @Output() addReservation = new EventEmitter<void>();
    @Output() delete = new EventEmitter<{ id: number; isReservation: boolean }>();

    @Output() search = new EventEmitter<{ searchQuery: string; skipCount: number }>();
    @Output() create = new EventEmitter<ActivityRelationCreateModel | ActivityRelationUpdateModel>();

    @Output() getRelation = new EventEmitter<number>();
    @Output() resetOverview = new EventEmitter<void>();
    destroyRef = inject(DestroyRef);

    constructor(private readonly modalService: NzModalService) {}

    participantStatusMapping = ParticipantStatusLabels;
    participantActivityStatus = ParticipantStatus;

    paymentStatusMapping = PaymentStatusIcons;
    paymentActivityStatus = PaymentStatus;

    applicationType = ApplicationType;
    ActivityAvailableParticipantsMapping = ActivityAvailableParticipantsLabels;

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    draggingEnabled = false;
    nubmers: number[] = [];

    editModal(id: number) {
        this.getRelation.emit(id);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (Object.keys(changes).includes('activityRelation') && !changes['activityRelation'].firstChange) {
            this.createActivityRelation(this.activityRelation);
        }
    }

    createActivityRelation(activityRelationData?: ActivityRelationUpdateModel) {
        const modal = this.modalService.create({
            nzContent: ActivityRelationCreateModalComponent,
            nzWidth: '60%',
            nzMaskClosable: false,
            nzData: {
                backgroundList: this.backgroundList,
                relations$: this.relations$,
                searchEmitter: this.search,
                createEmitter: this.create,
                startDate: this.activity.startDate,
                endDate: this.activity.endDate,
                activityId: this.activity.id,
                activityRelation: activityRelationData,
            },
        });
        modal.afterClose.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.resetOverview.emit());
    }
}
