import { Component, OnDestroy, OnInit } from '@angular/core';
import * as UserRoleApiActions from '@app/modules/user-roles/actions/user-role-api.actions';
import { UserRoleCreateModel, UserRoleUpdateModel } from '@app/modules/user-roles/models';
import * as UserRole from '@app/modules/user-roles/reducers';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { Subscription, take } from 'rxjs';

@Component({
	selector: 'app-user-role-detail',
	templateUrl: './user-role-detail.component.html',
	styleUrls: ['./user-role-detail.component.less'],
})
export class UserRoleDetailComponent implements OnInit, OnDestroy {
	loading$ = this.store.select(UserRole.selectDetailLoading);
	errors$ = this.store.select(UserRole.selectDetailErrors);
	userRole$ = this.store.select(UserRole.selectDetailUserRole);

	subscriptions = new Subscription();

	constructor(private readonly store: Store<State>) {}

	ngOnInit(): void {
		this.subscriptions.add(this.userRole$.pipe(take(1)).subscribe((userRole) => {
			if (!userRole) {
				this.store.dispatch(UserRoleApiActions.GetRoleClaims());
			}
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	submit(userRole: UserRoleCreateModel | UserRoleUpdateModel) {
		this.store.dispatch(
			userRole.hasOwnProperty('id')
				? UserRoleApiActions.Update(userRole as UserRoleUpdateModel)
				: UserRoleApiActions.Create(userRole)
		);
	}
}
