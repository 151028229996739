import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as RedirectActions from '@app/modules/redirects/actions/redirect-api.actions';
import { RedirectUpdateModel } from '@app/modules/redirects/models';
import * as Redirect from '@app/modules/redirects/reducers';
import { RedirectService } from '@app/modules/redirects/services/redirect.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class RedirectEffects {
  constructor(
    private actions$: Actions,
    private redirectService: RedirectService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<Redirect.State>
  ) {}

  /**
   * Get effects
   */
  loadRedirects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(Redirect.selectRedirectOverviewPagination),
        this.store.select(Redirect.selectRedirectOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.redirectService.getAll(sorting, pagination).pipe(
          map((response) => RedirectActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(RedirectActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadRedirectsFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectActions.FilterOverview),
      map(() => RedirectActions.GetOverview())
    )
  );

  loadRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectActions.Get),
      exhaustMap((action) =>
        this.redirectService.get(action.id).pipe(
          map((response) => RedirectActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(RedirectActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectActions.Create),
      exhaustMap((action) =>
        this.redirectService.create(action).pipe(
          map((redirect) => RedirectActions.CreateSuccess(redirect)),
          catchError((response: { error: ErrorResponse }) =>
            of(RedirectActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createRedirectSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RedirectActions.CreateSuccess),
        tap(() =>
          this.translate
            .get('redirects.notification.add')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectActions.Update),
      exhaustMap((action) =>
        this.redirectService.update(action).pipe(
          map((redirect) => RedirectActions.UpdateSuccess(redirect)),
          catchError((response: { error: ErrorResponse }) =>
            of(RedirectActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateRedirectSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RedirectActions.UpdateSuccess),
        tap(() =>
          this.translate
            .get('redirects.notification.save')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateRedirectSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RedirectActions.CreateSuccess, RedirectActions.UpdateSuccess),
        tap(() => this.router.navigate(['redirects']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectActions.Delete),
      exhaustMap((action) =>
        this.redirectService.delete(action.id).pipe(
          map(() => RedirectActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(RedirectActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteRedirectSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectActions.DeleteSuccess),
      map(() => RedirectActions.GetOverview())
    )
  );

  deleteRedirectSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RedirectActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  redirectErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RedirectActions.DeleteError, RedirectActions.GetOverviewError),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );

  slugExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RedirectActions.SlugExists),
      switchMap((action) => {
        return this.redirectService.slugExists(action.from).pipe(
          map((response) => {
            if (response.slugExists) {
              return RedirectActions.SlugExistsSuccess({
                response: response.slugExists,
              });
            }
            return action.hasOwnProperty('id')
              ? RedirectActions.Update(action as RedirectUpdateModel)
              : RedirectActions.Create(action);
          }),
          catchError((response: { error: ErrorResponse }) =>
            of(RedirectActions.SlugExistsError({ response: response.error }))
          )
        );
      })
    )
  );
}
