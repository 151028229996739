import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.less'],
})
export class NotFoundComponent {
  constructor(private readonly location: Location) {}

  navigateBack(): void {
    this.location.back();
  }
}
