import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as ActivityTemplateActions from '@app/modules/activity-template/actions/activity-template-api.actions';
import * as ActivityTemplate from '@app/modules/activity-template/reducers';
import { ActivityTemplateService } from '@app/modules/activity-template/services/activity-template.service';
import { ActivityTypeService } from '@app/modules/activity-type/services/activity-type.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, exhaustMap, catchError, of, tap } from 'rxjs';

@Injectable()
export class ActivityTemplateEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly activityTemplateService: ActivityTemplateService,
        private readonly activityTypeService: ActivityTypeService,
        private readonly messageService: NzMessageService,
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly store: Store<ActivityTemplate.State>,
    ) {}

    /**
     * Get effects
     */
    loadActivityTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityTemplateActions.GetOverview),
            concatLatestFrom(() => [
                this.store.select(ActivityTemplate.selectActivityTemplateOverviewPagination),
                this.store.select(ActivityTemplate.selectActivityTemplateOverviewSorting),
                this.store.select(ActivityTemplate.selectActivityTemplateActivityTypeId),
            ]),
            exhaustMap(([, pagination, sorting, activityTypeId]) =>
                this.activityTemplateService.getOverview(sorting, pagination, activityTypeId).pipe(
                    map((response) => ActivityTemplateActions.GetOverviewSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityTemplateActions.GetOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadActivityTemplatesFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityTemplateActions.FilterOverview),
            map(() => ActivityTemplateActions.GetOverview()),
        ),
    );

    loadActivityTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityTemplateActions.Get),
            exhaustMap((action) =>
                this.activityTemplateService.get(action.id).pipe(
                    map((response) => ActivityTemplateActions.GetSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityTemplateActions.GetError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create effects
     */
    createActivityTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityTemplateActions.Create),
            exhaustMap((action) =>
                this.activityTemplateService.create(action).pipe(
                    map((activityTemplate) => ActivityTemplateActions.CreateSuccess(activityTemplate)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityTemplateActions.CreateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    createActivityTemplateSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityTemplateActions.CreateSuccess),
                tap((activityTemplate) =>
                    this.translate
                        .get('notification.add', { item: activityTemplate.name })
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    /**
     * Update effects
     */
    updateActivityTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityTemplateActions.Update),
            exhaustMap((action) =>
                this.activityTemplateService.update(action).pipe(
                    map((activityTemplate) => ActivityTemplateActions.UpdateSuccess(activityTemplate)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityTemplateActions.UpdateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    updateActivityTemplateSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityTemplateActions.UpdateSuccess),
                tap((activityTemplate) =>
                    this.translate
                        .get('notification.save', { item: activityTemplate.name })
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    /**
     * Create update redirects & store clear
     */
    createUpdateActivityTemplateSuccessRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityTemplateActions.CreateSuccess, ActivityTemplateActions.UpdateSuccess),
                tap(() => this.router.navigate(['activiteit-templates'])),
            ),
        { dispatch: false },
    );

    /**
     * Delete effects
     */
    deleteActivityTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityTemplateActions.Delete),
            exhaustMap((action) =>
                this.activityTemplateService.delete(action.id).pipe(
                    map(() => ActivityTemplateActions.DeleteSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityTemplateActions.DeleteError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    deleteActivityTemplateSuccessReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityTemplateActions.DeleteSuccess),
            map(() => ActivityTemplateActions.GetOverview()),
        ),
    );

    deleteActivityTemplateSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityTemplateActions.DeleteSuccess),
                tap(() =>
                    this.translate
                        .get('notification.delete-success')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    activityTemplateErrorToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityTemplateActions.DeleteError, ActivityTemplateActions.GetOverviewError),
                tap((error) => this.messageService.error(BuildErrorString(error.response, this.translate))),
            ),
        { dispatch: false },
    );

    /** *
     * Get activity types
     */
    loadActivityTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityTemplateActions.GetActivityTypes),
            exhaustMap(() =>
                this.activityTypeService.getAll().pipe(
                    map((activityTypes) => ActivityTemplateActions.GetActivityTypesSuccess({ activityTypes })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityTemplateActions.GetActivityTypesError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );
}
