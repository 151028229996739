<div class="page-header">
	<h1 class="page-header__title">{{ 'countries.title' | translate }}</h1>
	<div class="page-header__actions">
		<a
			claimDirective
			[claims]="[
				{
					claimType: ClaimTypes.Country,
					claimValues: [ClaimValues.Create]
				}
			]"
			routerLink="/landen/toevoegen"
			nz-button
			nzType="primary">
			{{ 'common.add' | translate }}
			<i class="fa-regular fa-plus"></i>
		</a>
	</div>
</div>

<app-country-overview-table
	[data]="(tableData$ | async)!"
	(delete)="onDeleteConfirm($event)"
	(onQueryParamsChange)="onQueryParamsChange($event)"
	(changeSequence)="onSequenceChange($event)"
	(onResetOverview)="onResetOverview()" />
