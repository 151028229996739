import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'enumKeyValue'})
export class EnumKeyValuePipe implements PipeTransform {
  transform(obj: object) {
    return Object.entries(obj).
      filter(([key, value]) =>
        !/^\d+$/.test(key) || 
        !obj.hasOwnProperty(value)).
      map(([key, value]) => ({key, value}));
  }
}