import { Component } from '@angular/core';
import * as RedirectApiActions from '@app/modules/redirects/actions/redirect-api.actions';
import {
  RedirectCreateModel,
  RedirectUpdateModel,
} from '@app/modules/redirects/models';
import * as Redirect from '@app/modules/redirects/reducers';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-redirect-detail',
  templateUrl: './redirect-detail.component.html',
  styleUrls: ['./redirect-detail.component.less'],
})
export class RedirectDetailComponent {
  loading$ = this.store.select(Redirect.selectDetailLoading);
  errors$ = this.store.select(Redirect.selectDetailErrors);
  redirect$ = this.store.select(Redirect.selectDetailRedirect);
  slugExists$ = this.store.select(Redirect.selectDetailSlugExists);

  constructor(private readonly store: Store<State>) {}

  submit(redirect: RedirectCreateModel | RedirectUpdateModel) {
    this.store.dispatch(RedirectApiActions.SlugExists(redirect));
  }

  modalSubmit(redirect: RedirectCreateModel | RedirectUpdateModel) {
    this.store.dispatch(
      redirect.hasOwnProperty('id')
        ? RedirectApiActions.Update(redirect as RedirectUpdateModel)
        : RedirectApiActions.Create(redirect as RedirectCreateModel)
    );
  }
  resetSlugExists(): void {
    this.store.dispatch(RedirectApiActions.ResetSlugExists());
  }
}
