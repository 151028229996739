import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivityTemplateTableItemModel } from '@app/modules/activity-template/models/activity-template-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
    selector: 'app-activity-template-overview-table',
    templateUrl: './activity-template-overview-table.component.html',
})
export class ActivityTemplateOverviewTableComponent {
    @Input({ required: true }) data!: TableStateModel<ActivityTemplateTableItemModel>;

    @Output() delete = new EventEmitter<number>();
    @Output() onQueryParamsChange = new EventEmitter<any>();
    @Output() onResetOverview = new EventEmitter<void>();

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    draggingEnabled = false;
}
