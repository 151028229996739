
import * as UserRoleActions from "@app/modules/user-roles/actions/user-role-api.actions";
import { UserRoleUpdateModel } from "@app/modules/user-roles/models";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    userRole: null,
    loading: false,
    errors: {}
}

export interface State {
    userRole: UserRoleUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(UserRoleActions.Clear, () => ({ ...initialState })),

    on(UserRoleActions.GetRoleClaimsSuccess, (state, userRole) => ({ ...state, userRole })),

    on(UserRoleActions.Get, (state) => ({ ...state, loading: true })),
    on(UserRoleActions.GetSuccess, (state, userRole) => ({ ...state, userRole, loading: false })),
    on(UserRoleActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(UserRoleActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(UserRoleActions.UpdateSuccess, (state, userRole) => ({ ...state, userRole, loading: false })),
    on(UserRoleActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(UserRoleActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(UserRoleActions.CreateSuccess, (state, userRole) => ({ ...state, userRole, loading: false })),
    on(UserRoleActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getUserRole = (state: State) => state.userRole;