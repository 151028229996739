import { CdkDropList } from '@angular/cdk/drag-drop';
import { AfterViewInit, Directive, Inject, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { EditorComponent } from '@tinymce/tinymce-angular';

import { CDK_DROP_LIST } from '@angular/cdk/drag-drop';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
	selector: 'editor[draggableEditor]',
})
export class EditorDraggableDirective implements AfterViewInit, OnDestroy {
	@Input({ required: true }) control!: AbstractControl<string | null>;
	@Input({required: true}) isDraggable!: boolean;

	private subscriptions = new Subscription();
	private id: string = '';

	constructor(
		@Inject(CDK_DROP_LIST)
		@Optional()
		@SkipSelf()
		private _cdkDropList: CdkDropList,
		private _editor: EditorComponent
	) {}

	ngAfterViewInit() {
		if(!this.isDraggable) { 
			return;
		}

		this.id = this._editor.id;

		this.subscriptions.add(
			this._cdkDropList.dropped.subscribe(() => {
				setTimeout(() => {
					const newValue = this.control.value!;
					const newWindow = window as any;
					const tinymce = newWindow['tinymce'];
					const editor = tinymce.get(this.id);
					if (editor == null) {
						return;
					}
					editor.remove();
					tinymce.remove(this._editor.id);
					this._editor.initialValue = newValue;
					this._editor.initialise();
					const domEditor = tinymce.get(this.id);
					if (domEditor) {
						domEditor.fire('change');
					}
				});
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
