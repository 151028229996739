<nz-table
	#overviewTable
	[nzData]="data.list"
	[nzPageIndex]="data.pagination.page"
	[nzPageSize]="data.pagination.pageSize"
	[nzTotal]="data.pagination.totalItems ?? 0"
	[nzLoading]="data.loading"
	[nzFrontPagination]="false"
	(nzQueryParams)="onQueryParamsChange.emit($event)"
	nzTableLayout="fixed">
	<thead>
		<tr>
			<th
				nzWidth="60px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.BackOfficeUser,
						claimValues: [ClaimValues.Update]
					}
				]">
				{{ 'users.fields.active' | translate }}
			</th>
			<th nzColumnKey="email" [nzSortFn]="true" [nzSortOrder]="'ascend'">
				{{ 'users.fields.email-username' | translate }}
			</th>
			<th nzColumnKey="fullName" [nzSortFn]="true">
				{{ 'users.fields.fullname' | translate }}
			</th>
			<th>
				{{ 'users.fields.user-roles' | translate }}
			</th>
			<th>
				{{ 'users.fields.type' | translate }}
			</th>
			<th
				nzWidth="120px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.BackOfficeUser,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of overviewTable.data">
			<td
				nzAlign="center"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.BackOfficeUser,
						claimValues: [ClaimValues.Update]
					}
				]">
				<label
					*ngIf="row.id !== currentUser?.id"
					nz-checkbox
					[nzChecked]="row?.active"
					(nzCheckedChange)="changeActive(row.id, !row.active)"></label>
			</td>
			<td>{{ row.email | truncateText }}</td>
			<td>{{ row.fullName | truncateText }}</td>
			<td>
				<div class="tags-group">
					<nz-tag *ngFor="let row of row.roles">{{ row | truncateText }}</nz-tag>
				</div>
			</td>
			<td>
				<div class="tags-group">
					<nz-tag *ngIf="row.isTrainer">
						{{ 'users.enums.userType.' + UserType[UserType.Trainer] | translate }}
					</nz-tag>
					<nz-tag *ngIf="row.isEmployee">
						{{ 'users.enums.userType.' + UserType[UserType.Employee] | translate }}
					</nz-tag>
				</div>
			</td>
			<td
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.BackOfficeUser,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]">
				<div class="table-actions">
					<a
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.BackOfficeUser,
								claimValues: [ClaimValues.Read]
							}
						]"
						[routerLink]="['/gebruikers/bewerken', row.id]"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-pen"></i>
					</a>
					<button
						[disabled]="row.id === currentUser?.id"
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.BackOfficeUser,
								claimValues: [ClaimValues.Delete]
							}
						]"
						nz-button
						nzDanger
						nzType="text"
						nzShape="circle"
						nz-popconfirm
						nzOkDanger
						[nzPopconfirmTitle]="'notification.delete' | translate"
						[nzOkText]="'common.delete' | translate"
						[nzCancelText]="'common.cancel' | translate"
						[nzIcon]="templateIconDelete"
						nzPopconfirmPlacement="left"
						(nzOnConfirm)="delete.emit(row.id)">
						<i class="fa-regular fa-trash-can"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</nz-table>

<ng-template #templateIconDelete>
	<i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
