import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { processBackendValidatorsErrors } from '@app/shared/utilities/validation.utilities';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-edit-note-modal',
    templateUrl: './edit-note-modal.component.html',
})
export class EditNoteModalComponent {
    readonly nzModalData = inject(NZ_MODAL_DATA);
    noteMessage = this.nzModalData.noteMessage;
    editReason = this.nzModalData.editReason;
    errors$: Observable<{ [key: string]: string[] }> = this.nzModalData.errors$;

    errors: { [key: string]: string[] } = {};
    formGroup = new FormGroup({
        noteMessageEdit: new FormControl<string>(this.noteMessage, Validators.maxLength(500)),
        editReason: new FormControl<string>(this.editReason, Validators.maxLength(500)),
    });

    constructor() {
        this.errors$.pipe(takeUntilDestroyed()).subscribe((errors: { [key: string]: string[] }) => {
            this.errors = errors;
            processBackendValidatorsErrors(errors, this.formGroup);
        });
    }
}
