<div nz-row>
    <div nz-col>
        <div class="page-header">
            <button nz-button nzType="primary" (click)="this.createActivityRelation()">
                {{ 'activity-relation.detail.add-participant' | translate }}
                <i class="fa-solid fa-plus"></i>
            </button>
            <div class="page-header__actions">
                <button nz-button nzType="primary" (click)="this.addReservation.emit()">
                    {{ 'activity-relation.detail.add-reservation' | translate }}
                    <i class="fa-solid fa-plus"></i>
                </button>
            </div>
        </div>
        <app-activity-relation-overview-table
            [waitlist]="false"
            [data]="data"
            [activityRelation]="activityRelation"
            [backgroundList]="backgroundList"
            [activity]="activity"
            [relations$]="relations$"
            (delete)="this.delete.emit($event)"
            (search)="this.search.emit($event)"
            (create)="this.create.emit($event)"
            (getRelation)="this.getRelation.emit($event)"
            (resetOverview)="this.resetOverview.emit($event)"></app-activity-relation-overview-table>

        <span>
            <a>
                <i class="fa-regular fa-envelope"></i>
                &nbsp;{{ 'activity.fields.send-mail-participants' | translate }}
            </a>
        </span>
        <br />
        <span>
            <a>
                <i class="fa-solid fa-download"></i>
                &nbsp;{{ 'activity.fields.download-participants' | translate }}
            </a>
        </span>
        <br />
        <br />

        <h5 class="form-section__title">
            {{ 'activity.header.waitlist' | translate }}
        </h5>
        <app-activity-relation-overview-table
            [waitlist]="true"
            [data]="dataWaitlist"
            [activityRelation]="activityRelation"
            [backgroundList]="backgroundList"
            [activity]="activity"
            [relations$]="relations$"
            (delete)="this.delete.emit($event)"
            (search)="this.search.emit($event)"
            (create)="this.create.emit($event)"
            (getRelation)="this.getRelation.emit($event)"
            (resetOverview)="this.resetOverview.emit($event)"></app-activity-relation-overview-table>
        <span>
            <a>
                <i class="fa-regular fa-envelope"></i>
                &nbsp;{{ 'activity.fields.send-mail-waitlist' | translate }}
            </a>
        </span>
        <br />
    </div>
</div>
