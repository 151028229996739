import * as RelationTypeActions from "@app/modules/relation-type/actions/relation-type-api.actions"
import { RelationTypeUpdateModel } from "@app/modules/relation-type/models";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    relationType: null,
    loading: false,
    errors: {}
}

export interface State {
    relationType: RelationTypeUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(RelationTypeActions.Clear, () => ({ ...initialState })),

    on(RelationTypeActions.Get, (state) => ({ ...state, loading: true })),
    on(RelationTypeActions.GetSuccess, (state, relationType) => ({ ...state, relationType, loading: false })),
    on(RelationTypeActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(RelationTypeActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(RelationTypeActions.UpdateSuccess, (state, relationType) => ({ ...state, relationType, loading: false })),
    on(RelationTypeActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(RelationTypeActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(RelationTypeActions.CreateSuccess, (state, relationType) => ({ ...state, relationType, loading: false })),
    on(RelationTypeActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getRelationType = (state: State) => state.relationType;