import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackofficeMobileLayoutComponent } from '@app/core/containers/backoffice-mobile-layout/backoffice-mobile-layout.component';
import { PublicLayoutComponent } from '@app/core/containers/public-layout/public-layout.component';
import { TokenService } from '@app/core/services/token/token.service';
import { ActivityScheduleModule } from '@app/mobile/modules/activity-schedule/activity-schedule.module';
import { MobileAuthModule } from '@app/mobile/modules/auth/mobile-auth.module';
import { ErrorModule } from '@app/modules/error/error.module';

const routes: Routes = [
	{ path: '', redirectTo: 'auth', pathMatch: 'full' },
	{ path: '', component: PublicLayoutComponent, children: [{ path: 'auth', loadChildren: () => MobileAuthModule }] },

	{
		path: '',
		component: BackofficeMobileLayoutComponent,
		canMatch: [() => inject(TokenService).hasToken()],
		loadChildren: () => ErrorModule,
	},
	{ path: '', component: PublicLayoutComponent, loadChildren: () => ErrorModule },

	{
		path: '',
		component: BackofficeMobileLayoutComponent,
		children: [
			{ path: '', redirectTo: 'activiteiten', pathMatch: 'full' },
			{ path: 'activiteiten', loadChildren: () => ActivityScheduleModule },
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MobileRoutingModule {}
