import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import { UserRoleService } from '@app/modules/user-roles/services/user-role.service';
import * as UserActions from '@app/modules/users/actions/user-api.actions';
import * as User from '@app/modules/users/reducers';
import { UserService } from '@app/modules/users/services/user.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, switchMap, take, tap } from 'rxjs';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private userRoleService: UserRoleService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<User.State>
  ) {}

  /**
   * Get effects
   */
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(User.selectUserOverviewPagination),
        this.store.select(User.selectUserOverviewSorting),
        this.store.select(User.selectUserOverviewUserTypes),
      ]),
      exhaustMap(([, pagination, sorting, userTypes]) =>
        this.userService.getAll(sorting, pagination, userTypes).pipe(
          map((response) => UserActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadUsersFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.FilterOverview),
      map(() => UserActions.GetOverview())
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.Get),
      exhaustMap((action) =>
        this.userService.get(action.id).pipe(
          map((response) => UserActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  getUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GetUserRoles),
      exhaustMap(() =>
        this.userRoleService
          .getAll(
            { propertyName: 'name', order: 'asc' },
            { page: 1, pageSize: -1 }
          )
          .pipe(
            map((response) =>
              UserActions.GetUserRolesSuccess({ userRoles: response.list })
            ),
            catchError((response: { error: ErrorResponse }) =>
              of(UserActions.GetError({ response: response.error }))
            )
          )
      )
    )
  );

  downloadSignature$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.DownloadSignature),
      exhaustMap((action) =>
        this.userService.employeeSignature(action).pipe(
          map((response) =>
            UserActions.DownloadSignatureSuccess({
              name: action.name,
              blob: response,
            })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(UserActions.DownloadSignatureError({ response: response.error }))
          )
        )
      )
    )
  );

  downloadSignatureSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.DownloadSignatureSuccess),
        tap((response) => saveAs(response.blob, response.name))
      ),
    { dispatch: false }
  );

  /**
   * Create effects
   */
  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.Create),
      exhaustMap((action) =>
        this.userService.create(action).pipe(
          map((user) => UserActions.CreateSuccess(user)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createUserSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.CreateSuccess),
        tap(() =>
          this.translate
            .get('users.notification.add')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.Update),
      exhaustMap((action) =>
        this.userService.update(action).pipe(
          map((user) => UserActions.UpdateSuccess(user)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateUserSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.UpdateSuccess),
        tap(() =>
          this.translate
            .get('users.notification.save')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update users & store clear
   */
  createUpdateUserSuccessUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.CreateSuccess, UserActions.UpdateSuccess),
        tap(() => this.router.navigate(['gebruikers']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.Delete),
      exhaustMap((action) =>
        this.userService.delete(action.id).pipe(
          map(() => UserActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(UserActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteUserSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.DeleteSuccess),
      map(() => UserActions.GetOverview())
    )
  );

  deleteUserSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * SetActive effects
   */
  setActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UpdateActive),
      exhaustMap((action) =>
        this.userService.active(action.id, action.active).pipe(
          map((user) => UserActions.UpdateActiveSuccess(user)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserActions.UpdateActiveError({ response: response.error }))
          )
        )
      )
    )
  );

  setActiveSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UpdateActiveSuccess),
      map(() => UserActions.GetOverview())
    )
  );

  setActiveSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.UpdateActiveSuccess),
        tap(() =>
          this.translate
            .get('users.notification.save')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * User exists effects
   */
  userExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UserExists),
      switchMap((action) => {
        return this.store.select(User.selectDetailUserExists).pipe(
          take(1),
          map((userExists) => {
            return { action, userExists };
          })
        );
      }),
      exhaustMap(({ action, userExists }) => {
        if (userExists.websiteUserExists) {
          return of(UserActions.Create(action));
        }
        return this.userService.userExists(action.email).pipe(
          map((response) => {
            return response.websiteUserExists && !response.backOfficeUserExists
              ? UserActions.UserExistsSuccess({ response })
              : UserActions.Create(action);
          }),
          catchError((response: { error: ErrorResponse }) =>
            of(UserActions.UserExistsError({ response: response.error }))
          )
        );
      })
    )
  );

  userErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          UserActions.DeleteError,
          UserActions.GetOverviewError,
          UserActions.DownloadSignatureError,
          UserActions.UpdateActiveError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
