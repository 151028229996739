import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { BlogCategoryRoutingModule } from "@app/modules/blog-category/blog-category-routing.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { BlogCategoryOverviewComponent } from "@app/modules/blog-category/pages/overview/blog-category-overview.component";
import { BlogCategoryOverviewTableComponent } from "@app/modules/blog-category/components/blog-category-overview-table/blog-category-overview-table.component";
import { BlogCategoryDetailComponent } from "@app/modules/blog-category/pages/detail/blog-category-detail.component";
import { BlogCategoryDetailFormComponent } from "@app/modules/blog-category/components/blog-category-detail-form/blog-category-detail-form.component";
import { reducers, blogCategoryFeatureKey} from "@app/modules/blog-category/reducers";
import { BlogCategoryEffects } from "@app/modules/blog-category/effects/blog-category.effects";

const COMPONENTS = [
    BlogCategoryOverviewComponent,
    BlogCategoryDetailComponent,
    BlogCategoryOverviewTableComponent,
    BlogCategoryDetailFormComponent
];

const IMPORTS = [
    SharedModule,
    BlogCategoryRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(blogCategoryFeatureKey, reducers),
    EffectsModule.forFeature([BlogCategoryEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class BlogCategoryModule { }
