import {
  Action,
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as TagOverview from './tag-overview.reducer';
import * as TagDetailPage from './tag-detail-page.reducer';

export const tagFeatureKey = 'tag';

export interface TagState {
  tagOverview: TagOverview.State;
  tagDetail: TagDetailPage.State;
}

export interface State {
  tag: TagState;
}

const map: ActionReducerMap<TagState, Action> = {
  tagOverview: TagOverview.reducer,
  tagDetail: TagDetailPage.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: TagState, action: Action) =>
  combineReducers(map)(state, action);

const selectTagState =
  createFeatureSelector<TagState>(tagFeatureKey);

export const selectState = createSelector(
  selectTagState,
  (state) => state
);
export const selectTagOverviewState = createSelector(
  selectState,
  (state) => state.tagOverview
);

export const selectTagOverviewLoading = createSelector(
  selectTagOverviewState,
  TagOverview.getLoading
);
export const selectTagOverviewList = createSelector(
  selectTagOverviewState,
  TagOverview.getList
);
export const selectTagOverviewPagination = createSelector(
  selectTagOverviewState,
  TagOverview.getPagination
);
export const selectTagOverviewSorting = createSelector(
  selectTagOverviewState,
  TagOverview.getSorting
);

export const selectTagDetailState = createSelector(
  selectState,
  (state) => state.tagDetail
);

export const selectDetailLoading = createSelector(
  selectTagDetailState,
  TagDetailPage.getLoading
);
export const selectDetailTag = createSelector(
  selectTagDetailState,
  TagDetailPage.getTag
);
export const selectDetailErrors = createSelector(
  selectTagDetailState,
  TagDetailPage.getErrors
);
