import { ErrorResponse } from "@app/core/models/error-response.model";
import { ActivityLocationTableItemModel, ActivityLocationUpdateModel, ActivityLocationCreateModel } from "@app/modules/activity-location/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[ActivityLocation] GetOverview");
export const GetOverviewError = createAction("[ActivityLocation] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[ActivityLocation] GetOverviewSuccess", props<TableDataModel<ActivityLocationTableItemModel>>());

export const FilterOverview = createAction("[ActivityLocation] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[ActivityLocation] Clear");

export const Get = createAction("[ActivityLocation] Get", props<{ id: number }>());
export const GetError = createAction("[ActivityLocation] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[ActivityLocation] GetSuccess", props<ActivityLocationUpdateModel>());

export const Update = createAction("[ActivityLocation] Update", props<ActivityLocationUpdateModel>());
export const UpdateError = createAction("[ActivityLocation] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[ActivityLocation] UpdateSuccess", props<ActivityLocationUpdateModel>());

export const Create = createAction("[ActivityLocation] Create", props<ActivityLocationCreateModel>());
export const CreateError = createAction("[ActivityLocation] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[ActivityLocation] CreateSuccess", props<ActivityLocationUpdateModel>());

export const Delete = createAction("[ActivityLocation] Delete", props<{ id: number }>());
export const DeleteError = createAction("[ActivityLocation] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[ActivityLocation] DeleteSuccess");