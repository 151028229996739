import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivityTypeUpdateModel, ActivityTypeCreateModel } from '@app/modules/activity-type/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
    selector: 'app-activity-type-detail-form',
    templateUrl: './activity-type-detail-form.component.html',
})
export class ActivityTypeDetailFormComponent implements OnInit, OnChanges {
    update = false;
    readonly?: boolean;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) activityType: ActivityTypeUpdateModel | null = null;

    @Output() submitted = new EventEmitter<ActivityTypeCreateModel | ActivityTypeUpdateModel>();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        name: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        pluralName: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        code: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(10)]),
        hasEvaluationForm: new FormControl<boolean>(false),
        hasTheme: new FormControl<boolean>(false)
    });

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.activityType;

        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.ActivityType, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        this.formGroup.patchValue({
            name: this.activityType?.name,
            pluralName: this.activityType?.pluralName,
            code: this.activityType?.code,
            hasEvaluationForm: this.activityType?.hasEvaluationForm,
            hasTheme: this.activityType?.hasTheme
        });

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.activityType as ActivityTypeUpdateModel).id);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            name: this.formGroup.controls.name.value ?? '',
            pluralName: this.formGroup.controls.pluralName.value ?? '',
            code: this.formGroup.controls.code.value ?? '',
            hasEvaluationForm: this.formGroup.controls.hasEvaluationForm.value ?? false,
            hasTheme: this.formGroup.controls.hasTheme.value ?? false,
        });
    }

    private submitUpdate() {
        this.submitted.emit(this.formGroup.value as ActivityTypeUpdateModel);
    }
}