import { RedirectTableItemModel } from '@app/modules/redirects/models';
import * as RedirectActions from '@app/modules/redirects/actions/redirect-api.actions';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = new TableOverviewModel<RedirectTableItemModel>('id');

export interface State extends TableStateModel<RedirectTableItemModel> {}

export const reducer = createReducer(
	initialState,
	on(RedirectActions.GetOverview, (state) => ({ ...state, loading: true })),
	on(RedirectActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
	on(RedirectActions.GetOverviewError, (state) => ({ ...state, loading: false })),
	on(RedirectActions.FilterOverview, (state, { sorting, pagination }) => ({
		...state,
		sorting: applySortingOrDefault(initialState, sorting),
		pagination: {
			...state.pagination,
			page: pagination.page,
			pageSize: pagination.pageSize,
		},
	}))
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
