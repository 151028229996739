import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivityTableItemModel } from '@app/modules/activities/models/activities-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
    selector: 'app-activities-overview-table',
    templateUrl: './activities-overview-table.component.html',
})
export class ActivityOverviewTableComponent {
    @Input({ required: true }) data!: TableStateModel<ActivityTableItemModel>;

    @Output() delete = new EventEmitter<number>();
    @Output() onQueryParamsChange = new EventEmitter<any>();
    @Output() onResetOverview = new EventEmitter<void>();
    @Output() changeOnline = new EventEmitter<{
        id: number;
        online: boolean;
      }>();

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    draggingEnabled = false;

    onChangeShowOnline(id: number, online: boolean) {
        this.changeOnline.emit({ id, online });
      }
}
