<nz-table
	#overviewTable
	[nzData]="data.list"
	[nzPageIndex]="data.pagination.page"
	[nzPageSize]="data.pagination.pageSize"
	[nzTotal]="data.pagination.totalItems ?? 0"
	[nzLoading]="data.loading"
	[nzFrontPagination]="false"
	[nzShowPagination]="!draggingEnabled"
	(nzQueryParams)="onQueryParamsChange.emit($event)"
	nzTableLayout="fixed">
	<thead>
		<tr>
			<th
				*ngIf="categoryId !== -1 && categoryId !== 0"
				nzColumnKey="sequenceNumber"
				nzWidth="40px"
				nzAlign="center"
				[nzShowSort]="false"
				claimDirective
				[claims]="[{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Update] }]">
				<!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
				<label nz-checkbox [(ngModel)]="draggingEnabled" (ngModelChange)="draggingChanged()"></label>
			</th>
			<th
				nzColumnKey="online"
				nzWidth="80px"
				nzAlign="center"
				claimDirective
				[claims]="[{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Update] }]"
				[nzSortFn]="true"
				[nzSortOrder]="'descend'">
				{{ 'faq.fields.online' | translate }}
			</th>
			<th nzColumnKey="question" [nzSortFn]="true" [nzShowSort]="!draggingEnabled">
				{{ 'faq.fields.question' | translate }}
			</th>
			<th nzColumnKey="clicks" nzWidth="100px" [nzSortFn]="true" [nzShowSort]="!draggingEnabled">
				{{ 'faq.fields.clicks' | translate }}
			</th>
			<th
				nzWidth="120px"
				claimDirective
				[claims]="[{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Read, ClaimValues.Delete] }]"></th>
		</tr>
	</thead>
	<tbody cdkDropList (cdkDropListDropped)="drop($event)">
		<tr *ngFor="let row of overviewTable.data" cdkDrag [cdkDragData]="row" [cdkDragDisabled]="!draggingEnabled">
			<td
				*ngIf="categoryId !== -1 && categoryId !== 0"
				claimDirective
				[claims]="[{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Update] }]"
				nzAlign="center"
				nzWidth="40px">
				<i class="fa-solid fa-grip-dots-vertical" cdkDragHandle></i>
			</td>
			<td claimDirective [claims]="[{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Update] }]" nzAlign="center">
				<!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
				<label nz-checkbox [nzChecked]="row?.online" (nzCheckedChange)="changeOnline(row.id, !row.online)"></label>
			</td>
			<td>{{ row.question | truncateText }}</td>
			<td nzAlign="right">{{ row.clicks }}</td>
			<td
				claimDirective
				[claims]="[{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Read, ClaimValues.Delete] }]">
				<div class="table-actions">
					<a
						claimDirective
						[claims]="[{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Read] }]"
						[routerLink]="['/faq/bewerken', row.id]"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-pen"></i>
					</a>
					<button
						claimDirective
						[claims]="[{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Delete] }]"
						nz-button
						nzDanger
						nzType="text"
						nzShape="circle"
						nz-popconfirm
						nzOkDanger
						[nzPopconfirmTitle]="'notification.delete' | translate"
						[nzOkText]="'common.delete' | translate"
						[nzCancelText]="'common.cancel' | translate"
						[nzIcon]="templateIconDelete"
						nzPopconfirmPlacement="left"
						(nzOnConfirm)="delete.emit(row.id)">
						<i class="fa-regular fa-trash-can"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</nz-table>

<ng-template #templateIconDelete>
	<i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
