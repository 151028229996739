<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>
            {{
                (!!formGroup.get('id')?.value
                    ? 'activity-templates.detail.edit-title'
                    : 'activity-templates.detail.add-title'
                ) | translate
            }}
        </h4>

        <section class="form-section">
            <header>
                <h5 class="form-section__title">
                    {{ 'activity-templates.header.basis' | translate }}
                </h5>
            </header>
            <div nz-row>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="24">
                            {{ 'activity-templates.fields.activity-type' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="activityTypeValidationTemplate">
                            <nz-select
                                class="w-100"
                                nzPlaceHolder="{{ 'activity-templates.fields.activity-type' | translate }}"
                                formControlName="activityTypeId">
                                <nz-option
                                    *ngFor="let activityType of activityTypes"
                                    [nzLabel]="activityType.value"
                                    [nzValue]="activityType.key"></nz-option>
                            </nz-select>
                            <ng-template #activityTypeValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'activityTypeId'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24" nzRequired>
                            {{ 'activity-templates.fields.name' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="nameValidationTemplate">
                            <input nz-input [formControl]="formGroup.controls.name" />

                            <ng-template #nameValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'name'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div nz-row>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-templates.fields.exact-code' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="exactCodeValidationTemplate">
                            <input nz-input [formControl]="formGroup.controls.exactCode" />

                            <ng-template #exactCodeValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'exactCode'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </section>

        <section class="form-section">
            <header>
                <h5 class="form-section__title">
                    {{ 'activity-templates.header.price' | translate }}
                </h5>
            </header>
            <div nz-row>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-templates.fields.price-including-vat' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="priceIncludingVatValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.priceIncludingVat" />

                            <ng-template #priceIncludingVatValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'priceIncludingVat'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24"></nz-form-label>
                        <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                        <label nz-checkbox [nzValue]="activityTemplate?.displayPrices" formControlName="displayPrices">
                            {{ 'activity-templates.fields.display-prices' | translate }}
                        </label>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-templates.fields.price-second-including-vat' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="priceSecondIncludingVatValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.priceSecondIncludingVat" />

                            <ng-template #priceSecondIncludingVatValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'priceSecondIncludingVat'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label nzRequired [nzSpan]="24">
                            {{ 'activity-templates.fields.vat-percentage' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="vatPercentageValidationTemplate">
                            <nz-select
                                class="w-100"
                                nzPlaceHolder="{{ 'activity-templates.fields.vat-percentage' | translate }}"
                                formControlName="vatPercentage">
                                <nz-option [nzValue]="0" nzLabel="0%"></nz-option>
                                <nz-option [nzValue]="9" nzLabel="9%"></nz-option>
                                <nz-option [nzValue]="21" nzLabel="21%"></nz-option>
                            </nz-select>
                            <ng-template #vatPercentageValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'vatPercentage'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </section>

        <section class="form-section">
            <header>
                <h5 class="form-section__title">
                    {{ 'activity-templates.header.extra-information' | translate }}
                </h5>
            </header>
            <div nz-row>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                        <label nz-checkbox [nzValue]="activityTemplate?.includesLunch" formControlName="includesLunch">
                            {{ 'activity-templates.fields.include-lunch' | translate }}
                        </label>
                    </nz-form-item>
                </div>
            </div>
        </section>

        <section class="form-section">
            <header>
                <h5 class="form-section__title">
                    {{ 'activity-templates.header.theme' | translate }}
                </h5>
            </header>
            <div nz-row>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-templates.fields.theme-name' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="themeNameValidationTemplate">
                            <input nz-input [formControl]="formGroup.controls.themeName" />

                            <ng-template #themeNameValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'themeName'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-templates.fields.theme-code' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="themeCodeValidationTemplate">
                            <input nz-input [formControl]="formGroup.controls.themeCode" />

                            <ng-template #themeCodeValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'themeCode'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row>
                <div nz-col nzSpan="24">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-templates.fields.theme-description' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24">
                            <app-input-validation
                                [control]="formGroup.controls.themeDescription"
                                [field]="'themeDescription'"
                                [errors]="errors"></app-input-validation>
                            <app-editor
                                *ngIf="readonly !== undefined"
                                [control]="formGroup.controls.themeDescription"
                                [isDraggable]="false"
                                [isReadOnly]="readonly"></app-editor>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </section>

        <section class="form-section">
            <header>
                <h5 class="form-section__title">
                    {{ 'activity-templates.header.availability' | translate }}
                </h5>
            </header>
            <div nz-row>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-templates.fields.minimum-participants' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="minimumParticipantsValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.minimumParticipants" />

                            <ng-template #minimumParticipantsValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'minimumParticipants'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item>
                        <nz-form-label [nzSpan]="24">
                            {{ 'activity-templates.fields.maximum-participants' | translate }}
                        </nz-form-label>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="maximumParticipantsValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.maximumParticipants" />

                            <ng-template #maximumParticipantsValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'maximumParticipants'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row class="mb-0">
                <div nz-col nzFlex="0 0 130px"></div>
                <div nz-col nzSpan="4">
                    <strong>{{ 'activity-templates.fields.available' | translate }}</strong>
                </div>
                <div nz-col nzSpan="4">
                    <strong>{{ 'activity-templates.fields.waitinglist' | translate }}</strong>
                </div>
            </div>

            <div nz-row>
                <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
                    {{ 'activity-templates.fields.student' | translate }}
                </div>
                <div nz-col nzSpan="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="availableStudentValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.availableStudent" />
                            <ng-template #availableStudentValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'availableStudent'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="waitlistStudentValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.waitlistStudent" />

                            <ng-template #waitlistStudentValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'waitlistStudent'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row>
                <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
                    {{ 'activity-templates.fields.student-second' | translate }}
                </div>
                <div nz-col nzSpan="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="availableStudentSecondTimeValidationTemplate">
                            <input
                                type="number"
                                nz-input
                                [formControl]="formGroup.controls.availableStudentSecondTime" />

                            <ng-template #availableStudentSecondTimeValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'availableStudentSecondTime'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="waitlistStudentSecondTimeValidationTemplate">
                            <input
                                type="number"
                                nz-input
                                [formControl]="formGroup.controls.waitlistStudentSecondTime" />

                            <ng-template #waitlistStudentSecondTimeValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'waitlistStudentSecondTime'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row>
                <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
                    {{ 'activity-templates.fields.catch-up' | translate }}
                </div>
                <div nz-col nzSpan="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="availableCatchUpValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.availableCatchUp" />

                            <ng-template #availableCatchUpValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'availableCatchUp'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="waitlistCatchUpValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.waitlistCatchUp" />

                            <ng-template #waitlistCatchUpValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'waitlistCatchUp'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <div nz-row>
                <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
                    {{ 'activity-templates.fields.guest' | translate }}
                </div>
                <div nz-col nzSpan="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="availableGuestValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.availableGuest" />

                            <ng-template #availableGuestValidationTemplate let-control>
                                <app-input-validation
                                    [control]="control"
                                    [field]="'availableGuest'"
                                    [errors]="errors"></app-input-validation>
                            </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="4">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24" [nzErrorTip]="waitlistGuestValidationTemplate">
                            <input type="number" nz-input [formControl]="formGroup.controls.waitlistGuest" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

            <nz-form-control [nzErrorTip]="waitlistGuestValidationTemplate">
                <ng-template #waitlistGuestValidationTemplate let-control>
                    <app-input-validation
                        [control]="control"
                        [field]="'waitlistGuest'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </section>

        <div class="button-container">
            <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
                {{ 'common.save' | translate }}
            </button>
        </div>
    </nz-card>
</form>
