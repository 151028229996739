import { Component } from '@angular/core';
import * as Country from '@app/modules/countries/reducers'
import { Store } from '@ngrx/store';
import * as CountryApiActions from '@app/modules/countries/actions/country-api.actions';
import { State } from '@app/reducers';
import { CountryCreateModel, CountryUpdateModel } from '@app/modules/countries/models';

@Component({
    selector: 'app-country-detail',
    templateUrl: './country-detail.component.html',
    styleUrls: ['./country-detail.component.less'],
})
export class CountryDetailComponent {
    loading$ = this.store.select(Country.selectDetailLoading);
    errors$ = this.store.select(Country.selectDetailErrors);
    country$ = this.store.select(Country.selectDetailCountry);

    constructor(private readonly store: Store<State>) { }

    submit(country: CountryCreateModel | CountryUpdateModel) {
        this.store.dispatch(country.hasOwnProperty('id')
            ? CountryApiActions.Update(country as CountryUpdateModel) 
            : CountryApiActions.Create(country)
        );
    }
}