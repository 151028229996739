import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { SlugStatus } from '@app/modules/page/enums/slug-status';
import {
  PageChangeSequenceModel,
  PageChangeVisibilityModel,
  PageTableItemModel,
} from '@app/modules/page/models';
import { PageCreateModel } from '@app/modules/page/models/page-create.model';
import { PageUpdateModel } from '@app/modules/page/models/page-update.model';
import { TableDataModel } from '@app/shared/models';
import { serializeContentBlocks } from '@app/shared/models/content';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PageService {
  private readonly endpoints = {
    overview: 'Page/GetOverview',
    get: 'Page/:id',
    update: 'Page/Update',
    create: 'Page/Create',
    delete: 'Page/Delete?id=:id',
    changePageVisibility: 'Page/ChangePageVisibility',
    changeSequence: 'Page/ChangeSequence',
    getChildPages: 'Page/GetChildPages',
    newSlugAsRedirectExists: 'Page/NewSlugAsRedirectExists',
    redirectExists: 'Page/redirectExists',
  };

  constructor(private readonly httpService: HttpService) {}

  getAll(
    sorting: TableSortingModel,
    pagination: TablePaginationModel
  ): Observable<TableDataModel<PageTableItemModel>> {
    const paginationParams = parse(pagination, 'pagination');
    const sortingParams = parse(sorting, 'sorting');

    return this.httpService.get<any>(
      this.endpoints.overview + `?${paginationParams}&${sortingParams}`
    );
  }

  get(id: number): Observable<PageUpdateModel> {
    return this.httpService.get<any>(
      this.endpoints.get.replace(':id', id.toString())
    );
  }

  update(request: PageUpdateModel): Observable<PageUpdateModel> {
    return this.httpService.put<any>(this.endpoints.update, {
      ...request,
      contentBlocks: serializeContentBlocks(request).contentBlocks,
    });
  }

  create(request: PageCreateModel): Observable<PageUpdateModel> {
    return this.httpService.post<any>(this.endpoints.create, {
      ...request,
      contentBlocks: serializeContentBlocks(request).contentBlocks,
    });
  }

  delete(id: number): Observable<any> {
    return this.httpService.delete<any>(
      this.endpoints.delete.replace(':id', id.toString())
    );
  }

  changePageVisibility(request: PageChangeVisibilityModel): Observable<any> {
    return this.httpService.put<any>(
      this.endpoints.changePageVisibility,
      request
    );
  }

  changeSequence(request: PageChangeSequenceModel): Observable<any> {
    return this.httpService.put<any>(this.endpoints.changeSequence, request);
  }

  getChildPages(
    sorting: TableSortingModel,
    pagination: TablePaginationModel,
    parentId: number
  ): Observable<TableDataModel<PageTableItemModel>> {
    const paginationParams = parse(pagination, 'pagination');
    const sortingParams = parse(sorting, 'sorting');
    const parentIdParams = 'parentId=' + parentId;

    return this.httpService.get<any>(
      this.endpoints.getChildPages +
        `?${paginationParams}&${sortingParams}&${parentIdParams}`
    );
  }

  newSlugAsRedirectExists(
    newSlug: string,
    parentId: number | null,
    pageId: number | null
  ): Observable<{ slugStatus: SlugStatus }> {
    return this.httpService.post<{ slugStatus: SlugStatus }>(this.endpoints.newSlugAsRedirectExists, {
      newSlug,
      parentId,
      pageId
    })

  }
}
