import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import * as BlogCategoryApiActions from '@app/modules/blog-category/actions/blog-category-api.actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';
import { inject } from '@angular/core';

export const canActivate: CanActivateFn = (route, state) => {
    const store = inject(Store<State>);

    return of(true).pipe(
        map(() => BlogCategoryApiActions.Clear()),
        tap((action: any) => store.dispatch(action)),
        map(() => true),
    );
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);
