import * as ActivityTemplateApiActions from '@app/modules/activity-template/actions/activity-template-api.actions';
import { ActivityTemplateUpdateModel } from "@app/modules/activity-template/models";
import { ActivityTypeCodeModel } from '@app/modules/activity-type/models/activity-type-code.model';
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    activityTemplate: null,
    activityTypes: null,
    loading: false,
    errors: {}
}

export interface State {
    activityTemplate: ActivityTemplateUpdateModel | null,
    activityTypes: ActivityTypeCodeModel[] | null;
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(ActivityTemplateApiActions.Clear, () => ({ ...initialState })),

    on(ActivityTemplateApiActions.Get, (state) => ({ ...state, loading: true })),
    on(ActivityTemplateApiActions.GetSuccess, (state, activityTemplate) => ({ ...state, activityTemplate, loading: false })),
    on(ActivityTemplateApiActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityTemplateApiActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityTemplateApiActions.UpdateSuccess, (state, activityTemplate) => ({ ...state, activityTemplate, loading: false })),
    on(ActivityTemplateApiActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityTemplateApiActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityTemplateApiActions.CreateSuccess, (state, activityTemplate) => ({ ...state, activityTemplate, loading: false })),
    on(ActivityTemplateApiActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityTemplateApiActions.GetActivityTypes, (state) => ({ ...state, loading: true })),
	on(ActivityTemplateApiActions.GetActivityTypesSuccess, (state, { activityTypes }) => ({ ...state, activityTypes, loading: false })),
	on(ActivityTemplateApiActions.GetActivityTypesError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getActivityTemplate = (state: State) => state.activityTemplate;
export const getActivityTypes = (state: State) => state.activityTypes;