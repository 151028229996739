export enum ZipCodeCountry {
	None,
	NL,
	DE,
	BE,
	GB,
	JE,
	GG,
	IM,
	US,
	CA,
	IE,
	JP,
	FR,
	AU,
	IT,
	CH,
	AT,
	ES,
	DK,
	SE,
	NO,
	BR,
	PT,
	FI,
	AX,
	KR,
	CN,
	TW,
	SG,
	DZ,
	AD,
	AR,
	AM,
	AZ,
	BH,
	BD,
	BB,
	BY,
	BM,
	BA,
	IO,
	BN,
	BG,
	KH,
	CV,
	CL,
	CR,
	HR,
	CY,
	CZ,
	DO,
	EC,
	EG,
	EE,
	FO,
	GE,
	GR,
	GL,
	GT,
	HT,
	HN,
	HU,
	IS,
	IN,
	ID,
	IL,
	JO,
	KZ,
	KE,
	KW,
	KY,
	LA,
	LV,
	LB,
	LI,
	LT,
	LU,
	MK,
	MY,
	MV,
	MT,
	MU,
	MX,
	MD,
	MC,
	MA,
	NP,
	NZ,
	NI,
	NG,
	OM,
	PA,
	PK,
	PY,
	PH,
	PL,
	PR,
	RO,
	RU,
	SM,
	SA,
	SN,
	SK,
	SI,
	ZA,
	LK,
	TJ,
	TH,
	TN,
	TR,
	TM,
	UA,
	UY,
	UZ,
	VA,
	VE,
	ZM,
	AS,
	CC,
	CK,
	RS,
	ME,
	CS,
	YU,
	CX,
	ET,
	FK,
	NF,
	FM,
	GF,
	GN,
	GP,
	GS,
	GU,
	GW,
	HM,
	IQ,
	KG,
	LR,
	LS,
	MG,
	MH,
	MN,
	MP,
	MQ,
	NC,
	NE,
	VI,
	VN,
	PF,
	PG,
	PM,
	PN,
	PW,
	RE,
	SH,
	SJ,
	SO,
	SZ,
	TC,
	WF,
	XK,
	YT,
	PE,
	INTL,
}
