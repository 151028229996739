import { Pipe, PipeTransform } from '@angular/core';
import { DiscountType } from '@app/modules/discount-codes/enums';
import { DiscountCodeTableItemModel } from '@app/modules/discount-codes/models';

@Pipe({ name: 'discountPipe' })
export class DiscountPipe implements PipeTransform {
	transform(discountCodeTableItemModel: DiscountCodeTableItemModel): string {
		switch (discountCodeTableItemModel.discountType) {
			case DiscountType.Percentage:
				return discountCodeTableItemModel.discountPercentage.toFixed(1) + '%';
			case DiscountType.Amount:
				return '€' + discountCodeTableItemModel.discountAmount.toFixed(2);
			default:
				return '';
		}
	}
}
