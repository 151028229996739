import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as TagActions from '@app/modules/tag/actions/tag-api.actions';
import * as Tag from '@app/modules/tag/reducers';
import { TagService } from '@app/modules/tag/services/tag.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class TagEffects {
  constructor(
    private actions$: Actions,
    private tagService: TagService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<Tag.State>
  ) {}

  /**
   * Get effects
   */
  loadTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(Tag.selectTagOverviewPagination),
        this.store.select(Tag.selectTagOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.tagService.getAll(sorting, pagination).pipe(
          map((response) => TagActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(TagActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadTagsFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.FilterOverview),
      map(() => TagActions.GetOverview())
    )
  );

  loadTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.Get),
      exhaustMap((action) =>
        this.tagService.get(action.id).pipe(
          map((response) => TagActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(TagActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.Create),
      exhaustMap((action) =>
        this.tagService.create(action).pipe(
          map((tag) => TagActions.CreateSuccess(tag)),
          catchError((response: { error: ErrorResponse }) =>
            of(TagActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createTagSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TagActions.CreateSuccess),
        tap((tag) =>
          this.translate
            .get('notification.add', { item: tag.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.Update),
      exhaustMap((action) =>
        this.tagService.update(action).pipe(
          map((tag) => TagActions.UpdateSuccess(tag)),
          catchError((response: { error: ErrorResponse }) =>
            of(TagActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateTagSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TagActions.UpdateSuccess),
        tap((tag) =>
          this.translate
            .get('notification.save', { item: tag.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateTagSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TagActions.CreateSuccess, TagActions.UpdateSuccess),
        tap(() => this.router.navigate(['tags']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.Delete),
      exhaustMap((action) =>
        this.tagService.delete(action.id).pipe(
          map(() => TagActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(TagActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteTagSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagActions.DeleteSuccess),
      map(() => TagActions.GetOverview())
    )
  );

  deleteTagSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TagActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  tagErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TagActions.DeleteError, TagActions.GetOverviewError),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
