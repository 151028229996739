<div class="page-header">
	<h1 class="page-header__title">{{ 'email-templates.title' | translate }}</h1>
	<div class="page-header__actions">
		<a
			claimDirective
			[claims]="[
				{
					claimType: ClaimTypes.EmailTemplate,
					claimValues: [ClaimValues.Create]
				}
			]"
			routerLink="/email-templates/toevoegen"
			nz-button
			nzType="primary">
			{{ 'common.add' | translate }}
			<i class="fa-regular fa-plus"></i>
		</a>
	</div>
</div>

<nz-select
	*ngIf="selectedEmailTemplateType"
	[(ngModel)]="selectedEmailTemplateType"
	(ngModelChange)="onEmailTemplateTypeChange($event)"
	nzPlaceHolder="{{ 'email-templates.fields.emailTemplateType' | translate }}">
	<nz-option
		*ngFor="let emailTemplateType of EmailTemplateType | enumKeyValue"
		[nzLabel]="'email-templates.enums.emailTemplateType.' + emailTemplateType.key | translate"
		[nzValue]="emailTemplateType.value"></nz-option>
</nz-select>

<app-email-template-overview-table
	[data]="(tableData$ | async)!"
	(delete)="onDeleteConfirm($event)"
	(onQueryParamsChange)="onQueryParamsChange($event)">
</app-email-template-overview-table>