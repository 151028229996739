import { FAQCategoryUpdateModel } from "@app/modules/faq-categories/models/faq-category-update.model";
import * as FAQCategoryApiActions from "@app/modules/faq-categories/actions/faq-category-api.actions";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    faqCategory: null,
    loading: false,
    errors: {}
}

export interface State {
    faqCategory: FAQCategoryUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(FAQCategoryApiActions.Clear, () => ({ ...initialState })),

    on(FAQCategoryApiActions.Get, (state) => ({ ...state, loading: true })),
    on(FAQCategoryApiActions.GetSuccess, (state, faqCategory) => ({ ...state, faqCategory, loading: false })),
    on(FAQCategoryApiActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(FAQCategoryApiActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(FAQCategoryApiActions.UpdateSuccess, (state, faqCategory) => ({ ...state, faqCategory, loading: false })),
    on(FAQCategoryApiActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(FAQCategoryApiActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(FAQCategoryApiActions.CreateSuccess, (state, faqCategory) => ({ ...state, faqCategory, loading: false })),
    on(FAQCategoryApiActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getFAQCategory = (state: State) => state.faqCategory;