import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DiscountType, DiscountUsage } from '@app/modules/discount-codes/enums';
import { DiscountCodeCreateModel, DiscountCodeUpdateModel } from '@app/modules/discount-codes/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
	selector: 'app-discount-code-detail-form',
	templateUrl: './discount-code-detail-form.component.html',
	styleUrls: ['./discount-code-detail-form.component.less']
})
export class DiscountCodeDetailFormComponent implements OnInit, OnChanges {
	update = false;
	readonly?: boolean;

	dateFormat = 'dd-MM-yyyy';
	
	private readonly discountCodeLength = 8;
	private readonly discountCodeChars = 'ABCDEFGHJKLMNPQRSTUVWXYZ1234567890';

	@Input() loading = false;
	@Input() errors: { [key: string]: string[] } = {};
	@Input({ required: true }) discountCode: DiscountCodeUpdateModel | null = null;
	@Input({ required: true }) activities: KeyValueModel[] | null = [];
	@Input({ required: true }) activityTypes: KeyValueModel[] | null = [];
	@Input({ required: true }) relations: KeyValueModel[] | null = [];

	@Output() submitted = new EventEmitter<DiscountCodeCreateModel | DiscountCodeUpdateModel>();
	
	private codePattern = new RegExp('^[A-HJ-NP-Z0-9]{8}$');

	formGroup = new FormGroup({
		id: new FormControl<number | null>(null),
		active: new FormControl<boolean>(false, [CustomValidators.required]),
		discountUsage: new FormControl<DiscountUsage>(DiscountUsage.Once, [CustomValidators.required]),
		discountType: new FormControl<DiscountType>(DiscountType.Percentage, [CustomValidators.required]),
		discountAmount: new FormControl<number>(0, [CustomValidators.required, Validators.min(0.01)]),
		discountPercentage: new FormControl<number>(0, [
			CustomValidators.required,
			Validators.min(0.1),
			Validators.max(100),
			CustomValidators.correctAmountDecimal(1)]),
		code: new FormControl<string>('', [CustomValidators.required, Validators.pattern(this.codePattern), Validators.maxLength(100)]),
		startDate: new FormControl<Date>(new Date(), [CustomValidators.required]),
		endDate: new FormControl<Date>(new Date(), [CustomValidators.required]),
		relationdId: new FormControl<number | null>(null, []),
		activityId: new FormControl<number | null>(null, []),
		activityTypeId: new FormControl<number | null>(null, []),
	}, [CustomValidators.endDateAfterStartDate('startDate', 'endDate')]);

	DiscountUsage = DiscountUsage;
	DiscountType = DiscountType;

	constructor(private readonly claimService: ClaimService) {}

	async ngOnInit() {
		this.update = !!this.discountCode;

		if (
			this.update &&
			(!(await this.claimService.hasAny([{ claimType: ClaimTypes.DiscountCode, claimValues: [ClaimValues.Update] }])) ||
				this.discountCode!.used > 0 ||
				this.discountCode!.active)
		) {
			this.formGroup.disable();
			this.readonly = true;
		} else {
			this.readonly = false;
		}
		
		this.formGroup.patchValue({
			active: this.discountCode?.active ?? false,
			discountUsage: this.discountCode?.discountUsage ?? DiscountUsage.Once,
			discountType: this.discountCode?.discountType ?? DiscountType.Percentage,
			discountAmount: this.discountCode?.discountAmount ?? 0,
			discountPercentage: this.discountCode?.discountPercentage ?? 0,
			code: this.discountCode?.code ?? '',
			startDate: this.discountCode?.startDate ?? new Date(),
			endDate: this.discountCode?.endDate ?? new Date(),
			relationdId: this.discountCode?.relationId ?? null,
			activityId: this.discountCode?.activityId ?? null,
			activityTypeId: this.discountCode?.activityTypeId ?? null,
		});

		if (this.update) {
			this.formGroup.controls.id.setValidators([CustomValidators.required]);
			this.formGroup.controls.id.setValue((this.discountCode as DiscountCodeUpdateModel).id);
		}

		this.formGroup.controls.startDate.valueChanges.subscribe(() => {
			this.formGroup.controls.endDate.markAsTouched();
			this.formGroup.updateValueAndValidity();
		});
		this.formGroup.controls.endDate.valueChanges.subscribe(() => {
			this.formGroup.updateValueAndValidity();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		processBackendValidators(changes, this.formGroup);
	}

	submit() {
		this.correctValidators();
		if (isFormValid(this.formGroup)) {
			this.update ? this.submitUpdate() : this.submitCreate();
		}
	}

	generateDiscountCode() {
		const code = this.randomString();
		this.formGroup.controls.code.setValue(code);
	}

	private submitCreate() {
		this.submitted.emit({
			active: this.formGroup.value.active,
			discountUsage: this.formGroup.value.discountUsage,
			discountType: this.formGroup.value.discountType,
			discountAmount: this.formGroup.value.discountAmount,
			discountPercentage: this.formGroup.value.discountPercentage,
			code: this.formGroup.value.code,
			startDate: this.formGroup.value.startDate,
			endDate: this.formGroup.value.endDate,
			relationId: this.formGroup.value.relationdId,
			activityId: this.formGroup.value.activityId,
			activityTypeId: this.formGroup.value.activityTypeId,
			used: 0,
		} as DiscountCodeCreateModel);
	}

	private submitUpdate() {
		this.submitted.emit({
			id: this.formGroup.value.id,
			active: this.formGroup.value.active,
			discountUsage: this.formGroup.value.discountUsage,
			discountType: this.formGroup.value.discountType,
			discountAmount: this.formGroup.value.discountAmount,
			discountPercentage: this.formGroup.value.discountPercentage,
			code: this.formGroup.value.code,
			startDate: this.formGroup.value.startDate,
			endDate: this.formGroup.value.endDate,
			relationId: this.formGroup.value.relationdId,
			activityId: this.formGroup.value.activityId,
			activityTypeId: this.formGroup.value.activityTypeId,
		} as DiscountCodeUpdateModel);
	}

	private correctValidators() {
		if (this.formGroup.value.discountType === DiscountType.Percentage) {
			this.formGroup.controls.discountAmount.setValidators([]);
			this.formGroup.controls.discountAmount.setValue(0);
			this.formGroup.controls.discountAmount.updateValueAndValidity();
		} else {
			this.formGroup.controls.discountPercentage.setValidators([]);
			this.formGroup.controls.discountPercentage.setValue(0);
			this.formGroup.controls.discountPercentage.updateValueAndValidity();
		}
	}

	private randomString() {
		let result = '';
		for (let i = this.discountCodeLength; i > 0; --i)
			result += this.discountCodeChars[Math.floor(Math.random() * this.discountCodeChars.length)];
		return result;
	}
}
