import * as DiscountCodeActions from "@app/modules/discount-codes/actions/discount-code-api.actions"
import { DiscountCodeUpdateModel } from "@app/modules/discount-codes/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    discountCode: null,
    loading: false,
    activities: [],
    relations: [],
    activityTypes: [],
    errors: {}
}

export interface State {
    discountCode: DiscountCodeUpdateModel | null,
    loading: boolean,
    activities: KeyValueModel[],
    relations: KeyValueModel[],
    activityTypes: KeyValueModel[],
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(DiscountCodeActions.Clear, () => ({ ...initialState })),

    on(DiscountCodeActions.Get, (state) => ({ ...state, loading: true })),
    on(DiscountCodeActions.GetSuccess, (state, discountCode) => ({ ...state, discountCode, loading: false })),
    on(DiscountCodeActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(DiscountCodeActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(DiscountCodeActions.UpdateSuccess, (state, discountCode) => ({ ...state, discountCode, loading: false })),
    on(DiscountCodeActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(DiscountCodeActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(DiscountCodeActions.CreateSuccess, (state, discountCode) => ({ ...state, discountCode, loading: false })),
    on(DiscountCodeActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(DiscountCodeActions.GetActivitiesSuccess, (state, { activities }) => ({ ...state, activities })),
    on(DiscountCodeActions.GetActivityTypesSuccess, (state, { activityTypes }) => ({ ...state, activityTypes })),
    on(DiscountCodeActions.GetRelationsSuccess, (state, { relations }) => ({ ...state, relations }))
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getDiscountCode = (state: State) => state.discountCode;
export const getActivities = (state: State) => state.activities;
export const getRelations = (state: State) => state.relations;
export const getActivityTypes = (state: State) => state.activityTypes;