<div class="page-header">
  <h1 class="page-header__title">{{ "faq.title" | translate }}</h1>
  <div class="page-header__actions">
    <a
      claimDirective
      [claims]="[
        {
          claimType: ClaimTypes.FAQ,
          claimValues: [ClaimValues.Create]
        }
      ]"
      routerLink="/faq/toevoegen"
      nz-button
      nzType="primary"
    >
      {{ "common.add" | translate }}
      <i class="fa-regular fa-plus"></i>
    </a>
  </div>
</div>
<nz-select
  *ngIf="faqCategories$ | async"
  nzDisabled='{{this.enableCategories}}'
  [(ngModel)]="this.categoryId"
  (ngModelChange)="onCategoryChange($event)"
>
  <nz-option
    nzLabel="{{ 'faq.fields.all-categories' | translate }}"
    [nzValue]="-1"
  >
  </nz-option>
  <nz-option
    *ngFor="let category of faqCategories$ | async"
    [nzLabel]="category.value"
    [nzValue]="category.key"
  ></nz-option>
  <nz-option
    nzLabel="{{ 'faq.fields.not-in-category' | translate }}"
    [nzValue]="0"
  >
  </nz-option>
</nz-select>

<app-faq-overview-table
  [data]="(tableData$ | async)!"
  [categoryId]="this.categoryId"
  (delete)="onDeleteConfirm($event)"
  (onQueryParamsChange)="onQueryParamsChange($event)"
  (changeSequence)="onSequenceChange($event)"
  (onResetOverview)="onResetOverview()"
  (onChangeOnline)="onOnlineChange($event)"
  (changeDragDrop)="changeDragDrop($event)"
/>
