import * as CountryApiActions from "@app/modules/countries/actions/country-api.actions"
import { CountryTableItemModel } from "@app/modules/countries/models/"
import { TableOverviewModel } from "@app/shared/models/table/table-overview.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model"
import { applySortingOrDefault } from "@app/shared/utilities/overview-reducer.utilities"
import { createReducer, on } from "@ngrx/store"

const initialState: State = new TableOverviewModel<CountryTableItemModel>('name');

export interface State extends TableStateModel<CountryTableItemModel> { }

export const reducer = createReducer(initialState,
    on(CountryApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(CountryApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(CountryApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(CountryApiActions.FilterOverview, (state, { sorting, pagination }) => ({ 
        ...state, 
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: { 
            ...state.pagination, 
            page: pagination.page,
            pageSize: pagination.pageSize
        } 
    })),
    on(CountryApiActions.ResetOverview,  () => ({ ...initialState }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;