import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as ActivityApiActions from '@app/modules/activities/actions/activities-api.actions';
import { ActivityTemplateCreateModel } from '@app/modules/activity-template/models';
import { ActivityTemplateService } from '@app/modules/activity-template/services/activity-template.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const activityTemplateService = inject(ActivityTemplateService);
    const router = inject(Router);
    const store = inject(Store<State>);

    return activityTemplateService.get(route.params['id']).pipe(
        map((activityTemplate) => ActivityApiActions.GetTemplateSuccess(activityTemplate as ActivityTemplateCreateModel)),
        tap((action: any) => store.dispatch(action)),
        map((activityTemplate) => !!activityTemplate),
        catchError(() => {
            return router.navigate(['/not-found']);
        }),
    );
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    canActivate(route, state);
