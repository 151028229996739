<div class="page-header">
	<h1 class="page-header__title">{{ 'activity-locations.title' | translate }}</h1>
	<div class="page-header__actions">
		<a
			claimDirective
			[claims]="[
				{
					claimType: ClaimTypes.ActivityLocation,
					claimValues: [ClaimValues.Create]
				}
			]"
			routerLink="/activiteit-locaties/toevoegen"
			nz-button
			nzType="primary">
			{{ 'common.add' | translate }}
			<i class="fa-regular fa-plus"></i>
		</a>
	</div>
</div>

<app-activity-location-overview-table
	[data]="(tableData$ | async)!"
	(delete)="onDeleteConfirm($event)"
	(onQueryParamsChange)="onQueryParamsChange($event)" />
