export class ImageAndEditorContentBlockModel {
    editor: string;
    imageId: number | null;

    constructor(content: string) {
        const { editor, imageId } = content !== ''
            ? JSON.parse(content)
            : { editor: '', imageId: null };

        this.editor = editor;
        this.imageId = imageId;
    }
}