import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NoteItemModel } from '@app/modules/note/models/note-overview.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-notes-sidebar',
    templateUrl: './notes-sidebar.component.html',
})
export class NotesSidebarComponent {
    @Input({ required: true }) notes: NoteItemModel[] = [];
    @Input() loading$: Observable<boolean>;
    @Input() errors$: Observable<{ [key: string]: string[] }>;
    @Input() note$: Observable<string>;

    @Output() createNote = new EventEmitter<{ noteMessage: string; isImportant: boolean }>();
    @Output() setNoteImportant = new EventEmitter<{ noteId: number }>();
    @Output() doEditNote = new EventEmitter<{
        id: number;
        noteMessageEdit: string;
        editReason: string;
    }>();
    @Output() doDeleteNote = new EventEmitter<{ noteId: number }>();

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
}
