import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { tagClearGuard, tagExistsGuard } from '@app/modules/tag/guards';
import { TagDetailComponent } from '@app/modules/tag/pages/detail/tag-detail.component';
import { TagOverviewComponent } from '@app/modules/tag/pages/overview/tag-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: TagOverviewComponent },
    { path: 'toevoegen', component: TagDetailComponent, canActivate: [ tagClearGuard ] },
    { path: 'bewerken/:id', component: TagDetailComponent, canActivate: [ tagExistsGuard ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TagRoutingModule { }
