import { Component, Input, SkipSelf, OnInit } from "@angular/core";
import { ControlContainer, FormArray } from "@angular/forms";
import { ContentBlockFormGroup, ContentBlockOption, ContentBlockType } from "@app/shared/models/content";

import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-content-block-overview',
    templateUrl: './content-block-overview.component.html',
    styleUrls: ['./content-block-overview.component.less'],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ]
})
export class ContentBlockOverviewComponent implements OnInit {
    @Input() formArrayName!: string;
    @Input({ required: true }) availableContentTypes: ContentBlockType[] = [];
    @Input({ required: true }) isReadOnly: boolean = false;
    @Input() errors: { [key: string]: string[] } = {};

    formArray!: FormArray<ContentBlockFormGroup>;

    options: ContentBlockOption[] = [];
    ContentBlockType = ContentBlockType;

    constructor(private controlContainer: ControlContainer) { }

    ngOnInit() {
        if (this.controlContainer) {
            if (this.formArrayName) {
                this.formArray = this.controlContainer.control!.get(this.formArrayName) as FormArray;
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }

        this.addOptions();

        if (this.isReadOnly) {
            this.formArray.disable();
        }
    }

    addContentBlock(contentBlockType: ContentBlockType) {
        this.formArray.push(new ContentBlockFormGroup({type: contentBlockType}));
        this.addOptions();
        this.updateSequences();
    }

    removeContentBlock(index: number) {
        this.formArray.removeAt(index);
    }

    dropHandle(event: CdkDragDrop<{ title: string; poster: string }[]>) {
        const { previousIndex, currentIndex } = event;

        const currentGroup = this.formArray.at(previousIndex);

        this.formArray.removeAt(previousIndex);
        this.formArray.insert(currentIndex, currentGroup);

        this.updateSequences();
    }

    getContentTypeText(contentBlockType: ContentBlockType): string {
        return `content-block.values.${ContentBlockType[contentBlockType]}`;
    }

    private addOptions() {
        (this.formArray.controls || []).forEach((_, index) => {
            this.options[index] = {
                isReadOnly: this.isReadOnly,
                editingLabel: false,
                draggable: !this.isReadOnly
            };
        })
    }

    private updateSequences() {
        this.formArray.controls.forEach((control, index) => control.patchValue({sequenceNumber: index + 1}));
    }
}