import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageOverviewComponent } from './pages/overview/page-overview.component';
import { PageDetailComponent } from './pages/detail/page-detail.component';
import {
  pageClearGuard,
  pageExistsGuard
} from "@app/modules/page/guards";

const routes: Routes = [
  { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
  { path: 'overzicht', component: PageOverviewComponent },
  { path: 'toevoegen', component: PageDetailComponent, canActivate: [ pageClearGuard ] },
  { path: 'toevoegen/:parentId', component: PageDetailComponent, canActivate: [ pageClearGuard ] },
  { path: 'bewerken/:id', component: PageDetailComponent, canActivate: [ pageExistsGuard ] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PageRoutingModule {}
