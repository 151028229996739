import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import * as EmailTemplateActions from '@app/modules/email-template/actions/email-template-api.actions';
import * as EmailTemplate from '@app/modules/email-template/reducers';
import { EmailTemplateType } from '@app/modules/email-template/enums';
import { Subscription, firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-email-template-overview',
  templateUrl: './email-template-overview.component.html',
  styleUrl: './email-template-overview.component.less'
})
export class EmailTemplateOverviewComponent implements OnInit {
  	tableData$ = this.store.select(EmailTemplate.selectEmailTemplateOverviewState);

	ClaimTypes = ClaimTypes;
  	ClaimValues = ClaimValues;
	EmailTemplateType = EmailTemplateType;

	selectedEmailTemplateType: EmailTemplateType | null = null;

	constructor(private readonly store: Store) {}

	async ngOnInit(): Promise<void> {
		this.selectedEmailTemplateType = await firstValueFrom(this.store.select(EmailTemplate.selectEmailTemplateOverviewEmailTemplateType));
	}

	onDeleteConfirm(id: number): void {
		this.store.dispatch(EmailTemplateActions.Delete({ id: id }));
	}

	onEmailTemplateTypeChange(emailTemplateType: EmailTemplateType) {
		this.store.dispatch(EmailTemplateActions.SetEmailTemplateType({ emailTemplateType: emailTemplateType }));
		this.store.dispatch(
			EmailTemplateActions.FilterOverview({
				emailTemplateType: emailTemplateType,
				pagination: { page: 1, pageSize: 10 },
			})
		);
	}

	/**
	 * Trigger the loading of data whenever the table changes.
	 * The default pageSize, index and sort options are based on the TableStateModel.
	 * @param params
	 */
	onQueryParamsChange(params: NzTableQueryParams) {
		const { pageSize, pageIndex, sort, filter } = params;
		const currentSort = sort.find((item) => item.value !== null);
		const sortField = (currentSort?.key) ?? null;
		const sortOrder = (currentSort?.value) ?? null;

		this.store.dispatch(
			EmailTemplateActions.FilterOverview({
				emailTemplateType: this.selectedEmailTemplateType,
				pagination: {
					page: pageIndex,
					pageSize,
				},
				sorting: {
					propertyName: sortField ?? '',
					order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
				},
			})
		);
	}
}
