import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RelationOverviewComponent } from '@app/modules/relation/pages/overview/relation-overview.component';
import { relationClearGuard, relationExistsGuard } from '@app/modules/relation/guards';
import { RelationDetailComponent } from '@app/modules/relation/pages/detail/relation-detail.component';
import { RelationViewComponent } from '@app/modules/relation/pages/view/relation-view.component';

const routes: Routes = [
  { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
  { path: 'overzicht', component: RelationOverviewComponent, pathMatch: 'full' },
  { path: 'detail/:id', component: RelationViewComponent, canActivate: [relationExistsGuard] },
  { path: 'toevoegen', component: RelationDetailComponent, canActivate: [relationClearGuard] },
  { path: 'bewerken/:id', component: RelationDetailComponent, canActivate: [relationExistsGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RelationRoutingModule {}
