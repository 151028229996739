<div class="auth-card">
	<div class="auth-card-content">
		<h1>{{ 'common.login' | translate }}</h1>
		<form nz-form nzLayout="vertical" [formGroup]="form" (ngSubmit)="onLogin()">
			<nz-form-item>
				<nz-form-control [nzErrorTip]="EmailValidationTemplate">
					<nz-form-label nzFor="email">{{ 'common.email' | translate }}</nz-form-label>
					<nz-input-group nzSize="large">
						<input
							nz-input
							type="email"
							id="email"
							[placeholder]="'placeholder.email' | translate"
							[formControl]="form.controls.email" />
					</nz-input-group>
					<ng-template #EmailValidationTemplate let-control>
						<app-input-validation [control]="control" [field]="'email'"></app-input-validation>
					</ng-template>
				</nz-form-control>
			</nz-form-item>

			<nz-form-item>
				<nz-form-control [nzErrorTip]="PasswordValidationTemplate" [nzExtra]="passwordForgetTemplate">
					<div class="label">
						<nz-form-label nzFor="password">{{ 'common.password' | translate }}</nz-form-label>
					</div>
					<nz-input-group nzSize="large" [nzSuffix]="suffixTemplatePassword">
						<input
							nz-input
							[type]="passwordShow ? 'text' : 'password'"
							id="password"
							[placeholder]="'placeholder.password' | translate"
							[formControl]="form.controls.password"
							required />
						<ng-template #suffixTemplatePassword>
							<i
								class="fa-solid"
								[class]="!passwordShow ? 'fa-eye' : 'fa-eye-slash'"
								(click)="passwordToggle($event)"></i>
						</ng-template>
					</nz-input-group>
					<ng-template #passwordForgetTemplate>
						<div class="auth-forget-password">
							<a routerLink="/auth/wachtwoord-vergeten">{{ 'auth.forget-password' | translate }}</a>
						</div>
					</ng-template>
					<ng-template #PasswordValidationTemplate let-control>
						<app-input-validation [control]="control" [field]="'password'" [errors]="errors!"></app-input-validation>
					</ng-template>
				</nz-form-control>
			</nz-form-item>

			<nz-form-item>
				<button
					type="submit"
					nz-button
					nzBlock
					nzType="primary"
					class="success"
					nzSize="large"
					nzShape="round"
					[disabled]="loading || form.invalid">
					{{ 'common.login' | translate }}
					<i *ngIf="loading" class="fa-duotone fa-spinner-third fa-spin"></i>
				</button>
			</nz-form-item>
		</form>
	</div>
</div>
