import * as AuthActions from '@app/modules/auth/actions/auth.actions';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
	errors: {},
	email: '',
	loading: false,
};

export interface State {
	errors: { [key: string]: string[] };
	email: string;
	loading: boolean;
}

export const reducer = createReducer(
	initialState,
	on(AuthActions.Login, (state, { loginRequest }) => ({ ...state, email: loginRequest.email, loading: true })),
	on(AuthActions.LoginError, (state, { response }) => ({
		...state,
		loading: false,
		errors: response?.errors || {},
	})),
	on(AuthActions.LoginSuccess, () => ({ ...initialState })), // Reset state if succeeded
	on(AuthActions.LoginClear, () => ({ ...initialState }))
);

export const getLoading = (state: State) => state.loading;
export const getErrors = (state: State) => state.errors;
