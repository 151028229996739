import { ErrorResponse } from "@app/core/models";
import { ActivityRelationCreateModel } from "@app/modules/activity-relation/models/activity-relation-create.model";
import { ActivityRelationTableItemModel } from "@app/modules/activity-relation/models/activity-relation-table.model";
import { ActivityRelationUpdateModel } from "@app/modules/activity-relation/models/activity-relation-update.model";
import { TableDataModel } from "@app/shared/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { TableFilterModel } from "@app/shared/models/table/table-filter.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[ActivityRelation] GetOverview");
export const GetOverviewError = createAction("[ActivityRelation] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[ActivityRelation] GetOverviewSuccess", props<TableDataModel<ActivityRelationTableItemModel>>());

export const FilterOverview = createAction("[ActivityRelation] FilterOverview", props<{filter?: TableFilterModel[], thenBy?: TableSortingModel[], activityId: number, sorting?: TableSortingModel, pagination: { page: number, pageSize: number }}>());

export const GetWaitlistOverview = createAction("[ActivityRelation] GetWaitlistOverview");
export const GetWaitlistOverviewError = createAction("[ActivityRelation] GetWaitlistOverviewError", props<{ response: ErrorResponse }>());
export const GetWaitlistOverviewSuccess = createAction("[ActivityRelation] GetWaitlistOverviewSuccess", props<TableDataModel<ActivityRelationTableItemModel>>());

export const FilterWaitlistOverview = createAction("[ActivityRelation] FilterWaitlistOverview", props<{filter?: TableFilterModel[], thenBy?: TableSortingModel[], activityId: number, sorting?: TableSortingModel, pagination: { page: number, pageSize: number }}>());

export const ResetOverview = createAction("[ActivityRelation] ResetOverview");
export const AddReservation = createAction("[ActivityRelation] AddReservation");
export const AddReservationError = createAction("[ActivityRelation] AddReservationError", props<{ response: ErrorResponse }>());
export const AddReservationSuccess = createAction("[ActivityRelation] AddReservationSuccess", props<ActivityRelationTableItemModel>());

export const Delete = createAction("[ActivityRelation] Delete", props<{ id: number }>());
export const DeleteReservation = createAction("[ActivityRelation] DeleteReservation", props<{ id: number }>());
export const DeleteReservationSuccess = createAction("[ActivityRelation] DeleteReservationSuccess");
export const DeleteError = createAction("[ActivityRelation] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[ActivityRelation] DeleteSuccess");

export const GetBackgroundList = createAction("[ActivityRelation] GetBackgroundList");
export const GetBackgroundListSuccess = createAction("[ActivityRelation] GetBackgroundListSuccess", props<{response: KeyValueModel[]}>());
export const GetBackgroundListError = createAction("[ActivityRelation] GetBackgroundListError", props<{ response: ErrorResponse }>());

export const Create = createAction("[ActivityRelation] Create", props<ActivityRelationCreateModel>());
export const CreateError = createAction("[ActivityRelation] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[ActivityRelation] CreateSuccess", props<ActivityRelationCreateModel>());

export const Update = createAction("[ActivityRelation] Update", props<ActivityRelationUpdateModel>());
export const UpdateError = createAction("[ActivityRelation] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[ActivityRelation] UpdateSuccess", props<ActivityRelationUpdateModel>());

export const GetActivityRelation = createAction("[Activity] GetActivityRelation", props<{ id: number }>());
export const GetActivityRelationError = createAction("[Activity] GetActivityRelationError", props<{ response: ErrorResponse }>());
export const GetActivityRelationSuccess = createAction("[Activity] GetActivityRelationSuccess", props<{ response: ActivityRelationUpdateModel }>());