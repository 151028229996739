import { NgModule } from '@angular/core';
import { NewsDetailFormComponent } from '@app/modules/news/components/news-detail-form/news-detail-form.component';
import { NewsOverviewTableComponent } from '@app/modules/news/components/news-overview-table/news-overview-table.component';
import { NewsRoutingModule } from '@app/modules/news/news-routing.module';
import { NewsEffects } from '@app/modules/news/effects/news.effects';
import { NewsDetailComponent } from '@app/modules/news/pages/detail/news-detail.component';
import { NewsOverviewComponent } from '@app/modules/news/pages/overview/news-overview.component';
import { newsFeatureKey, reducers } from '@app/modules/news/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
	NewsDetailFormComponent,
	NewsOverviewTableComponent,
	NewsDetailComponent,
	NewsOverviewComponent,
];

const IMPORTS = [
	SharedModule,
	NewsRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(newsFeatureKey, reducers),
	EffectsModule.forFeature([NewsEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class NewsModule {}