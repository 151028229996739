import { NgModule } from '@angular/core';
import { UserRoleEffects } from '@app/modules/user-roles/effects/user-role.effects';
import { UserRoleRoutingModule } from '@app/modules/user-roles/user-role-routing.module';
import { userRoleFeatureKey, reducers } from '@app/modules/user-roles/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { UserRoleDetailFormComponent } from '@app/modules/user-roles/components/user-role-detail-form/user-role-detail-form.component';
import { UserRoleOverviewTableComponent } from '@app/modules/user-roles/components/user-role-overview-table/user-role-overview-table.component';
import { UserRoleDetailComponent } from '@app/modules/user-roles/pages/detail/user-role-detail.component';
import { UserRoleOverviewComponent } from '@app/modules/user-roles/pages/overview/user-role-overview.component';

const COMPONENTS = [
	UserRoleDetailFormComponent,
    UserRoleOverviewTableComponent,
    UserRoleDetailComponent,
	UserRoleOverviewComponent,
];

const IMPORTS = [
	SharedModule,
	UserRoleRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(userRoleFeatureKey, reducers),
	EffectsModule.forFeature([UserRoleEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class UserRolesModule {}
