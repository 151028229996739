import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
  BlogCreateModel,
  BlogTableItemModel,
  BlogUpdateModel,
} from '@app/modules/blogs/models';
import { TableDataModel } from '@app/shared/models';
import { serializeContentBlocks } from '@app/shared/models/content';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private readonly endpoints = {
    get: 'Blog/:id',
    overview: 'Blog/GetOverview',
    update: 'Blog/Update',
    create: 'Blog/Create',
    delete: 'Blog/Delete?id=:id',
    changeOnline: 'Blog/ChangeOnline',
  };

  constructor(private readonly httpService: HttpService) {}

  getAll(
    sorting: TableSortingModel,
    pagination: TablePaginationModel
  ): Observable<TableDataModel<BlogTableItemModel>> {
    const paginationParams = parse(pagination, 'pagination');
    const sortingParams = parse(sorting, 'sorting');

    return this.httpService.get<any>(
      this.endpoints.overview + `?${paginationParams}&${sortingParams}`
    );
  }

  get(id: number): Observable<BlogUpdateModel> {
    return this.httpService.get<any>(
      this.endpoints.get.replace(':id', id.toString())
    );
  }

  update(request: BlogUpdateModel): Observable<BlogUpdateModel> {
    return this.httpService.put<any>(
      this.endpoints.update,
      serializeContentBlocks(request)
    );
  }

  create(request: BlogCreateModel): Observable<BlogUpdateModel> {
    return this.httpService.post<any>(
      this.endpoints.create,
      serializeContentBlocks(request)
    );
  }

  delete(id: number): Observable<any> {
    return this.httpService.delete<any>(
      this.endpoints.delete.replace(':id', id.toString())
    );
  }

  changeOnline(id: number, showOnline: boolean): Observable<any> {
    return this.httpService.put<any>(this.endpoints.changeOnline, {
      id,
      showOnline,
    });
  }
}
