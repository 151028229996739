import { Component } from '@angular/core';
import * as NewsActions from '@app/modules/news/actions/news-api.actions';
import { NewsChangeSequenceModel } from '@app/modules/news/models';
import * as News from '@app/modules/news/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
	selector: 'app-news-overview',
	templateUrl: './news-overview.component.html',
	styleUrls: ['./news-overview.component.less'],
})
export class NewsOverviewComponent {
	tableData$ = this.store.select(News.selectNewsOverviewState);

	ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

	constructor(private readonly store: Store) {}

	onDeleteConfirm(id: number): void {
		this.store.dispatch(NewsActions.Delete({ id: id }));
	}

	onChangeShowOnline(event: any): void {
		this.store.dispatch(
		NewsActions.UpdateShowOnline({
			id: event.id,
			showOnline: event.showOnline,
		})
		);
	}

	/**
	 * Trigger the loading of data whenever the table changes.
	 * The default pageSize, index and sort options are based on the TableStateModel.
	 * @param params
	 */
	onQueryParamsChange(params: NzTableQueryParams) {
		const { pageSize, pageIndex, sort } = params;
		const currentSort = sort.find((item) => item.value !== null);
		const sortField = (currentSort?.key) ?? null;
		const sortOrder = (currentSort?.value) ?? null;

		this.store.dispatch(
			NewsActions.FilterOverview({
				pagination: {
					page: pageIndex,
					pageSize,
				},
				sorting: {
					propertyName: sortField ?? '',
					order:  sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
				},
			})
		);
	}

	onResetOverview() {
        this.store.dispatch(NewsActions.ResetOverview());
    }

	onSequenceChange(request: NewsChangeSequenceModel) {
		this.store.dispatch(NewsActions.ChangeSequence(request));
	}
}