import * as RelationApiActions from '@app/modules/relation/actions/relation-api.actions';
import { RelationUpdateModel } from '@app/modules/relation/models';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
    relation: {} as RelationUpdateModel,
    loading: false,
    errors: {},
};

export interface State {
    relation: RelationUpdateModel;
    loading: boolean;
    errors: { [key: string]: string[] };
}

export const reducer = createReducer(
    initialState,
    on(RelationApiActions.Get, (state) => ({ ...state, loading: true })),
    on(RelationApiActions.GetSuccess, (state, relation) => ({
        ...state,
        relation,
        loading: false,
    })),
    on(RelationApiActions.GetError, (state) => ({ ...state, loading: false })),
);

export const getLoading = (state: State) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getRelation = (state: State) => state.relation;
export const getRelationId = (state: State) => state.relation.id
