import { ErrorResponse } from '@app/core/models';
import { LoginRequest } from '@app/modules/auth/models/login-request.model';
import { LoginResponse } from '@app/shared/models/auth/login-response.model';
import { UserModel } from '@app/shared/models/auth/user.model';
import { createAction, props } from '@ngrx/store';

export const Login = createAction('[Auth] Login', props<{ loginRequest: LoginRequest }>());
export const LoginError = createAction('[Auth] Login Error', props<{ response: ErrorResponse }>());
export const LoginSuccess = createAction('[Auth] Login Success', props<{ loginResponse: LoginResponse }>());
export const Logout = createAction('[Auth] Logout');
export const GetCurrentUser = createAction('[Auth] Get Current User');
export const GetCurrentUserSuccess = createAction('[Auth] Get Current User Success', props<{ user: UserModel }>());
export const GetCurrentUserError = createAction('[Auth] Get Current User Error');
export const LoginClear = createAction('[Auth] Login Clear');
export const RequestPasswordReset = createAction('[Auth] Request Password Reset', props<{ email: string }>());
export const RequestPasswordResetSuccess = createAction('[Auth] Request Password Reset Success');
export const RequestPasswordResetError = createAction('[Auth] Request Password Reset Error', props<{ response: ErrorResponse }>());
export const ResetPassword = createAction('[Auth] Reset Password', props<{ email: string, password: string; token: string }>());
export const ResetPasswordSuccess = createAction('[Auth] Reset Password Success');
export const ResetPasswordError = createAction('[Auth] Reset Password Error', props<{ response: ErrorResponse }>());
export const ResetPasswordClear = createAction('[Auth] Reset Password Clear');