import { Component } from '@angular/core';
import * as FAQActions from '@app/modules/faq/actions/faq.actions';
import { FAQCreateModel, FAQUpdateModel } from '@app/modules/faq/models';
import * as FAQ from '@app/modules/faq/reducers';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-faq-detail',
	templateUrl: './faq-detail.component.html',
	styleUrls: ['./faq-detail.component.less'],
})
export class FAQDetailComponent {
	loading$ = this.store.select(FAQ.selectDetailLoading);
	errors$ = this.store.select(FAQ.selectDetailErrors);
	faq$ = this.store.select(FAQ.selectDetailFAQ);
	faqCategories$ = this.store.select(FAQ.selectDetailFAQCategories);
	faqCategoryId$ = this.store.select(FAQ.selectDetailFAQCategoryId);

	constructor(private readonly store: Store<State>) {
		this.store.dispatch(FAQActions.GetFAQCategories());
	}

	submit(faq: FAQCreateModel | FAQUpdateModel) {
		this.store.dispatch(faq.hasOwnProperty('id') ? FAQActions.Update(faq as FAQUpdateModel) : FAQActions.Create(faq));
	}
}
