import { Component, OnInit } from '@angular/core';
import * as Review from '@app/modules/review/reducers';
import { Store } from '@ngrx/store';
import * as ReviewApiActions from '@app/modules/review/actions/review-api.actions';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ReviewChangeSequenceModel } from '@app/modules/review/models';
import { ClaimTypes, ClaimValues, CompareType } from '@app/shared/models/common/constants';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';

@Component({
    selector: 'app-review-overview',
    templateUrl: './review-overview.component.html',
    styleUrls: ['./review-overview.component.less']
})
export class ReviewOverviewComponent implements OnInit {
    tableData$ = this.store.select(Review.selectReviewOverviewState);

	activityTypes$ = this.store.select(Review.selectActivityTypeOverviewList);	
	selectedActivityTypes: KeyValueModel | null = null;

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    hasSearchValue = false;
    hasActivityType = false;
    isDraggingEnabled = false;

    constructor(private readonly store: Store) { }

    ngOnInit(){
        this.store.dispatch(ReviewApiActions.GetActivityTypes());
    }

    onDeleteConfirm(id: number): void {
        this.store.dispatch(ReviewApiActions.Delete({ id: id }))
    }

	search(value: string): void {
        this.hasSearchValue = value  !== '';

        this.store.dispatch(ReviewApiActions.SetFilter({filter: this.getFilter(value) }));
		this.store.dispatch(ReviewApiActions.FilterOverview({
			pagination: { page: 1, pageSize: 10 }
		}));
	}

    onActivityTypeChange(activityType: KeyValueModel) {
        this.hasActivityType = activityType !== null;

		this.store.dispatch(ReviewApiActions.SetActivityTypeId({ activityTypeId: activityType?.key }));
		this.store.dispatch(ReviewApiActions.FilterOverview({
				pagination: { page: 1, pageSize: 10 },
			})
		);
	}

    ondraggingEnabledChange(event: boolean) {
        this.isDraggingEnabled = event;
    }

    /**
     * Trigger the loading of data whenever the table changes.
     * The default pageSize, index and sort options are based on the TableStateModel.
     * @param params 
     */
    onQueryParamsChange(params: NzTableQueryParams) {
        if(this.isDraggingEnabled){
            return;
        }

        const { pageSize, pageIndex, sort } = params;
        const currentSort = sort.find(item => item.value !== null);
        let sortField = (currentSort?.key) ?? null;
        const sortOrder = (currentSort?.value) ?? null;

        sortField = sortField == 'text' ? 'shortText' : sortField;

        this.store.dispatch(ReviewApiActions.FilterOverview({
            pagination: {
                page: pageIndex,
                pageSize
            },
            sorting: {
                propertyName: sortField ?? '',
                order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : ''
            },
            filter: [],
        }));
    }

    onResetOverview() {
        this.store.dispatch(ReviewApiActions.ResetOverview());
    }

    onSequenceChange(request: ReviewChangeSequenceModel) {
        this.store.dispatch(ReviewApiActions.ChangeSequence(request))
    }

    onChangeOnline(event: { id: number; online: boolean; }): void {
        this.store.dispatch(ReviewApiActions.UpdateOnline({ id: event.id, online: event.online }));
    }

    getFilter(searchValue: string): TableFilterModel[] {
        return [{
            column: 'name',
            value: searchValue,
            operand: CompareType.ContainsString
        },
        {
            column: 'activityType.name',
            value: searchValue,
            operand: CompareType.ContainsString
        },
        {
            column: 'longText',
            value: searchValue,
            operand: CompareType.ContainsString
        },
        {
            column: 'shortText',
            value: searchValue,
            operand: CompareType.ContainsString
        },
        {
            column: 'creationDate',
            value: searchValue,
            operand: CompareType.EqualTo
        }]
    }
}
