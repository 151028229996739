import { Component } from '@angular/core';
import * as ActivityScheduleActions from '@app/mobile/modules/activity-schedule/actions/activity-schedule.actions';
import { ActivityScheduleUpdateModel } from '@app/mobile/modules/activity-schedule/models';
import * as ActivitySchedule from '@app/mobile/modules/activity-schedule/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-activity-schedule-detail-page',
  templateUrl: './activity-schedule-detail-page.component.html',
  styleUrls: ['./activity-schedule-detail-page.component.less'],
})
export class ActivityScheduleDetailPageComponent {
  date = new Date();
  activitySchedule$ = this.store.select(
    ActivitySchedule.selectActivityScheduleDetail
  );

  constructor(private readonly store: Store) {}

  onSave(activitySchedule: ActivityScheduleUpdateModel): void {
    this.store.dispatch(ActivityScheduleActions.Update(activitySchedule));
  }
}
