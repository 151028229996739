import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as ActivityScheduleActions from '@app/mobile/modules/activity-schedule/actions/activity-schedule.actions';
import { ActivityScheduleService } from '@app/mobile/modules/activity-schedule/services/activity-schedule.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const activityScheduleService = inject(ActivityScheduleService);
  const router = inject(Router);
  const store = inject(Store<State>);

  return activityScheduleService.getActivitySchedule(route.params['id']).pipe(
    map((activitySchedule) =>
      ActivityScheduleActions.GetSuccess(activitySchedule)
    ),
    tap((action: any) => store.dispatch(action)),
    map((activitySchedule) => !!activitySchedule),
    catchError(() => {
      return router.navigate(['mobiel/not-found']);
    })
  );
};

export const canActivateChild: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
