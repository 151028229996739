import * as RelationApiActions from '@app/modules/relation/actions/relation-api.actions';
import { RelationTableItemModel } from '@app/modules/relation/models/relation-table.model';

import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

let initialState: State = new TableOverviewModel<RelationTableItemModel>('Id');
initialState = { ...initialState, pagination: { ...initialState.pagination, pageSize: 100 } };

export interface State extends TableStateModel<RelationTableItemModel> {}

export const reducer = createReducer(
    initialState,
    on(RelationApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(RelationApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(RelationApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(RelationApiActions.FilterOverview, (state, { sorting, pagination }) => ({
        ...state,
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: { ...state.pagination, page: pagination.page, pageSize: pagination.pageSize },
    })),
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
