import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@app/shared/models/common/constants';
import { isFormValid } from '@app/shared/utilities/validation.utilities';

@Component({
	selector: 'app-password-forget-form',
	templateUrl: './password-forget-form.component.html',
	styleUrls: ['./password-forget-form.component.less'],
})
export class PasswordForgetFormComponent {
	@Output() requestResetPassword = new EventEmitter<string>();

	formGroup = new FormGroup({
		email: new FormControl<string | null>(null, [Validators.required, Validators.pattern(Constants.EmailExpression)]),
	});
	submitted = false;

	onSubmit() {
		if (isFormValid(this.formGroup)) {
			this.requestResetPassword.emit(this.formGroup.controls.email.value!);
			this.submitted = true;
		}
	}
}
