import { Component, EventEmitter, Output } from "@angular/core";
import { ClaimTypes, ClaimValues } from "@app/shared/models/common/constants";

@Component({
    selector: 'app-toolbox-sidebar',
    templateUrl: './toolbox-sidebar.component.html',
    styleUrls: ['./toolbox-sidebar.component.less'],
})

export class ToolboxSidebarComponent {
    @Output() resendWelcomeMail = new EventEmitter<void>();
    @Output() loginAsRelation = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
}
