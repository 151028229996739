import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as noteOverview from './note-overview.reducer'

export const noteFeatureKey = 'note'

export interface NoteState {
    noteOverview: noteOverview.State
}

export interface State {
    note: NoteState
}

const map: ActionReducerMap<NoteState, Action> = {
    noteOverview: noteOverview.reducer
}

export const reducers = (state: NoteState, action: Action) => combineReducers(map)(state, action);

const selectNoteState = createFeatureSelector<NoteState>(noteFeatureKey);

export const selectState = createSelector(selectNoteState, state => state)
export const selectNoteOverviewState = createSelector(selectState, state => state.noteOverview)

export const selectLoading = createSelector(selectNoteOverviewState, noteOverview.getLoading)
export const selectErrors = createSelector(selectNoteOverviewState, noteOverview.getErrors)
export const selectNote = createSelector(selectNoteOverviewState, noteOverview.getCurrentNote)

export const selectNoteList = createSelector(selectNoteOverviewState, noteOverview.getNotes)
export const selectNoteType = createSelector(selectNoteOverviewState, noteOverview.getNoteType)
export const selectNoteTypeId = createSelector(selectNoteOverviewState, noteOverview.getNoteTypeId)