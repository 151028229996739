import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Asset } from "@app/shared/models/assetmanager/asset-record.model";

export class AssetFormGroup extends FormGroup {
    constructor(asset?: Asset, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super({
            id: new FormControl<number>(0),
            url: new FormControl<string>('', [Validators.maxLength(100)]),
            name: new FormControl<string>('', [Validators.maxLength(100)]),
        }, validatorOrOpts, asyncValidator);

        if (asset) {
            this.patchValue(asset);
        }
    }
}
