<h5 class="form-section__title">{{ 'activity.header.basis' | translate }}</h5>
<ul class="description-inline">
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.activity-type-name' | translate }}</div>
        <div class="description-inline__value">{{ activity.activityTypeName }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.name' | translate }}</div>
        <div class="description-inline__value">{{ activity.name }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.status' | translate }}</div>
        <div class="description-inline__value">{{ activity.status ? ('common.active' | translate) : ('common.inactive' | translate)  }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.planning-status' | translate }}</div>
        <div class="description-inline__value">{{ (statusLabel[activity.planningStatus] | translate) }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.location' | translate }}</div>
        <div class="description-inline__value">
            <span *ngIf="activity.activityLocation">
                {{ activity.activityLocation!.address }}, {{ activity.activityLocation!.zipCode }} {{ 'activity.card.in' | translate }} {{ activity.activityLocation!.city }}
            </span>
        </div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.attachments' | translate }}</div>
        <div class="description-inline__value">{{ totalAttachments() }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.course-leaders' | translate }}</div>
        <div class="description-inline__value"><!--TODO: Course leader names--></div>
    </li>
</ul>

<h5 class="form-section__title">{{ 'activity.header.date-settings' | translate }}</h5>
<ul class="description-inline">
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.start-date' | translate }}</div>
        <div class="description-inline__value">{{ activity.startDate | date : 'longDate' }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.end-date' | translate }}</div>
        <div class="description-inline__value">{{ activity.endDate | date : 'longDate' }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.evaluation-date' | translate }}</div>
        <div class="description-inline__value">{{ activity.evaluationDate | date : 'longDate' }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.cancelled-date' | translate }}</div>
        <div class="description-inline__value">{{ activity.cancelledDate }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.review-date' | translate }}</div>
        <div class="description-inline__value">{{ activity.reviewDate | date : 'longDate' }}</div>
    </li>

</ul>

<h5 class="form-section__title">{{ 'activity.header.visibility-site' | translate }}</h5>
<ul class="description-inline">
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.visibility' | translate }}</div>
        <div class="description-inline__value">
            <span *ngIf="activity.startDate || activity.endDate">
                {{ (activity.startDate | date : 'longDate') ?? '...' }} {{ 'activity.card.through' | translate }} {{ (activity.endDate | date : 'longDate') ?? '...' }}
            </span>
        </div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.online' | translate }}</div>
        <div class="description-inline__value">{{ activity.online ? ('common.yes' | translate) : ('common.no' | translate) }}</div>
    </li>
</ul>

<h5 class="form-section__title">{{ 'activity.header.price' | translate }}</h5>
<ul class="description-inline">
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.display-prices' | translate }}</div>
        <div class="description-inline__value">{{ activity.displayPrices ? ('common.yes' | translate) : ('common.no' | translate) }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.price-including-vat' | translate }}</div>
        <div class="description-inline__value">{{ activity.priceIncludingVat | currency }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.price-second-including-vat' | translate }}</div>
        <div class="description-inline__value">{{ activity.priceSecondIncludingVat | currency }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.vat-percentage' | translate }}</div>
        <div class="description-inline__value">{{ activity.vatPercentage }}%</div>
    </li>

</ul>

<h5 class="form-section__title">{{ 'activity.header.extra-information' | translate }}</h5>
<ul class="description-inline">
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.start-time' | translate }}</div>
        <div class="description-inline__value">{{ this.parseTimeToDate(activity.startTime) | date : 'shortTime' }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.end-time' | translate }}</div>
        <div class="description-inline__value">{{ this.parseTimeToDate(activity.endTime) | date : 'shortTime' }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.include-lunch' | translate }}</div>
        <div class="description-inline__value">{{ activity.includesLunch ? ('common.yes' | translate) : ('common.no' | translate) }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.attachment-one' | translate }}</div>
        <div class="description-inline__value">
            <a v-if="activity.firstAttachment" href={{activity.firstAttachment?.url}}>
                {{ activity.firstAttachment?.name }}
            </a>
        </div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.attachment-two' | translate }}</div>
        <div class="description-inline__value">
            <a if="activity.secondAttachment" href={{activity.secondAttachment?.url}}>
                {{ activity.secondAttachment?.name }}
            </a>
        </div>
    </li>
</ul>

<h5 class="form-section__title">{{ 'activity.header.theme' | translate }}</h5>
<ul class="description-inline">
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.theme-name' | translate }}</div>
        <div class="description-inline__value">{{ activity.themeName }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.theme-code' | translate }}</div>
        <div class="description-inline__value">{{ activity.themeCode }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.theme-description' | translate }}</div>
        <div class="description-inline__value">{{ activity.themeDescription }}</div>
    </li>
</ul>

<h5 class="form-section__title">{{ 'activity.header.availability' | translate }}</h5>
<ul class="description-inline">
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.minimum-participants' | translate }}</div>
        <div class="description-inline__value">{{ activity.minimumParticipants }}</div>
    </li>
    <li class="description-inline__item">
        <div class="description-inline__label">{{ 'activity.fields.maximum-participants' | translate }}</div>
        <div class="description-inline__value">{{ activity.maximumParticipants }}</div>
    </li>
</ul>
<div nz-row class="mb-0">
    <div nz-col nzFlex="0 0 130px"></div>
    <div nz-col nzSpan="4">
        <strong>{{ 'activity.fields.available' | translate }}</strong>
    </div>
    <div nz-col nzSpan="4">
        <strong>{{ 'activity.fields.waitinglist' | translate }}</strong>
    </div>
</div>
<div nz-row class="icon-plus-min align-center">
    <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
        {{ 'activity.fields.student' | translate }}
    </div>
    <div nz-col nzSpan="4">
        <div class="input-counter">
            <button
                *ngIf="
                    this.participants?.availableStudent !== 0 &&
                    this.participants?.availableStudent !== null
                "
                class="input-counter-button"
                (click)="DecrementAvailablePlaces(activity!.id, roleType.Student)">
                <i class="fa-solid fa-minus"></i>
            </button>
            <div class="input-counter-label">{{ this.participants?.availableStudent }}</div>
            <button
                class="input-counter-button"
                (click)="IncrementAvailablePlaces(activity!.id, roleType.Student)">
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>
    </div>
    <div nz-col nzSpan="4">
        <p class="p-l-25">{{ activity.waitlistStudent }}</p>
    </div>
</div>
<div nz-row class="icon-plus-min align-center">
    <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
        {{ 'activity.fields.student-second' | translate }}
    </div>
    <div nz-col nzSpan="4">
        <div class="input-counter">
            <button
                *ngIf="
                    this.participants?.availableStudentSecondTime !== 0 &&
                    this.participants?.availableStudentSecondTime !== null
                "
                class="input-counter-button"
                (click)="DecrementAvailablePlaces(activity!.id, roleType.StudentSecondTime)">
                <i class="fa-solid fa-minus"></i>
            </button>
            <div class="input-counter-label">{{ this.participants?.availableStudentSecondTime }}</div>
            <button
                class="input-counter-button"
                (click)="IncrementAvailablePlaces(activity!.id, roleType.StudentSecondTime)">
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>
    </div>
    <div nz-col nzSpan="4">
        <p class="p-l-25">{{ activity.waitlistStudentSecondTime }}</p>
    </div>
</div>
<div nz-row class="icon-plus-min align-center">
    <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
        {{ 'activity.fields.catch-up' | translate }}
    </div>
    <div nz-col nzSpan="4">
        <div class="input-counter">
            <button
                *ngIf="
                    this.participants?.availableCatchUp !== 0 &&
                    this.participants?.availableCatchUp !== null
                "
                class="input-counter-button"
                (click)="DecrementAvailablePlaces(activity!.id, roleType.CatchUp)">
                <i class="fa-solid fa-minus"></i>
            </button>
            <div class="input-counter-label">{{ this.participants?.availableCatchUp }}</div>
            <button
                class="input-counter-button"
                (click)="IncrementAvailablePlaces(activity!.id, roleType.CatchUp)">
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>
    </div>
    <div nz-col nzSpan="4">
        <p>{{ activity.waitlistCatchUp }}</p>
    </div>
</div>
<div nz-row class="icon-plus-min align-center">
    <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
        {{ 'activity.fields.guest' | translate }}
    </div>
    <div nz-col nzSpan="4">
        <div class="input-counter">
            <button
                *ngIf="
                    this.participants?.availableGuest !== 0 &&
                    this.participants?.availableGuest !== null
                "
                class="input-counter-button"
                (click)="DecrementAvailablePlaces(activity!.id, roleType.Guest)">
                <i class="fa-solid fa-minus"></i>
            </button>
            <div class="input-counter-label">{{ this.participants?.availableGuest }}</div>
            <button
                class="input-counter-button"
                (click)="IncrementAvailablePlaces(activity!.id, roleType.Guest)">
                <i class="fa-solid fa-plus"></i>
            </button>
        </div>
    </div>
    <div nz-col nzSpan="4">
        <p>{{ activity.waitlistGuest }}</p>
    </div>
</div>
