import * as PageActions from "@app/modules/page/actions/page-api.actions"
import {
  PageTableItemModel,
  PageUpdateModel
} from "@app/modules/page/models";
import { createReducer, on } from "@ngrx/store";
import {TableDataModel} from "@app/shared/models";
import { applySortingOrDefaultTableSortingModel } from "@app/shared/utilities/overview-reducer.utilities"
import { SlugStatus } from "@app/modules/page/enums/slug-status";

export const initialState: State = {
  page: null,
  loading: false,
  errors: {},
  pageRedirectExists: SlugStatus.NO_CONFLICTS,
  childPages: {
    list: [],
    pagination: { page: 1, pageSize: 10 },
    sorting: { propertyName: 'sequenceNumber', order: 'asc' },
    filter: [],
  }
}

export interface State {
  page: PageUpdateModel | null,
  pageRedirectExists: SlugStatus;
  loading: boolean,
  errors: { [key: string]: string[] },
  childPages: TableDataModel<PageTableItemModel>
}

export const reducer = createReducer(initialState,
  on(PageActions.Clear, () => ({ ...initialState })),

  on(PageActions.Get, (state) => ({ ...state, loading: true })),
  on(PageActions.GetSuccess, (state, page) => ({ ...state, page, loading: false })),
  on(PageActions.NewSlugAsRedirectExistsSuccess, (state, response ) => ({ ...state, pageRedirectExists: response.response})),

	on(PageActions.ResetRedirectExists, (state) => ({ ...state, pageRedirectExists: initialState.pageRedirectExists })),

  on(PageActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

  on(PageActions.GetChildPages, (state) => ({ ...state, loading: true })),
  on(PageActions.GetChildPagesSuccess, (state, childPages) => ({ ...state, childPages, loading: false })),
  on(PageActions.GetChildPagesError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
  on(PageActions.ResetChildPages,  (state) => ({ ...state, childPages: initialState.childPages })),

  on(PageActions.ChildPagesFilterOverview, (state, { sorting, pagination }) => ({ ...state, childPages: { list: [], filter: [], pagination, sorting: applySortingOrDefaultTableSortingModel(initialState.childPages.sorting, sorting) }, loading: true })),

  on(PageActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
  on(PageActions.UpdateSuccess, (state, page) => ({ ...state, page, loading: false })),
  on(PageActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

  on(PageActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
  on(PageActions.CreateSuccess, (state, page) => ({ ...state, page, loading: false })),
  on(PageActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getPage = (state: State) => state.page;
export const getChildPagesData = (state: State) => state.childPages;
export const getRedirectExists = (state: State) => state.pageRedirectExists;
