<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>{{ (!!formGroup.get('id')?.value ? "activity-locations.detail.edit-title" : "activity-locations.detail.add-title") | translate }}</h4>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "common.location-name" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="locationNameValidationTemplate">
                <input nz-input [formControl]="this.formGroup.controls.locationName" />

                <ng-template #locationNameValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'locationName'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "common.calendar-name" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="calendarNameValidationTemplate">
                <input nz-input [formControl]="this.formGroup.controls.calendarName" />

                <ng-template #calendarNameValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'calendarName'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "common.address" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="addressNameValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.address"/>

                <ng-template #addressNameValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'address'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "common.city" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="cityValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.city"/>

                <ng-template #cityValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'city'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "common.zip-code" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="zipCodeValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.zipCode"/>

                <ng-template #zipCodeValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'zipCode'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24">{{ "common.park-instruction" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <app-editor *ngIf="readonly != undefined" [control]="formGroup.controls.parkInstruction" [isDraggable]="false" [isReadOnly]="readonly"></app-editor>

                <app-input-validation [control]="formGroup.controls.parkInstruction" [field]="'parkInstruction'" [errors]="errors"></app-input-validation>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24">{{ "common.lunch" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <app-editor *ngIf="readonly != undefined" [control]="formGroup.controls.lunch" [isDraggable]="false" [isReadOnly]="readonly"></app-editor>

                <app-input-validation [control]="formGroup.controls.lunch" [field]="'lunch'" [errors]="errors"></app-input-validation>
            </nz-form-control>
        </nz-form-item>
        <div class="button-container">
            <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
                {{ "common.save" | translate }}
            </button>
        </div>
    </nz-card>
</form>