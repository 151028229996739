import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as ActivityRelationApiActions from '@app/modules/activity-relation/actions/activity-relation-api.actions';
import { ApplicationType } from '@app/modules/activity-relation/enum/application-type.enum';
import * as ActivityRelation from '@app/modules/activity-relation/reducers';
import * as ActivityApiActions from '@app/modules/activity/actions/activity-api.actions';
import { UpdateAvailableParticipantsModel } from '@app/modules/activity/models/update-activity-available-participants.model';
import * as Activity from '@app/modules/activity/reducers';
import * as NoteApiActions from '@app/modules/note/actions/note-api.actions';
import { NoteType } from '@app/modules/note/enum/note-type.enum';
import * as Note from '@app/modules/note/reducers';
import { CompareType } from '@app/shared/models/common/constants';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { Store } from '@ngrx/store';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';

@Component({
    selector: 'app-activity-view',
    templateUrl: './activity-view.component.html',
    styleUrls: ['./activity-view.component.less'],
})
export class ActivityViewComponent {
    loading$ = this.store.select(Activity.selectViewLoading);
    errors$ = this.store.select(Activity.selectViewErrors);
    activity$ = this.store.select(Activity.selectViewActivity);
    availableParticipants$ = this.store.select(Activity.selectAvailableSpots);
    acitivtyRelations$ = this.store.select(ActivityRelation.selectActivityRelationOverviewStateList);
    acitivtyRelationsWaitlist$ = this.store.select(ActivityRelation.selectActivityRelationWaitlistOverviewStateList);
    backgroundList$ = this.store.select(ActivityRelation.selectBackgroundList);
    relationList$ = this.store.select(Activity.selectRelations);
    activityRelation$ = this.store.select(ActivityRelation.selectActivityRelation);
    notes$ = this.store.select(Note.selectNoteList);
    note$ = this.store.select(Note.selectNote);

    noteErrors$ = this.store.select(Note.selectErrors);
    noteLoading$ = this.store.select(Note.selectLoading);

    sort: TableSortingModel;
    compareType = CompareType;

    activityId: number | null = null;

    constructor(private readonly store: Store) {
        this.activity$.pipe(takeUntilDestroyed()).subscribe((activity) => (this.activityId = activity.id));

        this.store.dispatch(ActivityRelationApiActions.GetBackgroundList());
        this.store.dispatch(
            NoteApiActions.SetNoteType({ noteType: NoteType.ActivityWaitlist, noteTypeId: this.activityId }),
        );
        this.store.dispatch(NoteApiActions.GetOverview());
        this.getParticipants();
        this.getWaitlist();
    }

    updateAvailableParticipants(update: UpdateAvailableParticipantsModel) {
        this.store.dispatch(ActivityApiActions.UpdateParticipants(update));
    }

    addReservation() {
        this.store.dispatch(ActivityRelationApiActions.AddReservation());
    }

    deleteParticipant(event: { id: number; isReservation: boolean }) {
        if (event.isReservation) {
            this.store.dispatch(ActivityRelationApiActions.DeleteReservation({ id: event.id }));
        } else {
            this.store.dispatch(ActivityRelationApiActions.Delete({ id: event.id }));
        }
    }

    getParticipants() {
        const newFilter = [
            { column: 'ApplicationType', value: ApplicationType.Reservation.toString(), operand: CompareType.EqualTo },
            { column: 'ApplicationType', value: ApplicationType.Normal.toString(), operand: CompareType.EqualTo },
        ] as TableFilterModel[];
        this.store.dispatch(
            ActivityRelationApiActions.FilterOverview({
                activityId: this.activityId,
                pagination: {
                    page: 1,
                    pageSize: -1,
                },
                sorting: {
                    propertyName: 'Role',
                    order: 'ASC',
                },
                filter: newFilter,
                thenBy: [{ propertyName: 'CreatedOn', order: 'ASC' }],
            }),
        );
    }

    getWaitlist() {
        const newFilter = {
            column: 'ApplicationType',
            value: ApplicationType.Waitlist.toString(),
            operand: CompareType.EqualTo,
        } as TableFilterModel;
        this.store.dispatch(
            ActivityRelationApiActions.FilterWaitlistOverview({
                activityId: this.activityId,
                pagination: {
                    page: 1,
                    pageSize: -1,
                },
                sorting: {
                    propertyName: 'Role',
                    order: 'ASC',
                },
                filter: [newFilter] as TableFilterModel[],
                thenBy: [{ propertyName: 'CreatedOn', order: 'ASC' }],
            }),
        );
    }

    searchRelation(event: any) {
        this.store.dispatch(ActivityApiActions.GetRelations(event));
    }

    reset() {
        this.store.dispatch(ActivityApiActions.EmptyRelations());
    }

    createActivityRelation(event: any) {
        Object.keys(event).includes('id')
            ? this.store.dispatch(ActivityRelationApiActions.Update(event))
            : this.store.dispatch(ActivityRelationApiActions.Create(event));
    }

    getRelation(id: number) {
        this.store.dispatch(ActivityRelationApiActions.GetActivityRelation({ id }));
    }

    createNote(event: any) {
        this.store.dispatch(
            NoteApiActions.Create({ ...event, activityId: this.activityId, noteType: NoteType.ActivityWaitlist }),
        );
    }

    editNote(event: any) {
        this.store.dispatch(
            NoteApiActions.Update({
                data: {
                    ...event,
                    noteMessageEdit: event.noteMessageEdit,
                    activityId: this.activityId,
                    noteType: NoteType.ActivityWaitlist,
                },
            }),
        );
    }

    setNoteImportant(event: any) {
        this.store.dispatch(NoteApiActions.SetImportant({ noteId: event.noteId }));
    }

    deleteNote(event: any) {
        this.store.dispatch(NoteApiActions.Delete({ noteId: event.noteId }));
    }

    noteSidebarEnabled = false;
    tabSize = 24;
    selectedTab(selectedTabEvent: NzTabChangeEvent) {
        if (selectedTabEvent['index'] === 1) {
            this.noteSidebarEnabled = true;
            this.tabSize = 18;
        } else {
            this.tabSize = 24;
            this.noteSidebarEnabled = false;
        }
    }
}
