import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RelationService {
	private readonly endpoints = {
		getAll: 'Relation/GetAll',
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(): Observable<KeyValueModel[]> {
		return this.httpService.get<KeyValueModel[]>(this.endpoints.getAll);
	}
}
