<form nz-form [formGroup]="formGroup">
  <nz-card>
    <h4>{{ "claim-categories.detail.edit-title" | translate }}</h4>
    <p>{{ getEditInfo() }}</p>
    <nz-checkbox-wrapper style="width: 100%; padding: 1rem">
      <nz-list nzItemLayout="horizontal" nzSplit=false>
        @for (role of claimCategory?.roles; track role){
        <nz-list-item>
          <label
            nz-checkbox
            [nzValue]="role.active"
            [formControlName]="role.id"
            >{{ role.roleName | titlecase }}</label
          >
        </nz-list-item>
        }
      </nz-list>
    </nz-checkbox-wrapper>
    <div class="button-container">
      <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
        {{ "common.save" | translate }}
      </button>
    </div>
  </nz-card>
</form>