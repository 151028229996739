import { RequestOptions } from '@app/core/models/request-options.model';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CustomRequestOptions implements RequestOptions {
    headers = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
    };
    observe = 'body';
    responseType = 'json';
    withCredentials = false;
    enableLoadingIcon = false;
}
