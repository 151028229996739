import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { FAQCategoryService } from '@app/modules/faq-categories/services/faq-category.service';
import * as FAQCategoryActions from '@app/modules/faq-categories/actions/faq-category-api.actions';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const faqCategoryService = inject(FAQCategoryService);
	const router = inject(Router);
	const store = inject(Store<State>);

	return faqCategoryService.get(route.params['id']).pipe(
		map((faqCategory) => FAQCategoryActions.GetSuccess(faqCategory)),
		tap((action: any) => store.dispatch(action)),
		map((faqCategory) => !!faqCategory),
		catchError(() => {
			return router.navigate(['/not-found']);
		})
	);
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);
