import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import * as NoteActions from '@app/modules/note/actions/note-api.actions';
import { EditNoteModalComponent } from '@app/modules/note/components/edit-note-modal/edit-note-modal.component';
import { NoteItemModel } from '@app/modules/note/models/note-overview.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Actions, ofType } from '@ngrx/effects';
import { NzModalService } from 'ng-zorro-antd/modal';
import { firstValueFrom, Observable, of, switchMap, take } from 'rxjs';

@Component({
    selector: 'app-note-entry',
    templateUrl: './note-entry.component.html',
})
export class NoteEntryComponent {
    @Input({ required: true }) note!: NoteItemModel;
    @Input({ required: true }) loading$: Observable<boolean>;
    @Input({ required: true }) errors$: Observable<{ [key: string]: string[] }>;

    @Output() setNoteImportant = new EventEmitter<{ noteId: number }>();
    @Output() doEditNote = new EventEmitter<{
        id: number;
        noteMessageEdit: string;
        editReason: string;
    }>();
    @Output() doDeleteNote = new EventEmitter<{ noteId: number }>();

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    destroyRef = inject(DestroyRef);
    constructor(private readonly modal: NzModalService, private readonly actions$: Actions) {}

    setImportant(noteId: number | string) {
        this.setNoteImportant.emit({ noteId: Number(noteId) });
    }

    deleteNote(noteId: number | string) {
        this.doDeleteNote.emit({ noteId: Number(noteId) });
    }

    createEditNoteModal(noteId: number | string, noteMessage: string, editReason: string): void {
        this.modal.create({
            nzContent: EditNoteModalComponent,
            nzMaskClosable: false,
            nzClosable: false,
            nzData: {
                noteMessage,
                editReason,
                errors$: this.errors$,
            },
            nzOnOk: (data) => {
                this.doEditNote.emit({
                    id: Number(noteId),
                    noteMessageEdit: data.formGroup.controls.noteMessageEdit.value,
                    editReason: data.formGroup.controls.editReason.value,
                });

                return firstValueFrom(
                    this.actions$.pipe(
                        ofType(NoteActions.UpdateSuccess, NoteActions.UpdateError),
                        take(1),
                        switchMap((x) => of(x.type === NoteActions.UpdateSuccess.type)),
                    ),
                );
            },
        });
    }
}
