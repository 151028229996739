import { ErrorResponse } from "@app/core/models/error-response.model";
import { UserRoleTableItemModel } from "@app/modules/user-roles/models";
import { UserType } from "@app/modules/users/enums/user-type";
import { UserCreateModel, UserTableItemModel, UserUpdateModel } from "@app/modules/users/models";
import { UserExistsModel } from "@app/modules/users/models/user-exists.model";
import { UserSignatureModel } from "@app/modules/users/models/user-signature.model";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";


export const GetOverview = createAction("[User] GetOverview");
export const GetOverviewError = createAction("[User] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[User] GetOverviewSuccess", props<TableDataModel<UserTableItemModel>>());

export const FilterOverview = createAction("[User] FilterOverview", props<{ sorting?: TableSortingModel, userTypes: UserType[], pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[User] Clear");
export const ResetUserExists = createAction("[User] ResetUserExists");

export const GetUserRoles = createAction("[User] GetUserRoles");
export const GetUserRolesSuccess = createAction("[User] GetUserRolesSuccess", props<{ userRoles: UserRoleTableItemModel[] }>());

export const Get = createAction("[User] Get", props<{ id: string }>());
export const GetError = createAction("[User] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[User] GetSuccess", props<UserUpdateModel>());

export const UserExists = createAction("[User] UserExists", props<UserCreateModel>());
export const UserExistsError = createAction("[User] UserExistsError", props<{ response: ErrorResponse }>());
export const UserExistsSuccess = createAction("[User] UserExistsSuccess", props<{ response: UserExistsModel }>());

export const Update = createAction("[User] Update", props<UserUpdateModel>());
export const UpdateError = createAction("[User] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[User] UpdateSuccess", props<UserUpdateModel>());

export const UpdateActive = createAction("[User] UpdateActive", props<{ id: string, active: boolean }>());
export const UpdateActiveError = createAction("[User] UpdateActiveError", props<{ response: ErrorResponse }>());
export const UpdateActiveSuccess = createAction("[User] UpdateActiveSuccess", props<UserUpdateModel>());

export const Create = createAction("[User] Create", props<UserCreateModel>());
export const CreateError = createAction("[User] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[User] CreateSuccess", props<UserUpdateModel>());

export const Delete = createAction("[User] Delete", props<{ id: string }>());
export const DeleteError = createAction("[User] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[User] DeleteSuccess");

export const SetUserTypes = createAction("[User] SetUserTypes", props<{ userTypes: UserType[]}>());

export const DownloadSignature = createAction("[User] DownloadSignature", props<UserSignatureModel>());
export const DownloadSignatureError = createAction("[User] DownloadSignatureError", props<{ response: ErrorResponse }>());
export const DownloadSignatureSuccess = createAction("[User] DownloadSignatureSuccess", props<{ name: string, blob: Blob }>());