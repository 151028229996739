import * as RedirectOverview from './redirect-overview.reducer';
import * as RedirectDetail from './redirect-detail.reducer';
import { ActionReducerMap, Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

export const redirectFeatureKey = 'redirect';

export interface RedirectState {
    redirectOverview: RedirectOverview.State,
    redirectDetail: RedirectDetail.State,
}

export interface State {
    redirect: RedirectState
}

const map: ActionReducerMap<RedirectState> = {
    redirectOverview: RedirectOverview.reducer,
    redirectDetail: RedirectDetail.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: RedirectState, action: Action) => combineReducers(map)(state, action);

const selectRedirectState = createFeatureSelector<RedirectState>(redirectFeatureKey);

export const selectState = createSelector(selectRedirectState, state => state)
export const selectRedirectOverviewState = createSelector(selectState, state => state.redirectOverview)

export const selectRedirectOverviewLoading = createSelector(selectRedirectOverviewState, RedirectOverview.getLoading)
export const selectRedirectOverviewList = createSelector(selectRedirectOverviewState, RedirectOverview.getList)
export const selectRedirectOverviewPagination = createSelector(selectRedirectOverviewState, RedirectOverview.getPagination)
export const selectRedirectOverviewSorting = createSelector(selectRedirectOverviewState, RedirectOverview.getSorting)

export const selectRedirectDetailState = createSelector(selectState, state => state.redirectDetail);

export const selectDetailLoading = createSelector(selectRedirectDetailState, RedirectDetail.getLoading);
export const selectDetailRedirect = createSelector(selectRedirectDetailState, RedirectDetail.getRedirect);
export const selectDetailErrors = createSelector(selectRedirectDetailState, RedirectDetail.getErrors);

export const selectDetailSlugExists = createSelector(selectRedirectDetailState, RedirectDetail.getSlugExists);