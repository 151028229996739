<form nz-form [formGroup]="formGroup">
  <nz-card>
    <h4>
      {{
        (!!formGroup.get("id")?.value
          ? "blog.detail.edit-title"
          : "blog.detail.add-title"
        ) | translate
      }}
    </h4>
    <div nz-row>
      <div nz-col nzFlex="auto">
        <div class="tab-container">
          <nz-tabset nzType="card">
            <nz-tab [nzTitle]="titleTemplate" [nzForceRender]="true">
              <ng-template #titleTemplate>
                <div
                  [ngClass]="{
                    'form-group-invalid':
                      formGroup.controls.contentFormGroup.invalid
                  }"
                >
                  {{ "blog.detail.content" | translate }}
                </div>
              </ng-template>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                  "blog.detail.title" | translate
                }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="titleValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      formGroup.controls.contentFormGroup.controls.title
                    "
                  />

                  <ng-template #titleValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'title'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                  "blog.detail.introduction" | translate
                }}</nz-form-label>
                <nz-form-control [nzSpan]="24">
                  <app-input-validation
                    [control]="
                      formGroup.controls.contentFormGroup.controls.introduction
                    "
                    [field]="'introduction'"
                    [errors]="errors"
                  ></app-input-validation>
                  <app-editor
                    *ngIf="readonly !== undefined"
                    [control]="
                      formGroup.controls.contentFormGroup.controls.introduction
                    "
                    [isDraggable]="false"
                    [isReadOnly]="readonly"
                  ></app-editor>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item
                style="margin-bottom: 1.5rem"
                [formGroup]="this.formGroup.controls.contentFormGroup"
              >
                <nz-form-label [nzSpan]="24" nzRequired
                  >{{ "news.detail.text" | translate }}
                </nz-form-label>

                <nz-form-control
                  style="display: flex; flex-direction: column-reverse"
                  [nzSpan]="24"
                >
                  <app-input-validation
                    [control]="
                      formGroup.controls.contentFormGroup.controls.contentBlocks
                    "
                    [field]="'contentBlocks'"
                    [errors]="errors"
                  ></app-input-validation>

                  <app-content-block-overview
                    *ngIf="readonly !== undefined"
                    nz-input
                    ngDefaultControl
                    [isReadOnly]="readonly"
                    [availableContentTypes]="availableContentTypes"
                    [errors]="errors"
                    formArrayName="contentBlocks"
                  ></app-content-block-overview>
                </nz-form-control>
              </nz-form-item>
            </nz-tab>
            <nz-tab [nzTitle]="seoTemplate">
              <ng-template #seoTemplate>
                <div
                  [ngClass]="{
                    'form-group-invalid':
                      formGroup.controls.seoFormGroup.invalid
                  }"
                >
                  {{ "blog.detail.seo" | translate }}
                </div>
              </ng-template>
              <nz-form-item>
                <nz-form-label nz d [nzSpan]="24" nzRequired>{{
                  "blog.detail.window-title" | translate
                }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="windowTitleValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      this.formGroup.controls.seoFormGroup.controls.windowTitle
                    "
                  />
                  <ng-template #windowTitleValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'windowTitle'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                  "blog.detail.keywords" | translate
                }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="keywordsValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      this.formGroup.controls.seoFormGroup.controls.keywords
                    "
                  />
                  <ng-template #keywordsValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'keywords'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                  "blog.detail.description" | translate
                }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="descriptionValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      this.formGroup.controls.seoFormGroup.controls.description
                    "
                  />
                  <ng-template #descriptionValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'description'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                  "blog.detail.slug" | translate
                }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="slugValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      this.formGroup.controls.seoFormGroup.controls.slug
                    "
                  />

                  <ng-template #slugValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'slug'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>

      <aside nz-col class="aside">
        <nz-card nzTitle="{{ 'blog.detail.information' | translate }}">
          <nz-form-item>
            <div nz-col nzSpan="7">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">{{
                  "blog.detail.online" | translate
                }}</nz-form-label>
                <span>
                  <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                  <label
                    nz-checkbox
                    [nzValue]="blog?.showOnline"
                    formControlName="online"
                  >
                  </label>
                </span>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="17">
              <nz-form-label nzSpan="24">{{
                "blog.detail.status" | translate
              }}</nz-form-label>
              <div>
                {{
                  this.blog?.publishDate ?? null
                    | blogStatusPipe : this.blog?.showOnline ?? false
                    | translate
                }}
              </div>
            </div>
          </nz-form-item>
          <nz-form-item *ngIf="employees">
            <nz-form-label [nzSpan]="24" nzRequired>{{
              "blog.detail.author" | translate
            }}</nz-form-label>
            <nz-form-control
              [nzErrorTip]="authorValidationTemplate"
            >
              <nz-select [formControl]="formGroup.controls.authorId">
                <nz-option
                  *ngFor="let employees of employees"
                  [nzLabel]="employees.value"
                  [nzValue]="employees.key"
                ></nz-option>
              </nz-select>

              <ng-template #authorValidationTemplate let-control>
                <app-input-validation
                  [control]="control"
                  [field]="'authorId'"
                  [errors]="errors"
                ></app-input-validation>
              </ng-template>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="24">{{
              "blog.detail.publish-on" | translate
            }}</nz-form-label>
            <nz-form-control>
              <nz-date-picker
                nzShowTime
                [formControl]="formGroup.controls.publishDate"
              ></nz-date-picker>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label [nzSpan]="24">{{
              "blog.detail.created-on" | translate
            }}</nz-form-label>
            <nz-form-control>
              <nz-date-picker
                nzShowTime
                [nzDisabled]="true"
                [formControl]="formGroup.controls.createdOn"
              ></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
        </nz-card>

        <nz-card nzTitle="{{ 'blog.detail.image' | translate }}">
          <app-asset
            *ngIf="readonly !== undefined"
            ngDefaultControl
            [formGroup]="formGroup.controls.asset"
            [isReadOnly]="readonly"
            [errors]="errors"
            [label]="'blog.fields.asset'"
            [fieldName]="'asset'"
            [required]="true"
            (formGroupChange)="
              formGroup.controls.videoLink.updateValueAndValidity()
            "
          />

          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="24">{{
              "blog.detail.video-link" | translate
            }}</nz-form-label>
            <nz-form-control
              [nzSpan]="24"
              [nzErrorTip]="videoLinkValidationTemplate"
            >
              <input
                nz-input
                [formControl]="this.formGroup.controls.videoLink"
              />

              <ng-template #videoLinkValidationTemplate let-control>
                <app-input-validation
                  [control]="control"
                  [field]="'videoLink'"
                  [errors]="errors"
                ></app-input-validation>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <i>{{ "blog.detail.image-or-link-required" | translate }}</i>
        </nz-card>
        <nz-card nzTitle="{{ 'blog.detail.tags' | translate }}">
          <div class="tags-group">
            <nz-form-item class="tagSelector">
              <nz-select
                [nzMode]='tagPicker'
                nzAllowClear
                nzShowSearch
                class="tagSelector"
                nzServerSearch
                (nzOnSearch)="onTagSearch($event)"
                [formControl]="this.formGroup.controls.tags"
              >
                <ng-container *ngFor="let o of tags">
                  <nz-option
                    [nzValue]="o.value"
                    [nzLabel]="o.value"
                    [nzHide]="!isNotSelected(o.value)"
                  ></nz-option>
                </ng-container>
              </nz-select>
              <ng-template #tagPlaceHolder let-selectedList
                >and {{ selectedList.length }} more selected</ng-template
              >
              <app-input-validation
                [control]="this.formGroup.controls.tags"
                [field]="'tags'"
                [errors]="errors"
              ></app-input-validation>
            </nz-form-item>
          </div>
        </nz-card>
      </aside>
    </div>

    <div class="button-container">
      <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
        {{ "common.save" | translate }}
      </button>
    </div>
  </nz-card>
</form>
