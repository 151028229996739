import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { activityClearGuard, activityExistsGuard, activityTemplateExistsGuard } from '@app/modules/activities/guards';
import { ActivityDetailComponent } from '@app/modules/activities/pages/detail/activities-detail.component';
import { ActivityOverviewComponent } from '@app/modules/activities/pages/overview/activities-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: ActivityOverviewComponent },
    { path: 'toevoegen', component: ActivityDetailComponent, canActivate: [activityClearGuard] },
    { path: 'toevoegen/:id', component: ActivityDetailComponent, canActivate: [activityClearGuard, activityTemplateExistsGuard] },
    { path: 'bewerken/:id', component: ActivityDetailComponent, canActivate: [activityExistsGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ActivityRoutingModule { }
