<nz-table
	#overviewTable
	[nzData]="data.list"
	[nzPageIndex]="data.pagination.page"
	[nzPageSize]="data.pagination.pageSize"
	[nzTotal]="data.pagination.totalItems ?? 0"
	[nzLoading]="data.loading"
	[nzFrontPagination]="false"
	(nzQueryParams)="queryParamsChange.emit($event)"
	nzTableLayout="fixed">
	<thead>
		<tr>
			<th
			nzColumnKey="active"
				nzWidth="60px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.DiscountCode,
						claimValues: [ClaimValues.Update]
					}
				]">
				{{ 'discount-code.fields.active' | translate }}
			</th>
			<th nzColumnKey="relation.name" [nzSortFn]="true">
				{{ 'discount-code.fields.relation-name' | translate }}
			</th>
			<th nzColumnKey="code" nzWidth="140px" [nzSortFn]="true" [nzSortOrder]="'ascend'">
				{{ 'discount-code.fields.code' | translate }}
			</th>
			<th nzColumnKey="discountUsage" nzWidth="160px" [nzSortFn]="true">
				{{ 'discount-code.fields.discount-usage' | translate }}
			</th>
			<th nzColumnKey="activity.name" [nzSortFn]="true">
				{{ 'discount-code.fields.activity-name' | translate }}
			</th>
			<th nzColumnKey="activityType.name" [nzSortFn]="true">
				{{ 'discount-code.fields.activity-type-name' | translate }}
			</th>
			<th nzColumnKey="endDate" nzWidth="120px" [nzSortFn]="true">
				{{ 'discount-code.fields.valid-to' | translate }}
			</th>
			<th nzColumnKey="discount" nzWidth="200px">
				{{ 'discount-code.fields.discount' | translate }}
			</th>
			<th nzColumnKey="used" nzWidth="100px" [nzSortFn]="true">
				{{ 'discount-code.fields.used' | translate }}
			</th>
			<th
				nzWidth="120px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.DiscountCode,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of overviewTable.data">
			<td
				nzAlign="center"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.DiscountCode,
						claimValues: [ClaimValues.Update]
					}
				]">
				<label nz-checkbox [nzChecked]="row?.active" (nzCheckedChange)="setActive(row.id, !row.active)"></label>
			</td>
			<td>{{ row.relationName | truncateText }}</td>
			<td>{{ row.code }}</td>
			<td>
				{{ 'discount-code.enums.usage.' + DiscountUsage[row.discountUsage].toLowerCase() | translate }}
			</td>
			<td>{{ row.activityName | truncateText }}</td>
			<td>{{ row.activityTypeName | truncateText }}</td>
			<td>{{ row.endDate | date }}</td>
			<td nzAlign="right">
				{{ row | discountPipe }}
			</td>
			<td nzAlign="right">{{ row.used }}</td>
			<td
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.DiscountCode,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]">
				<div class="table-actions">
					<a
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.DiscountCode,
								claimValues: [ClaimValues.Read]
							}
						]"
						[routerLink]="['/kortingscodes/bewerken', row.id]"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-pen"></i>
					</a>
					<button
						[disabled]="row.active || row.used > 0"
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.DiscountCode,
								claimValues: [ClaimValues.Delete]
							}
						]"
						nz-button
						nzDanger
						nzType="text"
						nzShape="circle"
						nz-popconfirm
						nzOkDanger
						[nzPopconfirmTitle]="'notification.delete' | translate"
						[nzOkText]="'common.delete' | translate"
						[nzCancelText]="'common.cancel' | translate"
						[nzIcon]="templateIconDelete"
						nzPopconfirmPlacement="left"
						(nzOnConfirm)="delete.emit(row.id)">
						<i class="fa-regular fa-trash-can"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</nz-table>

<ng-template #templateIconDelete>
	<i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
