<form nz-form [formGroup]="formGroup">
  <nz-card>
    <h4>
      {{
        (!!formGroup.get("id")?.value
          ? "countries.detail.edit-title"
          : "countries.detail.add-title"
        ) | translate
      }}
    </h4>
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>{{
        "countries.fields.iso" | translate
      }}</nz-form-label>
      <nz-form-control [nzSpan]="24" [nzErrorTip]="isoValidationTemplate">
        <input nz-input [formControl]="formGroup.controls.iso" />

        <ng-template #isoValidationTemplate let-control>
          <app-input-validation
            [control]="control"
            [field]="'iso'"
            [errors]="errors"
          ></app-input-validation>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>{{
        "countries.fields.name" | translate
      }}</nz-form-label>
      <nz-form-control [nzSpan]="24" [nzErrorTip]="nameValidationTemplate">
        <input nz-input [formControl]="formGroup.controls.name" />

        <ng-template #nameValidationTemplate let-control>
          <app-input-validation
            [control]="control"
            [field]="'name'"
            [errors]="errors"
          ></app-input-validation>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>{{
        "countries.fields.phone-code" | translate
      }}</nz-form-label>
      <nz-form-control [nzSpan]="24" [nzErrorTip]="phoneCodeValidationTemplate">
        <nz-select
          nzShowSearch
          nzPlaceHolder="{{ 'countries.fields.zip-code-country' | translate }}"
          [formControl]="formGroup.controls.phoneCodeCountry"
        >
          <nz-option
            *ngFor="let zipCodeCountry of ZipCodeCountries | enumKeyValue"
            [nzLabel]="
              'countries.enums.zip-code-country.' +
                zipCodeCountry.key.toLowerCase() | translate
            "
            [nzValue]="zipCodeCountry.value"
          ></nz-option>
        </nz-select>

        <ng-template #phoneCodeValidationTemplate let-control>
          <app-input-validation
            [control]="control"
            [field]="'phoneCode'"
            [errors]="errors"
          ></app-input-validation>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>{{
        "countries.fields.zip-code-country" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        [nzErrorTip]="zipCodeCountryValidationTemplate"
      >
        <nz-select
          nzShowSearch
          nzPlaceHolder="{{ 'countries.fields.zip-code-country' | translate }}"
          [formControl]="formGroup.controls.zipCodeCountry"
        >
          <nz-option
            *ngFor="let zipCodeCountry of ZipCodeCountries | enumKeyValue"
            [nzLabel]="
              'countries.enums.zip-code-country.' +
                zipCodeCountry.key.toLowerCase() | translate
            "
            [nzValue]="zipCodeCountry.value"
          ></nz-option>
        </nz-select>

        <ng-template #zipCodeCountryValidationTemplate let-control>
          <app-input-validation
            [control]="control"
            [field]="'zipCodeCountry'"
            [errors]="errors"
          ></app-input-validation>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <div class="button-container">
      <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
        {{ "common.save" | translate }}
      </button>
    </div>
  </nz-card>
</form>
