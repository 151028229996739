<div class="page-header">
	<a routerLink="/faq/overzicht" class="page-header__back">
		<i class="fa-regular fa-chevron-left"></i>
	</a>
	<h1 class="page-header__title">{{ 'faq.title' | translate }}</h1>
	<div class="page-header__actions"></div>
</div>

<app-faq-detail-form
	[faq]="faq$ | async"
	[faqCategories]="faqCategories$ | async"
	[faqCategoryId]="faqCategoryId$ | async"
	(submitted)="submit($event)"
	[errors]="errors$ | async"
	[loading]="loading$ | async" />
