import { Component } from '@angular/core';
import * as Activity from '@app/modules/activity/reducers';

import * as ActivityApiActions from '@app/modules/activity/actions/activity-api.actions';
import { ActivityCreateModel, ActivityUpdateModel } from '@app/modules/activity/models';
import { ActivityGenerateCode } from '@app/modules/activity/models/activity-generate-code.model';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-activity-detail',
    templateUrl: './activity-detail.component.html',
    styleUrls: ['./activity-detail.component.less'],
})
export class ActivityDetailComponent {
    loading$ = this.store.select(Activity.selectDetailLoading);
    errors$ = this.store.select(Activity.selectDetailErrors);
    activity$ = this.store.select(Activity.selectDetailActivity);
    activityTemplate$ = this.store.select(Activity.selectDetailActivityTemplate);
    activityTypes$ = this.store.select(Activity.selectActivityTemplateActivityTypes);
    activityLocations$ = this.store.select(Activity.selectActivityLocations);
    checkedCode$ = this.store.select(Activity.selectActivityCode);

    constructor(private readonly store: Store<State>) {
        this.store.dispatch(ActivityApiActions.GetActivityTypes());
        this.store.dispatch(ActivityApiActions.GetActivityLocations());
    }

    checkCode(code: ActivityGenerateCode) {
        this.store.dispatch(ActivityApiActions.CheckCode({ code }));
    }

    searchRelation(event: any){
        if(event.skipCount === 0){
            this.store.dispatch(ActivityApiActions.EmptyRelations())
        }
        this.store.dispatch(ActivityApiActions.GetRelations(event))
    }

    submit(activity: ActivityCreateModel | ActivityUpdateModel) {
        this.store.dispatch(
            activity.hasOwnProperty('id')
                ? ActivityApiActions.Update(activity as ActivityUpdateModel)
                : ActivityApiActions.Create(activity),
        );
    }
}
