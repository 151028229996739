export enum ApplicationType {
    Reservation = 0,
    Normal = 1,
    Waitlist = 2,
    CatchUp = 3,
}

export const ApplicationTypeLabelsMapping: Record<number, string> = {
    [ApplicationType.Reservation]: 'activity.enum.reservation',
    [ApplicationType.Normal]: 'activity.enum.normal',
    [ApplicationType.Waitlist]: 'activity.enum.waitlist',
    [ApplicationType.CatchUp]: 'activity.enum.catch-up',
}