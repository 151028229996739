<nz-list>
  @for (activitySchedule of activitySchedules; track activitySchedule) {
  <nz-list-item [routerLink]="['../bewerken', activitySchedule.key]">
    {{ activitySchedule.value }}
    <ul nz-list-item-actions>
      <nz-list-item-action>
        <i class="fa-regular fa-chevron-right"></i>
      </nz-list-item-action>
    </ul>
  </nz-list-item>
  }
</nz-list>
