import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { ActivityTemplateOverviewComponent } from "@app/modules/activity-template/pages/overview/activity-template-overview.component";
import { ActivityTemplateDetailComponent } from "@app/modules/activity-template/pages/detail/activity-template-detail.component";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ActivityTemplateRoutingModule } from "@app/modules/activity-template/activity-template-routing.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { activityTemplateFeatureKey, reducers } from "@app/modules/activity-template/reducers";
import { ActivityTemplateEffects } from "@app/modules/activity-template/effects/activity-template.effects";
import { ActivityTemplateOverviewTableComponent } from "@app/modules/activity-template/components/activity-template-overview-table/activity-template-overview-table.component";
import { ActivityTemplateDetailFormComponent } from "@app/modules/activity-template/components/activity-template-detail-form/activity-template-detail-form.component";

const COMPONENTS = [
    ActivityTemplateOverviewComponent,
    ActivityTemplateDetailComponent,
    ActivityTemplateOverviewTableComponent,
    ActivityTemplateDetailFormComponent
];

const IMPORTS = [
    SharedModule,
    ActivityTemplateRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(activityTemplateFeatureKey, reducers),
    EffectsModule.forFeature([ActivityTemplateEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class ActivityTemplatesModule { }
