import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { claimCategoryExistsGuard } from '@app/modules/claim-categories/guards';
import { ClaimCategoryDetailComponent } from '@app/modules/claim-categories/pages/detail/claim-category-detail.component';
import { ClaimCategoryOverviewComponent } from '@app/modules/claim-categories/pages/overview/claim-category-overview.component';

const routes: Routes = [
	{ path: '', redirectTo: 'overzicht', pathMatch: 'full' },
	{ path: 'overzicht', component: ClaimCategoryOverviewComponent },
	{ path: 'bewerken/:type/:value', component: ClaimCategoryDetailComponent, canActivate: [claimCategoryExistsGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ClaimCategoryRoutingModule {}
