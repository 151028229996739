import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ZipCodeCountry } from '@app/modules/countries/enums/zip-code-country.enum';
import { CountryUpdateModel, CountryCreateModel } from '@app/modules/countries/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
    selector: 'app-country-detail-form',
    templateUrl: './country-detail-form.component.html',
})
export class CountryDetailFormComponent implements OnInit, OnChanges {
    update = false;
    readonly?: boolean;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) country: CountryUpdateModel | null = null;

    @Output() submitted = new EventEmitter<CountryCreateModel | CountryUpdateModel>();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        iso: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(10)]),
        name: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        phoneCodeCountry: new FormControl<ZipCodeCountry>(ZipCodeCountry.None, [CustomValidators.required]),
        zipCodeCountry: new FormControl<ZipCodeCountry>(ZipCodeCountry.None, [CustomValidators.required]),
    });

    ZipCodeCountries = ZipCodeCountry;

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.country;

        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.Country, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        this.formGroup.patchValue({
            iso: this.country?.iso,
            name: this.country?.name,
            phoneCodeCountry: this.country?.phoneCodeCountry ?? ZipCodeCountry.None,
            zipCodeCountry: this.country?.zipCodeCountry ?? ZipCodeCountry.None,
        });

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.country as CountryUpdateModel).id);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            iso: this.formGroup.controls.iso.value ?? '',
            name: this.formGroup.controls.name.value ?? '',
            phoneCodeCountry: this.formGroup.controls.phoneCodeCountry.value ?? ZipCodeCountry.None,
            zipCodeCountry: this.formGroup.controls.zipCodeCountry.value ?? ZipCodeCountry.None,
        });
    }

    private submitUpdate() {
        this.submitted.emit(this.formGroup.value as CountryUpdateModel);
    }
}
