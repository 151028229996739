import { ErrorResponse } from "@app/core/models";
import { ActivityScheduleUpdateModel } from "@app/mobile/modules/activity-schedule/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { createAction, props } from "@ngrx/store";

export const Clear = createAction("[ActivitySchedule] Clear");

export const GetList = createAction("[ActivitySchedule] GetList");
export const GetListError = createAction("[ActivitySchedule] GetListError", props<{ response: ErrorResponse }>());
export const GetListSuccess = createAction("[ActivitySchedule] GetListSuccess", props<{ list: KeyValueModel[] }>());

export const Get = createAction("[ActivitySchedule] Get", props<{ id: number }>());
export const GetError = createAction("[ActivitySchedule] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[ActivitySchedule] GetSuccess", props<ActivityScheduleUpdateModel>());

export const Update = createAction("[ActivitySchedule] Update", props<ActivityScheduleUpdateModel>());
export const UpdateError = createAction("[ActivitySchedule] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[ActivitySchedule] UpdateSuccess");