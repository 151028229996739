import { ErrorResponse } from '@app/core/models/error-response.model';
import { ActivityTemplateCreateModel } from '@app/modules/activity-template/models';
import { ActivityTypeCodeModel } from '@app/modules/activity-type/models/activity-type-code.model';
import { ActivityCreateModel, ActivityTableItemModel, ActivityUpdateModel } from '@app/modules/activity/models';
import { ActivityAvailableParticipantsModel } from '@app/modules/activity/models/activity-available-participants.model';
import { ActivityChangeOnlineModel } from '@app/modules/activity/models/activity-change-online.model';
import { ActivityGenerateCode } from '@app/modules/activity/models/activity-generate-code.model';
import { UpdateAvailableParticipantsModel } from '@app/modules/activity/models/update-activity-available-participants.model';
import { TableDataModel } from '@app/shared/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { createAction, props } from '@ngrx/store';

export const GetOverview = createAction('[Activity] GetOverview');
export const GetOverviewError = createAction('[Activity] GetOverviewError', props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction(
    '[Activity] GetOverviewSuccess',
    props<TableDataModel<ActivityTableItemModel>>(),
);
export const ResetOverview = createAction('[Activity] ResetOverview');

export const FilterOverview = createAction(
    '[Activity] FilterOverview',
    props<{
        sorting?: TableSortingModel;
        pagination: { page: number; pageSize: number };
        thenBy?: TableSortingModel[];
    }>(),
);

export const Clear = createAction('[Activity] Clear');

export const Get = createAction('[Activity] Get', props<{ id: number }>());
export const GetError = createAction('[Activity] GetError', props<{ response: ErrorResponse }>());
export const GetSuccess = createAction('[Activity] GetSuccess', props<ActivityUpdateModel>());
export const GetTemplate = createAction('[Activity] GetTemplate', props<{ id: number }>());
export const GetTemplateSuccess = createAction('[Activity] GetTemplateSuccess', props<ActivityTemplateCreateModel>());

export const Update = createAction('[Activity] Update', props<ActivityUpdateModel>());
export const UpdateError = createAction('[Activity] UpdateError', props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction('[Activity] UpdateSuccess', props<ActivityUpdateModel>());

export const UpdateParticipants = createAction(
    '[Activity] UpdateParticipants',
    props<UpdateAvailableParticipantsModel>(),
);
export const UpdateParticipantsError = createAction(
    '[Activity] UpdateParticipantsError',
    props<{ response: ErrorResponse }>(),
);
export const UpdateParticipantsSuccess = createAction(
    '[Activity] UpdateParticipantsSuccess',
    props<ActivityAvailableParticipantsModel>(),
);

export const Create = createAction('[Activity] Create', props<ActivityCreateModel>());
export const CreateError = createAction('[Activity] CreateError', props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction('[Activity] CreateSuccess', props<ActivityUpdateModel>());

export const Delete = createAction('[Activity] Delete', props<{ id: number }>());
export const DeleteError = createAction('[Activity] DeleteError', props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction('[Activity] DeleteSuccess');

export const GetAllActivityTemplates = createAction('[Activity] GetAllActivityTemplates');
export const GetAllActivityTemplatesError = createAction(
    '[Activity] GetAllActivityTemplatesError',
    props<{ response: ErrorResponse }>(),
);
export const GetAllActivityTemplatesSuccess = createAction(
    '[Activity] GetAllActivityTemplatesSuccess',
    props<{ activityTemplates: KeyValueModel[] }>(),
);

export const GetActivityTypes = createAction('[Activity] GetActivityTypes');
export const GetActivityTypesError = createAction(
    '[Activity] GetActivityTypesError',
    props<{ response: ErrorResponse }>(),
);
export const GetActivityTypesSuccess = createAction(
    '[Activity] GetActivityTypesSuccess',
    props<{ activityTypes: ActivityTypeCodeModel[] }>(),
);

export const GetActivityLocations = createAction('[Activity] GetActivityLocations');
export const GetActivityLocationsError = createAction(
    '[Activity] GetActivityLocationsError',
    props<{ response: ErrorResponse }>(),
);
export const GetActivityLocationsSuccess = createAction(
    '[Activity] GetActivityLocationsSuccess',
    props<{ activityLocations: KeyValueModel[] }>(),
);

export const SetActiveActivityTypeId = createAction(
    '[Activity] SetActiveActivityTypeId',
    props<{ activityTypeId: number }>(),
);

export const CheckCode = createAction('[Activity] CheckCode', props<{ code: ActivityGenerateCode }>());
export const CheckCodeError = createAction('[Activity] CheckCodeError', props<{ response: ErrorResponse }>());
export const CheckCodeSuccess = createAction('[Activity] CheckCodeSuccess', props<{ code: string }>());

export const UpdateShowOnline = createAction('[Activity] Update ShowOnline', props<ActivityChangeOnlineModel>());
export const UpdateShowOnlineError = createAction(
    '[Activity] UpdateShowOnlineError',
    props<{ response: ErrorResponse }>(),
);
export const UpdateShowOnlineSuccess = createAction(
    '[Activity] UpdateShowOnlineSuccess',
    props<ActivityChangeOnlineModel>(),
);

export const DisplayOldActivities = createAction('[Activity] DisplayOldActivities', props<{ displayOld: boolean }>());
export const DisplayOldActivitiesError = createAction(
    '[Activity] DisplayOldActivitiesError',
    props<{ response: ErrorResponse }>(),
);
export const DisplayOldActivitiesSuccess = createAction(
    '[Activity] DisplayOldActivitiesSuccess',
    props<{ displayOld: boolean }>(),
);

export const GetRelations = createAction(
    '[Activity] GetRelations',
    props<{ searchQuery: string; skipCount: number; activityId: number | null }>(),
);
export const GetRelationsError = createAction('[Activity] GetRelationsError', props<{ response: ErrorResponse }>());
export const GetRelationsSuccess = createAction(
    '[Activity] GetRelationsSuccess',
    props<{ relations: KeyValueModel[] }>(),
);
export const GetRelationsAdditionalSuccess = createAction(
    '[Activity] GetRelationsSuccess',
    props<{ relations: KeyValueModel[] }>(),
);
export const EmptyRelations = createAction('[Activity] EmptyRelations');
