import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RelationTableItemModel } from '@app/modules/relation/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
    selector: 'app-relation-overview-table',
    templateUrl: './relation-overview-table.component.html',
})
export class RelationOverviewTableComponent {
    @Input({ required: true }) data!: TableStateModel<RelationTableItemModel>;
    @Input({ required: true }) hasReadClaims = false;

    @Output() delete = new EventEmitter<number>();
    @Output() onChangeActive = new EventEmitter<{
        id: number;
    }>();
    @Output() onQueryParamsChange = new EventEmitter<any>();

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    PageSizes = [100, 200, 500];

    constructor(private readonly router: Router) {}

    clickRow(id: number) {
        if (this.hasReadClaims) {
            this.router.navigateByUrl('/relaties/detail/' + id);
        }
    }

    changeActive(id: number) {
        this.onChangeActive.emit({ id });
    }
}
