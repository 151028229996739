<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>
            {{ (!!formGroup.get("id")?.value ? "news.detail.edit-title" : "news.detail.add-title") | translate }}
        </h4>

        <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="24">{{ "news.detail.title" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="titleValidationTemplate">
                <input nz-input [formControl]="this.formGroup.controls.title" />

                <ng-template #titleValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'title'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24">{{ "news.detail.show-online" | translate }}</nz-form-label>
            <span>
                <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                <label nz-checkbox [nzValue]="news?.showOnline" formControlName="showOnline">
                </label>
            </span>
        </nz-form-item>

        <app-asset
            *ngIf="readonly !== undefined"
            ngDefaultControl
            [formGroup]="formGroup.controls.asset"
            [errors]="errors"
            [fieldName]="'asset'"
            [isReadOnly]="readonly"
        ></app-asset>

        <nz-form-item class="mb-15">
            <nz-form-label [nzSpan]="24" nzRequired>{{ "news.detail.text" | translate }}</nz-form-label>
            <nz-form-control class="column-reverse" [nzSpan]="24" [nzErrorTip]="textValidationTemplate">
                <ng-template #textValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'text'" [errors]="errors"></app-input-validation>
                </ng-template>

                <app-input-validation [control]="formGroup.controls.text" [field]="'text'"
                    [errors]="errors"></app-input-validation>

                <app-content-block-overview *ngIf="readonly !== undefined" nz-input ngDefaultControl
                    [isReadOnly]="readonly" [availableContentTypes]="availableContentTypes" [errors]="errors"
                    formArrayName="text"></app-content-block-overview>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24">{{ "news.detail.link" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="linkValidationTemplate">
                <input nz-input [formControl]="this.formGroup.controls.link"
                    (ngModelChange)="formGroup.controls.linkText.updateValueAndValidity()" />

                <ng-template #linkValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'link'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24">{{ "news.detail.link-text" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="linkTextValidationTemplate">
                <input nz-input [formControl]="this.formGroup.controls.linkText"
                    (ngModelChange)="formGroup.controls.link.updateValueAndValidity()" />

                <ng-template #linkTextValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'linkText'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <div class="button-container">
            <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
                {{ "common.save" | translate }}
            </button>
        </div>
    </nz-card>
</form>