<div class="auth-card">
	<div class="auth-card-content">
		<h1>{{ 'auth.reset-password' | translate }}</h1>
		<form *ngIf="!success && !failed" nz-form nzLayout="vertical" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
			<nz-form-item>
				<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.password' | translate }}</nz-form-label>
				<nz-form-control [nzSpan]="24" [nzErrorTip]="PasswordValidationTemplate">
					<input type="password" nz-input [formControl]="formGroup.controls.password" />

					<ng-template #PasswordValidationTemplate let-control>
						<app-input-validation [control]="control" [field]="'password'" [errors]="errors!"></app-input-validation>
					</ng-template>
				</nz-form-control>
			</nz-form-item>

			<nz-form-item>
				<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.password-confirmation' | translate }}</nz-form-label>
				<nz-form-control [nzSpan]="24" [nzErrorTip]="PasswordConfirmationValidationTemplate">
					<input type="password" nz-input [formControl]="formGroup.controls.passwordConfirmation" />

					<ng-template #PasswordConfirmationValidationTemplate let-control>
						<app-input-validation
							[control]="control"
							[field]="'passwordConfirmation'"
							[errors]="errors!"></app-input-validation>
					</ng-template>
				</nz-form-control>
			</nz-form-item>

            <div class="button-container">
                <button [disabled]="formGroup.invalid" nz-button nzType="primary" (click)="onSubmit()">
                    {{ 'common.save' | translate }}
                </button>
            </div>
		</form>
		<nz-result *ngIf="success" nzStatus="success" nzSubTitle="{{ 'auth.password-reset-success' | translate }}">
			<div nz-result-extra>
				<a routerLink="/auth/login">{{ 'auth.back-to-login' | translate }}</a>
			</div>
		</nz-result>
        <nz-result *ngIf="failed" nzStatus="error" nzSubTitle="{{ 'auth.password-reset-failed' | translate }}">
			<div nz-result-extra>
				<a routerLink="/auth/wachtwoord-vergeten">{{ 'auth.to-password-forget' | translate }}</a>
			</div>
		</nz-result>
	</div>
</div>
