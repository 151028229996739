import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BlogTableItemModel } from '@app/modules/blogs/models/blog-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
  selector: 'app-blog-overview-table',
  templateUrl: './blog-overview-table.component.html',
})
export class BlogOverviewTableComponent {
  @Input({ required: true }) data!: TableStateModel<BlogTableItemModel>;

  @Output() delete = new EventEmitter<number>();
  @Output() changeShowOnline = new EventEmitter<{
    id: number;
    showOnline: boolean;
  }>();
  @Output() queryParamsChange = new EventEmitter<any>();

  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;

  onChangeShowOnline(id: number, showOnline: boolean) {
    this.changeShowOnline.emit({ id, showOnline });
  }
}
