import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { activityLocationClearGuard, activityLocationExistsGuard } from '@app/modules/activity-location/guards';
import { ActivityLocationDetailComponent } from '@app/modules/activity-location/pages/detail/activity-location-detail.component';
import { ActivityLocationOverviewComponent } from '@app/modules/activity-location/pages/overview/activity-location-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: ActivityLocationOverviewComponent },
    { path: 'toevoegen', component: ActivityLocationDetailComponent, canActivate: [activityLocationClearGuard] },
    { path: 'bewerken/:id', component: ActivityLocationDetailComponent, canActivate: [activityLocationExistsGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ActivityLocationRoutingModule { }
