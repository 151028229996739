import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { ContentBlockType } from "@app/shared/models/content/content-block-type.enum";
import { EditorContentBlockFormGroup } from "@app/shared/models/content/editor-content-block/editor-content-block-form-group";
import { ImageAndEditorContentBlockFormGroup } from "@app/shared/models/content/image-and-editor-content-block";

export class ContentBlockFormGroup extends FormGroup {
    constructor(
        contentBlock: { type: ContentBlockType; label?: string; content?: string; sequenceNumber?: number; id?: number; }, 
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, 
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super({
            id: new FormControl<number>(contentBlock.id ?? 0),
            label: new FormControl<string>(contentBlock.label ?? ''),
            sequenceNumber: new FormControl<number>(contentBlock.sequenceNumber ?? 0),
            type: new FormControl<ContentBlockType>(contentBlock.type),
            content: getContentFormGroup(contentBlock.type, contentBlock.content ?? '')
        }, validatorOrOpts, asyncValidator);
    }
}

const getContentFormGroup = (type: ContentBlockType, content: string): EditorContentBlockFormGroup|ImageAndEditorContentBlockFormGroup => {
    switch (type) {
        case ContentBlockType.Editor:
            return new EditorContentBlockFormGroup(content);
        case ContentBlockType.ImageAndEditor:
            return new ImageAndEditorContentBlockFormGroup(content)
        default:
            throw new Error(`Unsupported Content Block Type: ${type}`);
    }
}