<nz-table
	#overviewTable
	[nzData]="data.list"
	[nzPageIndex]="data.pagination.page"
	[nzPageSize]="data.pagination.pageSize"
	[nzTotal]="data.pagination.totalItems ?? 0"
	[nzLoading]="data.loading"
	[nzFrontPagination]="false"
	(nzQueryParams)="onQueryParamsChange.emit($event)"
	nzTableLayout="fixed">
	<thead>
		<tr>
			<th nzColumnKey="type" [nzSortFn]="true" [nzSortOrder]="'ascend'">
				{{ 'claim-categories.fields.type' | translate }}
			</th>
			<th nzColumnKey="value" [nzSortFn]="true">
				{{ 'claim-categories.fields.value' | translate }}
			</th>
			<th
				nzWidth="120px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.ClaimCategory,
						claimValues: [ClaimValues.Read]
					}
				]"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of overviewTable.data">
			<td>{{ translationKeyType(row.type) | translate | titlecase }}</td>
			<td>{{ translationKeyValue(row.value) | translate | titlecase }}</td>
			<td
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.ClaimCategory,
						claimValues: [ClaimValues.Read]
					}
				]">
				<div class="table-actions">
					<a
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.ClaimCategory,
								claimValues: [ClaimValues.Read]
							}
						]"
						[routerLink]="['/rechten/bewerken', row.type, row.value]"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-pen"></i>
					</a>
				</div>
			</td>
		</tr>
	</tbody>
</nz-table>
