import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { activityScheduleExistsGuard } from '@app/mobile/modules/activity-schedule/guards/';
import { ActivityScheduleDetailPageComponent } from '@app/mobile/modules/activity-schedule/pages/detail/activity-schedule-detail-page.component';
import { ActivityScheduleOverviewPageComponent } from '@app/mobile/modules/activity-schedule/pages/overview/activity-schedule-overview-page.component';

const routes: Routes = [
  { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
  { path: 'overzicht', component: ActivityScheduleOverviewPageComponent },
  {
    path: 'bewerken/:id',
    component: ActivityScheduleDetailPageComponent,
    canActivate: [activityScheduleExistsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ActivityScheduleRoutingModule {}
