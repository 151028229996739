import * as MaterialActions from '@app/modules/materials/actions/material-api.actions';
import { MaterialUpdateModel } from '@app/modules/materials/models';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
  material: null,
  loading: false,
  errors: {},
};

export interface State {
  material: MaterialUpdateModel | null;
  loading: boolean;
  errors: { [key: string]: string[] };
}

export const reducer = createReducer(
  initialState,
  on(MaterialActions.Clear, () => ({ ...initialState })),

  on(MaterialActions.Get, (state) => ({ ...state, loading: true })),
  on(MaterialActions.GetSuccess, (state, material) => ({
    ...state,
    material,
    loading: false,
  })),
  on(MaterialActions.GetError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(MaterialActions.Update, (state) => ({
    ...state,
    loading: true,
    errors: {},
  })),
  on(MaterialActions.UpdateSuccess, (state, material) => ({
    ...state,
    material,
    loading: false,
  })),
  on(MaterialActions.UpdateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(MaterialActions.Create, (state) => ({
    ...state,
    loading: true,
    errors: {},
  })),
  on(MaterialActions.CreateSuccess, (state, material) => ({
    ...state,
    material,
    loading: false,
  })),
  on(MaterialActions.CreateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  }))
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getMaterial = (state: State) => state.material;
