import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { countryClearGuard, countryExistsGuard } from '@app/modules/countries/guards';
import { CountryDetailComponent } from '@app/modules/countries/pages/detail/country-detail.component';
import { CountryOverviewComponent } from '@app/modules/countries/pages/overview/country-overview.component';

const routes: Routes = [
	{ path: '', redirectTo: 'overzicht', pathMatch: 'full' },
	{ path: 'overzicht', component: CountryOverviewComponent },
	{ path: 'toevoegen', component: CountryDetailComponent, canActivate: [countryClearGuard] },
	{ path: 'bewerken/:id', component: CountryDetailComponent, canActivate: [countryExistsGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CountryRoutingModule {}
