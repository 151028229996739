import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  blogreactionClearGuard,
  blogreactionExistsGuard,
} from '@app/modules/blog-reaction/guards';
import { BlogReactionDetailComponent } from '@app/modules/blog-reaction/pages/detail/blog-reaction-detail.component';
import { BlogReactionOverviewComponent } from '@app/modules/blog-reaction/pages/overview/blog-reaction-overview.component';
import { blogExistsGuard } from '@app/modules/blogs/guards';

const routes: Routes = [
  { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
  {
    path: 'overzicht',
    component: BlogReactionOverviewComponent,
    canActivate: [blogExistsGuard],
  },
  {
    path: 'toevoegen',
    component: BlogReactionDetailComponent,
    canActivate: [blogreactionClearGuard],
  },
  {
    path: 'toevoegen/:id',
    component: BlogReactionDetailComponent,
    canActivate: [blogreactionClearGuard],
  },
  {
    path: 'bewerken/:id',
    component: BlogReactionDetailComponent,
    canActivate: [blogreactionExistsGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlogReactionRoutingModule {}
