<nz-form-item>
	<nz-form-label *ngIf="label" [nzSpan]="24" [nzRequired]="required">{{ label }}</nz-form-label>
	<nz-form-control [nzSpan]="24" [nzErrorTip]="ValidationTemplate">
		<nz-select nzPlaceHolder="{{ placeholder }}" [nzAllowClear]="allowClear" nzShowSearch [formControl]="control!" [nzDisabled]="disabled">
			<ng-container *ngFor="let o of items">
				<nz-option *ngIf="!loading" [nzValue]="o.key" [nzLabel]="o.value"></nz-option>
			</ng-container>
			<nz-option *ngIf="loading" nzDisabled nzCustomContent>
				<span nz-icon nzType="loading" class="loading-icon"></span>
				{{ 'common.loading' | translate }}
			</nz-option>
		</nz-select>
		
		<ng-template #ValidationTemplate let-control>
			<app-input-validation [control]="control" [field]="field" [errors]="errors"></app-input-validation>
		</ng-template>
	</nz-form-control>
</nz-form-item>