import { ErrorResponse } from "@app/core/models/error-response.model";
import { FAQChangeSequenceModel, FAQCreateModel, FAQTableItemModel, FAQUpdateModel } from "@app/modules/faq/models";
import { TableDataModel } from "@app/shared/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[FAQ] GetOverview");
export const GetOverviewError = createAction("[FAQ] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[FAQ] GetOverviewSuccess", props<TableDataModel<FAQTableItemModel>>());
export const ResetOverview = createAction("[FAQ] ResetOverview");

export const FilterOverview = createAction("[FAQ] FilterOverview", props<{ categoryId: number, sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[FAQ] Clear");

export const Get = createAction("[FAQ] Get", props<{ id: number }>());
export const GetError = createAction("[FAQ] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[FAQ] GetSuccess", props<FAQUpdateModel>());

export const Update = createAction("[FAQ] Update", props<FAQUpdateModel>());
export const UpdateError = createAction("[FAQ] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[FAQ] UpdateSuccess", props<FAQUpdateModel>());

export const ChangeSequence = createAction("[FAQ] ChangeSequence", props<FAQChangeSequenceModel>());
export const ChangeSequenceError = createAction("[FAQ] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[FAQ] ChangeSequenceSuccess");

export const UpdateOnline = createAction("[FAQ] UpdateOnline", props<{ id: number, online: boolean }>());
export const UpdateOnlineError = createAction("[FAQ] UpdateOnlineError", props<{ response: ErrorResponse }>());
export const UpdateOnlineSuccess = createAction("[FAQ] UpdateOnlineSuccess", props<FAQUpdateModel>());

export const Create = createAction("[FAQ] Create", props<FAQCreateModel>());
export const CreateError = createAction("[FAQ] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[FAQ] CreateSuccess", props<FAQUpdateModel>());

export const Delete = createAction("[FAQ] Delete", props<{ id: number }>());
export const DeleteError = createAction("[FAQ] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[FAQ] DeleteSuccess");

export const SetActiveFAQCategoryId = createAction("[FAQ] SetActiveFAQCategoryId", props<{ faqCategoryId: number | null }>());

export const GetFAQCategories = createAction("[FAQ] GetFAQCategories");
export const GetFAQCategoriesError = createAction("[FAQ] GetFAQCategoriesError", props<{ response: ErrorResponse }>());
export const GetFAQCategoriesSuccess = createAction("[FAQ] GetFAQCategoriesSuccess", props<{ faqCategories: KeyValueModel[] }>());