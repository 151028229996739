<div class="page-header">
	<a routerLink="/kortingscodes/overzicht" class="page-header__back">
		<i class="fa-regular fa-chevron-left"></i>
	</a>
	<h1 class="page-header__title">{{ 'discount-code.title' | translate }}</h1>
	<div class="page-header__actions"></div>
</div>

<app-discount-code-detail-form
	[discountCode]="discountCode$ | async"
	[activities]="activities$ | async"
	[activityTypes]="activityTypes$ | async"
	[relations]="relations$ | async"
	(submitted)="submit($event)"
	[errors]="errors$ | async"
	[loading]="loading$ | async" />
