import { Component } from '@angular/core';
import * as RedirectActions from '@app/modules/redirects/actions/redirect-api.actions';
import * as Redirect from '@app/modules/redirects/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
	selector: 'app-redirect-overview',
	templateUrl: './redirect-overview.component.html',
	styleUrls: ['./redirect-overview.component.less'],
})
export class RedirectOverviewComponent {
	tableData$ = this.store.select(Redirect.selectRedirectOverviewState);

	ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

	constructor(private readonly store: Store) {}

	onDeleteConfirm(id: number): void {
		this.store.dispatch(RedirectActions.Delete({ id: id }));
	}

	/**
	 * Trigger the loading of data whenever the table changes.
	 * The default pageSize, index and sort options are based on the TableStateModel.
	 * @param params
	 */
	onQueryParamsChange(params: NzTableQueryParams) {
		const { pageSize, pageIndex, sort, filter } = params;
		const currentSort = sort.find((item) => item.value !== null);
		const sortField = (currentSort?.key) ?? null;
		const sortOrder = (currentSort?.value) ?? null;

		this.store.dispatch(
			RedirectActions.FilterOverview({
				pagination: {
					page: pageIndex,
					pageSize,
				},
				sorting: {
					propertyName: sortField ?? '',
					order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
				},
			})
		);
	}
}
