import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as BlogReactionActions from '@app/modules/blog-reaction/actions/blog-reaction-api.actions';
import * as BlogReactionApiActions from '@app/modules/blog-reaction/actions/blog-reaction-api.actions';
import * as BlogReaction from '@app/modules/blog-reaction/reducers';
import {
  ClaimTypes,
  ClaimValues,
  CompareType,
} from '@app/shared/models/common/constants';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-blog-reaction-overview',
  templateUrl: './blog-reaction-overview.component.html',
  styleUrls: ['./blog-reaction-overview.component.less'],
})
export class BlogReactionOverviewComponent {
  tableData$ = this.store.select(BlogReaction.selectBlogReactionOverviewState);

  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;
  blogId: number | undefined;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.blogId = params['blogId'];
    });
  }

  onDeleteConfirm(id: number): void {
    this.store.dispatch(BlogReactionActions.Delete({ id: id }));
  }

  onChangeShowOnline(event: any): void {
    this.store.dispatch(
      BlogReactionActions.UpdateShowOnline({
        id: event.id,
        showOnline: event.showOnline,
      })
    );
  }

  /**
   * Trigger the loading of data whenever the table changes.
   * The default pageSize, index and sort options are based on the TableStateModel.
   * @param params
   */
  onQueryParamsChange(params: NzTableQueryParams) {
    const { pageSize, pageIndex } = params;
    this.store.dispatch(
      BlogReactionApiActions.FilterOverview({
        pagination: {
          page: pageIndex,
          pageSize,
        },
        sorting: {
          propertyName: 'CreatedOn',
          order: 'ASC',
        },
        blogId: this.blogId!,
        filter: [
          {
            column: 'BlogId',
            value: this.blogId!.toString(),
            operand: CompareType.ContainsString,
          },
        ] as TableFilterModel[],
      })
    );
  }
}
