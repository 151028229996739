import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as PageApiActions from '@app/modules/page/actions/page-api.actions';
import { PageType } from '@app/modules/page/enums';
import { PageTypeOptions } from '@app/modules/page/enums/page-type';
import { SlugStatus } from '@app/modules/page/enums/slug-status';
import {
  PageChangeSequenceModel,
  PageCreateModel,
  PageTableItemModel,
  PageUpdateModel,
} from '@app/modules/page/models';
import { TableDataModel } from '@app/shared/models';
import {
  ClaimTypes,
  ClaimValues,
  Constants,
} from '@app/shared/models/common/constants';
import {
  ContentBlockFormGroup,
  ContentBlockType,
} from '@app/shared/models/content';
import { ClaimService } from '@app/shared/services/claim.service';
import {
  isFormValid,
  processBackendValidators,
} from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-detail-form',
  templateUrl: './page-detail-form.component.html',
  styleUrls: ['./page-detail-form.component.less'],
})
export class PageDetailFormComponent implements OnInit, OnChanges {
  update = false;
  readonly?: boolean;

  @Input() loading = false;
  @Input() errors: { [key: string]: string[] } = {};
  @Input({ required: true }) page: PageUpdateModel | null = null;
  @Input({ required: true })
  childPagesData: TableDataModel<PageTableItemModel> | null = null;
  @Input({ required: true }) parentId: number | null = null;
  @Input({ required: true }) redirectExists$!: Observable<SlugStatus>;

  @Output() submitted = new EventEmitter<{
    model: PageCreateModel | PageUpdateModel;
  }>();
  @Output() modalSubmitted = new EventEmitter<{
    model: PageCreateModel | PageUpdateModel;
  }>();

  @Output() resetRedirectExists = new EventEmitter<void>();

  selectedTab: number = 0;
  id: number | null = null;

  availableContentTypes = [
    ContentBlockType.Editor,
    ContentBlockType.ImageAndEditor,
  ];
  pageTypes = PageTypeOptions;

  formGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    isVisible: new FormControl<boolean>(true, [CustomValidators.required]),
    mainFormGroup: new FormGroup({
      pageType: new FormControl<PageType>(PageType.Page, [
        CustomValidators.required,
      ]),
      title: new FormControl<string>('', [
        CustomValidators.required,
        Validators.maxLength(100),
      ]),
      contentBlocks: new FormArray<ContentBlockFormGroup>(
        [],
        [CustomValidators.minArrayLength(1)]
      ),
    }),
    seoFormGroup: new FormGroup({
      metaTitle: new FormControl<string>('', [
        CustomValidators.required,
        Validators.maxLength(75),
      ]),
      metaDescription: new FormControl<string>('', [
        CustomValidators.required,
        Validators.maxLength(200),
      ]),
      metaKeywords: new FormControl<string>('', [
        CustomValidators.required,
        Validators.maxLength(200),
      ]),
      slug: new FormControl<string>('', [
        CustomValidators.required,
        Validators.maxLength(100),
        Validators.pattern(Constants.SlugExpression)
      ]),
    }),
  });

  constructor(
    private readonly claimService: ClaimService,
    private readonly store: Store,
    private readonly modalService: NzModalService,
    private readonly translate: TranslateService
  ) {}

  destoryRef = inject(DestroyRef);

  async ngOnInit() {
    this.update = !!this.page;
    this.redirectExists$
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe((status) => {
        this.createModal(status);
      });
    if (
      this.update &&
      !(await this.claimService.hasAny([
        { claimType: ClaimTypes.Page, claimValues: [ClaimValues.Update] },
      ]))
    ) {
      this.formGroup.disable();
      this.readonly = true;
    } else {
      this.readonly = false;
    }

    this.setFormData();
  }

  ngOnChanges(changes: SimpleChanges) {
    processBackendValidators(changes, this.formGroup.controls.mainFormGroup);
    processBackendValidators(changes, this.formGroup.controls.seoFormGroup);
    processBackendValidators(changes, this.formGroup);

    if (changes['page'] !== null) {
      this.setFormData();
      this.selectedTab = 0;
    }
  }

  submit() {
    if (isFormValid(this.formGroup)) {
      this.update
        ? this.submitUpdate(this.submitted)
        : this.submitCreate(this.submitted);
    }
  }

  private createModal(currentStatus: SlugStatus) {
    if (currentStatus === SlugStatus.NO_CONFLICTS) {
      return;
    }
    this.modalService.info({
      nzTitle: this.translate.instant('pages.modal.slug-exists-title'),
      nzContent: this.translate.instant(
        'pages.modal.slug-exists-body-' + currentStatus.toLowerCase()
      ),
      nzBodyStyle: { 'white-space': 'pre-wrap' },
      nzOkText: this.translate.instant('common.add'),
      nzCancelText: this.translate.instant('common.cancel'),
      nzOnOk: () => {
        this.update
          ? this.submitUpdate(this.modalSubmitted)
          : this.submitCreate(this.modalSubmitted);
        this.resetRedirectExists.emit();
      },
      nzOnCancel: () => {
        this.resetRedirectExists.emit();
      },
    });
  }

  private submitCreate(
    typeSubmit: EventEmitter<{
      model: PageCreateModel | PageUpdateModel;
    }>
  ) {
    const mainFormGroup = this.formGroup.controls.mainFormGroup.controls;
    const seoFormGroup = this.formGroup.controls.seoFormGroup.controls;
    typeSubmit.emit({
      model: {
        title: mainFormGroup.title.value ?? '',
        parentId: this.parentId,
        isVisible: this.formGroup.controls.isVisible.value ?? true,
        pageType: mainFormGroup.pageType.value ?? PageType.Page,
        metaTitle: seoFormGroup.metaTitle.value ?? '',
        metaDescription: seoFormGroup.metaDescription.value ?? '',
        metaKeywords: seoFormGroup.metaKeywords.value ?? '',
        slug: seoFormGroup.slug.value ?? '',
        contentBlocks: mainFormGroup.contentBlocks.value ?? [],
      } as PageCreateModel,
    });
  }

  private submitUpdate(
    typeSubmit: EventEmitter<{
      model: PageCreateModel | PageUpdateModel;
    }>
  ) {
    const mainFormGroup = this.formGroup.controls.mainFormGroup.controls;
    const seoFormGroup = this.formGroup.controls.seoFormGroup.controls;

    typeSubmit.emit({
      model: {
        id: this.formGroup.controls.id.value!,
        title: mainFormGroup.title.value ?? '',
        parentId: this.parentId,
        isVisible: this.formGroup.controls.isVisible.value ?? true,
        pageType: mainFormGroup.pageType.value ?? PageType.Page,
        metaTitle: seoFormGroup.metaTitle.value ?? '',
        metaDescription: seoFormGroup.metaDescription.value ?? '',
        metaKeywords: seoFormGroup.metaKeywords.value ?? '',
        slug: seoFormGroup.slug.value ?? '',
        contentBlocks: mainFormGroup.contentBlocks.value ?? [],
      } as PageUpdateModel,
    });
  }

  deleteChildPage($event: number): void {
    this.store.dispatch(
      PageApiActions.DeleteChildPage({ parentId: this.id!, id: $event })
    );
  }

  onChangeVisible($event: { id: number; isVisible: boolean }): void {
    this.store.dispatch(
      PageApiActions.ChangeVisibility({
        id: $event.id,
        isVisible: $event.isVisible,
      })
    );
  }

  onChangeSequence($event: PageChangeSequenceModel): void {
    this.store.dispatch(PageApiActions.ChangeSequence($event));
  }

  onQueryParamsChange($event: any): void {
    const { pageSize, pageIndex, sort } = $event;
    const currentSort = sort.find(
      (item: { value: null }) => item.value !== null
    );
    const sortField = (currentSort?.key) ?? null;
    const sortOrder = (currentSort?.value) ?? null;

    this.store.dispatch(
      PageApiActions.ChildPagesFilterOverview({
        parentId: this.formGroup.controls.id.value!,
        sorting: {
          propertyName: sortField ?? '',
          order: sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
        },
        pagination: {
          page: pageIndex,
          pageSize,
        },
      })
    );
  }

  onResetChildPages(): void {
    this.store.dispatch(PageApiActions.ResetChildPages({ parentId: this.id! }));
  }

  private setFormData() {
    this.formGroup.controls.mainFormGroup.controls.contentBlocks.clear();

    this.id = this.page?.id ?? null;

    this.formGroup.patchValue({
      isVisible: this.page?.isVisible ?? true,

      mainFormGroup: {
        title: this.page?.title ?? '',
        pageType: this.page?.pageType ?? PageType.Page,
        contentBlocks: this.page?.contentBlocks ?? [],
      },
      seoFormGroup: {
        metaTitle: this.page?.metaTitle ?? '',
        metaDescription: this.page?.metaDescription ?? '',
        metaKeywords: this.page?.metaKeywords ?? '',
        slug: this.page?.slug ?? '',
      },
    });

    (this.page?.contentBlocks ?? []).forEach((contentBlock) =>
      this.formGroup.controls.mainFormGroup.controls.contentBlocks.push(
        new ContentBlockFormGroup(contentBlock)
      )
    );

    if (this.update) {
      const idFormControl = this.formGroup.controls.id;
      idFormControl.setValidators([Validators.required]);
      idFormControl.setValue((this.page as PageUpdateModel).id);
    }
  }
}
