<form nz-form [formGroup]="formGroup">
    <nz-form-item class="mb-sm">
        <nz-form-control [nzSpan]="24" [nzErrorTip]="noteMessageValidationTemplate">
            <textarea rows="4" nz-input formControlName="noteMessage"></textarea>
            <ng-template #noteMessageValidationTemplate let-control>
                <app-input-validation
                    [control]="control"
                    [field]="'noteMessage'"
                    [errors]="this.errors"></app-input-validation>
            </ng-template>
        </nz-form-control>
    </nz-form-item>

    <div class="create-note-actions">
        <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
        <label
            nz-checkbox
            formControlName="isImportant"
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Note,
                    claimValues: [ClaimValues.Create]
                }
            ]">
            {{ 'note.detail.important' | translate }}
        </label>
        <button
            nz-button
            nzType="primary"
            class="secondary"
            (click)="makeNote()"
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Note,
                    claimValues: [ClaimValues.Create]
                }
            ]">
            {{ 'note.detail.edit-title' | translate }}
        </button>
    </div>
</form>
