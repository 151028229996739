import * as ActivityScheduleActions from '@app/mobile/modules/activity-schedule/actions/activity-schedule.actions';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
  list: [],
  loading: false,
};

export interface State {
  list: KeyValueModel[];
  loading: boolean;
}

export const reducer = createReducer(
  initialState,
  on(ActivityScheduleActions.GetList, (state) => ({
    ...state,
    loading: true,
  })),
  on(ActivityScheduleActions.GetListSuccess, (state, response) => ({
    ...state,
    list: response.list,
    loading: false,
  })),
  on(ActivityScheduleActions.GetListError, (state) => ({
    ...state,
    loading: false,
  }))
);

export const getList = (state: State) => state.list;
export const getLoading = (state: State) => state.loading;
