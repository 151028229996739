import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import * as PageApiActions from '@app/modules/page/actions/page-api.actions';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';
import { PageService } from "@app/modules/page/services/page.service";

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const pageService = inject(PageService);
  const router = inject(Router);
  const store = inject(Store<State>);

  return pageService.get(route.params['id']).pipe(
    map((page) => PageApiActions.GetSuccess(page)),
    tap((action: any) => store.dispatch(action)),
    map((page) => !!page),
    catchError(() => {
      return router.navigate(['/not-found']);
    })
  );
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  canActivate(route, state);
