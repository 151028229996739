import { Component, OnInit } from '@angular/core';
import { DemoTableItemModel } from '@app/modules/demo/models/demo-table.model';
import { TableDataModel } from '@app/shared/models';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam } from 'ng-zorro-antd/upload';

@Component({
    selector: 'app-demo-overview',
    templateUrl: './demo-overview.component.html',
    styleUrls: ['./demo-overview.component.less'],
})
export class DemoOverviewComponent implements OnInit {
    date = new Date();

    drawerOpen = false;
    modelOpen = false;

    tableLoading = false;
    tableData: TableDataModel<DemoTableItemModel> = {
        pagination: {
            page: 1,
            pageSize: 10,
            totalPages: 2,
            totalItems: 20,
        },
        list: [],
        sorting: {
            propertyName: '',
            order: ''
        },
        filter: []
    };

    constructor(private messageService: NzMessageService) { }

    ngOnInit(): void {
        this.tableData.list = new Array(100).fill(0).map((_, index) => ({
            id: index,
            name: `Edward King ${index}`,
            address: `London, Park Lane no. ${index}`,
            date: this.date.toDateString(),
            age: Math.floor(Math.random() * (55 - 20 + 1) + 20),
            disabled: index % 2 === 0,
        }));
    }

    onDeleteConfirm(name: string): void {
        this.messageService.success(`Deleted item ${name}`);
    }

    uploadHandle({ file, fileList }: NzUploadChangeParam): void {
        const status = file.status;
        if (status !== 'uploading') {
          console.log(file, fileList);
        }
        if (status === 'done') {
          this.messageService.success(`${file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          this.messageService.error(`${file.name} file upload failed.`);
        }
    }

}