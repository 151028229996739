import { ErrorResponse } from "@app/core/models/error-response.model";
import { ActivityTypeTableItemModel, ActivityTypeUpdateModel, ActivityTypeChangeSequenceModel, ActivityTypeCreateModel } from "@app/modules/activity-type/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[ActivityType] GetOverview");
export const GetOverviewError = createAction("[ActivityType] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[ActivityType] GetOverviewSuccess", props<TableDataModel<ActivityTypeTableItemModel>>());
export const ResetOverview = createAction("[ActivityType] ResetOverview");

export const FilterOverview = createAction("[ActivityType] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[ActivityType] Clear");

export const Get = createAction("[ActivityType] Get", props<{ id: number }>());
export const GetError = createAction("[ActivityType] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[ActivityType] GetSuccess", props<ActivityTypeUpdateModel>());

export const Update = createAction("[ActivityType] Update", props<ActivityTypeUpdateModel>());
export const UpdateError = createAction("[ActivityType] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[ActivityType] UpdateSuccess", props<ActivityTypeUpdateModel>());

export const ChangeSequence = createAction("[ActivityType] ChangeSequence", props<ActivityTypeChangeSequenceModel>());
export const ChangeSequenceError = createAction("[ActivityType] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[ActivityType] ChangeSequenceSuccess");

export const Create = createAction("[ActivityType] Create", props<ActivityTypeCreateModel>());
export const CreateError = createAction("[ActivityType] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[ActivityType] CreateSuccess", props<ActivityTypeUpdateModel>());

export const Delete = createAction("[ActivityType] Delete", props<{ id: number }>());
export const DeleteError = createAction("[ActivityType] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[ActivityType] DeleteSuccess");