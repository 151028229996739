<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>{{ (!!formGroup.get('id')?.value ? "redirects.detail.edit-title" : "redirects.detail.add-title") | translate }}</h4>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "redirects.fields.from" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="FromValidationTemplate">
                <input nz-input [formControl]="this.formGroup.controls.from" />

                <ng-template #FromValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'from'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "redirects.fields.to" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="ToValidationTemplate">
                <input nz-input [formControl]="this.formGroup.controls.to" />

                <ng-template #ToValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'to'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <div class="button-container">
            <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
                {{ "common.save" | translate }}
            </button>
        </div>
    </nz-card>
</form>