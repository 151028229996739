import { Component } from '@angular/core';
import * as ClaimCategoryActions from '@app/modules/claim-categories/actions/claim-category-api.actions';
import * as ClaimCategory from '@app/modules/claim-categories/reducers';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
	selector: 'app-claim-category-overview',
	templateUrl: './claim-category-overview.component.html',
	styleUrls: ['./claim-category-overview.component.less'],
})
export class ClaimCategoryOverviewComponent {
	tableData$ = this.store.select(ClaimCategory.selectClaimCategoryOverviewState);

	constructor(private readonly store: Store) {}

	/**
	 * Trigger the loading of data whenever the table changes.
	 * The default pageSize, index and sort options are based on the TableStateModel.
	 * @param params
	 */
	onQueryParamsChange(params: NzTableQueryParams) {
		const { pageSize, pageIndex, sort, filter } = params;
		const currentSort = sort.find((item) => item.value !== null);
		const sortField = (currentSort?.key) ?? null;
		const sortOrder = (currentSort?.value) ?? null;

		this.store.dispatch(
			ClaimCategoryActions.FilterOverview({
				pagination: {
					page: pageIndex,
					pageSize,
				},
				sorting: {
					propertyName: sortField ?? '',
					order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
				},
			})
		);
	}
}
