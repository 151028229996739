import { NgModule } from '@angular/core';
import { FAQDetailFormComponent } from '@app/modules/faq/components/faq-detail-form/faq-detail-form.component';
import { FAQOverviewTableComponent } from '@app/modules/faq/components/faq-overview-table/faq-overview-table.component';
import { FAQEffects } from '@app/modules/faq/effects/faq.effects';
import { FAQRoutingModule } from '@app/modules/faq/faq-routing.module';
import { FAQDetailComponent } from '@app/modules/faq/pages/detail/faq-detail.component';
import { FAQOverviewComponent } from '@app/modules/faq/pages/overview/faq-overview.component';
import { faqFeatureKey, reducers } from '@app/modules/faq/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [FAQOverviewTableComponent, FAQOverviewComponent, FAQDetailFormComponent, FAQDetailComponent];

const IMPORTS = [
	SharedModule,
	FAQRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(faqFeatureKey, reducers),
	EffectsModule.forFeature([FAQEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class FAQModule {}
