import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MaterialUpdateModel,
  MaterialCreateModel,
} from '@app/modules/materials/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import {
  isFormValid,
  processBackendValidators,
} from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
  selector: 'app-material-detail-form',
  templateUrl: './material-detail-form.component.html',
  styleUrls: ['./material-detail-form.component.less'],
})
export class MaterialDetailFormComponent implements OnInit {
  update = false;
  readonly?: boolean;

  @Input() loading = false;
  @Input() errors: { [key: string]: string[] } = {};
  @Input({ required: true }) material: MaterialUpdateModel | null = null;

  @Output() submitted = new EventEmitter<
    MaterialCreateModel | MaterialUpdateModel
  >();

  formGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
    description: new FormControl<string>('', [Validators.maxLength(200)]),
    priceExclVAT: new FormControl<number>(0, [
      CustomValidators.required,
      CustomValidators.correctAmountDecimal(2),
      Validators.min(-99999999999999.99),
      Validators.max(99999999999999.99),
    ]),
    vatFactor: new FormControl<number>(0.21, [
      CustomValidators.required,
      Validators.min(0),
      Validators.max(100),
      Validators.maxLength(4),
      CustomValidators.correctAmountDecimal(1),
    ]),
    inQuotation: new FormControl<boolean>(false),
    includeVAT: new FormControl<boolean>(false),
  });

  constructor(private readonly claimService: ClaimService) {}

  async ngOnInit() {
    this.update = !!this.material;
    
    if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.Material, claimValues: [ClaimValues.Update] }])) {
        this.formGroup.disable();
        this.readonly = true;
    } else {
      this.readonly = false;
    }

    this.formGroup.patchValue({
      name: this.material?.name,
      description: this.material?.description,
      priceExclVAT: this.material?.priceExclVAT,
      vatFactor: this.material?.vatFactor ?? 21,
      inQuotation: this.material?.inQuotation,
    });

    if (this.update) {
      this.formGroup.controls.id.setValidators([CustomValidators.required]);
      this.formGroup.controls.id.setValue(
        (this.material as MaterialUpdateModel).id
      );
      this.formGroup.controls.vatFactor.setValue(
        100 * this.formGroup.controls.vatFactor.value!
      );
      this.formGroup.updateValueAndValidity();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    processBackendValidators(changes, this.formGroup);
  }

  submit() {
    if (isFormValid(this.formGroup)) {
      this.update ? this.submitUpdate() : this.submitCreate();
    }
  }
  
  private submitCreate() {
    this.submitted.emit({
      name: this.formGroup.controls.name.value!,
      description: this.formGroup.controls.description.value ?? '',
      priceExclVAT: this.formGroup.controls.priceExclVAT.value!,
      vatFactor: this.formGroup.controls.vatFactor.value! / 100,
      inQuotation: this.formGroup.controls.inQuotation.value ?? false,
    });
  }

  private submitUpdate() {
    this.submitted.emit({
      id: this.formGroup.controls.id.value!,
      name: this.formGroup.controls.name.value!,
      description: this.formGroup.controls.description.value ?? '',
      priceExclVAT: this.formGroup.controls.priceExclVAT.value!,
      vatFactor: this.formGroup.controls.vatFactor.value! / 100,
      inQuotation: this.formGroup.controls.inQuotation.value ?? false,
    });
  }
}
