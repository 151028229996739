import { Component } from '@angular/core';
import * as Activity from '@app/modules/activities/reducers';

import * as ActivityApiActions from '@app/modules/activities/actions/activities-api.actions';
import { ActivityCreateModel, ActivityUpdateModel } from '@app/modules/activities/models';
import { ActivityGenerateCode } from '@app/modules/activities/models/activity-generate-code.model';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-activities-detail',
    templateUrl: './activities-detail.component.html',
    styleUrls: ['./activities-detail.component.less'],
})
export class ActivityDetailComponent {
    loading$ = this.store.select(Activity.selectDetailLoading);
    errors$ = this.store.select(Activity.selectDetailErrors);
    activity$ = this.store.select(Activity.selectDetailActivity);
    activityTemplate$ = this.store.select(Activity.selectDetailActivityTemplate);
    activityTypes$ = this.store.select(Activity.selectActivityTemplateActivityTypes);
    activityLocations$ = this.store.select(Activity.selectActivityLocations);
    checkedCode$ = this.store.select(Activity.selectActivityCode);

    constructor(private readonly store: Store<State>) {
        this.store.dispatch(ActivityApiActions.GetActivityTypes());
        this.store.dispatch(ActivityApiActions.GetActivityLocations());
    }

    checkCode(code: ActivityGenerateCode) {
        this.store.dispatch(ActivityApiActions.CheckCode({ code }));
    }

    submit(activity: ActivityCreateModel | ActivityUpdateModel) {
        this.store.dispatch(
            activity.hasOwnProperty('id')
                ? ActivityApiActions.Update(activity as ActivityUpdateModel)
                : ActivityApiActions.Create(activity),
        );
    }
}
