<nz-form-item >
  <nz-form-label [nzSpan]="24" nzRequired>
   {{ title }}
  </nz-form-label>
  <nz-form-control
    [nzSpan]="24"
    [nzErrorTip]="currencyInputTemplate"
  >
    <ng-template #eurField><span class="eurSign">€</span></ng-template>
    <nz-input-group [nzPrefix]="eurField">
      <input
        class="currency-input"
        [formControl]="control"
        type="number"
        placeholder="0,00"
        nz-input
      />
    </nz-input-group>
    <ng-template #currencyInputTemplate let-control>
      <app-input-validation
        [control]="control"
        [field]="fieldName"
        [errors]="errors"
      ></app-input-validation>
    </ng-template>
  </nz-form-control>
</nz-form-item>
