import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { BillingInformationType } from "@app/shared/enums/billing-information.type";
import { BillingInformationModel } from "@app/shared/models/billing-information/billing-information.model";
import { Constants } from "@app/shared/models/common/constants";
import CustomValidators from "@app/shared/validators/custom.validator";

export class BillingInformationFormGroup extends FormGroup {
    constructor(
        billingInformation: BillingInformationModel, 
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, 
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super({
            id: new FormControl<number>(billingInformation.id ?? 0),
            name: new FormControl<string>(billingInformation.name ?? ''),
            sendInvoiceTo: new FormControl<BillingInformationType>(billingInformation.sendInvoiceTo ?? null),
            companyName: new FormControl<string>(billingInformation.companyName ?? '', [Validators.maxLength(100)]),
            countryId: new FormControl<number>(billingInformation.countryId ?? 0, CustomValidators.required),
            address: new FormControl<string>(billingInformation.address ?? '', [CustomValidators.required, Validators.maxLength(200)]),
            city: new FormControl<string>(billingInformation.city ?? '', [CustomValidators.required, Validators.maxLength(100)]),
            zipCode: new FormControl<string>(billingInformation.zipCode ?? '', [CustomValidators.required, Validators.maxLength(10)]),

            email: new FormControl<string>(billingInformation.emailAddress ?? '', [
                CustomValidators.required,
                Validators.email,
                Validators.pattern(Constants.EmailExpression),
            ]),

            vatNumber: new FormControl<string>(billingInformation.vatNumber ?? '', [Validators.maxLength(15)]),
        }, validatorOrOpts, asyncValidator);
    }
}