import { Component } from '@angular/core';
import * as BlogApiActions from '@app/modules/blogs/actions/blog-api.actions';
import * as Blog from '@app/modules/blogs/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-blog-overview',
  templateUrl: './blog-overview.component.html',
  styleUrls: ['./blog-overview.component.less'],
})
export class BlogOverviewComponent {
  tableData$ = this.store.select(Blog.selectBlogOverviewState);

  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;

  constructor(private readonly store: Store) {}

  onDeleteConfirm(id: any): void {
    this.store.dispatch(BlogApiActions.Delete({ id: id }));
  }

  /**
   * Trigger the loading of data whenever the table changes.
   * The default pageSize, index and sort options are based on the TableStateModel.
   * @param params
   */
  onQueryParamsChange(params: any) {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(
      (item: { value: null }) => item.value !== null
    );
    const sortField = (currentSort?.key) ?? null;
    const sortOrder = (currentSort?.value) ?? null;

    this.store.dispatch(
      BlogApiActions.FilterOverview({
        pagination: {
          page: pageIndex,
          pageSize,
        },
        sorting: {
          propertyName: sortField ?? '',
          order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
        },
      })
    );
  }

  onResetOverview() {
    this.store.dispatch(BlogApiActions.ResetOverview());
  }

  onChangeShowOnline(event: any) {
    this.store.dispatch(
      BlogApiActions.UpdateShowOnline({
        id: event.id,
        showOnline: event.showOnline,
      })
    );
  }
}
