import { NgModule } from '@angular/core';
import { CoreModule } from '@app/core/core.module';
import { ErrorRoutingModule } from '@app/modules/error/error-routing.module';
import { ForbiddenComponent } from '@app/modules/error/pages/forbidden/forbidden-page.component';
import { InternalServerErrorPageComponent } from '@app/modules/error/pages/internal-server-error/internal-server-error-page.component';
import { NotFoundComponent } from '@app/modules/error/pages/not-found/not-found-page.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
	declarations: [ForbiddenComponent, NotFoundComponent, InternalServerErrorPageComponent],
	imports: [ErrorRoutingModule, CoreModule, SharedModule],
})
export class ErrorModule {}
