
<div class="page-header">
    <a routerLink="/blog-categorieen/overzicht" class="page-header__back">
        <i class="fa-regular fa-chevron-left"></i>
    </a>
    <h1 class="page-header__title">{{ "blog-categories.title" | translate }}</h1>
    <div class="page-header__actions"></div>
</div>

<app-blog-category-detail-form [blogCategory]="blogCategory$ | async" (submitted)="submit($event)" [errors]="errors$ | async" [loading]="loading$ | async"/>
