import * as TagApiActions from '@app/modules/tag/actions/tag-api.actions';
import { TagTableItemModel } from '@app/modules/tag/models/tag-table.model';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = new TableOverviewModel<TagTableItemModel>('Name');

export interface State extends TableStateModel<TagTableItemModel> {}

export const reducer = createReducer(
	initialState,
	on(TagApiActions.GetOverview, (state) => ({ ...state, loading: true })),
	on(TagApiActions.GetOverviewSuccess, (state, response) => ({
		...state,
		...response,
		loading: false,
	})),
	on(TagApiActions.GetOverviewError, (state) => ({
		...state,
		loading: false,
	})),
	on(TagApiActions.FilterOverview, (state, { sorting, pagination }) => ({
		...state,
		sorting: applySortingOrDefault(initialState, sorting),
		pagination: {
			...state.pagination,
			page: pagination.page,
			pageSize: pagination.pageSize,
		},
	}))
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
