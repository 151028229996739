import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-activities-template-modal',
    templateUrl: './activities-template-modal.component.html',
})
export class ActivityTemplateModalComponent implements OnInit {
    readonly nzModalData = inject(NZ_MODAL_DATA);
    templateNames$!: Observable<KeyValueModel[]>;

    constructor(private readonly modal: NzModalRef, private readonly router: Router) {}

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    draggingEnabled = false;
    chosenTemplate = -1;

    ngOnInit(): void {
        this.templateNames$ = this.nzModalData.templateNames;
    }

    destroyModal(): void {
        this.modal.destroy();
    }

    selectTemplate(): void {
        this.router.navigate(['activiteiten', 'toevoegen', ...[this.chosenTemplate !== -1 ? this.chosenTemplate : []]]);
        this.modal.destroy();
    }
}
