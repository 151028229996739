import { Injectable, OnDestroy } from '@angular/core';
import { DirectUrlModel } from '@app/shared/models';
import Flmngr from '@flmngr/flmngr-angular';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { HttpService } from '@app/core/services/http/http.service';
import { AssetFile } from '@app/shared/models/assetmanager/asset-file.model';

@Injectable({
  providedIn: 'root',
})
export class AssetService implements OnDestroy {
  private readonly endpoints = {
    filemanager: 'Asset/FileManager',
    files: 'AssetFile',
    directUrl: 'Asset/DirectUrl',
  };

  private subscriptions = new Subscription();
  private acceptedExtensions = ['png', 'jpg', 'jpeg', 'gif', 'webp', 'pdf', 'doc', 'docx', 'docm', 'xls', 'xlsx'];

  constructor(private readonly httpService: HttpService) {
    Flmngr.load({
      apiKey: 'FLMNFLMN',
      urlFileManager: httpService.getFullUrl(this.endpoints.filemanager),
      urlFiles: httpService.getFullUrl(this.endpoints.files),
    });
  }

  chooseFile(
    allowMultiple: boolean,
    callback: (files: AssetFile[]) => void
  ): void {
    Flmngr.open({
      isMultiple: allowMultiple,
      acceptExtensions: this.acceptedExtensions,
      onFinish: (files) => {
        const promises: Observable<DirectUrlModel>[] = [];
        const assetFiles: AssetFile[] = [];

        files.forEach((file) => {
          promises.push(this.getDirectUrl(file.url));
        });

        this.subscriptions.add(forkJoin(promises).subscribe({
          next: (results) => {
            results.forEach((result, index) => {
              const file = files[index];
              const name = this.getName(file.url);
              file.url = result.url;

              assetFiles.push({
                ...file,
                name: name
              });
            });
          },
          complete: () => {
            if (callback) {
              callback(assetFiles);
            }
          },
        }));
      },
    });
  }

  getDirectUrl(url: string): Observable<DirectUrlModel> {
    return this.httpService.post<DirectUrlModel>(this.endpoints.directUrl, {
      ImagePath: url,
    });
  }

  ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

  private getName(url: string) {
    return url.split('/').pop() ?? '';
  }
}
