<form nz-form [formGroup]="formGroup">
  <nz-card>
    <h4>
      {{
        (!!formGroup.get("id")?.value
            ? "pages.detail.edit-title"
            : "pages.detail.add-title"
        ) | translate
      }}
    </h4>
    <div nz-row>
      <div nz-col nzFlex="auto" class="tab-column">
        <div class="tab-container">
          <nz-tabset nzType="card" [(nzSelectedIndex)]="selectedTab">
            <nz-tab [nzTitle]="titleTemplate" [nzForceRender]="true">
              <ng-template #titleTemplate>
                <div
                  [ngClass]="{
                    'form-group-invalid':
                      formGroup.controls.mainFormGroup.invalid
                  }"
                >
                  {{ "pages.detail.content" | translate }}
                </div>
              </ng-template>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                    "pages.detail.title" | translate
                  }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="titleValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      formGroup.controls.mainFormGroup.controls.title
                    "
                  />

                  <ng-template #titleValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'title'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item
                [formGroup]="this.formGroup.controls.mainFormGroup"
              >
                <nz-form-label [nzSpan]="24" nzRequired
                >{{ "pages.detail.content" | translate }}
                </nz-form-label>

                <nz-form-control
                  [nzSpan]="24"
                >
                  <app-input-validation
                    [control]="
                      formGroup.controls.mainFormGroup.controls.contentBlocks
                    "
                    [field]="'contentBlocks'"
                    [errors]="errors"
                  ></app-input-validation>

                  <app-content-block-overview
                    *ngIf="readonly !== undefined"
                    nz-input
                    ngDefaultControl
                    [isReadOnly]="readonly"
                    [availableContentTypes]="availableContentTypes"
                    [errors]="errors"
                    formArrayName="contentBlocks"
                  ></app-content-block-overview>
                </nz-form-control>
              </nz-form-item>
            </nz-tab>
            <nz-tab [nzTitle]="childPagesTemplate" [nzForceRender]="true" *ngIf="update && childPagesData !== null">
              <ng-template #childPagesTemplate>
                <div>
                  {{ "pages.detail.child-pages" | translate }}
                </div>
              </ng-template>
              <app-child-pages-overview-table
                [data]="childPagesData!"
                [parentId]="id!"
                (delete)="deleteChildPage($event)"
                (onChangeVisible)="onChangeVisible($event)"
                (changeSequence)="onChangeSequence($event)"
                (onQueryParamsChange)="onQueryParamsChange($event)"
                (onResetChildPages)="onResetChildPages()"
              ></app-child-pages-overview-table>
            </nz-tab>
            <nz-tab [nzTitle]="seoTemplate">
              <ng-template #seoTemplate>
                <div
                  [ngClass]="{
                    'form-group-invalid':
                      formGroup.controls.seoFormGroup.invalid
                  }"
                >
                  {{ "pages.detail.seo" | translate }}
                </div>
              </ng-template>
              <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{
                    "pages.detail.meta-title" | translate
                  }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="metaTitleValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      this.formGroup.controls.seoFormGroup.controls.metaTitle
                    "
                  />
                  <ng-template #metaTitleValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'metaTitle'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                    "pages.detail.keywords" | translate
                  }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="metaKeywordsValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      this.formGroup.controls.seoFormGroup.controls.metaKeywords
                    "
                  />
                  <ng-template #metaKeywordsValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'metaKeywords'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                    "pages.detail.description" | translate
                  }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="metaDescriptionValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      this.formGroup.controls.seoFormGroup.controls.metaDescription
                    "
                  />
                  <ng-template #metaDescriptionValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'metaDescription'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label nzRequired [nzSpan]="24">{{
                    "pages.detail.slug" | translate
                  }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="slugValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="
                      this.formGroup.controls.seoFormGroup.controls.slug
                    "
                  />

                  <ng-template #slugValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'slug'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>

      <aside nz-col class="aside">
        <nz-card nzTitle="{{ 'pages.detail.information' | translate }}">
            <nz-form-item>
              <nz-form-label [nzSpan]="24">{{
                  "pages.detail.isVisible" | translate
                }}</nz-form-label>
                <nz-form-control [nzSpan]="24">
                <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                <label
                  nz-checkbox
                  [nzValue]="page?.isVisible"
                  formControlName="isVisible"
                >
                </label>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item *ngIf="pageTypes">
            <nz-form-label [nzSpan]="24" nzRequired>{{
                "pages.detail.page-type" | translate
              }}</nz-form-label>
            <nz-form-control
              [nzSpan]="24"
              [nzErrorTip]="pageTypeValidationTemplate"
            >
              <nz-select [formControl]="formGroup.controls.mainFormGroup.controls.pageType">
                <nz-option
                  *ngFor="let pageType of pageTypes"
                  [nzLabel]="('pages.detail.page-types.' + pageType.value) | translate"
                  [nzValue]="pageType.key"
                ></nz-option>
              </nz-select>

              <ng-template #pageTypeValidationTemplate let-control>
                <app-input-validation
                  [control]="control"
                  [field]="'pageType'"
                  [errors]="errors"
                ></app-input-validation>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </nz-card>
      </aside>
    </div>

    <div class="button-container">
      <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
        {{ "common.save" | translate }}
      </button>
    </div>
  </nz-card>
</form>
