import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AssetFormGroup } from '@app/shared/models/assetmanager';
import { AssetService } from '@app/shared/services/asset.service';

@Component({
    selector: 'app-asset',
    templateUrl: './asset.component.html',
    styleUrl: './asset.component.less'
})
export class AssetComponent {
    @Input({ required: true }) isReadOnly!: boolean;
    @Input({ required: false }) formGroup!: AssetFormGroup;
    @Input({ required: true }) errors!: { [key: string]: string[] };
    @Input({ required: true }) fieldName!: string;
    @Input() required: boolean = false;
    @Input() maxAssets: number = 0;
    @Input() label: string = '';

    @Output() formGroupChange = new EventEmitter<AssetFormGroup>();

    constructor(private assetService: AssetService) { }

    protected addAsset(): void {
        this.assetService.chooseFile(false, (files) => {
            files.forEach(file => {
                this.formGroup.patchValue({ url: file.url, name: file.name });
            })

            this.formGroup.updateValueAndValidity();
            this.formGroup.markAsDirty();
            this.formGroupChange.emit(this.formGroup);
        });
    }

    protected removeAsset(): void {
        this.formGroup.reset();
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAsDirty();
        this.formGroupChange.emit(this.formGroup);
    }
}