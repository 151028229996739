import * as ActivityApiActions from '@app/modules/activity/actions/activity-api.actions';
import { ActivityUpdateModel } from '@app/modules/activity/models';
import { ActivityAvailableParticipantsModel } from '@app/modules/activity/models/activity-available-participants.model';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
    activity: {} as ActivityUpdateModel,
    loading: false,
    errors: {},
    availableParticipants: {} as ActivityAvailableParticipantsModel,
};

export interface State {
    activity: ActivityUpdateModel;
    loading: boolean;
    errors: { [key: string]: string[] };
    availableParticipants: ActivityAvailableParticipantsModel;
}

export const reducer = createReducer(
    initialState,
    on(ActivityApiActions.Get, (state) => ({ ...state, loading: true })),
    on(ActivityApiActions.GetSuccess, (state, activity) => ({
        ...state,
        activity,
        loading: false,
        availableParticipants: {
            availableStudent: activity.availableStudent,
            availableStudentSecondTime: activity.availableStudentSecondTime,
            availableCatchUp: activity.availableCatchUp,
            availableGuest: activity.availableGuest,
        } as ActivityAvailableParticipantsModel,
    })),
    on(ActivityApiActions.GetError, (state) => ({ ...state, loading: false })),

    on(ActivityApiActions.UpdateParticipantsSuccess, (state, participants) => ({
        ...state,
        availableParticipants: participants,
    })),
);

export const getLoading = (state: State) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getActivity = (state: State) => state.activity;
export const getAvailableSpots = (state: State) => state.availableParticipants;
