export enum ActivityAvailableParticipantsEnum {
    Student = 1,
    StudentSecondTime = 2,
    CatchUp = 3,
    GuestClient = 4,
    Guest = 5
}

export const ActivityAvailableParticipantsLabels: Record<number, string> = {
    [ActivityAvailableParticipantsEnum.Student]: "activity.enum.student",
    [ActivityAvailableParticipantsEnum.StudentSecondTime]: "activity.enum.student-second-time",
    [ActivityAvailableParticipantsEnum.CatchUp]: "activity.enum.catch-up",
    [ActivityAvailableParticipantsEnum.GuestClient]: "activity.enum.guest-client",
    [ActivityAvailableParticipantsEnum.Guest]: "activity.enum.guest",
}