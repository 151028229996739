import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '@app/shared/models/auth/user.model';
import { UserRoleTableItemModel } from '@app/modules/user-roles/models';
import { UserCreateModel, UserUpdateModel } from '@app/modules/users/models';
import { UserExistsModel } from '@app/modules/users/models/user-exists.model';
import { UserSignatureModel } from '@app/modules/users/models/user-signature.model';
import { AssetFormGroup } from '@app/shared/models/assetmanager';
import { ClaimTypes, ClaimValues, Constants } from '@app/shared/models/common/constants';
import { ContentBlockFormGroup, ContentBlockType } from '@app/shared/models/content';
import { ClaimService } from '@app/shared/services/claim.service';
import {
	clearValidators,
	isFormValid,
	processBackendValidators,
	setValidators,
} from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { pairwise, startWith } from 'rxjs';

@Component({
	selector: 'app-user-detail-form',
	templateUrl: './user-detail-form.component.html',
	styleUrl: './user-detail-form.component.less',
})
export class UserDetailFormComponent implements OnInit, OnChanges {
	private static readonly USER_TAB: number = 0;
	private static readonly EMPLOYEE_TAB: number = 1;

	update = false;
	readonly?: boolean;
	tabIndex: number = UserDetailFormComponent.USER_TAB;
	availableContentTypes = [ContentBlockType.Editor, ContentBlockType.ImageAndEditor];

	@Input() loading = false;
	@Input() errors: { [key: string]: string[] } = {};
	@Input({ required: true }) user: UserUpdateModel | null = null;
	@Input({ required: true }) currentUser: UserModel | null = null;
	@Input({ required: true }) userRoles: UserRoleTableItemModel[] | null = [];
	@Input({ required: true }) userExists!: UserExistsModel;

	@Output() submitted = new EventEmitter<UserCreateModel | UserUpdateModel>();
	@Output() downloadSignature = new EventEmitter<UserSignatureModel>();
	@Output() resetUserExists = new EventEmitter<void>();

	formGroup = new FormGroup({
		userFormGroup: new FormGroup({
			id: new FormControl<string | null>(null),
			fullname: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
			email: new FormControl<string>('', [
				CustomValidators.required,
				Validators.email,
				Validators.pattern(Constants.EmailExpression),
			]),
			password: new FormControl<string>('', [
				CustomValidators.required, 
				Validators.pattern(Constants.PasswordExpression)
			]),
			passwordConfirmation: new FormControl<string>('', [CustomValidators.required]),
			pin: new FormControl<string>('', [Validators.minLength(5), Validators.maxLength(5), Validators.pattern("[0-9]*")]),
			active: new FormControl<boolean>(true, [CustomValidators.required]),
			roles: new FormControl<string[]>([], [CustomValidators.required]),
			isTrainer: new FormControl<boolean>(false),
			isEmployee: new FormControl<boolean>(false),
		},
		{
			validators: [CustomValidators.match('password', 'passwordConfirmation')],
		}),
		employeeFormGroup: new FormGroup({
			online: new FormControl<boolean>(false),
			showInBCC: new FormControl<boolean>(false),
			image: new AssetFormGroup(),
			signature: new FormControl<UserSignatureModel | null>(null),
			function: new FormControl<string>(''),
			text: new FormArray<ContentBlockFormGroup>([]),
		}),
	});

	constructor(private readonly claimService: ClaimService, private readonly modalService: NzModalService, private readonly translate: TranslateService) {}

	async ngOnInit() {
		this.update = !!this.user;

		if (
			this.update &&
			!(await this.claimService.hasAny([{ claimType: ClaimTypes.BackOfficeUser, claimValues: [ClaimValues.Update] }]))
		) {
			this.formGroup.disable();
			this.readonly = true;
		} else {
			this.readonly = false;
		}

		this.formGroup.patchValue({
			userFormGroup: {
				id: this.user?.id,
				fullname: this.user?.fullName,
				email: this.user?.email,
				password: this.user?.password,
				pin: this.user?.pin,
				active: this.user?.active ?? true,
				roles: this.user?.roles,
				isTrainer: this.user?.isTrainer,
				isEmployee: this.user?.isEmployee,
			},
			employeeFormGroup: {
				online: this.user?.employee?.online,
				showInBCC: this.user?.employee?.showInBCC,
				image: this.user?.employee?.image,
				signature: this.user?.employee?.signature,
				function: this.user?.employee?.function,
			},
		});

		(this.user?.employee?.contentBlocks ?? []).forEach((contentBlock) =>
			this.formGroup.controls.employeeFormGroup.controls.text.push(new ContentBlockFormGroup(contentBlock))
		);

		if (this.update) {
			this.formGroup.controls.userFormGroup.controls.email.disable()
			setValidators(this.formGroup.controls.userFormGroup.controls.id, [CustomValidators.required]);
			clearValidators(this.formGroup.controls.userFormGroup.controls.password);
			this.formGroup.controls.userFormGroup.clearValidators();
			clearValidators(this.formGroup.controls.userFormGroup.controls.passwordConfirmation);

			if (this.user?.isEmployee) {
				this.setEmployeeFormValidators();
			}
		}

		this.formGroup.controls.userFormGroup.controls.isEmployee.valueChanges
			.pipe(startWith(this.formGroup.controls.userFormGroup.controls.isEmployee.value), pairwise())
			.subscribe({
				next: ([prevIsEmployee, currentIsEmployee]) => {
					if (prevIsEmployee === currentIsEmployee) return;

					if (currentIsEmployee) {
						this.tabIndex = UserDetailFormComponent.EMPLOYEE_TAB;
						this.setEmployeeFormValidators();
					} else {
						this.tabIndex = UserDetailFormComponent.USER_TAB;
						clearValidators(this.formGroup.controls.employeeFormGroup.controls.image);
						clearValidators(this.formGroup.controls.employeeFormGroup.controls.signature);
						clearValidators(this.formGroup.controls.employeeFormGroup.controls.function);
						clearValidators(this.formGroup.controls.employeeFormGroup.controls.text);
					}
				},
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		processBackendValidators(changes, this.formGroup.controls.userFormGroup);
		processBackendValidators(changes, this.formGroup.controls.employeeFormGroup);

		if (this.userExists.websiteUserExists && !this.userExists.backOfficeUserExists) {
			this.modalService.info({
				nzTitle: this.translate.instant('users.modal.website-user-exists-title'),
				nzContent: this.translate.instant('users.modal.website-user-exists-body'),
				nzBodyStyle: { 'white-space': 'pre-wrap' },
				nzOkText: this.translate.instant('common.add'),
				nzCancelText: this.translate.instant('common.cancel'),
				nzOnOk: () => {
					this.submitCreate();
					this.userExists = { backOfficeUserExists: false, websiteUserExists: false };
				},
				nzOnCancel: () => {
					this.resetUserExists.emit();
				},
			});
		}
	}

	protected submit() {
		if (isFormValid(this.formGroup)) {
			this.update ? this.submitUpdate() : this.submitCreate();
		}
	}

	private submitCreate() {
		const userFormGroup = this.formGroup.get('userFormGroup');
		const employeeFormGroup = this.formGroup.get('employeeFormGroup');

		this.submitted.emit({
			fullName: userFormGroup?.get('fullname')?.value,
			email: userFormGroup?.get('email')?.value,
			password: userFormGroup?.get('password')?.value,
			pin: userFormGroup?.get('pin')?.value,
			active: userFormGroup?.get('active')?.value,
			roles: userFormGroup?.get('roles')?.value,
			isTrainer: userFormGroup?.get('isTrainer')?.value,
			isEmployee: userFormGroup?.get('isEmployee')?.value,
			employee: {
				online: employeeFormGroup?.get('online')?.value,
				showInBCC: employeeFormGroup?.get('showInBCC')?.value,
				image: employeeFormGroup?.get('image')?.value,
				signature: employeeFormGroup?.get('signature')?.value,
				function: employeeFormGroup?.get('function')?.value,
				contentBlocks: employeeFormGroup?.get('text')?.value,
			},
		} as UserCreateModel);
	}

	private submitUpdate() {
		const userFormGroup = this.formGroup.get('userFormGroup');
		const employeeFormGroup = this.formGroup.get('employeeFormGroup');

		this.submitted.emit({
			id: userFormGroup?.get('id')?.value,
			fullName: userFormGroup?.get('fullname')?.value,
			password: userFormGroup?.get('password')?.value,
			pin: userFormGroup?.get('pin')?.value,
			active: userFormGroup?.get('active')?.value,
			roles: userFormGroup?.get('roles')?.value,
			isTrainer: userFormGroup?.get('isTrainer')?.value,
			isEmployee: userFormGroup?.get('isEmployee')?.value,
			employee: {
				online: employeeFormGroup?.get('online')?.value,
				showInBCC: employeeFormGroup?.get('showInBCC')?.value,
				image: employeeFormGroup?.get('image')?.value,
				signature: employeeFormGroup?.get('signature')?.value,
				function: employeeFormGroup?.get('function')?.value,
				contentBlocks: employeeFormGroup?.get('text')?.value,
			},
		} as UserUpdateModel);
	}

	protected getSignature() {
		const signature = this.formGroup.get('employeeFormGroup')?.get('signature')?.value;

		this.downloadSignature.emit({
			blobReference: signature?.blobReference,
			name: signature?.name,
		} as UserSignatureModel);
	}

	private setEmployeeFormValidators(): void {
		setValidators(this.formGroup.controls.employeeFormGroup.controls.image, [CustomValidators.assetRequired()]);
		setValidators(this.formGroup.controls.employeeFormGroup.controls.signature, [CustomValidators.required]);
		setValidators(this.formGroup.controls.employeeFormGroup.controls.function, [CustomValidators.required]);
		setValidators(this.formGroup.controls.employeeFormGroup.controls.text, [CustomValidators.minArrayLength(1)]);
	}

	protected addSignature(event: any): void {
		(event.target.files as File[]).forEach((file: File) => {
			const reader: FileReader = new FileReader();
			reader.onload = () => {
				if (!reader.result) return;

				const dataUrl = reader.result.toString();

				this.formGroup.controls.employeeFormGroup.controls.signature.setValue({
					content: dataUrl.substring(dataUrl.indexOf(',') + 1),
					name: file.name,
					contentType: file.type,
				});

				event.target.value = '';
			};

			reader.readAsDataURL(file);
		});
	}

	protected removeSignature(): void {
		this.formGroup.controls.employeeFormGroup.controls.signature.reset();
		this.formGroup.controls.employeeFormGroup.controls.signature.updateValueAndValidity();
		this.formGroup.controls.employeeFormGroup.controls.signature.markAsDirty();
	}
}
