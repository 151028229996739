import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { MaterialOverviewComponent } from '@app/modules/materials/pages/overview/material-overview.component';
import { MaterialDetailComponent } from '@app/modules/materials/pages/detail/material-detail.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { MaterialRoutingModule } from '@app/modules/materials/material-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  materialFeatureKey,
  reducers,
} from '@app/modules/materials/reducers';
import { MaterialEffects } from '@app/modules/materials/effects/material.effects';
import { MaterialOverviewTableComponent } from '@app/modules/materials/components/material-overview-table/material-overview-table.component';
import { MaterialDetailFormComponent } from '@app/modules/materials/components/material-detail-form/material-detail-form.component';

const COMPONENTS = [
  MaterialOverviewComponent,
  MaterialDetailComponent,
  MaterialOverviewTableComponent,
  MaterialDetailFormComponent,
];

const IMPORTS = [
  SharedModule,
  MaterialRoutingModule,
  NzDividerModule,
  StoreModule.forFeature(materialFeatureKey, reducers),
  EffectsModule.forFeature([MaterialEffects]),
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [IMPORTS],
  providers: [],
})
export class MaterialsModule {}
