import { ErrorResponse } from "@app/core/models/error-response.model";
import { EmailTemplateType } from "@app/modules/email-template/enums";
import { EmailTemplateCreateModel } from "@app/modules/email-template/models/email-template-create.model";
import { EmailTemplateDefaultModel } from "@app/modules/email-template/models/email-template-default.model";
import { EmailTemplateTableItemModel } from "@app/modules/email-template/models/email-template-table.model";
import { EmailTemplateUpdateModel } from "@app/modules/email-template/models/email-template-update.model";
import { TableDataModel } from "@app/shared/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[EmailTemplate] GetOverview");
export const GetOverviewError = createAction("[EmailTemplate] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[EmailTemplate] GetOverviewSuccess", props<TableDataModel<EmailTemplateTableItemModel>>());
export const ResetOverview = createAction("[EmailTemplate] ResetOverview");

export const FilterOverview = createAction("[EmailTemplate] FilterOverview", props<{ emailTemplateType: EmailTemplateType | null, sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[EmailTemplate] Clear");

export const Get = createAction("[EmailTemplate] Get", props<{ id: number }>());
export const GetError = createAction("[EmailTemplate] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[EmailTemplate] GetSuccess", props<EmailTemplateUpdateModel>());

export const Update = createAction("[EmailTemplate] Update", props<EmailTemplateUpdateModel>());
export const UpdateError = createAction("[EmailTemplate] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[EmailTemplate] UpdateSuccess", props<EmailTemplateUpdateModel>());

export const Create = createAction("[EmailTemplate] Create", props<EmailTemplateCreateModel>());
export const CreateError = createAction("[EmailTemplate] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[EmailTemplate] CreateSuccess", props<EmailTemplateUpdateModel>());

export const Delete = createAction("[EmailTemplate] Delete", props<{ id: number }>());
export const DeleteError = createAction("[EmailTemplate] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[EmailTemplate] DeleteSuccess");

export const GetDefault = createAction("[EmailTemplate] GetDefault", props<EmailTemplateDefaultModel>());
export const GetDefaultError = createAction("[EmailTemplate] GetDefaultError", props<{ response: ErrorResponse }>());
export const GetDefaultSuccess = createAction("[EmailTemplate] GetDefaultSuccess", props<EmailTemplateUpdateModel>());
export const GetDefaultImport = createAction("[EmailTemplate] GetDefaultImport", props<EmailTemplateUpdateModel>());

export const SetEmailTemplateType = createAction("[EmailTemplate] SetEmailTemplateType", props<{ emailTemplateType: EmailTemplateType }>());

export const GetRelationTypes = createAction("[EmailTemplate] GetRelationTypes");
export const GetRelationTypesError = createAction("[EmailTemplate] GetRelationTypesError", props<{ response: ErrorResponse }>());
export const GetRelationTypesSuccess = createAction("[EmailTemplate] GetRelationTypesSuccess", props<{ relationTypes: KeyValueModel[] }>());