export enum ResultStatus {
    Open = 1,
    Finished = 2,
    Cancelled = 3,
}

export const ResultStatusLabels: Record<number, string> = {
    [ResultStatus.Open]: 'activity.enum.open',
    [ResultStatus.Finished]: 'activity.enum.finished',
    [ResultStatus.Cancelled]: 'activity.enum.cancelled',
}