import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { EmailTemplateType } from '@app/modules/email-template/enums';
import { EmailTemplateCreateModel } from '@app/modules/email-template/models/email-template-create.model';
import { EmailTemplateDefaultModel } from '@app/modules/email-template/models/email-template-default.model';
import { EmailTemplateTableItemModel } from '@app/modules/email-template/models/email-template-table.model';
import { EmailTemplateUpdateModel } from '@app/modules/email-template/models/email-template-update.model';
import { TableDataModel } from '@app/shared/models/table/table-data.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class EmailTemplateService {

	private readonly endpoints = {
		get: 'EmailTemplate/:id',
		overview: 'EmailTemplate/GetOverview',
		update: 'EmailTemplate/Update',
		create: 'EmailTemplate/Create',
		delete: 'EmailTemplate/Delete?id=:id',
		default: 'EmailTemplate/GetDefault?relationTypeId=:relationTypeId&emailTemplateType=:emailTemplateType'
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(
		sorting: TableSortingModel,
		pagination: TablePaginationModel, 
		emailTemplateType: EmailTemplateType
	): Observable<TableDataModel<EmailTemplateTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');
		const emailTemplateTypeParam = `emailTemplateType=${emailTemplateType}`;

		return this.httpService.get<TableDataModel<EmailTemplateTableItemModel>>(`${this.endpoints.overview}?${paginationParams}&${sortingParams}&${emailTemplateTypeParam}`);
	}

	get(id: number): Observable<EmailTemplateUpdateModel> {
		return this.httpService.get<EmailTemplateUpdateModel>(this.endpoints.get.replace(':id', id.toString()));
	}

	update(request: EmailTemplateUpdateModel): Observable<EmailTemplateUpdateModel> {
		return this.httpService.put<EmailTemplateUpdateModel>(this.endpoints.update, request);
	}

	create(request: EmailTemplateCreateModel): Observable<EmailTemplateUpdateModel> {
		return this.httpService.post<EmailTemplateUpdateModel>(this.endpoints.create, request);
	}

	delete(id: number): Observable<void> {
		return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
	}

	getDefault(request: EmailTemplateDefaultModel): Observable<EmailTemplateUpdateModel> {
		return this.httpService.get<EmailTemplateUpdateModel>(this.endpoints.default.replace(':relationTypeId', request.relationTypeId.toString()).replace(':emailTemplateType', request.emailTemplateType.toString()));
    }
}
