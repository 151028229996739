import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { EmailTemplateService } from '@app/modules/email-template/services/email-template.service';
import * as EmailTemplateActions from '@app/modules/email-template/actions/email-template-api.actions';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const emailTemplateService = inject(EmailTemplateService);
	const router = inject(Router);
	const store = inject(Store<State>);

	return emailTemplateService.get(route.params['id']).pipe(
		map((emailTemplate) => EmailTemplateActions.GetSuccess(emailTemplate)),
		tap((action: any) => store.dispatch(action)),
		map((emailTemplate) => !!emailTemplate),
		catchError(() => {
			return router.navigate(['/not-found']);
		})
	);
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);
