import * as PageApiActions from "@app/modules/page/actions/page-api.actions"
import { PageTableItemModel } from "@app/modules/page/models/page-table.model"
import { TableOverviewModel } from "@app/shared/models/table/table-overview.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model"
import { applySortingOrDefault } from "@app/shared/utilities/overview-reducer.utilities"
import { createReducer, on } from "@ngrx/store"

const initialState: State = new TableOverviewModel<PageTableItemModel>('title' );

export interface State extends TableStateModel<PageTableItemModel> { }

export const reducer = createReducer(initialState,
  on(PageApiActions.GetOverview, (state) => ({ ...state, loading: true })),
  on(PageApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
  on(PageApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
  on(PageApiActions.FilterOverview, (state, { sorting, pagination }) => ({
    ...state,
    sorting: applySortingOrDefault(initialState, sorting),
    pagination: {
      ...state.pagination,
      page: pagination.page,
      pageSize: pagination.pageSize
    }
  })),
  on(PageApiActions.ResetOverview,  () => ({ ...initialState }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
