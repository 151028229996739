import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { TableDataModel } from '@app/shared/models';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';
import {
  TagCreateModel,
  TagTableItemModel,
  TagUpdateModel
} from "@app/modules/tag/models";

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private readonly endpoints = {
    get: 'Tag/:id',
    overview: 'Tag/GetOverview',
    update: 'Tag/Update',
    create: 'Tag/Create',
    delete: 'Tag/Delete?id=:id',
    getAllNames: 'Tag/GetAllNames',
    createMultiple: 'Tag/CreateMultiple',
  };

  constructor(private readonly httpService: HttpService) {}

  getAll(
    sorting: TableSortingModel,
    pagination: TablePaginationModel
  ): Observable<TableDataModel<TagTableItemModel>> {
    const paginationParams = parse(pagination, 'pagination');
    const sortingParams = parse(sorting, 'sorting');

    return this.httpService.get<any>(
      this.endpoints.overview + `?${paginationParams}&${sortingParams}`
    );
  }

  get(id: number): Observable<TagUpdateModel> {
    return this.httpService.get<any>(
      this.endpoints.get.replace(':id', id.toString())
    );
  }

  getAllNames(searchQuery: any): Observable<any> {
    return this.httpService.post<any>(this.endpoints.getAllNames,{ SearchQuery: searchQuery["searchQuery"] ?? '', selectedTags: searchQuery["selectedTags"] });
  }

  update(request: TagUpdateModel): Observable<TagUpdateModel> {
    return this.httpService.put<any>(this.endpoints.update, request);
  }

  create(request: TagCreateModel): Observable<TagUpdateModel> {
    return this.httpService.post<any>(this.endpoints.create, request);
  }

  delete(id: number): Observable<any> {
    return this.httpService.delete<any>(
      this.endpoints.delete.replace(':id', id.toString())
    );
  }
}
