import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { ApplicationType } from '@app/modules/activity-relation/enum/application-type.enum';
import { ActivityRelationCreateModel } from '@app/modules/activity-relation/models/activity-relation-create.model';
import { ActivityRelationTableItemModel } from '@app/modules/activity-relation/models/activity-relation-table.model';
import { ActivityRelationUpdateModel } from '@app/modules/activity-relation/models/activity-relation-update.model';
import { ActivityAvailableParticipantsLabels } from '@app/modules/activity/enums/activity-available-participants';
import { ParticipantStatus, ParticipantStatusLabels } from '@app/modules/activity/enums/participant-status.enum';
import { PaymentStatus, PaymentStatusIcons } from '@app/modules/activity/enums/payment-status.enum';
import { ActivityUpdateModel } from '@app/modules/activity/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-activity-relation-overview-table',
    templateUrl: './activity-relation-overview-table.component.html',
    styleUrls: ['./activity-relation-overview-table.component.less'],
})
export class ActivityRelationTablePartComponent {
    @Input({ required: true }) data: TableStateModel<ActivityRelationTableItemModel>;
    @Input({ required: true }) activityRelation: ActivityRelationUpdateModel;
    @Input({ required: true }) backgroundList: KeyValueModel[];
    @Input({ required: true }) waitlist: boolean;

    @Input({ required: true }) activity: ActivityUpdateModel;

    @Input({ required: true }) relations$: Observable<KeyValueModel[]>;

    @Output() delete = new EventEmitter<{ id: number; isReservation: boolean }>();

    @Output() search = new EventEmitter<{ searchQuery: string; skipCount: number }>();
    @Output() create = new EventEmitter<ActivityRelationCreateModel | ActivityRelationUpdateModel>();

    @Output() getRelation = new EventEmitter<number>();
    @Output() resetOverview = new EventEmitter<void>();

    destroyRef = inject(DestroyRef);

    participantStatusMapping = ParticipantStatusLabels;
    participantActivityStatus = ParticipantStatus;

    paymentStatusMapping = PaymentStatusIcons;
    paymentActivityStatus = PaymentStatus;

    applicationType = ApplicationType;
    ActivityAvailableParticipantsMapping = ActivityAvailableParticipantsLabels;

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    draggingEnabled = false;
}
