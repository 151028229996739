<form nz-form [formGroup]="formGroup">
	<nz-card>
		<h4>
			{{
				(!!formGroup.get('id')?.value ? 'blog-categories.detail.edit-title' : 'blog-categories.detail.add-title')
					| translate
			}}
		</h4>
		<nz-form-item>
			<nz-form-label [nzSpan]="24" nzRequired>{{ 'common.name' | translate }}</nz-form-label>
			<nz-form-control [nzSpan]="24" [nzErrorTip]="titleValidationTemplate">
				<input nz-input [formControl]="formGroup.controls.title" />

				<ng-template #titleValidationTemplate let-control>
					<app-input-validation [control]="control" [field]="'title'" [errors]="errors"></app-input-validation>
				</ng-template>
			</nz-form-control>
		</nz-form-item>

		<nz-form-item>
			<nz-form-label [nzSpan]="24" nzRequired>{{ 'common.slug' | translate }}</nz-form-label>
			<nz-form-control [nzSpan]="24" [nzErrorTip]="slugValidationTemplate">
				<input nz-input [formControl]="formGroup.controls.slug" />

				<ng-template #slugValidationTemplate let-control>
					<app-input-validation [control]="control" [field]="'slug'" [errors]="errors"></app-input-validation>
				</ng-template>
			</nz-form-control>
		</nz-form-item>

		<div class="button-container">
			<button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
				{{ 'common.save' | translate }}
			</button>
		</div>
	</nz-card>
</form>
