import { Component } from '@angular/core';
import * as BlogApiActions from '@app/modules/blogs/actions/blog-api.actions';
import { BlogCreateModel, BlogUpdateModel } from '@app/modules/blogs/models';
import * as Blog from '@app/modules/blogs/reducers';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.less'],
})
export class BlogDetailComponent {
  loading$ = this.store.select(Blog.selectDetailLoading);
  errors$ = this.store.select(Blog.selectDetailErrors);
  blog$ = this.store.select(Blog.selectDetailBlog);
  employees$ = this.store.select(Blog.selectDetailEmployees);
  tags$ = this.store.select(Blog.selectDetailTags);

  constructor(private readonly store: Store<State>) {
    this.store.dispatch(BlogApiActions.GetEmployees());
  }

  submit(blog: BlogCreateModel | BlogUpdateModel) {
    this.store.dispatch(
      blog.hasOwnProperty('id')
        ? BlogApiActions.Update(blog as BlogUpdateModel)
        : BlogApiActions.Create(blog)
    );
  }

  tagSearch(searchWord: any) {
    this.store.dispatch(
      BlogApiActions.GetAllTags({
        searchQuery: searchWord.searchQuery,
        selectedTags: searchWord.selectedTags,
      })
    );
  }
}
