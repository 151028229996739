import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { materialClearGuard, materialExistsGuard } from '@app/modules/materials/guards';
import { MaterialDetailComponent } from '@app/modules/materials/pages/detail/material-detail.component';
import { MaterialOverviewComponent } from '@app/modules/materials/pages/overview/material-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: MaterialOverviewComponent },
    { path: 'toevoegen', component: MaterialDetailComponent, canActivate: [materialClearGuard] },
    { path: 'bewerken/:id', component: MaterialDetailComponent, canActivate: [materialExistsGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MaterialRoutingModule { }
