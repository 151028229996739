import * as EmailTemplateApiActions from "@app/modules/email-template/actions/email-template-api.actions";
import { createReducer, on } from "@ngrx/store";
import { EmailTemplateUpdateModel } from "@app/modules/email-template/models/email-template-update.model";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { EmailTemplateType } from "@app/modules/email-template/enums";

export const initialState: State = {
    emailTemplate: null,
    loading: false,
    errors: {},
    defaultEmailTemplate: null,
    relationTypes: null,
    emailTemplateType: EmailTemplateType.PasswordResetMail
}

export interface State {
    emailTemplate: EmailTemplateUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] },
    defaultEmailTemplate: EmailTemplateUpdateModel | null,
    relationTypes: KeyValueModel[] | null,
    emailTemplateType: EmailTemplateType
}

export const reducer = createReducer(initialState,
    on(EmailTemplateApiActions.Clear, () => ({ ...initialState })),

    on(EmailTemplateApiActions.Get, (state) => ({ ...state, loading: true })),
    on(EmailTemplateApiActions.GetSuccess, (state, emailTemplate) => ({ ...state, emailTemplate, loading: false, defaultEmailTemplate: null })),
    on(EmailTemplateApiActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(EmailTemplateApiActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(EmailTemplateApiActions.UpdateSuccess, (state, emailTemplate) => ({ ...state, emailTemplate, loading: false })),
    on(EmailTemplateApiActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(EmailTemplateApiActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(EmailTemplateApiActions.CreateSuccess, (state, emailTemplate) => ({ ...state, emailTemplate, loading: false })),
    on(EmailTemplateApiActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(EmailTemplateApiActions.GetDefault, (state) => ({ ...state, loading: true, errors: {} })),
    on(EmailTemplateApiActions.GetDefaultSuccess, (state) => ({ ...state, loading: false })),
    on(EmailTemplateApiActions.GetDefaultImport, (state, defaultEmailTemplate) => ({ ...state, defaultEmailTemplate, loading: false })),
    on(EmailTemplateApiActions.GetDefaultError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(EmailTemplateApiActions.GetRelationTypes, (state) => ({ ...state, loading: true })),
    on(EmailTemplateApiActions.GetRelationTypesSuccess, (state, { relationTypes }) => ({ ...state, relationTypes, loading: false })),
    on(EmailTemplateApiActions.GetRelationTypesError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(EmailTemplateApiActions.SetEmailTemplateType, (state, { emailTemplateType }) => ({ ...state, emailTemplateType }))
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getEmailTemplate = (state: State) => state.emailTemplate;
export const getDefaultEmailTemplate = (state: State) => state.defaultEmailTemplate;
export const getRelationTypes = (state: State) => state.relationTypes;
export const getEmailTemplateType = (state: State) => state.emailTemplateType;