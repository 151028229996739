import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenComponent } from '@app/modules/error/pages/forbidden/forbidden-page.component';
import { InternalServerErrorPageComponent } from '@app/modules/error/pages/internal-server-error/internal-server-error-page.component';
import { NotFoundComponent } from '@app/modules/error/pages/not-found/not-found-page.component';

const routes: Routes = [
	{ path: 'forbidden', component: ForbiddenComponent },
	{ path: 'not-found', component: NotFoundComponent },
	{ path: 'internal-server-error', component: InternalServerErrorPageComponent },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ErrorRoutingModule {}
