import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { BlogRoutingModule } from './blog-routing.module';

import { BlogDetailComponent } from '@app/modules/blogs/pages/detail/blog-detail.component';
import { BlogOverviewComponent } from '@app/modules/blogs/pages/overview/blog-overview.component';

import { NzTagModule } from 'ng-zorro-antd/tag';

import { BlogDetailFormComponent } from '@app/modules/blogs/components/blog-detail-form/blog-detail-form.component';
import { BlogOverviewTableComponent } from '@app/modules/blogs/components/blog-overview-table/blog-overview-table.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

import { BlogEffects } from '@app/modules/blogs/effects/blog.effects';
import { blogFeatureKey, reducers } from '@app/modules/blogs/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

const COMPONENTS = [
  BlogOverviewComponent,
  BlogOverviewTableComponent,
  BlogDetailComponent,
  BlogDetailFormComponent,
];

const IMPORTS = [
  NzTabsModule,
  SharedModule,
  NzTagModule,
  BlogRoutingModule,
  NzDividerModule,
  StoreModule.forFeature(blogFeatureKey, reducers),
  EffectsModule.forFeature([BlogEffects]),
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [IMPORTS],
  providers: [],
})
export class BlogModule {}
