import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { ActivityLocationOverviewComponent } from "@app/modules/activity-location/pages/overview/activity-location-overview.component";
import { ActivityLocationDetailComponent } from "@app/modules/activity-location/pages/detail/activity-location-detail.component";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ActivityLocationRoutingModule } from "@app/modules/activity-location/activity-location-routing.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { activityLocationFeatureKey, reducers } from "@app/modules/activity-location/reducers";
import { ActivityLocationEffects } from "@app/modules/activity-location/effects/activity-location.effects";
import { ActivityLocationOverviewTableComponent } from "@app/modules/activity-location/components/activity-location-overview-table/activity-location-overview-table.component";
import { ActivityLocationDetailFormComponent } from "@app/modules/activity-location/components/activity-location-detail-form/activity-location-detail-form.component";

const COMPONENTS = [
    ActivityLocationOverviewComponent,
    ActivityLocationDetailComponent,
    ActivityLocationOverviewTableComponent,
    ActivityLocationDetailFormComponent
];

const IMPORTS = [
    SharedModule,
    ActivityLocationRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(activityLocationFeatureKey, reducers),
    EffectsModule.forFeature([ActivityLocationEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class ActivityLocationsModule { }
