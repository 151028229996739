import { Injectable } from "@angular/core";
import { HttpService } from "@app/core/services/http/http.service";
import { LoginRequest } from "@app/mobile/modules/auth/models/login-request.model";
import { LoginResponse } from "@app/shared/models/auth/login-response.model";
import { Observable } from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class MobileAuthService {
	private readonly endpoints = {
		login: 'BackOfficeUser/Login/Mobile',
	};

	constructor(private readonly httpService: HttpService) {}

	login(request: LoginRequest): Observable<LoginResponse> {
		return this.httpService.post<LoginResponse>(this.endpoints.login, request);
	}
}