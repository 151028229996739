import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as UserRoleActions from '@app/modules/user-roles/actions/user-role-api.actions';
import { UserRoleService } from '@app/modules/user-roles/services/user-role.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const userRoleService = inject(UserRoleService);
	const router = inject(Router);
	const store = inject(Store<State>);

	return userRoleService.get(route.params['id']).pipe(
		map((userRole) => UserRoleActions.GetSuccess(userRole)),
		tap((action: any) => store.dispatch(action)),
		map((userRole) => !!userRole),
		catchError(() => {
			return router.navigate(['/not-found']);
		})
	);
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);
