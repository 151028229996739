<nz-table
	#overviewTable
	[nzData]="data.list"
	[nzPageIndex]="data.pagination.page"
	[nzPageSize]="data.pagination.pageSize"
	[nzTotal]="data.pagination.totalItems ?? 0"
	[nzLoading]="data.loading"
	[nzFrontPagination]="false"
	[nzShowPagination]="!draggingEnabled"
	(nzQueryParams)="onQueryParamsChange.emit($event)"
	nzTableLayout="fixed">
	<thead>
		<tr>
			<th
				nzColumnKey="sequenceNumber"
				nzAlign="center"
				nzWidth="40px"
				[nzShowSort]="false"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.BlogCategory,
						claimValues: [ClaimValues.Update]
					}
				]">
				<label nz-checkbox [(ngModel)]="draggingEnabled" (ngModelChange)="draggingChanged()"></label>
			</th>
			<th nzColumnKey="title" [nzSortFn]="true" [nzShowSort]="!draggingEnabled">
				{{ 'common.title' | translate }}
			</th>
			<th
				nzWidth="120px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.BlogCategory,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]"></th>
		</tr>
	</thead>
	<tbody cdkDropList (cdkDropListDropped)="drop($event)">
		<tr *ngFor="let row of overviewTable.data" cdkDrag [cdkDragData]="row" [cdkDragDisabled]="!draggingEnabled">
			<td
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.BlogCategory,
						claimValues: [ClaimValues.Update]
					}
				]"
				nzAlign="center">
				<i class="fa-solid fa-grip-dots-vertical" cdkDragHandle></i>
			</td>
			<td>{{ row.title | truncateText }}</td>
			<td
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.BlogCategory,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]">
				<div class="table-actions">
					<a
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.BlogCategory,
								claimValues: [ClaimValues.Read]
							}
						]"
						[routerLink]="['/blog-categorieen/bewerken', row.id]"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-pen"></i>
					</a>
					<button
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.BlogCategory,
								claimValues: [ClaimValues.Delete]
							}
						]"
						nz-button
						nzDanger
						nzType="text"
						nzShape="circle"
						nz-popconfirm
						nzOkDanger
						[nzPopconfirmTitle]="'notification.delete' | translate"
						[nzOkText]="'common.delete' | translate"
						[nzCancelText]="'common.cancel' | translate"
						[nzIcon]="templateIconDelete"
						nzPopconfirmPlacement="left"
						(nzOnConfirm)="delete.emit(row.id)">
						<i class="fa-regular fa-trash-can"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</nz-table>

<ng-template #templateIconDelete>
	<i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
