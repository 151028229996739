<nz-table
	#overviewTable
	[nzData]="data.list"
	[nzPageIndex]="data.pagination.page"
	[nzPageSize]="data.pagination.pageSize"
	[nzTotal]="data.pagination.totalItems ?? 0"
	[nzLoading]="data.loading"
	[nzFrontPagination]="false"
	(nzQueryParams)="onQueryParamsChange.emit($event)"
	nzTableLayout="fixed">
	<thead>
		<tr>
			<th nzColumnKey="name" [nzSortFn]="true" [nzSortOrder]="'ascend'">
				{{ 'user-roles.fields.name' | translate }}
			</th>
			<th
				nzWidth="120px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.UserRole,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of overviewTable.data">
			<td>{{ row.name | truncateText }}</td>
			<td
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.UserRole,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]">
				<div class="table-actions">
					<a
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.UserRole,
								claimValues: [ClaimValues.Read]
							}
						]"
						[routerLink]="['/gebruikersgroepen/bewerken', row.id]"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-pen"></i>
					</a>
					<button
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.UserRole,
								claimValues: [ClaimValues.Delete]
							}
						]"
						nz-button
						nzDanger
						nzType="text"
						nzShape="circle"
						nz-popconfirm
						nzOkDanger
						[nzPopconfirmTitle]="getTranslationKey(row.linkedUserCount) | translate : { count: row.linkedUserCount }"
						[nzOkText]="'common.delete' | translate"
						[nzCancelText]="'common.cancel' | translate"
						[nzIcon]="templateIconDelete"
						nzPopconfirmPlacement="left"
						(nzOnConfirm)="delete.emit(row.id)">
						<i class="fa-regular fa-trash-can"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</nz-table>

<ng-template #templateIconDelete>
	<i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
