import { ErrorResponse } from "@app/core/models/error-response.model";
import { UserRoleCreateModel, UserRoleTableItemModel, UserRoleUpdateModel } from "@app/modules/user-roles/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[UserRole] GetOverview");
export const GetOverviewError = createAction("[UserRole] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[UserRole] GetOverviewSuccess", props<TableDataModel<UserRoleTableItemModel>>());

export const FilterOverview = createAction("[UserRole] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[UserRole] Clear");

export const GetRoleClaims = createAction("[UserRole] GetRoleClaims");
export const GetRoleClaimsSuccess = createAction("[UserRole] GetRoleClaimsSuccess", props<UserRoleUpdateModel>());

export const Get = createAction("[UserRole] Get", props<{ id: string }>());
export const GetError = createAction("[UserRole] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[UserRole] GetSuccess", props<UserRoleUpdateModel>());

export const Update = createAction("[UserRole] Update", props<UserRoleUpdateModel>());
export const UpdateError = createAction("[UserRole] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[UserRole] UpdateSuccess", props<UserRoleUpdateModel>());

export const Create = createAction("[UserRole] Create", props<UserRoleCreateModel>());
export const CreateError = createAction("[UserRole] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[UserRole] CreateSuccess", props<UserRoleUpdateModel>());

export const Delete = createAction("[UserRole] Delete", props<{ id: string }>());
export const DeleteError = createAction("[UserRole] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[UserRole] DeleteSuccess");