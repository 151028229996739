import * as NoteApiActions from '@app/modules/note/actions/note-api.actions';
import { NoteType } from '@app/modules/note/enum/note-type.enum';
import { NoteItemModel } from '@app/modules/note/models/note-overview.model';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
    notes: [],
    noteType: null,
    loading: false,
    errors: {},
    noteTypeId: null,
    note: '',
};

export interface State {
    notes: NoteItemModel[];
    loading: boolean;
    errors: { [key: string]: string[] };
    noteType: NoteType | null;
    noteTypeId: number | null;
    note: string;
}

export const reducer = createReducer(
    initialState,

    on(NoteApiActions.SetNoteType, (state, noteType) => ({
        ...state,
        noteType: noteType.noteType,
        noteTypeId: noteType.noteTypeId,
    })),

    on(NoteApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(NoteApiActions.GetOverviewSuccess, (state, notes) => ({ ...state, notes: notes.data, loading: false })),
    on(NoteApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),

    on(NoteApiActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(NoteApiActions.UpdateSuccess, (state) => ({ ...state, loading: false })),
    on(NoteApiActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {},})),

    on(NoteApiActions.Create, (state, model) => ({ ...state, note: model.noteMessage, loading: true, errors: {} })),
    on(NoteApiActions.CreateSuccess, (state) => ({ ...state, note: '' ,loading: false })),
    on(NoteApiActions.CreateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    on(NoteApiActions.SetImportant, (state) => ({ ...state, loading: true, errors: {} })),
    on(NoteApiActions.SetImportantSuccess, (state) => ({ ...state, loading: false })),
    on(NoteApiActions.SetImportantError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    // general
    on(NoteApiActions.ResetOverview, () => ({ ...initialState })),
);

export const getNotes = (state: State) => state.notes;
export const getNoteType = (state: State) => state.noteType;
export const getNoteTypeId = (state: State) => state.noteTypeId;
export const getLoading = (state: State) => state.loading;
export const getErrors = (state: State) => state.errors;
export const getCurrentNote = (state: State) => state.note;
