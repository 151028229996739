import * as ActivityTypeActions from "@app/modules/activity-type/actions/activity-type-api.actions"
import { ActivityTypeUpdateModel } from "@app/modules/activity-type/models";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    activityType: null,
    loading: false,
    errors: {}
}

export interface State {
    activityType: ActivityTypeUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(ActivityTypeActions.Clear, () => ({ ...initialState })),

    on(ActivityTypeActions.Get, (state) => ({ ...state, loading: true })),
    on(ActivityTypeActions.GetSuccess, (state, activityType) => ({ ...state, activityType, loading: false })),
    on(ActivityTypeActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityTypeActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityTypeActions.UpdateSuccess, (state, activityType) => ({ ...state, activityType, loading: false })),
    on(ActivityTypeActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityTypeActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityTypeActions.CreateSuccess, (state, activityType) => ({ ...state, activityType, loading: false })),
    on(ActivityTypeActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getActivityType = (state: State) => state.activityType;