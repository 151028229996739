import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivityLocationTableItemModel } from '@app/modules/activity-location/models/activity-location-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
    selector: 'app-activity-location-overview-table',
    templateUrl: './activity-location-overview-table.component.html',
})
export class ActivityLocationOverviewTableComponent {
    @Input({ required: true }) data!: TableStateModel<ActivityLocationTableItemModel>;

    @Output() delete = new EventEmitter<number>();
    @Output() onQueryParamsChange = new EventEmitter<any>();

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
}