import { Injectable } from '@angular/core';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as ActivityRelationActions from '@app/modules/activity-relation/actions/activity-relation-api.actions';
import * as ActivityRelation from '@app/modules/activity-relation/reducers';
import { ActivityRelationService } from '@app/modules/activity-relation/services/activity-relation.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class ActivityRelationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly activityRelationService: ActivityRelationService,
        private readonly messageService: NzMessageService,
        private readonly translate: TranslateService,
        private readonly store: Store<ActivityRelation.State>,
    ) {}

    /**
     * Get effects
     */
    loadActivityRelations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.GetOverview),
            concatLatestFrom(() => [
                this.store.select(ActivityRelation.selectActivityOverviewPagination),
                this.store.select(ActivityRelation.selectActivityRelationOverviewSorting),
                this.store.select(ActivityRelation.selectChosenActivityId),
                this.store.select(ActivityRelation.selectActivityRelationOverviewSortingThen),
                this.store.select(ActivityRelation.selectActivityRelationOverviewFilter),
            ]),
            exhaustMap(([, pagination, sorting, activityId, sortingThen, filter]) =>
                this.activityRelationService.getOverview(sorting, pagination, activityId, sortingThen, filter).pipe(
                    map((response) => ActivityRelationActions.GetOverviewSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.GetOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadActivityRelationsFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.FilterOverview),
            map(() => ActivityRelationActions.GetOverview()),
        ),
    );

    /**
     * Get acitvityRelation effects
     */

    loadActivityRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.GetActivityRelation),
            exhaustMap((action) =>
                this.activityRelationService.getActivityRelation(action.id).pipe(
                    map((response) => ActivityRelationActions.GetActivityRelationSuccess({ response })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.GetActivityRelationError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Get waitlist effects
     */
    loadActivityWaitlistRelations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.GetWaitlistOverview),
            concatLatestFrom(() => [
                this.store.select(ActivityRelation.selectActivityWaitlistOverviewPagination),
                this.store.select(ActivityRelation.selectActivityRelationWaitlistOverviewSorting),
                this.store.select(ActivityRelation.selectChosenActivityId),
                this.store.select(ActivityRelation.selectActivityRelationWaitlistOverviewSortingThen),
                this.store.select(ActivityRelation.selectActivityRelationWaitlistOverviewFilter),
            ]),
            exhaustMap(([, pagination, sorting, activityId, sortingThen, filter]) =>
                this.activityRelationService.getOverview(sorting, pagination, activityId, sortingThen, filter).pipe(
                    map((response) => ActivityRelationActions.GetWaitlistOverviewSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.GetWaitlistOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadActivityWaitlistRelationsFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.FilterWaitlistOverview),
            map(() => ActivityRelationActions.GetWaitlistOverview()),
        ),
    );

    /**
     * get backgrounds effects
     */
    loadBackgroundList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.GetBackgroundList),
            exhaustMap(() =>
                this.activityRelationService.getBackgroundList().pipe(
                    map((response) => ActivityRelationActions.GetBackgroundListSuccess({ response })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.GetBackgroundListError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * update  effects
     */

    updateActivityRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.Update),
            exhaustMap((action) =>
                this.activityRelationService.update(action).pipe(
                    map((activity) => ActivityRelationActions.UpdateSuccess(activity)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.UpdateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    updateActivityRelationSuccessToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.UpdateSuccess),
            tap(() =>
                this.translate
                    .get('activity-relation.notification.update')
                    .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
            ),
            switchMap(() => of(ActivityRelationActions.GetOverview(), ActivityRelationActions.GetWaitlistOverview())),
        ),
    );

    /**
     * create  effects
     */

    createActivityRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.Create),
            exhaustMap((action) =>
                this.activityRelationService.create(action).pipe(
                    map((activity) => ActivityRelationActions.CreateSuccess(activity)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.CreateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    createActivityRelationSuccessToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.CreateSuccess),
            tap(() =>
                this.translate
                    .get('activity-relation.notification.add')
                    .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
            ),
            switchMap(() => of(ActivityRelationActions.GetOverview(), ActivityRelationActions.GetWaitlistOverview())),
        ),
    );

    /**
     * create reservation effects
     */

    createReservationActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.AddReservation),
            concatLatestFrom(() => [this.store.select(ActivityRelation.selectChosenActivityId)]),
            exhaustMap(([, action]) =>
                this.activityRelationService.createReservation(action).pipe(
                    map((activity) => ActivityRelationActions.AddReservationSuccess(activity)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.AddReservationError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    createReservationSuccessToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.AddReservationSuccess),
            tap(() =>
                this.translate
                    .get('activity.notification.add-reservation')
                    .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
            ),
            switchMap(() => of(ActivityRelationActions.GetOverview(), ActivityRelationActions.GetWaitlistOverview())),
        ),
    );

    /**
     * delete effects
     */

    deleteActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.Delete),
            exhaustMap((action) =>
                this.activityRelationService.delete(action.id).pipe(
                    map(() => ActivityRelationActions.DeleteSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.DeleteError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    deleteReservationActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.DeleteReservation),
            exhaustMap((action) =>
                this.activityRelationService.deleteReservation(action.id).pipe(
                    map(() => ActivityRelationActions.DeleteReservationSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityRelationActions.DeleteError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    deleteActivitySuccessReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityRelationActions.DeleteSuccess, ActivityRelationActions.DeleteReservationSuccess),
            map(() => ActivityRelationActions.GetOverview()),
        ),
    );

    deleteActivitySuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityRelationActions.DeleteSuccess),
                tap(() =>
                    this.translate
                        .get('notification.delete-success')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    deleteActivityReservationSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityRelationActions.DeleteReservationSuccess),
                tap(() =>
                    this.translate
                        .get('activity.notification.remove-reservation')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );
}
