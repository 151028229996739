import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
    ActivityTypeChangeSequenceModel,
    ActivityTypeCreateModel,
    ActivityTypeTableItemModel,
    ActivityTypeUpdateModel,
} from '@app/modules/activity-type/models';
import { ActivityTypeCodeModel } from '@app/modules/activity-type/models/activity-type-code.model';
import { TableDataModel } from '@app/shared/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityTypeService {
    private readonly endpoints = {
        get: 'ActivityType/:id',
        overview: 'ActivityType/GetOverview',
        update: 'ActivityType/Update',
        create: 'ActivityType/Create',
        delete: 'ActivityType/Delete?id=:id',
        changeSequence: 'ActivityType/ChangeSequence/',
        getAll: 'ActivityType/GetAll',
        getCodesAll: 'ActivityType/GetCodesAll',
    };

    constructor(private readonly httpService: HttpService) {}

    getOverview(
        sorting: TableSortingModel,
        pagination: TablePaginationModel,
    ): Observable<TableDataModel<ActivityTypeTableItemModel>> {
        const paginationParams = parse(pagination, 'pagination');
        const sortingParams = parse(sorting, 'sorting');

        return this.httpService.get<TableDataModel<ActivityTypeTableItemModel>>(
            this.endpoints.overview + `?${paginationParams}&${sortingParams}`,
        );
    }

    getAll(): Observable<KeyValueModel[]> {
        return this.httpService.get<KeyValueModel[]>(this.endpoints.getAll);
    }

    getCodesAll(): Observable<ActivityTypeCodeModel[]> {
        return this.httpService.get<ActivityTypeCodeModel[]>(this.endpoints.getCodesAll);
    }

    get(id: number): Observable<ActivityTypeUpdateModel> {
        return this.httpService.get<ActivityTypeUpdateModel>(this.endpoints.get.replace(':id', id.toString()));
    }

    update(request: ActivityTypeUpdateModel): Observable<ActivityTypeUpdateModel> {
        return this.httpService.put<ActivityTypeUpdateModel>(this.endpoints.update, request);
    }

    create(request: ActivityTypeCreateModel): Observable<ActivityTypeUpdateModel> {
        return this.httpService.post<ActivityTypeUpdateModel>(this.endpoints.create, request);
    }

    delete(id: number): Observable<void> {
        return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
    }

    changeSequence(request: ActivityTypeChangeSequenceModel): Observable<void> {
        return this.httpService.put<void>(this.endpoints.changeSequence, request);
    }
}
