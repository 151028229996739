import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { 
	ReviewChangeSequenceModel, 
	ReviewCreateModel, 
	ReviewTableItemModel, 
	ReviewUpdateModel } 
	from '@app/modules/review/models';
import { TableDataModel } from '@app/shared/models';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ReviewService {
	private readonly endpoints = {
		get: 'Review/:id',
		overview: 'Review/GetOverview',
		update: 'Review/Update',
		create: 'Review/Create',
		delete: 'Review/Delete?id=:id',
		changeSequence: 'Review/ChangeSequence',
		online: 'Review/Online'
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(
		sorting: TableSortingModel,
		pagination: TablePaginationModel,
		filter: TableFilterModel[],
		activityTypeId?: number
	): Observable<TableDataModel<ReviewTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');
		const filterParams = parse(filter, 'filters');
		const activityParams = `activityTypeId=${activityTypeId ? activityTypeId : ''}`

		return this.httpService.get<TableDataModel<ReviewTableItemModel>>(this.endpoints.overview + `?${paginationParams}&${sortingParams}&${filterParams}&${activityParams}`);
	}

	get(id: number): Observable<ReviewUpdateModel> {
		return this.httpService.get<ReviewUpdateModel>(this.endpoints.get.replace(':id', id.toString()));
	}

	update(request: ReviewUpdateModel): Observable<ReviewUpdateModel> {
		return this.httpService.put<ReviewUpdateModel>(this.endpoints.update, request);
	}

	create(request: ReviewCreateModel): Observable<ReviewUpdateModel> {
		return this.httpService.post<ReviewUpdateModel>(this.endpoints.create, request);
	}

	delete(id: number): Observable<void> {
		return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
	}

	changeSequence(request: ReviewChangeSequenceModel): Observable<void> {
		return this.httpService.put<void>(this.endpoints.changeSequence, request);
	}

	online(id: number, online: boolean): Observable<ReviewUpdateModel> {
		return this.httpService.put<ReviewUpdateModel>(this.endpoints.online, { id, online });
	}
}
