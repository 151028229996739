import { Location } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: 'app-internal-server-error-page',
  templateUrl: './internal-server-error-page.component.html',
  styleUrls: ['./internal-server-error-page.component.less'],
})
export class InternalServerErrorPageComponent {
  constructor(private readonly location: Location) {}

  navigateBack(): void {
    this.location.back();
  }
}
