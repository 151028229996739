import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as PageApiActions from '@app/modules/page/actions/page-api.actions';
import { PageCreateModel, PageUpdateModel } from '@app/modules/page/models';
import * as Page from '@app/modules/page/reducers';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-page-detail',
  templateUrl: './page-detail.component.html',
  styleUrls: ['./page-detail.component.less'],
})
export class PageDetailComponent implements OnInit, OnDestroy {
  loading$ = this.store.select(Page.selectDetailLoading);
  errors$ = this.store.select(Page.selectDetailErrors);
  page$ = this.store.select(Page.selectDetailPage);
  childPagesData$ = this.store.select(Page.selectDetailChildPages);
  redirectExists$ = this.store.select(Page.selectDetailRedirectExists);

  private subscriptions = new Subscription();
  parentId: number | null = null;

  constructor(
    private readonly store: Store<State>,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.params
        .pipe(map((params) => params['parentId']))
        .subscribe((id) => {
          if (id) {
            this.parentId = parseInt(id);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(PageApiActions.Clear());
    this.subscriptions.unsubscribe();
  }

  submit(page: { model: PageCreateModel | PageUpdateModel }) {
    this.store.dispatch(
      PageApiActions.NewSlugAsRedirectExists({
        model: page.model,
      })
    );
  }

  modalSubmit(page: { model: PageCreateModel | PageUpdateModel }) {
    page.model.hasOwnProperty('id')
      ? this.store.dispatch(
          PageApiActions.Update(
            page.model as PageUpdateModel,
          )
        )
      : this.store.dispatch(PageApiActions.Create(page.model));
  }


  resetRedirectExists(): void {
    this.store.dispatch(PageApiActions.ResetRedirectExists());
  }
}
