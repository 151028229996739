import { Component } from '@angular/core';
import * as RelationApiActions from '@app/modules/relation/actions/relation-api.actions';
import * as Relation from '@app/modules/relation/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';
import { ClaimService } from '@app/shared/services/claim.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
    selector: 'app-relation-overview',
    templateUrl: './relation-overview.component.html',
    styleUrls: ['./relation-overview.component.less'],
})
export class RelationOverviewComponent {
    tableData$ = this.store.select(Relation.selectRelationOverviewState);
    hasReadClaims = this.claimService.has([{ claimType: ClaimTypes.Relation, claimValues: [ClaimValues.Read] }]);

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    constructor(private readonly store: Store, private readonly claimService: ClaimService) {}

    onChangeActive(param: { id: number }) {
        this.store.dispatch(
            RelationApiActions.ChangeActive({
                id: param.id
            }),
        );
    }

    onQueryParamsChange(params: NzTableQueryParams) {
        const { pageSize, pageIndex, sort } = params;
        const currentSort = sort.find((item) => item.value !== null);
        const sortField = currentSort?.key ?? null;
        const sortOrder = currentSort?.value ?? null;
        this.store.dispatch(
            RelationApiActions.FilterOverview({
                pagination: {
                    page: pageIndex,
                    pageSize,
                },
                sorting: {
                    propertyName: sortField ?? '',
                    order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
                },
            }),
        );
    }
}
