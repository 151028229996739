import { Component } from '@angular/core';
import * as RelationType from '@app/modules/relation-type/reducers'
import { Store } from '@ngrx/store';
import * as RelationTypeApiActions from '@app/modules/relation-type/actions/relation-type-api.actions';
import { State } from '@app/reducers';
import { RelationTypeCreateModel, RelationTypeUpdateModel } from '@app/modules/relation-type/models';

@Component({
    selector: 'app-relation-type-detail',
    templateUrl: './relation-type-detail.component.html',
    styleUrls: ['./relation-type-detail.component.less'],
})
export class RelationTypeDetailComponent {
    loading$ = this.store.select(RelationType.selectDetailLoading);
    errors$ = this.store.select(RelationType.selectDetailErrors);
    relationType$ = this.store.select(RelationType.selectDetailRelationType);

    constructor(private readonly store: Store<State>) { }

    submit(relationType: RelationTypeCreateModel | RelationTypeUpdateModel) {
        this.store.dispatch(relationType.hasOwnProperty('id')
            ? RelationTypeApiActions.Update(relationType as RelationTypeUpdateModel) 
            : RelationTypeApiActions.Create(relationType)
        );
    }
}