import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { ClaimCategoryTableItemModel, ClaimCategoryUpdateModel } from '@app/modules/claim-categories/models';
import { TableDataModel } from '@app/shared/models';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ClaimCategoryService {
	private readonly endpoints = {
		get: 'ClaimCategory/:type/:value',
		overview: 'ClaimCategory/GetOverview',
		update: 'ClaimCategory/Update',
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(
		sorting: TableSortingModel,
		pagination: TablePaginationModel
	): Observable<TableDataModel<ClaimCategoryTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');

		return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}`);
	}

	get(type: string, value: string): Observable<ClaimCategoryUpdateModel> {
		return this.httpService.get<any>(this.endpoints.get.replace(':type', type).replace(':value', value));
	}

	update(request: ClaimCategoryUpdateModel): Observable<ClaimCategoryUpdateModel> {
		return this.httpService.put<any>(this.endpoints.update, request);
	}
}
