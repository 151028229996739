import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as ReviewOverview from './review-overview.reducer'
import * as ReviewDetailPage from './review-detail-page.reducer'

export const reviewFeatureKey = 'review';

export interface ReviewState {
    reviewOverview: ReviewOverview.State,
    reviewDetail: ReviewDetailPage.State,
}

export interface State {
    review: ReviewState
}

const map: ActionReducerMap<ReviewState, Action> = {
    reviewOverview: ReviewOverview.reducer,
    reviewDetail: ReviewDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: ReviewState, action: Action) => combineReducers(map)(state, action);

const selectReviewState = createFeatureSelector<ReviewState>(reviewFeatureKey);

export const selectState = createSelector(selectReviewState, state => state)
export const selectReviewOverviewState = createSelector(selectState, state => state.reviewOverview)

export const selectReviewOverviewLoading = createSelector(selectReviewOverviewState, ReviewOverview.getLoading)
export const selectReviewOverviewList = createSelector(selectReviewOverviewState, ReviewOverview.getList)
export const selectReviewOverviewPagination = createSelector(selectReviewOverviewState, ReviewOverview.getPagination)
export const selectReviewOverviewSorting = createSelector(selectReviewOverviewState, ReviewOverview.getSorting)
export const selectReviewOverviewFilter = createSelector(selectReviewOverviewState, ReviewOverview.getFilter)
export const selectActivityTypeOverviewList = createSelector(selectReviewOverviewState, ReviewOverview.getActivityTypes)
export const selectActivityTypeIdOverviewList = createSelector(selectReviewOverviewState, ReviewOverview.getActivityTypeId)

export const selectReviewDetailState = createSelector(selectState, state => state.reviewDetail);

export const selectDetailLoading = createSelector(selectReviewDetailState, ReviewDetailPage.getLoading);
export const selectDetailReview = createSelector(selectReviewDetailState, ReviewDetailPage.getReview);
export const selectDetailErrors = createSelector(selectReviewDetailState, ReviewDetailPage.getErrors);
export const selectDetailActivityTypes = createSelector(selectReviewDetailState, ReviewDetailPage.getActivityTypes);