<div class="page-header">
	<h1 class="page-header__title">{{ 'activity-templates.title' | translate }}</h1>
	<div class="page-header__actions">
		<a
			claimDirective
			[claims]="[
				{
					claimType: ClaimTypes.ActivityTemplate,
					claimValues: [ClaimValues.Create]
				}
			]"
			routerLink="/activiteit-templates/toevoegen"
			nz-button
			nzType="primary">
			{{ 'common.add' | translate }}
			<i class="fa-regular fa-plus"></i>
		</a>
	</div>
</div>

<nz-select
*ngIf="activityTypes$ | async"
  [(ngModel)]="this.activityTypeId"
  (ngModelChange)="onCategoryChange($event)"
>
  <nz-option
    nzLabel="{{ 'activity-templates.fields.all-types' | translate }}"
    [nzValue]="-1"
  >
  </nz-option>
  <nz-option
    *ngFor="let category of activityTypes$ | async"
    [nzLabel]="category.value"
    [nzValue]="category.key"
  ></nz-option>
</nz-select>

<app-activity-template-overview-table
	[data]="(tableData$ | async)!"
	(delete)="onDeleteConfirm($event)"
	(onQueryParamsChange)="onQueryParamsChange($event)"
	(onResetOverview)="onResetOverview()" />
