import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { EditorContentBlockModel } from "@app/shared/models/content/editor-content-block/editor-content-block.model";

export class EditorContentBlockFormGroup extends FormGroup {
    constructor(content: string, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super({
            editor: new FormControl<string>(''),
        }, validatorOrOpts, asyncValidator);

        this.patchValue(new EditorContentBlockModel(content));
    }
}
