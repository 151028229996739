import { ActionReducerMap, Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as Auth from './auth.reducer';

export const authFeatureKey = 'mobile-auth';

export interface AuthState {
	auth: Auth.State;
}

export interface State {
	auth: AuthState;
}

export const map: ActionReducerMap<AuthState> = {
	auth: Auth.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: AuthState, action: Action) => combineReducers(map)(state, action);

const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectState = createSelector(selectAuthState, (state) => state);