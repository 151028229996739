import * as ReviewActions from "@app/modules/review/actions/review-api.actions"
import { ReviewUpdateModel } from "@app/modules/review/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    review: null,
    loading: false,
    activityTypes: [],
    errors: {}
}

export interface State {
    review: ReviewUpdateModel | null,
    loading: boolean,
    activityTypes: KeyValueModel[],
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(ReviewActions.Clear, () => ({ ...initialState })),

    on(ReviewActions.Get, (state) => ({ ...state, loading: true })),
    on(ReviewActions.GetSuccess, (state, review) => ({ ...state, review: review, loading: false })),
    on(ReviewActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ReviewActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(ReviewActions.UpdateSuccess, (state, review) => ({ ...state, review: review, loading: false })),
    on(ReviewActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ReviewActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(ReviewActions.CreateSuccess, (state, review) => ({ ...state, review: review, loading: false })),
    on(ReviewActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ReviewActions.GetActivityTypes, (state) => ({ ...state, loading: true })),
    on(ReviewActions.GetActivityTypesSuccess, (state, response) => ({ ...state, activityTypes: response.activityTypes, loading: false })),
    on(ReviewActions.GetActivityTypesError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getReview = (state: State) => state.review;
export const getActivityTypes = (state: State) => state.activityTypes;