import * as BlogActions from '@app/modules/blogs/actions/blog-api.actions';
import { BlogUpdateModel } from '@app/modules/blogs/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
  blog: null,
  loading: false,
  errors: {},
  employees: [],
  tags: [],
};

export interface State {
  blog: BlogUpdateModel | null;
  loading: boolean;
  errors: { [key: string]: string[] };
  employees: KeyValueModel[];
  tags: KeyValueModel[];
}

export const reducer = createReducer(
  initialState,
  on(BlogActions.Clear, () => ({ ...initialState })),

  on(BlogActions.Get, (state) => ({ ...state, loading: true })),
  on(BlogActions.GetSuccess, (state, blog) => ({
    ...state,
    blog,
    loading: false,
  })),
  on(BlogActions.GetError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(BlogActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
  on(BlogActions.UpdateSuccess, (state, blog) => ({
    ...state,
    blog,
    loading: false,
  })),
  on(BlogActions.UpdateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(BlogActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
  on(BlogActions.CreateSuccess, (state, blog) => ({
    ...state,
    blog,
    loading: false,
  })),
  on(BlogActions.CreateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(BlogActions.GetEmployeesSuccess, (state, { employees }) => ({
    ...state,
    employees,
    loading: false,
  })),

  on(BlogActions.GetAllTagsSuccess, (state, { tags }) => ({
    ...state,
    tags: tags,
    loading: false,
  })),

  on(BlogActions.GetAllTags, (state) => ({ ...state, loading: true })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getBlog = (state: State) => state.blog;
export const getEmployees = (state: State) => state.employees;
export const getAllTags = (state: State) => state.tags;
