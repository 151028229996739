import * as ActivityLocationApiActions from "@app/modules/activity-location/actions/activity-location-api.actions"
import { ActivityLocationTableItemModel } from "@app/modules/activity-location/models/activity-location-table.model"
import { TableOverviewModel } from "@app/shared/models/table/table-overview.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model"
import { applySortingOrDefault } from "@app/shared/utilities/overview-reducer.utilities"
import { createReducer, on } from "@ngrx/store"

const initialState: State = new TableOverviewModel<ActivityLocationTableItemModel>('locationName');

export interface State extends TableStateModel<ActivityLocationTableItemModel> { };

export const reducer = createReducer(initialState,
    on(ActivityLocationApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(ActivityLocationApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(ActivityLocationApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(ActivityLocationApiActions.FilterOverview, (state, { sorting, pagination }) => ({ 
        ...state, 
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: { 
            ...state.pagination, 
            page: pagination.page,
            pageSize: pagination.pageSize
        } 
    }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;