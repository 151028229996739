<div class="page-header">
  <a routerLink="/materialen/overzicht" class="page-header__back">
    <i class="fa-regular fa-chevron-left"></i>
  </a>
  <h1 class="page-header__title">{{ "materials.title" | translate }}</h1>
  <div class="page-header__actions"></div>
</div>

<app-material-detail-form
  [material]="Material$ | async"
  (submitted)="submit($event)"
  [errors]="errors$ | async"
  [loading]="loading$ | async"
/>
