import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { RelationOverviewComponent } from "@app/modules/relation/pages/overview/relation-overview.component";
import { RelationRoutingModule } from "@app/modules/relation/relation-routing.module";
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
    RelationOverviewComponent,
];

const IMPORTS = [
    SharedModule,
    RelationRoutingModule,
    NzDividerModule
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class RelationModule { }
