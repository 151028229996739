import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as RedirectActions from '@app/modules/redirects/actions/redirect-api.actions';
import { RedirectService } from '@app/modules/redirects/services/redirect.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const redirectService = inject(RedirectService);
	const router = inject(Router);
	const store = inject(Store<State>);

	return redirectService.get(route.params['id']).pipe(
		map((redirect) => RedirectActions.GetSuccess(redirect)),
		tap((action: any) => store.dispatch(action)),
		map((redirect) => !!redirect),
		catchError(() => {
			return router.navigate(['/not-found']);
		})
	);
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);
