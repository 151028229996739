<div class="page-header">
    <a routerLink="/email-templates/overzicht" class="page-header__back">
        <i class="fa-regular fa-chevron-left"></i>
    </a>
    <h1 class="page-header__title">{{ "email-templates.title" | translate }}</h1>
    <div class="page-header__actions"></div>
</div>

<app-email-template-detail-form 
    [emailTemplate]="emailTemplate$ | async" 
    [relationTypes]="relationTypes$ | async"
    [defaultEmailTemplate]="defaultEmailTemplate$ | async"
    (submitted)="submit($event)" 
    (getDefault)="getDefault($event)" 
    [errors]="errors$ | async" 
    [loading]="loading$ | async"/>