import { ErrorResponse } from '@app/core/models';
import { NoteType } from '@app/modules/note/enum/note-type.enum';
import { NoteCreateModel } from '@app/modules/note/models/note-create.model';
import { NoteItemModel } from '@app/modules/note/models/note-overview.model';
import { NoteUpdateModel } from '@app/modules/note/models/note-update.model';
import { createAction, props } from '@ngrx/store';

export const ResetOverview = createAction('[Note] Reset');

export const GetOverview = createAction('[Note] GetOverview');
export const GetOverviewError = createAction('[Note] GetOverviewError', props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction('[Note] GetOverviewSuccess', props<{ data: NoteItemModel[] }>());

export const SetNoteType = createAction('[Note] SetNoteType', props<{ noteType: NoteType; noteTypeId: number }>());

export const Create = createAction('[Note] Create', props<NoteCreateModel>());
export const CreateError = createAction('[Note] CreateError', props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction('[Note] CreateSuccess');

export const SetImportant = createAction('[Note] SetImportant', props<{ noteId: number }>());
export const SetImportantError = createAction('[Note] SetImportantError', props<{ response: ErrorResponse }>());
export const SetImportantSuccess = createAction('[Note] SetImportantSuccess');

export const Delete = createAction('[Note] Delete', props<{ noteId: number }>());
export const DeleteError = createAction('[Note] DeleteError', props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction('[Note] DeleteSuccess');

export const Update = createAction('[Note] Update', props<{ data: NoteUpdateModel }>());
export const UpdateError = createAction('[Note] UpdateError', props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction('[Note] UpdateSuccess');
