import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BlogCategoryChangeSequenceModel, BlogCategoryTableItemModel } from '@app/modules/blog-category/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
	selector: 'app-blog-category-overview-table',
	templateUrl: './blog-category-overview-table.component.html',
})
export class BlogCategoryOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<BlogCategoryTableItemModel>;

	@Output() delete = new EventEmitter<number>();
	@Output() onQueryParamsChange = new EventEmitter<any>();
	@Output() changeSequence = new EventEmitter<BlogCategoryChangeSequenceModel>();
	@Output() onResetOverview = new EventEmitter<void>();

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;
	draggingEnabled = false;

	drop(event: CdkDragDrop<string[]>): void {
		this.changeSequence.emit({
			id: event.item.data.id,
			newSequenceNumber: event.currentIndex,
		});
	}

	draggingChanged() {
		if (this.draggingEnabled) {
			this.onQueryParamsChange.emit({
				pageIndex: 1,
				pageSize: -1,
				sort: [{key: 'sequenceNumber', value: 'ascend'}],
			});
		} else {
			this.onResetOverview.emit();
		}
	}
}
