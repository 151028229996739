import { ErrorResponse } from "@app/core/models/error-response.model";
import { RelationTypeChangeSequenceModel, RelationTypeCreateModel, RelationTypeTableItemModel, RelationTypeUpdateModel } from "@app/modules/relation-type/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[RelationType] GetOverview");
export const GetOverviewError = createAction("[RelationType] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[RelationType] GetOverviewSuccess", props<TableDataModel<RelationTypeTableItemModel>>());
export const ResetOverview = createAction("[RelationType] ResetOverview");

export const FilterOverview = createAction("[RelationType] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[RelationType] Clear");

export const Get = createAction("[RelationType] Get", props<{ id: number }>());
export const GetError = createAction("[RelationType] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[RelationType] GetSuccess", props<RelationTypeUpdateModel>());

export const Update = createAction("[RelationType] Update", props<RelationTypeUpdateModel>());
export const UpdateError = createAction("[RelationType] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[RelationType] UpdateSuccess", props<RelationTypeUpdateModel>());

export const ChangeSequence = createAction("[RelationType] ChangeSequence", props<RelationTypeChangeSequenceModel>());
export const ChangeSequenceError = createAction("[RelationType] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[RelationType] ChangeSequenceSuccess");

export const Create = createAction("[RelationType] Create", props<RelationTypeCreateModel>());
export const CreateError = createAction("[RelationType] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[RelationType] CreateSuccess", props<RelationTypeUpdateModel>());

export const Delete = createAction("[RelationType] Delete", props<{ id: number }>());
export const DeleteError = createAction("[RelationType] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[RelationType] DeleteSuccess");