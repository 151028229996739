import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WaitlistModel } from '@app/modules/activity/models/waitlist.model';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { debounceTime, Subject } from 'rxjs';

@Component({
    selector: 'app-waitlist-dropdown',
    templateUrl: './waitlist-dropdown.component.html',
})
export class ActivityWaitlistDropdownComponent implements OnInit{
    @Input({ required: false }) waitlistList: WaitlistModel[] = [];
    @Input({ required: false }) waitlistComparableList: WaitlistModel[] = [];
    @Input({ required: true }) relationsList: KeyValueModel[] = [];
    @Input({ required: true }) selectedValue!: number | null;

    @Output() selectedValueChange = new EventEmitter<number | null>();
    @Output() search = new EventEmitter<{ searchQuery: string; skipCount: number }>();

    private readonly searchSubject = new Subject<{ searchQuery: string; skipCount: number }>();
    private readonly debounceTimeMs = 350;
    searchQuery = '';

    constructor() {
        this.searchSubject
            .pipe(takeUntilDestroyed())
            .pipe(debounceTime(this.debounceTimeMs))
            .subscribe((searchValue) => {
                if (searchValue.searchQuery.length > 2) {
                    this.search.emit(searchValue);
                }
            });
    }

    ngOnInit(){
        this.search.emit({ searchQuery: this.selectedValue?.toString() ?? '', skipCount: 0 })
    }

    loadMore() {
        this.searchSubject.next({ searchQuery: this.searchQuery, skipCount: this.waitlistList.length });
    }

    inputSearch(query: string) {
        this.searchQuery = query;
        this.searchSubject.next({ searchQuery: query, skipCount: 0 });
    }

    inputChange(event: number) {
        this.selectedValue = event;
        this.selectedValueChange.emit(this.selectedValue);
    }
}
