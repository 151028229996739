import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as EmailTemplateActions from '@app/modules/email-template/actions/email-template-api.actions';
import * as EmailTemplate from '@app/modules/email-template/reducers';
import { EmailTemplateService } from '@app/modules/email-template/services/email-template.service';
import { RelationTypeService } from '@app/modules/relation-type/services/relation-type.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { catchError, exhaustMap, map, of, switchMap, take, tap } from 'rxjs';

@Injectable()
export class EmailTemplateEffects {
  constructor(
    private actions$: Actions,
    private emailTemplateService: EmailTemplateService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<EmailTemplate.State>,
    private modalService: NzModalService,
    private relationTypeService: RelationTypeService
  ) {}

  /**
   * Get effects
   */
  loadEmailTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(EmailTemplate.selectEmailTemplateOverviewPagination),
        this.store.select(EmailTemplate.selectEmailTemplateOverviewSorting),
      ]),
      switchMap(([, pagination, sorting]) =>
        this.store
          .select(EmailTemplate.selectEmailTemplateOverviewEmailTemplateType)
          .pipe(
            take(1),
            map((emailTemplateType) => {
              return { pagination, sorting, emailTemplateType };
            })
          )
      ),
      exhaustMap(({ pagination, sorting, emailTemplateType }) => {
        return this.emailTemplateService
          .getAll(sorting, pagination, emailTemplateType)
          .pipe(
            map((response) =>
              EmailTemplateActions.GetOverviewSuccess(response)
            ),
            catchError((response: { error: ErrorResponse }) =>
              of(
                EmailTemplateActions.GetOverviewError({
                  response: response.error,
                })
              )
            )
          );
      })
    )
  );

  loadEmailTemplatesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.FilterOverview),
      map(() => EmailTemplateActions.GetOverview())
    )
  );

  loadEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.Get),
      exhaustMap((action) =>
        this.emailTemplateService.get(action.id).pipe(
          map((emailTemplate) =>
            EmailTemplateActions.GetSuccess(emailTemplate)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(EmailTemplateActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  loadDefaultEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.GetDefault),
      exhaustMap((action) =>
        this.emailTemplateService.getDefault(action).pipe(
          map((emailTemplate) =>
            EmailTemplateActions.GetDefaultSuccess(emailTemplate)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              EmailTemplateActions.GetDefaultError({ response: response.error })
            )
          )
        )
      )
    )
  );

  loadDefaultEmailTemplateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailTemplateActions.GetDefaultSuccess),
        tap((emailTemplate) => {
          this.modalService.create({
            nzTitle: this.translate.instant('email-templates.copy-modal.title'),
            nzContent: this.translate.instant(
              'email-templates.copy-modal.body'
            ),
            nzCancelText: this.translate.instant('common.cancel'),
            nzOkText: this.translate.instant('common.import'),
            nzOnOk: () => {
              this.store.dispatch(
                EmailTemplateActions.GetDefaultImport(emailTemplate)
              );
              this.messageService.success(
                this.translate.instant('email-templates.copy-modal.succes')
              );
            },
          });
        })
      ),
    { dispatch: false }
  );

  loadDefaultEmailTemplateError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailTemplateActions.GetDefaultError),
        tap(() =>
          this.translate
            .get('error.emailTemplateType-duplicate')
            .subscribe((translatedMessage: string) =>
              this.messageService.error(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  loadRelationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.GetRelationTypes),
      exhaustMap(() =>
        this.relationTypeService.getAll().pipe(
          map((relationTypes) =>
            EmailTemplateActions.GetRelationTypesSuccess({ relationTypes })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              EmailTemplateActions.GetRelationTypesError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.Create),
      exhaustMap((action) =>
        this.emailTemplateService.create(action).pipe(
          map((emailTemplate) =>
            EmailTemplateActions.CreateSuccess(emailTemplate)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(EmailTemplateActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createEmailTemplateSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailTemplateActions.CreateSuccess),
        tap((emailTemplate) =>
          this.translate
            .get('notification.add', { item: emailTemplate.templateName })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.Update),
      exhaustMap((action) =>
        this.emailTemplateService.update(action).pipe(
          map((emailTemplate) =>
            EmailTemplateActions.UpdateSuccess(emailTemplate)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(EmailTemplateActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateEmailTemplateSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailTemplateActions.UpdateSuccess),
        tap((emailTemplate) =>
          this.translate
            .get('notification.save', { item: emailTemplate.templateName })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateEmailTemplateSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EmailTemplateActions.CreateSuccess,
          EmailTemplateActions.UpdateSuccess
        ),
        tap(() => this.router.navigate(['/email-templates/overzicht']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteEmailTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.Delete),
      exhaustMap((action) =>
        this.emailTemplateService.delete(action.id).pipe(
          map(() => EmailTemplateActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(EmailTemplateActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteEmailTemplateSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmailTemplateActions.DeleteSuccess),
      map(() => EmailTemplateActions.GetOverview())
    )
  );

  deleteEmailTemplateSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailTemplateActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  emailTemplateErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EmailTemplateActions.GetOverviewError,
          EmailTemplateActions.DeleteError,
          EmailTemplateActions.GetRelationTypesError,
          EmailTemplateActions.GetDefaultError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
