import { Component } from '@angular/core';
import * as Tags from '@app/modules/tag/reducers';
import { Store } from '@ngrx/store';
import * as TagApiActions from '@app/modules/tag/actions/tag-api.actions';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
    selector: 'app-tag-overview',
    templateUrl: './tag-overview.component.html',
    styleUrls: ['./tag-overview.component.less']
})
export class TagOverviewComponent {
    tableData$ = this.store.select(Tags.selectTagOverviewState);

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    constructor(private readonly store: Store) { }

    onDeleteConfirm(id: number): void {
        this.store.dispatch(TagApiActions.Delete({ id: id }))
    }

    /**
     * Trigger the loading of data whenever the table changes.
     * The default pageSize, index and sort options are based on the TableStateModel.
     * @param params
     */
    onQueryParamsChange(params: NzTableQueryParams) {
        const { pageSize, pageIndex, sort } = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort?.key) ?? null;
        const sortOrder = (currentSort?.value) ?? null;

        this.store.dispatch(TagApiActions.FilterOverview({
            pagination: {
                page: pageIndex,
                pageSize
            },
            sorting: {
                propertyName: sortField ?? '',
                order: sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : ''
            }
        }));
    }
}
