import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { DashboardOverviewComponent } from "./pages/overview/dashboard-overview.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";

const COMPONENTS = [
    DashboardOverviewComponent,
];

const IMPORTS = [
    SharedModule,
    DashboardRoutingModule,
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class DashboardModule { }
