import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as PageOverview from '@app/modules/page/reducers/page-overview.reducer'
import * as PageDetailPage from '@app/modules/page/reducers/page-detail-page.reducer'

export const pageFeatureKey = 'page';

export interface PageState {
  pageOverview: PageOverview.State,
  pageDetail: PageDetailPage.State
}

export interface State {
  page: PageState
}

const map: ActionReducerMap<PageState, Action> = {
  pageOverview: PageOverview.reducer,
  pageDetail: PageDetailPage.reducer
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: PageState, action: Action) => combineReducers(map)(state, action);

const selectPageState = createFeatureSelector<PageState>(pageFeatureKey);

export const selectState = createSelector(selectPageState, state => state)
export const selectPageOverviewState = createSelector(selectState, state => state.pageOverview)

export const selectPageOverviewLoading = createSelector(selectPageOverviewState, PageOverview.getLoading)
export const selectPageOverviewList = createSelector(selectPageOverviewState, PageOverview.getList)
export const selectPageOverviewPagination = createSelector(selectPageOverviewState, PageOverview.getPagination)
export const selectPageOverviewSorting = createSelector(selectPageOverviewState, PageOverview.getSorting)

export const selectPageDetailState = createSelector(selectState, state => state.pageDetail);

export const selectDetailLoading = createSelector(selectPageDetailState, PageDetailPage.getLoading);
export const selectDetailPage = createSelector(selectPageDetailState, PageDetailPage.getPage);
export const selectDetailErrors = createSelector(selectPageDetailState, PageDetailPage.getErrors);

export const selectDetailChildPages = createSelector(selectPageDetailState, PageDetailPage.getChildPagesData);

export const selectDetailRedirectExists = createSelector(selectPageDetailState, PageDetailPage.getRedirectExists);