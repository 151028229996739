import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { ActivityTypeOverviewComponent } from "@app/modules/activity-type/pages/overview/activity-type-overview.component";
import { ActivityTypeDetailComponent } from "@app/modules/activity-type/pages/detail/activity-type-detail.component";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ActivityTypeRoutingModule } from "@app/modules/activity-type/activity-type-routing.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { activityTypeFeatureKey, reducers } from "@app/modules/activity-type/reducers";
import { ActivityTypeEffects } from "@app/modules/activity-type/effects/activity-type.effects";
import { ActivityTypeOverviewTableComponent } from "@app/modules/activity-type/components/activity-type-overview-table/activity-type-overview-table.component";
import { ActivityTypeDetailFormComponent } from "@app/modules/activity-type/components/activity-type-detail-form/activity-type-detail-form.component";

const COMPONENTS = [
    ActivityTypeOverviewComponent,
    ActivityTypeDetailComponent,
    ActivityTypeOverviewTableComponent,
    ActivityTypeDetailFormComponent
];

const IMPORTS = [
    SharedModule,
    ActivityTypeRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(activityTypeFeatureKey, reducers),
    EffectsModule.forFeature([ActivityTypeEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class ActivityTypesModule { }
