import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import {
  PageChangeSequenceModel,
  PageTableItemModel
} from '@app/modules/page/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {ClaimService} from "@app/shared/services/claim.service";
import {TableDataModel} from "@app/shared/models";

@Component({
	selector: 'app-child-pages-overview-table',
	templateUrl: './child-pages-overview-table.component.html',
  styleUrls: ['./child-pages-overview-table.component.less'],
})
export class ChildPagesOverviewTableComponent implements OnInit {
	@Input({ required: true }) data!: TableDataModel<PageTableItemModel>;
  @Input({ required: true }) parentId!: number;

  @Output() delete = new EventEmitter<number>();
  @Output() onQueryParamsChange = new EventEmitter<any>();
  @Output() changeSequence = new EventEmitter<PageChangeSequenceModel>();
  @Output() onResetChildPages = new EventEmitter<void>();
  @Output() onChangeVisible = new EventEmitter<{
    id: number;
    isVisible: boolean;
  }>();

  draggingEnabled = false;
  readonly?: boolean;

  constructor(private readonly claimService: ClaimService) {}

  async ngOnInit(){
    this.readonly = !await this.claimService.hasAny([{claimType: ClaimTypes.Page, claimValues: [ClaimValues.Update]}]);
  }

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;

  changeVisible(id: number, isVisible: boolean) : void {
    this.onChangeVisible.emit({ id, isVisible });
  }

  drop(event: CdkDragDrop<string[]>): void {
    if(!this.parentId) return;

    this.changeSequence.emit({
      id: event.item.data.id,
      newSequenceNumber: event.currentIndex,
      parentId: this.parentId,
    });
  }

  draggingChanged() {
    if (this.draggingEnabled) {
      this.onQueryParamsChange.emit({
        pageIndex: 1,
        pageSize: -1,
        sort: [{key: 'sequenceNumber', value: 'ascend'}],
      });
    } else {
      this.onResetChildPages.emit();
    }
  }
}
