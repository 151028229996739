import { NgModule } from '@angular/core';
import { ClaimCategoryRoutingModule } from '@app/modules/claim-categories/claim-category-routing.module';
import { ClaimCategoryDetailFormComponent } from '@app/modules/claim-categories/components/claim-category-detail-form/claim-category-detail-form.component';
import { ClaimCategoryOverviewTableComponent } from '@app/modules/claim-categories/components/claim-category-overview-table/claim-category-overview-table.component';
import { ClaimCategoryEffects } from '@app/modules/claim-categories/effects/claim-category.effects';
import { ClaimCategoryDetailComponent } from '@app/modules/claim-categories/pages/detail/claim-category-detail.component';
import { ClaimCategoryOverviewComponent } from '@app/modules/claim-categories/pages/overview/claim-category-overview.component';
import { claimCategoryFeatureKey, reducers } from '@app/modules/claim-categories/reducers';

import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
	ClaimCategoryDetailFormComponent,
	ClaimCategoryOverviewTableComponent,
	ClaimCategoryOverviewComponent,
	ClaimCategoryDetailComponent
];

const IMPORTS = [
	SharedModule,
	ClaimCategoryRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(claimCategoryFeatureKey, reducers),
	EffectsModule.forFeature([ClaimCategoryEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class ClaimCategoriesModule {}
