import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  isFormValid,
  processBackendValidators,
} from '@app/shared/utilities/validation.utilities';
import { TagCreateModel, TagUpdateModel } from '@app/modules/tag/models';
import { ClaimService } from '@app/shared/services/claim.service';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
  selector: 'app-tag-detail-form',
  templateUrl: './tag-detail-form.component.html',
})
export class TagDetailFormComponent implements OnInit, OnChanges {
  update = false;
  readonly?: boolean;

  @Input() loading = false;
  @Input() errors: { [key: string]: string[] } = {};
  @Input({ required: true }) tag: TagUpdateModel | null = null;

  @Output() submitted = new EventEmitter<TagCreateModel | TagUpdateModel>();

  formGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
    slug: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
  });

  constructor(private readonly claimService: ClaimService) {}

  async ngOnInit() {
    this.update = !!this.tag;

    if (
      this.update &&
      !(await this.claimService.hasAny([
        { claimType: ClaimTypes.Tags, claimValues: [ClaimValues.Update] },
      ]))
    ) {
      this.formGroup.disable();
      this.readonly = true;
    } else {
      this.readonly = false;
    }

    this.formGroup.patchValue({
      name: this.tag?.name,
      slug: this.tag?.slug,
    });

    if (this.update) {
      this.formGroup.controls.id.setValidators([CustomValidators.required]);
      this.formGroup.controls.id.setValue((this.tag as TagUpdateModel).id);

      this.formGroup.controls.slug.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    processBackendValidators(changes, this.formGroup);
  }

  submit() {
    if (isFormValid(this.formGroup)) {
      this.update ? this.submitUpdate() : this.submitCreate();
    }
  }

  private submitCreate() {
    this.submitted.emit({
      name: this.formGroup.controls.name.value ?? '',
      slug: this.formGroup.controls.slug.value ?? '',
    });
  }

  private submitUpdate() {
    this.submitted.emit(this.formGroup.value as TagUpdateModel);
  }
}
