import { Component } from '@angular/core';
import * as ActivityLocation from '@app/modules/activity-location/reducers';
import { Store } from '@ngrx/store';
import * as ActivityLocationApiActions from '@app/modules/activity-location/actions/activity-location-api.actions';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
    selector: 'app-activity-location-overview',
    templateUrl: './activity-location-overview.component.html',
    styleUrls: ['./activity-location-overview.component.less']
})
export class ActivityLocationOverviewComponent {
    tableData$ = this.store.select(ActivityLocation.selectActivityLocationOverviewState);

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    constructor(private readonly store: Store) { }

    onDeleteConfirm(id: number): void {
        this.store.dispatch(ActivityLocationApiActions.Delete({ id: id }))
    }

    /**
     * Trigger the loading of data whenever the table changes.
     * The default pageSize, index and sort options are based on the TableStateModel.
     * @param params 
     */
    onQueryParamsChange(params: NzTableQueryParams) {
        const { pageSize, pageIndex, sort, filter } = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort?.key) ?? null;
        const sortOrder = (currentSort?.value) ?? null;

        this.store.dispatch(ActivityLocationApiActions.FilterOverview({
            pagination: {
                page: pageIndex,
                pageSize
            },
            sorting: {
                propertyName: sortField ?? '',
                order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : ''
            }
        }));
    }
}