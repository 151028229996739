import {
  Action,
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as BlogDetailPage from './blog-detail.reducer';
import * as BlogOverview from './blog-overview.reducer';

export const blogFeatureKey = 'blog';

export interface BlogState {
  blogOverview: BlogOverview.State;
  blogDetail: BlogDetailPage.State;
}

export interface State {
  blog: BlogState;
}

const map: ActionReducerMap<BlogState, Action> = {
  blogOverview: BlogOverview.reducer,
  blogDetail: BlogDetailPage.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: BlogState, action: Action) =>
  combineReducers(map)(state, action);

const selectBlogState = createFeatureSelector<BlogState>(blogFeatureKey);

export const selectState = createSelector(selectBlogState, (state) => state);
export const selectBlogOverviewState = createSelector(
  selectState,
  (state) => state.blogOverview
);

export const selectBlogOverviewLoading = createSelector(
  selectBlogOverviewState,
  BlogOverview.getLoading
);
export const selectBlogOverviewList = createSelector(
  selectBlogOverviewState,
  BlogOverview.getList
);
export const selectBlogOverviewPagination = createSelector(
  selectBlogOverviewState,
  BlogOverview.getPagination
);
export const selectBlogOverviewSorting = createSelector(
  selectBlogOverviewState,
  BlogOverview.getSorting
);

export const selectBlogDetailState = createSelector(
  selectState,
  (state) => state.blogDetail
);

export const selectDetailLoading = createSelector(
  selectBlogDetailState,
  BlogDetailPage.getLoading
);
export const selectDetailBlog = createSelector(
  selectBlogDetailState,
  BlogDetailPage.getBlog
);
export const selectDetailErrors = createSelector(
  selectBlogDetailState,
  BlogDetailPage.getErrors
);
export const selectDetailEmployees = createSelector(
  selectBlogDetailState,
  BlogDetailPage.getEmployees
);
export const selectDetailTags = createSelector(
  selectBlogDetailState,
  BlogDetailPage.getAllTags
);
