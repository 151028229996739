import { authFeatureKey } from '@app/modules/auth/reducers';
import * as Auth from '@app/modules/auth/reducers/auth.reducer';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '@src/environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface State {
	auth: Auth.State;
}

export const reducers: ActionReducerMap<State, Action> = {
	auth: Auth.reducer
};

export function debug(reducer: ActionReducer<State>): ActionReducer<State> {
	return function (state, action) {
		const result = reducer(state, action);
		console.groupCollapsed(action.type);
		console.log('prev state', state);
		console.log('action', action);
		console.log('next state', result);
		console.groupEnd();

		return result;
	};
}

function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
	return localStorageSync({
		keys: [authFeatureKey],
		rehydrate: true,
	})(reducer);
}

export const metaReducers: MetaReducer<any>[] = !environment.production
	? [debug, localStorageSyncReducer]
	: [localStorageSyncReducer];
