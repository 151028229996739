import * as ReviewApiActions from "@app/modules/review/actions/review-api.actions"
import { ReviewTableItemModel } from "@app/modules/review/models/review-table.model"
import { KeyValueModel } from "@app/shared/models/common/key-value.model"
import { TableOverviewModel } from "@app/shared/models/table/table-overview.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model"
import { applySortingOrDefault } from "@app/shared/utilities/overview-reducer.utilities"
import { createReducer, on } from "@ngrx/store"

const initialState: State = new TableOverviewModel<ReviewTableItemModel>('date');

export interface State extends TableStateModel<ReviewTableItemModel> { 
    activityTypes?: KeyValueModel[],
    activityTypeId?: number
}

export const reducer = createReducer(initialState,
    on(ReviewApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(ReviewApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(ReviewApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),

    on(ReviewApiActions.GetActivityTypes, (state) => ({ ...state, loading: true })),
    on(ReviewApiActions.GetActivityTypesSuccess, (state, response) => ({ ...state, activityTypes: response.activityTypes, loading: false })),
    on(ReviewApiActions.GetActivityTypesError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ReviewApiActions.SetFilter, (state, {filter}) => ({ 
        ...state, 
        filter: filter
    })),
    on(ReviewApiActions.SetActivityTypeId, (state, {activityTypeId: activityId}) => ({ 
        ...state, 
        activityTypeId: activityId
    })),
    on(ReviewApiActions.FilterOverview, (state, { sorting, pagination, filter }) => ({ 
        ...state, 
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: { 
            ...state.pagination, 
            page: pagination.page,
            pageSize: pagination.pageSize
        },
        filter: state.filter.concat(filter ?? []),
    })),
    on(ReviewApiActions.ResetOverview,  (state) => ({ ...initialState, activityTypes: state.activityTypes, activityTypeId: state.activityTypeId }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
export const getFilter = (state: State) => state.filter;
export const getActivityTypes = (state: State) => state.activityTypes;
export const getActivityTypeId = (state: State) => state.activityTypeId;