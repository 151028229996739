<nz-table
	#overviewTable
	[nzData]="data.list"
	[nzPageIndex]="data.pagination.page"
	[nzPageSize]="data.pagination.pageSize"
	[nzTotal]="data.pagination.totalItems ?? 0"
	[nzLoading]="data.loading"
	[nzFrontPagination]="false"
	(nzQueryParams)="onQueryParamsChange.emit($event)"
	nzTableLayout="fixed">
	<thead>
		<tr>
			<th nzColumnKey="type">
				{{ 'email-templates.fields.emailTemplateType' | translate }}
			</th>
			<th nzColumnKey="relationtype" [nzSortFn]="true" [nzSortOrder]="'ascend'">
				{{ 'email-templates.fields.relationTypeId' | translate }}
			</th>
			<th nzColumnKey="templateName" [nzSortFn]="true">
				{{ 'email-templates.fields.templateName' | translate }}
			</th>
			<th nzColumnKey="subject" [nzSortFn]="true">
				{{ 'email-templates.fields.subject' | translate }}
			</th>
			<th>
				{{ 'email-templates.fields.assets' | translate }}
			</th>
			<th nzColumnKey="description" [nzSortFn]="true">
				{{ 'email-templates.fields.description' | translate }}
			</th>
			<th
				nzWidth="120px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.EmailTemplate,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]"></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let row of overviewTable.data">
			<td>
				{{ 'email-templates.enums.emailTemplateType.' + EmailTemplateType[row.emailTemplateType] | translate }}
			</td>
			<td>
				{{ row.relationTypeName ?? 'placeholder.all-options' | translate | truncateText }}
			</td>
			<td>
				{{ row.emailTemplateName | truncateText }}
			</td>
			<td>
				{{ row.subject | truncateText }}
			</td>
			<td class="assets">
				@for(asset of row.assets; track asset) {
				<a [href]="asset.url" target="_blank" [title]="asset.name | truncateText"
					><i class="fa-regular fa-download"></i
				></a>
				}
			</td>
			<td>
				{{ row.description | truncateText }}
			</td>
			<td
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.EmailTemplate,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]">
				<div class="table-actions">
					<a
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.EmailTemplate,
								claimValues: [ClaimValues.Read]
							}
						]"
						[routerLink]="['/email-templates/bewerken', row.id]"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-pen"></i>
					</a>
					<button
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.EmailTemplate,
								claimValues: [ClaimValues.Delete]
							}
						]"
						nz-button
						nzDanger
						nzType="text"
						nzShape="circle"
						nz-popconfirm
						nzOkDanger
						[nzPopconfirmTitle]="'notification.delete' | translate"
						[nzOkText]="'common.delete' | translate"
						[nzCancelText]="'common.cancel' | translate"
						[nzIcon]="templateIconDelete"
						nzPopconfirmPlacement="left"
						(nzOnConfirm)="delete.emit(row.id)"
						[disabled]="!row.relationTypeName">
						<i class="fa-regular fa-trash-can"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</nz-table>

<ng-template #templateIconDelete>
	<i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
