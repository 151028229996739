<form nz-form [formGroup]="formGroup">
  <nz-card>
    <h4>
      {{
        (!!formGroup.get("id")?.value
          ? "materials.detail.edit-title"
          : "materials.detail.add-title"
        ) | translate
      }}
    </h4>
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>{{
        "common.name" | translate
      }}</nz-form-label>
      <nz-form-control [nzSpan]="24" [nzErrorTip]="nameValidationTemplate">
        <input nz-input [formControl]="this.formGroup.controls.name" />
        <ng-template #nameValidationTemplate let-control>
          <app-input-validation
            [control]="control"
            [field]="'name'"
            [errors]="errors"
          ></app-input-validation>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSpan]="24">{{
        "common.description" | translate
      }}</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        [nzErrorTip]="descriptionValidationTemplate"
      >
        <input nz-input [formControl]="this.formGroup.controls.description" />

        <ng-template #descriptionValidationTemplate let-control>
          <app-input-validation
            [control]="control"
            [field]="'description'"
            [errors]="errors"
          ></app-input-validation>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <div nz-row>
      <currency-input
        [(control)]="formGroup.controls.priceExclVAT"
        [errors]="errors"
        [fieldName]="'priceExclVAT'"
        [title]="'materials.fields.priceExclVAT' | translate"
        [nzSpan]="12"
        nz-col
        class="ant-form-item"
      />

      <nz-form-item nz-col [nzSpan]="12">
        <nz-form-label [nzSpan]="24" nzRequired>{{
          "materials.detail.vatfactor" | translate
        }}</nz-form-label>
        <nz-form-control
          [nzSpan]="24"
          [nzErrorTip]="VATfactorValidationTemplate"
        >
          <input
            type="number"
            nz-input
            [formControl]="formGroup.controls.vatFactor"
          />

          <ng-template #VATfactorValidationTemplate let-control>
            <app-input-validation
              [control]="control"
              [field]="'vatFactor'"
              [errors]="errors"
            ></app-input-validation>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item>
      <nz-form-label [nzSpan]="24">{{
        "materials.detail.quotation" | translate
      }}</nz-form-label>
      <label
        nz-checkbox
        [nzValue]="material?.inQuotation"
        formControlName="inQuotation"
      ></label>
    </nz-form-item>
    <div class="button-container">
      <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
        {{ "common.save" | translate }}
      </button>
    </div>
  </nz-card>
</form>
