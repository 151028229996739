import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { ActivityOverviewComponent } from "@app/modules/activities/pages/overview/activities-overview.component";
import { ActivityDetailComponent } from "@app/modules/activities/pages/detail/activities-detail.component";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ActivityRoutingModule } from "@app/modules/activities/activities-routing.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { activityFeatureKey, reducers } from "@app/modules/activities/reducers";
import { ActivityEffects } from "@app/modules/activities/effects/activities.effects";
import { ActivityOverviewTableComponent } from "@app/modules/activities/components/activities-overview-table/activities-overview-table.component";
import { ActivityDetailFormComponent } from "@app/modules/activities/components/activities-detail-form/activities-detail-form.component";
import { ActivityTemplateModalComponent } from "@app/modules/activities/components/activities-template-modal/activities-template-modal.component";

const COMPONENTS = [
    ActivityOverviewComponent,
    ActivityDetailComponent,
    ActivityTemplateModalComponent,
    ActivityOverviewTableComponent,
    ActivityDetailFormComponent
];

const IMPORTS = [
    SharedModule,
    ActivityRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(activityFeatureKey, reducers),
    EffectsModule.forFeature([ActivityEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class ActivitiesModule { }
