import { ErrorResponse } from "@app/core/models/error-response.model";
import { ReviewChangeSequenceModel } from "@app/modules/review/models/review-change-sequence.model";
import { ReviewCreateModel } from "@app/modules/review/models/review-create.model";
import { ReviewTableItemModel } from "@app/modules/review/models/review-table.model";
import { ReviewUpdateModel } from "@app/modules/review/models/review-update.model";
import { TableDataModel } from "@app/shared/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { TableFilterModel } from "@app/shared/models/table/table-filter.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[Review] GetOverview");
export const GetOverviewError = createAction("[Review] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Review] GetOverviewSuccess", props<TableDataModel<ReviewTableItemModel>>());
export const ResetOverview = createAction("[Review] ResetOverview");

export const FilterOverview = createAction("[Review] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number }, filter?: TableFilterModel[] }>());

export const SetFilter = createAction("[Review] SetFilter", props<{ filter: TableFilterModel[]}>());
export const SetActivityTypeId = createAction("[Review] SetActivityTypeId", props<{ activityTypeId: number}>());

export const Clear = createAction("[Review] Clear");

export const Get = createAction("[Review] Get", props<{ id: number }>());
export const GetError = createAction("[Review] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Review] GetSuccess", props<ReviewUpdateModel>());

export const Update = createAction("[Review] Update", props<ReviewUpdateModel>());
export const UpdateError = createAction("[Review] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Review] UpdateSuccess", props<ReviewUpdateModel>());

export const ChangeSequence = createAction("[Review] ChangeSequence", props<ReviewChangeSequenceModel>());
export const ChangeSequenceError = createAction("[Review] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[Review] ChangeSequenceSuccess");

export const Create = createAction("[Review] Create", props<ReviewCreateModel>());
export const CreateError = createAction("[Review] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Review] CreateSuccess", props<ReviewUpdateModel>());

export const Delete = createAction("[Review] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Review] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Review] DeleteSuccess");

export const GetActivityTypes = createAction("[Review] GetActivityTypes");
export const GetActivityTypesError = createAction("[Review] GetActivityTypesError", props<{ response: ErrorResponse }>());
export const GetActivityTypesSuccess = createAction("[Review] GetActivityTypesSuccess", props<{ activityTypes: KeyValueModel[] }>());

export const UpdateOnline = createAction("[User] UpdateOnline", props<{ id: number, online: boolean }>());
export const UpdateOnlineError = createAction("[User] UpdateOnlineError", props<{ response: ErrorResponse }>());
export const UpdateOnlineSuccess = createAction("[User] UpdateOnlineSuccess", props<ReviewUpdateModel>());