import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as BlogCategoryOverview from './blog-category-overview.reducer'
import * as BlogCategoryDetailPage from './blog-category-detail-page.reducer'

export const blogCategoryFeatureKey = 'blogCategory';

export interface BlogCategoryState {
    blogCategoryOverview: BlogCategoryOverview.State,
    blogCategoryDetail: BlogCategoryDetailPage.State,
}

export interface State {
    blogCategory: BlogCategoryState
}

const map: ActionReducerMap<BlogCategoryState, Action> = {
    blogCategoryOverview: BlogCategoryOverview.reducer,
    blogCategoryDetail: BlogCategoryDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: BlogCategoryState, action: Action) => combineReducers(map)(state, action);

const selectBlogCategoryState = createFeatureSelector<BlogCategoryState>(blogCategoryFeatureKey);

export const selectState = createSelector(selectBlogCategoryState, state => state)
export const selectBlogCategoryOverviewState = createSelector(selectState, state => state.blogCategoryOverview)

export const selectBlogCategoryOverviewLoading = createSelector(selectBlogCategoryOverviewState, BlogCategoryOverview.getLoading)
export const selectBlogCategoryOverviewList = createSelector(selectBlogCategoryOverviewState, BlogCategoryOverview.getList)
export const selectBlogCategoryOverviewPagination = createSelector(selectBlogCategoryOverviewState, BlogCategoryOverview.getPagination)
export const selectBlogCategoryOverviewSorting = createSelector(selectBlogCategoryOverviewState, BlogCategoryOverview.getSorting)

export const selectBlogCategoryDetailState = createSelector(selectState, state => state.blogCategoryDetail);

export const selectDetailLoading = createSelector(selectBlogCategoryDetailState, BlogCategoryDetailPage.getLoading);
export const selectDetailBlogCategory = createSelector(selectBlogCategoryDetailState, BlogCategoryDetailPage.getBlogCategory);
export const selectDetailErrors = createSelector(selectBlogCategoryDetailState, BlogCategoryDetailPage.getErrors);
