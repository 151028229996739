import { Component, OnInit } from '@angular/core';
import * as Review from '@app/modules/review/reducers'
import { Store } from '@ngrx/store';
import * as ReviewApiActions from '@app/modules/review/actions/review-api.actions';
import { State } from '@app/reducers';
import { ReviewCreateModel, ReviewUpdateModel } from '@app/modules/review/models';

@Component({
    selector: 'app-review-detail',
    templateUrl: './review-detail.component.html',
    styleUrls: ['./review-detail.component.less'],
})
export class ReviewDetailComponent implements OnInit {
    loading$ = this.store.select(Review.selectDetailLoading);
    errors$ = this.store.select(Review.selectDetailErrors);
    review$ = this.store.select(Review.selectDetailReview);
    activityTypes$ = this.store.select(Review.selectDetailActivityTypes);

    constructor(private readonly store: Store<State>) { }

    ngOnInit(){
        this.store.dispatch(ReviewApiActions.GetActivityTypes());
    }

    submit(review: ReviewCreateModel | ReviewUpdateModel) {
        this.store.dispatch(review.hasOwnProperty('id')
            ? ReviewApiActions.Update(review as ReviewUpdateModel) 
            : ReviewApiActions.Create(review)
        );
    }
}