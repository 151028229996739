import { ValidatorFn, AbstractControl, FormGroup, Validators } from '@angular/forms';

export default class CustomValidators extends Validators {
    static match(controlName: string, matchControlName: string): ValidatorFn {
        return (controls: AbstractControl) => {
            const control = controls.get(controlName);
            const matchControl = controls.get(matchControlName);

            if (matchControl?.errors && !matchControl?.getError('matching')) {
                return null;
            }

            if (control?.value === matchControl?.value) {
                matchControl?.setErrors(null);
                return null;
            }

            matchControl?.setErrors({
                matching: {
                    actualValue: matchControl?.value,
                    requiredValue: control?.value,
                },
            });
            return { matching: true, error: true };
        };
    }

    static correctAmountDecimal(maxDecimal: number): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value == null) return null;

            const regex = new RegExp(`^-?\\d*([.,]\\d{0,${maxDecimal}})?$`);

            if (regex.test(control.value)) {
                return null;
            }
            control.setErrors({
                correctCurrencyDecimalNotation: {},
            });
            return { correctCurrencyDecimalNotation: {}, error: true };
        };
    }

    static minArrayLength(min: number): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value.length >= min) {
                return null;
            }
            control.setErrors({
                minArrayLength: {
                    actualLength: control.value.length,
                    requiredLength: min,
                },
            });
            return { minArrayLength: { requiredLength: min }, error: true };
        };
    }

    static maxArrayLength(max: number): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value.length <= max) {
                return null;
            }
            control.setErrors({
                maxArrayLength: {
                    actualLength: control.value.length,
                    requiredLength: max,
                },
            });
            return { maxArrayLength: { requiredLength: max }, error: true };
        };
    }

    static otherFieldFilled(otherField: AbstractControl, otherFieldName: string): ValidatorFn {
        return (control: AbstractControl) => {
            if (otherField.value != '' && otherField.value != undefined) {
                return null;
            }
            if (control.value == '' || control.value == undefined) {
                return null;
            }
            control.setErrors({
                otherFieldEmpty: {
                    otherName: otherFieldName,
                },
            });
            return { otherFieldEmpty: { otherName: otherFieldName }, error: true };
        };
    }

    static endDateAfterStartDate(startDate: string, endDate: string): ValidatorFn {
        return (controls: AbstractControl) => {
            const startDateControl = controls.get(startDate);
            const endDateControl = controls.get(endDate);

            if (endDateControl?.errors && !endDateControl?.getError('endDateAfterStartDate')) {
                return null;
            }
            const startDateValue = new Date(startDateControl?.value);
            const endDateValue = new Date(endDateControl?.value);

            if (startDateValue <= endDateValue) {
                endDateControl?.setErrors(null);
                return null;
            }

            endDateControl?.setErrors({
                endDateAfterStartDate: {
                    startDate: endDateControl?.value,
                    endDate: startDateControl?.value,
                },
            });
            return { endDateAfterStartDate: true, error: true };
        };
    }

    static assetRequired(): ValidatorFn {
        return (control: AbstractControl | FormGroup) => {
            const name = control.get('name');
            const url = control.get('url');

            return !!name?.value && !!url?.value ? null : { assetRequired: true, error: true };
        };
    }

    static override required({ value }: AbstractControl): { required: boolean } | null {
        return String(value ?? '').trim() ? null : { required: true };
    }

    static greaterOrEqualToOtherField(field: string): ValidatorFn {
        return (control: AbstractControl) => {
            const group = control.parent;
            const fieldToCompare = group!.get(field);
            if(fieldToCompare!.value == null || control.value == null || !isFinite(Number(control.value))){
                return null
            }
            if (Number(fieldToCompare!.value) <= Number(control.value)) {
                return null;
            }
            control.setErrors({
                higherThanMinimum: {},
            });
            return { higherThanMinimum: {}, error: true };
        };
    }
}
