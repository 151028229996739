import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { emailTemplateClearGuard, emailTemplateExistsGuard } from '@app/modules/email-template/guards';
import { EmailTemplateDetailComponent } from '@app/modules/email-template/pages/detail/email-template-detail.component';
import { EmailTemplateOverviewComponent } from '@app/modules/email-template/pages/overview/email-template-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: EmailTemplateOverviewComponent },
    { path: 'toevoegen', component: EmailTemplateDetailComponent, canActivate: [emailTemplateClearGuard] }, 
    { path: 'bewerken/:id', component: EmailTemplateDetailComponent, canActivate: [emailTemplateExistsGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EmailTemplateRoutingModule { }
