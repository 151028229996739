import * as ClaimCategoryOverview from './claim-category-overview.reducer';
import * as ClaimCategoryDetail from './claim-category-detail.reducer';
import { ActionReducerMap, Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

export const claimCategoryFeatureKey = 'claim-catgory';

export interface ClaimCategoryState {
    claimCategoryOverview: ClaimCategoryOverview.State,
    claimCategoryDetail: ClaimCategoryDetail.State,
}

export interface State {
    claimCategory: ClaimCategoryState
}

const map: ActionReducerMap<ClaimCategoryState> = {
    claimCategoryOverview: ClaimCategoryOverview.reducer,
    claimCategoryDetail: ClaimCategoryDetail.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: ClaimCategoryState, action: Action) => combineReducers(map)(state, action);

const selectClaimCategoryState = createFeatureSelector<ClaimCategoryState>(claimCategoryFeatureKey);

export const selectState = createSelector(selectClaimCategoryState, state => state)
export const selectClaimCategoryOverviewState = createSelector(selectState, state => state.claimCategoryOverview)

export const selectClaimCategoryOverviewLoading = createSelector(selectClaimCategoryOverviewState, ClaimCategoryOverview.getLoading)
export const selectClaimCategoryOverviewList = createSelector(selectClaimCategoryOverviewState, ClaimCategoryOverview.getList)
export const selectClaimCategoryOverviewPagination = createSelector(selectClaimCategoryOverviewState, ClaimCategoryOverview.getPagination)
export const selectClaimCategoryOverviewSorting = createSelector(selectClaimCategoryOverviewState, ClaimCategoryOverview.getSorting)

export const selectClaimCategoryDetailState = createSelector(selectState, state => state.claimCategoryDetail);

export const selectDetailLoading = createSelector(selectClaimCategoryDetailState, ClaimCategoryDetail.getLoading);
export const selectDetailClaimCategory = createSelector(selectClaimCategoryDetailState, ClaimCategoryDetail.getClaimCategory);
export const selectDetailErrors = createSelector(selectClaimCategoryDetailState, ClaimCategoryDetail.getErrors);