import * as TagActions from '@app/modules/tag/actions/tag-api.actions';
import { TagUpdateModel } from '@app/modules/tag/models';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
  tag: null,
  loading: false,
  errors: {},
};

export interface State {
  tag: TagUpdateModel | null;
  loading: boolean;
  errors: { [key: string]: string[] };
}

export const reducer = createReducer(
  initialState,
  on(TagActions.Clear, () => ({ ...initialState })),

  on(TagActions.Get, (state) => ({ ...state, loading: true })),
  on(TagActions.GetSuccess, (state, tag) => ({
    ...state,
    tag,
    loading: false,
  })),
  on(TagActions.GetError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(TagActions.Update, (state) => ({
    ...state,
    loading: true,
    errors: {},
  })),
  on(TagActions.UpdateSuccess, (state, tag) => ({
    ...state,
    tag,
    loading: false,
  })),
  on(TagActions.UpdateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(TagActions.Create, (state) => ({
    ...state,
    loading: true,
    errors: {},
  })),
  on(TagActions.CreateSuccess, (state, tag) => ({
    ...state,
    tag,
    loading: false,
  })),
  on(TagActions.CreateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  }))
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getTag = (state: State) => state.tag;
