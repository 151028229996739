<div class="note-item-header">
    <div class="note-item-header__title">
        {{ note.createdOn | date : 'dd MMM y h:mm' }} | {{ note.fullName }}
    </div>
    <div class="note-item-header__actions">
        <button
            nz-button
            nzType="text"
            nzSize="small"
            nzShape="circle"
            class="secondary"
            [ngClass]="note.isImportant ? 'active' : ''"
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Note,
                    claimValues: [ClaimValues.Update]
                }
            ]"
            (click)="setImportant(note.id)">
            <i class="fa-regular fa-thumbtack"></i>
        </button>

        <button
            nz-button
            nzType="text"
            nzSize="small"
            nzShape="circle"
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Note,
                    claimValues: [ClaimValues.Update]
                }
            ]"
            (click)="createEditNoteModal(note.id, note.noteMessage, note.editReason)">
            <i class="fa-regular fa-pen"></i>
        </button>

        <button
            nz-button
            nzType="text"
            nzSize="small"
            nzShape="circle"
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Note,
                    claimValues: [ClaimValues.Delete]
                }
            ]"
            nz-popconfirm
            nzOkDanger
            [nzPopconfirmTitle]="'notification.delete' | translate"
            [nzOkText]="'common.delete' | translate"
            [nzCancelText]="'common.cancel' | translate"
            [nzIcon]="templateIconDelete"
            nzPopconfirmPlacement="left"
            (nzOnConfirm)="deleteNote(note.id)">
            <i class="fa-regular fa-trash-can"></i>
        </button>
    </div>
</div>
<div class="note-item-body" [innerHTML]="note.noteMessage"></div>

<div *ngIf="note.editedOn" class="note-item-edit">
    <span class="note-item-edit__header">
        {{ 'note.fields.edited-on' | translate }} {{ note.editedOn | date : 'dd MMM y h:mm' }}
    </span>
    <div class="note-item-edit__body" [innerHTML]="note.editReason"></div>
</div>

<ng-template #templateIconDelete>
    <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
