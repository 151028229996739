<form nz-form [formGroup]="formGroup">
	<h4>{{ (update ? 'users.detail.edit-title' : 'users.detail.add-title') | translate }}</h4>

	<div class="tab-container">
		<nz-tabset
		[(nzSelectedIndex)]="tabIndex"
		nzType="card">
			<nz-tab [nzTitle]="titleTemplate">
				<ng-template #titleTemplate>
					<div [ngClass]="{'form-group-invalid': formGroup.controls.userFormGroup.invalid}">
						{{ 'users.tabs.user' | translate }}
					</div>
				</ng-template>

				<nz-form-item>
					<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.fullname' | translate }}</nz-form-label>
					<nz-form-control [nzSpan]="24" [nzErrorTip]="FullNameValidationTemplate">
						<input nz-input [formControl]="formGroup.controls.userFormGroup.controls.fullname" />
		
						<ng-template #FullNameValidationTemplate let-control>
							<app-input-validation [control]="control" [field]="'fullname'" [errors]="errors"></app-input-validation>
						</ng-template>
					</nz-form-control>
				</nz-form-item>
		
				<nz-form-item>
					<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.email-username' | translate }}</nz-form-label>
					<nz-form-control [nzSpan]="24" [nzErrorTip]="EmailValidationTemplate">
						<input nz-input [formControl]="formGroup.controls.userFormGroup.controls.email"/>
		
						<ng-template #EmailValidationTemplate let-control>
							<app-input-validation [control]="control" [field]="'email'" [errors]="errors"></app-input-validation>
						</ng-template>
					</nz-form-control>
				</nz-form-item>
		
				<nz-form-item *ngIf="!this.update">
					<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.password' | translate }}</nz-form-label>
					<nz-form-control [nzSpan]="24" [nzErrorTip]="PasswordValidationTemplate">
						<input
							type="password"
							nz-input
							[formControl]="formGroup.controls.userFormGroup.controls.password" />
		
						<ng-template #PasswordValidationTemplate let-control>
							<app-input-validation [control]="control" [field]="'password'" [errors]="errors"></app-input-validation>
						</ng-template>
					</nz-form-control>
				</nz-form-item>
		
				<nz-form-item *ngIf="!this.update">
					<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.password-confirmation' | translate }}</nz-form-label>
					<nz-form-control [nzSpan]="24" [nzErrorTip]="PasswordConfirmationValidationTemplate">
						<input
							type="password"
							nz-input
							[formControl]="formGroup.controls.userFormGroup.controls.passwordConfirmation" />
		
						<ng-template #PasswordConfirmationValidationTemplate let-control>
							<app-input-validation
								[control]="control"
								[field]="'passwordConfirmation'"
								[errors]="errors"></app-input-validation>
						</ng-template>
					</nz-form-control>
				</nz-form-item>

				<nz-form-item *ngIf="!update || currentUser?.id != user?.id">
					<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.user-roles' | translate }}</nz-form-label>
					<nz-form-control [nzSpan]="24" [nzErrorTip]="UserRolesValidationTemplate">
						<nz-select
							nzMode="multiple"
							style="width: 100%"
							nzShowSearch
							nzAllowClear
							nzPlaceHolder="{{ 'users.fields.user-roles' | translate }}"
							[formControl]="formGroup.controls.userFormGroup.controls.roles">
							<nz-option *ngFor="let role of userRoles" [nzLabel]="role.name" [nzValue]="role.name"></nz-option>
						</nz-select>
						<ng-template #UserRolesValidationTemplate let-control>
							<app-input-validation [control]="control" [field]="'roles'" [errors]="errors"></app-input-validation>
						</ng-template>
					</nz-form-control>
				</nz-form-item>
		
				<nz-form-item>
					<nz-form-label [nzSpan]="24">{{ 'users.fields.pin' | translate }}</nz-form-label>
					<nz-form-control [nzSpan]="24" [nzErrorTip]="PinValidationTemplate">
						<input type="password" nz-input [formControl]="formGroup.controls.userFormGroup.controls.pin" />
		
						<ng-template #PinValidationTemplate let-control>
							<app-input-validation [control]="control" [field]="'pin'" [errors]="errors"></app-input-validation>
						</ng-template>
					</nz-form-control>
				</nz-form-item>

				<nz-form-item *ngIf="!update || currentUser?.id != user?.id">
					<nz-form-label [nzSpan]="24">{{ 'users.fields.active' | translate }}</nz-form-label>
					<label nz-checkbox [nzValue]="user?.active" [formControl]="formGroup.controls.userFormGroup.controls.active"></label>
				</nz-form-item>

				<nz-form-item>
					<nz-form-label [nzSpan]="24">{{ 'users.fields.isTrainer' | translate }}</nz-form-label>
					<label nz-checkbox [nzValue]="user?.isTrainer" [formControl]="formGroup.controls.userFormGroup.controls.isTrainer"></label>
				</nz-form-item>

				<nz-form-item>
					<nz-form-label [nzSpan]="24">{{ 'users.fields.isEmployee' | translate }}</nz-form-label>
					<label nz-checkbox [nzValue]="user?.isEmployee" [formControl]="formGroup.controls.userFormGroup.controls.isEmployee"></label>
				</nz-form-item>
			</nz-tab>
		
			<nz-tab [nzTitle]="titleTemplate" *ngIf="formGroup.controls.userFormGroup.controls.isEmployee.value" formGroupName="employeeFormGroup">
				<ng-template #titleTemplate>
					<div [ngClass]="{'form-group-invalid': formGroup.controls.employeeFormGroup.invalid}">
						{{ 'users.tabs.employee' | translate }}
					</div>
				</ng-template>
				
				<nz-form-item>
					<nz-form-label [nzSpan]="24">{{ 'users.fields.online' | translate }}</nz-form-label>
					<label nz-checkbox [nzValue]="user?.employee?.online" [formControl]="formGroup.controls.employeeFormGroup.controls.online"></label>
				</nz-form-item>

				<nz-form-item>
					<nz-form-label [nzSpan]="24">{{ 'users.fields.showInBCC' | translate }}</nz-form-label>
					<label nz-checkbox [nzValue]="user?.employee?.online" [formControl]="formGroup.controls.employeeFormGroup.controls.showInBCC"></label>
				</nz-form-item>

				<app-asset
					*ngIf="readonly !== undefined"
					ngDefaultControl
					[formGroup]="formGroup.controls.employeeFormGroup.controls.image"
					[errors]="errors"
					[fieldName]="'image'"
					[isReadOnly]="readonly"
					label="{{ 'users.fields.image' | translate }}"
					[required]="true"
				></app-asset>

				<nz-form-item class="mb-15">
					<nz-form-label [nzSpan]="24" nzRequired>
						{{ 'users.fields.signature' | translate }}    
					</nz-form-label>
						
					<nz-form-control [nzSpan]="24">
						<div *ngIf="!readonly" class="button-container">
							<button nz-button nzType="primary" [disabled]="!!formGroup.controls.employeeFormGroup.controls.signature.value" onclick="document.getElementById('signature-file').click()">
								{{ "common.add" | translate }}
							</button>
							<input type="file" id="signature-file" (change)="addSignature($event)" style="display: none;">
						</div>
				
						<app-input-validation [control]="formGroup.controls.employeeFormGroup.controls.signature" [field]="'signature'" [errors]="errors"/>
				
						<nz-list nzBordered>
							<nz-list-item *ngIf="!!formGroup.controls.employeeFormGroup.controls.signature.value">
								@if (update) {
									<a (click)="getSignature()"><i class="fa-regular fa-download"></i> {{ formGroup.controls.employeeFormGroup.controls.signature.value.name }}</a>
								} @else {
									{{ formGroup.controls.employeeFormGroup.controls.signature.value.name }}
								}
				
								<button nz-button nzType="text" class="error" (click)="removeSignature()" *ngIf="!readonly">
									<i class="fa-regular fa-trash-can"></i>
									{{ "common.delete" | translate }}
								</button>
							</nz-list-item>
							<nz-list-footer *ngIf="!formGroup.controls.employeeFormGroup.controls.signature.value">
								{{ "common.assets-placeholder" | translate }}
							</nz-list-footer>
						</nz-list>
					</nz-form-control>
				</nz-form-item>

				<nz-form-item>
					<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.function' | translate }}</nz-form-label>
					<nz-form-control [nzSpan]="24" [nzErrorTip]="FunctionValidationTemplate">
						<input nz-input [formControl]="formGroup.controls.employeeFormGroup.controls.function"/>

						<ng-template #FunctionValidationTemplate let-control>
							<app-input-validation [control]="control" [field]="'function'" [errors]="errors"></app-input-validation>
						</ng-template>
					</nz-form-control>
				</nz-form-item>

				<nz-form-item class="mb-15">
					<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.text' | translate }}</nz-form-label>
					<nz-form-control class="column-reverse" [nzSpan]="24">
						<app-input-validation [control]="formGroup.controls.employeeFormGroup.controls.text" [field]="'text'"
							[errors]="errors"></app-input-validation>
		
						<app-content-block-overview *ngIf="readonly !== undefined" nz-input ngDefaultControl
							[isReadOnly]="readonly" [availableContentTypes]="availableContentTypes" [errors]="errors"
							formArrayName="text"></app-content-block-overview>
					</nz-form-control>
				</nz-form-item>
			</nz-tab>
		</nz-tabset>
		
		<div class="button-container">
			<button style="margin: 14px;" *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
				{{ 'common.save' | translate }}
			</button>
		</div>
	</div>
</form>