import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivityLocationUpdateModel, ActivityLocationCreateModel } from '@app/modules/activity-location/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
    selector: 'app-activity-location-detail-form',
    templateUrl: './activity-location-detail-form.component.html',
})
export class ActivityLocationDetailFormComponent implements OnInit, OnChanges {
    update = false;
    readonly?: boolean;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) activityLocation: ActivityLocationUpdateModel | null = null;

    @Output() submitted = new EventEmitter<ActivityLocationCreateModel | ActivityLocationUpdateModel>();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        locationName: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        calendarName: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        address: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(200)]),
        city: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        zipCode: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(10)]),
        parkInstruction: new FormControl<string | null>(null),
        lunch: new FormControl<string | null>(null),
    });

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.activityLocation;

        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.ActivityLocation, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        this.formGroup.patchValue({
            locationName: this.activityLocation?.locationName,
            calendarName: this.activityLocation?.calendarName,
            address: this.activityLocation?.address,
            city: this.activityLocation?.city,
            zipCode: this.activityLocation?.zipCode,
            parkInstruction: this.activityLocation?.parkInstruction,
            lunch: this.activityLocation?.lunch
        });

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.activityLocation as ActivityLocationUpdateModel).id);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            locationName: this.formGroup.controls.locationName.value ?? '',
            calendarName: this.formGroup.controls.calendarName.value ?? '',
            address: this.formGroup.controls.address.value ?? '',
            city: this.formGroup.controls.city.value ?? '',
            zipCode: this.formGroup.controls.zipCode.value ?? '',
            parkInstruction: this.formGroup.controls.parkInstruction.value ?? '',
            lunch: this.formGroup.controls.lunch.value ?? '',
        });
    }

    private submitUpdate() {
        this.submitted.emit({
            id: this.formGroup.controls.id.value!,
            locationName: this.formGroup.controls.locationName.value ?? '',
            calendarName: this.formGroup.controls.calendarName.value ?? '',
            address: this.formGroup.controls.address.value ?? '',
            city: this.formGroup.controls.city.value ?? '',
            zipCode: this.formGroup.controls.zipCode.value ?? '',
            parkInstruction: this.formGroup.controls.parkInstruction.value ?? '',
            lunch: this.formGroup.controls.lunch.value ?? '',
        });
    }
}