import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
	DiscountCodeCreateModel,
	DiscountCodeTableItemModel,
	DiscountCodeUpdateModel,
} from '@app/modules/discount-codes/models';
import { TableDataModel } from '@app/shared/models';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DiscountCodeService {
	private readonly endpoints = {
		get: 'DiscountCode/:id',
		overview: 'DiscountCode/GetOverview',
		update: 'DiscountCode/Update',
		create: 'DiscountCode/Create',
		delete: 'DiscountCode/Delete?id=:id',
		changeActive: 'DiscountCode/ChangeActive',
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(
		sorting: TableSortingModel,
		pagination: TablePaginationModel
	): Observable<TableDataModel<DiscountCodeTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');

		return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}`);
	}

	get(id: number): Observable<DiscountCodeUpdateModel> {
		return this.httpService.get<any>(this.endpoints.get.replace(':id', id.toString()));
	}

	update(request: DiscountCodeUpdateModel): Observable<DiscountCodeUpdateModel> {
		return this.httpService.put<any>(this.endpoints.update, request);
	}

	create(request: DiscountCodeCreateModel): Observable<DiscountCodeUpdateModel> {
		return this.httpService.post<any>(this.endpoints.create, request);
	}

	delete(id: number): Observable<any> {
		return this.httpService.delete<any>(this.endpoints.delete.replace(':id', id.toString()));
	}

	changeActive(id: number, active: boolean): Observable<any> {
		return this.httpService.put<any>(this.endpoints.changeActive, { id, active });
	}
}
