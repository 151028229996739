import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Login } from '@app/mobile/modules/auth/actions/mobile-auth.actions';
import { Constants } from '@app/shared/models/common/constants';
import CustomValidators from '@app/shared/validators/custom.validator';
import { Store } from '@ngrx/store';
import * as Auth from '@app/mobile/modules/auth/reducers';

@Component({
	selector: 'app-mobile-login-form',
	templateUrl: './mobile-login-form.component.html',
	styleUrls: ['./mobile-login-form.component.less'],
})
export class MobileLoginFormComponent {
	@Input() loading = false;

	pincodeLength = 5;

	constructor(private readonly store: Store<Auth.State>) {}

	public form = new FormGroup({
		email: new FormControl<string | null>(null, [
			CustomValidators.required,
			Validators.pattern(Constants.EmailExpression),
		]),
		pincode: new FormControl<string | null>(null, [CustomValidators.required, Validators.minLength(this.pincodeLength)])
	});

	onCodeChanged(code: string): void {
		this.form.patchValue({
			pincode: code,
		});
	}
	
	onLogin(): void {
		this.loading = true;
		
		this.store.dispatch(
			Login({
				loginRequest: {
					email: this.form.controls.email.value!,
					pin: this.form.controls.pincode.value!,
				},
			})
		);
	}
}
