import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivate as loginClearGuard } from '@app/modules/auth/guards/login-clear.guard';
import { canActivate as passwordClearGuard } from '@app/modules/auth/guards/password-reset-clear.guard';
import { PasswordForgetPageComponent } from '@app/modules/auth/pages/password-forget/password-forget-page.component';
import { PasswordResetPageComponent } from '@app/modules/auth/pages/password-reset/password-reset-page.component';
import { LoginPageComponent } from './pages/login/login-page.component';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginPageComponent, canActivate: [loginClearGuard] },
	{ path: 'wachtwoord-vergeten', component: PasswordForgetPageComponent },
	{ path: 'wachtwoord-herstellen', component: PasswordResetPageComponent, canActivate: [passwordClearGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
