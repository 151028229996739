import { TableItemModel } from "@app/shared/models"
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model";

export const applySortingOrDefault = (initialState: TableStateModel<TableItemModel>, sorting?: TableSortingModel) => ({
    propertyName: sorting?.propertyName === '' ? initialState.sorting.propertyName : sorting?.propertyName || initialState.sorting.propertyName,
    order: sorting?.order === '' ? initialState.sorting.order : sorting?.order || initialState.sorting.order
});

export const applySortingOrDefaultTableSortingModel = (initialState: TableSortingModel, sorting?: TableSortingModel) => ({
    propertyName: sorting?.propertyName === '' ? initialState.propertyName : sorting?.propertyName || initialState.propertyName,
    order: sorting?.order === '' ? initialState.order : sorting?.order || initialState.order
});