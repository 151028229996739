import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as FAQCategoryOverview from './faq-category-overview.reducer'
import * as FAQCategoryDetail from './faq-category-detail.reducer'

export const faqCategoryFeatureKey = 'faqCategory';

export interface FAQCategoryState {
    faqCategoryOverview: FAQCategoryOverview.State,
    faqCategoryDetail: FAQCategoryDetail.State,
}

export interface State {
    faq: FAQCategoryState
}

const map: ActionReducerMap<FAQCategoryState, Action> = {
    faqCategoryOverview: FAQCategoryOverview.reducer,
    faqCategoryDetail: FAQCategoryDetail.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: FAQCategoryState, action: Action) => combineReducers(map)(state, action);

const selectFaqCategoryState = createFeatureSelector<FAQCategoryState>(faqCategoryFeatureKey);

export const selectState = createSelector(selectFaqCategoryState, state => state)
export const selectFaqCategoryOverviewState = createSelector(selectState, state => state.faqCategoryOverview)

export const selectFaqCategoryOverviewLoading = createSelector(selectFaqCategoryOverviewState, FAQCategoryOverview.getLoading)
export const selectFaqCategoryOverviewList = createSelector(selectFaqCategoryOverviewState, FAQCategoryOverview.getList)
export const selectFaqCategoryOverviewPagination = createSelector(selectFaqCategoryOverviewState, FAQCategoryOverview.getPagination)
export const selectFaqCategoryOverviewSorting = createSelector(selectFaqCategoryOverviewState, FAQCategoryOverview.getSorting)

export const selectFaqCategoryDetailState = createSelector(selectState, state => state.faqCategoryDetail);

export const selectDetailLoading = createSelector(selectFaqCategoryDetailState, FAQCategoryDetail.getLoading);
export const selectDetailFaqCategory = createSelector(selectFaqCategoryDetailState, FAQCategoryDetail.getFAQCategory);
export const selectDetailErrors = createSelector(selectFaqCategoryDetailState, FAQCategoryDetail.getErrors);