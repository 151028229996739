import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailTemplateCreateModel } from '@app/modules/email-template/models/email-template-create.model';
import { EmailTemplateDefaultModel } from '@app/modules/email-template/models/email-template-default.model';
import { EmailTemplateUpdateModel } from '@app/modules/email-template/models/email-template-update.model';
import {
  isFormValid,
  processBackendValidators,
} from '@app/shared/utilities/validation.utilities';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';
import { firstValueFrom, pairwise, startWith } from 'rxjs';
import * as EmailTemplate from '@app/modules/email-template/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { AssetFormGroup } from '@app/shared/models/assetmanager';
import CustomValidators from '@app/shared/validators/custom.validator';
import { EmailPriority, EmailTemplateType } from '@app/modules/email-template/enums';

@Component({
    selector: 'app-email-template-detail-form',
    templateUrl: './email-template-detail-form.component.html'
})
export class EmailTemplateDetailFormComponent implements OnInit, OnChanges {
    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) emailTemplate: EmailTemplateUpdateModel | null = null;
    @Input({ required: true }) relationTypes: KeyValueModel[] | null = null;
    @Input({ required: true }) set defaultEmailTemplate(emailTemplate: EmailTemplateUpdateModel | null) {
        this.importEmailTemplate(emailTemplate);
    }

    @Output() submitted = new EventEmitter<EmailTemplateCreateModel | EmailTemplateUpdateModel>();
    @Output() getDefault = new EventEmitter<EmailTemplateDefaultModel>();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        relationTypeId: new FormControl<number | null>(null, [CustomValidators.required]),
        emailTemplateType: new FormControl<EmailTemplateType | null>(null, [CustomValidators.required]),
        templateName: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        showHeaderImage: new FormControl<boolean>(false),
        description: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(400)]),
        senderEmail: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        senderEmailDomain: new FormControl<string>('', [CustomValidators.required]),
        subject: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        assets: new FormArray<AssetFormGroup>([]),
        message: new FormControl<string>('', [CustomValidators.required]),
        priority: new FormControl<EmailPriority | null>(null, [CustomValidators.required])
    });

    EmailTemplateType = EmailTemplateType;
    EmialPriority = EmailPriority;

    update = false;
    readonly?: boolean;

    private readonly defaultSenderEmailPrefix = 'info';
    private readonly defaultSenderEmailDomain = 'ivsw.nl';

  constructor(
    private readonly store: Store<State>,
    private readonly claimService: ClaimService
  ) {}

    async ngOnInit() {
        this.update = !!this.emailTemplate;

        if (this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.EmailTemplate, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        if (this.update) {
            this.formGroup.patchValue({
                id: this.emailTemplate?.id,
                relationTypeId: this.emailTemplate?.relationTypeId,
                emailTemplateType: this.emailTemplate?.emailTemplateType,
                templateName: this.emailTemplate?.templateName,
                showHeaderImage: this.emailTemplate?.showHeaderImage,
                description: this.emailTemplate?.description,
                senderEmail: this.getSenderPrefix(this.emailTemplate?.senderEmail),
                senderEmailDomain: this.getSenderEmailDomain(this.emailTemplate?.senderEmail),
                subject: this.emailTemplate?.subject,
                message: this.emailTemplate?.message,
                priority: this.emailTemplate?.priority
            });

            (this.emailTemplate?.assets ?? []).forEach(asset => this.formGroup.controls.assets.push(new AssetFormGroup(asset)));

            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.relationTypeId.disable();
        } else {
            this.formGroup.controls.senderEmail.setValue(this.defaultSenderEmailPrefix);
            this.formGroup.controls.senderEmailDomain.setValue(this.defaultSenderEmailDomain);
            this.formGroup.controls.emailTemplateType.setValue(await firstValueFrom(this.store.select(EmailTemplate.selectDetailEmailTemplateType)));
        }

        this.formGroup.valueChanges
            .pipe(
                startWith(this.formGroup.value),
                pairwise())
            .subscribe(([prev, current]) => {
                if ((prev.relationTypeId === current.relationTypeId && prev.emailTemplateType === current.emailTemplateType) || !current.relationTypeId || !current.emailTemplateType) return;

                this.getDefault.emit({
                    relationTypeId: current.relationTypeId,
                    emailTemplateType: current.emailTemplateType
                });
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

  submit() {
    if (Object.keys(this.errors).length === 0 && isFormValid(this.formGroup)) {
      this.update ? this.submitUpdate() : this.submitCreate();
    }
  }

    private submitCreate() {
        this.submitted.emit({
            relationTypeId: this.formGroup.controls.relationTypeId.value!,
            emailTemplateType: this.formGroup.controls.emailTemplateType.value!,
            templateName: this.formGroup.controls.templateName.value!,
            showHeaderImage: this.formGroup.controls.showHeaderImage.value!,
            description: this.formGroup.controls.description.value!,
            senderName: 'IVSW',
            senderEmail: this.combineSenderEmail(this.formGroup.controls.senderEmail.value!, this.formGroup.controls.senderEmailDomain.value!),
            subject: this.formGroup.controls.subject.value!,
            message: this.formGroup.controls.message.value!,
            assets: this.formGroup.controls.assets.value!,
            priority: this.formGroup.controls.priority.value!
        });
    }

    private submitUpdate() {
        this.submitted.emit({
            id: this.formGroup.controls.id.value!,
            relationTypeId: this.formGroup.controls.relationTypeId.value,
            emailTemplateType: this.formGroup.controls.emailTemplateType.value!,
            templateName: this.formGroup.controls.templateName.value!,
            showHeaderImage: this.formGroup.controls.showHeaderImage.value!,
            description: this.formGroup.controls.description.value!,
            senderName: 'IVSW',
            senderEmail: this.combineSenderEmail(this.formGroup.controls.senderEmail.value!, this.formGroup.controls.senderEmailDomain.value!),
            subject: this.formGroup.controls.subject.value!,
            message: this.formGroup.controls.message.value!,
            assets: this.formGroup.controls.assets.value!,
            priority: this.formGroup.controls.priority.value!
        });
    }

    private importEmailTemplate(emailTemplate: EmailTemplateUpdateModel | null) {
        if (!emailTemplate) return;

        this.emailTemplate = emailTemplate;

        this.formGroup.patchValue({
            templateName: emailTemplate.templateName,
            showHeaderImage: emailTemplate.showHeaderImage,
            description: emailTemplate.description,
            senderEmail: this.getSenderPrefix(emailTemplate.senderEmail),
            senderEmailDomain: this.getSenderEmailDomain(emailTemplate.senderEmail),
            subject: emailTemplate.subject,
            message: emailTemplate.message,
            assets: emailTemplate.assets,
            priority: emailTemplate.priority
        });
    }

    private getSenderEmailDomain(senderEmail: string | undefined): string {
        return senderEmail?.split('@')[1] ?? '';
    }

    private getSenderPrefix(senderEmail: string | undefined): string {
        return senderEmail?.split('@')[0] ?? '';
    }

    private combineSenderEmail(senderPrefix: string, senderDomain: string): string {
        return `${senderPrefix}@${senderDomain}`;
    }
}