<div class="page-header">
  <h1 class="page-header__title">{{ 'pages.title' | translate }}</h1>
  <div class="page-header__actions">
    <a
      claimDirective
      [claims]="[
				{
					claimType: ClaimTypes.Page,
					claimValues: [ClaimValues.Create]
				}
			]"
      routerLink="/paginas/toevoegen/"
      nz-button
      nzType="primary">
      {{ 'common.add' | translate }}
      <i class="fa-regular fa-plus"></i>
    </a>
  </div>
</div>

<app-page-overview-table
  [data]="(tableData$ | async)!"
  (delete)="onDelete($event)"
  (onQueryParamsChange)="onQueryParamsChange($event)"
  (onResetOverview)="onResetOverview()"
  (onChangeVisible)="onChangeVisible($event)"/>
