import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as ActivityApiActions from '@app/modules/activities/actions/activities-api.actions';
import { ActivityService } from '@app/modules/activities/services/activities.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    const activityService = inject(ActivityService);
    const router = inject(Router);
    const store = inject(Store<State>);

    return activityService.get(route.params['id']).pipe(
        map((activity) => ActivityApiActions.GetSuccess(activity)),
        tap((action: any) => store.dispatch(action)),
        map((activity) => !!activity),
        catchError(() => {
            return router.navigate(['/not-found']);
        }),
    );
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    canActivate(route, state);
