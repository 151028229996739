import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { activityClearGuard, activityExistsGuard, activityTemplateExistsGuard } from '@app/modules/activity/guards';
import { ActivityDetailComponent } from '@app/modules/activity/pages/detail/activity-detail.component';
import { ActivityViewComponent } from '@app/modules/activity/pages/view/activity-view.component';
import { ActivityOverviewComponent } from '@app/modules/activity/pages/overview/activity-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: ActivityOverviewComponent },
    { path: 'detail/:id', component: ActivityViewComponent, canActivate: [activityExistsGuard] },
    { path: 'toevoegen', component: ActivityDetailComponent, canActivate: [activityClearGuard] },
    { path: 'toevoegen/:id', component: ActivityDetailComponent, canActivate: [activityClearGuard, activityTemplateExistsGuard] },
    { path: 'bewerken/:id', component: ActivityDetailComponent, canActivate: [activityExistsGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ActivityRoutingModule { }
