<div class="auth-card">
	<div class="auth-card-content">
		<h1>{{ 'auth.forget-password' | translate }}</h1>
		<form *ngIf="!submitted" nz-form nzLayout="vertical" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
			<nz-form-item>
				<nz-form-control [nzErrorTip]="EmailValidationTemplate" [nzExtra]="instruction">
                    <ng-template #instruction>
                        <p>{{ 'auth.forget-password-instruction' | translate }}</p>
                    </ng-template>

					<nz-form-label nzFor="email">{{ 'common.email' | translate }}</nz-form-label>
					<nz-input-group nzSize="large">
						<input
							nz-input
							type="email"
							id="email"
							[placeholder]="'placeholder.email' | translate"
							[formControl]="formGroup.controls.email" />
					</nz-input-group>
					<ng-template #EmailValidationTemplate let-control>
						<app-input-validation [control]="control" [field]="'email'"></app-input-validation>
					</ng-template>
				</nz-form-control>
			</nz-form-item>

			<nz-form-item>
				<button type="submit" [disabled]="formGroup.invalid" nz-button nzBlock nzType="primary" class="success" nzSize="large" nzShape="round">
					{{ 'auth.send' | translate }}
				</button>
			</nz-form-item>
		</form>
		<nz-result *ngIf="submitted" nzStatus="success" nzSubTitle="{{ 'auth.password-request-complete' | translate }}">
			<div nz-result-extra>
				<a routerLink="/auth/login">{{ 'auth.back-to-login' | translate }}</a>
			</div>
		</nz-result>
	</div>
</div>
