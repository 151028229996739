import * as MaterialApiActions from '@app/modules/materials/actions/material-api.actions';
import { MaterialTableItemModel } from '@app/modules/materials/models/material-table.model';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = new TableOverviewModel<MaterialTableItemModel>(
  'Name'
);

export interface State extends TableStateModel<MaterialTableItemModel> {}

export const reducer = createReducer(
  initialState,
  on(MaterialApiActions.GetOverview, (state) => ({ ...state, loading: true })),
  on(MaterialApiActions.GetOverviewSuccess, (state, response) => ({
    ...state,
    ...response,
    loading: false,
  })),
  on(MaterialApiActions.GetOverviewError, (state) => ({
    ...state,
    loading: false,
  })),
  on(MaterialApiActions.FilterOverview, (state, { sorting, pagination }) => ({
    ...state,
    sorting: applySortingOrDefault(initialState, sorting),
    pagination: {
      ...state.pagination,
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
  }))
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
