import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
    ActivityTemplateCreateModel,
    ActivityTemplateTableItemModel,
    ActivityTemplateUpdateModel,
} from '@app/modules/activity-template/models';
import { TableDataModel } from '@app/shared/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ActivityTemplateService {
	private readonly endpoints = {
		get: 'ActivityTemplate/:id',
		overview: 'ActivityTemplate/GetOverview',
		update: 'ActivityTemplate/Update',
		create: 'ActivityTemplate/Create',
		delete: 'ActivityTemplate/Delete?id=:id',
		changeSequence: 'ActivityTemplate/ChangeSequence/',
		getAll: 'ActivityTemplate/GetAll',
	};

	constructor(private readonly httpService: HttpService) {}

	getOverview(
		sorting: TableSortingModel,
		pagination: TablePaginationModel,
		activityId: number
	): Observable<TableDataModel<ActivityTemplateTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');
		const activityIdParam = `activityTypeId=${activityId}`;

		return this.httpService.get<TableDataModel<ActivityTemplateTableItemModel>>(this.endpoints.overview + `?${paginationParams}&${sortingParams}&${activityIdParam}`);
	}

	getAll(): Observable<KeyValueModel[]> {
		return this.httpService.get<KeyValueModel[]>(this.endpoints.getAll);
	}

	get(id: number): Observable<ActivityTemplateUpdateModel> {
		return this.httpService.get<ActivityTemplateUpdateModel>(this.endpoints.get.replace(':id', id.toString()));
	}

	update(request: ActivityTemplateUpdateModel): Observable<ActivityTemplateUpdateModel> {
		return this.httpService.put<ActivityTemplateUpdateModel>(this.endpoints.update, request);
	}

	create(request: ActivityTemplateCreateModel): Observable<ActivityTemplateUpdateModel> {
		return this.httpService.post<ActivityTemplateUpdateModel>(this.endpoints.create, request);
	}

	delete(id: number): Observable<void> {
		return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
	}
}
