import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectClaims } from '@app/modules/auth/reducers';
import { Claim } from '@app/shared/models/common/claim.model';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ClaimService {
    claims$ = this.store.select(selectClaims);

    claims: KeyValue<string, string[]>[] | null = [];

    constructor(private readonly store: Store) {
        this.claims$.pipe(takeUntilDestroyed()).subscribe((claims) => (this.claims = claims));
    }

    async hasAny(claims: Claim[]): Promise<boolean> {
        const userClaims = await firstValueFrom(this.claims$);

        return (
            (userClaims &&
                userClaims.some((u) => {
                    return claims.some((c) => {
                        return c.claimType === u.key && c.claimValues.some((cv) => u.value.includes(cv));
                    });
                })) ??
            false
        );
    }

    public has(claims: Claim[]) {
        return (this.claims &&
            this.claims.some((u) => {
                return claims.some((c) => {
                    return c.claimType === u.key && c.claimValues.some((cv) => u.value.includes(cv));
                });
            })) ??
            false;
    }
}
