import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { activityTemplateClearGuard, activityTemplateExistsGuard } from '@app/modules/activity-template/guards';
import { ActivityTemplateDetailComponent } from '@app/modules/activity-template/pages/detail/activity-template-detail.component';
import { ActivityTemplateOverviewComponent } from '@app/modules/activity-template/pages/overview/activity-template-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: ActivityTemplateOverviewComponent },
    { path: 'toevoegen', component: ActivityTemplateDetailComponent, canActivate: [activityTemplateClearGuard] },
    { path: 'bewerken/:id', component: ActivityTemplateDetailComponent, canActivate: [activityTemplateExistsGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ActivityTemplateRoutingModule { }
