import { ErrorResponse } from "@app/core/models/error-response.model";
import { ActivityTemplateTableItemModel, ActivityTemplateUpdateModel, ActivityTemplateCreateModel } from "@app/modules/activity-template/models";
import { ActivityTypeCodeModel } from "@app/modules/activity-type/models/activity-type-code.model";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[ActivityTemplate] GetOverview");
export const GetOverviewError = createAction("[ActivityTemplate] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[ActivityTemplate] GetOverviewSuccess", props<TableDataModel<ActivityTemplateTableItemModel>>());
export const ResetOverview = createAction("[ActivityTemplate] ResetOverview");

export const FilterOverview = createAction("[ActivityTemplate] FilterOverview", props<{activityTypeId: number ,sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[ActivityTemplate] Clear");

export const Get = createAction("[ActivityTemplate] Get", props<{ id: number }>());
export const GetError = createAction("[ActivityTemplate] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[ActivityTemplate] GetSuccess", props<ActivityTemplateUpdateModel>());

export const Update = createAction("[ActivityTemplate] Update", props<ActivityTemplateUpdateModel>());
export const UpdateError = createAction("[ActivityTemplate] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[ActivityTemplate] UpdateSuccess", props<ActivityTemplateUpdateModel>());

export const Create = createAction("[ActivityTemplate] Create", props<ActivityTemplateCreateModel>());
export const CreateError = createAction("[ActivityTemplate] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[ActivityTemplate] CreateSuccess", props<ActivityTemplateUpdateModel>());

export const Delete = createAction("[ActivityTemplate] Delete", props<{ id: number }>());
export const DeleteError = createAction("[ActivityTemplate] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[ActivityTemplate] DeleteSuccess");

export const SetActiveActivityTypeId = createAction("[ActivityTemplate] SetActiveActivityTypeId", props<{ activityTypeId: number }>());

export const GetActivityTypes = createAction("[ActivityTemplate] GetActivityTypes");
export const GetActivityTypesError = createAction("[ActivityTemplate] GetActivityTypesError", props<{ response: ErrorResponse }>());
export const GetActivityTypesSuccess = createAction("[ActivityTemplate] GetActivityTypesSuccess", props<{ activityTypes: ActivityTypeCodeModel[] }>());