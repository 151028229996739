import { Component, Input } from '@angular/core';
import { CountryModel } from '@app/modules/countries/models';
import { BillingInformationFormGroup } from '@app/shared/models/billing-information/billing-information-form-group';

@Component({
	selector: 'app-billing-information-form',
    styleUrl: './billing-information-form.component.less',
	templateUrl: './billing-information-form.component.html',
})
export class BillingInformationFormComponent {
    @Input({ required: true }) formGroup: BillingInformationFormGroup;
    @Input({ required: true }) countries: CountryModel[] = [];

	@Input() required = false;
	@Input() disabled = false;
}
