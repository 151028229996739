import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FAQCreateModel, FAQUpdateModel } from '@app/modules/faq/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { ContentBlockFormGroup, ContentBlockType } from '@app/shared/models/content';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
	selector: 'app-faq-detail-form',
    styleUrls: ['./faq-detail-form.component.less'],
	templateUrl: './faq-detail-form.component.html',
})
export class FAQDetailFormComponent implements OnInit, OnChanges {
	update = false;
	readonly?: boolean;

	@Input() loading = false;
	@Input() errors: { [key: string]: string[] } = {};
	@Input({ required: true }) faq: FAQUpdateModel | null = null;
	@Input() faqCategories: KeyValueModel[] | null = null;
	@Input() faqCategoryId: number | null = null;

	@Output() submitted = new EventEmitter<FAQCreateModel | FAQUpdateModel>();

	formGroup = new FormGroup({
		id: new FormControl<number | null>(null),
		online: new FormControl<boolean>(true, [CustomValidators.required]),
		faqCategoryId: new FormControl<number | null>(null, [CustomValidators.required]),
		question: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(200)]),
		answer: new FormArray<ContentBlockFormGroup>([], [CustomValidators.minArrayLength(1)]),
	});
	availableContentTypes = [ContentBlockType.Editor, ContentBlockType.ImageAndEditor];

	constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
		this.update = !!this.faq;

        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

		this.formGroup.patchValue({
			online: this.faq?.online ?? true,
			faqCategoryId: this.faqCategoryId,
			question: this.faq?.question ?? '',
		});

        (this.faq?.contentBlocks ?? []).forEach(contentBlock => this.formGroup.controls.answer.push(new ContentBlockFormGroup(contentBlock)));

		if (this.update) {
			this.formGroup.controls.id.setValidators([CustomValidators.required]);
			this.formGroup.controls.id.setValue((this.faq as FAQUpdateModel).id);
			this.formGroup.controls.faqCategoryId.setValue((this.faq as FAQUpdateModel).faqCategoryId);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		processBackendValidators(changes, this.formGroup);
	}

	submit() {
		if (isFormValid(this.formGroup)) {
			this.update ? this.submitUpdate() : this.submitCreate();
		}
	}

	private submitCreate() {
		this.submitted.emit({
			online: this.formGroup.controls.online.value ?? false,
			faqCategoryId: this.formGroup.controls.faqCategoryId.value ?? 0,
			question: this.formGroup.controls.question.value ?? '',
			contentBlocks: this.formGroup.controls.answer.value ?? [],
		});
	}

	private submitUpdate() {
		this.submitted.emit({
			id: this.formGroup.controls.id.value ?? 0,
			online: this.formGroup.controls.online.value ?? false,
			faqCategoryId: this.formGroup.controls.faqCategoryId.value ?? 0,
			question: this.formGroup.controls.question.value ?? '',
			contentBlocks: this.formGroup.controls.answer.value ?? [],
		});
	}
}
