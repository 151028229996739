import { Component } from '@angular/core';
import * as Material from '@app/modules/materials/reducers';
import * as MaterialActions from '@app/modules/materials/actions/material-api.actions';
import { Store } from '@ngrx/store';
import * as MaterialApiActions from '@app/modules/materials/actions/material-api.actions';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
  selector: 'app-material-overview',
  templateUrl: './material-overview.component.html',
  styleUrls: ['./material-overview.component.less'],
})
export class MaterialOverviewComponent {
  tableData$ = this.store.select(Material.selectMaterialOverviewState);

  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;

  constructor(private readonly store: Store) {}

  onDeleteConfirm(id: number): void {
    this.store.dispatch(MaterialActions.Delete({ id: id }));
  }

  onChangeQuotation(event: any): void {
    this.store.dispatch(
      MaterialActions.UpdateQuotation({
        id: event.id,
        inQuotation: event.inQuotation,
      })
    );
  }

  /**
   * Trigger the loading of data whenever the table changes.
   * The default pageSize, index and sort options are based on the TableStateModel.
   * @param params
   */
  onQueryParamsChange(params: NzTableQueryParams) {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort?.key) ?? null;
    const sortOrder = (currentSort?.value) ?? null;

    this.store.dispatch(
      MaterialApiActions.FilterOverview({
        pagination: {
          page: pageIndex,
          pageSize,
        },
        sorting: {
          propertyName: sortField ?? '',
          order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
        },
      })
    );
  }
}
