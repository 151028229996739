import { NgModule } from '@angular/core';
import { DiscountCodeDetailFormComponent } from '@app/modules/discount-codes/components/discount-code-detail-form/discount-code-detail-form.component';
import { DiscountCodeOverviewTableComponent } from '@app/modules/discount-codes/components/discount-code-overview-table/discount-code-overview-table.component';
import { DiscountCodeRoutingModule } from '@app/modules/discount-codes/discount-code-routing.module';
import { DiscountCodeEffects } from '@app/modules/discount-codes/effects/discount-code.effects';
import { DiscountCodeDetailComponent } from '@app/modules/discount-codes/pages/detail/discount-code-detail.component';
import { DiscountCodeOverviewComponent } from '@app/modules/discount-codes/pages/overview/discount-code-overview.component';
import { discountCodeFeatureKey, reducers } from '@app/modules/discount-codes/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
	DiscountCodeOverviewTableComponent,
	DiscountCodeOverviewComponent,
	DiscountCodeDetailFormComponent,
	DiscountCodeDetailComponent,
];

const IMPORTS = [
	SharedModule,
	DiscountCodeRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(discountCodeFeatureKey, reducers),
	EffectsModule.forFeature([DiscountCodeEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class DiscountCodeModule {}
