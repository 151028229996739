import * as UserActions from '@app/modules/users/actions/user-api.actions';
import { UserType } from '@app/modules/users/enums/user-type';
import { UserTableItemModel } from '@app/modules/users/models';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
	...new TableOverviewModel<UserTableItemModel>('id'),
	userTypes: [UserType.All]
};

export interface State extends TableStateModel<UserTableItemModel> {
	userTypes: UserType[]
}

export const reducer = createReducer(
	initialState,
	on(UserActions.GetOverview, (state) => ({ ...state, loading: true })),
	on(UserActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
	on(UserActions.GetOverviewError, (state) => ({ ...state, loading: false })),
	on(UserActions.FilterOverview, (state, { sorting, pagination }) => ({
		...state,
		sorting: applySortingOrDefault(initialState, sorting),
		pagination: {
			...state.pagination,
			page: pagination.page,
			pageSize: pagination.pageSize,
		},
	})),
	on(UserActions.SetUserTypes, (state, { userTypes }) => ({ ...state, userTypes }))
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
export const getUserTypes = (state: State) => state.userTypes;
