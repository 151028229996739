import { NgModule } from "@angular/core";
import { ActivityRelationCreateModalComponent } from "@app/modules/activity-relation/components/activity-relation-create-modal/activity-relation-create-modal.component";
import { ActivityRelationTablePartComponent } from "@app/modules/activity-relation/components/activity-relation-overview-table/activity-relation-overview-table.component";
import { ActivityRelationTableComponent } from "@app/modules/activity-relation/components/activity-relation-overview/activity-relation-overview.component";
import { ActivityWaitlistDropdownComponent } from "@app/modules/activity-relation/components/waitlist-dropdown/waitlist-dropdown.component";

import { ActivityRelationEffects } from "@app/modules/activity-relation/effects/activity-relation.effects";
import { activityRelationFeatureKey, reducers } from "@app/modules/activity-relation/reducers";
import { ActivityRoutingModule } from "@app/modules/activity/activity-routing.module";
import { SharedModule } from "@app/shared/shared.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
    ActivityRelationTableComponent,
    ActivityRelationCreateModalComponent,
    ActivityWaitlistDropdownComponent,
    ActivityRelationTablePartComponent
];

const IMPORTS = [
    SharedModule,
    ActivityRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(activityRelationFeatureKey, reducers),
    EffectsModule.forFeature([ActivityRelationEffects])
];

@NgModule({
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class ActivityRelationModule { }
