import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { LoginRequest } from '@app/modules/auth/models/login-request.model';
import { LoginResponse } from '@app/shared/models/auth/login-response.model';
import { UserModel } from '@app/shared/models/auth/user.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private readonly endpoints = {
		login: 'BackofficeUser/login',
		currentUser: 'BackofficeUser/currentuser',
		requestPasswordReset: 'BackofficeUser/requestpasswordreset',
		resetPassword: 'BackofficeUser/resetpassword',
	};

	constructor(private readonly httpService: HttpService) {}

	login(request: LoginRequest): Observable<LoginResponse> {
		return this.httpService.post<LoginResponse>(this.endpoints.login, request);
	}

	currentUser(): Observable<UserModel> {
		return this.httpService.get<UserModel>(this.endpoints.currentUser);
	}

	requestPasswordReset(email: string, passwordResetUrl: string): Observable<void> {
		return this.httpService.post<void>(this.endpoints.requestPasswordReset, {
			userName: email,
			routerLink: passwordResetUrl,
		});
	}

	resetPassword(email: string, password: string, token: string): Observable<void> {
		return this.httpService.post<void>(this.endpoints.resetPassword, {
			userName: email,
			newPassword: password,
			resetToken: token,
		});
	}
}
