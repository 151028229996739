import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as BlogCategoryActions from '@app/modules/blog-category/actions/blog-category-api.actions';
import * as BlogCategory from '@app/modules/blog-category/reducers';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, exhaustMap, catchError, of, tap } from 'rxjs';
import { BlogCategoryService } from '@app/modules/blog-category/services/blog-category.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';

@Injectable()
export class BlogCategoryEffects {
  constructor(
    private actions$: Actions,
    private blogCategoryService: BlogCategoryService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<BlogCategory.State>
  ) {}

  /**
   * Get effects
   */
  loadBlogCategoriess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(BlogCategory.selectBlogCategoryOverviewPagination),
        this.store.select(BlogCategory.selectBlogCategoryOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.blogCategoryService.getAll(sorting, pagination).pipe(
          map((response) => BlogCategoryActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(
              BlogCategoryActions.GetOverviewError({ response: response.error })
            )
          )
        )
      )
    )
  );

  loadBlogCategoriesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.FilterOverview),
      map(() => BlogCategoryActions.GetOverview())
    )
  );

  loadBlogCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.Get),
      exhaustMap((action) =>
        this.blogCategoryService.get(action.id).pipe(
          map((response) => BlogCategoryActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogCategoryActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createBlogCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.Create),
      exhaustMap((action) =>
        this.blogCategoryService.create(action).pipe(
          map((blogCategory) =>
            BlogCategoryActions.CreateSuccess(blogCategory)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogCategoryActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createBlogCategorySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlogCategoryActions.CreateSuccess),
        tap((blogCategory) =>
          this.translate
            .get('notification.add', { item: blogCategory.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateBlogCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.Update),
      exhaustMap((action) =>
        this.blogCategoryService.update(action).pipe(
          map((blogCategory) =>
            BlogCategoryActions.UpdateSuccess(blogCategory)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogCategoryActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateBlogCategorySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlogCategoryActions.UpdateSuccess),
        tap((blogCategory) =>
          this.translate
            .get('notification.save', { item: blogCategory.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateBlogCategorySuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BlogCategoryActions.CreateSuccess,
          BlogCategoryActions.UpdateSuccess
        ),
        tap(() => this.router.navigate(['blog-categorieen']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteBlogCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.Delete),
      exhaustMap((action) =>
        this.blogCategoryService.delete(action.id).pipe(
          map(() => BlogCategoryActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogCategoryActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteBlogCategorySuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.DeleteSuccess),
      map(() => BlogCategoryActions.GetOverview())
    )
  );

  deleteBlogCategorySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlogCategoryActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change sequence effects
   */
  changeSequenceBlogCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.ChangeSequence),
      exhaustMap((action) =>
        this.blogCategoryService.changeSequence(action).pipe(
          map(() => BlogCategoryActions.ChangeSequenceSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(
              BlogCategoryActions.ChangeSequenceError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  changeSequenceSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogCategoryActions.ChangeSequenceSuccess),
      map(() => BlogCategoryActions.GetOverview())
    )
  );

  blogCategoriesErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BlogCategoryActions.DeleteError,
          BlogCategoryActions.GetOverviewError,
          BlogCategoryActions.ChangeSequenceError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
