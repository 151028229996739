import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ClaimCategoryUpdateModel } from '@app/modules/claim-categories/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid } from '@app/shared/utilities/validation.utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-claim-category-detail-form',
	templateUrl: './claim-category-detail-form.component.html',
})
export class ClaimCategoryDetailFormComponent implements OnInit {
	@Input() loading = false;
	@Input() errors: { [key: string]: string[] } = {};
	@Input({ required: true }) claimCategory: ClaimCategoryUpdateModel | null = null;

	@Output() submitted = new EventEmitter<ClaimCategoryUpdateModel>();

	readonly?: boolean;
	formGroup = new UntypedFormGroup({});

	constructor(private translate: TranslateService, private readonly claimService: ClaimService) {}

    async ngOnInit() {
		for (const role of this.claimCategory?.roles!) {
			this.formGroup.addControl(role.id, new FormControl<boolean>(role.active));
			
			if(this.claimCategory?.value === ClaimValues.Read && this.claimCategory?.type === ClaimTypes.AssetManager) {
				this.formGroup.get(role.id)?.setValue(true);
				this.formGroup.get(role.id)?.disable();
			}
			else if (role.hasCUD && this.claimCategory?.value === ClaimValues.Read) {
				this.formGroup.get(role.id)?.disable();
			} 
		}

		if(!await this.claimService.hasAny([{ claimType: ClaimTypes.ClaimCategory, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }
	}

	submit() {
		if (isFormValid(this.formGroup)) {
			this.submitUpdate();
		}
	}

	getEditInfo() {
		return this.translate.instant('claim-categories.detail.edit-info', {
			type: this.translate.instant(`claim-categories.types.${this.claimCategory?.type}`),
			value: this.translate.instant(`claim-categories.values.${this.claimCategory?.value}`),
		});
	}

	private submitUpdate() {
		const roles = Object.keys(this.formGroup.controls)
			.filter((key) => key !== 'type' && key !== 'value')
			.map((key) => ({
				id: key,
				roleName: this.claimCategory?.roles.find((role) => role.id === key)?.roleName,
				active: this.formGroup.get(key)?.value,
			}));

		this.submitted.emit({
			type: this.claimCategory?.type,
			value: this.claimCategory?.value,
			roles: roles,
		} as ClaimCategoryUpdateModel);
	}
}
