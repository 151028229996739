export enum PaymentType {
    None = 1,
    BankTransfer = 2,
    Ideal = 3
}

export const PaymentTypeLabelsMapping: Record<number, string> = {
    [PaymentType.None]: 'activity.enum.none',
    [PaymentType.BankTransfer]: 'activity.enum.bank-transfer',
    [PaymentType.Ideal]: 'activity.enum.ideal',
}