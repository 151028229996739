import { Component, OnInit } from '@angular/core';
import * as DiscountCode from '@app/modules/discount-codes/reducers';
import { Store } from '@ngrx/store';
import * as DiscountCodeApiActions from '@app/modules/discount-codes/actions/discount-code-api.actions';
import { State } from '@app/reducers';
import { DiscountCodeCreateModel, DiscountCodeUpdateModel } from '@app/modules/discount-codes/models';

@Component({
	selector: 'app-discount-code-detail',
	templateUrl: './discount-code-detail.component.html',
	styleUrls: ['./discount-code-detail.component.less'],
})
export class DiscountCodeDetailComponent implements OnInit {
	loading$ = this.store.select(DiscountCode.selectDetailLoading);
	errors$ = this.store.select(DiscountCode.selectDetailErrors);
	discountCode$ = this.store.select(DiscountCode.selectDetailDiscountCode);
	activities$ = this.store.select(DiscountCode.selectDetailActivities);
	relations$ = this.store.select(DiscountCode.selectDetailRelations);
	activityTypes$ = this.store.select(DiscountCode.selectDetailActivityTypes);

	constructor(private readonly store: Store<State>) {}

	ngOnInit() {
		this.store.dispatch(DiscountCodeApiActions.GetActivities());
		this.store.dispatch(DiscountCodeApiActions.GetActivityTypes());
		this.store.dispatch(DiscountCodeApiActions.GetRelations());
	}

	submit(discountCode: DiscountCodeCreateModel | DiscountCodeUpdateModel) {
		this.store.dispatch(
			discountCode.hasOwnProperty('id')
				? DiscountCodeApiActions.Update(discountCode as DiscountCodeUpdateModel)
				: DiscountCodeApiActions.Create(discountCode)
		);
	}
}
