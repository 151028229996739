import { Component, OnInit } from '@angular/core';
import * as ActivityScheduleActions from '@app/mobile/modules/activity-schedule/actions/activity-schedule.actions';
import * as ActivitySchedule from '@app/mobile/modules/activity-schedule/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-activity-schedule-overview-page',
  templateUrl: './activity-schedule-overview-page.component.html',
  styleUrls: ['./activity-schedule-overview-page.component.less'],
})
export class ActivityScheduleOverviewPageComponent implements OnInit {
  date = new Date();
  activitySchedules$ = this.store.select(
    ActivitySchedule.selectActivityScheduleOverviewList
  );

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(ActivityScheduleActions.GetList());
  }
}
