import { Component, OnInit } from '@angular/core';
import * as Auth from '@app/modules/auth/reducers';
import * as UserApiActions from '@app/modules/users/actions/user-api.actions';
import { UserCreateModel, UserUpdateModel } from '@app/modules/users/models';
import { UserSignatureModel } from '@app/modules/users/models/user-signature.model';
import * as User from '@app/modules/users/reducers';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-user-detail',
	templateUrl: './user-detail.component.html',
	styleUrls: ['./user-detail.component.less'],
})
export class UserDetailComponent implements OnInit {
	loading$ = this.store.select(User.selectDetailLoading);
	errors$ = this.store.select(User.selectDetailErrors);
	user$ = this.store.select(User.selectDetailUser);
	userRoles$ = this.store.select(User.selectDetailUserRoles);
	currentUser$ = this.store.select(Auth.selectUser);
	userExists$ = this.store.select(User.selectDetailUserExists);

	constructor(private readonly store: Store<State>) {}

	ngOnInit(): void {
		this.store.dispatch(UserApiActions.GetUserRoles());
	}

	submit(user: UserCreateModel | UserUpdateModel): void {
		this.store.dispatch(
			user.hasOwnProperty('id') ? UserApiActions.Update(user as UserUpdateModel) : UserApiActions.UserExists(user)
		);
	}

	resetUserExists(): void {
		this.store.dispatch(UserApiActions.ResetUserExists());
	}

	protected downloadSignature(signature: UserSignatureModel) {
		this.store.dispatch(UserApiActions.DownloadSignature(signature));
	}
}
