import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as ActivityTemplateOverview from './activity-template-overview.reducer'
import * as ActivityTemplateDetailPage from './activity-template-detail-page.reducer'

export const activityTemplateFeatureKey = 'activityTemplate';

export interface ActivityTemplateState {
    activityTemplateOverview: ActivityTemplateOverview.State,
    activityTemplateDetail: ActivityTemplateDetailPage.State,
}

export interface State {
    activityTemplate: ActivityTemplateState
}

const map: ActionReducerMap<ActivityTemplateState, Action> = {
    activityTemplateOverview: ActivityTemplateOverview.reducer,
    activityTemplateDetail: ActivityTemplateDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: ActivityTemplateState, action: Action) => combineReducers(map)(state, action);

const selectActivityTemplateState = createFeatureSelector<ActivityTemplateState>(activityTemplateFeatureKey);

export const selectState = createSelector(selectActivityTemplateState, state => state)
export const selectActivityTemplateOverviewState = createSelector(selectState, state => state.activityTemplateOverview)

export const selectActivityTemplateOverviewLoading = createSelector(selectActivityTemplateOverviewState, ActivityTemplateOverview.getLoading)
export const selectActivityTemplateOverviewList = createSelector(selectActivityTemplateOverviewState, ActivityTemplateOverview.getList)
export const selectActivityTemplateOverviewPagination = createSelector(selectActivityTemplateOverviewState, ActivityTemplateOverview.getPagination)
export const selectActivityTemplateOverviewSorting = createSelector(selectActivityTemplateOverviewState, ActivityTemplateOverview.getSorting)

export const selectActivityTemplateActivityTypeId = createSelector(selectActivityTemplateOverviewState, ActivityTemplateOverview.getActivityTypeId);
export const selectActivityTemplateActivityTypes = createSelector(selectActivityTemplateOverviewState, ActivityTemplateOverview.getActivityTypes);

export const selectActivityTemplateDetailState = createSelector(selectState, state => state.activityTemplateDetail);

export const selectDetailLoading = createSelector(selectActivityTemplateDetailState, ActivityTemplateDetailPage.getLoading);
export const selectDetailActivityTemplate = createSelector(selectActivityTemplateDetailState, ActivityTemplateDetailPage.getActivityTemplate);
export const selectDetailErrors = createSelector(selectActivityTemplateDetailState, ActivityTemplateDetailPage.getErrors);
export const selectDetailActivityTypes = createSelector(selectActivityTemplateDetailState, ActivityTemplateDetailPage.getActivityTypes);