import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as BlogActions from '@app/modules/blogs/actions/blog-api.actions';
import * as Blog from '@app/modules/blogs/reducers';
import { BlogService } from '@app/modules/blogs/services/blog.service';
import { TagService } from '@app/modules/tag/services/tag.service';
import { UserService } from '@app/modules/users/services/user.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class BlogEffects {
  constructor(
    private actions$: Actions,
    private blogService: BlogService,
    private tagService: TagService,
    private userService: UserService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<Blog.State>
  ) {}

  /**
   * Get effects
   */
  loadBlogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(Blog.selectBlogOverviewPagination),
        this.store.select(Blog.selectBlogOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.blogService.getAll(sorting, pagination).pipe(
          map((response) => BlogActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadBlogsFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.FilterOverview),
      map(() => BlogActions.GetOverview())
    )
  );

  loadBlog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.Get),
      exhaustMap((action) =>
        this.blogService.get(action.id).pipe(
          map((response) => BlogActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  loadEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.GetEmployees),
      exhaustMap(() =>
        this.userService.getEmployees().pipe(
          map((employees) => BlogActions.GetEmployeesSuccess({ employees })),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogActions.GetEmployeesError({ response: response.error }))
          )
        )
      )
    )
  );

  loadTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.GetAllTags),
      exhaustMap((action) =>
        this.tagService.getAllNames(action).pipe(
          map((tags) => BlogActions.GetAllTagsSuccess({ tags })),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogActions.GetAllTagsError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createBlog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.Create),
      exhaustMap((action) =>
        this.blogService.create(action).pipe(
          map((blog) => {
            return BlogActions.CreateSuccess(blog);
          }),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createBlogSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlogActions.CreateSuccess),
        tap((blog) =>
          this.translate
            .get('notification.add', { item: blog.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateBlog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.Update),
      exhaustMap((action) =>
        this.blogService.update(action).pipe(
          map((blog) => BlogActions.UpdateSuccess(blog)),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateBlogSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlogActions.UpdateSuccess),
        tap((blog) =>
          this.translate
            .get('notification.save', { item: blog.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateBlogSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlogActions.CreateSuccess, BlogActions.UpdateSuccess),
        tap(() => this.router.navigate(['blogs']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteBlog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.Delete),
      exhaustMap((action) =>
        this.blogService.delete(action.id).pipe(
          map(() => BlogActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteBlogSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.DeleteSuccess),
      map(() => BlogActions.GetOverview())
    )
  );

  deleteBlogSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlogActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change active effects
   */
  changeActiveBlog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.UpdateShowOnline),
      exhaustMap((action) =>
        this.blogService.changeOnline(action.id, action.showOnline).pipe(
          map((blog) => BlogActions.UpdateShowOnlineSuccess(blog)),
          catchError((response: { error: ErrorResponse }) =>
            of(BlogActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  changeActiveSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlogActions.UpdateShowOnlineSuccess),
        tap((blog) =>
          this.translate
            .get('notification.save', { item: blog.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  changeActiveSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.UpdateShowOnlineSuccess),
      map(() => BlogActions.GetOverview())
    )
  );

  blogErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BlogActions.DeleteError,
          BlogActions.GetOverviewError,
          BlogActions.UpdateShowOnlineError,
          BlogActions.GetAllTagsError,
          BlogActions.GetEmployeesError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
