import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogReactionTableItemModel } from '@app/modules/blog-reaction/models/blog-reaction-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
  selector: 'app-blog-reaction-overview-table',
  templateUrl: './blog-reaction-overview-table.component.html',
  styleUrls: ['./blog-reaction-overview-table.component.less'],
})
export class BlogReactionOverviewTableComponent {
  @Input({ required: true }) data?: TableStateModel<BlogReactionTableItemModel>;
  @Input() subReactions: boolean = false;

  @Output() delete = new EventEmitter<number>();
  @Output() changeShowOnline = new EventEmitter<{
    id: number;
    showOnline: boolean;
  }>();
  @Output() queryParamsChange = new EventEmitter<any>();

  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;
  maxDescriptionLength: number = 30;
  blogId: number | undefined;

  constructor(private readonly activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params) => {
      this.blogId = params['blogId'];
    });
  }

  changeOnline(id: number, showOnline: boolean) {
    this.changeShowOnline.emit({ id, showOnline });
  }
}
