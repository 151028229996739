import * as AuthActions from '@app/modules/auth/actions/auth.actions';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
	errors: {},
	loading: false,
	success: false,
};

export interface State {
	errors: { [key: string]: string[] };
	loading: boolean;
	success: boolean;
}

export const reducer = createReducer(
	initialState,
	on(AuthActions.ResetPassword, (state) => ({ ...state, loading: true })),
	on(AuthActions.ResetPasswordError, (state, { response }) => ({
		...state,
		loading: false,
		errors: response?.errors || {},
	})),
	on(AuthActions.ResetPasswordSuccess, () => ({ ...initialState, success: true })),
	on(AuthActions.ResetPasswordClear, () => ({ ...initialState }))
);

export const getLoading = (state: State) => state.loading;
export const getErrors = (state: State) => state.errors;
export const getSuccess = (state: State) => state.success;
