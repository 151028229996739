import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  TagTableItemModel
} from '@app/modules/tag/models/tag-table.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
	selector: 'app-tag-overview-table',
	templateUrl: './tag-overview-table.component.html',
})
export class TagOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<TagTableItemModel>;

	@Output() delete = new EventEmitter<number>();
	@Output() onQueryParamsChange = new EventEmitter<any>();

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;
}