import { NgModule } from '@angular/core';
import { BlogReactionRoutingModule } from '@app/modules/blog-reaction/blog-reaction-routing.module';
import { BlogReactionDetailFormComponent } from '@app/modules/blog-reaction/components/blog-reaction-detail-form/blog-reaction-detail-form.component';
import { BlogReactionOverviewTableComponent } from '@app/modules/blog-reaction/components/blog-reaction-overview-table/blog-reaction-overview-table.component';
import { BlogReactionEffects } from '@app/modules/blog-reaction/effects/blog-reaction.effects';
import { BlogReactionDetailComponent } from '@app/modules/blog-reaction/pages/detail/blog-reaction-detail.component';
import { BlogReactionOverviewComponent } from '@app/modules/blog-reaction/pages/overview/blog-reaction-overview.component';
import {
  blogreactionFeatureKey,
  reducers,
} from '@app/modules/blog-reaction/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
  BlogReactionOverviewComponent,
  BlogReactionDetailComponent,
  BlogReactionOverviewTableComponent,
  BlogReactionDetailFormComponent,
];

const IMPORTS = [
  SharedModule,
  BlogReactionRoutingModule,
  NzDividerModule,
  StoreModule.forFeature(blogreactionFeatureKey, reducers),
  EffectsModule.forFeature([BlogReactionEffects]),
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [IMPORTS],
  providers: [],
})
export class BlogReactionsModule {}
