import { ErrorResponse } from '@app/core/models/error-response.model';
import {
  BlogReactionCreateModel,
  BlogReactionTableItemModel,
  BlogReactionUpdateModel,
} from '@app/modules/blog-reaction/models';
import { TableDataModel } from '@app/shared/models';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { createAction, props } from '@ngrx/store';

export const GetOverview = createAction('[BlogReaction] GetOverview');
export const GetOverviewError = createAction(
  '[BlogReaction] GetOverviewError',
  props<{ response: ErrorResponse }>()
);
export const GetOverviewSuccess = createAction(
  '[BlogReaction] GetOverviewSuccess',
  props<TableDataModel<BlogReactionTableItemModel>>()
);

export const SetFilter = createAction(
  '[BlogReaction] SetFilter',
  props<{ filter: TableFilterModel[] }>()
);
export const FilterOverview = createAction(
  '[BlogReaction] FilterOverview',
  props<{
    sorting?: TableSortingModel;
    pagination: { page: number; pageSize: number };
    blogId: number;
    filter?: TableFilterModel[];
  }>()
);

export const Clear = createAction('[BlogReaction] Clear');

export const Get = createAction('[BlogReaction] Get', props<{ id: number }>());
export const GetError = createAction(
  '[BlogReaction] GetError',
  props<{ response: ErrorResponse }>()
);
export const GetSuccess = createAction(
  '[BlogReaction] GetSuccess',
  props<BlogReactionUpdateModel>()
);

export const Update = createAction(
  '[BlogReaction] Update',
  props<BlogReactionUpdateModel>()
);
export const UpdateError = createAction(
  '[BlogReaction] UpdateError',
  props<{ response: ErrorResponse }>()
);
export const UpdateSuccess = createAction(
  '[BlogReaction] UpdateSuccess',
  props<BlogReactionUpdateModel>()
);

export const Create = createAction(
  '[BlogReaction] Create',
  props<BlogReactionCreateModel>()
);
export const CreateError = createAction(
  '[BlogReaction] CreateError',
  props<{ response: ErrorResponse }>()
);
export const CreateSuccess = createAction(
  '[BlogReaction] CreateSuccess',
  props<BlogReactionUpdateModel>()
);

export const Delete = createAction(
  '[BlogReaction] Delete',
  props<{ id: number }>()
);
export const DeleteError = createAction(
  '[BlogReaction] DeleteError',
  props<{ response: ErrorResponse }>()
);
export const DeleteSuccess = createAction('[BlogReaction] DeleteSuccess');

export const DeleteFromDetailPage = createAction(
  '[BlogReaction] DeleteFromDetailPage',
  props<{ id: number, parentReactionId: number; }>()
);
export const DeleteFromDetailPageError = createAction(
  '[BlogReaction] DeleteFromDetailPageError',
  props<{ response: ErrorResponse }>()
);
export const DeleteFromDetailPageSuccess = createAction('[BlogReaction] DeleteFromDetailPageSuccess', props<{ id: number }>());

export const UpdateShowOnline = createAction(
  '[BlogReaction] UpdateShowOnline',
  props<{ id: number; showOnline: boolean }>()
);
export const UpdateShowOnlineError = createAction(
  '[BlogReaction] UpdateShowOnlineError',
  props<{ response: ErrorResponse }>()
);
export const UpdateShowOnlineSuccess = createAction(
  '[BlogReaction] UpdateShowOnlineSuccess',
  props<BlogReactionUpdateModel>()
);

export const UpdateShowOnlineFromDetailPage = createAction(
  '[BlogReaction] UpdateShowOnlineFromDetailPage',
  props<{ id: number; showOnline: boolean }>()
);
export const UpdateShowOnlineFromDetailPageError = createAction(
  '[BlogReaction] UpdateShowOnlineFromDetailPageError',
  props<{ response: ErrorResponse }>()
);
export const UpdateShowOnlineFromDetailPageSuccess = createAction(
  '[BlogReaction] UpdateShowOnlineFromDetailPageSuccess',
  props<BlogReactionUpdateModel>()
);
