import { Component } from '@angular/core';
import * as Page from "@app/modules/page/reducers";
import {Store} from "@ngrx/store";
import * as PageApiActions from "@app/modules/page/actions/page-api.actions";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import {keyframes} from "@angular/animations";

@Component({
    selector: 'app-page-overview',
    templateUrl: './page-overview.component.html',
    styleUrls: ['./page-overview.component.less'],
})
export class PageOverviewComponent {
  tableData$ = this.store.select(Page.selectPageOverviewState);

  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;

  constructor(private readonly store: Store) { }

  /**
   * Trigger the loading of data whenever the table changes.
   * The default pageSize, index and sort options are based on the TableStateModel.
   * @param params
   */
  onQueryParamsChange(params: NzTableQueryParams) {
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort?.key) ?? null;
    const sortOrder = (currentSort?.value) ?? null;

    this.store.dispatch(PageApiActions.FilterOverview({
      pagination: {
        page: pageIndex,
        pageSize
      },
      sorting: {
        propertyName: sortField ?? '',
        order: sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : ''
      }
    }));
  }

  onResetOverview() {
    this.store.dispatch(PageApiActions.ResetOverview());
  }

  onChangeVisible(event:any) {
    this.store.dispatch(PageApiActions.ChangeVisibility( {
      id: event.id,
      isVisible: event.isVisible
    }));
  }

  onDelete($event: number) {
    this.store.dispatch(PageApiActions.Delete({ id: $event }));
  }
}
