import { Component } from '@angular/core';
import * as BlogCategory from '@app/modules/blog-category/reducers'
import { Store } from '@ngrx/store';
import * as BlogCategoryApiActions from '@app/modules/blog-category/actions/blog-category-api.actions';
import { State } from '@app/reducers';
import {
  BlogCategoryCreateModel,
  BlogCategoryUpdateModel
} from '@app/modules/blog-category/models';

@Component({
    selector: 'app-blog-category-detail',
    templateUrl: './blog-category-detail.component.html',
    styleUrls: ['./blog-category-detail.component.less'],
})
export class BlogCategoryDetailComponent {
    loading$ = this.store.select(BlogCategory.selectDetailLoading);
    errors$ = this.store.select(BlogCategory.selectDetailErrors);
    blogCategory$ = this.store.select(BlogCategory.selectDetailBlogCategory);

    constructor(private readonly store: Store<State>) { }

    submit(blogCategory: BlogCategoryCreateModel | BlogCategoryUpdateModel) {
        this.store.dispatch(blogCategory.hasOwnProperty('id')
            ? BlogCategoryApiActions.Update(blogCategory as BlogCategoryUpdateModel)
            : BlogCategoryApiActions.Create(blogCategory)
        );
    }
}
