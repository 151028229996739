import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FAQCategoryChangeSequenceModel } from '@app/modules/faq-categories/models/faq-category-change-sequence.model';
import { FAQCategoryTableItemModel } from '@app/modules/faq-categories/models/faq-category-table.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';

@Component({
  selector: 'app-faq-category-overview-table',
  templateUrl: './faq-category-overview-table.component.html'
})
export class FAQCategoryOverviewTableComponent implements OnInit {
  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;

  @Input({ required: true }) data!: TableStateModel<FAQCategoryTableItemModel>;

	@Output() delete = new EventEmitter<number>();
	@Output() onQueryParamsChange = new EventEmitter<any>();
	@Output() changeSequence = new EventEmitter<FAQCategoryChangeSequenceModel>();
	@Output() onResetOverview = new EventEmitter<void>();

	draggingEnabled = false;
	readonly?: boolean;

  constructor(private readonly claimService: ClaimService) {}

	async ngOnInit(){
		if(!await this.claimService.hasAny([{ claimType: ClaimTypes.FAQCategory, claimValues: [ClaimValues.Update] }])) {
            this.readonly = true;
        } else {
            this.readonly = false;
        }
	}

	drop(event: CdkDragDrop<string[]>): void {
		this.changeSequence.emit({
			id: event.item.data.id,
			newSequenceNumber: event.currentIndex,
		});
	}

	draggingChanged() {
        if(this.draggingEnabled) {
            this.onQueryParamsChange.emit({
                pageIndex: 1,
                pageSize: -1,
                sort: [{key: 'sequenceNumber', value: 'ascend'}],
            });
        }
        else {
            this.onResetOverview.emit();
        }
    }
}
