<div>
    <div *nzModalTitle>
        {{ 'activity.modal.title' | translate }}
    </div>

    {{ 'activity.modal.template-choose' | translate }}
    <nz-select [(ngModel)]="this.chosenTemplate" style="width: 100%">
        <nz-option
            [nzValue]="-1"
            nzLabel="{{ 'activity.modal.use-no-template' | translate }}"></nz-option>
        <nz-option
            *ngFor="let category of this.templateNames$ | async"
            [nzLabel]="category.value"
            [nzValue]="category.key"></nz-option>
    </nz-select>

    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="destroyModal()">{{ 'common.cancel' | translate }}</button>
        <button nz-button nzType="primary" (click)="selectTemplate()">{{ 'common.import' | translate }}</button>
    </div>
</div>
