import { NgModule } from '@angular/core';
import { MobileLoginFormComponent } from '@app/mobile/modules/auth/components/mobile-login-form/mobile-login-form.component';
import { MobileAuthRoutingModule } from '@app/mobile/modules/auth/mobile-auth-routing.module';
import { MobileLoginPageComponent } from '@app/mobile/modules/auth/pages/login/mobile-login-page.component';
import { AuthEffects } from '@app/modules/auth/effects/auth.effects';
import { authFeatureKey, reducers } from '@app/modules/auth/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { CodeInputModule } from 'angular-code-input';

const COMPONENTS = [
	MobileLoginPageComponent,
	MobileLoginFormComponent
];

const IMPORTS = [
	SharedModule,
	MobileAuthRoutingModule,
	NzFormModule,
	NzCheckboxModule,
	CodeInputModule,
	StoreModule.forFeature(authFeatureKey, reducers),
	EffectsModule.forFeature([AuthEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class MobileAuthModule {}
