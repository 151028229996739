import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as ActivityOverview from './activities-overview.reducer'
import * as ActivityDetailPage from './activities-detail-page.reducer'

export const activityFeatureKey = 'activity';

export interface ActivityState {
    activityOverview: ActivityOverview.State,
    activityDetail: ActivityDetailPage.State,
}

export interface State {
    activity: ActivityState
}

const map: ActionReducerMap<ActivityState, Action> = {
    activityOverview: ActivityOverview.reducer,
    activityDetail: ActivityDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: ActivityState, action: Action) => combineReducers(map)(state, action);

const selectActivityState = createFeatureSelector<ActivityState>(activityFeatureKey);

export const selectState = createSelector(selectActivityState, state => state)
export const selectActivityOverviewState = createSelector(selectState, state => state.activityOverview)

export const selectActivityOverviewLoading = createSelector(selectActivityOverviewState, ActivityOverview.getLoading)
export const selectActivityOverviewList = createSelector(selectActivityOverviewState, ActivityOverview.getList)
export const selectActivityOverviewPagination = createSelector(selectActivityOverviewState, ActivityOverview.getPagination)
export const selectActivityOverviewSorting = createSelector(selectActivityOverviewState, ActivityOverview.getSorting)

export const selectActivityDetailState = createSelector(selectState, state => state.activityDetail);

export const selectDetailLoading = createSelector(selectActivityDetailState, ActivityDetailPage.getLoading);
export const selectDetailActivity = createSelector(selectActivityDetailState, ActivityDetailPage.getActivity);
export const selectDetailErrors = createSelector(selectActivityDetailState, ActivityDetailPage.getErrors);
export const selectDetailActivityTemplate = createSelector(selectActivityDetailState, ActivityDetailPage.getActivityTemplate);

export const selectActivityOverviewActivityTypeId = createSelector(selectActivityOverviewState, ActivityOverview.getChosenActivityType);

export const selectActivityTemplateActivityTypes = createSelector(selectActivityDetailState, ActivityDetailPage.getActivityTypes);
export const selectChosenActivityType = createSelector(selectActivityOverviewState, ActivityOverview.getChosenActivityType);
export const selectDisplayOld = createSelector(selectActivityOverviewState, ActivityOverview.getDisplayOldActivities);

export const selectActivityTemplates = createSelector(selectActivityOverviewState, ActivityOverview.getActivityTemplates)
export const selectActivityLocations = createSelector(selectActivityDetailState, ActivityDetailPage.getActivityLocations)
export const selectActivityCode = createSelector(selectActivityDetailState, ActivityDetailPage.getCode)