import { ErrorResponse } from "@app/core/models/error-response.model";
import { NewsTableItemModel, NewsUpdateModel, NewsChangeSequenceModel, NewsCreateModel } from "@app/modules/news/models";
import { NewsChangeShowOnlineModel } from "@app/modules/news/models/news-change-online.model";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[News] GetOverview");
export const GetOverviewError = createAction("[News] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[News] GetOverviewSuccess", props<TableDataModel<NewsTableItemModel>>());
export const ResetOverview = createAction("[News] ResetOverview");

export const FilterOverview = createAction("[News] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[News] Clear");

export const Get = createAction("[News] Get", props<{ id: number }>());
export const GetError = createAction("[News] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[News] GetSuccess", props<NewsUpdateModel>());

export const Update = createAction("[News] Update", props<NewsUpdateModel>());
export const UpdateError = createAction("[News] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[News] UpdateSuccess", props<NewsUpdateModel>());

export const ChangeSequence = createAction("[News] ChangeSequence", props<NewsChangeSequenceModel>());
export const ChangeSequenceError = createAction("[News] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[News] ChangeSequenceSuccess");

export const Create = createAction("[News] Create", props<NewsCreateModel>());
export const CreateError = createAction("[News] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[News] CreateSuccess", props<NewsUpdateModel>());

export const Delete = createAction("[News] Delete", props<{ id: number }>());
export const DeleteError = createAction("[News] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[News] DeleteSuccess");

export const UpdateShowOnline = createAction("[News] Update ShowOnline", props<NewsChangeShowOnlineModel>());
export const UpdateShowOnlineError = createAction("[News] UpdateShowOnlineError", props<{ response: ErrorResponse }>());
export const UpdateShowOnlineSuccess = createAction("[News] UpdateShowOnlineSuccess", props<NewsChangeShowOnlineModel>());