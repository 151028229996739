import * as ActivityLocationActions from "@app/modules/activity-location/actions/activity-location-api.actions"
import { ActivityLocationUpdateModel } from "@app/modules/activity-location/models";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    activityLocation: null,
    loading: false,
    errors: {}
}

export interface State {
    activityLocation: ActivityLocationUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(ActivityLocationActions.Clear, () => ({ ...initialState })),

    on(ActivityLocationActions.Get, (state) => ({ ...state, loading: true })),
    on(ActivityLocationActions.GetSuccess, (state, activityLocation) => ({ ...state, activityLocation, loading: false })),
    on(ActivityLocationActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityLocationActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityLocationActions.UpdateSuccess, (state, activityLocation) => ({ ...state, activityLocation, loading: false })),
    on(ActivityLocationActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityLocationActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityLocationActions.CreateSuccess, (state, activityLocation) => ({ ...state, activityLocation, loading: false })),
    on(ActivityLocationActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getActivityLocation = (state: State) => state.activityLocation;