<nz-table
  *ngIf="data"
  #overviewTable
  [nzData]="data.list"
  [nzLoading]="data.loading"
  [nzShowPagination]="false"
  (nzQueryParams)="queryParamsChange.emit($event)"
  nzTableLayout="fixed"
>
  <thead>
    <tr>
      <th
        nzWidth="70px"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.BlogReactions,
            claimValues: [ClaimValues.Update]
          }
        ]"
        nzColumnKey="showOnline"
      >
        {{ "blog-reactions.fields.show-online" | translate }}
      </th>
      <th nzColumnKey="createdOn">
        {{ "common.created-on" | translate }}
      </th>
      <th nzColumnKey="user.fullName">
        {{ "common.name" | translate }}
      </th>
      <th nzColumnKey="emailAddress">
        {{ "blog-reactions.fields.email-address" | translate }}
      </th>
      <th nzColumnKey="reactionText">
        {{ "blog-reactions.fields.reaction" | translate }}
      </th>
      <th
        nzColumnKey="numberReactions"
        nzWidth="80px"
        *ngIf="!subReactions"
      ></th>
      <th
        nzWidth="120px"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.BlogReactions,
            claimValues: [ClaimValues.Read, ClaimValues.Delete]
          }
        ]"
      ></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of overviewTable.data" [class.child]="row.parentReactionId">
      <td
        nzAlign="center"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.BlogReactions,
            claimValues: [ClaimValues.Update]
          }
        ]"
      >
        <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
        <label
          nz-checkbox
          [nzChecked]="row.showOnline"
          (nzCheckedChange)="changeOnline(row.id, !row.showOnline)"
        ></label>
      </td>
      <td>{{ row.createdOn | date }}</td>
      <td>{{ row.name }}</td>
      <td>{{ row.emailAddress }}</td>
      <td>
        <div [innerHTML]="row.reaction | sanitizeHtml: 200"></div>
      </td>
      <td nzAlign="center" *ngIf="!subReactions">
        <i class="fa-solid fa-comments number-comments" *ngIf="!row.parentReactionId"></i
        >{{
          !row.parentReactionId ? row.subReactionAmount : ""
        }}
      </td>
      <td
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.BlogReactions,
            claimValues: [ClaimValues.Read, ClaimValues.Delete]
          }
        ]"
      >
        <div class="table-actions">
          <a
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.BlogReactions,
                claimValues: [ClaimValues.Read]
              }
            ]"
            [routerLink]="[
              '/blogs',
              blogId,
              'blog-reacties',
              'bewerken',
              row.id
            ]"
            nz-button
            nzType="text"
            nzShape="circle"
          >
            <i class="fa-regular fa-pen"></i>
          </a>
          <button
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.BlogReactions,
                claimValues: [ClaimValues.Delete]
              }
            ]"
            nz-button
            nzDanger
            nzType="text"
            nzShape="circle"
            nz-popconfirm
            nzOkDanger
            [nzPopconfirmTitle]="'notification.delete' | translate"
            [nzOkText]="'common.delete' | translate"
            [nzCancelText]="'common.cancel' | translate"
            [nzIcon]="templateIconDelete"
            nzPopconfirmPlacement="left"
            (nzOnConfirm)="delete.emit(row.id)"
          >
            <i class="fa-regular fa-trash-can"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #templateIconDelete>
  <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
