import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TruncateTextPipe } from '@app/shared/pipes/truncate-text.pipe';

@Pipe({ name: 'sanitizeHtml' })
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(rawText: string, maxLength: null | number = null): SafeHtml {
    let text = rawText.replace(/(<([^>]+)>)/gi, '');
    if (maxLength) {
      text = new TruncateTextPipe().transform(text, maxLength);
    }
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
