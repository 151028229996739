import { ActivityRelationTableItemModel } from '@app/modules/activity-relation/models/activity-relation-table.model';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';
import * as ActivityRelationApiActions from '@app/modules/activity-relation/actions/activity-relation-api.actions';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { ActivityRelationUpdateModel } from '@app/modules/activity-relation/models/activity-relation-update.model';

const initialState: State = {
    relations: new TableOverviewModel<ActivityRelationTableItemModel>('Role'),
    waitlistRelations: new TableOverviewModel<ActivityRelationTableItemModel>('Role'),
    activityId: 0,
    backgroundList: [],
    activityRelation: {} as ActivityRelationUpdateModel,
};

export interface State {
    relations: TableStateModel<ActivityRelationTableItemModel>;
    waitlistRelations: TableStateModel<ActivityRelationTableItemModel>;
    activityId: number;
    backgroundList: KeyValueModel[];
    activityRelation: ActivityRelationUpdateModel;
}

export const reducer = createReducer(
    initialState,

    // normal overview
    on(ActivityRelationApiActions.GetOverview, (state) => ({
        ...state,
        relations: { ...state.relations, loading: true },
    })),
    on(ActivityRelationApiActions.GetOverviewSuccess, (state, response) => ({
        ...state,
        relations: {
            ...state.relations,
            ...response,
            loading: false,
        },
    })),
    on(ActivityRelationApiActions.GetOverviewError, (state) => ({
        ...state,
        relations: { ...state.relations, loading: false },
    })),

    on(ActivityRelationApiActions.FilterOverview, (state, { activityId, sorting, pagination, thenBy, filter }) => ({
        ...state,
        activityId,
        relations: {
            ...state.relations,
            sorting: applySortingOrDefault(initialState.relations, sorting),
            pagination: {
                ...state.relations.pagination,
                page: pagination.page,
                pageSize: pagination.pageSize,
            },
            filter,
            thenBy,
        },
    })),

    // create

    on(ActivityRelationApiActions.Create, (state) => ({ ...state, loading: true, errors: {} })),

    on(ActivityRelationApiActions.CreateSuccess, (state) => ({
        ...state,
        loading: false,
    })),

    on(ActivityRelationApiActions.CreateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    // update

    on(ActivityRelationApiActions.Update, (state) => ({ ...state, loading: true, errors: {} })),

    on(ActivityRelationApiActions.UpdateSuccess, (state) => ({
        ...state,
        loading: false,
    })),

    on(ActivityRelationApiActions.UpdateError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    // waitlist overview
    on(ActivityRelationApiActions.GetWaitlistOverview, (state) => ({
        ...state,
        waitlistRelations: { ...state.waitlistRelations, loading: true },
    })),
    on(ActivityRelationApiActions.GetWaitlistOverviewSuccess, (state, response) => ({
        ...state,
        waitlistRelations: {
            ...state.waitlistRelations,
            ...response,
            loading: false,
        },
    })),
    on(ActivityRelationApiActions.GetWaitlistOverviewError, (state) => ({
        ...state,
        waitlistRelations: { ...state.waitlistRelations, loading: false },
    })),

    on(
        ActivityRelationApiActions.FilterWaitlistOverview,
        (state, { activityId, sorting, pagination, thenBy, filter }) => ({
            ...state,
            activityId,
            waitlistRelations: {
                ...state.waitlistRelations,
                sorting: applySortingOrDefault(initialState.waitlistRelations, sorting),
                pagination: {
                    ...state.waitlistRelations.pagination,
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                },
                filter,
                thenBy,
            },
        }),
    ),

    // activityRelation get

    on(ActivityRelationApiActions.GetActivityRelation, (state) => ({ ...state, loading: true })),
    on(ActivityRelationApiActions.GetActivityRelationSuccess, (state, activityRelation) => ({
        ...state,
        activityRelation: activityRelation.response,
        loading: false,
    })),
    on(ActivityRelationApiActions.GetActivityRelationError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),

    // backgrounds

    on(ActivityRelationApiActions.GetBackgroundListSuccess, (state, response) => ({
        ...state,
        backgroundList: response.response,
    })),

    // general
    on(ActivityRelationApiActions.ResetOverview, () => ({ ...initialState })),
);

export const getLoading = (state: State) => state.relations.loading;
export const getList = (state: State) => state.relations.list;
export const getPagination = (state: State) => state.relations.pagination;
export const getSorting = (state: State) => state.relations.sorting;
export const getActivityId = (state: State) => state.activityId;
export const getSortingThen = (state: State) => state.relations.thenBy;
export const getFilter = (state: State) => state.relations.filter;

export const getWaitlistList = (state: State) => state.waitlistRelations.list;
export const getWaitlistPagination = (state: State) => state.waitlistRelations.pagination;
export const getWaitlistSorting = (state: State) => state.waitlistRelations.sorting;
export const getWaitlistSortingThen = (state: State) => state.waitlistRelations.thenBy;
export const getWaitlistFilter = (state: State) => state.waitlistRelations.filter;

export const getBackgroundList = (state: State) => state.backgroundList;

export const getActivityRelation = (state: State) => state.activityRelation;
