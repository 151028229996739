import { Component } from '@angular/core';
import * as BlogCategory from '@app/modules/blog-category/reducers';
import { Store } from '@ngrx/store';
import * as BlogCategoryApiActions from '@app/modules/blog-category/actions/blog-category-api.actions';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BlogCategoryChangeSequenceModel } from '@app/modules/blog-category/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
    selector: 'app-blog-category-overview',
    templateUrl: './blog-category-overview.component.html',
    styleUrls: ['./blog-category-overview.component.less']
})
export class BlogCategoryOverviewComponent {
    tableData$ = this.store.select(BlogCategory.selectBlogCategoryOverviewState);

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    constructor(private readonly store: Store) { }

    onDeleteConfirm(id: number): void {
        this.store.dispatch(BlogCategoryApiActions.Delete({ id: id }))
    }

    /**
     * Trigger the loading of data whenever the table changes.
     * The default pageSize, index and sort options are based on the TableStateModel.
     * @param params
     */
    onQueryParamsChange(params: NzTableQueryParams) {
        const { pageSize, pageIndex, sort, filter } = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort?.key) ?? null;
        const sortOrder = (currentSort?.value) ?? null;

        this.store.dispatch(BlogCategoryApiActions.FilterOverview({
            pagination: {
                page: pageIndex,
                pageSize
            },
            sorting: {
                propertyName: sortField ?? '',
                order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : ''
            }
        }));
    }

    onResetOverview() {
        this.store.dispatch(BlogCategoryApiActions.ResetOverview());
    }

    onSequenceChange(request: BlogCategoryChangeSequenceModel) {
        this.store.dispatch(BlogCategoryApiActions.ChangeSequence(request))
    }
}
