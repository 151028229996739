<div class="page-header">
  <h1 class="page-header__title">{{ "blog.title" | translate }}</h1>
  <div class="page-header__actions">
    <a
      claimDirective
      [claims]="[
        {
          claimType: ClaimTypes.Blog,
          claimValues: [ClaimValues.Create]
        }
      ]"
      routerLink="/blogs/toevoegen"
      nz-button
      nzType="primary"
    >
      {{ "common.add" | translate }}
      <i class="fa-regular fa-plus"></i>
    </a>
  </div>
</div>

<app-blog-overview-table
  [data]="(this.tableData$ | async)!"
  (delete)="onDeleteConfirm($event)"
  (queryParamsChange)="onQueryParamsChange($event)"
  (onResetOverview)="onResetOverview()"
  (changeShowOnline)="onChangeShowOnline($event)"
/>
