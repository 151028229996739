import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as UserActions from '@app/modules/users/actions/user-api.actions';
import { UserService } from '@app/modules/users/services/user.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const userService = inject(UserService);
	const router = inject(Router);
	const store = inject(Store<State>);

	return userService.get(route.params['id']).pipe(
		map((user) => UserActions.GetSuccess(user)),
		tap((action: any) => store.dispatch(action)),
		map((user) => !!user),
		catchError(() => {
			return router.navigate(['/not-found']);
		})
	);
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);
