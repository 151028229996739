import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as RelationTypeActions from '@app/modules/relation-type/actions/relation-type-api.actions';
import * as RelationType from '@app/modules/relation-type/reducers';
import { RelationTypeService } from '@app/modules/relation-type/services/relation-type.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, exhaustMap, catchError, of, tap } from 'rxjs';

@Injectable()
export class RelationTypeEffects {
  constructor(
    private actions$: Actions,
    private relationTypeService: RelationTypeService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<RelationType.State>
  ) {}

  /**
   * Get effects
   */
  loadRelationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(RelationType.selectRelationTypeOverviewPagination),
        this.store.select(RelationType.selectRelationTypeOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.relationTypeService.getOverview(sorting, pagination).pipe(
          map((response) => RelationTypeActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(
              RelationTypeActions.GetOverviewError({ response: response.error })
            )
          )
        )
      )
    )
  );

  loadRelationTypesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.FilterOverview),
      map(() => RelationTypeActions.GetOverview())
    )
  );

  loadRelationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.Get),
      exhaustMap((action) =>
        this.relationTypeService.get(action.id).pipe(
          map((response) => RelationTypeActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(RelationTypeActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createRelationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.Create),
      exhaustMap((action) =>
        this.relationTypeService.create(action).pipe(
          map((relationType) =>
            RelationTypeActions.CreateSuccess(relationType)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(RelationTypeActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createRelationTypeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RelationTypeActions.CreateSuccess),
        tap((relationType) =>
          this.translate
            .get('notification.add', { item: relationType.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateRelationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.Update),
      exhaustMap((action) =>
        this.relationTypeService.update(action).pipe(
          map((relationType) =>
            RelationTypeActions.UpdateSuccess(relationType)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(RelationTypeActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateRelationTypeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RelationTypeActions.UpdateSuccess),
        tap((relationType) =>
          this.translate
            .get('notification.save', { item: relationType.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateRelationTypeSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RelationTypeActions.CreateSuccess,
          RelationTypeActions.UpdateSuccess
        ),
        tap(() => this.router.navigate(['relatie-typen']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteRelationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.Delete),
      exhaustMap((action) =>
        this.relationTypeService.delete(action.id).pipe(
          map(() => RelationTypeActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(RelationTypeActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteRelationTypeSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.DeleteSuccess),
      map(() => RelationTypeActions.GetOverview())
    )
  );

  deleteRelationTypeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RelationTypeActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change sequence effects
   */
  changeSequenceRelationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.ChangeSequence),
      exhaustMap((action) =>
        this.relationTypeService.changeSequence(action).pipe(
          map(() => RelationTypeActions.ChangeSequenceSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(
              RelationTypeActions.ChangeSequenceError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  changeSequenceSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelationTypeActions.ChangeSequenceSuccess),
      map(() => RelationTypeActions.GetOverview())
    )
  );

  relationTypeErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          RelationTypeActions.DeleteError,
          RelationTypeActions.GetOverviewError,
          RelationTypeActions.ChangeSequenceError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
