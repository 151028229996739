<div>
    <nz-card>
        <div>
            <b>{{"activity.card.activity" | translate}}:&nbsp;</b>
            {{ activity.name }}
        </div>
        <br>
        <div *ngIf="activity.code">
            <b>{{"activity.card.code" | translate}}:&nbsp;</b>
            {{ activity.code }}
        </div>
        <div *ngIf="activity.startDate || activity.endDate">
            <b>{{"activity.card.date" | translate}}:&nbsp;</b>
            {{ (activity.startDate | date: 'longDate') ?? "..." }}&nbsp;{{ "activity.card.through" | translate }}&nbsp;{{ (activity.endDate | date: 'longDate') ?? "..." }}
        </div>
        <div *ngIf="activity.activityLocation?.address || activity.activityLocation?.zipCode || activity.activityLocation?.city">
            <b>{{"activity.card.location" | translate}}:&nbsp;</b>
            {{ activity.activityLocation?.address ?? "..."}},&nbsp;{{ activity.activityLocation?.zipCode ?? "..."}}&nbsp;{{ "activity.card.in" | translate }}&nbsp;{{ activity.activityLocation?.city ?? "..."}}
        </div>
    </nz-card>
</div> 