import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AssetFormGroup } from '@app/shared/models/assetmanager';
import { AssetService } from '@app/shared/services/asset.service';

@Component({
    selector: 'app-asset-overview',
    templateUrl: './asset-overview.component.html',
    styleUrl: './asset-overview.component.less'
})
export class AssetOverviewComponent {
    @Input({ required: true }) isReadOnly!: boolean;
    @Input({ required: true }) formArray!: FormArray<AssetFormGroup>;
    @Input({ required: true }) errors!: { [key: string]: string[] };
    @Input({ required: true }) fieldName!: string;
    @Input() required: boolean = false;
    @Input() maxAssets: number = 0;

    @Output() formArrayChange = new EventEmitter<FormArray>();

    constructor(private assetService: AssetService) { }

    protected addAsset(): void {
        this.assetService.chooseFile(false, (files) => {
            files.forEach(file => {
                this.formArray.push(new AssetFormGroup({ url: file.url, name: file.name }));
            })

            this.formArray.updateValueAndValidity();
            this.formArray.markAsDirty();
            this.formArrayChange.emit(this.formArray);
        });
    }

    protected removeAsset(i: number): void {
        this.formArray.removeAt(i);
        this.formArray.updateValueAndValidity();
        this.formArray.markAsDirty();
        this.formArrayChange.emit(this.formArray);
    }
}