import { NgModule } from '@angular/core';
import { LoginFormComponent } from '@app/modules/auth/components/login-form/login-form.component';
import { PasswordForgetFormComponent } from '@app/modules/auth/components/password-forget-form/password-forget-form.component';
import { PasswordResetFormComponent } from '@app/modules/auth/components/password-reset-form/password-reset-form.component';
import { AuthEffects } from '@app/modules/auth/effects/auth.effects';
import { PasswordForgetPageComponent } from '@app/modules/auth/pages/password-forget/password-forget-page.component';
import { PasswordResetPageComponent } from '@app/modules/auth/pages/password-reset/password-reset-page.component';
import { authFeatureKey, reducers } from '@app/modules/auth/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzFormModule } from 'ng-zorro-antd/form';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginPageComponent } from './pages/login/login-page.component';

const COMPONENTS = [
	LoginPageComponent,
	LoginFormComponent,
	PasswordForgetPageComponent,
	PasswordForgetFormComponent,
	PasswordResetPageComponent,
	PasswordResetFormComponent,
	PasswordResetFormComponent,
];

const IMPORTS = [
	SharedModule,
	AuthRoutingModule,
	NzFormModule,
	StoreModule.forFeature(authFeatureKey, reducers),
	EffectsModule.forFeature([AuthEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class AuthModule {}
