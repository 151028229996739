import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
  MaterialCreateModel,
  MaterialTableItemModel,
  MaterialUpdateModel,
} from '@app/modules/materials/models';
import { TableDataModel } from '@app/shared/models';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  private readonly endpoints = {
    get: 'Material/:id',
    overview: 'Material/GetOverview',
    update: 'Material/Update',
    create: 'Material/Create',
    quotation: 'Material/InQuotation',
    delete: 'Material/Delete?id=:id',
    changeSequence: 'Material/ChangeSequence/',
  };

  constructor(private readonly httpService: HttpService) {}

  getAll(
    sorting: TableSortingModel,
    pagination: TablePaginationModel
  ): Observable<TableDataModel<MaterialTableItemModel>> {
    const paginationParams = parse(pagination, 'pagination');
    const sortingParams = parse(sorting, 'sorting');

    return this.httpService.get<any>(
      this.endpoints.overview + `?${paginationParams}&${sortingParams}`
    );
  }

  get(id: number): Observable<MaterialUpdateModel> {
    return this.httpService.get<any>(
      this.endpoints.get.replace(':id', id.toString())
    );
  }

  update(request: MaterialUpdateModel): Observable<MaterialUpdateModel> {
    return this.httpService.put<any>(this.endpoints.update, request);
  }

  quotation(id: number, inQuotation: boolean): Observable<any> {
    return this.httpService.put<any>(this.endpoints.quotation, {
      id,
      inQuotation,
    });
  }

  create(request: MaterialCreateModel): Observable<MaterialUpdateModel> {
    return this.httpService.post<any>(this.endpoints.create, request);
  }

  delete(id: number): Observable<any> {
    return this.httpService.delete<any>(
      this.endpoints.delete.replace(':id', id.toString())
    );
  }
}
