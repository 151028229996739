import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.less'],
})
export class ForbiddenComponent {
  constructor(private readonly location: Location) {}

  navigateBack(): void {
    this.location.back();
  }
}
