<div class="auth-container">
	<div class="auth-wrapper">
		<figure class="auth-logo">
			<img src="./assets/images/logo-ivsw.svg" alt="Logo IVSW" />
		</figure>
		<app-password-reset-form
			[loading]="loading$ | async"
			[errors]="errors$ | async"
			[success]="success$ | async"
			(resetPassword)="resetPassword($event)"></app-password-reset-form>
	</div>
</div>
