import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RelationTypeUpdateModel, RelationTypeCreateModel } from '@app/modules/relation-type/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
    selector: 'app-relation-type-detail-form',
    templateUrl: './relation-type-detail-form.component.html',
})
export class RelationTypeDetailFormComponent implements OnInit {
    update = false;
    readonly?: boolean;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) relationType: RelationTypeUpdateModel | null = null;

    @Output() submitted = new EventEmitter<RelationTypeCreateModel | RelationTypeUpdateModel>();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        name: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        description: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(200)]),
    });

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.relationType;
        
        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.RelationType, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        this.formGroup.patchValue({
            name: this.relationType?.name,
            description: this.relationType?.description,
        });

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.relationType as RelationTypeUpdateModel).id);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            name: this.formGroup.controls.name.value ?? '',
            description: this.formGroup.controls.description.value ?? ''
        });
    }

    private submitUpdate() {
        this.submitted.emit(this.formGroup.value as RelationTypeUpdateModel);
    }
}