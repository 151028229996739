import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginRequest } from '@app/modules/auth/models/login-request.model';
import { Constants } from '@app/shared/models/common/constants';
import { processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
	selector: 'app-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.less'],
})
export class LoginFormComponent implements OnChanges {
	@Input() loading: boolean | null = false;
	@Input() errors: { [key: string]: string[] } | null = {};

	@Output() submitted = new EventEmitter<LoginRequest>();

	passwordShow = false;

	public form = new FormGroup({
		email: new FormControl<string | null>(null, [
			CustomValidators.required,
			Validators.pattern(Constants.EmailExpression),
		]),
		password: new FormControl<string | null>(null, [CustomValidators.required])
	});

	ngOnChanges(changes: SimpleChanges) {
		processBackendValidators(changes, this.form);
	}

	passwordToggle(event: Event) {
		event.preventDefault();
		this.passwordShow = !this.passwordShow;
	}

	onLogin() {
		this.submitted.emit({
			email: this.form.controls.email.value!,
			password: this.form.controls.password.value!,
		});
	}
}
