import { Component } from '@angular/core';

@Component({
	selector: 'app-mobile-login-page',
	templateUrl: './mobile-login-page.component.html',
	styleUrls: ['./mobile-login-page.component.less'],
})
export class MobileLoginPageComponent {
	
}
