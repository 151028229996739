import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as DiscountCodeApiActions from '@app/modules/discount-codes/actions/discount-code-api.actions';
import { DiscountCodeService } from '@app/modules/discount-codes/services/discount-code.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const discountCodeService = inject(DiscountCodeService);
	const router = inject(Router);
	const store = inject(Store<State>);

	return discountCodeService.get(route.params['id']).pipe(
		map((discountCode) => DiscountCodeApiActions.GetSuccess(discountCode)),
		tap((action: any) => store.dispatch(action)),
		map((discountCode) => !!discountCode),
		catchError(() => {
			return router.navigate(['/not-found']);
		})
	);
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);
