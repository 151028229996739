
<form nz-form [formGroup]="formGroup">
    <section class="form-section">
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="24">
                        {{ 'relation.fields.relation-type' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="relationTypeValidationTemplate">
                        <nz-select
                            class="w-100"
                            nzPlaceHolder="{{ 'relation.placeholder.relation-type' | translate }}"
                            formControlName="relationTypeId">
                            <nz-option
                                *ngFor="let relationType of relationTypes"
                                [nzLabel]="relationType.value"
                                [nzValue]="relationType.key"></nz-option>
                        </nz-select>
                        <ng-template #relationTypeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'relationTypeId'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </section>
    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'relation.header.personal-information' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="24">
                        {{ 'relation.fields.salutation' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="salutationValidationTemplate">
                        <nz-select
                            class="w-100"
                            nzPlaceHolder="{{ 'relation.placeholder.relation-type' | translate }}"
                            formControlName="salutation">
                            <nz-option
                                *ngFor="let salutationType of salutationTypes"
                                [nzLabel]="'common.enums.salutation.' + salutationType.key | translate"
                                [nzValue]="salutationType.key"></nz-option>
                        </nz-select>
                        <ng-template #salutationValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'salutation'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <div nz-row class="justify-space-between">
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSpan]="24">
                                {{ 'relation.fields.first-name' | translate }}
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="firstNameValidationTemplate">
                                <input nz-input [formControl]="formGroup.controls.firstName" />
        
                                <ng-template #firstNameValidationTemplate let-control>
                                    <app-input-validation
                                        [control]="control"
                                        [field]="'firstName'"
                                        [errors]="errors"></app-input-validation>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="7">
                        <nz-form-item>
                            <nz-form-label [nzSpan]="24">
                                {{ 'relation.fields.middle-name' | translate }}
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="middleNameValidationTemplate">
                                <input nz-input [formControl]="formGroup.controls.middleName" />
        
                                <ng-template #middleNameValidationTemplate let-control>
                                    <app-input-validation
                                        [control]="control"
                                        [field]="'middleName'"
                                        [errors]="errors"></app-input-validation>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzRequired [nzSpan]="24">
                                {{ 'relation.fields.last-name' | translate }}
                            </nz-form-label>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="lastNameValidationTemplate">
                                <input nz-input [formControl]="formGroup.controls.lastName" />
        
                                <ng-template #lastNameValidationTemplate let-control>
                                    <app-input-validation
                                        [control]="control"
                                        [field]="'lastName'"
                                        [errors]="errors"></app-input-validation>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'relation.header.address-information' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="24">
                        {{ 'relation.fields.country' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="countryValidationTemplate">
                        <nz-select
                            class="w-100"
                            nzPlaceHolder="{{ 'relation.placeholder.country' | translate }}"
                            formControlName="countryId">
                            <nz-option
                                *ngFor="let country of countries"
                                [nzLabel]="country.name"
                                [nzValue]="country.id"></nz-option>
                        </nz-select>
                        <ng-template #countryValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'countryId'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.zip-code" | translate }}</nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="zipCodeValidationTemplate">
                        <input nz-input [formControl]="formGroup.controls.zipCode"/>
            
                        <ng-template #zipCodeValidationTemplate let-control>
                            <app-input-validation [control]="control" [field]="'zipCode'" [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.city" | translate }}</nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="cityValidationTemplate">
                        <input nz-input [formControl]="formGroup.controls.city"/>
            
                        <ng-template #cityValidationTemplate let-control>
                            <app-input-validation [control]="control" [field]="'city'" [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzRequired>{{ "relation.fields.address" | translate }}</nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="addressValidationTemplate">
                        <input nz-input [formControl]="formGroup.controls.address"/>
            
                        <ng-template #addressValidationTemplate let-control>
                            <app-input-validation [control]="control" [field]="'address'" [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </section>

    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'relation.header.contact-information' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
					<nz-form-label [nzSpan]="24" nzRequired>{{ 'users.fields.email-username' | translate }}</nz-form-label>
					<nz-form-control [nzSpan]="24" [nzErrorTip]="EmailValidationTemplate">
						<input nz-input [formControl]="formGroup.controls.email"/>
		
						<ng-template #EmailValidationTemplate let-control>
							<app-input-validation [control]="control" [field]="'email'" [errors]="errors"></app-input-validation>
						</ng-template>
					</nz-form-control>
				</nz-form-item>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="12">
                <app-phone-number-input [phoneNumberControl]="formGroup.controls.phone1" [phoneCodeControl]="formGroup.controls.phone1Code" 
                    [phoneNumberField]="'phone1'" [phoneCodeField]="'phone1Code'" [label]="'users.fields.phone1' | translate" [required]="true"
                    [errors]="errors" [countries]="countries" />
            </div>
            <div nz-col nzSpan="12">
                <app-phone-number-input [phoneNumberControl]="formGroup.controls.phone2" [phoneCodeControl]="formGroup.controls.phone2Code" 
                    [phoneNumberField]="'phone2'" [phoneCodeField]="'phone2Code'" [label]="'users.fields.phone2' | translate"
                    [errors]="errors" [countries]="countries" />
            </div>
        </div>
    </section>

    <section *ngIf="this.update" class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'relation.header.billing-information' | translate }}
            </h5>
        </header>
        <div nz-row>
            TODO: Factuur gegevens
        </div>
    </section>

    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'relation.header.account-information' | translate }}
            </h5>
        </header>

        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'relation.fields.dietaryPreference' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="dietaryPreferenceValidationTemplate">
                        <textarea nz-input rows="4" [formControl]="formGroup.controls.dietaryPreference"></textarea>

                        <ng-template #dietaryPreferenceValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'dietaryPreference'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <nz-form-item>
            <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
            <label nz-checkbox [nzValue]="relation?.active" formControlName="active">
                {{ 'relation.fields.active' | translate }}
            </label>
        </nz-form-item>

        <nz-form-item>
            <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
            <label nz-checkbox [nzValue]="relation?.isStudent" formControlName="isStudent">
                {{ 'relation.fields.is-student' | translate }}
            </label>
        </nz-form-item>

        <nz-form-item>
            <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
            <label nz-checkbox [nzValue]="relation?.wasStudent" formControlName="wasStudent">
                {{ 'relation.fields.was-student' | translate }}
            </label>
        </nz-form-item>
    </section>

    <div class="button-container">
        <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</form>
