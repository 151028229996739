import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import { ActivityTypeService } from '@app/modules/activity-type/services/activity-type.service';
import * as ReviewActions from '@app/modules/review/actions/review-api.actions';
import * as Review from '@app/modules/review/reducers';
import { ReviewService } from '@app/modules/review/services/review.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, debounceTime, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class ReviewEffects {
  constructor(
    private actions$: Actions,
    private reviewService: ReviewService,
    private messageService: NzMessageService,
    private activityTypeService: ActivityTypeService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<Review.State>
  ) {}

  /**
   * Get effects
   */
  loadReviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(Review.selectReviewOverviewPagination),
        this.store.select(Review.selectReviewOverviewSorting),
        this.store.select(Review.selectReviewOverviewFilter),
        this.store.select(Review.selectActivityTypeIdOverviewList),
      ]),
      exhaustMap(([, pagination, sorting, filter, activityId]) =>
        this.reviewService.getAll(sorting, pagination, filter, activityId).pipe(
          map((response) => ReviewActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(ReviewActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadReviewsFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.FilterOverview),
      debounceTime(200),
      map(() => ReviewActions.GetOverview())
    )
  );

  loadReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.Get),
      exhaustMap((action) =>
        this.reviewService.get(action.id).pipe(
          map((response) => ReviewActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(ReviewActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.Create),
      exhaustMap((action) =>
        this.reviewService.create(action).pipe(
          map((review) => ReviewActions.CreateSuccess(review)),
          catchError((response: { error: ErrorResponse }) =>
            of(ReviewActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createReviewSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.CreateSuccess),
        tap((review) =>
          this.translate
            .get('notification.add', { item: review.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.Update),
      exhaustMap((action) =>
        this.reviewService.update(action).pipe(
          map((review) => ReviewActions.UpdateSuccess(review)),
          catchError((response: { error: ErrorResponse }) =>
            of(ReviewActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateReviewSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.UpdateSuccess),
        tap((review) =>
          this.translate
            .get('notification.save', { item: review.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateReviewSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.CreateSuccess, ReviewActions.UpdateSuccess),
        tap(() => this.router.navigate(['reviews']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.Delete),
      exhaustMap((action) =>
        this.reviewService.delete(action.id).pipe(
          map(() => ReviewActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(ReviewActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteReviewSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.DeleteSuccess),
      map(() => ReviewActions.GetOverview())
    )
  );

  deleteReviewSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change sequence effects
   */
  changeSequenceReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.ChangeSequence),
      exhaustMap((action) =>
        this.reviewService.changeSequence(action).pipe(
          map(() => ReviewActions.ChangeSequenceSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(ReviewActions.ChangeSequenceError({ response: response.error }))
          )
        )
      )
    )
  );

  changeSequenceSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.ChangeSequenceSuccess),
      map(() => ReviewActions.GetOverview())
    )
  );

  loadActivityTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.GetActivityTypes),
      exhaustMap(() =>
        this.activityTypeService.getAll().pipe(
          map((response) =>
            ReviewActions.GetActivityTypesSuccess({ activityTypes: response })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ReviewActions.GetActivityTypesError({ response: response.error })
            )
          )
        )
      )
    )
  );

  /**
   * SetOnline effects
   */
  setOnline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.UpdateOnline),
      exhaustMap((action) =>
        this.reviewService.online(action.id, action.online).pipe(
          map((review) => ReviewActions.UpdateOnlineSuccess(review)),
          catchError((response: { error: ErrorResponse }) =>
            of(ReviewActions.UpdateOnlineError({ response: response.error }))
          )
        )
      )
    )
  );

  setOnlineSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReviewActions.UpdateOnlineSuccess),
      map(() => ReviewActions.GetOverview())
    )
  );

  setOnlineSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReviewActions.UpdateOnlineSuccess),
        tap((review) =>
          this.translate
            .get('notification.save', { item: review.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  reviewErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ReviewActions.DeleteError,
          ReviewActions.GetOverviewError,
          ReviewActions.ChangeSequenceError,
          ReviewActions.GetActivityTypesError,
          ReviewActions.UpdateOnlineError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
