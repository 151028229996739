import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input'

@Component({
    standalone: true,
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.less'],
    imports: [CommonModule, FormsModule, NzSelectModule, NzInputModule]
})
export class Topbar {
    environmentOptions = [
        { value: 0, label: 'Main website' },
        { value: 1, label: 'Bedrijven' },
    ]

    selectedEnvironment = 0;
}