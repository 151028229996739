import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import {
  BlogCategoryCreateModel,
  BlogCategoryUpdateModel
} from "@app/modules/blog-category/models";
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
    selector: 'app-blog-category-detail-form',
    templateUrl: './blog-category-detail-form.component.html',
})
export class BlogCategoryDetailFormComponent implements OnInit {
    update = false;
    readonly?: boolean;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) blogCategory: BlogCategoryUpdateModel | null = null;

    @Output() submitted = new EventEmitter<BlogCategoryCreateModel | BlogCategoryUpdateModel>();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        title: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        slug: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
    });

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.blogCategory;

        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.BlogCategory, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        this.formGroup.patchValue({
            title: this.blogCategory?.title,
            slug: this.blogCategory?.slug,
        });

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.blogCategory as BlogCategoryUpdateModel).id);
            this.formGroup.controls.slug.disable();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            title: this.formGroup.controls.title.value ?? '',
            slug: this.formGroup.controls.slug.value ?? ''
        });
    }

    private submitUpdate() {
        this.submitted.emit(this.formGroup.value as BlogCategoryUpdateModel);
    }
}
