<form nz-form [formGroup]="formGroup">
  <nz-card>
    <h4>
      {{
        (!!formGroup.get("id")?.value
          ? "blog-reactions.detail.edit-title"
          : "blog-reactions.detail.add-title"
        ) | translate
      }}
    </h4>
    <div nz-row>
      <div nz-col nzFlex="auto" class="tab-column">
        <div class="tab-container">
          <nz-tabset nzType="card">
            <nz-tab [nzTitle]="titleTemplate" [nzForceRender]="true">
              <ng-template #titleTemplate>
                <div>
                  {{ "blog-reactions.detail.content" | translate }}
                </div>
              </ng-template>

              <nz-form-item>
                <nz-form-label [nzSpan]="24">{{
                  "blog-reactions.detail.show-online" | translate
                }}</nz-form-label>
                <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                <label
                  nz-checkbox
                  [nzValue]="blogReaction?.showOnline"
                  formControlName="showOnline"
                ></label>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{
                  "common.name" | translate
                }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="nameValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="this.formGroup.controls.name"
                  />
                  <ng-template #nameValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'name'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired>{{
                  "blog-reactions.detail.reaction" | translate
                }}</nz-form-label>
                <nz-form-control [nzSpan]="24">
                  <app-editor
                    *ngIf="readonly !== undefined"
                    [control]="formGroup.controls.reaction"
                    [isDraggable]="false"
                    [isReadOnly]="readonly"
                    [simpleEditor]=true
                  ></app-editor>

                  <app-input-validation
                    [control]="formGroup.controls.reaction"
                    [field]="'reaction'"
                    [errors]="errors"
                  ></app-input-validation>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label [nzSpan]="24" nzRequired="">{{
                  "blog-reactions.detail.email-address" | translate
                }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="emailAddressValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="this.formGroup.controls.emailAddress"
                  />
                  <ng-template #emailAddressValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'emailAddress'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label [nzSpan]="24">{{
                  "blog-reactions.detail.made-by" | translate
                }}</nz-form-label>
                <nz-form-control
                  [nzSpan]="24"
                  [nzErrorTip]="userIdValidationTemplate"
                >
                  <input
                    nz-input
                    [formControl]="this.formGroup.controls.userName"
                  />
                  <ng-template #userIdValidationTemplate let-control>
                    <app-input-validation
                      [control]="control"
                      [field]="'userId'"
                      [errors]="errors"
                    ></app-input-validation>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label [nzSpan]="24">{{
                  "blog-reactions.detail.send-subreaction-notification"
                    | translate
                }}</nz-form-label>
                <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                <label
                  nz-checkbox
                  [nzValue]="blogReaction?.sendSubReactionNotification"
                  formControlName="sendSubreactionNotification"
                ></label>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label [nzSpan]="24">{{
                  "blog-reactions.detail.send-reaction-notification" | translate
                }}</nz-form-label>
                <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                <label
                  nz-checkbox
                  [nzValue]="blogReaction?.sendReactionNotification"
                  formControlName="sendReactionNotification"
                ></label>
              </nz-form-item>
            </nz-tab>

            <nz-tab
              [nzTitle]="titleTemplate"
              [nzForceRender]="true"
              *ngIf="!blogReaction?.parentReactionId && this.update"
            >
              <ng-template #titleTemplate>
                <div>
                  {{ "blog-reactions.detail.sub-reactions" | translate }}
                </div>
              </ng-template>
              <app-blog-reaction-overview-table
                [data]="blogReaction?.subReactions"
                (delete)="onDeleteConfirm($event)"
                (queryParamsChange)="onQueryParamsChange()"
                (changeShowOnline)="onChangeShowOnline($event)"
                [subReactions]="true"
              />
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
        {{ "common.save" | translate }}
      </button>
    </div>
  </nz-card>
</form>
