import {
  Action,
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as BlogReactionDetailPage from './blog-reaction-detail-page.reducer';
import * as BlogReactionOverview from './blog-reaction-overview.reducer';

export const blogreactionFeatureKey = 'blogreaction';

export interface BlogReactionState {
  blogreactionOverview: BlogReactionOverview.State;
  blogreactionDetail: BlogReactionDetailPage.State;
}

export interface State {
  blogreaction: BlogReactionState;
}

const map: ActionReducerMap<BlogReactionState, Action> = {
  blogreactionOverview: BlogReactionOverview.reducer,
  blogreactionDetail: BlogReactionDetailPage.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: BlogReactionState, action: Action) =>
  combineReducers(map)(state, action);

const selectBlogReactionState = createFeatureSelector<BlogReactionState>(
  blogreactionFeatureKey
);

export const selectState = createSelector(
  selectBlogReactionState,
  (state) => state
);
export const selectBlogReactionOverviewState = createSelector(
  selectState,
  (state) => state.blogreactionOverview
);

export const selectBlogId = createSelector(
  selectBlogReactionOverviewState,
  BlogReactionOverview.getBlogId
);

export const selectBlogReactionOverviewFilter = createSelector(
  selectBlogReactionOverviewState,
  BlogReactionOverview.getFilter
);

export const selectBlogReactionOverviewLoading = createSelector(
  selectBlogReactionOverviewState,
  BlogReactionOverview.getLoading
);
export const selectBlogReactionOverviewList = createSelector(
  selectBlogReactionOverviewState,
  BlogReactionOverview.getList
);
export const selectBlogReactionOverviewPagination = createSelector(
  selectBlogReactionOverviewState,
  BlogReactionOverview.getPagination
);
export const selectBlogReactionOverviewSorting = createSelector(
  selectBlogReactionOverviewState,
  BlogReactionOverview.getSorting
);

export const selectBlogReactionDetailState = createSelector(
  selectState,
  (state) => state.blogreactionDetail
);

export const selectDetailLoading = createSelector(
  selectBlogReactionDetailState,
  BlogReactionDetailPage.getLoading
);
export const selectDetailBlogReaction = createSelector(
  selectBlogReactionDetailState,
  BlogReactionDetailPage.getBlogReaction
);
export const selectDetailErrors = createSelector(
  selectBlogReactionDetailState,
  BlogReactionDetailPage.getErrors
);
