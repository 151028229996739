import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';
import { EmailTemplateCreateModel } from '@app/modules/email-template/models/email-template-create.model';
import { EmailTemplateUpdateModel } from '@app/modules/email-template/models/email-template-update.model';
import * as EmailTemplateApiActions from '@app/modules/email-template/actions/email-template-api.actions';
import * as EmailTemplate from '@app/modules/email-template/reducers';
import { EmailTemplateDefaultModel } from '@app/modules/email-template/models/email-template-default.model';

@Component({
  selector: 'app-email-template-detail',
  templateUrl: './email-template-detail.component.html',
  styleUrl: './email-template-detail.component.less'
})
export class EmailTemplateDetailComponent {
  	loading$ = this.store.select(EmailTemplate.selectDetailLoading);
	errors$ = this.store.select(EmailTemplate.selectDetailErrors);
	emailTemplate$ = this.store.select(EmailTemplate.selectDetailEmailTemplate);
	relationTypes$ = this.store.select(EmailTemplate.selectDetailRelationTypes);
	defaultEmailTemplate$ = this.store.select(EmailTemplate.selectDetailDefaultEmailTemplate);

	constructor(private readonly store: Store<State>) {
		this.store.dispatch(EmailTemplateApiActions.GetRelationTypes());
	}

	submit(emailTemplate: EmailTemplateCreateModel | EmailTemplateUpdateModel) {
		this.store.dispatch(
			emailTemplate.hasOwnProperty('id')
				? EmailTemplateApiActions.Update(emailTemplate as EmailTemplateUpdateModel)
				: EmailTemplateApiActions.Create(emailTemplate)
		);
	}

	getDefault(emailTemplateDefault: EmailTemplateDefaultModel) {
		this.store.dispatch(EmailTemplateApiActions.GetDefault(emailTemplateDefault));
	}
}
