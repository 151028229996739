import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as LoginPage from './login-page.reducer';
import * as Auth from './auth.reducer';
import * as PasswordReset from './password-reset-page.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
	auth: Auth.State;
	loginPage: LoginPage.State;
	passwordResetPage: PasswordReset.State;
}

export interface State {
	auth: AuthState;
}

export const map: ActionReducerMap<AuthState> = {
	auth: Auth.reducer,
	loginPage: LoginPage.reducer,
	passwordResetPage: PasswordReset.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: AuthState, action: Action) => combineReducers(map)(state, action);

const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectState = createSelector(selectAuthState, (state) => state);

export const selectLoginPageState = createSelector(selectState, (state) => state.loginPage);
export const selectAuthPageState = createSelector(selectState, (state) => state.auth);

export const selectLoginPageLoading = createSelector(selectLoginPageState, LoginPage.getLoading);
export const selectLoginPageErrors = createSelector(selectLoginPageState, LoginPage.getErrors);
export const selectUser = createSelector(selectAuthPageState, Auth.getUser);
export const selectToken = createSelector(selectAuthPageState, Auth.getToken);
export const selectClaims = createSelector(selectAuthPageState, Auth.getClaims);

export const selectPasswordResetPageState = createSelector(selectState, (state) => state.passwordResetPage);
export const selectPasswordResetPageLoading = createSelector(selectPasswordResetPageState, PasswordReset.getLoading);
export const selectPasswordResetPageErrors = createSelector(selectPasswordResetPageState, PasswordReset.getErrors);
export const selectPasswordResetPageSuccess = createSelector(selectPasswordResetPageState, PasswordReset.getSuccess);