import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as FAQActions from '@app/modules/faq/actions/faq.actions';
import { FAQService } from '@app/modules/faq/services/faq.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const faqService = inject(FAQService);
	const router = inject(Router);
	const store = inject(Store<State>);

	return faqService.get(route.params['id']).pipe(
		map((faq) => FAQActions.GetSuccess(faq)),
		tap((action: any) => store.dispatch(action)),
		map((faq) => !!faq),
		catchError(() => {
			return router.navigate(['/not-found']);
		})
	);
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);
