import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
	UserRoleCreateModel,
	UserRoleTableItemModel,
	UserRoleUpdateModel,
} from '@app/modules/user-roles/models';
import { TableDataModel } from '@app/shared/models';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserRoleService {
	private readonly endpoints = {
		get: 'Role/:id',
		getRoleClaims: 'Role/GetRoleClaims',
		overview: 'Role/GetOverview',
		update: 'Role/Update',
		create: 'Role/Create',
		delete: 'Role/Delete?id=:id',
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(
		sorting: TableSortingModel,
		pagination: TablePaginationModel
	): Observable<TableDataModel<UserRoleTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');

		return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}`);
	}

	get(id: string): Observable<UserRoleUpdateModel> {
		return this.httpService.get<any>(this.endpoints.get.replace(':id', id));
	}

	getRoleClaims(): Observable<any> {
		return this.httpService.get<any>(this.endpoints.getRoleClaims);
	}

	update(request: UserRoleUpdateModel): Observable<UserRoleUpdateModel> {
		return this.httpService.put<any>(this.endpoints.update, request);
	}

	create(request: UserRoleCreateModel): Observable<UserRoleUpdateModel> {
		return this.httpService.post<any>(this.endpoints.create, request);
	}

	delete(id: string): Observable<any> {
		return this.httpService.delete<any>(this.endpoints.delete.replace(':id', id));
	}
}
