<nz-table
	#overviewTable
	[nzData]="data.list"
	[nzPageIndex]="data.pagination.page"
	[nzPageSize]="data.pagination.pageSize"
	[nzTotal]="data.pagination.totalItems ?? 0"
	[nzLoading]="data.loading"
	[nzFrontPagination]="false"
	[nzShowPagination]="!draggingEnabled"
	(nzQueryParams)="queryParamsChange.emit($event)"
	nzTableLayout="fixed">
	<thead>
		<tr>
			<th
				nzColumnKey="sequenceNumber"
				nzAlign="center"
				nzWidth="40px"
				[nzShowSort]="false"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.Review,
						claimValues: [ClaimValues.Update]
					}
				]">
				<label nz-checkbox [(ngModel)]="draggingEnabled" (ngModelChange)="draggingChanged()" [disabled]="isFiltered"></label>
			</th>
			<th
				nzColumnKey="online"
				nzWidth="80px"
				[nzSortFn]="true"
				[nzShowSort]="!draggingEnabled"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.Review,
						claimValues: [ClaimValues.Update]
					}
				]">
				{{ 'reviews.overview.online' | translate }}
			</th>
			<th nzColumnKey="name" [nzSortFn]="true" [nzShowSort]="!draggingEnabled">
				{{ 'common.name' | translate }}
			</th>
			<th nzColumnKey="activityType.name" [nzSortFn]="true" [nzShowSort]="!draggingEnabled">
				{{ 'reviews.overview.activity-type' | translate }}
			</th>
			<th nzColumnKey="shortText" [nzSortFn]="true" [nzShowSort]="!draggingEnabled">
				{{ 'reviews.overview.text' | translate }}
			</th>
			<th nzColumnKey="date" nzWidth="120px" [nzSortFn]="true" [nzSortOrder]="'ascend'" [nzShowSort]="!draggingEnabled">
				{{ 'reviews.overview.creation-date' | translate }}
			</th>
			<th
				nzWidth="120px"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.Review,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]"></th>
		</tr>
	</thead>
	<tbody cdkDropList (cdkDropListDropped)="drop($event)">
		<tr
			*ngFor="let row of overviewTable.data"
			cdkDrag
			[cdkDragData]="row"
			[cdkDragDisabled]="!draggingEnabled || isFiltered"
			[ngClass]="expandSet.has(row.id) ? 'row-expanded' : null">
			<td
				nzAlign="center"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.Review,
						claimValues: [ClaimValues.Update]
					}
				]">
				<i class="fa-solid fa-grip-dots-vertical" cdkDragHandle></i>
			</td>
			<td
				nzAlign="center"
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.Review,
						claimValues: [ClaimValues.Update]
					}
				]">
				<label nz-checkbox [nzChecked]="row.online" (nzCheckedChange)="setOnline(row.id, !row.online)"></label>
			</td>
			<td>{{ row.name | truncateText }}</td>
			<td>{{ row.activityTypeName | truncateText }}</td>
			<td>
				<div class="review-text">
					<div [ngClass]="expandSet.has(row.id) ? 'expanded' : 'collapsed'">
						<div [innerHTML]="row.shortText"></div>
						<div *ngIf="expandSet.has(row.id)" [innerHTML]="row.longText"></div>
					</div>
					<button
						*ngIf="expandSet.has(row.id)"
						(click)="onExpandChange(row.id, false)"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-minus"></i>
					</button>
					<button
						*ngIf="!expandSet.has(row.id)"
						(click)="onExpandChange(row.id, true)"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-add"></i>
					</button>
				</div>
			</td>
			<td>{{ row.date | date }}</td>
			<td
				claimDirective
				[claims]="[
					{
						claimType: ClaimTypes.Review,
						claimValues: [ClaimValues.Read, ClaimValues.Delete]
					}
				]">
				<div class="table-actions">
					<a
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.Review,
								claimValues: [ClaimValues.Read]
							}
						]"
						[routerLink]="['/reviews/bewerken', row.id]"
						nz-button
						nzType="text"
						nzShape="circle">
						<i class="fa-regular fa-pen"></i>
					</a>
					<button
						claimDirective
						[claims]="[
							{
								claimType: ClaimTypes.Review,
								claimValues: [ClaimValues.Delete]
							}
						]"
						nz-button
						nzDanger
						nzType="text"
						nzShape="circle"
						nz-popconfirm
						nzOkDanger
						[nzPopconfirmTitle]="'notification.delete' | translate"
						[nzOkText]="'common.delete' | translate"
						[nzCancelText]="'common.cancel' | translate"
						[nzIcon]="templateIconDelete"
						nzPopconfirmPlacement="left"
						(nzOnConfirm)="delete.emit(row.id)">
						<i class="fa-regular fa-trash-can"></i>
					</button>
				</div>
			</td>
		</tr>
	</tbody>
</nz-table>

<ng-template #templateIconDelete>
	<i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
