import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TokenService } from "@app/core/services/token/token.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { exhaustMap, map, catchError, of, tap } from "rxjs";
import * as MobileAuthActions from '@app/mobile/modules/auth/actions/mobile-auth.actions';
import { MobileAuthService } from "@app/mobile/modules/auth/services/mobile-auth.service";

@Injectable()
export class MobileAuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly mobileAuthService: MobileAuthService,
    private readonly router: Router,
    private readonly tokenService: TokenService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MobileAuthActions.Login),
      exhaustMap((action) =>
        this.mobileAuthService.login(action.loginRequest).pipe(
          map((response) =>
            MobileAuthActions.LoginSuccess({ loginResponse: response })
          ),
          catchError((error) =>
            of(MobileAuthActions.LoginError({ response: error.error }))
          )
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MobileAuthActions.LoginSuccess),
        tap((action) => {
          this.tokenService.setToken(action.loginResponse.token);
          this.tokenService.setMobileLogin(true);
          this.router.navigate(['mobiel/activiteiten']);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MobileAuthActions.Logout),
        tap(() => {
          this.tokenService.removeToken();
          this.router.navigate(['mobiel']);
        })
      ),
    { dispatch: false }
  );
}