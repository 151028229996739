import { Injectable } from '@angular/core';
import { selectClaims } from '@app/modules/auth/reducers';
import { Claim } from '@app/shared/models/common/claim.model';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  claims$ = this.store.select(selectClaims);

  constructor(private readonly store: Store) {}

  async hasAny(claims: Claim[]): Promise<boolean> {
    const userClaims = await firstValueFrom(this.claims$);
    
    return (
      (userClaims &&
        userClaims.some((u) => {
          return claims.some((c) => {
            return (
              c.claimType === u.key &&
              c.claimValues.some((cv) => u.value.includes(cv))
            );
          });
        })) ??
      false
    );
  }
}
