<div class="page-header">
    <a routerLink="/relaties/overzicht" class="page-header__back">
        <i class="fa-regular fa-chevron-left"></i>
    </a>
    <h1 class="page-header__title">{{ 'relation.title' | translate }}</h1>
    <div class="page-header__actions">
        <button [routerLink]="['/relaties/bewerken', (relation$ | async)?.id]" nz-button nzType="primary">
            {{ 'relation.detail.edit-title' | translate }}
            <i class="fa-regular fa-pen"></i>
        </button>
    </div>
</div>

<div nz-row [nzGutter]="16">
    <div nz-col [nzSpan]="24">
        <app-relation-view-card [relation]="(relation$ | async)!" [loading]="loading$ | async" />
    </div>
</div>
<div nz-row [nzGutter]="16">
    <div nz-col [nzSpan]="18">
        <app-relation-view-info
            [relation]="(relation$ | async)!"
            [loading]="loading$ | async"
            [errors]="errors$ | async" />
    </div>
    <div nz-col [nzSpan]="6">
        <div nz-row>
            <div nz-col [nzSpan]="24">
                <app-toolbox-sidebar
                    (delete)="delete()"
                    (loginAsRelation)="loginAsRelation()"
                    (resendWelcomeMail)="resendWelcomeMail()" />
            </div>
            <div nz-col [nzSpan]="24">
                <app-notes-sidebar
                    [note$]="note$"
                    [errors$]="noteErrors$"
                    [loading$]="noteLoading$"
                    [notes]="(notes$ | async)!"
                    (createNote)="createNote($event)"
                    (setNoteImportant)="setNoteImportant($event)"
                    (doEditNote)="editNote($event)"
                    (doDeleteNote)="deleteNote($event)"></app-notes-sidebar>
            </div>
        </div>
    </div>
</div>
