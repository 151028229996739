<div class="component-container" cdkDropList (cdkDropListDropped)="dropHandle($event)">
    <section *ngFor="let contentBlock of formArray.controls; let i = index" [formGroup]="contentBlock" class="component"
        [cdkDragDisabled]="!options[i].draggable" cdkDrag cdkDragLockAxis="y">
        <div class="component-header">
            <div class="component-drag-handle" cdkDragHandle *ngIf="options[i].draggable">
                <i class="fa-solid fa-grip-dots-vertical"></i>
            </div>
            <div class="component-title">
                <nz-tag class="primary-tag">{{ getContentTypeText(contentBlock.controls['type'].value) | translate }}</nz-tag>
                <input nz-input placeholder="{{ 'content-block.fields.label' | translate }}" formControlName="label"
                    [readonly]="!options[i].editingLabel" [nzBorderless]="!options[i].editingLabel" />
            </div>
            <div class="component-actions" *ngIf="!options[i].isReadOnly">
                <button *ngIf="!options[i].editingLabel" nz-button nzType="text" class="warning"
                    (click)="options[i].editingLabel = true">
                    <i class="fa-regular fa-pencil"></i>
                    {{ "content-block.actions.edit-label" | translate }}
                </button>
                <button *ngIf="options[i].editingLabel" nz-button nzType="text" class="success"
                    (click)="options[i].editingLabel = false">
                    <i class="fa-regular fa-check"></i>
                    {{ "content-block.actions.edit-label" | translate }}
                </button>
                <button nz-button nzType="text" class="error" (click)="removeContentBlock(i)">
                    <i class="fa-regular fa-trash-can"></i>
                    {{ "content-block.actions.delete" | translate }}
                </button>
            </div>
        </div>

        @switch (contentBlock.controls['type'].value)
        {
            @case (ContentBlockType.Editor)
            {
                <app-editor-content-block formGroupName="content" [isReadOnly]="options[i].isReadOnly"></app-editor-content-block>
            }
            @case (ContentBlockType.ImageAndEditor)
            {
                <app-image-and-editor-content-block formGroupName="content" [isReadOnly]="options[i].isReadOnly" [errors]="errors"></app-image-and-editor-content-block>
            }
        }
    </section>

    <nz-divider [nzText]="text" class="m-0" *ngIf="!this.isReadOnly">
        <ng-template #text>
            <button nz-button nzType="primary" nzSize="small" nzShape="circle" nz-dropdown nzTrigger="click"
                nzPlacement="topCenter" [nzClickHide]="true" [nzDropdownMenu]="componentAddDropdown">
                <i class="fa-regular fa-add"></i>
            </button>
        </ng-template>
    </nz-divider>

    <nz-dropdown-menu #componentAddDropdown="nzDropdownMenu">
        <ul nz-menu nzSelectable>
            <li nz-menu-item *ngFor="let contentType of availableContentTypes" (click)="addContentBlock(contentType)" aria-hidden="true">
                {{ getContentTypeText(contentType) | translate }}
            </li>
        </ul>
    </nz-dropdown-menu>
</div>