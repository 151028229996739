import { TableItemModel } from "@app/shared/models/table/table-item.model";
import { TableStateModel } from "@app/shared/models/table/table-state.model";

export class TableOverviewModel<T extends TableItemModel> implements TableStateModel<T> {
    validationMessages = [];
    list = [];
    pagination = {
        page: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0
    };
    filter = [];
    sorting = {
        propertyName: '',
        order: 'ASC'
    };
    loading = false;

    constructor(propertyName: string, pageSize: number = 10) {
        this.pagination.pageSize = pageSize;
        this.sorting.propertyName = propertyName;
    }
}