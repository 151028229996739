import { HttpXhrBackend } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { httpServiceFactory } from '@app/core/factories';
import { HttpService } from '@app/core/services/http/http.service';

import { AppRoutingModule } from '@app/app-routing.module';
import { BackofficeLayoutComponent } from '@app/core/containers/backoffice-layout/backoffice-layout.component';
import { PublicLayoutComponent } from '@app/core/containers/public-layout/public-layout.component';
import { CustomRequestOptions } from '@app/core/services/http/custom-request.options';
import { TokenService } from '@app/core/services/token/token.service';
import { Sidebar, Topbar } from '@app/shared/components';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { BackofficeMobileLayoutComponent } from '@app/core/containers/backoffice-mobile-layout/backoffice-mobile-layout.component';
import { MobileTopbarComponent } from '@app/mobile/shared/components/topbar/mobile-topbar.component';

export const COMPONENTS = [BackofficeLayoutComponent, PublicLayoutComponent, BackofficeMobileLayoutComponent];
export const MODULES = [Topbar, Sidebar, MobileTopbarComponent, AppRoutingModule];

@NgModule({
	imports: [...MODULES],
	declarations: [COMPONENTS],
	exports: [COMPONENTS],
	providers: [
		{
			provide: HttpService,
			useFactory: httpServiceFactory,
			deps: [HttpXhrBackend, CustomRequestOptions, TokenService, Store, Router],
		},
	],
})
export class CoreModule {}
