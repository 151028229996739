import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { ActivityScheduleUpdateModel } from '@app/mobile/modules/activity-schedule/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityScheduleService {
  private readonly endpoints = {
    getList: 'ActivitySchedule/List',
    get: 'ActivitySchedule/:id',
    update: 'ActivitySchedule',
  };

  constructor(private readonly httpService: HttpService) {}

  getActivityScheduleList(): Observable<KeyValueModel[]> {
    return this.httpService.get<KeyValueModel[]>(this.endpoints.getList);
  }

  getActivitySchedule(id: number): Observable<ActivityScheduleUpdateModel> {
    return this.httpService.get<ActivityScheduleUpdateModel>(
      this.endpoints.get.replace(':id', id.toString())
    );
  }

  update(request: ActivityScheduleUpdateModel): Observable<void> {
    return this.httpService.put<void>(this.endpoints.update, request);
  }
}
