<nz-table
  #overviewTable
  [nzData]="data.list"
  [nzPageIndex]="data.pagination.page"
  [nzPageSize]="data.pagination.pageSize"
  [nzTotal]="data.pagination.totalItems ?? 0"
  [nzLoading]="data.loading"
  [nzFrontPagination]="false"
  [nzShowPagination]="true"
  (nzQueryParams)="onQueryParamsChange.emit($event)"
  nzTableLayout="fixed"
>
  <thead>
    <tr>
      <th
        nzWidth="90px"
        [nzShowSort]="false"
        nzColumnKey="isVisible"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Update]
          }
        ]"
      >
        {{ "common.is-visible" | translate }}
      </th>
      <th
        [nzSortFn]="true"
        [nzShowSort]="true"
        nzColumnKey="title"
        [nzSortOrder]="'ascend'"
      >
        {{ "common.title" | translate }}
      </th>
      <th
        nzWidth="140px"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Read, ClaimValues.Delete]
          }
        ]"
      ></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of overviewTable.data">
      <td
        nzAlign="center"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Update]
          }
        ]"
      >
        <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
        <label
          nz-checkbox
          [nzChecked]="row?.isVisible"
          (nzCheckedChange)="changeVisible(row.id, !row.isVisible)"
        ></label>
      </td>
      <td>{{ row.title | truncateText }}</td>
      <td
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Read, ClaimValues.Delete]
          }
        ]"
      >
        <div class="table-actions">
          <a
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Page,
                claimValues: [ClaimValues.Read]
              }
            ]"
            [href]="row.previewLink"
            target="_blank"
            nz-button
            nzType="text"
            nzShape="circle"
          >
            <i class="fa-regular fa-magnifying-glass"></i>
          </a>
          <a
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Page,
                claimValues: [ClaimValues.Read]
              }
            ]"
            [routerLink]="['/paginas/bewerken/', row.id]"
            nz-button
            nzType="text"
            nzShape="circle"
          >
            <i class="fa-regular fa-pen"></i>
          </a>
          <button
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Page,
                claimValues: [ClaimValues.Delete]
              }
            ]"
            nz-button
            nzDanger
            nzType="text"
            nzShape="circle"
            nz-popconfirm
            nzOkDanger
            [nzPopconfirmTitle]="'notification.delete' | translate"
            [nzOkText]="'common.delete' | translate"
            [nzCancelText]="'common.cancel' | translate"
            [nzIcon]="templateIconDelete"
            nzPopconfirmPlacement="left"
            (nzOnConfirm)="delete.emit(row.id)"
          >
            <i class="fa-regular fa-trash-can"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #templateIconDelete>
  <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
