export enum ActivityPlanningStatus {
    InAssignment = 1,
    InPlanning = 2,
    Done = 3,
}

export const ActivityPlanningStatusLabels: Record<number, string> = {
    [ActivityPlanningStatus.InAssignment]: "activity.fields.InAssignment",
    [ActivityPlanningStatus.InPlanning]: "activity.fields.InPlanning",
    [ActivityPlanningStatus.Done]: "activity.fields.Done",
  }