import { Component, SkipSelf } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { AbstractBlockComponent, ImageAndEditorContentBlockFormGroup } from "@app/shared/models/content";

@Component({
    selector: 'app-image-and-editor-content-block',
    templateUrl: './image-and-editor-content-block.component.html',
    styleUrls: ['./image-and-editor-content-block.component.less'],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ]
})
export class ImageAndEditorContentBlockComponent extends AbstractBlockComponent {
    override formGroup!: ImageAndEditorContentBlockFormGroup;

    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }
}