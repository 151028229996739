<nz-table
  #overviewTable
  [nzData]="data.list"
  [nzPageIndex]="data.pagination.page"
  [nzPageSize]="data.pagination.pageSize"
  [nzTotal]="data.pagination.totalItems ?? 0"
  [nzLoading]="data.loading"
  [nzFrontPagination]="false"
  (nzQueryParams)="queryParamsChange.emit($event)"
  nzTableLayout="fixed"
>
  <thead>
    <tr>
      <th
        nzWidth="100px"
        nzColumnKey="showOnline"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Blog,
            claimValues: [ClaimValues.Update]
          }
        ]"
      >
        {{ "blog.fields.online" | translate }}
      </th>
      <th nzColumnKey="title" [nzSortFn]="true">
        {{ "blog.fields.title" | translate }}
      </th>
      <th nzColumnKey="publishDate">
        {{ "blog.fields.status" | translate }}
      </th>
      <th nzColumnKey="createdOn" [nzSortFn]="true">
        {{ "blog.fields.date" | translate }}
      </th>
      <th nzColumnKey="publishDate" [nzSortFn]="true" [nzSortOrder]="'descend'">
        {{ "blog.fields.publish-date" | translate }}
      </th>
      <th nzColumnKey="numberReactions" nzWidth="80px"></th>
      <th
        nzWidth="140px"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Blog,
            claimValues: [ClaimValues.Read, ClaimValues.Delete]
          }
        ]"
      ></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of overviewTable.data">
      <td
        nzAlign="center"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Blog,
            claimValues: [ClaimValues.Update]
          }
        ]"
      >
        <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
        <label
          nz-checkbox
          [nzChecked]="row.showOnline"
          (nzCheckedChange)="onChangeShowOnline(row.id, !row.showOnline)"
        ></label>
      </td>
      <td>{{ row.title | truncateText }}</td>
      <td>
        {{ row.publishDate | blogStatusPipe : row.showOnline | translate }}
      </td>
      <td>
        {{ row.createdOn | date }}
      </td>
      <td>{{ row.publishDate | date }}</td>
      <td nzAlign="center">
        <a
          claimDirective
          [claims]="[
            {
              claimType: ClaimTypes.Blog,
              claimValues: [ClaimValues.Read]
            }
          ]"
          [routerLink]="['/blogs', row.id, 'blog-reacties']"
          nz-button
          nzType="text"
          nzShape="circle"
          ><i class="fa-solid fa-comments"></i>{{
            row.numberReactions
          }}
        </a>
      </td>
      <td
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Blog,
            claimValues: [ClaimValues.Read, ClaimValues.Delete]
          }
        ]"
      >
        <div class="table-actions">
          <a
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Blog,
                claimValues: [ClaimValues.Read]
              }
            ]"
            [href]="row.previewLink"
            target="_blank"
            nz-button
            nzType="text"
            nzShape="circle"
          >
            <i class="fa-regular fa-magnifying-glass"></i>
          </a>

          <a
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Blog,
                claimValues: [ClaimValues.Read]
              }
            ]"
            [routerLink]="['/blogs/bewerken', row.id]"
            nz-button
            nzType="text"
            nzShape="circle"
          >
            <i class="fa-regular fa-pen"></i>
          </a>

          <button
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Blog,
                claimValues: [ClaimValues.Delete]
              }
            ]"
            nz-button
            nzDanger
            nzType="text"
            nzShape="circle"
            nz-popconfirm
            nzOkDanger
            [nzPopconfirmTitle]="'notification.delete' | translate"
            [nzOkText]="'common.delete' | translate"
            [nzCancelText]="'common.cancel' | translate"
            [nzIcon]="templateIconDelete"
            nzPopconfirmPlacement="left"
            (nzOnConfirm)="delete.emit(row.id)"
          >
            <i class="fa-regular fa-trash-can"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #templateIconDelete>
  <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
