import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as ActivityTypeActions from '@app/modules/activity-type/actions/activity-type-api.actions';
import * as ActivityType from '@app/modules/activity-type/reducers';
import { ActivityTypeService } from '@app/modules/activity-type/services/activity-type.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, exhaustMap, catchError, of, tap } from 'rxjs';

@Injectable()
export class ActivityTypeEffects {
  constructor(
    private actions$: Actions,
    private activityTypeService: ActivityTypeService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<ActivityType.State>
  ) {}

  /**
   * Get effects
   */
  loadActivityTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(ActivityType.selectActivityTypeOverviewPagination),
        this.store.select(ActivityType.selectActivityTypeOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.activityTypeService.getOverview(sorting, pagination).pipe(
          map((response) => ActivityTypeActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityTypeActions.GetOverviewError({ response: response.error })
            )
          )
        )
      )
    )
  );

  loadActivityTypesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.FilterOverview),
      map(() => ActivityTypeActions.GetOverview())
    )
  );

  loadActivityType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.Get),
      exhaustMap((action) =>
        this.activityTypeService.get(action.id).pipe(
          map((response) => ActivityTypeActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(ActivityTypeActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createActivityType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.Create),
      exhaustMap((action) =>
        this.activityTypeService.create(action).pipe(
          map((activityType) =>
            ActivityTypeActions.CreateSuccess(activityType)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(ActivityTypeActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createActivityTypeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivityTypeActions.CreateSuccess),
        tap((activityType) =>
          this.translate
            .get('notification.add', { item: activityType.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateActivityType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.Update),
      exhaustMap((action) =>
        this.activityTypeService.update(action).pipe(
          map((activityType) =>
            ActivityTypeActions.UpdateSuccess(activityType)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(ActivityTypeActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateActivityTypeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivityTypeActions.UpdateSuccess),
        tap((activityType) =>
          this.translate
            .get('notification.save', { item: activityType.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateActivityTypeSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ActivityTypeActions.CreateSuccess,
          ActivityTypeActions.UpdateSuccess
        ),
        tap(() => this.router.navigate(['activiteit-typen']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteActivityType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.Delete),
      exhaustMap((action) =>
        this.activityTypeService.delete(action.id).pipe(
          map(() => ActivityTypeActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(ActivityTypeActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteActivityTypeSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.DeleteSuccess),
      map(() => ActivityTypeActions.GetOverview())
    )
  );

  deleteActivityTypeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivityTypeActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change sequence effects
   */
  changeSequenceActivityType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ChangeSequence),
      exhaustMap((action) =>
        this.activityTypeService.changeSequence(action).pipe(
          map(() => ActivityTypeActions.ChangeSequenceSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityTypeActions.ChangeSequenceError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  changeSequenceSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityTypeActions.ChangeSequenceSuccess),
      map(() => ActivityTypeActions.GetOverview())
    )
  );

  activityTypeErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ActivityTypeActions.DeleteError,
          ActivityTypeActions.GetOverviewError,
          ActivityTypeActions.ChangeSequenceError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
