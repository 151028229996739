<form nz-form [formGroup]="formGroup">
    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'activity.header.basis' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label nzRequired [nzSpan]="24">
                        {{ 'activity.fields.activity-type' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="activityTypeValidationTemplate">
                        <nz-select
                            class="w-100"
                            nzPlaceHolder="{{ 'activity.fields.activity-type' | translate }}"
                            formControlName="activityTypeId">
                            <nz-option
                                *ngFor="let activityType of activityTypes"
                                [nzLabel]="activityType.name"
                                [nzValue]="activityType.id"></nz-option>
                        </nz-select>
                        <ng-template #activityTypeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'activityTypeId'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24" nzRequired>
                        {{ 'activity.fields.name' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="nameValidationTemplate">
                        <input nz-input [formControl]="formGroup.controls.name" />

                        <ng-template #nameValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'name'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.exact-code' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="exactCodeValidationTemplate">
                        <input nz-input [formControl]="formGroup.controls.exactCode" />

                        <ng-template #exactCodeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'exactCode'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.code' | translate }}
                    </nz-form-label>

                    <nz-form-control [nzSpan]="24" [nzErrorTip]="codeValidationTemplate">
                        <nz-input-group [nzSuffix]="generateSuffix">
                            <input nz-input [formControl]="formGroup.controls.code" [value]="this.code ?? ''" />
                            <ng-template #generateSuffix>
                                <a class="mouse-pointer">
                                    <i
                                        class="fa-solid fa-rotate-right"
                                        (click)="generateCode()"
                                        (keypress)="generateCode()"
                                        role="button"
                                        tabindex="{0}"></i>
                                </a>
                            </ng-template>
                        </nz-input-group>
                        <ng-template #codeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'code'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.status' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="statusValidationTemplate">
                        <nz-select formControlName="status">
                            <nz-option
                                *ngFor="let stat of activityStatus | enumKeyValue"
                                [nzValue]="stat.value"
                                [nzLabel]="statusMapping[stat.value] | translate"></nz-option>
                        </nz-select>

                        <ng-template #statusValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'status'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.planning-status' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="planningStatusValidationTemplate">
                        <nz-select formControlName="planningStatus">
                            <nz-option
                                *ngFor="let stat of planningActivityStatus | enumKeyValue"
                                [nzValue]="stat.value"
                                [nzLabel]="planningStatusMapping[stat.value] | translate"></nz-option>
                        </nz-select>

                        <ng-template #planningStatusValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'planningStatus'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.location' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="locationValidationTemplate">
                        <nz-select
                            formControlName="location"
                            nzPlaceHolder="{{ 'activity.fields.select-location' | translate }}">
                            <nz-option
                                *ngFor="let stat of activityLocations"
                                [nzValue]="stat.key"
                                [nzLabel]="stat.value"></nz-option>
                        </nz-select>

                        <ng-template #locationValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'location'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </section>
    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'activity.header.date-settings' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.activityDate' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="activityDateValidationTemplate">
                        <nz-range-picker
                            [nzFormat]="dateFormat"
                            [formControl]="formGroup.controls.activityDate"
                            class="w-100"></nz-range-picker>

                        <ng-template #activityDateValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'activityDate'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzSpan="6">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.evaluation-date' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="evaluationDateValidationTemplate">
                        <nz-date-picker
                            [nzFormat]="this.dateFormat"
                            class="w-100"
                            [formControl]="formGroup.controls.evaluationDate"></nz-date-picker>

                        <ng-template #evaluationDateValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'evaluationDate'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="6">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.cancelled-date' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="cancelledDateValidationTemplate">
                        <nz-date-picker
                            (ngModelChange)="cancelledDateChange()"
                            [nzFormat]="this.dateFormat"
                            class="w-100"
                            [formControl]="formGroup.controls.cancelledDate"></nz-date-picker>

                        <ng-template #cancelledDateValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'cancelledDate'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="6">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.review-date' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="reviewDateValidationTemplate">
                        <nz-date-picker
                            [nzFormat]="this.dateFormat"
                            class="w-100"
                            [formControl]="formGroup.controls.reviewDate"></nz-date-picker>

                        <ng-template #reviewDateValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'reviewDate'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </section>

    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'activity.header.visibility-site' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24"></nz-form-label>
                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                    <label nz-checkbox [nzValue]="activity?.online" formControlName="online">
                        {{ 'activity.fields.online' | translate }}
                    </label>
                </nz-form-item>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.visibility' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="visibilityValidationTemplate">
                        <nz-range-picker
                            [nzFormat]="this.dateFormat"
                            [formControl]="formGroup.controls.visibility"
                            class="w-100"></nz-range-picker>

                        <ng-template #visibilityValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'visibility'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </section>

    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'activity.header.price' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24"></nz-form-label>
                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                    <label nz-checkbox [nzValue]="activity?.displayPrices" formControlName="displayPrices">
                        {{ 'activity.fields.display-prices' | translate }}
                    </label>
                </nz-form-item>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.price-including-vat' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="priceIncludingVatValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.priceIncludingVat" />

                        <ng-template #priceIncludingVatValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'priceIncludingVat'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.price-second-including-vat' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="priceSecondIncludingVatValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.priceSecondIncludingVat" />

                        <ng-template #priceSecondIncludingVatValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'priceSecondIncludingVat'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.vat-percentage' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="vatPercentageValidationTemplate">
                        <nz-select
                            class="w-100"
                            nzPlaceHolder="{{ 'activity.fields.vat-percentage' | translate }}"
                            formControlName="vatPercentage">
                            <nz-option [nzValue]="0" nzLabel="0%"></nz-option>
                            <nz-option [nzValue]="9" nzLabel="9%"></nz-option>
                            <nz-option [nzValue]="21" nzLabel="21%"></nz-option>
                        </nz-select>
                        <ng-template #vatPercentageValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'vatPercentage'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </section>

    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'activity.header.extra-information' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-label [nzSpan]="24"></nz-form-label>
                <nz-form-item>
                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                    <label nz-checkbox [nzValue]="activity?.includesLunch" formControlName="includesLunch">
                        {{ 'activity.fields.include-lunch' | translate }}
                    </label>
                </nz-form-item>
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="6">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.start-time' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="startTimeValidationTemplate">
                        <nz-time-picker
                            [formControl]="formGroup.controls.startTime"
                            class="w-100"
                            nzFormat="HH:mm"
                            [nzDefaultOpenValue]="emptyStartTime"></nz-time-picker>

                        <ng-template #startTimeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'startTime'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="6">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.end-time' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="endTimeValidationTemplate">
                        <nz-time-picker
                            [formControl]="formGroup.controls.endTime"
                            class="w-100"
                            nzFormat="HH:mm"
                            [nzDefaultOpenValue]="emptyEndTime"></nz-time-picker>

                        <ng-template #endTimeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'endTime'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzSpan="12">
                <app-asset
                    *ngIf="readonly !== undefined"
                    ngDefaultControl
                    [formGroup]="formGroup.controls.attachmentOne"
                    [isReadOnly]="readonly"
                    [errors]="errors"
                    [label]="'activity.fields.attachment-one'"
                    [fieldName]="'asset'"
                    [required]="false" />
            </div>
        </div>
        <div nz-row>
            <div nz-col nzSpan="12">
                <app-asset
                    *ngIf="readonly !== undefined"
                    ngDefaultControl
                    [formGroup]="formGroup.controls.attachmentTwo"
                    [isReadOnly]="readonly"
                    [errors]="errors"
                    [label]="'activity.fields.attachment-two'"
                    [fieldName]="'asset'"
                    [required]="false" />
            </div>
        </div>
    </section>

    <section class="form-section" *ngIf="hasTheme">
        <header>
            <h5 class="form-section__title">
                {{ 'activity.header.theme' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.theme-name' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="themeNameValidationTemplate">
                        <input nz-input [formControl]="formGroup.controls.themeName" />

                        <ng-template #themeNameValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'themeName'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.theme-code' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="themeCodeValidationTemplate">
                        <input nz-input [formControl]="formGroup.controls.themeCode" />

                        <ng-template #themeCodeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'themeCode'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzSpan="24">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.theme-description' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24">
                        <app-input-validation
                            [control]="formGroup.controls.themeDescription"
                            [field]="'themeDescription'"
                            [errors]="errors"></app-input-validation>
                        <app-editor
                            *ngIf="readonly !== undefined"
                            [control]="formGroup.controls.themeDescription"
                            [isDraggable]="false"
                            [isReadOnly]="readonly"></app-editor>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </section>

    <section class="form-section">
        <header>
            <h5 class="form-section__title">
                {{ 'activity.header.availability' | translate }}
            </h5>
        </header>
        <div nz-row>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.minimum-participants' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="minimumParticipantsValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.minimumParticipants" />

                        <ng-template #minimumParticipantsValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'minimumParticipants'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
                <nz-form-item>
                    <nz-form-label [nzSpan]="24">
                        {{ 'activity.fields.maximum-participants' | translate }}
                    </nz-form-label>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="maximumParticipantsValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.maximumParticipants" />

                        <ng-template #maximumParticipantsValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'maximumParticipants'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row class="mb-0">
            <div nz-col nzFlex="0 0 130px"></div>
            <div nz-col nzSpan="4">
                <strong>{{ 'activity.fields.available' | translate }}</strong>
            </div>
            <div nz-col nzSpan="4">
                <strong>{{ 'activity.fields.waitinglist' | translate }}</strong>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
                {{ 'activity.fields.student' | translate }}
            </div>
            <div nz-col nzSpan="4">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="availableStudentValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.availableStudent" />
                        <ng-template #availableStudentValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'availableStudent'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="4">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="waitlistStudentValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.waitlistStudent" />

                        <ng-template #waitlistStudentValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'waitlistStudent'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
                {{ 'activity.fields.student-second' | translate }}
            </div>
            <div nz-col nzSpan="4">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="availableStudentSecondTimeValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.availableStudentSecondTime" />

                        <ng-template #availableStudentSecondTimeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'availableStudentSecondTime'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="4">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="waitlistStudentSecondTimeValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.waitlistStudentSecondTime" />

                        <ng-template #waitlistStudentSecondTimeValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'waitlistStudentSecondTime'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
                {{ 'activity.fields.catch-up' | translate }}
            </div>
            <div nz-col nzSpan="4">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="availableCatchUpValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.availableCatchUp" />

                        <ng-template #availableCatchUpValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'availableCatchUp'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="4">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="waitlistCatchUpValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.waitlistCatchUp" />

                        <ng-template #waitlistCatchUpValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'waitlistCatchUp'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <div nz-row>
            <div nz-col nzFlex="0 0 130px" class="text-right pt-md">
                {{ 'activity.fields.guest' | translate }}
            </div>
            <div nz-col nzSpan="4">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="availableGuestValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.availableGuest" />

                        <ng-template #availableGuestValidationTemplate let-control>
                            <app-input-validation
                                [control]="control"
                                [field]="'availableGuest'"
                                [errors]="errors"></app-input-validation>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="4">
                <nz-form-item>
                    <nz-form-control [nzSpan]="24" [nzErrorTip]="waitlistGuestValidationTemplate">
                        <input type="number" nz-input [formControl]="formGroup.controls.waitlistGuest" />
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>

        <nz-form-control [nzErrorTip]="waitlistGuestValidationTemplate">
            <ng-template #waitlistGuestValidationTemplate let-control>
                <app-input-validation
                    [control]="control"
                    [field]="'waitlistGuest'"
                    [errors]="errors"></app-input-validation>
            </ng-template>
        </nz-form-control>
    </section>

    <div class="button-container">
        <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
            {{ 'common.save' | translate }}
        </button>
    </div>
</form>
