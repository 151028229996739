import { Component } from '@angular/core';
import * as Relation from '@app/modules/relation/reducers'
import * as RelationApiActions from '@app/modules/relation/actions/relation-api.actions';
import { Store } from "@ngrx/store";
import * as Note from '@app/modules/note/reducers';
import * as NoteApiActions from '@app/modules/note/actions/note-api.actions';
import { NoteType } from '@app/modules/note/enum/note-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-relation-view',
    templateUrl: './relation-view.component.html',
    styleUrls: ['./relation-view.component.less'],
})
export class RelationViewComponent {
    loading$ = this.store.select(Relation.selectViewLoading);
    errors$ = this.store.select(Relation.selectViewErrors);
    relation$ = this.store.select(Relation.selectViewRelation);

    notes$ = this.store.select(Note.selectNoteList);
    note$ = this.store.select(Note.selectNote);

    noteErrors$ = this.store.select(Note.selectErrors);
    noteLoading$ = this.store.select(Note.selectLoading);

    relationId: number | null = null;


    constructor(private readonly store: Store) {
        this.relation$.pipe(takeUntilDestroyed()).subscribe((relation) => (this.relationId = relation.id));
        this.store.dispatch(
            NoteApiActions.SetNoteType({ noteType: NoteType.Relation, noteTypeId: this.relationId }),
        );
        this.store.dispatch(NoteApiActions.GetOverview());
    }

    delete(){
        this.store.dispatch(RelationApiActions.Delete());
    }

    resendWelcomeMail(){
        this.store.dispatch(RelationApiActions.ResendWelcomeMail());
    }

    loginAsRelation(){
        this.store.dispatch(RelationApiActions.LoginAsRelation());
    }

    createNote(event: any) {
        this.store.dispatch(
            NoteApiActions.Create({ ...event, noteTypeId: this.relationId, noteType: NoteType.Relation }),
        );
    }

    editNote(event: any) {
        this.store.dispatch(
            NoteApiActions.Update({
                data: {
                    ...event,
                    noteMessageEdit: event.noteMessageEdit,
                    noteTypeId: this.relationId,
                    noteType: NoteType.ActivityWaitlist,
                },
            }),
        );
    }

    setNoteImportant(event: any) {
        this.store.dispatch(NoteApiActions.SetImportant({ noteId: event.noteId }));
    }

    deleteNote(event: any) {
        this.store.dispatch(NoteApiActions.Delete({ noteId: event.noteId }));
    }

}