<div class="tab-container">
    <nz-tabset nzType="card">
        <nz-tab [nzTitle]="'activity.header.details' | translate">
            <section class="form-section">
                <header>
                    <h5 class="form-section__title">
                        {{ 'relation.header.relation-information' | translate }}
                    </h5>
                </header>
                <div nz-row>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.status' | translate }}:</strong>
                        {{ relation.active ? ('relation.fields.active' | translate) : ('relation.fields.inactive' | translate) }}
                    </div>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.relation-type' | translate }}:</strong>
                        {{ relation.relationTypeName }}
                    </div>
                </div>
            </section>

            <section class="form-section">
                <header>
                    <h5 class="form-section__title">
                        {{ 'relation.header.personal-information' | translate }}
                    </h5>
                </header>
                <div nz-row>
                    <div nz-col nzSpan="12" style="display: flex;">
                        <strong>{{ 'relation.fields.name' | translate }}:</strong>&nbsp;
                        <div *ngIf="relation.salutation || relation.firstName || relation.lastName" style="display: flex;">
                            <div *ngIf="relation.salutation">
                                {{ ('common.enums.salutation.' + relation.salutation | translate) }}&nbsp;
                            </div>
                            {{ relation.firstName }}
                            <div *ngIf="relation.middleName">
                                &nbsp;{{ relation.middleName }}&nbsp;
                            </div>
                            {{ relation.lastName }}
                        </div>
                    </div>
                </div>
            </section>

            <section class="form-section">
                <header>
                    <h5 class="form-section__title">
                        {{ 'relation.header.address-information' | translate }}
                    </h5>
                </header>
                <div nz-row>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.address' | translate }}:</strong>&nbsp;
                        {{ relation.address }}
                    </div>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.zip-code' | translate }}:</strong>
                        {{ relation.zipCode }}
                    </div>

                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.city' | translate }}:</strong>
                        {{ relation.city }}
                    </div>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.country' | translate }}:</strong>
                        {{ relation.country.name }}
                    </div>
                </div>
            </section>

            <section class="form-section">
                <header>
                    <h5 class="form-section__title">
                        {{ 'relation.header.contact-information' | translate }}
                    </h5>
                </header>
                <div nz-row>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.phone-number-one' | translate }}:</strong>
                        +{{ relation.phone1CountryCode + relation.phone1 }}
                    </div>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.email' | translate }}:</strong>
                        <a [href]="'mailto:' + relation.email">
                            {{ relation.email }}
                        </a>
                    </div>

                    <div *ngIf="relation.phone2CountryCode && relation.phone2" nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.phone-number-two' | translate }}:</strong>
                        +{{ relation.phone2CountryCode + relation.phone2 }}
                    </div>
                </div>
            </section>

            <section class="form-section">
                <header>
                    <h5 class="form-section__title">
                        {{ 'relation.header.mailing-address' | translate }}
                    </h5>
                </header>
                <div nz-row>
                    <div nz-col nzSpan="12" style="display: flex; flex-direction: column;">
                        <ng-container *ngIf="relation.selectedBillingInformationId">
                            {{ billingInformationType[relation.billingInformationSendInvoiceTo] | translate }}<br>
                            <ng-container *ngIf="relation.billingInformationCompanyName">
                                {{ relation.billingInformationCompanyName }}<br>
                            </ng-container>
                            <ng-container *ngIf="relation.billingInformationName">
                                {{ (('common.regarding' | translate) + ' ' + relation.billingInformationName) }}<br>
                            </ng-container>
    
                            {{ relation.billingInformationAddress }}<br>
                            {{ relation.billingInformationZipCode + ', ' + relation.billingInformationCity}}<br>
                            {{ relation.country.name }}
                        </ng-container>
                        
                        <ng-container *ngIf="!relation.selectedBillingInformationId">
                            {{ 'relation.fields.personal-address' | translate }}<br>
                        </ng-container>
                    </div>
                </div>
            </section>

            <section class="form-section">
                <header>
                    <h5 class="form-section__title">
                        {{ 'relation.header.account-information' | translate }}
                    </h5>
                </header>
                <div nz-row>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.dietaryPreference' | translate }}:</strong>&nbsp;
                        {{ relation.dietaryPreference }}
                    </div>
                    <div nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.is-student' | translate }}:</strong>
                        {{ relation.isStudent ? ('relation.fields.yes' | translate) : ('relation.fields.no' | translate) }}
                    </div>

                    <div *ngIf="relation.phone2CountryCode && relation.phone2" nz-col nzSpan="12">
                        <strong>{{ 'relation.fields.was-student' | translate }}:</strong>
                        {{ relation.wasStudent ? ('relation.fields.yes' | translate) : ('relation.fields.no' | translate) }}
                    </div>
                </div>
            </section>
        </nz-tab>
    </nz-tabset>
</div>
