
<h1>{{ 'common.login' | translate }}</h1>
<form nz-form nzLayout="vertical" [formGroup]="form" (ngSubmit)="onLogin()">
	<nz-form-item>
		<nz-form-control [nzErrorTip]="EmailValidationTemplate">
			<nz-form-label nzFor="email">{{ 'common.email' | translate }}</nz-form-label>
			<input
				nz-input
				nzSize="large"
				type="email"
				id="email"
				[placeholder]="'placeholder.email' | translate"
				[formControl]="form.controls.email" 
			/>
			<ng-template #EmailValidationTemplate let-control>
				<app-input-validation [control]="control" [field]="'email'"></app-input-validation>
			</ng-template>
		</nz-form-control>
	</nz-form-item>

	<nz-form-item>
		<code-input 
			[isCodeHidden]="false"
			[codeLength]="pincodeLength"
			(codeChanged)="onCodeChanged($event)">
		</code-input>
	</nz-form-item>

	<nz-form-item>
		<button
			type="submit"
			nz-button
			nzBlock
			nzType="primary"
			class="success"
			nzSize="large"
			nzShape="round"
			[disabled]="loading || form.invalid">
			{{ 'common.login' | translate }}
			<i *ngIf="loading" class="fa-duotone fa-spinner-third fa-spin"></i>
		</button>
	</nz-form-item>
</form>