import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { BlogCreateModel, BlogUpdateModel } from '@app/modules/blogs/models';
import { AssetFormGroup } from '@app/shared/models/assetmanager';
import {
  ClaimTypes,
  ClaimValues,
  Constants,
} from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import {
  ContentBlockFormGroup,
  ContentBlockType,
} from '@app/shared/models/content';
import { ClaimService } from '@app/shared/services/claim.service';
import {
  isFormValid,
  processBackendValidators,
} from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { BehaviorSubject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-blog-detail-form',
  templateUrl: './blog-detail-form.component.html',
  styleUrls: ['./blog-detail-form.component.less'],
})
export class BlogDetailFormComponent implements OnInit, OnChanges {
  update = false;
  readonly?: boolean;

  @Input() loading = false;
  @Input() errors: { [key: string]: string[] } = {};
  @Input({ required: true }) blog: BlogUpdateModel | null = null;
  @Input({ required: true }) employees: KeyValueModel[] | null = [];
  @Input({ required: true }) tags: KeyValueModel[] | null = [];
  @Input({ required: true }) isLoading = false;

  @Output() submitted = new EventEmitter<BlogCreateModel | BlogUpdateModel>();
  @Output() searchTags = new EventEmitter<object>();

  availableContentTypes = [ContentBlockType.Editor];

  formGroup = new FormGroup(
    {
      contentFormGroup: new FormGroup({
        contentBlocks: new FormArray<ContentBlockFormGroup>(
          [],
          [CustomValidators.minArrayLength(1)]
        ),

        title: new FormControl<string>('', [
          CustomValidators.required,
          Validators.maxLength(100),
        ]),
        introduction: new FormControl<string>('', [CustomValidators.required]),
      }),
      seoFormGroup: new FormGroup({
        windowTitle: new FormControl<string>('', [
          CustomValidators.required,
          Validators.maxLength(200),
        ]),
        keywords: new FormControl<string>('', [
          CustomValidators.required,
          Validators.maxLength(100),
        ]),
        description: new FormControl<string>('', [
          CustomValidators.required,
          Validators.maxLength(200),
        ]),
        slug: new FormControl<string>('', [
          CustomValidators.required,
          Validators.maxLength(100),
          Validators.pattern(Constants.SlugExpression),
        ]),
      }),

      id: new FormControl<number | null>(null),
      online: new FormControl<boolean>(false),
      authorId: new FormControl<string | null>(null, [
        CustomValidators.required,
        Validators.maxLength(450),
      ]),
      publishDate: new FormControl<Date | null>(null),
      videoLink: new FormControl<string | null>(null, [
        Validators.pattern(Constants.RequiredProtocolUrlExpression),
        Validators.maxLength(100),
      ]),
      tags: new FormControl<(string | number)[]>([]),
      status: new FormControl<number | null>(null),
      createdOn: new FormControl<Date>(new Date()),
      asset: new AssetFormGroup(),
    },
    {
      validators: (control: AbstractControl): ValidationErrors | null => {
        const videoLink = control.get('videoLink')!;
        const asset = control.get('asset')!;

        const isValid =
          (videoLink.value !== '' &&
            videoLink.value !== null &&
            videoLink.value !== undefined) ||
          (asset.value.url !== '' &&
            asset.value.url !== null &&
            asset.value.url !== undefined);

        const eitherRequiredError: ValidationErrors = {
          ['eitherRequired']: { eitherRequired: true },
        };

        if (!isValid && !videoLink.errors) {
          videoLink.setErrors(eitherRequiredError);
        }

        return !isValid ? eitherRequiredError : null;
      },
    }
  );

  searchChange$ = new BehaviorSubject('');
  optionList: string[] = [];
  subscriptions$ = new Subscription();
  selectedList: string[] = [];
  tagPicker: NzSelectModeType = 'multiple';

  constructor(private readonly claimService: ClaimService) {}

  async ngOnInit() {
    this.update = !!this.blog;
    if (
      this.update &&
      !(await this.claimService.hasAny([
        { claimType: ClaimTypes.Blog, claimValues: [ClaimValues.Update] },
      ]))
    ) {
      this.formGroup.disable();
      this.readonly = true;
    } else {
      this.readonly = false;
    }

    if (
      await this.claimService.hasAny([
        { claimType: ClaimTypes.Tags, claimValues: [ClaimValues.Create] },
      ])
    ) {
      this.tagPicker = 'tags';
    }

    this.selectedList = this.blog?.tags ?? [];
    this.formGroup.patchValue({
      contentFormGroup: {
        title: this.blog?.title,
        introduction: this.blog?.introduction,
      },
      seoFormGroup: {
        windowTitle: this.blog?.windowTitle,
        keywords: this.blog?.keywords,
        description: this.blog?.description,
        slug: this.blog?.slug,
      },

      id: this.blog?.id,
      online: this.blog?.showOnline ?? false,
      authorId: this.blog?.authorId,
      publishDate: this.blog?.publishDate ?? null,
      videoLink: this.blog?.videoLink,
      tags: this.blog?.tags ?? [],
      createdOn: this.blog?.createdOn ?? new Date(),
      asset: {
        url: this.blog?.imagePath ?? '',
        name: this.blog?.imageName ?? '',
      },
    });

    (this.blog?.contentBlocks ?? []).forEach((contentBlock) =>
      this.formGroup.controls.contentFormGroup.controls.contentBlocks.push(
        new ContentBlockFormGroup(contentBlock)
      )
    );

    if (this.update) {
      this.formGroup.updateValueAndValidity();
    }

    const subscriptions = new Subscription();

    subscriptions.add(
      this.searchChange$.pipe(debounceTime(500)).subscribe((value) => {
        this.searchTags.emit({
          searchQuery: value,
          selectedTags: this.formGroup.value.tags!,
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    processBackendValidators(changes, this.formGroup.controls.contentFormGroup);
    processBackendValidators(changes, this.formGroup.controls.seoFormGroup);
    processBackendValidators(changes, this.formGroup);
  }

  submit() {
    if (isFormValid(this.formGroup)) {
      this.update ? this.submitUpdate() : this.submitCreate();
    }
  }

  onTagSearch(value: string): void {
    this.searchChange$.next(value);
  }

  isNotSelected(value: string | number): boolean {
    return this.formGroup.value.tags!.indexOf(value) === -1;
  }

  private submitCreate() {
    const blogDetails = {
      showOnline: this.formGroup.controls.online.value,
      authorId: this.formGroup.controls.authorId.value,
      title: this.formGroup.controls.contentFormGroup.controls.title.value,
      introduction:
        this.formGroup.controls.contentFormGroup.controls.introduction.value,
      description:
        this.formGroup.controls.seoFormGroup.controls.description.value,
      slug: this.formGroup.controls.seoFormGroup.controls.slug.value,
      windowTitle:
        this.formGroup.controls.seoFormGroup.controls.windowTitle.value,
      publishDate: this.formGroup.controls.publishDate.value ?? null,
      keywords:
        this.formGroup.controls.seoFormGroup.controls.keywords.value ?? null,
      tagIds:
        this.formGroup.controls.tags.value?.filter(
          (tag) => typeof tag === 'number'
        ) ?? [],
      imageName:
        this.formGroup.controls.asset.value.name == ''
          ? null
          : this.formGroup.controls.asset.value.name,
      imagePath:
        this.formGroup.controls.asset.value.url == ''
          ? null
          : this.formGroup.controls.asset.value.url,
      videoLink:
        this.formGroup.controls.videoLink.value == ''
          ? null
          : this.formGroup.controls.videoLink.value,
      contentBlocks:
        this.formGroup.controls.contentFormGroup.controls.contentBlocks.value ??
        null,
      tags: this.formGroup.controls.tags.value ?? null,
    } as BlogCreateModel;
    this.submitted.emit(blogDetails);
  }

  private submitUpdate() {
    const blogDetails = {
      id: this.formGroup.controls.id.value,
      showOnline: this.formGroup.controls.online.value,
      authorId: this.formGroup.controls.authorId.value,
      introduction:
        this.formGroup.controls.contentFormGroup.controls.introduction.value,
      title: this.formGroup.controls.contentFormGroup.controls.title.value,
      description:
        this.formGroup.controls.seoFormGroup.controls.description.value,
      slug: this.formGroup.controls.seoFormGroup.controls.slug.value,
      windowTitle:
        this.formGroup.controls.seoFormGroup.controls.windowTitle.value,
      publishDate: this.formGroup.controls.publishDate.value ?? null,
      keywords:
        this.formGroup.controls.seoFormGroup.controls.keywords.value ?? null,
      tagIds: this.formGroup.controls.tags.value!.filter(
        (tag) => typeof tag === 'number'
      ),
      imageName:
        this.formGroup.controls.asset.value.name == ''
          ? null
          : this.formGroup.controls.asset.value.name,
      imagePath:
        this.formGroup.controls.asset.value.url == ''
          ? null
          : this.formGroup.controls.asset.value.url,
      videoLink:
        this.formGroup.controls.videoLink.value == ''
          ? null
          : this.formGroup.controls.videoLink.value,
      contentBlocks:
        this.formGroup.controls.contentFormGroup.controls.contentBlocks.value ??
        null,
      tags: this.formGroup.controls.tags.value ?? null,
    } as BlogUpdateModel;
    this.submitted.emit(blogDetails);
  }
}
