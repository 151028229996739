<div class="page-header">
	<h1 class="page-header__title">{{ 'discount-code.title' | translate }}</h1>
	<div class="page-header__actions">
		<a
			claimDirective
			[claims]="[
				{
					claimType: ClaimTypes.DiscountCode,
					claimValues: [ClaimValues.Create]
				}
			]"
			routerLink="/kortingscodes/toevoegen"
			nz-button
			nzType="primary">
			{{ 'common.add' | translate }}
			<i class="fa-regular fa-plus"></i>
		</a>
	</div>
</div>

<app-discount-code-overview-table
	[data]="(tableData$ | async)!"
	(delete)="onDeleteConfirm($event)"
	(queryParamsChange)="onQueryParamsChange($event)"
	(changeActive)="onActiveChange($event)"
	(resetOverview)="onResetOverview()" />
