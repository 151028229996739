import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as ActivityScheduleActions from '@app/mobile/modules/activity-schedule/actions/activity-schedule.actions';
import { ActivityScheduleService } from '@app/mobile/modules/activity-schedule/services/activity-schedule.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class ActivityScheduleEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly activityScheduleService: ActivityScheduleService,
    private readonly messageService: NzMessageService,
    private readonly translate: TranslateService,
    private readonly router: Router
  ) {}

  /**
   * Get effects
   */
  getList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityScheduleActions.GetList),
      exhaustMap(() =>
        this.activityScheduleService.getActivityScheduleList().pipe(
          map((response) =>
            ActivityScheduleActions.GetListSuccess({
              list: response,
            })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityScheduleActions.GetListError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityScheduleActions.Get),
      exhaustMap((action) =>
        this.activityScheduleService.getActivitySchedule(action.id).pipe(
          map((response) => ActivityScheduleActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityScheduleActions.GetError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  activityScheduleErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ActivityScheduleActions.GetListError,
          ActivityScheduleActions.GetError,
          ActivityScheduleActions.UpdateError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityScheduleActions.Update),
      exhaustMap((action) =>
        this.activityScheduleService.update(action).pipe(
          map(() => ActivityScheduleActions.UpdateSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityScheduleActions.UpdateError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityScheduleActions.UpdateSuccess),
      tap(() => {
        this.router.navigate(['/mobiel/activiteiten/overzicht']);
        this.messageService.success(
          this.translate.instant('activity-schedule.update-success')
        );
      })
    ),
    { dispatch: false }
  );
}
