import * as NewsActions from "@app/modules/news/actions/news-api.actions"
import { NewsUpdateModel } from "@app/modules/news/models";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    news: null,
    loading: false,
    errors: {}
}

export interface State {
    news: NewsUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(NewsActions.Clear, () => ({ ...initialState })),

    on(NewsActions.Get, (state) => ({ ...state, loading: true })),
    on(NewsActions.GetSuccess, (state, news) => ({ ...state, news, loading: false })),
    on(NewsActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(NewsActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(NewsActions.UpdateSuccess, (state, news) => ({ ...state, news, loading: false })),
    on(NewsActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(NewsActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(NewsActions.CreateSuccess, (state, news) => ({ ...state, news, loading: false })),
    on(NewsActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: State) => state.loading;
export const getErrors = (state: State) => state.errors;
export const getNews = (state: State) => state.news;