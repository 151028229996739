<div class="sidebar-logo">
	<img src="./assets/images/logo-ivsw.svg" alt="Logo IVSW" />
</div>

<nav class="nav">
	<ul class="nav-list">
		<li class="nav-item" *ngFor="let item of navList" routerLinkActive="active">
			@if(item.subNav) {
			<div
				class="nav-link"
				(click)="openMenu($event)"
				claimDirective
				[claims]="item.claims ?? []"
			>
				<div class="nav-link__icon"><i class="fa-regular" [ngClass]="item.icon"></i></div>
				<div class="nav-link__label">{{ item.label | translate }}</div>
				<div class="nav-link__icon nav-link__toggler"><i class="fa-regular fa-chevron-down"></i></div>
			</div>
			<ul class="sub-nav-list">
				<li
					class="sub-nav-item"
					*ngFor="let child of item.subNav"
					claimDirective
					[claims]="child.claims">
					<a
						[routerLink]="child.route"
						routerLinkActive="active"
						class="sub-nav-link"
						>{{ child.label | translate }}</a
					>
					@if(child.subNav) {
					<ul class="sub-nav-list">
						<li
							class="sub-nav-item"
							*ngFor="let subSubNav of child.subNav"
							claimDirective
							[claims]="subSubNav.claims">
							<a
								[routerLink]="subSubNav.route"
								routerLinkActive="active"
								class="sub-nav-link sub-sub-nav-link"
								>{{ subSubNav.label | translate }}</a
							>
						</li>
					</ul>
					}
				</li>
			</ul>
			} @else {
			<a [routerLink]="item.route" class="nav-link">
				<div class="nav-link__icon"><i class="fa-regular" [ngClass]="item.icon"></i></div>
				<div class="nav-link__label">{{ item.label | translate }}</div>
			</a>
			}
		</li>
	</ul>
</nav>

<nav class="nav bottom">
	<ul class="nav-list">
		<li class="nav-item" routerLinkActive="active">
			<a routerLink="./demo" class="nav-link">
				<div class="nav-link__icon"><i class="fa-regular fa-wand-magic-sparkles"></i></div>
				<div class="nav-link__label">Demo</div>
			</a>
		</li>
		<li class="nav-item">
			<a class="nav-link">
				<div class="nav-link__icon"><i class="fa-regular fa-user"></i></div>
				<div class="nav-link__label">{{ (user$ | async)?.fullName }}</div>
				<div class="nav-link__icon" nz-dropdown [nzDropdownMenu]="userMenu"><i class="fa-regular fa-ellipsis"></i></div>
			</a>
		</li>
	</ul>
</nav>

<nz-dropdown-menu #userMenu="nzDropdownMenu">
	<ul nz-menu class="sidebar-dropdown-menu"  (click)="logout()">
		<li nz-menu-item>
			<span>{{ 'common.logout' | translate }}</span>
		</li>
	</ul>
</nz-dropdown-menu>
