import * as BlogReactionApiActions from '@app/modules/blog-reaction/actions/blog-reaction-api.actions';
import { BlogReactionTableItemModel } from '@app/modules/blog-reaction/models/blog-reaction-table.model';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
  ...new TableOverviewModel<BlogReactionTableItemModel>('Name'),
  blogId: null,
};
export interface State extends TableStateModel<BlogReactionTableItemModel> {
  blogId: number | null;
}

export const reducer = createReducer(
  initialState,
  on(BlogReactionApiActions.GetOverview, (state) => ({
    ...state,
    loading: true,
  })),
  on(BlogReactionApiActions.GetOverviewSuccess, (state, response) => ({
    ...state,
    ...response,
    loading: false,
  })),
  on(BlogReactionApiActions.GetOverviewError, (state) => ({
    ...state,
    loading: false,
  })),
  on(
    BlogReactionApiActions.FilterOverview,
    (state, { sorting, pagination, blogId, filter }) => ({
      ...state,
      sorting: applySortingOrDefault(initialState, sorting),
      pagination: {
        ...state.pagination,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
      blogId: blogId,
      filter: filter ?? [],
    })
  ),
  on(BlogReactionApiActions.SetFilter, (state, { filter }) => ({
    ...state,
    filter: filter,
  }))
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
export const getBlogId = (state: State) => state.blogId;
export const getFilter = (state: State) => state.filter;
