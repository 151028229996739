import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ActivityPlanningStatus,
    ActivityPlanningStatusLabelMapping,
} from '@app/modules/activities/enums/activity-planning-status.enum';
import { ActivityStatus, ActivityStatusLabelMapping } from '@app/modules/activities/enums/activity-status.enum';
import { ActivityCreateModel, ActivityUpdateModel } from '@app/modules/activities/models';
import { DatePickerType } from '@app/modules/activities/models/activities-create.model';
import { ActivityAsset } from '@app/modules/activities/models/activity-asset.model';
import { ActivityGenerateCode } from '@app/modules/activities/models/activity-generate-code.model';
import { ActivityTemplateCreateModel } from '@app/modules/activity-template/models';
import { ActivityTypeCodeModel } from '@app/modules/activity-type/models/activity-type-code.model';
import { AssetFormGroup } from '@app/shared/models/assetmanager';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-activities-detail-form',
    templateUrl: './activities-detail-form.component.html',
})
export class ActivityDetailFormComponent implements OnInit, OnChanges, OnDestroy {
    update = false;
    readonly?: boolean;
    maxValidatorLength = 999999;
    canGenerateCode = true;
    dateFormat = 'dd-MM-yyyy';
    timeDateFormat = 'YYYY-MM-dd';

    statusMapping = ActivityStatusLabelMapping;
    activityStatus = ActivityStatus;

    planningStatusMapping = ActivityPlanningStatusLabelMapping;
    planningActivityStatus = ActivityPlanningStatus;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) activity: ActivityUpdateModel | null = null;
    @Input({ required: true }) activityTypes: ActivityTypeCodeModel[] | null = null;
    @Input({ required: true }) activityLocations: KeyValueModel[] | null = null;
    @Input({ required: true }) activityTemplate: ActivityTemplateCreateModel | null = null;
    @Input({ required: true }) activityCode: string | null = null;
    @Input({ required: true }) code: string | null = null;
    @Output() submitted = new EventEmitter<ActivityCreateModel | ActivityUpdateModel>();
    @Output() checkCode = new EventEmitter<ActivityGenerateCode>();
    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        activityTypeId: new FormControl<number>(0, CustomValidators.required),
        name: new FormControl<string>('', [Validators.maxLength(100)]),
        exactCode: new FormControl<string>('', [Validators.maxLength(100)]),
        displayPrices: new FormControl<boolean>(false),
        priceIncludingVat: new FormControl<number>(0, [
            CustomValidators.correctAmountDecimal(2),
            Validators.min(0),
            Validators.max(this.maxValidatorLength),
        ]),
        priceSecondIncludingVat: new FormControl<number>(0, [
            CustomValidators.correctAmountDecimal(2),
            Validators.min(0),
            Validators.max(this.maxValidatorLength),
        ]),
        vatPercentage: new FormControl<number>(0, [Validators.min(0), Validators.max(100)]),
        includesLunch: new FormControl<boolean>(false),
        themeName: new FormControl<string>('', Validators.maxLength(100)),
        themeCode: new FormControl<string>('', Validators.maxLength(50)),
        themeDescription: new FormControl<string>(''),
        minimumParticipants: new FormControl<number>(0, [Validators.min(0), Validators.max(1000)]),
        maximumParticipants: new FormControl<number>(0, [Validators.max(1000)]),
        availableStudent: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        availableStudentSecondTime: new FormControl<number>(0, [
            Validators.min(0),
            Validators.max(this.maxValidatorLength),
        ]),
        availableGuest: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        availableCatchUp: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        waitlistStudent: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        waitlistStudentSecondTime: new FormControl<number>(0, [
            Validators.min(0),
            Validators.max(this.maxValidatorLength),
        ]),
        waitlistGuest: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        waitlistCatchUp: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),

        code: new FormControl<string | null>({ value: this.code, disabled: true }),
        status: new FormControl<ActivityStatus>(ActivityStatus.Active),
        planningStatus: new FormControl<ActivityPlanningStatus>(ActivityPlanningStatus.InAssignment),
        location: new FormControl<number | null>(null),

        evaluationDate: new FormControl<DatePickerType>(null),

        cancelledDate: new FormControl<DatePickerType>(null),
        reviewDate: new FormControl<DatePickerType>(null),

        visibility: new FormControl<DatePickerType[] | null>(null),
        activityDate: new FormControl<DatePickerType[] | null>(null),

        online: new FormControl<boolean>(true),
        startTime: new FormControl<DatePickerType>(null),
        endTime: new FormControl<DatePickerType>(null),

        attachmentOne: new AssetFormGroup(),
        attachmentTwo: new AssetFormGroup(),
    });

    minimumParticipantsSubscription$ = new Subscription();
    startTimeSubscription$ = new Subscription();

    constructor(private readonly claimService: ClaimService) {}
    ngOnDestroy() {
        this.minimumParticipantsSubscription$.unsubscribe();
        this.startTimeSubscription$.unsubscribe();
    }

    async ngOnInit() {
        this.update = !!this.activity;

        this.formGroup.controls.maximumParticipants.addValidators(
            CustomValidators.greaterOrEqualToOtherField('minimumParticipants'),
        );

        this.formGroup.controls.endTime.addValidators(CustomValidators.greaterOrEqualToOtherField('startTime'));

        this.minimumParticipantsSubscription$ = this.formGroup.controls.minimumParticipants.valueChanges.subscribe({
            next: () => {
                this.formGroup.controls.maximumParticipants.markAsTouched();
                this.formGroup.controls.maximumParticipants.updateValueAndValidity();
            },
        });

        this.startTimeSubscription$ = this.formGroup.controls.startTime.valueChanges.subscribe({
            next: () => {
                this.formGroup.controls.endTime.markAsTouched();
                this.formGroup.controls.endTime.updateValueAndValidity();
            },
        });

        if (
            this.update &&
            !(await this.claimService.hasAny([{ claimType: ClaimTypes.Activity, claimValues: [ClaimValues.Update] }]))
        ) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }
        let activityProperties;
        if (this.update) {
            activityProperties = this.activity;
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.activity as ActivityUpdateModel).id);
        } else {
            activityProperties = this.activityTemplate as ActivityUpdateModel;
        }
        this.formGroup.patchValue({
            activityTypeId: activityProperties?.activityTypeId,
            name: activityProperties?.name,
            exactCode: activityProperties?.exactCode,
            displayPrices: activityProperties?.displayPrices,
            priceIncludingVat: activityProperties?.priceIncludingVat,
            priceSecondIncludingVat: activityProperties?.priceSecondIncludingVat,
            includesLunch: activityProperties?.includesLunch,
            themeName: activityProperties?.themeName,
            themeCode: activityProperties?.themeCode,
            themeDescription: activityProperties?.themeDescription,
            minimumParticipants: activityProperties?.minimumParticipants,
            maximumParticipants: activityProperties?.maximumParticipants,
            availableStudent: activityProperties?.availableStudent,
            availableStudentSecondTime: activityProperties?.availableStudentSecondTime,
            availableGuest: activityProperties?.availableGuest,
            availableCatchUp: activityProperties?.availableCatchUp,
            waitlistStudent: activityProperties?.waitlistStudent,
            waitlistStudentSecondTime: activityProperties?.waitlistStudentSecondTime,
            waitlistGuest: activityProperties?.waitlistGuest,
            waitlistCatchUp: activityProperties?.waitlistCatchUp,
            vatPercentage: activityProperties?.vatPercentage ?? 0,
            code: activityProperties?.code ?? '',
            location: activityProperties?.activityLocationId,
            status: activityProperties?.status ?? 1,
            planningStatus: activityProperties?.planningStatus ?? 1,
            activityDate: activityProperties?.startDate
                ? [activityProperties?.startDate, activityProperties?.endDate]
                : null,

            evaluationDate: activityProperties?.evaluationDate,
            cancelledDate: activityProperties?.cancelledDate,
            reviewDate: activityProperties?.reviewDate,
            visibility: activityProperties?.visibleFromDate
                ? [activityProperties?.visibleFromDate ?? null, activityProperties?.visibleUntilDate ?? null]
                : null,

            startTime: this.parseTimeToDate(activityProperties?.startTime),
            endTime: this.parseTimeToDate(activityProperties?.endTime),
            attachmentOne: activityProperties?.firstAttachment,
            attachmentTwo: activityProperties?.secondAttachment,
        });
        this.typeChange(this.activityTypes?.find((x) => x.id === this.formGroup.controls.activityTypeId.value));
    }

    private parseTimeToDate(timeString: Date | null | undefined): DatePickerType {
        return timeString
            ? new Date(`${formatDate(new Date(), this.timeDateFormat, 'nl-NL')} ${timeString}`)
            : null;
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
        this.formGroup.controls.maximumParticipants.updateValueAndValidity();
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            name: this.formGroup.controls.name.value ?? '',
            activityTypeId: this.formGroup.controls.activityTypeId.value ?? 0,
            exactCode: this.formGroup.controls.exactCode.value ?? null,
            vatPercentage: this.formGroup.controls.vatPercentage.value ?? 0,
            minimumParticipants: this.formGroup.controls.minimumParticipants.value ?? null,
            maximumParticipants: this.formGroup.controls.maximumParticipants.value ?? null,
            displayPrices: this.formGroup.controls.displayPrices.value ?? false,
            priceIncludingVat: this.formGroup.controls.priceIncludingVat.value ?? null,
            priceSecondIncludingVat: this.formGroup.controls.priceSecondIncludingVat.value ?? null,
            includesLunch: this.formGroup.controls.includesLunch.value ?? false,
            themeName: this.formGroup.controls.themeName.value ?? '',
            themeCode: this.formGroup.controls.themeCode.value ?? '',
            themeDescription: this.formGroup.controls.themeDescription.value ?? '',
            availableStudent: this.formGroup.controls.availableStudent.value ?? null,
            waitlistStudent: this.formGroup.controls.waitlistStudent.value ?? null,
            availableStudentSecondTime: this.formGroup.controls.availableStudentSecondTime.value ?? null,
            waitlistStudentSecondTime: this.formGroup.controls.waitlistStudentSecondTime.value ?? null,
            availableCatchUp: this.formGroup.controls.availableCatchUp.value ?? null,
            waitlistCatchUp: this.formGroup.controls.waitlistCatchUp.value ?? null,
            availableGuest: this.formGroup.controls.availableGuest.value ?? null,
            waitlistGuest: this.formGroup.controls.waitlistGuest.value ?? null,
            code: this.code ?? null,
            status: this.formGroup.controls.status.value ?? ActivityStatus.Active,
            planningStatus: this.formGroup.controls.planningStatus.value ?? ActivityPlanningStatus.InAssignment,
            activityLocationId: this.formGroup.controls.location.value ?? null,

            startDate: this.formGroup.controls.activityDate?.value
                ? this.formGroup.controls.activityDate.value[0]
                : null,
            endDate: this.formGroup.controls.activityDate?.value ? this.formGroup.controls.activityDate.value[1] : null,

            evaluationDate:
                this.formGroup.controls.evaluationDate.value! &&
                this.activityTypes?.find((x) => x.id === this.formGroup.controls.activityTypeId.value)
                    ? this.formGroup.controls.evaluationDate.value
                    : null,
            cancelledDate: this.formGroup.controls.cancelledDate.value ?? null,
            reviewDate: this.formGroup.controls.reviewDate.value ?? null,

            visibleFromDate: this.formGroup.controls.visibility?.value
                ? this.formGroup.controls.visibility.value[0]
                : null,
            visibleUntilDate: this.formGroup.controls.visibility?.value
                ? this.formGroup.controls.visibility.value[1]
                : null,

            online: this.formGroup.controls.online.value ?? true,
            startTime: this.formGroup.controls.startTime.value ? this.formGroup.controls.startTime.value : null,
            endTime: this.formGroup.controls.endTime.value ? this.formGroup.controls.endTime.value : null,
            firstAttachment:
                this.formGroup.controls.attachmentOne.value.url !== ''
                    ? (this.formGroup.controls.attachmentOne.value as ActivityAsset)
                    : null,
            secondAttachment:
                this.formGroup.controls.attachmentTwo.value.url !== ''
                    ? (this.formGroup.controls.attachmentTwo.value as ActivityAsset)
                    : null,
        });
    }

    typeChange(chosenType: any) {
        if (this.activityTypes?.find((x) => x.id === chosenType)?.hasEvalutationForm) {
            this.formGroup.controls.evaluationDate.enable();
        } else {
            this.formGroup.controls.evaluationDate.disable();
        }
    }

    cancelledDateChange() {
        if (
            this.formGroup.controls.cancelledDate.value == null ||
            this.formGroup.controls.cancelledDate.value === undefined
        ) {
            this.formGroup.controls.status.enable();
        } else {
            this.formGroup.controls.status.setValue(2);
            this.formGroup.controls.status.disable();
        }
    }

    generateCode() {
        this.canGenerateCode = true;
        if (this.formGroup.controls.location.value == null) {
            this.formGroup.controls.location.markAsTouched();
            this.formGroup.controls.location.setErrors({ requiredFor: true, error: true });
            this.canGenerateCode = false;
        }

        if (this.formGroup.controls.activityDate.value === undefined) {
            this.formGroup.controls.activityDate.markAsTouched();
            this.formGroup.controls.activityDate.setErrors({ requiredFor: true, error: true });
            this.canGenerateCode = false;
        }
        if (this.formGroup.controls.activityTypeId.value == null) {
            this.formGroup.controls.activityTypeId.markAsTouched();
            this.formGroup.controls.activityTypeId.setErrors({ requiredFor: true, error: true });
            this.canGenerateCode = false;
        }
        if (this.canGenerateCode) {
            this.checkCode.emit({
                activityTypeId: this.formGroup.controls.activityTypeId.value!,
                date: this.formGroup.controls.activityDate.value![0],
                locationId: this.formGroup.controls.location.value!,
            } as ActivityGenerateCode);
        }
    }

    private submitUpdate() {
        this.submitted.emit({
            id: this.formGroup.controls.id.value!,
            name: this.formGroup.controls.name.value ?? '',
            activityTypeId: this.formGroup.controls.activityTypeId.value ?? 0,
            exactCode: this.formGroup.controls.exactCode.value ?? null,
            vatPercentage: this.formGroup.controls.vatPercentage.value ?? 0,
            minimumParticipants: this.formGroup.controls.minimumParticipants.value ?? null,
            maximumParticipants: this.formGroup.controls.maximumParticipants.value ?? null,
            displayPrices: this.formGroup.controls.displayPrices.value ?? false,
            priceIncludingVat: this.formGroup.controls.priceIncludingVat.value ?? null,
            priceSecondIncludingVat: this.formGroup.controls.priceSecondIncludingVat.value ?? null,
            includesLunch: this.formGroup.controls.includesLunch.value ?? false,
            themeName: this.formGroup.controls.themeName.value ?? '',
            themeCode: this.formGroup.controls.themeCode.value ?? '',
            themeDescription: this.formGroup.controls.themeDescription.value ?? '',
            availableStudent: this.formGroup.controls.availableStudent.value ?? null,
            waitlistStudent: this.formGroup.controls.waitlistStudent.value ?? null,
            availableStudentSecondTime: this.formGroup.controls.availableStudentSecondTime.value ?? null,
            waitlistStudentSecondTime: this.formGroup.controls.waitlistStudentSecondTime.value ?? null,
            availableCatchUp: this.formGroup.controls.availableCatchUp.value ?? null,
            waitlistCatchUp: this.formGroup.controls.waitlistCatchUp.value ?? null,
            availableGuest: this.formGroup.controls.availableGuest.value ?? null,
            waitlistGuest: this.formGroup.controls.waitlistGuest.value ?? null,
            code: this.code ?? null,
            status: this.formGroup.controls.status.value ?? ActivityStatus.Active,
            planningStatus: this.formGroup.controls.planningStatus.value ?? ActivityPlanningStatus.InAssignment,
            activityLocationId: this.formGroup.controls.location.value ?? null,

            startDate: this.formGroup.controls.activityDate?.value
                ? this.formGroup.controls.activityDate.value[0]
                : null,
            endDate: this.formGroup.controls.activityDate?.value ? this.formGroup.controls.activityDate.value[1] : null,

            evaluationDate:
                this.formGroup.controls.evaluationDate.value! &&
                this.activityTypes?.find((x) => x.id === this.formGroup.controls.activityTypeId.value)
                    ? this.formGroup.controls.evaluationDate.value
                    : null,
            cancelledDate: this.formGroup.controls.cancelledDate.value ?? null,
            reviewDate: this.formGroup.controls.reviewDate.value ?? null,

            visibleFromDate: this.formGroup.controls.visibility?.value
                ? this.formGroup.controls.visibility.value[0]
                : null,
            visibleUntilDate: this.formGroup.controls.visibility?.value
                ? this.formGroup.controls.visibility.value[1]
                : null,

            online: this.formGroup.controls.online.value ?? true,
            startTime: this.formGroup.controls.startTime.value,
            endTime: this.formGroup.controls.endTime.value,
            firstAttachment:
                this.formGroup.controls.attachmentOne.value.url !== null &&
                this.formGroup.controls.attachmentOne.value.url !== ''
                    ? (this.formGroup.controls.attachmentOne.value as ActivityAsset)
                    : null,
            secondAttachment:
                this.formGroup.controls.attachmentTwo.value.url !== null &&
                this.formGroup.controls.attachmentTwo.value.url !== ''
                    ? (this.formGroup.controls.attachmentTwo.value as ActivityAsset)
                    : null,
        });
    }
}
