import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ReviewService } from '@app/modules/review/services/review.service';
import { catchError, map, tap } from 'rxjs/operators';
import * as ReviewApiActions from '@app/modules/review/actions/review-api.actions';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';

export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const reviewService = inject(ReviewService);
    const router = inject(Router);
    const store = inject(Store<State>);

    return reviewService.get(route.params['id']).pipe(
        map(review => ReviewApiActions.GetSuccess(review)),
        tap((action: any) => store.dispatch(action)),
        map(review => !!review),
        catchError(() => {
            return router.navigate(['/not-found']);
        })
    );
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);