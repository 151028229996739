export class EditorContentBlockModel {
    editor: string;

    constructor(content: string) {
        const { editor } = content !== ''
            ? JSON.parse(content)
            : { editor: '' };

        this.editor = editor;
    }
}