import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as ActivityRelationOverview from './activity-relation-overview.reducer'

export const activityRelationFeatureKey = 'activityRelation'

export interface ActivityRelationState {
    activityRelationOverview: ActivityRelationOverview.State
}

export interface State {
    activityRelation: ActivityRelationState
}

const map: ActionReducerMap<ActivityRelationState, Action> = {
    activityRelationOverview: ActivityRelationOverview.reducer
}

export const reducers = (state: ActivityRelationState, action: Action) => combineReducers(map)(state, action);

const selectActivityRelationState = createFeatureSelector<ActivityRelationState>(activityRelationFeatureKey);

export const selectState = createSelector(selectActivityRelationState, state => state)
export const selectActivityRelationOverviewState = createSelector(selectState, state => state.activityRelationOverview)

export const selectActivityRelationOverviewStateList = createSelector(selectState, state => state.activityRelationOverview.relations)
export const selectActivityRelationWaitlistOverviewStateList = createSelector(selectState, state => state.activityRelationOverview.waitlistRelations)


export const selectActivityRelationOverviewList = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getList)
export const selectActivityOverviewPagination = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getPagination)
export const selectActivityRelationOverviewSorting = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getSorting)
export const selectChosenActivityId = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getActivityId)
export const selectActivityRelationOverviewSortingThen = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getSortingThen)
export const selectActivityRelationOverviewFilter= createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getFilter)

export const selectActivityRelationWaitlistOverviewList = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getWaitlistList)
export const selectActivityWaitlistOverviewPagination = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getWaitlistPagination)
export const selectActivityRelationWaitlistOverviewSorting = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getWaitlistSorting)
export const selectActivityRelationWaitlistOverviewSortingThen = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getWaitlistSortingThen)
export const selectActivityRelationWaitlistOverviewFilter= createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getWaitlistFilter)

export const selectBackgroundList = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getBackgroundList)
export const selectActivityRelation = createSelector(selectActivityRelationOverviewState, ActivityRelationOverview.getActivityRelation)
