<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>{{ (!!formGroup.get('id')?.value ? "reviews.detail.edit-title" : "reviews.detail.add-title") | translate }}</h4>

        <app-select-single
            [placeholder]="'reviews.detail.activity-type' | translate"
            [control]="formGroup.controls.activityTypeId"
            label="{{ 'reviews.detail.activity-type' | translate }}"
            field="activityTypeId"
            [errors]="errors"
            [loading]="loading"
            [items]="activityTypes">
        </app-select-single>
        
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "common.name" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="nameValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.name" />

                <ng-template #nameValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'name'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
			<nz-form-label [nzSpan]="24">{{ 'reviews.detail.online' | translate }}</nz-form-label>
			<label nz-checkbox [formControl]="formGroup.controls.online"></label>
		</nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ 'reviews.detail.creation-date' | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="creationDateValidationTemplate"> 
                <nz-date-picker [formControl]="formGroup!.controls.creationDate"></nz-date-picker>

                <ng-template #creationDateValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'creationDate'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ 'reviews.detail.function' | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="functionValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.function" />

                <ng-template #functionValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'function'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ 'reviews.detail.short-text' | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="shortTextValidationTemplate">
                <textarea nz-input [formControl]="formGroup.controls.shortText" ></textarea>

                <ng-template #shortTextValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'shortText'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "reviews.detail.long-text" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <app-editor *ngIf="readonly !== undefined" [control]="formGroup.controls.longText" [isDraggable]="false" [isReadOnly]="readonly"></app-editor>

                <app-input-validation [control]="formGroup.controls.longText" [field]="'longText'" [errors]="errors"></app-input-validation>
            </nz-form-control>
        </nz-form-item>
        <div class="button-container">
            <button nz-button nzType="primary" (click)="submit()">
                {{ "common.save" | translate }}
            </button>
        </div>
    </nz-card>

</form>