import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  RedirectCreateModel,
  RedirectUpdateModel,
} from '@app/modules/redirects/models';
import {
  ClaimTypes,
  ClaimValues,
  Constants,
} from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import {
  isFormValid,
  processBackendValidators,
} from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-redirect-detail-form',
  templateUrl: './redirect-detail-form.component.html',
})
export class RedirectDetailFormComponent implements OnInit, OnChanges {
  update = false;
  readonly?: boolean;

  @Input() loading = false;
  @Input() errors: { [key: string]: string[] } = {};
  @Input({ required: true }) redirect: RedirectUpdateModel | null = null;
  @Input({ required: true }) slugExists$!: Observable<boolean>;

  @Output() resetSlugExists = new EventEmitter<void>();
  @Output() submitted = new EventEmitter<
    RedirectCreateModel | RedirectUpdateModel
  >();
  @Output() modalSubmitted = new EventEmitter<
    RedirectCreateModel | RedirectUpdateModel
  >();

  formGroup = new FormGroup(
    {
      id: new FormControl<number | null>(null),
      from: new FormControl<string>('', [
        CustomValidators.required,
        Validators.maxLength(300),
        Validators.pattern(Constants.SlugPathLenientExpression),
      ]),
      to: new FormControl<string>('', [
        CustomValidators.required,
        Validators.maxLength(300),
        Validators.pattern(Constants.SlugPathExpression),
      ]),
    },
    {
      validators: (control: AbstractControl): ValidationErrors | null => {
        const fromSlug = control.get('from')!;
        const toSlug = control.get('to')!;

        const isValid = fromSlug.value != toSlug.value;

        const duplicateError: ValidationErrors = {
          ['duplicate']: { duplicate: true },
        };

        if (isValid) {
          return null;
        }

        toSlug.setErrors(duplicateError);
        return duplicateError;
      },
    }
  );

  constructor(
    private readonly claimService: ClaimService,
    private readonly modalService: NzModalService,
    private readonly translate: TranslateService
  ) {}

  destoryRef = inject(DestroyRef);

  async ngOnInit() {
    this.update = !!this.redirect;
    this.slugExists$
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe((status) => {
        this.createModal(status);
      });

    if (
      this.update &&
      !(await this.claimService.hasAny([
        { claimType: ClaimTypes.Redirect, claimValues: [ClaimValues.Update] },
      ]))
    ) {
      this.formGroup.disable();
      this.readonly = true;
    } else {
      this.readonly = false;
    }

    this.formGroup.patchValue({
      from: this.redirect?.from,
      to: this.redirect?.to,
    });

    if (this.update) {
      this.formGroup.controls.id.setValidators([CustomValidators.required]);
      this.formGroup.controls.id.setValue(
        (this.redirect as RedirectUpdateModel).id
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    processBackendValidators(changes, this.formGroup);
  }

  private createModal(slugExists: boolean) {
    if (!slugExists) {
      return;
    }
    this.modalService.info({
      nzTitle: this.translate.instant('redirects.modal.slug-exists-title'),
      nzContent: this.translate.instant('redirects.modal.slug-exists-body'),
      nzBodyStyle: { 'white-space': 'pre-wrap' },
      nzOkText: this.translate.instant('common.add'),
      nzCancelText: this.translate.instant('common.cancel'),
      nzOnOk: () => {
        this.update
          ? this.submitUpdate(this.modalSubmitted)
          : this.submitCreate(this.modalSubmitted);
        this.resetSlugExists.emit();
      },
      nzOnCancel: () => {
        this.resetSlugExists.emit();
      },
    });
  }

  submit() {
    if (isFormValid(this.formGroup)) {
      this.update
        ? this.submitUpdate(this.submitted)
        : this.submitCreate(this.submitted);
    }
  }

  private submitCreate(
    typeSubmit: EventEmitter<RedirectCreateModel | RedirectUpdateModel>
  ) {
    typeSubmit.emit({
      from: this.formGroup.controls.from.value ?? '',
      to: this.formGroup.controls.to.value ?? '',
    });
  }

  private submitUpdate(
    typeSubmit: EventEmitter<RedirectCreateModel | RedirectUpdateModel>
  ) {
    typeSubmit.emit(this.formGroup.value as RedirectUpdateModel);
  }
}
