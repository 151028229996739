import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants } from '@app/shared/models/common/constants';
import { isFormValid } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
	selector: 'app-password-reset-form',
	templateUrl: './password-reset-form.component.html',
	styleUrls: ['./password-reset-form.component.less'],
})
export class PasswordResetFormComponent implements OnChanges {
	@Input() loading = false;
	@Input() errors: { [key: string]: string[] } | null = {};
    @Input() success = false;
	@Output() resetPassword = new EventEmitter<string>();

	formGroup = new FormGroup(
		{
			password: new FormControl<string>('', [Validators.required, Validators.pattern(Constants.PasswordExpression)]),
			passwordConfirmation: new FormControl<string>('', [Validators.required]),
		},
		{ validators: [CustomValidators.match('password', 'passwordConfirmation')] }
	);
	failed = false;

	ngOnChanges(): void {
		this.failed = Object.keys(this.errors || {}).some((key) => !this.formGroup.get(key));
	}

	onSubmit() {
		if (isFormValid(this.formGroup)) {
			this.resetPassword.emit(this.formGroup.value.password!);
		}
	}
}
