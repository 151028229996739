import * as UserOverview from './user-overview.reducer';
import * as UserDetail from './user-detail.reducer';
import { ActionReducerMap, Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

export const userFeatureKey = 'user';

export interface UserState {
    userOverview: UserOverview.State,
    userDetail: UserDetail.State,
}

export interface State {
    user: UserState
}

const map: ActionReducerMap<UserState> = {
    userOverview: UserOverview.reducer,
    userDetail: UserDetail.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: UserState, action: Action) => combineReducers(map)(state, action);

const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const selectState = createSelector(selectUserState, state => state)

export const selectUserOverviewState = createSelector(selectState, state => state.userOverview)
export const selectUserOverviewLoading = createSelector(selectUserOverviewState, UserOverview.getLoading)
export const selectUserOverviewList = createSelector(selectUserOverviewState, UserOverview.getList)
export const selectUserOverviewPagination = createSelector(selectUserOverviewState, UserOverview.getPagination)
export const selectUserOverviewSorting = createSelector(selectUserOverviewState, UserOverview.getSorting)
export const selectUserOverviewUserTypes = createSelector(selectUserOverviewState, UserOverview.getUserTypes)

export const selectUserDetailState = createSelector(selectState, state => state.userDetail);
export const selectDetailUserRoles = createSelector(selectUserDetailState, UserDetail.getUserRoles);
export const selectDetailLoading = createSelector(selectUserDetailState, UserDetail.getLoading);
export const selectDetailUser = createSelector(selectUserDetailState, UserDetail.getUser);
export const selectDetailErrors = createSelector(selectUserDetailState, UserDetail.getErrors);
export const selectDetailUserExists = createSelector(selectUserDetailState, UserDetail.getUserExists);