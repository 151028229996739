import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { DemoOverviewComponent } from "./pages/overview/demo-overview.component";
import { DemoRoutingModule } from "./demo-routing.module";

import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzUploadModule } from 'ng-zorro-antd/upload';

const COMPONENTS = [
    DemoOverviewComponent,
];

const IMPORTS = [
    SharedModule,
    DemoRoutingModule,
    NzTableModule,
    NzPopconfirmModule,
    NzDrawerModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzRadioModule,
    NzDropDownModule,
    NzTabsModule,
    NzAlertModule,
    NzStepsModule,
    NzCollapseModule,
    NzDividerModule,
    NzUploadModule
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class DemoModule { }
