import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as UserRoleActions from '@app/modules/user-roles/actions/user-role-api.actions';
import * as UserRole from '@app/modules/user-roles/reducers';
import { UserRoleService } from '@app/modules/user-roles/services/user-role.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class UserRoleEffects {
  constructor(
    private actions$: Actions,
    private userRoleService: UserRoleService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<UserRole.State>
  ) {}

  /**
   * Get effects
   */
  loadUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(UserRole.selectUserRoleOverviewPagination),
        this.store.select(UserRole.selectUserRoleOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.userRoleService.getAll(sorting, pagination).pipe(
          map((response) => UserRoleActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserRoleActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadUserRolesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.FilterOverview),
      map(() => UserRoleActions.GetOverview())
    )
  );

  loadUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.Get),
      exhaustMap((action) =>
        this.userRoleService.get(action.id).pipe(
          map((response) => UserRoleActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserRoleActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  getRoleClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.GetRoleClaims),
      exhaustMap(() =>
        this.userRoleService.getRoleClaims().pipe(
          map((response) => UserRoleActions.GetRoleClaimsSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserRoleActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.Create),
      exhaustMap((action) =>
        this.userRoleService.create(action).pipe(
          map((userRole) => UserRoleActions.CreateSuccess(userRole)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserRoleActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createUserRoleSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserRoleActions.CreateSuccess),
        tap(() =>
          this.translate
            .get('user-roles.notification.add')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.Update),
      exhaustMap((action) =>
        this.userRoleService.update(action).pipe(
          map((userRole) => UserRoleActions.UpdateSuccess(userRole)),
          catchError((response: { error: ErrorResponse }) =>
            of(UserRoleActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateUserRoleSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserRoleActions.UpdateSuccess),
        tap(() =>
          this.translate
            .get('user-roles.notification.save')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update user roles & store clear
   */
  createUpdateUserRoleSuccessUserRole$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserRoleActions.CreateSuccess, UserRoleActions.UpdateSuccess),
        tap(() => this.router.navigate(['gebruikersgroepen']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.Delete),
      exhaustMap((action) =>
        this.userRoleService.delete(action.id).pipe(
          map(() => UserRoleActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(UserRoleActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteUserRoleSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRoleActions.DeleteSuccess),
      map(() => UserRoleActions.GetOverview())
    )
  );

  deleteUserRoleSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserRoleActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  userRoleErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserRoleActions.DeleteError, UserRoleActions.GetOverviewError),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
