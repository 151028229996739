import { Component } from '@angular/core';
import { Login } from '@app/modules/auth/actions/auth.actions';
import { LoginRequest } from '@app/modules/auth/models/login-request.model';
import * as Auth from '@app/modules/auth/reducers';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.less'],
})
export class LoginPageComponent {
	loading$ = this.store.select(Auth.selectLoginPageLoading);
	errors$ = this.store.select(Auth.selectLoginPageErrors);

	constructor(private readonly store: Store<Auth.State>) {}

	submit(loginRequest: LoginRequest) {
		this.store.dispatch(
			Login({
				loginRequest: {
					email: loginRequest.email,
					password: loginRequest.password,
				},
			})
		);
	}
}
