import {
  Action,
  ActionReducerMap,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as MaterialOverview from './material-overview.reducer';
import * as MaterialDetailPage from './material-detail-page.reducer';

export const materialFeatureKey = 'material';

export interface MaterialState {
  materialOverview: MaterialOverview.State;
  materialDetail: MaterialDetailPage.State;
}

export interface State {
  material: MaterialState;
}

const map: ActionReducerMap<MaterialState, Action> = {
  materialOverview: MaterialOverview.reducer,
  materialDetail: MaterialDetailPage.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: MaterialState, action: Action) =>
  combineReducers(map)(state, action);

const selectMaterialState =
  createFeatureSelector<MaterialState>(materialFeatureKey);

export const selectState = createSelector(
  selectMaterialState,
  (state) => state
);
export const selectMaterialOverviewState = createSelector(
  selectState,
  (state) => state.materialOverview
);

export const selectMaterialOverviewLoading = createSelector(
  selectMaterialOverviewState,
  MaterialOverview.getLoading
);
export const selectMaterialOverviewList = createSelector(
  selectMaterialOverviewState,
  MaterialOverview.getList
);
export const selectMaterialOverviewPagination = createSelector(
  selectMaterialOverviewState,
  MaterialOverview.getPagination
);
export const selectMaterialOverviewSorting = createSelector(
  selectMaterialOverviewState,
  MaterialOverview.getSorting
);

export const selectMaterialDetailState = createSelector(
  selectState,
  (state) => state.materialDetail
);

export const selectDetailLoading = createSelector(
  selectMaterialDetailState,
  MaterialDetailPage.getLoading
);
export const selectDetailMaterial = createSelector(
  selectMaterialDetailState,
  MaterialDetailPage.getMaterial
);
export const selectDetailErrors = createSelector(
  selectMaterialDetailState,
  MaterialDetailPage.getErrors
);
