import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import * as NewsActions from '@app/modules/news/actions/news-api.actions';
import { NewsService } from '@app/modules/news/services/news.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot) => {
	const newsService = inject(NewsService);
	const router = inject(Router);
	const store = inject(Store<State>);

	return newsService.get(route.params['id']).pipe(
		map((news) => NewsActions.GetSuccess(news)),
		tap((action: any) => store.dispatch(action)),
		map((news) => !!news),
		catchError(() => {
			return router.navigate(['/not-found']);
		})
	);
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);