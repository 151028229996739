import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { FAQChangeSequenceModel, FAQCreateModel, FAQTableItemModel, FAQUpdateModel } from '@app/modules/faq/models';
import { TableDataModel } from '@app/shared/models';
import { serializeContentBlocks } from '@app/shared/models/content';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FAQService {
	private readonly endpoints = {
		get: 'FAQ/:id',
		overview: 'FAQ/GetOverview',
		update: 'FAQ/Update',
		create: 'FAQ/Create',
		delete: 'FAQ/Delete?id=:id',
		changeSequence: 'FAQ/ChangeSequence',
        changeOnline: 'FAQ/ChangeOnline',
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(sorting: TableSortingModel, pagination: TablePaginationModel, categoryId: number): Observable<TableDataModel<FAQTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');
		const categoryIdParam = `categoryId=${categoryId}`;

		return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}&${categoryIdParam}`);
	}

	get(id: number): Observable<FAQUpdateModel> {
		return this.httpService.get<any>(this.endpoints.get.replace(':id', id.toString()));
	}

	update(request: FAQUpdateModel): Observable<FAQUpdateModel> {
		return this.httpService.put<any>(this.endpoints.update, serializeContentBlocks(request));
	}

	create(request: FAQCreateModel): Observable<FAQUpdateModel> {
		return this.httpService.post<any>(this.endpoints.create, serializeContentBlocks(request));
	}

	delete(id: number): Observable<any> {
		return this.httpService.delete<any>(this.endpoints.delete.replace(':id', id.toString()));
	}

	changeSequence(request: FAQChangeSequenceModel): Observable<any> {
		return this.httpService.put<any>(this.endpoints.changeSequence, request);
	}

    online(id: number, online: boolean): Observable<any> {
		return this.httpService.put<any>(this.endpoints.changeOnline, { id, online });
	}
}
