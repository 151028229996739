export enum SalutationType {
    Unknown = 0,
    Mr = 1,
    Mrs = 2,
}
export const SalutationTypeOptions: { key: number, value: string }[] = [
    { key: SalutationType.Unknown, value: 'Unknown' },
    { key: SalutationType.Mr, value: 'Mr' },
    { key: SalutationType.Mrs, value: 'Mrs' },
];
