<div class="page-header">
    <a routerLink="/activiteiten/overzicht" class="page-header__back">
        <i class="fa-regular fa-chevron-left"></i>
    </a>
    <h1 class="page-header__title">{{ 'activities.title' | translate }}</h1>
    <div class="page-header__actions"></div>
</div>

<div class="tab-container">
    <nz-tabset nzType="card">
        <nz-tab [nzTitle]="'activities.header.details' | translate">
            <app-activities-detail-form
                [code]="checkedCode$ | async"
                [activityLocations]="activityLocations$ | async"
                [activityCode]="checkedCode$ | async"
                [activityTypes]="activityTypes$ | async"
                [activityTemplate]="activityTemplate$ | async"
                [activity]="activity$ | async"
                (submitted)="submit($event)"
                (checkCode)="checkCode($event)"
                [errors]="errors$ | async"
                [loading]="loading$ | async" />
        </nz-tab>
    </nz-tabset>
</div>
