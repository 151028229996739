import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as ActivityLocationActions from '@app/modules/activity-location/actions/activity-location-api.actions';
import * as ActivityLocation from '@app/modules/activity-location/reducers';
import { ActivityLocationService } from '@app/modules/activity-location/services/activity-location.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, exhaustMap, catchError, of, tap } from 'rxjs';

@Injectable()
export class ActivityLocationEffects {
  constructor(
    private actions$: Actions,
    private activityLocationService: ActivityLocationService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<ActivityLocation.State>
  ) {}

  /**
   * Get effects
   */
  loadActivityLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLocationActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(
          ActivityLocation.selectActivityLocationOverviewPagination
        ),
        this.store.select(
          ActivityLocation.selectActivityLocationOverviewSorting
        ),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.activityLocationService.getOverview(sorting, pagination).pipe(
          map((response) =>
            ActivityLocationActions.GetOverviewSuccess(response)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityLocationActions.GetOverviewError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  loadActivityLocationsFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLocationActions.FilterOverview),
      map(() => ActivityLocationActions.GetOverview())
    )
  );

  loadActivityLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLocationActions.Get),
      exhaustMap((action) =>
        this.activityLocationService.get(action.id).pipe(
          map((response) => ActivityLocationActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(ActivityLocationActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createActivityLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLocationActions.Create),
      exhaustMap((action) =>
        this.activityLocationService.create(action).pipe(
          map((activityLocation) =>
            ActivityLocationActions.CreateSuccess(activityLocation)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityLocationActions.CreateError({ response: response.error })
            )
          )
        )
      )
    )
  );

  createActivityLocationSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivityLocationActions.CreateSuccess),
        tap((activityLocation) =>
          this.translate
            .get('notification.add', { item: activityLocation.locationName })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateActivityLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLocationActions.Update),
      exhaustMap((action) =>
        this.activityLocationService.update(action).pipe(
          map((activityLocation) =>
            ActivityLocationActions.UpdateSuccess(activityLocation)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityLocationActions.UpdateError({ response: response.error })
            )
          )
        )
      )
    )
  );

  updateActivityLocationSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivityLocationActions.UpdateSuccess),
        tap((activityLocation) =>
          this.translate
            .get('notification.save', { item: activityLocation.locationName })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateActivityLocationSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ActivityLocationActions.CreateSuccess,
          ActivityLocationActions.UpdateSuccess
        ),
        tap(() => this.router.navigate(['activiteit-locaties']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteActivityLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLocationActions.Delete),
      exhaustMap((action) =>
        this.activityLocationService.delete(action.id).pipe(
          map(() => ActivityLocationActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ActivityLocationActions.DeleteError({ response: response.error })
            )
          )
        )
      )
    )
  );

  deleteActivityLocationSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityLocationActions.DeleteSuccess),
      map(() => ActivityLocationActions.GetOverview())
    )
  );

  deleteActivityLocationSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivityLocationActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  activityLocationErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ActivityLocationActions.DeleteError,
          ActivityLocationActions.GetOverviewError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
