<form nz-form [formGroup]="formGroup">
	<nz-card>
		<h4>
			{{ (!!formGroup.get('id')?.value ? 'user-roles.detail.edit-title' : 'user-roles.detail.add-title') | translate }}
		</h4>
		<nz-form-item>
			<nz-form-label [nzSpan]="24" nzRequired>{{ 'user-roles.fields.name' | translate }}</nz-form-label>
			<nz-form-control [nzSpan]="24" [nzErrorTip]="NameValidationTemplate">
				<input nz-input formControlName="name" />

				<ng-template #NameValidationTemplate let-control>
					<app-input-validation [control]="control" [field]="'name'" [errors]="errors"></app-input-validation>
				</ng-template>
			</nz-form-control>
		</nz-form-item>

		<nz-checkbox-wrapper>
			<div nz-row [nzGutter]="[24, 24]">
				<div nz-col nzSpan="6" *ngFor="let claim of roleClaims; index as claimIndex;">
					<div class="group">
						<div nz-row>
							<h4>{{ translationKeyType(claim.type) | translate | titlecase }}</h4>
						</div>
						<div nz-row [nzGutter]="[0, 8]">
							<div *ngFor="let claimValue of claim.values; index as claimValueIndex" nz-col nzSpan="12">
								<label
									nz-checkbox
									[nzValue]="claimValue.active"
									(nzCheckedChange)="checkClaim(claimIndex, claimValueIndex)"
									[formControlName]="claim.type + ':' + claimValue.value"
									>{{ translationKeyValue(claimValue.value) | translate | titlecase }}</label
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nz-checkbox-wrapper>
        <div class="button-container">
			<button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
				{{ 'common.save' | translate }}
			</button>
		</div>
	</nz-card>
</form>