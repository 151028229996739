<div class="page-header">
    <h1 class="page-header__title">{{ "faq-categories.title" | translate }}</h1>
    <div class="page-header__actions">
        <a 
            routerLink="/faq-categorieen/toevoegen" 
            nz-button nzType="primary" 
            claimDirective 
            [claims]="[
                {
                    claimType: ClaimTypes.FAQCategory,
                    claimValues: [ClaimValues.Create]
                }
            ]">
            {{ "common.add" | translate }}
            <i class="fa-regular fa-plus"></i>
        </a>
    </div>
</div>

<app-faq-category-overview-table [data]="(tableData$ | async)!" (delete)="onDeleteConfirm($event)" (onQueryParamsChange)="onQueryParamsChange($event)" (changeSequence)="onSequenceChange($event)" (onResetOverview)="onResetOverview()" />