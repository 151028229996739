import { UserRoleTableItemModel } from '@app/modules/user-roles/models';
import * as UserRoleActions from '@app/modules/user-roles/actions/user-role-api.actions';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = new TableOverviewModel<UserRoleTableItemModel>('name');

export interface State extends TableStateModel<UserRoleTableItemModel> {}

export const reducer = createReducer(
	initialState,
	on(UserRoleActions.GetOverview, (state) => ({ ...state, loading: true })),
	on(UserRoleActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
	on(UserRoleActions.GetOverviewError, (state) => ({ ...state, loading: false })),
	on(UserRoleActions.FilterOverview, (state, { sorting, pagination }) => ({
		...state,
		sorting: applySortingOrDefault(initialState, sorting),
		pagination: {
			...state.pagination,
			page: pagination.page,
			pageSize: pagination.pageSize,
		},
	}))
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
