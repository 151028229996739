import { NgModule } from "@angular/core";
import { FAQCategoryDetailFormComponent } from "@app/modules/faq-categories/components/faq-category-detail-form/faq-category-detail-form.component";
import { FAQCategoryOverviewTableComponent } from "@app/modules/faq-categories/components/faq-category-overview-table/faq-category-overview-table.component";
import { FAQCategoryEffects } from "@app/modules/faq-categories/effects/faq-category.effects";
import { FAQCategoryRoutingModule } from "@app/modules/faq-categories/faq-category-routing.module";
import { FAQCategoryDetailComponent } from "@app/modules/faq-categories/pages/detail/faq-category-detail.component";
import { FAQCategoryOverviewComponent } from "@app/modules/faq-categories/pages/overview/faq-category-overview.component";
import { faqCategoryFeatureKey, reducers } from "@app/modules/faq-categories/reducers";
import { SharedModule } from "@app/shared/shared.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NzDividerModule } from "ng-zorro-antd/divider";

const COMPONENTS = [
    FAQCategoryDetailComponent,
    FAQCategoryOverviewComponent,
    FAQCategoryOverviewTableComponent,
    FAQCategoryDetailFormComponent
];

const IMPORTS = [
    SharedModule,
	FAQCategoryRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(faqCategoryFeatureKey, reducers),
	EffectsModule.forFeature([FAQCategoryEffects]),
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class FAQCategoryModule { }
