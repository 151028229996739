import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import nl from '@angular/common/locales/nl';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { metaReducers, reducers } from '@app/reducers';
import { SidebarComponent } from '@app/shared/components/sidebar/sidebar.component';
import { Topbar } from '@app/shared/components/topbar/topbar.component';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NZ_I18N, nl_NL } from 'ng-zorro-antd/i18n';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CoreModule } from '@app/core/core.module';
import { CustomRequestOptions } from '@app/core/services/http/custom-request.options';
import { AuthModule } from '@app/modules/auth/auth.module';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

registerLocaleData(nl);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		CoreModule,
		SharedModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SidebarComponent,
		Topbar,
		AuthModule,
		StoreModule.forRoot(reducers, {
			metaReducers,
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
			},
		}),
		EffectsModule.forRoot(),
		TranslateModule.forRoot({
			defaultLanguage: 'nl',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		{ provide: NZ_I18N, useValue: nl_NL },
		{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
		{
			provide: DATE_PIPE_DEFAULT_OPTIONS,
			useValue: { dateFormat: 'dd-MM-yyyy' },
		},
		{ provide: LOCALE_ID, useValue: "nl-NL" },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: "EUR" },
		CustomRequestOptions,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
