import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
    ActivityLocationCreateModel,
    ActivityLocationTableItemModel,
    ActivityLocationUpdateModel,
} from '@app/modules/activity-location/models';
import { TableDataModel } from '@app/shared/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityLocationService {
    private readonly endpoints = {
        get: 'ActivityLocation/:id',
        getAll: 'ActivityLocation/getAll',
        overview: 'ActivityLocation/GetOverview',
        update: 'ActivityLocation/Update',
        create: 'ActivityLocation/Create',
        delete: 'ActivityLocation/Delete?id=:id',
        changeSequence: 'ActivityLocation/ChangeSequence/',
    };

    constructor(private readonly httpService: HttpService) {}

    getOverview(
        sorting: TableSortingModel,
        pagination: TablePaginationModel,
    ): Observable<TableDataModel<ActivityLocationTableItemModel>> {
        const paginationParams = parse(pagination, 'pagination');
        const sortingParams = parse(sorting, 'sorting');

        return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}`);
    }

    getAll(): Observable<KeyValueModel[]> {
        return this.httpService.get<any>(this.endpoints.getAll);
    }

    get(id: number): Observable<ActivityLocationUpdateModel> {
        return this.httpService.get<any>(this.endpoints.get.replace(':id', id.toString()));
    }

    update(request: ActivityLocationUpdateModel): Observable<ActivityLocationUpdateModel> {
        return this.httpService.put<any>(this.endpoints.update, request);
    }

    create(request: ActivityLocationCreateModel): Observable<ActivityLocationUpdateModel> {
        return this.httpService.post<any>(this.endpoints.create, request);
    }

    delete(id: number): Observable<any> {
        return this.httpService.delete<any>(this.endpoints.delete.replace(':id', id.toString()));
    }
}
