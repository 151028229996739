export enum ParticipantStatus {
    Option = 1,
    Intake = 2,
    Registered = 3,
    Unenrolled = 4,
    Credited = 5,
    Canceled = 6,
    Reservation = 7,
}

export const ParticipantStatusLabels: Record<number, string> = {
    [ParticipantStatus.Option]: 'activity.enum.option',
    [ParticipantStatus.Intake]: 'activity.enum.intake',
    [ParticipantStatus.Registered]: 'activity.enum.registered',
    [ParticipantStatus.Unenrolled]: 'activity.enum.unenrolled',
    [ParticipantStatus.Credited]: 'activity.enum.credited',
    [ParticipantStatus.Canceled]: 'activity.enum.canceld',
    [ParticipantStatus.Reservation]: 'activity.enum.reserveration',
};
