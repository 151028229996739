import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserModel } from '@app/shared/models/auth/user.model';
import { UserType } from '@app/modules/users/enums/user-type';
import { UserTableItemModel } from '@app/modules/users/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { NzCustomColumn } from 'ng-zorro-antd/table';

@Component({
	selector: 'app-user-overview-table',
	templateUrl: './user-overview-table.component.html',
})
export class UserOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<UserTableItemModel>;
	@Input({ required: true}) currentUser: UserModel | null = null;

	@Output() delete = new EventEmitter<string>();
	@Output() onChangeActive = new EventEmitter<{ id: string; active: boolean }>();
	@Output() onQueryParamsChange = new EventEmitter<any>();

	ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
	UserType = UserType

	changeActive(id: string, active: boolean) {
		this.onChangeActive.emit({ id, active });
	}

	columns: NzCustomColumn[] = [
		{
			value: 'active',
			default: true,
			width: 0,
		},
		{
			value: 'email',
			default: true,
			width: 0,
		},
		{
			value: 'fullname',
			default: true,
			width: 0,
		},
		{
			value: 'roles',
			default: true,
			width: 0,
		},
		{
			value: 'type',
			default: true,
			width: 0
		},
		{
			value: 'actions',
			default: true,
			width: 0,
		},
	];
}