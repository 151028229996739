export enum PaymentStatus {
    Open = 1,
    RegistrationPaid = 2,
    PaymentTerms = 3,
    Paid = 4,
    Free = 5,
    Credited = 6
}

export const PaymentStatusLabels: Record<number, string> = {
    [PaymentStatus.Open]: 'activity.enum.open',
    [PaymentStatus.RegistrationPaid]: 'activity.enum.registration-paid',
    [PaymentStatus.PaymentTerms]: 'activity.enum.payment-terms',
    [PaymentStatus.Paid]: 'activity.enum.paid',
    [PaymentStatus.Free]: 'activity.enum.free',
    [PaymentStatus.Credited]: 'activity.enum.credited'
}

export const PaymentStatusIcons: Record<number, string> = {
    [PaymentStatus.Open]: 'fa-regular fa-square fa-lg',
    [PaymentStatus.RegistrationPaid]: 'fa-regular fa-square-check fa-lg',
    [PaymentStatus.PaymentTerms]: 'fa-regular fa-clock fa-lg',
    [PaymentStatus.Paid]: 'fa-regular fa-square-check green-icon fa-lg',
    [PaymentStatus.Free]: 'fa-solid fa-house-chimney fa-lg',
    [PaymentStatus.Credited]: 'fa-solid fa-share fa-lg'
}
