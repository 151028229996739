import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RelationOverviewComponent } from '@app/modules/relation/pages/overview/relation-overview.component';

const routes: Routes = [
  { path: '', redirectTo: 'relaties', pathMatch: 'full' },
  { path: 'relaties', redirectTo: 'relaties/overzicht', pathMatch: 'full' },
  { path: 'relaties/overzicht', component: RelationOverviewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RelationRoutingModule {}
