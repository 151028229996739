import * as UserRoleOverview from './user-role-overview.reducer';
import * as UserRoleDetail from './user-role-detail.reducer';
import { ActionReducerMap, Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

export const userRoleFeatureKey = 'userRole';

export interface UserRoleState {
    userRoleOverview: UserRoleOverview.State,
    userRoleDetail: UserRoleDetail.State,
}

export interface State {
    userRole: UserRoleState
}

const map: ActionReducerMap<UserRoleState> = {
    userRoleOverview: UserRoleOverview.reducer,
    userRoleDetail: UserRoleDetail.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: UserRoleState, action: Action) => combineReducers(map)(state, action);

const selectUserRoleState = createFeatureSelector<UserRoleState>(userRoleFeatureKey);

export const selectState = createSelector(selectUserRoleState, state => state)
export const selectUserRoleOverviewState = createSelector(selectState, state => state.userRoleOverview)

export const selectUserRoleOverviewLoading = createSelector(selectUserRoleOverviewState, UserRoleOverview.getLoading)
export const selectUserRoleOverviewList = createSelector(selectUserRoleOverviewState, UserRoleOverview.getList)
export const selectUserRoleOverviewPagination = createSelector(selectUserRoleOverviewState, UserRoleOverview.getPagination)
export const selectUserRoleOverviewSorting = createSelector(selectUserRoleOverviewState, UserRoleOverview.getSorting)

export const selectUserRoleDetailState = createSelector(selectState, state => state.userRoleDetail);

export const selectDetailLoading = createSelector(selectUserRoleDetailState, UserRoleDetail.getLoading);
export const selectDetailUserRole = createSelector(selectUserRoleDetailState, UserRoleDetail.getUserRole);
export const selectDetailErrors = createSelector(selectUserRoleDetailState, UserRoleDetail.getErrors);