import { AbstractControlOptions, AsyncValidatorFn, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { ImageAndEditorContentBlockModel } from "@app/shared/models/content/image-and-editor-content-block/image-and-editor-content-block.model";
import CustomValidators from "@app/shared/validators/custom.validator";

export class ImageAndEditorContentBlockFormGroup extends FormGroup {
    constructor(content: string, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
        super({
            editor: new FormControl<string>(''),
            imageId: new FormControl<number | null>(null, [CustomValidators.required]),
        }, validatorOrOpts, asyncValidator);

        this.patchValue(new ImageAndEditorContentBlockModel(content));
    }
}
