import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

import {
  PageTableItemModel
} from '@app/modules/page/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
	selector: 'app-page-overview-table',
	templateUrl: './page-overview-table.component.html',
  styleUrls: ['./page-overview-table.component.less'],
})
export class PageOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<PageTableItemModel>;

  @Output() delete = new EventEmitter<number>();
  @Output() onQueryParamsChange = new EventEmitter<any>();
	@Output() onResetOverview = new EventEmitter<void>();
  @Output() onChangeVisible = new EventEmitter<{
    id: number;
    isVisible: boolean;
  }>();

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;

  public changeVisible(id: number, isVisible: boolean) : void {
    this.onChangeVisible.emit({ id, isVisible });
  }
}
