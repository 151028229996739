import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';

@Component({
	selector: 'app-select-single',
	templateUrl: './select-single.component.html',
})
export class SelectSingleComponent {
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input({ required: true }) field: string = '';
	@Input({ required: true }) errors: { [key: string]: string[] } = {};
	@Input({ required: true }) loading = true;
	@Input({ required: true }) items: KeyValueModel[] | null = null;
	@Input({ required: true }) control?: FormControl<number | null>;
	@Input() allowClear: boolean = false;
	@Input() required: boolean = true;
	@Input() disabled: boolean = false;
}
