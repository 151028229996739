import { CommonModule } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import * as AuthActions from '@app/modules/auth/actions/auth.actions';
import { selectUser } from '@app/modules/auth/reducers';
import { NavigationListModel } from '@app/shared/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { SharedModule } from '@app/shared/shared.module';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';

@Component({
  standalone: true,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NzLayoutModule,
    NzMenuModule,
    NzDropDownModule,
    TranslateModule,
    SharedModule,
  ],
})
export class SidebarComponent {
  user$ = this.store.select(selectUser);
  isCollapsed = false;
  navList: NavigationListModel[] = [];
  ClaimValues = ClaimValues;

  constructor(
    public router: Router,
    private elem: ElementRef,
    private store: Store,
  ) {
    this.navList = [
      {
        label: 'navigation.home',
        icon: 'fa-home',
        route: './dashboard',
      },
      {
        label: 'navigation.content-management.header',
        icon: 'fa-palette',
        subNav: [
          {
            label: 'Paginas',
            route: './paginas',
            claims: [
              {
                claimType: ClaimTypes.Page,
                claimValues: [ClaimValues.Read],
              }
            ]
          },
          {
            label: 'navigation.content-management.faq.header',
            route: './faq',
            claims: [
              {
                claimType: ClaimTypes.FAQ,
                claimValues: [ClaimValues.Read],
              },
            ],
            subNav: [
              {
                label: 'navigation.content-management.faq.categories',
                route: './faq-categorieen',
                claims: [
                  {
                    claimType: ClaimTypes.FAQCategory,
                    claimValues: [ClaimValues.Read],
                  },
                ],
              },
            ],
          },
          {
            label: 'navigation.content-management.news.header',
            route: './nieuws',
            claims: [
              {
                claimType: ClaimTypes.News,
                claimValues: [ClaimValues.Read]
              }
            ]
          },
          {
            label: 'navigation.system.reviews',
            route: './reviews',
            claims: [
              {
                claimType: ClaimTypes.Review,
                claimValues: [ClaimValues.Read]
              }
            ],
          },
          // { label: 'Nieuwsbrief aanmeldingen', route: './routerLink' },
          {
            label: 'navigation.content-management.blog.header',
            route: './blogs',
            claims: [
              {
                claimType: ClaimTypes.Blog,
                claimValues: [ClaimValues.Read],
              },
            ],
            subNav: [
              {
                label: 'navigation.content-management.blog.tags',
                route: './tags',
                claims: [
                  {
                    claimType: ClaimTypes.Tags,
                    claimValues: [ClaimValues.Read],
                  },
                ],
              },
              // Not used for now
              // {
              //   label: 'navigation.content-management.blog.categories',
              //   route: './blog-categorieen',
              //   claims: [
              //     {
              //       claimType: ClaimTypes.BlogCategory,
              //       claimValues: [ClaimValues.Read],
              //     },
              //   ],
              // },
            ],
          },
          {
            label: 'navigation.content-management.redirects',
            route: './redirects',
            claims: [
              {
                claimType: ClaimTypes.Redirect,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
        ],
      },
      {
        label: 'navigation.relations.header',
        icon: 'fa-users',
        subNav: [
          { label: 'Relaties overzicht',  route: './relaties/overzicht', claims: [{ claimType: ClaimTypes.Relation, claimValues: [ClaimValues.Read] }]},
          // { label: 'Facturen versturen', route: './routerLink' },
          {
            label: 'navigation.relations.discount-codes',
            route: './kortingscodes',
            claims: [{ claimType: ClaimTypes.DiscountCode, claimValues: [ClaimValues.Read] }],
          },
        ],
      },
      {
          label: 'Activiteiten',
          icon: 'fa-calendar',
          subNav: [
      { label: 'Activiteiten overzicht', route: './activiteiten/overzicht', claims: [{ claimType: ClaimTypes.Activity, claimValues: [ClaimValues.Read] }] },
      // { label: 'Aanwezigheidsregistratie', route: './routerLink' },
      // { label: 'Bedrijfsactiviteiten', route: './routerLink' },
          ]
      },
      {
        label: 'navigation.system.header',
        icon: 'fa-cog',
        subNav: [
          {
            label: 'navigation.system.users',
            route: './gebruikers',
            claims: [
              {
                claimType: ClaimTypes.BackOfficeUser,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
          {
            label: 'navigation.system.user-roles',
            route: './gebruikersgroepen',
            claims: [
              {
                claimType: ClaimTypes.UserRole,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
          {
            label: 'navigation.system.claim-categories',
            route: './rechten',
            claims: [
              {
                claimType: ClaimTypes.ClaimCategory,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
          {
            label: 'navigation.system.relation-types',
            route: './relatie-typen',
            claims: [
              {
                claimType: ClaimTypes.RelationType,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
          {
            label: 'navigation.system.activity-types',
            route: './activiteit-typen',
            claims: [
              {
                claimType: ClaimTypes.ActivityType,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
          {
            label: 'navigation.system.activity-templates',
            route: './activiteit-templates',
            claims: [
              {
                claimType: ClaimTypes.ActivityTemplate,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
          {
            label: 'navigation.system.activity-locations',
            route: './activiteit-locaties',
            claims: [
              {
                claimType: ClaimTypes.ActivityLocation,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
          {
            label: 'navigation.system.email-templates',
            route: './email-templates',
            claims: [
              {
                claimType: ClaimTypes.EmailTemplate,
                claimValues: [ClaimValues.Read]
              }
            ]
          },
          {
            label: 'navigation.system.countries',
            route: './landen',
            claims: [
              {
                claimType: ClaimTypes.Country,
                claimValues: [ClaimValues.Read],
              },
            ],
          },
          {
            label: 'navigation.system.materials',
            route: './materialen',
            claims: [
              {
                claimType: ClaimTypes.Material,
                claimValues: [ClaimValues.Read],
              },
            ],
          }
        ],
      },
    ];

    this.navList = this.navList.map((x) => ({
      label: x.label,
      icon: x.icon,
      route: x.route,
      subNav: x.subNav,
      claims: x.subNav?.flatMap((child) =>
        child.claims.map((claim) => ({
          claimType: claim.claimType,
          claimValues: [ClaimValues.Read],
        }))
      ),
    }));

    router.events.subscribe(() => {
      const openItems =
        this.elem.nativeElement.querySelectorAll('.nav-item.open');
      const activeItem =
        this.elem.nativeElement.querySelectorAll('.nav-item.active')[0];

      for (let i = 0; i < openItems.length; i++) {
        openItems[i].classList.remove('open');
      }

      activeItem?.classList.add('open');
    });
  }

  openMenu(e: Event) {
    const target = e.currentTarget as Element;
    target.parentElement?.classList.toggle('open');
  }

  logout() {
    this.store.dispatch(AuthActions.Logout());
  }
}
