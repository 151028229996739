import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { UserType } from '@app/modules/users/enums/user-type';
import { UserCreateModel, UserTableItemModel, UserUpdateModel } from '@app/modules/users/models';
import { UserExistsModel } from '@app/modules/users/models/user-exists.model';
import { UserSignatureModel } from '@app/modules/users/models/user-signature.model';
import { TableDataModel } from '@app/shared/models';
import { serializeContentBlocks } from '@app/shared/models/content';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private readonly endpoints = {
		get: 'BackofficeUser/:id',
		overview: 'BackofficeUser/GetOverview',
		update: 'BackofficeUser/Update',
		active: 'BackofficeUser/Active',
		create: 'BackofficeUser/Create',
		delete: 'BackofficeUser/Delete?id=:id',
		changeSequence: 'BackofficeUser/ChangeSequence/',
		getEmployees: 'BackofficeUser/GetEmployees',
		employeeSignature: 'BackofficeUser/EmployeeSignature/:blobReference',
		userExists: 'BackofficeUser/UserExists',
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(sorting: TableSortingModel, pagination: TablePaginationModel, userTypes: UserType[]): Observable<TableDataModel<UserTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');
		const userTypesParams = userTypes.map(x => `userTypes=${x}`).join('&');

		return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}&${userTypesParams}`);
	}

	get(id: string): Observable<UserUpdateModel> {
		return this.httpService.get<any>(this.endpoints.get.replace(':id', id));
	}

	update(request: UserUpdateModel): Observable<UserUpdateModel> {
		return this.httpService.put<any>(this.endpoints.update, {...request, employee: serializeContentBlocks(request.employee!)});
	}

	active(id: string, active: boolean): Observable<any> {
		return this.httpService.put<any>(this.endpoints.active, { id, active });
	}

	create(request: UserCreateModel): Observable<UserUpdateModel> {
		return this.httpService.post<any>(this.endpoints.create, {...request, employee: serializeContentBlocks(request.employee!)});
	}

	delete(id: string): Observable<UserUpdateModel> {
		return this.httpService.delete<any>(this.endpoints.delete.replace(':id', id));
	}

	getEmployees(): Observable<any> {
		return this.httpService.get<any>(this.endpoints.getEmployees);
	}

	employeeSignature(request: UserSignatureModel) {
		return this.httpService.get<any>(this.endpoints.employeeSignature.replace(':blobReference', request.blobReference!), { responseType: 'blob' });
	}

	userExists(email: string): Observable<UserExistsModel> {
		return this.httpService.get<UserExistsModel>(this.endpoints.userExists, { params: { email } });
	}
}
