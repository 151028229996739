import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReviewCreateModel, ReviewUpdateModel } from '@app/modules/review/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { ClaimService } from '@app/shared/services/claim.service';
import { getDateFromDateTime } from '@app/shared/utilities/date-converter.utilities';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
    selector: 'app-review-detail-form',
    templateUrl: './review-detail-form.component.html',
    styleUrls: ['./review-detail-form.component.less']
})
export class ReviewDetailFormComponent implements OnChanges, OnInit {
    update = false;
    readonly?: boolean;

    localActivities: KeyValueModel[] |  null = null;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) review: ReviewUpdateModel | null = null;

    @Input({required: true}) activityTypes: KeyValueModel[] | null = [];

    @Output() submitted = new EventEmitter<ReviewCreateModel | ReviewUpdateModel>();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        name: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        longText: new FormControl<string>('', [CustomValidators.required]),
        creationDate: new FormControl<Date | null >(null, [CustomValidators.required]),
        function: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        online: new FormControl<boolean>(false),
        activityTypeId: new FormControl<number | null>(null, [CustomValidators.required]),
        shortText: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(200)]),
    });

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.review;
        
        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.Review, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        this.formGroup.patchValue({
            name: this.review?.name,
            longText: this.review?.longText,
            activityTypeId: this.review?.activityTypeId,
            creationDate: this.review?.date ? new Date(this.review!.date) : null,
            function: this.review?.function,
            online: this.review?.online,
            shortText: this.review?.shortText
        });

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.review as ReviewUpdateModel).id);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            name: this.formGroup.controls.name.value ?? '',
            longText: this.formGroup.controls.longText.value ?? '',
            date: getDateFromDateTime(new Date(this.formGroup.controls.creationDate.value!))?? '' ,
            function: this.formGroup.controls.function.value ?? '',
            online: this.formGroup.controls.online.value ?? false,
            activityTypeId: this.formGroup.controls.activityTypeId.value!,
            shortText: this.formGroup.controls.shortText.value ?? '',
        });
    }

    private submitUpdate() {
        this.submitted.emit({
            id: this.formGroup.controls.id.value!,
            name: this.formGroup.controls.name.value ?? '',
            longText: this.formGroup.controls.longText.value ?? '',
            date:  getDateFromDateTime(new Date(this.formGroup.controls.creationDate.value!))?? '' ,
            function: this.formGroup.controls.function.value ?? '',
            online: this.formGroup.controls.online.value ?? false,
            activityTypeId: this.formGroup.controls.activityTypeId.value!,
            shortText: this.formGroup.controls.shortText.value ?? '',
        });
    }
}