export enum ActivityPlanningStatus {
    InAssignment = 1,
    InPlanning = 2,
    Done = 3,
}

export const ActivityPlanningStatusLabelMapping: Record<number, string> = {
    [ActivityPlanningStatus.InAssignment]: "activities.fields.InAssignment",
    [ActivityPlanningStatus.InPlanning]: "activities.fields.InPlanning",
    [ActivityPlanningStatus.Done]: "activities.fields.Done",
  }