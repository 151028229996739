import { NgModule } from '@angular/core';
import { CreateNoteComponent } from '@app/modules/note/components/create-note/create-note.component';
import { EditNoteModalComponent } from '@app/modules/note/components/edit-note-modal/edit-note-modal.component';
import { NoteEntryComponent } from '@app/modules/note/components/note-entry/note-entry.component';

import { NotesSidebarComponent } from '@app/modules/note/components/notes-sidebar/notes-sidebar.component';
import { NoteEffects } from '@app/modules/note/effects/note.effects';
import { NoteRoutingModule } from '@app/modules/note/note-routing.module';
import { noteFeatureKey, reducers } from '@app/modules/note/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [NotesSidebarComponent, EditNoteModalComponent, CreateNoteComponent, NoteEntryComponent];

const IMPORTS = [
    SharedModule,
    NzDividerModule,
    NoteRoutingModule,
    StoreModule.forFeature(noteFeatureKey, reducers),
    EffectsModule.forFeature([NoteEffects]),
];

@NgModule({
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})
export class NoteModule {}
