import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';

@Component({
    selector: 'app-create-note',
    templateUrl: './create-note.component.html',
    styleUrl: './create-note.component.less',
})
export class CreateNoteComponent implements OnChanges {
    @Output() createNote = new EventEmitter<{ noteMessage: string; isImportant: boolean }>();
    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input() note: string;

    formGroup = new FormGroup({
        noteMessage: new FormControl<string>('', Validators.maxLength(500)),
        isImportant: new FormControl<boolean>(false),
    });

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
        this.emptyTextField(changes);
    }

    makeNote() {
        if (isFormValid(this.formGroup)) {
            this.createNote.emit({
                noteMessage: this.formGroup.controls.noteMessage.value,
                isImportant: this.formGroup.controls.isImportant.value,
            });
        }
    }

    emptyTextField(changes: SimpleChanges) {
        if (
            Object.hasOwn(changes, 'note') &&
            changes['note'].previousValue !== '' &&
            changes['note'].currentValue === ''
        ) {
            this.formGroup.patchValue({
                noteMessage: '',
                isImportant: false,
            });
        }
    }
}
