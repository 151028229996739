import * as ActivityTemplateApiActions from '@app/modules/activity-template/actions/activity-template-api.actions';
import { ActivityTemplateTableItemModel } from '@app/modules/activity-template/models/activity-template-table.model';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
    ...new TableOverviewModel<ActivityTemplateTableItemModel>('name'),
    activityTypeId: -1,
    activityTypes: null,
};

export interface State extends TableStateModel<ActivityTemplateTableItemModel> {
    activityTypeId: number;
    activityTypes: KeyValueModel[] | null;
}

export const reducer = createReducer(
    initialState,
    on(ActivityTemplateApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(ActivityTemplateApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(ActivityTemplateApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(ActivityTemplateApiActions.FilterOverview, (state, { sorting, pagination }) => ({
        ...state,
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: {
            ...state.pagination,
            page: pagination.page,
            pageSize: pagination.pageSize,
        },
    })),
    on(ActivityTemplateApiActions.ResetOverview, (state) => ({ ...initialState, activityTypes: state.activityTypes  })),

    on(ActivityTemplateApiActions.SetActiveActivityTypeId, (state, { activityTypeId }) => ({ ...state, activityTypeId })),

	on(ActivityTemplateApiActions.GetActivityTypes, (state) => ({ ...state, loading: true })),
	on(ActivityTemplateApiActions.GetActivityTypesSuccess, (state, { activityTypes }) => ({ ...state, activityTypes, loading: false })),
	on(ActivityTemplateApiActions.GetActivityTypesError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
export const getActivityTypeId = (state: State) => state.activityTypeId;
export const getActivityTypes = (state: State) => state.activityTypes;