<div class="page-header">
	<h1 class="page-header__title">{{ 'reviews.title' | translate }}</h1>
	<div class="page-header__actions">
		<a
			claimDirective
			[claims]="[
				{
					claimType: ClaimTypes.Review,
					claimValues: [ClaimValues.Create]
				}
			]"
			routerLink="/reviews/toevoegen"
			nz-button
			nzType="primary">
			{{ 'common.add' | translate }}
			<i class="fa-regular fa-plus"></i>
		</a>
	</div>
</div>

<div>
	<nz-input-group [nzSuffix]="suffixIconSearch" nz-col nzSpan="6" class="search-field">
		<input type="text" #searchText nz-input placeholder="{{ 'placeholder.search' | translate }}" (keyup)="search(searchText.value)" [disabled]="isDraggingEnabled"/>
	</nz-input-group>
	<ng-template #suffixIconSearch>
		<i nz-icon nzType="search"></i>
	</ng-template>

	<nz-select
		class="activity-select"
		*ngIf="activityTypes$ | async"
		nzAllowClear
		[(ngModel)]="selectedActivityTypes"
		(ngModelChange)="onActivityTypeChange($event)"
		nzPlaceHolder="{{ 'reviews.overview.activity-type' | translate }}"
		[disabled]="isDraggingEnabled">
		<nz-option
			*ngFor="let activityType of activityTypes$ | async"
			[nzLabel]="activityType.value"
			[nzValue]="activityType"></nz-option>
	</nz-select>
</div>

<app-review-overview-table
	[data]="(tableData$ | async)!"
	[isFiltered]="hasSearchValue || hasActivityType"
	(delete)="onDeleteConfirm($event)"
	(queryParamsChange)="onQueryParamsChange($event)"
	(changeSequence)="onSequenceChange($event)"
	(resetOverview)="onResetOverview()" 
	(changeOnline)="onChangeOnline($event)"
	(draggingEnabledChange)="ondraggingEnabledChange($event)"/>
