import { NgModule } from '@angular/core';
import { TagOverviewComponent } from "@app/modules/tag/pages/overview/tag-overview.component";
import { TagDetailComponent } from "@app/modules/tag/pages/detail/tag-detail.component";
import { TagOverviewTableComponent } from "@app/modules/tag/components/tag-overview-table/tag-overview-table.component";
import { TagDetailFormComponent } from "@app/modules/tag/components/tag-detail-form/tag-detail-form.component";
import { SharedModule } from "@app/shared/shared.module";
import { TagRoutingModule } from "@app/modules/tag/tag-routing.module";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { StoreModule } from "@ngrx/store";
import {
  reducers,
  tagFeatureKey
} from "@app/modules/tag/reducers";
import { EffectsModule } from "@ngrx/effects";
import { TagEffects } from "@app/modules/tag/effects/tag.effects";

const COMPONENTS = [
  TagOverviewComponent,
  TagDetailComponent,
  TagOverviewTableComponent,
  TagDetailFormComponent
];

const IMPORTS = [
  SharedModule,
  TagRoutingModule,
  NzDividerModule,
  StoreModule.forFeature(tagFeatureKey, reducers),
  EffectsModule.forFeature([TagEffects])
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [IMPORTS],
  providers: [],
})
export class TagModule { }
