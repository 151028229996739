import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoOverviewComponent } from './pages/overview/demo-overview.component';

const routes: Routes = [
  { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
  { path: 'overzicht', component: DemoOverviewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DemoRoutingModule {}
