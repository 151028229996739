import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
  BlogReactionCreateModel,
  BlogReactionTableItemModel,
  BlogReactionUpdateModel,
} from '@app/modules/blog-reaction/models';
import { TableDataModel } from '@app/shared/models';
import { TableFilterModel } from '@app/shared/models/table/table-filter.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlogReactionService {
  private readonly endpoints = {
    get: 'BlogReaction/:id',
    overview: 'BlogReaction/GetOverview?blogId=:blogId',
    update: 'BlogReaction/Update',
    create: 'BlogReaction/Create',
    showOnline: 'BlogReaction/showOnline',
    delete: 'BlogReaction/Delete?id=:id',
    changeSequence: 'BlogReaction/ChangeSequence/',
  };

  constructor(private readonly httpService: HttpService) {}

  getAll(
    sorting: TableSortingModel,
    pagination: TablePaginationModel,
    filter: TableFilterModel[],
    blogId: number
  ): Observable<TableDataModel<BlogReactionTableItemModel>> {
    const paginationParams = parse(pagination, 'pagination');
    const sortingParams = parse(sorting, 'sorting');
    const filterParams = parse(filter, 'filters');
    return this.httpService.get<TableDataModel<BlogReactionTableItemModel>>(
      (
        this.endpoints.overview +
        `&${paginationParams}&${sortingParams}&${filterParams}`
      ).replace(':blogId', blogId.toString())
    );
  }

  get(id: number): Observable<BlogReactionUpdateModel> {
    return this.httpService.get<BlogReactionUpdateModel>(
      this.endpoints.get.replace(':id', id.toString())
    );
  }

  update(
    request: BlogReactionUpdateModel
  ): Observable<BlogReactionUpdateModel> {
    return this.httpService.put<BlogReactionUpdateModel>(this.endpoints.update, request);
  }

  showOnline(id: number, showOnline: boolean): Observable<BlogReactionUpdateModel> {
    return this.httpService.put<BlogReactionUpdateModel>(this.endpoints.showOnline, {
      id,
      showOnline,
    });
  }

  create(
    request: BlogReactionCreateModel
  ): Observable<BlogReactionUpdateModel> {
    return this.httpService.post<BlogReactionUpdateModel>(this.endpoints.create, request);
  }

  delete(id: number): Observable<void> {
    return this.httpService.delete<void>(
      this.endpoints.delete.replace(':id', id.toString())
    );
  }
}
