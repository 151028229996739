import { Component } from '@angular/core';
import * as DiscountCodeApiActions from '@app/modules/discount-codes/actions/discount-code-api.actions';
import * as DiscountCode from '@app/modules/discount-codes/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
	selector: 'app-discount-code-overview',
	templateUrl: './discount-code-overview.component.html',
	styleUrls: ['./discount-code-overview.component.less'],
})
export class DiscountCodeOverviewComponent {
	tableData$ = this.store.select(DiscountCode.selectDiscountCodeOverviewState);

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;

	constructor(private readonly store: Store) {}

	onDeleteConfirm(id: number): void {
		this.store.dispatch(DiscountCodeApiActions.Delete({ id: id }));
	}

	/**
	 * Trigger the loading of data whenever the table changes.
	 * The default pageSize, index and sort options are based on the TableStateModel.
	 * @param params
	 */
	onQueryParamsChange(params: NzTableQueryParams) {
		const { pageSize, pageIndex, sort, filter } = params;
		const currentSort = sort.find((item) => item.value !== null);
		const sortField = (currentSort?.key) ?? null;
		const sortOrder = (currentSort?.value) ?? null;

		this.store.dispatch(
			DiscountCodeApiActions.FilterOverview({
				pagination: {
					page: pageIndex,
					pageSize,
				},
				sorting: {
					propertyName: sortField ?? '',
					order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
				},
			})
		);
	}

	onResetOverview() {
		this.store.dispatch(DiscountCodeApiActions.ResetOverview());
	}

	onActiveChange(event: { id: number; active: boolean; }) {
		this.store.dispatch(DiscountCodeApiActions.ChangeActive({ id: event.id, active: event.active }));
	}
}
