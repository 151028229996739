import * as ActivityApiActions from '@app/modules/activity/actions/activity-api.actions';
import { ActivityTableItemModel } from '@app/modules/activity/models/activity-table.model';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
    ...new TableOverviewModel<ActivityTableItemModel>('name'),
    activityTemplates: [],
    activityTypeId: -1,
    displayOld: false,
};

export interface State extends TableStateModel<ActivityTableItemModel> {
    activityTemplates: KeyValueModel[];
    activityTypeId: number;
    displayOld: boolean;
}

export const reducer = createReducer(
    initialState,
    on(ActivityApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(ActivityApiActions.GetOverviewSuccess, (state, response) => ({
        ...state,
        ...response,
        loading: false,
    })),
    on(ActivityApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(ActivityApiActions.FilterOverview, (state, { sorting, pagination, thenBy }) => ({
        ...state,
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: {
            ...state.pagination,
            page: pagination.page,
            pageSize: pagination.pageSize,
        },
        thenBy
    })),
    on(ActivityApiActions.ResetOverview, (state) => ({ ...initialState })),
    on(ActivityApiActions.GetAllActivityTemplates, (state) => ({ ...state, loading: true })),
    on(ActivityApiActions.GetAllActivityTemplatesSuccess, (state, { activityTemplates }) => ({
        ...state,
        activityTemplates,
        loading: false,
    })),
    on(ActivityApiActions.SetActiveActivityTypeId, (state, { activityTypeId }) => ({ ...state, activityTypeId })),

    on(ActivityApiActions.DisplayOldActivities, (state,  { displayOld } ) => ({ ...state, displayOld})),

    on(ActivityApiActions.GetAllActivityTemplatesError, (state, { response }) => ({
        ...state,
        loading: false,
        errors: response.errors || {},
    })),
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
export const getSortingThen = (state: State) => state.thenBy
export const getActivityTemplates = (state: State) => state.activityTemplates;
export const getChosenActivityType = (state: State) => state.activityTypeId;
export const getDisplayOldActivities = (state: State) => state.displayOld