import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivityTypeChangeSequenceModel } from '@app/modules/activity-type/models';
import { ActivityTypeTableItemModel } from '@app/modules/activity-type/models/activity-type-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
	selector: 'app-activity-type-overview-table',
	templateUrl: './activity-type-overview-table.component.html',
})
export class ActivityTypeOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<ActivityTypeTableItemModel>;

	@Output() delete = new EventEmitter<number>();
	@Output() onQueryParamsChange = new EventEmitter<any>();
	@Output() changeSequence = new EventEmitter<ActivityTypeChangeSequenceModel>();
	@Output() onResetOverview = new EventEmitter<void>();

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;
	draggingEnabled = false;

	drop(event: CdkDragDrop<string[]>): void {
		this.changeSequence.emit({
			id: event.item.data.id,
			newSequenceNumber: event.currentIndex,
		});
	}

	draggingChanged() {
		if (this.draggingEnabled) {
			this.onQueryParamsChange.emit({
				pageIndex: 1,
				pageSize: -1,
				sort: [{key: 'sequenceNumber', value: 'ascend'}],
			});
		} else {
			this.onResetOverview.emit();
		}
	}
}