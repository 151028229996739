import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivityTemplateCreateModel, ActivityTemplateUpdateModel } from '@app/modules/activity-template/models';
import { ActivityTypeCodeModel } from '@app/modules/activity-type/models/activity-type-code.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
    selector: 'app-activity-template-detail-form',
    templateUrl: './activity-template-detail-form.component.html',
})
export class ActivityTemplateDetailFormComponent implements OnInit, OnChanges {
    update = false;
    readonly?: boolean;
    maxValidatorLength = 999999;

    hasTheme = false;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) activityTemplate: ActivityTemplateUpdateModel | null = null;
    @Input({ required: true }) activityTypes: ActivityTypeCodeModel[] | null = null;
    @Output() submitted = new EventEmitter<ActivityTemplateCreateModel | ActivityTemplateUpdateModel>();

    destroyRef = inject(DestroyRef);

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        activityTypeId: new FormControl<number>(0, CustomValidators.required),
        activityTypeName: new FormControl<string>('', Validators.maxLength(100)),
        name: new FormControl<string>('', [Validators.maxLength(100)]),
        exactCode: new FormControl<string>('', [Validators.maxLength(100)]),
        displayPrices: new FormControl<boolean>(false),
        priceIncludingVat: new FormControl<number>(0, [
            CustomValidators.correctAmountDecimal(2),
            Validators.min(0),
            Validators.max(this.maxValidatorLength),
        ]),
        priceSecondIncludingVat: new FormControl<number>(0, [
            CustomValidators.correctAmountDecimal(2),
            Validators.min(0),
            Validators.max(this.maxValidatorLength),
        ]),
        vatPercentage: new FormControl<number>(0, [Validators.min(0), Validators.max(100)]),
        includesLunch: new FormControl<boolean>(false),
        themeName: new FormControl<string>('', Validators.maxLength(100)),
        themeCode: new FormControl<string>('', Validators.maxLength(50)),
        themeDescription: new FormControl<string>(''),
        minimumParticipants: new FormControl<number>(0, [Validators.min(0), Validators.max(1000)]),
        maximumParticipants: new FormControl<number>(0, [Validators.max(1000)]),
        availableStudent: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        availableStudentSecondTime: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        availableGuest: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        availableCatchUp: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        waitlistStudent: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        waitlistStudentSecondTime: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        waitlistGuest: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
        waitlistCatchUp: new FormControl<number>(0, [Validators.min(0), Validators.max(this.maxValidatorLength)]),
    });

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.activityTemplate;

        this.formGroup.controls.maximumParticipants.addValidators(
            CustomValidators.greaterOrEqualToOtherField('minimumParticipants'),
        );

        this.formGroup.controls.minimumParticipants.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
            next: () => {
                this.formGroup.controls.maximumParticipants.markAsTouched();
                this.formGroup.controls.maximumParticipants.updateValueAndValidity();
            },
        });

        this.formGroup.controls.activityTypeId.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activityTypeId => this.typeChange(activityTypeId));

        if (
            this.update &&
            !(await this.claimService.hasAny([
                { claimType: ClaimTypes.ActivityTemplate, claimValues: [ClaimValues.Update] },
            ]))
        ) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        this.formGroup.patchValue({
            id: this.activityTemplate?.id,
            activityTypeId: this.activityTemplate?.activityTypeId,
            activityTypeName: this.activityTemplate?.activityTypeName,
            name: this.activityTemplate?.name,
            exactCode: this.activityTemplate?.exactCode,
            displayPrices: this.activityTemplate?.displayPrices,
            priceIncludingVat: this.activityTemplate?.priceIncludingVat,
            priceSecondIncludingVat: this.activityTemplate?.priceSecondIncludingVat,
            includesLunch: this.activityTemplate?.includesLunch,
            themeName: this.activityTemplate?.themeName,
            themeCode: this.activityTemplate?.themeCode,
            themeDescription: this.activityTemplate?.themeDescription,
            minimumParticipants: this.activityTemplate?.minimumParticipants,
            maximumParticipants: this.activityTemplate?.maximumParticipants,
            availableStudent: this.activityTemplate?.availableStudent,
            availableStudentSecondTime: this.activityTemplate?.availableStudentSecondTime,
            availableGuest: this.activityTemplate?.availableGuest,
            availableCatchUp: this.activityTemplate?.availableCatchUp,
            waitlistStudent: this.activityTemplate?.waitlistStudent,
            waitlistStudentSecondTime: this.activityTemplate?.waitlistStudentSecondTime,
            waitlistGuest: this.activityTemplate?.waitlistGuest,
            waitlistCatchUp: this.activityTemplate?.waitlistCatchUp,
            vatPercentage: this.activityTemplate?.vatPercentage ?? 0,
        });

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.activityTemplate as ActivityTemplateUpdateModel).id);
        }

        this.formGroup.controls.activityTypeId.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(x => this.typeChange(x));
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
        this.formGroup.controls.maximumParticipants.updateValueAndValidity();

        if (changes['activityTypes']) {
            this.typeChange(this.formGroup.controls.activityTypeId.value);
        }
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    typeChange(activityTypeId: number|null) {
        const activityType = this.activityTypes?.find(x => x.id === activityTypeId);

        this.hasTheme = activityType?.hasTheme ?? false;
    }

    private submitCreate() {
        this.submitted.emit({
            name: this.formGroup.controls.name.value ?? '',
            activityTypeId: this.formGroup.controls.activityTypeId.value ?? 0,
            activityTypeName: "",
            exactCode: this.formGroup.controls.exactCode.value ?? '',
            vatPercentage: this.formGroup.controls.vatPercentage.value ?? 0,
            minimumParticipants: this.formGroup.controls.minimumParticipants.value ?? null,
            maximumParticipants: this.formGroup.controls.maximumParticipants.value ?? null,
            displayPrices: this.formGroup.controls.displayPrices.value ?? false,
            priceIncludingVat: this.formGroup.controls.priceIncludingVat.value ?? null,
            priceSecondIncludingVat: this.formGroup.controls.priceSecondIncludingVat.value ?? null,
            includesLunch: this.formGroup.controls.includesLunch.value ?? false,
            themeName: this.formGroup.controls.themeName.value ?? '',
            themeCode: this.formGroup.controls.themeCode.value ?? '',
            themeDescription: this.formGroup.controls.themeDescription.value ?? '',
            availableStudent: this.formGroup.controls.availableStudent.value ?? null,
            waitlistStudent: this.formGroup.controls.waitlistStudent.value ?? null,
            availableStudentSecondTime: this.formGroup.controls.availableStudentSecondTime.value ?? null,
            waitlistStudentSecondTime: this.formGroup.controls.waitlistStudentSecondTime.value ?? null,
            availableCatchUp: this.formGroup.controls.availableCatchUp.value ?? null,
            waitlistCatchUp: this.formGroup.controls.waitlistCatchUp.value ?? null,
            availableGuest: this.formGroup.controls.availableGuest.value ?? null,
            waitlistGuest: this.formGroup.controls.waitlistGuest.value ?? null,
        });
    }

    private submitUpdate() {
        this.submitted.emit({
            id: this.formGroup.controls.id.value!,
            name: this.formGroup.controls.name.value ?? '',
            activityTypeId: this.formGroup.controls.activityTypeId.value ?? 0,
            activityTypeName: this.formGroup.controls.activityTypeName.value ?? '',
            exactCode: this.formGroup.controls.exactCode.value ?? '',
            vatPercentage: this.formGroup.controls.vatPercentage.value ?? 0,
            minimumParticipants: this.formGroup.controls.minimumParticipants.value ?? null,
            maximumParticipants: this.formGroup.controls.maximumParticipants.value ?? null,
            displayPrices: this.formGroup.controls.displayPrices.value ?? false,
            priceIncludingVat: this.formGroup.controls.priceIncludingVat.value ?? null,
            priceSecondIncludingVat: this.formGroup.controls.priceSecondIncludingVat.value ?? null,
            includesLunch: this.formGroup.controls.includesLunch.value ?? false,
            themeName: this.formGroup.controls.themeName.value ?? '',
            themeCode: this.formGroup.controls.themeCode.value ?? '',
            themeDescription: this.formGroup.controls.themeDescription.value ?? '',
            availableStudent: this.formGroup.controls.availableStudent.value ?? null,
            waitlistStudent: this.formGroup.controls.waitlistStudent.value ?? null,
            availableStudentSecondTime: this.formGroup.controls.availableStudentSecondTime.value ?? null,
            waitlistStudentSecondTime: this.formGroup.controls.waitlistStudentSecondTime.value ?? null,
            availableCatchUp: this.formGroup.controls.availableCatchUp.value ?? null,
            waitlistCatchUp: this.formGroup.controls.waitlistCatchUp.value ?? null,
            availableGuest: this.formGroup.controls.availableGuest.value ?? null,
            waitlistGuest: this.formGroup.controls.waitlistGuest.value ?? null,
        });
    }
}
