import * as ActivityTypeApiActions from "@app/modules/activity-type/actions/activity-type-api.actions"
import { ActivityTypeTableItemModel } from "@app/modules/activity-type/models/activity-type-table.model"
import { TableOverviewModel } from "@app/shared/models/table/table-overview.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model"
import { applySortingOrDefault } from "@app/shared/utilities/overview-reducer.utilities"
import { createReducer, on } from "@ngrx/store"

const initialState: State = new TableOverviewModel<ActivityTypeTableItemModel>('name');

export interface State extends TableStateModel<ActivityTypeTableItemModel> { }

export const reducer = createReducer(initialState,
    on(ActivityTypeApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(ActivityTypeApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(ActivityTypeApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(ActivityTypeApiActions.FilterOverview, (state, { sorting, pagination }) => ({ 
        ...state, 
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: { 
            ...state.pagination, 
            page: pagination.page,
            pageSize: pagination.pageSize
        } 
    })),
    on(ActivityTypeApiActions.ResetOverview,  () => ({ ...initialState }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;