import { UserRoleTableItemModel } from '@app/modules/user-roles/models';
import * as UserActions from '@app/modules/users/actions/user-api.actions';
import { UserUpdateModel } from '@app/modules/users/models';
import { UserExistsModel } from '@app/modules/users/models/user-exists.model';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
	user: null,
	userRoles: [],
	userExists: { backOfficeUserExists: false, websiteUserExists: false },
	loading: false,
	errors: {},
};

export interface State {
	user: UserUpdateModel | null;
	userRoles: UserRoleTableItemModel[];
	userExists: UserExistsModel;
	loading: boolean;
	errors: { [key: string]: string[] };
}

export const reducer = createReducer(
	initialState,
	on(UserActions.Clear, () => ({ ...initialState })),
	on(UserActions.GetUserRolesSuccess, (state, { userRoles }) => ({ ...state, userRoles })),
	on(UserActions.UserExistsSuccess, (state, { response }) => ({ ...state, userExists: response })),
	on(UserActions.ResetUserExists, (state) => ({ ...state, userExists: initialState.userExists })),

	on(UserActions.Get, (state) => ({ ...state, loading: true })),
	on(UserActions.GetSuccess, (state, user) => ({ ...state, user, loading: false })),
	on(UserActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

	on(UserActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
	on(UserActions.UpdateSuccess, (state, user) => ({ ...state, user, loading: false })),
	on(UserActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

	on(UserActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
	on(UserActions.CreateSuccess, (state, user) => ({ ...state, user, loading: false, userExists: { backOfficeUserExists: false, websiteUserExists: false }})),
	on(UserActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {}, userExists: { backOfficeUserExists: false, websiteUserExists: false }})),

	on(UserActions.DownloadSignature, (state) => ({ ...state, loading: true, errors: {} })),
	on(UserActions.DownloadSignatureSuccess, (state) => ({ ...state, loading: false })),
	on(UserActions.DownloadSignatureError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getUser = (state: State) => state.user;
export const getUserRoles = (state: State) => state.userRoles;
export const getUserExists = (state: State) => state.userExists;
