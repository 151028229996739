import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import * as BlogReactionApiActions from '@app/modules/blog-reaction/actions/blog-reaction-api.actions';
import { BlogReactionService } from '@app/modules/blog-reaction/services/blog-reaction.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const blogreactionService = inject(BlogReactionService);
  const router = inject(Router);
  const store = inject(Store<State>);

  return blogreactionService.get(route.params['id']).pipe(
    map((blogreaction) => BlogReactionApiActions.GetSuccess(blogreaction)),
    tap((action: any) => store.dispatch(action)),
    map((blogreaction) => !!blogreaction),
    catchError(() => {
      return router.navigate(['/404']);
    })
  );
};

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
