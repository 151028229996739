import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as CountryActions from '@app/modules/countries/actions/country-api.actions';
import * as Country from '@app/modules/countries/reducers';
import { CountryService } from '@app/modules/countries/services/country.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class CountryEffects {
  constructor(
    private actions$: Actions,
    private countryService: CountryService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<Country.State>
  ) {}

  /**
   * Get effects
   */
  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(Country.selectCountryOverviewPagination),
        this.store.select(Country.selectCountryOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.countryService.getAll(sorting, pagination).pipe(
          map((response) => CountryActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(CountryActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadCountriesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.FilterOverview),
      map(() => CountryActions.GetOverview())
    )
  );

  loadCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.Get),
      exhaustMap((action) =>
        this.countryService.get(action.id).pipe(
          map((response) => CountryActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(CountryActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.Create),
      exhaustMap((action) =>
        this.countryService.create(action).pipe(
          map((country) => CountryActions.CreateSuccess(country)),
          catchError((response: { error: ErrorResponse }) =>
            of(CountryActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createCountrySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CountryActions.CreateSuccess),
        tap((country) =>
          this.translate
            .get('notification.add', { item: country.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.Update),
      exhaustMap((action) =>
        this.countryService.update(action).pipe(
          map((country) => CountryActions.UpdateSuccess(country)),
          catchError((response: { error: ErrorResponse }) =>
            of(CountryActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateCountrySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CountryActions.UpdateSuccess),
        tap((country) =>
          this.translate
            .get('notification.save', { item: country.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateCountrySuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CountryActions.CreateSuccess, CountryActions.UpdateSuccess),
        tap(() => this.router.navigate(['landen']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.Delete),
      exhaustMap((action) =>
        this.countryService.delete(action.id).pipe(
          map(() => CountryActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(CountryActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteCountrySuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.DeleteSuccess),
      map(() => CountryActions.GetOverview())
    )
  );

  deleteCountrySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CountryActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change sequence effects
   */
  changeSequenceCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.ChangeSequence),
      exhaustMap((action) =>
        this.countryService.changeSequence(action).pipe(
          map(() => CountryActions.ChangeSequenceSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(CountryActions.ChangeSequenceError({ response: response.error }))
          )
        )
      )
    )
  );

  changeSequenceSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.ChangeSequenceSuccess),
      map(() => CountryActions.GetOverview())
    )
  );

  countryErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CountryActions.GetOverviewError,
          CountryActions.ChangeSequenceError,
          CountryActions.DeleteError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
