import { Component } from '@angular/core';
import * as ActivityLocation from '@app/modules/activity-location/reducers'
import { Store } from '@ngrx/store';
import * as ActivityLocationApiActions from '@app/modules/activity-location/actions/activity-location-api.actions';
import { State } from '@app/reducers';
import { ActivityLocationCreateModel, ActivityLocationUpdateModel } from '@app/modules/activity-location/models';

@Component({
    selector: 'app-activity-location-detail',
    templateUrl: './activity-location-detail.component.html',
    styleUrls: ['./activity-location-detail.component.less'],
})
export class ActivityLocationDetailComponent {
    loading$ = this.store.select(ActivityLocation.selectDetailLoading);
    errors$ = this.store.select(ActivityLocation.selectDetailErrors);
    activityLocation$ = this.store.select(ActivityLocation.selectDetailActivityLocation);

    constructor(private readonly store: Store<State>) { }

    submit(activityLocation: ActivityLocationCreateModel | ActivityLocationUpdateModel) {
        this.store.dispatch(activityLocation.hasOwnProperty('id')
            ? ActivityLocationApiActions.Update(activityLocation as ActivityLocationUpdateModel) 
            : ActivityLocationApiActions.Create(activityLocation)
        );
    }
}