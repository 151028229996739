import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FAQCategoryCreateModel } from '@app/modules/faq-categories/models/faq-category-create.model';
import { FAQCategoryUpdateModel } from '@app/modules/faq-categories/models/faq-category-update.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
  selector: 'app-faq-category-detail-form',
  templateUrl: './faq-category-detail-form.component.html'
})
export class FAQCategoryDetailFormComponent implements OnInit {
    update = false;
    readonly?: boolean;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) faqCategory: FAQCategoryUpdateModel | null = null;

    @Output() submitted = new EventEmitter<FAQCategoryCreateModel | FAQCategoryUpdateModel>();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        category: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
    });

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.faqCategory;

        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.FAQCategory, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        this.formGroup.patchValue({
            category: this.faqCategory?.category
        });

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.faqCategory as FAQCategoryUpdateModel).id);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            category: this.formGroup.controls.category.value ?? '',
        });
    }

    private submitUpdate() {
        this.submitted.emit(this.formGroup.value as FAQCategoryUpdateModel);
    }
}
