import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ReviewTableItemModel, ReviewChangeSequenceModel } from '@app/modules/review/models';
import { ClaimService } from '@app/shared/services/claim.service';

@Component({
	selector: 'app-review-overview-table',
	styleUrl: './review-overview-table.component.less',
	templateUrl: './review-overview-table.component.html',
})
export class ReviewOverviewTableComponent implements OnInit{
	expandSet = new Set<number>();
	onExpandChange(id: number, checked: boolean): void {
		if (checked) {
			this.expandSet.add(id);
		} else {
			this.expandSet.delete(id);
		}
	}

	@Input({ required: true }) data!: TableStateModel<ReviewTableItemModel>;
	@Input({ required: true }) isFiltered!: boolean;

	@Output() delete = new EventEmitter<number>();
	@Output() queryParamsChange = new EventEmitter<any>();
	@Output() changeSequence = new EventEmitter<ReviewChangeSequenceModel>();
	@Output() resetOverview = new EventEmitter<void>();
	@Output() changeOnline = new EventEmitter<{ id: number; online: boolean }>();
	@Output() draggingEnabledChange = new EventEmitter<boolean>();

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;
	draggingEnabled = false;
	readonly?: boolean;

    constructor(private readonly claimService: ClaimService) {}

	async ngOnInit(){
		if(!await this.claimService.hasAny([{ claimType: ClaimTypes.Review, claimValues: [ClaimValues.Update] }])) {
            this.readonly = true;
        } else {
            this.readonly = false;
        }
	}

	drop(event: CdkDragDrop<string[]>): void {
		this.changeSequence.emit({
			id: event.item.data.id,
			newSequenceNumber: event.currentIndex,
		});
	}

	draggingChanged() {
		if (this.draggingEnabled) {
			this.queryParamsChange.emit({
				pageIndex: 1,
				pageSize: -1,
				sort: [{key: 'sequenceNumber', value: 'ascend'}]
			});
		} else {
			this.resetOverview.emit();
		}

		this.draggingEnabledChange.emit(this.draggingEnabled);
	}

	setOnline(id: number, online: boolean) {
		this.changeOnline.emit({ id, online });
	}
}