<div>
    <form nz-form [formGroup]="formGroup">
        <nz-form-item>
            <nz-form-label [nzSpan]="24">
                <h3>{{ 'note.modal.edit.message' | translate }}</h3>
            </nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="noteMessageValidationTemplate">
                <textarea rows="4" nz-input formControlName="noteMessageEdit"></textarea>
                <ng-template #noteMessageValidationTemplate let-control>
                    <app-input-validation
                        [control]="control"
                        [field]="'noteMessageEdit'"
                        [errors]="this.errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24">
                <h3>{{ 'note.modal.edit.reason' | translate }}</h3>
            </nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="editReasonValidationTemplate">
                <textarea rows="4" nz-input formControlName="editReason"></textarea>
                <ng-template #editReasonValidationTemplate let-control>
                    <app-input-validation
                        [control]="control"
                        [field]="'editReason'"
                        [errors]="this.errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
