import { ErrorResponse } from "@app/core/models/error-response.model";
import { MaterialTableItemModel, MaterialUpdateModel, MaterialCreateModel } from "@app/modules/materials/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[Material] GetOverview");
export const GetOverviewError = createAction("[Material] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Material] GetOverviewSuccess", props<TableDataModel<MaterialTableItemModel>>());

export const FilterOverview = createAction("[Material] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[Material] Clear");

export const Get = createAction("[Material] Get", props<{ id: number }>());
export const GetError = createAction("[Material] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Material] GetSuccess", props<MaterialUpdateModel>());

export const Update = createAction("[Material] Update", props<MaterialUpdateModel>());
export const UpdateError = createAction("[Material] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Material] UpdateSuccess", props<MaterialUpdateModel>());

export const Create = createAction("[Material] Create", props<MaterialCreateModel>());
export const CreateError = createAction("[Material] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Material] CreateSuccess", props<MaterialUpdateModel>());

export const Delete = createAction("[Material] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Material] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Material] DeleteSuccess");

export const UpdateQuotation = createAction("[Material] UpdateQuotation", props<{ id: number, inQuotation: boolean }>());
export const UpdateQuotationError = createAction("[Material] UpdateQuotationError", props<{ response: ErrorResponse }>());
export const UpdateQuotationSuccess = createAction("[Material] UpdateQuotationSuccess", props<MaterialUpdateModel>());