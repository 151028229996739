import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'currency-input',
	templateUrl: './currency-input.component.html',
	styleUrls: ['./currency-input.component.less'],
})
export class CurrencyInputComponent {
	@Input({ required: true }) control!: FormControl<number | null>;
	@Input({ required: true }) errors!: { [key: string]: string[] };
	@Input({ required: true }) fieldName!: string;
	@Input({ required: true }) title!: string;

	@Output() controlChange = new EventEmitter<FormControl>();

	inputChanged() {
		this.control.updateValueAndValidity();
		this.control.markAsTouched();
		this.controlChange.emit(this.control);
	}
}
