<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>{{ (!!formGroup.get('id')?.value ? "relation-type.detail.edit-title" : "relation-type.detail.add-title") | translate }}</h4>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "common.name" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="nameValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.name" />

                <ng-template #nameValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'name'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "common.description" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="descriptionValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.description" />

                <ng-template #descriptionValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'description'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>
                {{ 'relation-type.fields.type' | translate }}
            </nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="typeValidationTemplate">
                <nz-select
                    class="w-100"
                    formControlName="type">
                    <nz-option
                        *ngFor="let type of types | enumKeyValue"
                        [nzLabel]="typeLabels[type.value] | translate"
                        [nzValue]="type.value"></nz-option>
                </nz-select>
                <ng-template #typeValidationTemplate let-control>
                    <app-input-validation
                        [control]="control"
                        [field]="'type'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <div class="button-container">
            <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
                {{ "common.save" | translate }}
            </button>
        </div>
    </nz-card>
</form>