import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-input-validation',
	templateUrl: './input-validation.component.html',
	styleUrls: ['./input-validation.component.less'],
	encapsulation: ViewEncapsulation.None,
})
export class InputValidationComponent {
	@Input({ required: true }) field!: string;
	@Input({ required: true }) control!: AbstractControl | FormControl | FormArray | FormGroup;

	@Input() errors: { [key: string]: string[] } = {};
}
