import * as BlogReactionActions from '@app/modules/blog-reaction/actions/blog-reaction-api.actions';
import { BlogReactionUpdateModel } from '@app/modules/blog-reaction/models';
import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
  blogreaction: null,
  loading: false,
  errors: {},
};

export interface State {
  blogreaction: BlogReactionUpdateModel | null;
  loading: boolean;
  errors: { [key: string]: string[] };
}

export const reducer = createReducer(
  initialState,
  on(BlogReactionActions.Clear, () => ({ ...initialState })),

  on(BlogReactionActions.Get, (state) => ({ ...state, loading: true })),
  on(BlogReactionActions.GetSuccess, (state, blogreaction) => ({
    ...state,
    blogreaction,
    loading: false,
  })),
  on(BlogReactionActions.GetError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(BlogReactionActions.Update, (state) => ({
    ...state,
    loading: true,
    errors: {},
  })),
  on(BlogReactionActions.UpdateSuccess, (state, blogreaction) => ({
    ...state,
    blogreaction,
    loading: false,
  })),
  on(BlogReactionActions.UpdateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  })),

  on(BlogReactionActions.Create, (state) => ({
    ...state,
    loading: true,
    errors: {},
  })),
  on(BlogReactionActions.CreateSuccess, (state, blogreaction) => ({
    ...state,
    blogreaction,
    loading: false,
  })),
  on(BlogReactionActions.CreateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  }))
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getBlogReaction = (state: State) => state.blogreaction;
