import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { EmailTemplateTableItemModel } from '@app/modules/email-template/models/email-template-table.model';
import { EmailTemplateType } from '@app/modules/email-template/enums';

@Component({
  selector: 'app-email-template-overview-table',
  templateUrl: './email-template-overview-table.component.html',
  styleUrl: './email-template-overview-table.component.less'
})
export class EmailTemplateOverviewTableComponent {
  @Input({ required: true }) data!: TableStateModel<EmailTemplateTableItemModel>;

	@Output() delete = new EventEmitter<number>();
	@Output() onQueryParamsChange = new EventEmitter<any>();

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;

	EmailTemplateType = EmailTemplateType;
}
