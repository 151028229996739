import { Component } from '@angular/core';
import * as ActivityTypeApiActions from '@app/modules/activity-type/actions/activity-type-api.actions';
import { ActivityTypeChangeSequenceModel } from '@app/modules/activity-type/models';
import * as ActivityType from '@app/modules/activity-type/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
	selector: 'app-activity-type-overview',
	templateUrl: './activity-type-overview.component.html',
	styleUrls: ['./activity-type-overview.component.less'],
})
export class ActivityTypeOverviewComponent {
	tableData$ = this.store.select(ActivityType.selectActivityTypeOverviewState);

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;

	constructor(private readonly store: Store) {}

	onDeleteConfirm(id: number): void {
		this.store.dispatch(ActivityTypeApiActions.Delete({ id: id }));
	}

	/**
	 * Trigger the loading of data whenever the table changes.
	 * The default pageSize, index and sort options are based on the TableStateModel.
	 * @param params
	 */
	onQueryParamsChange(params: NzTableQueryParams) {
		const { pageSize, pageIndex, sort, filter } = params;
		const currentSort = sort.find((item) => item.value !== null);
		const sortField = (currentSort?.key) ?? null;
		const sortOrder = (currentSort?.value) ?? null;

		this.store.dispatch(
			ActivityTypeApiActions.FilterOverview({
				pagination: {
					page: pageIndex,
					pageSize,
				},
				sorting: {
					propertyName: sortField ?? '',
					order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
				},
			})
		);
	}

	onResetOverview() {
		this.store.dispatch(ActivityTypeApiActions.ResetOverview());
	}

	onSequenceChange(request: ActivityTypeChangeSequenceModel) {
		this.store.dispatch(ActivityTypeApiActions.ChangeSequence(request));
	}
}
