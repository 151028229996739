import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { FAQCategoryChangeSequenceModel } from '@app/modules/faq-categories/models/faq-category-change-sequence.model';
import { FAQCategoryCreateModel } from '@app/modules/faq-categories/models/faq-category-create.model';
import { FAQCategoryTableItemModel } from '@app/modules/faq-categories/models/faq-category-table.model';
import { FAQCategoryUpdateModel } from '@app/modules/faq-categories/models/faq-category-update.model';
import { TableDataModel } from '@app/shared/models/table/table-data.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FAQCategoryService {
	private readonly endpoints = {
		get: 'FAQCategory/:id',
		overview: 'FAQCategory/GetOverview',
		update: 'FAQCategory/Update',
		create: 'FAQCategory/Create',
		delete: 'FAQCategory/Delete?id=:id',
		changeSequence: 'FAQCategory/ChangeSequence',
		getAll: 'FAQCategory/GetAll'
	};

	constructor(private readonly httpService: HttpService) {}

	getOverview(
		sorting: TableSortingModel,
		pagination: TablePaginationModel
	): Observable<TableDataModel<FAQCategoryTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');

		return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}`);
	}

	get(id: number): Observable<FAQCategoryUpdateModel> {
		return this.httpService.get<any>(this.endpoints.get.replace(':id', id.toString()));
	}

	update(request: FAQCategoryUpdateModel): Observable<FAQCategoryUpdateModel> {
		return this.httpService.put<any>(this.endpoints.update, request);
	}

	create(request: FAQCategoryCreateModel): Observable<FAQCategoryUpdateModel> {
		return this.httpService.post<any>(this.endpoints.create, request);
	}

	delete(id: number): Observable<any> {
		return this.httpService.delete<any>(this.endpoints.delete.replace(':id', id.toString()));
	}

	changeSequence(request: FAQCategoryChangeSequenceModel): Observable<any> {
		return this.httpService.put<any>(this.endpoints.changeSequence, request);
	}

	getAll(): Observable<any> {
		return this.httpService.get<any>(this.endpoints.getAll);
	}
}
