<div class="page-header">
  <a [routerLink]="['/blogs']" class="page-header__back">
    <i class="fa-regular fa-chevron-left"></i>
  </a>
  <h1 class="page-header__title">{{ "blog-reactions.title" | translate }}</h1>
  <div class="page-header__actions" *ngIf="this.tableData$ | async">
    <a
      claimDirective
      [claims]="[
        {
          claimType: ClaimTypes.BlogReactions,
          claimValues: [ClaimValues.Create]
        }
      ]"
      [routerLink]="['/blogs', blogId, 'blog-reacties', 'toevoegen']"
      nz-button
      nzType="primary"
    >
      {{ "common.add" | translate }}
      <i class="fa-regular fa-plus"></i>
    </a>
  </div>
</div>

<app-blog-reaction-overview-table
  [data]="(tableData$ | async)!"
  (delete)="onDeleteConfirm($event)"
  (changeShowOnline)="onChangeShowOnline($event)"
  (queryParamsChange)="onQueryParamsChange($event)"
/>
