import { Component, OnDestroy } from '@angular/core';
import * as FAQActions from '@app/modules/faq/actions/faq.actions';
import { FAQChangeSequenceModel } from '@app/modules/faq/models';
import * as FAQ from '@app/modules/faq/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription, take } from 'rxjs';

@Component({
    selector: 'app-faq-overview',
    templateUrl: './faq-overview.component.html',
    styleUrls: ['./faq-overview.component.less'],
})
export class FAQOverviewComponent implements OnDestroy {
    tableData$ = this.store.select(FAQ.selectFAQOverviewState);
    faqCategories$ = this.store.select(FAQ.selectFAQOverviewFAQCategories);
    selectedFaqCategoryId$ = this.store.select(FAQ.selectDetailFAQCategoryId);

    enableCategories: boolean = false;
    faqCategories: KeyValueModel[] = [];
    categoryId: number = -1;
    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    private subscriptions = new Subscription();

    constructor(private readonly store: Store) {
        this.store.dispatch(FAQActions.GetFAQCategories());
        this.selectedFaqCategoryId$.subscribe((catId) => {
            this.categoryId = catId ?? -1;
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onCategoryChange(faqCategoryId: number) {
        this.store.dispatch(FAQActions.SetActiveFAQCategoryId({ faqCategoryId }));

        this.store
            .select(FAQ.selectFAQOverviewSorting)
            .pipe(take(1))
            .subscribe((sort) => {
                this.store.dispatch(
                    FAQActions.FilterOverview({
                        categoryId: faqCategoryId,
                        pagination: { page: 1, pageSize: 10 },
                        sorting: sort,
                    }),
                );
            });
    }

    onDeleteConfirm(id: number): void {
        this.store.dispatch(FAQActions.Delete({ id: id }));
    }

    changeDragDrop(dragDropEnabled: boolean) {
        this.enableCategories = !dragDropEnabled;
    }
    /**
     * Trigger the loading of data whenever the table changes.
     * The default pageSize, index and sort options are based on the TableStateModel.
     * @param params
     */
    onQueryParamsChange(params: NzTableQueryParams) {
        const { pageSize, pageIndex, sort } = params;
        const currentSort = sort.find((item) => item.value !== null);
        const sortField = (currentSort?.key) ?? null;
        const sortOrder = (currentSort?.value) ?? null;

        this.store.dispatch(
            FAQActions.FilterOverview({
                categoryId: this.categoryId,
                pagination: {
                    page: pageIndex,
                    pageSize,
                },
                sorting: {
                    propertyName: sortField ?? '',
                    order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
                },
            }),
        );
    }

    onResetOverview() {
        this.store.dispatch(FAQActions.ResetOverview());
    }

    onSequenceChange(request: FAQChangeSequenceModel) {
        this.store.dispatch(FAQActions.ChangeSequence(request));
    }

    onOnlineChange(request: { id: number; online: boolean }) {
        this.store.dispatch(FAQActions.UpdateOnline(request));
    }
}
