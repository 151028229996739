import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as PageActions from '@app/modules/page/actions/page-api.actions';
import { SlugStatus } from '@app/modules/page/enums/slug-status';
import { PageUpdateModel } from '@app/modules/page/models';
import * as Page from '@app/modules/page/reducers';
import { PageService } from '@app/modules/page/services/page.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class PageEffects {
  constructor(
    private actions$: Actions,
    private pageService: PageService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<Page.State>
  ) {}

  /**
   * Get effects
   */
  loadPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(Page.selectPageOverviewPagination),
        this.store.select(Page.selectPageOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.pageService.getAll(sorting, pagination).pipe(
          map((response) => PageActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(PageActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadPagesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.FilterOverview),
      map(() => PageActions.GetOverview())
    )
  );

  loadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.Get),
      exhaustMap((action) =>
        this.pageService.get(action.id).pipe(
          map((response) => PageActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(PageActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.Create),
      exhaustMap((action) =>
        this.pageService.create(action).pipe(
          map((page) => PageActions.CreateSuccess(page)),
          catchError((response: { error: ErrorResponse }) =>
            of(PageActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createPageSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PageActions.CreateSuccess),
        tap((page) =>
          this.translate
            .get('notification.add', { item: page.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updatePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.Update),
      exhaustMap((action) =>
        this.pageService.update(action).pipe(
          map((page) => PageActions.UpdateSuccess(page)),
          catchError((response: { error: ErrorResponse }) =>
            of(PageActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updatePageSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PageActions.UpdateSuccess),
        tap((page) =>
          this.translate
            .get('notification.save', { item: page.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdatePageSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PageActions.CreateSuccess, PageActions.UpdateSuccess),
        tap(() => this.router.navigate(['paginas']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deletePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.Delete),
      exhaustMap((action) =>
        this.pageService.delete(action.id).pipe(
          map(() => PageActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(PageActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deletePageSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.DeleteSuccess),
      map(() => PageActions.GetOverview())
    )
  );

  deletePageSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PageActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  pageErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PageActions.DeleteError,
          PageActions.ChangeSequenceError,
          PageActions.GetOverviewError,
          PageActions.ChangeVisibilityError,
          PageActions.DeleteChildPageError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change visibility effects
   */
  changeVisibilityPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.ChangeVisibility),
      exhaustMap((action) =>
        this.pageService.changePageVisibility(action).pipe(
          map(() => PageActions.ChangeVisibilitySuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(PageActions.ChangeVisibilityError({ response: response.error }))
          )
        )
      )
    )
  );

  changeVisibilitySuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.ChangeVisibilitySuccess),
      map(() => PageActions.GetOverview())
    )
  );

  changeVisibilitySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PageActions.ChangeVisibilitySuccess),
        tap(() =>
          this.translate
            .get('pages.notification.save')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  changeSequenceChildPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.ChangeSequence),
      exhaustMap((action) =>
        this.pageService.changeSequence(action).pipe(
          map(() =>
            PageActions.ChangeSequenceSuccess({ parentId: action.parentId })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(PageActions.ChangeSequenceError({ response: response.error }))
          )
        )
      )
    )
  );

  changeSequenceSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.ChangeSequenceSuccess),
      map((action) => PageActions.GetChildPages({ parentId: action.parentId }))
    )
  );

  loadChildPages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.GetChildPages),
      concatLatestFrom(() => [this.store.select(Page.selectDetailChildPages)]),
      exhaustMap(([{ parentId }, childPages]) =>
        this.pageService
          .getChildPages(childPages.sorting, childPages.pagination, parentId)
          .pipe(
            map((response) => PageActions.GetChildPagesSuccess(response)),
            catchError((response: { error: ErrorResponse }) =>
              of(PageActions.GetChildPagesError({ response: response.error }))
            )
          )
      )
    )
  );

  onPageLoadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.GetSuccess),
      map((action) => PageActions.GetChildPages({ parentId: action.id }))
    )
  );

  loadChildPagesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.ChildPagesFilterOverview),
      map((action) => PageActions.GetChildPages({ parentId: action.parentId }))
    )
  );

  deleteChildPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.DeleteChildPage),
      exhaustMap((action) =>
        this.pageService.delete(action.id).pipe(
          map(() =>
            PageActions.DeleteChildPageSuccess({ parentId: action.parentId })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(PageActions.DeleteChildPageError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteChildPageSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.DeleteChildPageSuccess),
      map((action) => PageActions.GetChildPages({ parentId: action.parentId }))
    )
  );

  newSlugAsRedirectExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PageActions.NewSlugAsRedirectExists),
      exhaustMap((action) => {
        const pageId = action.model.hasOwnProperty('id')
          ? (action.model as PageUpdateModel).id
          : null;

        return this.pageService
          .newSlugAsRedirectExists(
            action.model.slug,
            action.model.parentId,
            pageId
          )
          .pipe(
            map((response) => {
              if (response.slugStatus !== SlugStatus.NO_CONFLICTS) {
                return PageActions.NewSlugAsRedirectExistsSuccess({
                  response: response.slugStatus,
                });
              }
              return action.model.hasOwnProperty('id')
                ? PageActions.Update(action.model as PageUpdateModel)
                : PageActions.Create(action.model);
            }),
            catchError((response: { error: ErrorResponse }) =>
              of(
                PageActions.RedirectFromExistsError({
                  response: response.error,
                })
              )
            )
          );
      })
    )
  );
}
