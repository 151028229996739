import { Injectable } from '@angular/core';
import { ErrorResponse } from '@app/core/models';
import * as NoteActions from '@app/modules/note/actions/note-api.actions';
import * as Note from '@app/modules/note/reducers';
import { NoteService } from '@app/modules/note/services/note.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class NoteEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly noteService: NoteService,

        private readonly store: Store<Note.State>,

        private readonly messageService: NzMessageService,
        private readonly translate: TranslateService,
    ) {}

    /**
     * get backgrounds effects
     */
    loadNoteList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.GetOverview),
            concatLatestFrom(() => [this.store.select(Note.selectNoteType), this.store.select(Note.selectNoteTypeId)]),
            exhaustMap(([, noteType, noteTypeId]) =>
                this.noteService.getActivityNotesList(noteTypeId, noteType).pipe(
                    map((data) => NoteActions.GetOverviewSuccess({ data })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(NoteActions.GetOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create effects
     */

    createNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.Create),
            exhaustMap((action) =>
                this.noteService.create(action).pipe(
                    map(() => {
                        return NoteActions.CreateSuccess();
                    }),
                    catchError((response: { error: ErrorResponse }) =>
                        of(NoteActions.CreateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    createNoteSuccessToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.CreateSuccess),
            map(() => NoteActions.GetOverview()),
            tap(() =>
                this.translate
                    .get('note.modal.notification.add')
                    .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
            ),
        ),
    );

    /**
     *  Update
     */

    updateNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.Update),
            exhaustMap((action) =>
                this.noteService.update(action.data).pipe(
                    map(() => {
                        return NoteActions.UpdateSuccess();
                    }),
                    catchError((response: { error: ErrorResponse }) =>
                        of(NoteActions.UpdateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    updateNoteSuccessToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.UpdateSuccess),
            map(() => NoteActions.GetOverview()),
            tap(() =>
                this.translate
                    .get('note.modal.notification.update')
                    .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
            ),
        ),
    );

    /**
     * Change active effects
     */
    changeActiveBlog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.SetImportant),
            exhaustMap((action) =>
                this.noteService.setImportant(action.noteId).pipe(
                    map(() => NoteActions.SetImportantSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(NoteActions.SetImportantError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    changeActiveSuccessToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.SetImportantSuccess),
            map(() => NoteActions.GetOverview()),
            tap(() =>
                this.translate
                    .get('note.modal.notification.update')
                    .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
            ),
        ),
    );
    /**
     * Delete effects
     */
    deleteNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.Delete),
            exhaustMap((action) =>
                this.noteService.delete(Number(action.noteId)).pipe(
                    map(() => NoteActions.DeleteSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(NoteActions.DeleteError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    deleteNoteSuccessReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.DeleteSuccess),
            map(() => NoteActions.GetOverview()),
        ),
    );

    deleteNoteSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NoteActions.DeleteSuccess),
                tap(() =>
                    this.translate
                        .get('note.modal.notification.delete')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );
}
