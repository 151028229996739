import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as ActivityTypeOverview from './activity-type-overview.reducer'
import * as ActivityTypeDetailPage from './activity-type-detail-page.reducer'

export const activityTypeFeatureKey = 'activityType';

export interface ActivityTypeState {
    activityTypeOverview: ActivityTypeOverview.State,
    activityTypeDetail: ActivityTypeDetailPage.State,
}

export interface State {
    activityType: ActivityTypeState
}

const map: ActionReducerMap<ActivityTypeState, Action> = {
    activityTypeOverview: ActivityTypeOverview.reducer,
    activityTypeDetail: ActivityTypeDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: ActivityTypeState, action: Action) => combineReducers(map)(state, action);

const selectActivityTypeState = createFeatureSelector<ActivityTypeState>(activityTypeFeatureKey);

export const selectState = createSelector(selectActivityTypeState, state => state)
export const selectActivityTypeOverviewState = createSelector(selectState, state => state.activityTypeOverview)

export const selectActivityTypeOverviewLoading = createSelector(selectActivityTypeOverviewState, ActivityTypeOverview.getLoading)
export const selectActivityTypeOverviewList = createSelector(selectActivityTypeOverviewState, ActivityTypeOverview.getList)
export const selectActivityTypeOverviewPagination = createSelector(selectActivityTypeOverviewState, ActivityTypeOverview.getPagination)
export const selectActivityTypeOverviewSorting = createSelector(selectActivityTypeOverviewState, ActivityTypeOverview.getSorting)

export const selectActivityTypeDetailState = createSelector(selectState, state => state.activityTypeDetail);

export const selectDetailLoading = createSelector(selectActivityTypeDetailState, ActivityTypeDetailPage.getLoading);
export const selectDetailActivityType = createSelector(selectActivityTypeDetailState, ActivityTypeDetailPage.getActivityType);
export const selectDetailErrors = createSelector(selectActivityTypeDetailState, ActivityTypeDetailPage.getErrors);