<div class="page-header">
  <a routerLink="/blogs/overzicht" class="page-header__back">
    <i class="fa-regular fa-chevron-left"></i>
  </a>
  <h1 class="page-header__title">{{ "blog.title" | translate }}</h1>
  <div class="page-header__actions"></div>
</div>

<app-blog-detail-form
  [blog]="blog$ | async"
  (submitted)="submit($event)"
  [errors]="errors$ | async"
  [loading]="loading$ | async"
  [employees]="employees$ | async"
  [tags]="tags$ | async"
  (searchTags)="tagSearch($event)"
  [isLoading]="loading$ | async"
/>
