import { Component, OnInit } from '@angular/core';
import * as Auth from '@app/modules/auth/reducers';
import * as UserActions from '@app/modules/users/actions/user-api.actions';
import { UserType } from '@app/modules/users/enums/user-type';
import * as User from '@app/modules/users/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { firstValueFrom } from 'rxjs';

@Component({
	selector: 'app-user-overview',
	templateUrl: './user-overview.component.html',
	styleUrls: ['./user-overview.component.less'],
})
export class UserOverviewComponent implements OnInit {
	tableData$ = this.store.select(User.selectUserOverviewState);
	currentUser$ = this.store.select(Auth.selectUser);
	selectedUserTypes: UserType[] = [];

	ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
	UserType = UserType;

	constructor(private readonly store: Store) {}
	
	async ngOnInit(): Promise<void> {
		this.selectedUserTypes = await firstValueFrom(this.store.select(User.selectUserOverviewUserTypes));
	}

	onUserTypesChange(userTypes: UserType[]) {
		userTypes = userTypes.length ? userTypes : [UserType.All];
		this.store.dispatch(UserActions.SetUserTypes({ userTypes: userTypes }));
		this.store.dispatch(
			UserActions.FilterOverview({
				userTypes: userTypes,
				pagination: { page: 1, pageSize: 10 },
			})
		);
	}

	onDeleteConfirm(id: string): void {
		this.store.dispatch(UserActions.Delete({ id: id }));
	}

	onChangeActive(event: any): void {
		this.store.dispatch(UserActions.UpdateActive({ id: event.id, active: event.active }));
	}

	/**
	 * Trigger the loading of data whenever the table changes.
	 * The default pageSize, index and sort options are based on the TableStateModel.
	 * @param params
	 */
	onQueryParamsChange(params: NzTableQueryParams) {
		const { pageSize, pageIndex, sort, filter } = params;
		const currentSort = sort.find((item) => item.value !== null);
		const sortField = (currentSort?.key) ?? null;
		const sortOrder = (currentSort?.value) ?? null;

		this.store.dispatch(
			UserActions.FilterOverview({
				userTypes: this.selectedUserTypes,
				pagination: {
					page: pageIndex,
					pageSize,
				},
				sorting: {
					propertyName: sortField ?? '',
					order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
				},
			})
		);
	}
}
