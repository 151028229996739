<div [formGroup]="formGroup">
    <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>{{ 'content-block.fields.imageId' | translate }}</nz-form-label>
        <nz-form-control [nzSpan]="24" [nzErrorTip]="imageIdValidationTemplate">
            <input nz-input formControlName="imageId" />

            <ng-template #imageIdValidationTemplate let-control>
                <app-input-validation [control]="control" [field]="'imageId'" [errors]="errors"></app-input-validation>
            </ng-template>
        </nz-form-control>
    </nz-form-item>

    <app-editor [control]="formGroup.controls['editor']" [isDraggable]="!isReadOnly" [isReadOnly]="isReadOnly" nz-input ngDefaultControl></app-editor>
</div>