import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as AuthActions from '@app/modules/auth/actions/auth.actions';
import * as Auth from '@app/modules/auth/reducers';
import { parseToken } from '@app/shared/utilities/token.utilities';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-password-reset-page',
	templateUrl: './password-reset-page.component.html',
	styleUrls: ['./password-reset-page.component.less'],
})
export class PasswordResetPageComponent {
	loading$ = this.store.select(Auth.selectPasswordResetPageLoading);
	errors$ = this.store.select(Auth.selectPasswordResetPageErrors);
	success$ = this.store.select(Auth.selectPasswordResetPageSuccess);

	private email = '';
	private token = '';

	constructor(private readonly store: Store<Auth.State>, private readonly router: Router) {
		this.router.routerState.root.queryParams.subscribe((params) => {
			this.email = params['email'];
			this.token = parseToken(params['token']);
		});
	}

	resetPassword(password: string) {
		this.store.dispatch(AuthActions.ResetPassword({ email: this.email, password: password, token: this.token }));
	}
}
