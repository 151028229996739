import { ErrorResponse } from "@app/core/models/error-response.model";
import { BlogTableItemModel, BlogUpdateModel, BlogCreateModel } from "@app/modules/blogs/models";
import { BlogChangeShowOnlineModel } from "@app/modules/blogs/models/blog-change-online.model";
import { TableDataModel } from "@app/shared/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[Blog] GetOverview");
export const GetOverviewError = createAction("[Blog] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Blog] GetOverviewSuccess", props<TableDataModel<BlogTableItemModel>>());
export const ResetOverview = createAction("[Blog] ResetOverview");

export const FilterOverview = createAction("[Blog] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[Blog] Clear");

export const Get = createAction("[Blog] Get", props<{ id: number }>());
export const GetError = createAction("[Blog] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Blog] GetSuccess", props<BlogUpdateModel>());

export const Update = createAction("[Blog] Update", props<BlogUpdateModel>());
export const UpdateError = createAction("[Blog] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Blog] UpdateSuccess", props<BlogUpdateModel>());

export const Create = createAction("[Blog] Create", props<BlogCreateModel>());
export const CreateError = createAction("[Blog] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Blog] CreateSuccess", props<BlogUpdateModel>());

export const Delete = createAction("[Blog] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Blog] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Blog] DeleteSuccess");

export const UpdateShowOnline = createAction("[Blog] Update ShowOnline", props<BlogChangeShowOnlineModel>());
export const UpdateShowOnlineError = createAction("[Blog] UpdateShowOnlineError", props<{ response: ErrorResponse }>());
export const UpdateShowOnlineSuccess = createAction("[Blog] UpdateShowOnlineSuccess", props<BlogChangeShowOnlineModel>());

export const GetEmployees = createAction("[Blog] GetEmployees");
export const GetEmployeesError = createAction("[Blog] GetEmployeesError", props<{ response: ErrorResponse }>());
export const GetEmployeesSuccess = createAction("[Blog] GetEmployeesSuccess",  props<{ employees: KeyValueModel[] }>());

export const GetAllTags = createAction("[Blog] GetAllTags", props<{ searchQuery: string, selectedTags: string[]}>());
export const GetAllTagsError = createAction("[Blog] GetAllTagsError", props<{ response: ErrorResponse }>());
export const GetAllTagsSuccess = createAction("[Blog] GetAllTagsSuccess",  props<{ tags: KeyValueModel[] }>());