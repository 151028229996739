import { ErrorResponse } from "@app/core/models/error-response.model";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";
import {BlogCategoryTableItemModel} from "@app/modules/blog-category/models/blog-category-table.model";
import {BlogCategoryUpdateModel} from "@app/modules/blog-category/models/blog-category-update.model";
import {BlogCategoryChangeSequenceModel} from "@app/modules/blog-category/models/blog-category-change-sequence.model";
import {BlogCategoryCreateModel} from "@app/modules/blog-category/models/blog-category-create.model";

export const GetOverview = createAction("[Blog Category] GetOverview");
export const GetOverviewError = createAction("[Blog Category] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Blog Category] GetOverviewSuccess", props<TableDataModel<BlogCategoryTableItemModel>>());
export const ResetOverview = createAction("[Blog Category] ResetOverview");

export const FilterOverview = createAction("[Blog Category] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[Blog Category] Clear");

export const Get = createAction("[Blog Category] Get", props<{ id: number }>());
export const GetError = createAction("[Blog Category] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Blog Category] GetSuccess", props<BlogCategoryUpdateModel>());

export const Update = createAction("[Blog Category] Update", props<BlogCategoryUpdateModel>());
export const UpdateError = createAction("[Blog Category] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Blog Category] UpdateSuccess", props<BlogCategoryUpdateModel>());

export const ChangeSequence = createAction("[Blog Category] ChangeSequence", props<BlogCategoryChangeSequenceModel>());
export const ChangeSequenceError = createAction("[Blog Category] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[Blog Category] ChangeSequenceSuccess");

export const Create = createAction("[Blog Category] Create", props<BlogCategoryCreateModel>());
export const CreateError = createAction("[Blog Category] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Blog Category] CreateSuccess", props<BlogCategoryUpdateModel>());

export const Delete = createAction("[Blog Category] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Blog Category] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Blog Category] DeleteSuccess");
