<nz-form-item>
    <nz-form-label [nzSpan]="24" [nzRequired]="this.required">{{ "common.assets" | translate }}
    </nz-form-label>
    <nz-form-control [nzSpan]="24">
        <app-input-validation [control]="formArray" [field]="fieldName" [errors]="errors"></app-input-validation>

        <div *ngIf="!isReadOnly" class="button-container">
            <button nz-button nzType="primary" (click)="addAsset()"
                [disabled]="(this.maxAssets > 0 && formArray.controls.length! >= this.maxAssets)">{{ "common.add" | translate }}</button>
        </div>
        <nz-list nzBordered>
            @for (formGroup of formArray.controls; track formGroup; let i = $index) {
                <nz-list-item>
                    <a [href]="formGroup.value.url" target="_blank"><i class="fa-regular fa-download"></i> {{ formGroup.value.name }}</a>
                    <button nz-button nzType="text" class="error" (click)="removeAsset(i)" *ngIf="!isReadOnly">
                        <i class="fa-regular fa-trash-can"></i>
                        {{ "common.delete" | translate }}
                    </button>
                </nz-list-item>
            }
            <nz-list-footer *ngIf="formArray.controls!.length === 0">
                {{ "common.assets-placeholder" | translate }}
            </nz-list-footer>
        </nz-list>
    </nz-form-control>
</nz-form-item>