import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AssetService } from '@app/shared/services/asset.service';
import { Editor } from 'tinymce';

@Component({
  selector: 'app-editor',
  templateUrl: './WYSIWYG-editor.component.html',
  styleUrls: ['./WYSIWYG-editor.component.less'],
})
export class WYSIWYGEditorComponent implements OnInit {
  @Input({ required: true }) control!: AbstractControl<string | null>;
  @Input({ required: true }) isDraggable!: boolean;
  @Input({ required: true }) isReadOnly!: boolean;
  @Input() simpleEditor: boolean = false;

  public tinyMceConfig?: any;

  constructor(private readonly assetService: AssetService) {}

  ngOnInit(): void {
    this.tinyMceConfig = {
      base_url: '/tinymce',
      suffix: '.min',
      license_key: 'gpl',
      menubar: this.simpleEditor ? '' : 'edit view insert format',
      branding: false,
      promotion: false,
      editable_root: !this.isReadOnly,
      plugins: 'wordcount',
      toolbar: this.simpleEditor
        ? 'bold italic underline strikethrough | outdent indent'
        : 'undo redo | blocks | bold italic | assetButton | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
      setup: (editor: Editor) => {
        editor.ui.registry.addButton('assetButton', {
          text: '',
          tooltip: '',
          icon: 'image',
          enabled: !this.isReadOnly,
          onAction: () => {
            this.assetService.chooseFile(false, (files) => {
              files.forEach((file) => {
                editor.insertContent(`<img src="${file.url}" />`);
              });
            });
          },
        });
      },
    };
  }

  inputChanged(event: any): void {
    this.control.setValue(event);
    this.control.markAsDirty();
  }
}
