export function parse(filter?: any, prefix?: string): URLSearchParams {
  if (filter == null) {
    return new URLSearchParams();
  }

  let queryParamsObject = {};
  if(filter.length){
    queryParamsObject = parseList(filter, prefix);
  }
  else{
    queryParamsObject = parseItem(filter, prefix);
  }

  return new URLSearchParams(queryParamsObject)
}

function parseItem(filter?: any, prefix?: string): any {
  let queryParamsObject = {};

  for (const key in filter) {
    const value = filter[key];
    let newKey;

    if (prefix) {
      newKey = `${prefix}.${key}`;
    } else {
      newKey = key;
    }

    if (isNullOrWhitespace(value)) {
      continue;
    }

    queryParamsObject = {
      ...queryParamsObject,
      [newKey]: value.toString()
    }
  }

  return queryParamsObject;
}

function parseList(filter?: any, prefix?: string): any {  
  let queryParamsObject = {};
  for (const key in filter) {
    const value = filter[key];
    let newKey;

    if (prefix) {
      newKey = `${prefix}[${key}]`;
    } else {
      newKey = `[${key}]`;
    }

    if (isNullOrWhitespace(value)) {
      continue;
    }

    const item =  parseItem(value, newKey);

    queryParamsObject = {
      ...queryParamsObject,
      ...item
    }
  }

  return queryParamsObject;
}

function isNullOrWhitespace(str: any | null) {
  return (
    str == null ||
    String(str).match(/\S+/) == null
  );
}
