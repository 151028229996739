import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
  NewsChangeSequenceModel,
  NewsCreateModel,
  NewsTableItemModel,
  NewsUpdateModel,
} from '@app/modules/news/models';
import { NewsChangeShowOnlineModel } from '@app/modules/news/models/news-change-online.model';
import { TableDataModel } from '@app/shared/models';
import { serializeContentBlocks } from '@app/shared/models/content';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private readonly endpoints = {
    get: 'News/:id',
    overview: 'News/GetOverview',
    update: 'News/Update',
    create: 'News/Create',
    delete: 'News/Delete?id=:id',
    showOnline: 'News/ShowOnline',
    changeSequence: 'News/ChangeSequence',
  };

  constructor(private readonly httpService: HttpService) {}

  getAll(
    sorting: TableSortingModel,
    pagination: TablePaginationModel
  ): Observable<TableDataModel<NewsTableItemModel>> {
    const paginationParams = parse(pagination, 'pagination');
    const sortingParams = parse(sorting, 'sorting');

    return this.httpService.get<TableDataModel<NewsTableItemModel>>(
      this.endpoints.overview + `?${paginationParams}&${sortingParams}`
    );
  }

  get(id: number): Observable<NewsUpdateModel> {
    return this.httpService.get<NewsUpdateModel>(
      this.endpoints.get.replace(':id', id.toString())
    );
  }

  update(request: NewsUpdateModel): Observable<NewsUpdateModel> {
    return this.httpService.put<NewsUpdateModel>(this.endpoints.update, serializeContentBlocks(request));
  }

  create(request: NewsCreateModel): Observable<NewsUpdateModel> {
    return this.httpService.post<NewsUpdateModel>(this.endpoints.create, serializeContentBlocks(request));
  }

  delete(id: number): Observable<void> {
    return this.httpService.delete<void>(
      this.endpoints.delete.replace(':id', id.toString())
    );
  }

  changeSequence(request: NewsChangeSequenceModel): Observable<NewsChangeSequenceModel> {
    return this.httpService.put<NewsChangeSequenceModel>(this.endpoints.changeSequence, request);
  }

  showOnline(request: NewsChangeShowOnlineModel): Observable<NewsChangeShowOnlineModel> {
    return this.httpService.put<NewsChangeShowOnlineModel>(this.endpoints.showOnline, request);
  }
}