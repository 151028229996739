import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MaterialTableItemModel } from '@app/modules/materials/models/material-table.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
  selector: 'app-material-overview-table',
  templateUrl: './material-overview-table.component.html',
})
export class MaterialOverviewTableComponent{
  @Input({ required: true }) data!: TableStateModel<MaterialTableItemModel>;

  @Output() delete = new EventEmitter<number>();
  @Output() onChangeQuotation = new EventEmitter<{
    id: number;
    inQuotation: boolean;
  }>();
  @Output() onQueryParamsChange = new EventEmitter<any>();

  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;
  maxDescriptionLength: number = 30;

  changeQuotation(id: number, inQuotation: boolean) {
    this.onChangeQuotation.emit({ id, inQuotation });
  }
}
