import { Component } from '@angular/core';
import * as News from '@app/modules/news/reducers'
import { Store } from '@ngrx/store';
import * as NewsApiActions from '@app/modules/news/actions/news-api.actions';
import { State } from '@app/reducers';
import { NewsCreateModel, NewsUpdateModel } from '@app/modules/news/models';

@Component({
    selector: 'app-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.less'],
})
export class NewsDetailComponent {
    loading$ = this.store.select(News.selectDetailLoading);
    errors$ = this.store.select(News.selectDetailErrors);
    news$ = this.store.select(News.selectDetailNews);

    constructor(private readonly store: Store<State>) { }

    submit(news: NewsCreateModel | NewsUpdateModel) {
        this.store.dispatch(Object.prototype.hasOwnProperty.call(news, 'id')
            ? NewsApiActions.Update(news as NewsUpdateModel) 
            : NewsApiActions.Create(news)
        );
    }
}