import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { activityTypeClearGuard, activityTypeExistsGuard } from '@app/modules/activity-type/guards';
import { ActivityTypeDetailComponent } from '@app/modules/activity-type/pages/detail/activity-type-detail.component';
import { ActivityTypeOverviewComponent } from '@app/modules/activity-type/pages/overview/activity-type-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: ActivityTypeOverviewComponent },
    { path: 'toevoegen', component: ActivityTypeDetailComponent, canActivate: [activityTypeClearGuard] },
    { path: 'bewerken/:id', component: ActivityTypeDetailComponent, canActivate: [activityTypeExistsGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ActivityTypeRoutingModule { }
