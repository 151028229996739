import { Component } from '@angular/core';
import * as Auth from '@app/modules/auth/reducers';
import { Store } from '@ngrx/store';
import * as AuthActions from '@app/modules/auth/actions/auth.actions';

@Component({
	selector: 'app-password-forget-page',
	templateUrl: './password-forget-page.component.html',
	styleUrls: ['./password-forget-page.component.less'],
})
export class PasswordForgetPageComponent {
	constructor(private readonly store: Store<Auth.State>) {}

	submit(email: string) {
        this.store.dispatch(AuthActions.RequestPasswordReset({ email }));
	}
}
