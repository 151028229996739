import { NgModule } from '@angular/core';
import { RedirectDetailFormComponent } from '@app/modules/redirects/components/redirect-detail-form/redirect-detail-form.component';
import { RedirectOverviewTableComponent } from '@app/modules/redirects/components/redirect-overview-table/redirect-overview-table.component';
import { RedirectEffects } from '@app/modules/redirects/effects/redirect.effects';
import { RedirectDetailComponent } from '@app/modules/redirects/pages/detail/redirect-detail.component';
import { RedirectOverviewComponent } from '@app/modules/redirects/pages/overview/redirect-overview.component';
import { RedirectRoutingModule } from '@app/modules/redirects/redirect-routing.module';
import { redirectFeatureKey, reducers } from '@app/modules/redirects/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
	RedirectDetailComponent,
	RedirectDetailFormComponent,
	RedirectOverviewTableComponent,
	RedirectOverviewComponent,
];

const IMPORTS = [
	SharedModule,
	RedirectRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(redirectFeatureKey, reducers),
	EffectsModule.forFeature([RedirectEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class RedirectsModule {}
