import * as BlogCategoryApiActions from "@app/modules/blog-category/actions/blog-category-api.actions"
import { createReducer, on } from "@ngrx/store";
import { BlogCategoryUpdateModel } from "@app/modules/blog-category/models/blog-category-update.model";

export const initialState: State = {
  blogCategory: null,
    loading: false,
    errors: {}
}

export interface State {
    blogCategory: BlogCategoryUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(BlogCategoryApiActions.Clear, () => ({ ...initialState })),

    on(BlogCategoryApiActions.Get, (state) => ({ ...state, loading: true })),
    on(BlogCategoryApiActions.GetSuccess, (state, blogCategory) => ({ ...state, blogCategory, loading: false })),
    on(BlogCategoryApiActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(BlogCategoryApiActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(BlogCategoryApiActions.UpdateSuccess, (state, blogCategory) => ({ ...state, blogCategory, loading: false })),
    on(BlogCategoryApiActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(BlogCategoryApiActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(BlogCategoryApiActions.CreateSuccess, (state, blogCategory) => ({ ...state, blogCategory, loading: false })),
    on(BlogCategoryApiActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getBlogCategory = (state: State) => state.blogCategory;
