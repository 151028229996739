import { Component } from '@angular/core';
import * as ActivityType from '@app/modules/activity-type/reducers'
import { Store } from '@ngrx/store';
import * as ActivityTypeApiActions from '@app/modules/activity-type/actions/activity-type-api.actions';
import { State } from '@app/reducers';
import { ActivityTypeCreateModel, ActivityTypeUpdateModel } from '@app/modules/activity-type/models';

@Component({
    selector: 'app-activity-type-detail',
    templateUrl: './activity-type-detail.component.html',
    styleUrls: ['./activity-type-detail.component.less'],
})
export class ActivityTypeDetailComponent {
    loading$ = this.store.select(ActivityType.selectDetailLoading);
    errors$ = this.store.select(ActivityType.selectDetailErrors);
    activityType$ = this.store.select(ActivityType.selectDetailActivityType);

    constructor(private readonly store: Store<State>) { }

    submit(activityType: ActivityTypeCreateModel | ActivityTypeUpdateModel) {
        this.store.dispatch(activityType.hasOwnProperty('id')
            ? ActivityTypeApiActions.Update(activityType as ActivityTypeUpdateModel) 
            : ActivityTypeApiActions.Create(activityType)
        );
    }
}