import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { RedirectCreateModel, RedirectTableItemModel, RedirectUpdateModel } from '@app/modules/redirects/models';
import { SlugExistsModel } from '@app/modules/redirects/models/slug-exists.model';
import { TableDataModel } from '@app/shared/models';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RedirectService {
	private readonly endpoints = {
		get: 'Redirect/:id',
		overview: 'Redirect/GetOverview',
		update: 'Redirect/Update',
		create: 'Redirect/Create',
		delete: 'Redirect/Delete?id=:id',
		changeSequence: 'Redirect/ChangeSequence/',
		slugExists: 'Redirect/SlugExists/'
	};

	constructor(private readonly httpService: HttpService) {}

	getAll(
		sorting: TableSortingModel,
		pagination: TablePaginationModel
	): Observable<TableDataModel<RedirectTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');

		return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}`);
	}

	get(id: number): Observable<RedirectUpdateModel> {
		return this.httpService.get<any>(this.endpoints.get.replace(':id', id.toString()));
	}

	update(request: RedirectUpdateModel): Observable<RedirectUpdateModel> {
		return this.httpService.put<any>(this.endpoints.update, request);
	}

	create(request: RedirectCreateModel): Observable<RedirectUpdateModel> {
		return this.httpService.post<any>(this.endpoints.create, request);
	}

	delete(id: number): Observable<any> {
		return this.httpService.delete<any>(this.endpoints.delete.replace(':id', id.toString()));
	}
	
	slugExists(slug: string): Observable<SlugExistsModel> {
		return this.httpService.get<SlugExistsModel>(this.endpoints.slugExists, { params: { slug } });
	}
}
