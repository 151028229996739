import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges, OnChanges, OnInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NewsUpdateModel, NewsCreateModel } from '@app/modules/news/models';
import {
    isFormValid,
    processBackendValidators,
} from '@app/shared/utilities/validation.utilities';
import { ContentBlockFormGroup, ContentBlockType } from '@app/shared/models/content';
import CustomValidators from '@app/shared/validators/custom.validator';
import { ClaimTypes, ClaimValues, Constants } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { AssetFormGroup } from '@app/shared/models/assetmanager';

@Component({
    selector: 'app-news-detail-form',
    templateUrl: './news-detail-form.component.html',
})
export class NewsDetailFormComponent implements OnChanges, OnInit {
    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input({ required: true }) news: NewsUpdateModel | null = null;

    @Output() submitted = new EventEmitter<NewsCreateModel | NewsUpdateModel>();

    availableContentTypes = [ContentBlockType.Editor];

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),
        showOnline: new FormControl<boolean>(true),
        title: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        text: new FormArray<ContentBlockFormGroup>([], [CustomValidators.required]),
        link: new FormControl<string | null>(null, [Validators.pattern(Constants.UrlExpression), Validators.maxLength(100)]),
        linkText: new FormControl<string | null>(null, [Validators.maxLength(100)]),
        asset: new AssetFormGroup()
    });

    update = false;
    readonly?: boolean;

    constructor(
        private readonly claimService: ClaimService
    ) { }

    async ngOnInit() {
        this.update = !!this.news;

        this.formGroup.controls.linkText.setValidators(
            CustomValidators.otherFieldFilled(this.formGroup.controls.link, 'link')
        );
        this.formGroup.controls.link.addValidators(
            CustomValidators.otherFieldFilled(
                this.formGroup.controls.linkText,
                'linkText'
            )
        );

        if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.News, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        if (this.update) {
            this.formGroup.patchValue({
                showOnline: this.news?.showOnline ?? true,
                title: this.news?.title,
                text: this.news?.contentBlocks ?? [],
                link: this.news?.link,
                linkText: this.news?.linkText,
                asset: { url: this.news?.imagePath ?? '', name: this.news?.imageName ?? '' }
            });

            (this.news?.contentBlocks ?? []).forEach(contentBlock => this.formGroup.controls.text.push(new ContentBlockFormGroup(contentBlock)));
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue((this.news as NewsUpdateModel).id);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    private submitCreate() {
        this.submitted.emit({
            showOnline: this.formGroup.controls.showOnline.value ?? true,
            title: this.formGroup.controls.title.value!,
            contentBlocks: this.formGroup.controls.text.value ?? [],
            link:
                this.formGroup.controls.link.value != ''
                    ? this.formGroup.controls.link.value
                    : null,
            linkText:
                this.formGroup.controls.linkText.value != ''
                    ? this.formGroup.controls.linkText.value
                    : null,
            imageName: this.formGroup.controls.asset.value.name ?? '',
            imagePath: this.formGroup.controls.asset.value.url ?? ''
        });
    }

    private submitUpdate() {
        this.submitted.emit({
            id: this.formGroup.controls.id.value!,
            showOnline: this.formGroup.controls.showOnline.value ?? true,
            title: this.formGroup.controls.title.value!,
            contentBlocks: this.formGroup.controls.text.value ?? [],
            link:
                this.formGroup.controls.link.value != ''
                    ? this.formGroup.controls.link.value
                    : null,
            linkText:
                this.formGroup.controls.linkText.value != ''
                    ? this.formGroup.controls.linkText.value
                    : null,
            imageName: this.formGroup.controls.asset.value.name ?? '',
            imagePath: this.formGroup.controls.asset.value.url ?? ''
        });
    }
}