import { inject } from '@angular/core';
import {
	CanActivateFn,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivateChildFn,
	Router,
} from '@angular/router';
import { TokenService } from '@app/core/services/token/token.service';

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const tokenService = inject(TokenService);
	const router = inject(Router);

	return tokenService.hasToken() ? true : router.navigate(['/auth/login'])
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	canActivate(route, state);
