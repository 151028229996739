import * as CountryActions from "@app/modules/countries/actions/country-api.actions"
import { CountryUpdateModel } from "@app/modules/countries/models";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    country: null,
    loading: false,
    errors: {}
}

export interface State {
    country: CountryUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(CountryActions.Clear, () => ({ ...initialState })),

    on(CountryActions.Get, (state) => ({ ...state, loading: true })),
    on(CountryActions.GetSuccess, (state, country) => ({ ...state, country, loading: false })),
    on(CountryActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(CountryActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(CountryActions.UpdateSuccess, (state, country) => ({ ...state, country, loading: false })),
    on(CountryActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(CountryActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(CountryActions.CreateSuccess, (state, country) => ({ ...state, country, loading: false })),
    on(CountryActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getCountry = (state: State) => state.country;