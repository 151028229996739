import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { RelationTypeOverviewComponent } from "@app/modules/relation-type/pages/overview/relation-type-overview.component";
import { RelationTypeDetailComponent } from "@app/modules/relation-type/pages/detail/relation-type-detail.component";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { RelationTypeRoutingModule } from "@app/modules/relation-type/relation-type-routing.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { reducers, relationTypeFeatureKey } from "@app/modules/relation-type/reducers";
import { RelationTypeEffects } from "@app/modules/relation-type/effects/relation-type.effects";
import { RelationTypeOverviewTableComponent } from "@app/modules/relation-type/components/relation-type-overview-table/relation-type-overview-table.component";
import { RelationTypeDetailFormComponent } from "@app/modules/relation-type/components/relation-type-detail-form/relation-type-detail-form.component";

const COMPONENTS = [
    RelationTypeOverviewComponent,
    RelationTypeDetailComponent,
    RelationTypeOverviewTableComponent,
    RelationTypeDetailFormComponent
];

const IMPORTS = [
    SharedModule,
    RelationTypeRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(relationTypeFeatureKey, reducers),
    EffectsModule.forFeature([RelationTypeEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class RelationTypesModule { }
