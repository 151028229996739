import { HttpXhrBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { CustomRequestOptions } from '@app/core/services/http/custom-request.options';
import { HttpService } from '@app/core/services/http/http.service';
import { TokenService } from '@app/core/services/token/token.service';
import { Store } from '@ngrx/store';

function httpServiceFactory(
	backend: HttpXhrBackend,
	options: CustomRequestOptions,
	tokenService: TokenService,
	store: Store,
	router: Router,
) {
	return new HttpService(backend, options, tokenService, store, router);
}

export { httpServiceFactory };

