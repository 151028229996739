<div class="page-header">
    <h1 class="page-header__title">{{ 'relation.title' | translate }}</h1>
    <div class="page-header__actions">
        <a
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Relation,
                    claimValues: [ClaimValues.Create]
                }
            ]"
            routerLink="/relaties/toevoegen"
            nz-button
            nzType="primary">
            {{ 'common.add' | translate }}
            <i class="fa-regular fa-plus"></i>
        </a>
    </div>
</div>

<app-relation-overview-table
    [data]="(tableData$ | async)!"
    [hasReadClaims]="hasReadClaims"
    (onChangeActive)="onChangeActive($event)"
    (onQueryParamsChange)="onQueryParamsChange($event)" />
