import { Injectable } from '@angular/core';
import { TokenModel } from '@app/shared/models/auth/token.model';
import { jwtDecode } from 'jwt-decode';

@Injectable({
	providedIn: 'root',
})
export class TokenService {
	private readonly tokenKey: string = 'token';
	private readonly mobileKey: string = 'mobile';

	public setToken(token: TokenModel): void {
		localStorage.setItem(this.tokenKey, JSON.stringify(token));
	}

	public removeToken(): void {
		localStorage.removeItem(this.tokenKey);
		localStorage.removeItem(this.mobileKey);
	}

	public getToken(): TokenModel {
		return JSON.parse(localStorage.getItem(this.tokenKey) ?? '{}');
	}

	public getDecodedToken(): any {
		const accessToken = this.getToken().accessToken;
		if(!accessToken) {
			return { };
		}

		return jwtDecode(accessToken);
	}

	public hasToken(): boolean {
		return !!localStorage.getItem(this.tokenKey);
	}

	public shouldRefreshToken(): boolean {
		const decodedToken = this.getDecodedToken();
		const expirationDate = new Date((decodedToken?.exp ?? 0) * 1000);
		const currentDate = new Date();
		const timeDifference = expirationDate.getTime() - currentDate.getTime();
		const minutesDifference = timeDifference / 60000;
		return minutesDifference < 1;
	}

	public setMobileLogin(mobile: boolean) {
		localStorage.setItem(this.mobileKey, mobile ? 'true' : 'false');
	}

	public getMobileLogin() {
		return localStorage.getItem(this.mobileKey) === 'true';
	}
}
