import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DiscountCodeTableItemModel } from '@app/modules/discount-codes/models/discount-code-table.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { DiscountUsage } from '@app/modules/discount-codes/enums';

@Component({
	selector: 'app-discount-code-overview-table',
	templateUrl: './discount-code-overview-table.component.html',
})
export class DiscountCodeOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<DiscountCodeTableItemModel>;

	@Output() delete = new EventEmitter<number>();
	@Output() queryParamsChange = new EventEmitter<any>();
	@Output() changeActive = new EventEmitter<{ id: number; active: boolean }>();

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;
	DiscountUsage = DiscountUsage;

	setActive(id: number, active: boolean) {
		this.changeActive.emit({ id: id, active: active });
	}
}
