import * as FAQCategoryApiActions from "@app/modules/faq-categories/actions/faq-category-api.actions"
import { FAQCategoryTableItemModel } from "@app/modules/faq-categories/models/faq-category-table.model"
import { TableOverviewModel } from "@app/shared/models/table/table-overview.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model"
import { applySortingOrDefault } from "@app/shared/utilities/overview-reducer.utilities"
import { createReducer, on } from "@ngrx/store"

const initialState: State = new TableOverviewModel<FAQCategoryTableItemModel>('sequenceNumber');

export interface State extends TableStateModel<FAQCategoryTableItemModel> { };

export const reducer = createReducer(initialState,
    on(FAQCategoryApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(FAQCategoryApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(FAQCategoryApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(FAQCategoryApiActions.FilterOverview, (state, { sorting, pagination }) => ({ 
        ...state, 
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: { 
            ...state.pagination, 
            page: pagination.page,
            pageSize: pagination.pageSize
        } 
    })),
    on(FAQCategoryApiActions.ResetOverview,  () => ({ ...initialState }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;