import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as ClaimCategoryActions from '@app/modules/claim-categories/actions/claim-category-api.actions';
import * as ClaimCategory from '@app/modules/claim-categories/reducers';
import { ClaimCategoryService } from '@app/modules/claim-categories/services/claim-category.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class ClaimCategoryEffects {
  constructor(
    private actions$: Actions,
    private claimCategoryService: ClaimCategoryService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<ClaimCategory.State>
  ) {}

  /**
   * Get effects
   */
  loadClaimCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimCategoryActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(ClaimCategory.selectClaimCategoryOverviewPagination),
        this.store.select(ClaimCategory.selectClaimCategoryOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.claimCategoryService.getAll(sorting, pagination).pipe(
          map((response) => ClaimCategoryActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(
              ClaimCategoryActions.GetOverviewError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  loadClaimCategoriessFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimCategoryActions.FilterOverview),
      map(() => ClaimCategoryActions.GetOverview())
    )
  );

  loadClaimCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimCategoryActions.Get),
      exhaustMap((action) =>
        this.claimCategoryService.get(action.claimType, action.value).pipe(
          map((response) =>
            ClaimCategoryActions.GetSuccess({ claimCategory: response })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(ClaimCategoryActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Update effects
   */
  updateClaimCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ClaimCategoryActions.Update),
      exhaustMap((action) =>
        this.claimCategoryService.update(action.claimCategory).pipe(
          map((claimCategory) =>
            ClaimCategoryActions.UpdateSuccess({ claimCategory: claimCategory })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(ClaimCategoryActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateClaimCategorySuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimCategoryActions.UpdateSuccess),
        tap(() =>
          this.translate
            .get('claim-categories.notification.save')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update claimCategorys & store clear
   */
  updateClaimCategorySuccessClaimCategory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimCategoryActions.UpdateSuccess),
        tap(() => this.router.navigate(['rechten']))
      ),
    { dispatch: false }
  );

  claimCategoryErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClaimCategoryActions.GetOverviewError),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
