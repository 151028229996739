import { Component, Input } from '@angular/core';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';

@Component({
    selector: 'app-activity-schedule-overview-list',
    templateUrl: './activity-schedule-overview-list.component.html',
    styleUrls: ['./activity-schedule-overview-list.component.less'],
  })
export class ActivityScheduleOverviewListComponent {
  @Input({ required: true }) activitySchedules: KeyValueModel[] | null = null;
}
