import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
	CountryChangeSequenceModel,
	CountryCreateModel,
	CountryModel,
	CountryTableItemModel,
	CountryUpdateModel,
} from '@app/modules/countries/models';
import { TableDataModel } from '@app/shared/models';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CountryService {
	private readonly endpoints = {
		get: 'Country/:id',
		overview: 'Country/GetOverview',
		update: 'Country/Update',
		create: 'Country/Create',
		delete: 'Country/Delete?id=:id',
		changeSequence: 'Country/ChangeSequence',
		getAll: 'Country/GetAll',
	};

	constructor(private readonly httpService: HttpService) {}

	overview(
		sorting: TableSortingModel,
		pagination: TablePaginationModel
	): Observable<TableDataModel<CountryTableItemModel>> {
		const paginationParams = parse(pagination, 'pagination');
		const sortingParams = parse(sorting, 'sorting');

		return this.httpService.get<any>(this.endpoints.overview + `?${paginationParams}&${sortingParams}`);
	}

	getAll(): Observable<CountryModel[]> {
		return this.httpService.get<CountryModel[]>(this.endpoints.getAll);
	}

	get(id: number): Observable<CountryUpdateModel> {
		return this.httpService.get<any>(this.endpoints.get.replace(':id', id.toString()));
	}

	update(request: CountryUpdateModel): Observable<CountryUpdateModel> {
		return this.httpService.put<any>(this.endpoints.update, request);
	}

	create(request: CountryCreateModel): Observable<CountryUpdateModel> {
		return this.httpService.post<any>(this.endpoints.create, request);
	}

	delete(id: number): Observable<any> {
		return this.httpService.delete<any>(this.endpoints.delete.replace(':id', id.toString()));
	}

	changeSequence(request: CountryChangeSequenceModel): Observable<any> {
		return this.httpService.put<any>(this.endpoints.changeSequence, request);
	}
}
