import { Component } from '@angular/core';
import * as ActivityTemplate from '@app/modules/activity-template/reducers';
import { Store } from '@ngrx/store';
import * as ActivityTemplateApiActions from '@app/modules/activity-template/actions/activity-template-api.actions';
import { State } from '@app/reducers';
import { ActivityTemplateCreateModel, ActivityTemplateUpdateModel } from '@app/modules/activity-template/models';

@Component({
    selector: 'app-activity-template-detail',
    templateUrl: './activity-template-detail.component.html',
    styleUrls: ['./activity-template-detail.component.less'],
})
export class ActivityTemplateDetailComponent {
    loading$ = this.store.select(ActivityTemplate.selectDetailLoading);
    errors$ = this.store.select(ActivityTemplate.selectDetailErrors);
    activityTemplate$ = this.store.select(ActivityTemplate.selectDetailActivityTemplate);
    activityTypes$ = this.store.select(ActivityTemplate.selectDetailActivityTypes);

    constructor(private readonly store: Store<State>) {
        this.store.dispatch(ActivityTemplateApiActions.GetActivityTypes());
    }

    submit(activityTemplate: ActivityTemplateCreateModel | ActivityTemplateUpdateModel) {
        this.store.dispatch(
            activityTemplate.hasOwnProperty('id')
                ? ActivityTemplateApiActions.Update(activityTemplate as ActivityTemplateUpdateModel)
                : ActivityTemplateApiActions.Create(activityTemplate),
        );
    }
}
