import { ErrorResponse } from "@app/core/models/error-response.model";
import { TagTableItemModel, TagUpdateModel, TagCreateModel } from "@app/modules/tag/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[Tags] GetOverview");
export const GetOverviewError = createAction("[Tags] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Tags] GetOverviewSuccess", props<TableDataModel<TagTableItemModel>>());

export const FilterOverview = createAction("[Tags] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[Tags] Clear");

export const Get = createAction("[Tags] Get", props<{ id: number }>());
export const GetError = createAction("[Tags] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Tags] GetSuccess", props<TagUpdateModel>());

export const Update = createAction("[Tags] Update", props<TagUpdateModel>());
export const UpdateError = createAction("[Tags] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Tags] UpdateSuccess", props<TagUpdateModel>());

export const Create = createAction("[Tags] Create", props<TagCreateModel>());
export const CreateError = createAction("[Tags] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Tags] CreateSuccess", props<TagUpdateModel>());

export const Delete = createAction("[Tags] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Tags] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Tags] DeleteSuccess");
