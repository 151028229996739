import * as ActivityScheduleActions from '@app/mobile/modules/activity-schedule/actions/activity-schedule.actions';
import { ActivityScheduleUpdateModel } from '@app/mobile/modules/activity-schedule/models';
import { createReducer, on } from '@ngrx/store';

const initialState: State = {
  activitySchedule: null,
  loading: false,
  errors: {},
};

export interface State {
  activitySchedule: ActivityScheduleUpdateModel | null;
  loading: boolean;
  errors: { [key: string]: string[] };
}

export const reducer = createReducer(
  initialState,
  on(ActivityScheduleActions.Clear, () => ({ ...initialState })),

  on(ActivityScheduleActions.Get, (state) => ({
    ...state,
    loading: true,
  })),
  on(ActivityScheduleActions.GetSuccess, (state, activitySchedule) => ({
    ...state,
    activitySchedule,
    loading: false,
  })),
  on(ActivityScheduleActions.GetError, (state) => ({
    ...state,
    loading: false,
  })),

  on(ActivityScheduleActions.Update, (state) => ({
    ...state,
    loading: true,
    errors: {},
  })),
  on(ActivityScheduleActions.UpdateSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(ActivityScheduleActions.UpdateError, (state, { response }) => ({
    ...state,
    loading: false,
    errors: response.errors || {},
  }))
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getActivitySchedule = (state: State) => state.activitySchedule;
