import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { reducers, reviewFeatureKey } from "@app/modules/review/reducers";
import { ReviewEffects } from "@app/modules/review/effects/review.effects";
import { ReviewDetailComponent } from "@app/modules/review/pages/detail/review-detail.component";
import { ReviewOverviewComponent } from "@app/modules/review/pages/overview/review-overview.component";
import { ReviewOverviewTableComponent } from "@app/modules/review/components/review-overview-table/review-overview-table.component";
import { ReviewDetailFormComponent } from "@app/modules/review/components/review-detail-form/review-detail-form.component";
import { ReviewRoutingModule } from "@app/modules/review/review-routing.module";
import { NzDatePickerComponent } from "ng-zorro-antd/date-picker";
import { NzIconModule } from "ng-zorro-antd/icon";

const COMPONENTS = [
    ReviewOverviewComponent,
    ReviewDetailComponent,
    ReviewOverviewTableComponent,
    ReviewDetailFormComponent
];

const IMPORTS = [
    SharedModule,
    NzIconModule,
    ReviewRoutingModule,
    NzDividerModule,
    StoreModule.forFeature(reviewFeatureKey, reducers),
    EffectsModule.forFeature([ReviewEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class ReviewsModule { }
