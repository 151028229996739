import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ActivityTypeService } from '@app/modules/activity-type/services/activity-type.service';
import { catchError, map, tap } from 'rxjs/operators';
import * as ActivityTypeApiActions from '@app/modules/activity-type/actions/activity-type-api.actions';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';

export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const activityTypeService = inject(ActivityTypeService);
    const router = inject(Router);
    const store = inject(Store<State>);

    return activityTypeService.get(route.params['id']).pipe(
        map(activityType => ActivityTypeApiActions.GetSuccess(activityType)),
        tap((action: any) => store.dispatch(action)),
        map(activityType => !!activityType),
        catchError(() => {
            return router.navigate(['/not-found']);
        })
    );
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);