import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ActivityScheduleUpdateModel } from '@app/mobile/modules/activity-schedule/models';

@Component({
  selector: 'app-activity-schedule-detail-form',
  templateUrl: './activity-schedule-detail-form.component.html',
  styleUrls: ['./activity-schedule-detail-form.component.less'],
})
export class ActivityScheduleDetailFormComponent implements OnInit {
  @Input({ required: true })
  activitySchedule: ActivityScheduleUpdateModel | null = null;
  @Output() save = new EventEmitter<ActivityScheduleUpdateModel>();

  ngOnInit () {
    if (this.activitySchedule) {
      this.activitySchedule = {
        ...this.activitySchedule,
        attendees: this.activitySchedule.attendees.map(attendee => ({
          ...attendee,
          inAttendance: attendee.inAttendance ?? true
        }))
      }
    }
  }

  public onModelChange(relationId: number, inAttendance: boolean) {
    const attendees = this.activitySchedule?.attendees.map((attendee) => {
      if (attendee.relationId === relationId) {
        return {
          ...attendee,
          inAttendance,
        };
      }
      return attendee;
    });
    this.activitySchedule = {
      ...this.activitySchedule!,
      attendees: attendees!,
    };
  }

  public submit() {
    this.save.emit({ ...this.activitySchedule! });
  }
}
