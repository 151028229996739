<div class="page-header">
    <h1 class="page-header__title">{{ 'activity.title' | translate }}</h1>
    <div class="page-header__actions">
        <button
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Activity,
                    claimValues: [ClaimValues.Create]
                }
            ]"
            (click)="createActivity()"
            nz-button
            nzType="primary">
            {{ 'common.add' | translate }}
            <i class="fa-regular fa-plus"></i>
        </button>
    </div>
</div>
<div nz-row>
    <nz-select
        *ngIf="activityTypes$ | async"
        [ngModel]="selectedActityTypeId$ | async"
        (ngModelChange)="onCategoryChange($event)">
        <nz-option nzLabel="{{ 'activity-templates.fields.all-types' | translate }}" [nzValue]="-1"></nz-option>
        <nz-option
            *ngFor="let category of (activityTypes$ | async)"
            [nzLabel]="category.name"
            [nzValue]="category.id"></nz-option>
    </nz-select>
</div>
<div nz-row>
    <div nz-col>
        <td
            nzAlign="center"
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Activity,
                    claimValues: [ClaimValues.Read]
                }
            ]">
            <label nz-checkbox (nzCheckedChange)="onShowOldActivities($event)">
                {{ 'activity.fields.display-old' | translate }}
            </label>
        </td>
    </div>
</div>
<div nz-row>
    <app-activity-overview-table
        [data]="(tableData$ | async)!"
        (delete)="onDeleteConfirm($event)"
        (onQueryParamsChange)="onQueryParamsChange($event)"
        (onResetOverview)="onResetOverview()"
        (changeOnline)="onChangeOnline($event)" />
</div>
