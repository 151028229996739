import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectClearGuard, redirectExistsGuard } from '@app/modules/redirects/guards';
import { RedirectDetailComponent } from '@app/modules/redirects/pages/detail/redirect-detail.component';
import { RedirectOverviewComponent } from '@app/modules/redirects/pages/overview/redirect-overview.component';

const routes: Routes = [
	{ path: '', redirectTo: 'overzicht', pathMatch: 'full' },
	{ path: 'overzicht', component: RedirectOverviewComponent },
	{ path: 'toevoegen', component: RedirectDetailComponent, canActivate: [redirectClearGuard] },
	{ path: 'bewerken/:id', component: RedirectDetailComponent, canActivate: [redirectExistsGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class RedirectRoutingModule {}
