<nz-card nzTitle="{{ 'note.title' | translate }}">
    <app-create-note
        [errors]="errors$ | async"
        [loading]="loading$ | async"
        [note]="note$ | async"
        (createNote)="this.createNote.emit($event)"></app-create-note>

    <div class="notes-list mt-md">
        <div
            *ngFor="let note of this.notes"
            class="note-item"
            claimDirective
            [claims]="[
                {
                    claimType: ClaimTypes.Note,
                    claimValues: [ClaimValues.Read]
                }
            ]"
        >
            <app-note-entry
                [loading$]="loading$"
                [errors$]="errors$"
                [note]="note"
                (setNoteImportant)="this.setNoteImportant.emit($event)"
                (doEditNote)="this.doEditNote.emit($event)"
                (doDeleteNote)="this.doDeleteNote.emit($event)"
            ></app-note-entry>
        </div>
    </div>
    
</nz-card>
