import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as ActivityLocationOverview from './activity-location-overview.reducer'
import * as ActivityLocationDetailPage from './activity-location-detail-page.reducer'

export const activityLocationFeatureKey = 'activityLocation';

export interface ActivityLocationState {
    activityLocationOverview: ActivityLocationOverview.State,
    activityLocationDetail: ActivityLocationDetailPage.State,
}

export interface State {
    activityLocation: ActivityLocationState
}

const map: ActionReducerMap<ActivityLocationState, Action> = {
    activityLocationOverview: ActivityLocationOverview.reducer,
    activityLocationDetail: ActivityLocationDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: ActivityLocationState, action: Action) => combineReducers(map)(state, action);

const selectActivityLocationState = createFeatureSelector<ActivityLocationState>(activityLocationFeatureKey);

export const selectState = createSelector(selectActivityLocationState, state => state)
export const selectActivityLocationOverviewState = createSelector(selectState, state => state.activityLocationOverview)

export const selectActivityLocationOverviewLoading = createSelector(selectActivityLocationOverviewState, ActivityLocationOverview.getLoading)
export const selectActivityLocationOverviewList = createSelector(selectActivityLocationOverviewState, ActivityLocationOverview.getList)
export const selectActivityLocationOverviewPagination = createSelector(selectActivityLocationOverviewState, ActivityLocationOverview.getPagination)
export const selectActivityLocationOverviewSorting = createSelector(selectActivityLocationOverviewState, ActivityLocationOverview.getSorting)

export const selectActivityLocationDetailState = createSelector(selectState, state => state.activityLocationDetail);

export const selectDetailLoading = createSelector(selectActivityLocationDetailState, ActivityLocationDetailPage.getLoading);
export const selectDetailActivityLocation = createSelector(selectActivityLocationDetailState, ActivityLocationDetailPage.getActivityLocation);
export const selectDetailErrors = createSelector(selectActivityLocationDetailState, ActivityLocationDetailPage.getErrors);