@if (control.errors?.['required']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: (getErrorKey('required') | translate)}">
    </ng-container>
}

@if(control.errors?.['minlength']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: (getErrorKey('minlength') | translate)}">
    </ng-container>
}

@if(control.errors?.['maxlength']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: (getErrorKey('max_length') | translate: { '0': control.errors?.['maxlength'].requiredLength})}">
    </ng-container>
}

@if(control.errors?.['matching']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: (getErrorKey('matching') | translate)}">
    </ng-container>
}

@if(control.errors?.['pattern']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: (getErrorKey('pattern') | translate)}">
    </ng-container>
}

@if(control.errors?.['minArrayLength']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: (getErrorKey('minArrayLength') | translate: {minLength: control.errors?.['minArrayLength'].requiredLength})}">
    </ng-container>
}

@if(control.errors?.['maxArrayLength']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: (getErrorKey('maxArrayLength') | translate: {maxLength: control.errors?.['maxArrayLength'].requiredLength})}">
    </ng-container>
}

@if(control.errors?.['correctCurrencyDecimalNotation']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: (getErrorKey('correctCurrencyDecimalNotation') | translate)}">
    </ng-container>
}

@if(control.errors?.['min']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('min') | translate)}">
    </ng-container>
}

@if(control.errors?.['max']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('max') | translate)}">
    </ng-container>
}

@if(control.errors?.['maxLength']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('maxLength') | translate)}">
    </ng-container>
}

@if(control.errors?.['otherFieldEmpty']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('otherFieldEmpty') | translate)}">
    </ng-container>
}

@if(control.errors?.['endDateAfterStartDate']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('endDateAfterStartDate') | translate)}">
    </ng-container>
}

@if(control.errors?.['assetRequired']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('assetRequired') | translate)}">
    </ng-container>
}

@if(control.errors?.['eitherRequired']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('eitherRequired') | translate)}">
    </ng-container>
}

@if(control.errors?.['duplicate']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('duplicate') | translate)}">
    </ng-container>
}

@if(control.errors?.['higherThanMinimum']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('higherThanMinimum') | translate)}">
    </ng-container>
}

@if(control.errors?.['requiredFor']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('requiredFor') | translate)}">
    </ng-container>
}

@if(control.errors?.['zipCode']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('invalid') | translate)}">
    </ng-container>
}

@if(control.errors?.['phoneNumber']) {
    <ng-container
        *ngTemplateOutlet="errorTemplate; context:{$implicit: ( getErrorKey('invalid') | translate)}">
    </ng-container>
}

<ng-template #errorTemplate let-error>
    <div role="alert" class="ant-form-item-explain-error">
        {{ error }}
    </div>
</ng-template>