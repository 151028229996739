import { ErrorResponse } from "@app/core/models/error-response.model";
import { DiscountCodeCreateModel, DiscountCodeTableItemModel, DiscountCodeUpdateModel } from "@app/modules/discount-codes/models";
import { TableDataModel } from "@app/shared/models";
import { KeyValueModel } from "@app/shared/models/common/key-value.model";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[DiscountCode] GetOverview");
export const GetOverviewError = createAction("[DiscountCode] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[DiscountCode] GetOverviewSuccess", props<TableDataModel<DiscountCodeTableItemModel>>());
export const ResetOverview = createAction("[DiscountCode] ResetOverview");

export const FilterOverview = createAction("[DiscountCode] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[DiscountCode] Clear");

export const Get = createAction("[DiscountCode] Get", props<{ id: number }>());
export const GetError = createAction("[DiscountCode] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[DiscountCode] GetSuccess", props<DiscountCodeUpdateModel>());

export const Update = createAction("[DiscountCode] Update", props<DiscountCodeUpdateModel>());
export const UpdateError = createAction("[DiscountCode] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[DiscountCode] UpdateSuccess", props<DiscountCodeUpdateModel>());

export const ChangeActive = createAction("[DiscountCode] ChangeActive", props<{ id: number, active: boolean}>());
export const ChangeActiveError = createAction("[DiscountCode] ChangeActiveError", props<{ response: ErrorResponse }>());
export const ChangeActiveSuccess = createAction("[DiscountCode] ChangeActiveSuccess", props<DiscountCodeUpdateModel>());

export const Create = createAction("[DiscountCode] Create", props<DiscountCodeCreateModel>());
export const CreateError = createAction("[DiscountCode] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[DiscountCode] CreateSuccess", props<DiscountCodeUpdateModel>());

export const Delete = createAction("[DiscountCode] Delete", props<{ id: number }>());
export const DeleteError = createAction("[DiscountCode] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[DiscountCode] DeleteSuccess");

export const GetActivities = createAction("[DiscountCode] GetActivities");
export const GetActivitiesSuccess = createAction("[DiscountCode] GetActivitiesSuccess", props<{ activities: KeyValueModel[] }>());
export const GetActivitiesError = createAction("[DiscountCode] GetActivitiesError", props<{ response: ErrorResponse }>());

export const GetRelations = createAction("[DiscountCode] GetRelations");
export const GetRelationsSuccess = createAction("[DiscountCode] GetRelationsSuccess", props<{ relations: KeyValueModel[] }>());
export const GetRelationsError = createAction("[DiscountCode] GetRelationsError", props<{ response: ErrorResponse }>());

export const GetActivityTypes = createAction("[DiscountCode] GetActivityTypes");
export const GetActivityTypesSuccess = createAction("[DiscountCode] GetActivityTypesSuccess", props<{ activityTypes: KeyValueModel[] }>());
export const GetActivityTypesError = createAction("[DiscountCode] GetActivityTypesError", props<{ response: ErrorResponse }>());