import {
    Action,
    ActionReducerMap,
    combineReducers,
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import * as ActivityScheduleDetailPage from './activity-schedule-detail-page.reducer';
import * as ActivityScheduleOverview from './activity-schedule-overview.reducer';

export const activityScheduleFeatureKey = 'activitySchedule';

export interface ActivityScheduleState {
  activityScheduleOverview: ActivityScheduleOverview.State;
  activityScheduleDetail: ActivityScheduleDetailPage.State;
}

export interface State {
  activityRegistration: ActivityScheduleState;
}

const map: ActionReducerMap<ActivityScheduleState, Action> = {
  activityScheduleOverview: ActivityScheduleOverview.reducer,
  activityScheduleDetail: ActivityScheduleDetailPage.reducer,
};

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: ActivityScheduleState, action: Action) =>
  combineReducers(map)(state, action);

const selectActivityScheduleState =
  createFeatureSelector<ActivityScheduleState>(activityScheduleFeatureKey);

export const selectState = createSelector(
  selectActivityScheduleState,
  (state) => state
);
export const selectActivityScheduleOverviewState = createSelector(
  selectState,
  (state) => state.activityScheduleOverview
);
export const selectActivityScheduleDetailState = createSelector(
  selectState,
  (state) => state.activityScheduleDetail
);

export const selectActivityScheduleOverviewList = createSelector(
  selectActivityScheduleOverviewState,
  ActivityScheduleOverview.getList
);
export const selectActivityScheduleOverviewLoading = createSelector(
  selectActivityScheduleOverviewState,
  ActivityScheduleOverview.getLoading
);

export const selectActivityScheduleDetail = createSelector(
  selectActivityScheduleDetailState,
  ActivityScheduleDetailPage.getActivitySchedule
);
export const selectActivityScheduleDetailLoading = createSelector(
  selectActivityScheduleDetailState,
  ActivityScheduleDetailPage.getLoading
);
export const selectActivityScheduleDetailErrors = createSelector(
  selectActivityScheduleDetailState,
  ActivityScheduleDetailPage.getErrors
);
