import * as ActivityTemplateActions from "@app/modules/activity-template/actions/activity-template-api.actions"
import { ActivityTemplateUpdateModel } from "@app/modules/activity-template/models";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    activityTemplate: null,
    loading: false,
    errors: {}
}

export interface State {
    activityTemplate: ActivityTemplateUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(ActivityTemplateActions.Clear, () => ({ ...initialState })),

    on(ActivityTemplateActions.Get, (state) => ({ ...state, loading: true })),
    on(ActivityTemplateActions.GetSuccess, (state, activityTemplate) => ({ ...state, activityTemplate, loading: false })),
    on(ActivityTemplateActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityTemplateActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityTemplateActions.UpdateSuccess, (state, activityTemplate) => ({ ...state, activityTemplate, loading: false })),
    on(ActivityTemplateActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(ActivityTemplateActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(ActivityTemplateActions.CreateSuccess, (state, activityTemplate) => ({ ...state, activityTemplate, loading: false })),
    on(ActivityTemplateActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getActivityTemplate = (state: State) => state.activityTemplate;