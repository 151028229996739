import * as EmailTemplateApiActions from "@app/modules/email-template/actions/email-template-api.actions"
import { EmailTemplateType } from "@app/modules/email-template/enums"
import { EmailTemplateTableItemModel } from "@app/modules/email-template/models/email-template-table.model"
import { TableOverviewModel } from "@app/shared/models/table/table-overview.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model"
import { applySortingOrDefault } from "@app/shared/utilities/overview-reducer.utilities"
import { createReducer, on } from "@ngrx/store"

const initialState: State = {
    ...new TableOverviewModel<EmailTemplateTableItemModel>('relationtype'),
    emailTemplateType: EmailTemplateType.PasswordResetMail
};

export interface State extends TableStateModel<EmailTemplateTableItemModel> { 
    emailTemplateType: EmailTemplateType;
}

export const reducer = createReducer(initialState,
    on(EmailTemplateApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(EmailTemplateApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(EmailTemplateApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(EmailTemplateApiActions.FilterOverview, (state, { sorting, pagination }) => ({ 
        ...state, 
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: { 
            ...state.pagination, 
            page: pagination.page,
            pageSize: pagination.pageSize
        } 
    })),
    on(EmailTemplateApiActions.ResetOverview,  () => ({ ...initialState })),
    on(EmailTemplateApiActions.SetEmailTemplateType, (state, { emailTemplateType }) => ({ ...state, emailTemplateType }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
export const getEmailTemplateType = (state: State) => state.emailTemplateType;