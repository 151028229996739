import { Component, SkipSelf } from "@angular/core";
import { ControlContainer } from "@angular/forms";
import { AbstractBlockComponent, EditorContentBlockFormGroup } from "@app/shared/models/content";

@Component({
    selector: 'app-editor-content-block',
    templateUrl: './editor-content-block.component.html',
    styleUrls: ['./editor-content-block.component.less'],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]]
        }
    ]
})
export class EditorContentBlockComponent extends AbstractBlockComponent {
    override formGroup!: EditorContentBlockFormGroup;

    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }
}