import { ErrorResponse } from "@app/core/models/error-response.model";
import { FAQCategoryChangeSequenceModel } from "@app/modules/faq-categories/models/faq-category-change-sequence.model";
import { FAQCategoryCreateModel } from "@app/modules/faq-categories/models/faq-category-create.model";
import { FAQCategoryTableItemModel } from "@app/modules/faq-categories/models/faq-category-table.model";
import { FAQCategoryUpdateModel } from "@app/modules/faq-categories/models/faq-category-update.model";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[FAQCategory] GetOverview");
export const GetOverviewError = createAction("[FAQCategory] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[FAQCategory] GetOverviewSuccess", props<TableDataModel<FAQCategoryTableItemModel>>());
export const ResetOverview = createAction("[FAQCategory] ResetOverview");

export const FilterOverview = createAction("[FAQCategory] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[FAQCategory] Clear");

export const Get = createAction("[FAQCategory] Get", props<{ id: number }>());
export const GetError = createAction("[FAQCategory] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[FAQCategory] GetSuccess", props<FAQCategoryUpdateModel>());

export const Update = createAction("[FAQCategory] Update", props<FAQCategoryUpdateModel>());
export const UpdateError = createAction("[FAQCategory] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[FAQCategory] UpdateSuccess", props<FAQCategoryUpdateModel>());

export const ChangeSequence = createAction("[FAQCategory] ChangeSequence", props<FAQCategoryChangeSequenceModel>());
export const ChangeSequenceError = createAction("[FAQCategory] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[FAQCategory] ChangeSequenceSuccess");

export const Create = createAction("[FAQCategory] Create", props<FAQCategoryCreateModel>());
export const CreateError = createAction("[FAQCategory] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[FAQCategory] CreateSuccess", props<FAQCategoryUpdateModel>());

export const Delete = createAction("[FAQCategory] Delete", props<{ id: number }>());
export const DeleteError = createAction("[FAQCategory] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[FAQCategory] DeleteSuccess");