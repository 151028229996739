<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>{{ (!!formGroup.get('id')?.value ? "faq-categories.detail.edit-title" : "faq-categories.detail.add-title") | translate }}</h4>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "faq-categories.fields.category" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="categoryValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.category" />

                <ng-template #categoryValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'category'" [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <div class="button-container">
            <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
                {{ "common.save" | translate }}
            </button>
        </div>
    </nz-card>
</form>