@if(activitySchedule) {
<div class="page-header">
  <h1 class="page-header__title">
    {{ activitySchedule.activityName }}
  </h1>
</div>

<div class="attendancy">
  <div class="attendancy-header">
    <div class="attendancy-row">
      <div class="attendancy-name">{{ "common.name" | translate }}</div>
      <div class="attendancy-choice">
        {{ "activity-schedule.absent" | translate }}
      </div>
      <div class="attendancy-choice">
        {{ "activity-schedule.present" | translate }}
      </div>
    </div>
  </div>
  <div class="attendancy-body">
    @for(attendee of activitySchedule.attendees; track attendee) {
    <div class="attendancy-row">
      <div class="attendancy-name" (click)="onModelChange(attendee.relationId, !attendee.inAttendance)">
        {{ attendee.name }}
      </div>
      <nz-radio-group
        [ngModel]="attendee.inAttendance"
        (ngModelChange)="onModelChange(attendee.relationId, $event)"
        nzSize="large"
      >
        <div class="attendancy-choice attendancy-red">
          <label nz-radio [nzValue]="false"></label>
        </div>
        <div class="attendancy-choice">
          <label nz-radio [nzValue]="true"></label>
        </div>
      </nz-radio-group>
    </div>
    }
  </div>
</div>

<button nz-button nzBlock nzType="primary" nzSize="large" (click)="submit()">
  {{ "common.save" | translate }}
</button>
}
