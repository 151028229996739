import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as FAQOverview from './faq-overview.reducer'
import * as FAQDetailPage from './faq-detail-page.reducer'

export const faqFeatureKey = 'faq';

export interface FAQState {
    faqOverview: FAQOverview.State,
    faqDetail: FAQDetailPage.State
}

export interface State {
    faq: FAQState
}

const map: ActionReducerMap<FAQState, Action> = {
    faqOverview: FAQOverview.reducer,
    faqDetail: FAQDetailPage.reducer
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: FAQState, action: Action) => combineReducers(map)(state, action);

const selectFAQState = createFeatureSelector<FAQState>(faqFeatureKey);
export const selectState = createSelector(selectFAQState, state => state)

export const selectFAQOverviewState = createSelector(selectState, state => state.faqOverview)
export const selectFAQOverviewLoading = createSelector(selectFAQOverviewState, FAQOverview.getLoading)
export const selectFAQOverviewList = createSelector(selectFAQOverviewState, FAQOverview.getList)
export const selectFAQOverviewPagination = createSelector(selectFAQOverviewState, FAQOverview.getPagination)
export const selectFAQOverviewSorting = createSelector(selectFAQOverviewState, FAQOverview.getSorting)
export const selectFAQOverviewFAQCategoryId = createSelector(selectFAQOverviewState, FAQOverview.getFAQCategoryId);
export const selectFAQOverviewFAQCategories = createSelector(selectFAQOverviewState, FAQOverview.getFAQCategories);

export const selectFAQDetailState = createSelector(selectState, state => state.faqDetail);
export const selectDetailLoading = createSelector(selectFAQDetailState, FAQDetailPage.getLoading);
export const selectDetailFAQ = createSelector(selectFAQDetailState, FAQDetailPage.getFAQ);
export const selectDetailErrors = createSelector(selectFAQDetailState, FAQDetailPage.getErrors);
export const selectDetailFAQCategoryId = createSelector(selectFAQDetailState, FAQDetailPage.getFAQCategoryId);
export const selectDetailFAQCategories = createSelector(selectFAQDetailState, FAQDetailPage.getFAQCategories);