<nz-table
    #overviewTable
    nzShowSizeChanger
    [nzPageSizeOptions]="this.PageSizes"
    [nzData]="data.list"
    [nzPageIndex]="data.pagination.page"
    [(nzPageSize)]="data.pagination.pageSize"
    [nzTotal]="data.pagination.totalItems ?? 0"
    [nzLoading]="data.loading"
    [nzFrontPagination]="false"
    (nzQueryParams)="onQueryParamsChange.emit($event)"
    nzTableLayout="fixed">
    <thead>
        <tr>
            <th
                nzWidth="80px"
                nzColumnKey="Active"
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Relation,
                        claimValues: [ClaimValues.Update]
                    }
                ]">
                {{ 'relation.fields.active' | translate }}
            </th>
            <th nzColumnKey="Id" [nzSortFn]="true" [nzSortOrder]="'ascend'">
                {{ 'relation.fields.relation-id' | translate }}
            </th>
            <th nzColumnKey="RelationType.Name" [nzSortFn]="true">
                {{ 'relation.fields.relation-type' | translate }}
            </th>
            <th nzColumnKey="Company.Name" [nzSortFn]="true">
                {{ 'relation.fields.company' | translate }}
            </th>
            <th nzColumnKey="User.Fullname" [nzSortFn]="true">
                {{ 'common.name' | translate }}
            </th>
            <th nzColumnKey="User.WebsiteUser.City" [nzSortFn]="true">
                {{ 'relation.fields.city' | translate }}
            </th>
            <th nzColumnKey="User.PhoneNumber" [nzSortFn]="true">
                {{ 'relation.fields.phone-number' | translate }}
            </th>
            <th nzColumnKey="User.Email" [nzSortFn]="true">
                {{ 'relation.fields.email' | translate }}
            </th>
            <th
                nzWidth="120px"
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Relation,
                        claimValues: [ClaimValues.Read, ClaimValues.Delete]
                    }
                ]"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of overviewTable.data">
            <td
                nzAlign="center"
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Relation,
                        claimValues: [ClaimValues.Update]
                    }
                ]">
                <label
                    nz-checkbox
                    [nzChecked]="row.active"
                    (nzCheckedChange)="changeActive(row.id)"></label>
            </td>

            <td [class]="this.hasReadClaims ? 'mouse-pointer' : ''" (keyup)="clickRow(row.id)" (click)="clickRow(row.id)">{{ row.id }}</td>
            <td [class]="this.hasReadClaims ? 'mouse-pointer' : ''" (keyup)="clickRow(row.id)" (click)="clickRow(row.id)">
                {{ row.relationTypeName | truncateText }}
            </td>
            <td [class]="this.hasReadClaims ? 'mouse-pointer' : ''" (keyup)="clickRow(row.id)" (click)="clickRow(row.id)">
                {{ row.companyName | truncateText }}
            </td>

            <td [class]="this.hasReadClaims ? 'mouse-pointer' : ''" (keyup)="clickRow(row.id)" (click)="clickRow(row.id)">
                {{ row.name | truncateText }}
            </td>

            <td [class]="this.hasReadClaims ? 'mouse-pointer' : ''" (keyup)="clickRow(row.id)" (click)="clickRow(row.id)">
                {{ row.city | truncateText }}
            </td>
            <td [class]="this.hasReadClaims ? 'mouse-pointer' : ''" (keyup)="clickRow(row.id)" (click)="clickRow(row.id)">
                {{ row.phoneNumber | truncateText }}
            </td>
            <td>
                <a [href]="'mailto:' + row.email">
                    {{ row.email | truncateText }}
                </a>
            </td>
            <td
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Relation,
                        claimValues: [ClaimValues.Read, ClaimValues.Delete]
                    }
                ]">
                <div class="table-actions">
                    <a
                        claimDirective
                        [claims]="[
                            {
                                claimType: ClaimTypes.Relation,
                                claimValues: [ClaimValues.Read]
                            }
                        ]"
                        [routerLink]="['/relaties/detail', row.id]"
                        nz-button
                        nzType="text"
                        nzShape="circle">
                        <i class="fa-regular fa-magnifying-glass"></i>
                    </a>
                </div>
            </td>
        </tr>
    </tbody>
</nz-table>
