
import * as RedirectActions from "@app/modules/redirects/actions/redirect-api.actions";
import { RedirectUpdateModel } from "@app/modules/redirects/models";
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    redirect: null,
    loading: false,
    slugExists: false,
    errors: {}
}

export interface State {
    redirect: RedirectUpdateModel | null,
    loading: boolean,
    slugExists: boolean;
    errors: { [key: string]: string[] }
}

export const reducer = createReducer(initialState,
    on(RedirectActions.Clear, () => ({ ...initialState })),
    on(RedirectActions.SlugExistsSuccess, (state, { response }) => ({ ...state, slugExists: response })),
	on(RedirectActions.ResetSlugExists, (state) => ({ ...state, slugExists: initialState.slugExists })),

    on(RedirectActions.Get, (state) => ({ ...state, loading: true })),
    on(RedirectActions.GetSuccess, (state, redirect) => ({ ...state, redirect, loading: false })),
    on(RedirectActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(RedirectActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(RedirectActions.UpdateSuccess, (state, redirect) => ({ ...state, redirect, loading: false })),
    on(RedirectActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(RedirectActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(RedirectActions.CreateSuccess, (state, redirect) => ({ ...state, redirect, loading: false })),
    on(RedirectActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getRedirect = (state: State) => state.redirect;
export const getSlugExists = (state: State) => state.slugExists;
