import { NgModule } from '@angular/core';
import { EmailTemplateDetailFormComponent } from '@app/modules/email-template/components/email-template-detail-form/email-template-detail-form.component';
import { EmailTemplateEffects } from '@app/modules/email-template/effects/email-template.effects';
import { EmailTemplateRoutingModule } from '@app/modules/email-template/email-template-routing.module';
import { EmailTemplateDetailComponent } from '@app/modules/email-template/pages/detail/email-template-detail.component';
import { EmailTemplateOverviewComponent } from '@app/modules/email-template/pages/overview/email-template-overview.component';
import { emailTemplateFeatureKey, reducers } from '@app/modules/email-template/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { EmailTemplateOverviewTableComponent } from '@app/modules/email-template/components/email-template-overview-table/email-template-overview-table.component';

const COMPONENTS = [EmailTemplateOverviewComponent, EmailTemplateDetailComponent, EmailTemplateDetailFormComponent, EmailTemplateOverviewTableComponent]; 

const IMPORTS = [
	SharedModule,
	EmailTemplateRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(emailTemplateFeatureKey, reducers),
	EffectsModule.forFeature([EmailTemplateEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class EmailTemplateModule {}
