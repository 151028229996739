import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MaterialService } from '@app/modules/materials/services/material.service';
import { catchError, map, tap } from 'rxjs/operators';
import * as MaterialApiActions from '@app/modules/materials/actions/material-api.actions';
import { Store } from '@ngrx/store';
import { State } from '@app/reducers';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const materialService = inject(MaterialService);
  const router = inject(Router);
  const store = inject(Store<State>);

  return materialService.get(route.params['id']).pipe(
    map((material) => MaterialApiActions.GetSuccess(material)),
    tap((action: any) => store.dispatch(action)),
    map((material) => !!material),
    catchError(() => {
      return router.navigate(['/404']);
    })
  );
}; 

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
