import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CountryModel } from '@app/modules/countries/models';
import { RelationCreateModel, RelationUpdateModel } from '@app/modules/relation/models';
import { SalutationType, SalutationTypeOptions } from '@app/shared/enums/salutation.type';
import { BillingInformationModel } from '@app/shared/models';
import { BillingInformationFormGroup } from '@app/shared/models/billing-information/billing-information-form-group';
import { ClaimTypes, ClaimValues, Constants } from '@app/shared/models/common/constants';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
    selector: 'app-relation-detail-form',
    templateUrl: './relation-detail-form.component.html',
})
export class RelationDetailFormComponent implements OnInit, OnChanges {
    update = false;
    readonly?: boolean;

    hasTheme = false;
    destroyRef = inject(DestroyRef);

    salutationTypes = SalutationTypeOptions;

    @Input() loading = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input() relationTypes: KeyValueModel[] = [];
    @Input() countries: CountryModel[] = [];
    @Input({ required: true }) relation!: RelationUpdateModel | null;

    @Output() submitted = new EventEmitter<RelationCreateModel | RelationUpdateModel>();
    @Output() generatePassword = new EventEmitter();

    formGroup = new FormGroup({
        id: new FormControl<number | null>(null),

        relationTypeId: new FormControl<number | null>(null, CustomValidators.required),

        salutation: new FormControl<SalutationType>(SalutationType.Unknown, [CustomValidators.required]),

        firstName: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        middleName: new FormControl<string>('', [CustomValidators.maxLength(100)]),
        lastName: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),

        countryId: new FormControl<number | null>(null, CustomValidators.required),
        address: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(200)]),
        city: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(100)]),
        zipCode: new FormControl<string>('', [CustomValidators.required, Validators.maxLength(10)]),

        email: new FormControl<string>('', [
            CustomValidators.required,
            Validators.maxLength(200),
            Validators.pattern(Constants.EmailExpression),
        ]),
        dietaryPreference: new FormControl<string>('', Validators.maxLength(200)),
        active: new FormControl<boolean>(true),
        isStudent: new FormControl<boolean>(false),
        wasStudent: new FormControl<boolean>(false),

        phone1: new FormControl<string>('', CustomValidators.required),
        phone1Code: new FormControl<string>('', CustomValidators.required),

        phone2: new FormControl<string>(''),
        phone2Code: new FormControl<string>(''),

        billingInformation: new BillingInformationFormGroup({} as BillingInformationModel)
    });

    constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = this.relation != null && Object.keys(this.relation).length > 0;

        if (
            this.update &&
            !(await this.claimService.hasAny([{ claimType: ClaimTypes.Relation, claimValues: [ClaimValues.Update] }]))
        ) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

        if (this.update) {
            this.formGroup.controls.id.setValidators([CustomValidators.required]);
            this.formGroup.controls.id.setValue(this.relation.id);

            this.formGroup.patchValue({
                active: this.relation.active,
                isStudent: this.relation.isStudent,
                wasStudent: this.relation.wasStudent,
                dietaryPreference: this.relation.dietaryPreference,
                relationTypeId: this.relation.relationTypeId,
                salutation: this.relation.salutation,
                firstName: this.relation.firstName,
                middleName: this.relation.middleName,
                lastName: this.relation.lastName,
                address: this.relation.address,
                zipCode: this.relation.zipCode,
                city: this.relation.city,
                countryId: this.relation.countryId,
                phone1Code: this.relation.phone1CountryCode,
                phone1: this.relation.phone1,
                phone2Code: this.relation.phone2CountryCode ?? null,
                phone2: this.relation.phone2 ?? null,
                email: this.relation.email,
                billingInformation: new BillingInformationFormGroup({
                    id: this.relation.selectedBillingInformationId,
                    address: this.relation.billingInformationAddress,
                    city: this.relation.billingInformationCity,
                    companyName: this.relation.billingInformationCompanyName,
                    countryId: this.relation.countryId,
                    emailAddress: this.relation.billingInformationEmailAddress,
                    name: this.relation.billingInformationName,
                    zipCode: this.relation.zipCode,
                    vatNumber: this.relation.billingInformationVATNumber,
                    sendInvoiceTo: this.relation.billingInformationSendInvoiceTo,
                })
            });

            this.formGroup.controls.billingInformation.setValidators([CustomValidators.required]);
        } else {
            this.formGroup.removeControl('billingInformation');
        }

        this.addPhoneNumerValidation();
        this.addZipCodeValidation();
        this.addZipCodeTransformToUpper();
    }

    ngOnChanges(changes: SimpleChanges) {
        processBackendValidators(changes, this.formGroup);
    }

    submit() {
        if (isFormValid(this.formGroup)) {
            this.update ? this.submitUpdate() : this.submitCreate();
        }
    }

    generateNewPassword() {
        this.generatePassword.emit();
    }

    private addZipCodeValidation() {
        this.formGroup.controls.countryId.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(countryId => {
            this.formGroup.controls.zipCode.clearValidators();
            const validators = [CustomValidators.required, Validators.maxLength(10)];
            const selectedCountry = this.countries.find(x => x.id == countryId);

            if (selectedCountry?.validation) {
                validators.push(CustomValidators.zipCodeValidation(selectedCountry.validation));
                
            }

            this.formGroup.controls.zipCode.addValidators(validators);

            if (this.formGroup.controls.zipCode.touched) {
                this.formGroup.controls.zipCode.markAsDirty();
                this.formGroup.controls.zipCode.updateValueAndValidity();
            }
        });
    }

    private addPhoneNumerValidation() {
        this.formGroup.controls.phone1Code.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(phone1Code => {
            this.formGroup.controls.phone1.clearValidators();
            const validators: ValidatorFn[] = [CustomValidators.required];

            const selectedCountry = this.countries.find(x => x.phoneCode == phone1Code);

            if (selectedCountry?.phoneValidation) {
                validators.push(CustomValidators.phoneNumberValidation(selectedCountry.phoneValidation));
            }

            this.formGroup.controls.phone1.addValidators(validators);

            if (this.formGroup.controls.phone1.touched) {
                this.formGroup.controls.phone1.markAsDirty();
                this.formGroup.controls.phone1.updateValueAndValidity();
            }
        });

        this.formGroup.controls.phone2Code.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(phone2Code => {
            this.formGroup.controls.phone2.clearValidators();
            const validators: ValidatorFn[] = [];

            const selectedCountry = this.countries.find(x => x.phoneCode == phone2Code);

            if (selectedCountry?.phoneValidation) {
                validators.push(CustomValidators.phoneNumberValidation(selectedCountry.phoneValidation));
            }

            this.formGroup.controls.phone2.addValidators(validators);

            if (this.formGroup.controls.phone2.touched) {
                this.formGroup.controls.phone2.markAsDirty();
                this.formGroup.controls.phone2.updateValueAndValidity();
            }
        });
    }

    private addZipCodeTransformToUpper() 
    {
        this.formGroup.controls.zipCode.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(zipCode => {
                this.formGroup.controls.zipCode.patchValue(zipCode.toUpperCase(), {emitEvent: false});
            });
    }

    private submitCreate() {
        this.submitted.emit({
            active: this.formGroup.controls.active.value,
            isStudent: this.formGroup.controls.isStudent.value,
            wasStudent: this.formGroup.controls.wasStudent.value,
            dietaryPreference: this.formGroup.controls.dietaryPreference.value,
            relationTypeId: this.formGroup.controls.relationTypeId.value,
            salutation: this.formGroup.controls.salutation.value,
            firstName: this.formGroup.controls.firstName.value,
            middleName: this.formGroup.controls.middleName.value,
            lastName: this.formGroup.controls.lastName.value,
            address: this.formGroup.controls.address.value,
            zipCode: this.formGroup.controls.zipCode.value,
            city: this.formGroup.controls.city.value,
            countryId: this.formGroup.controls.countryId.value,
            phone1CountryCode: this.formGroup.controls.phone1Code.value,
            phone1: this.formGroup.controls.phone1.value,
            phone2CountryCode: this.formGroup.controls.phone2Code.value,
            phone2: this.formGroup.controls.phone2.value,
            email: this.formGroup.controls.email.value
        } as RelationCreateModel);
    }

    private submitUpdate() {
        //this.submitted.emit({});
    }
}
