import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { relationTypeClearGuard, relationTypeExistsGuard } from '@app/modules/relation-type/guards';
import { RelationTypeDetailComponent } from '@app/modules/relation-type/pages/detail/relation-type-detail.component';
import { RelationTypeOverviewComponent } from '@app/modules/relation-type/pages/overview/relation-type-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: RelationTypeOverviewComponent },
    { path: 'toevoegen', component: RelationTypeDetailComponent, canActivate: [ relationTypeClearGuard ] },
    { path: 'bewerken/:id', component: RelationTypeDetailComponent, canActivate: [ relationTypeExistsGuard ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RelationTypeRoutingModule { }
