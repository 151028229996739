import { Component } from '@angular/core';
import * as Tag from '@app/modules/tag/reducers'
import { Store } from '@ngrx/store';
import * as TagApiActions from '@app/modules/tag/actions/tag-api.actions';
import { State } from '@app/reducers';
import { TagCreateModel, TagUpdateModel } from "@app/modules/tag/models";

@Component({
    selector: 'app-tag-detail',
    templateUrl: './tag-detail.component.html',
    styleUrls: ['./tag-detail.component.less'],
})
export class TagDetailComponent {
    loading$ = this.store.select(Tag.selectDetailLoading);
    errors$ = this.store.select(Tag.selectDetailErrors);
    tag$ = this.store.select(Tag.selectDetailTag);

    constructor(private readonly store: Store<State>) { }

    submit(tag: TagCreateModel | TagUpdateModel) {
        this.store.dispatch(tag.hasOwnProperty('id')
            ? TagApiActions.Update(tag as TagUpdateModel)
            : TagApiActions.Create(tag)
        );
    }
}
