import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-front-end-validation',
	templateUrl: './front-end-validation.component.html',
	styleUrls: ['./front-end-validation.component.less'],
	encapsulation: ViewEncapsulation.None,
})
export class FrontEndValidationComponent {
	@Input({ required: true }) field!: string;
	@Input({ required: true }) control!: AbstractControl | FormControl | FormArray | FormGroup;

	getErrorKey(validator: string) {
		return `error.${this.field}-${validator.toLowerCase()}`;
	}
}
