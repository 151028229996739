import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import nl from '@angular/common/locales/nl';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

import {
	BackEndValidationComponent,
	FrontEndValidationComponent,
	InputValidationComponent,
	WYSIWYGEditorComponent,
} from '@app/shared/components';
import { AssetOverviewComponent } from '@app/shared/components/asset-overview/asset-overview.component';
import { ContentBlockOverviewComponent } from '@app/shared/components/content-block-overview/content-block-overview.component';
import { CurrencyInputComponent } from '@app/shared/components/currency-input/currency-input.component';

import { SelectSingleComponent } from '@app/shared/components/select-single/select-single.component';
import { ClaimDirective } from '@app/shared/directives/claim.directive';
import { EditorDraggableDirective } from '@app/shared/directives/editor-draggable.directive';
import { DiscountPipe } from '@app/shared/pipes/discount.pipe';
import { EnumKeyValuePipe } from '@app/shared/pipes/enum-key-value.pipe';
import { TruncateTextPipe } from '@app/shared/pipes/truncate-text.pipe';
import { BlogStatusPipe } from '@app/shared/pipes/blog-status.pipe';
import { SanitizeHtmlPipe } from '@app/shared/pipes/sanitize-html.pipe';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NZ_I18N, nl_NL } from 'ng-zorro-antd/i18n';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzResultModule } from 'ng-zorro-antd/result';

import { EditorContentBlockComponent, ImageAndEditorContentBlockComponent } from '@app/shared/components/content-blocks';
import { AssetComponent } from '@app/shared/components/asset/asset.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

registerLocaleData(nl);

const modules = [
	CommonModule,
	RouterLink,
	RouterLinkActive,
	FormsModule,
	ReactiveFormsModule,
	TranslateModule,
	DragDropModule,
	NzButtonModule,
	NzFormModule,
	NzInputModule,
	NzRadioModule,
	NzCheckboxModule,
	NzCardModule,
	NzTypographyModule,
	NzSkeletonModule,
	NzTabsModule,
	NzGridModule,
	NzProgressModule,
	NzTableModule,
	NzPopconfirmModule,
	NzSelectModule,
	NzDropDownModule,
	NzCollapseModule,
	NzListModule,
	NzModalModule,
	NzDatePickerModule,
    NzDividerModule,
    NzTagModule,
	NzResultModule,
	NzTimePickerModule,
];

@NgModule({
	declarations: [
		BackEndValidationComponent,
		FrontEndValidationComponent,
		InputValidationComponent,
		ClaimDirective,
		EditorDraggableDirective,
		WYSIWYGEditorComponent,
		TruncateTextPipe,
		CurrencyInputComponent,
		ContentBlockOverviewComponent,
		EditorContentBlockComponent,
        ImageAndEditorContentBlockComponent,
		EnumKeyValuePipe,
		AssetOverviewComponent,
        AssetComponent,
		SelectSingleComponent,
		DiscountPipe,
		BlogStatusPipe,
		SanitizeHtmlPipe,
	],
	exports: [
		...modules,
		BackEndValidationComponent,
		FrontEndValidationComponent,
		InputValidationComponent,
		ClaimDirective,
		EditorDraggableDirective,
		WYSIWYGEditorComponent,
		TruncateTextPipe,
		CurrencyInputComponent,
		ContentBlockOverviewComponent,
		EditorContentBlockComponent,
		EnumKeyValuePipe,
		AssetOverviewComponent,
        AssetComponent,
		SelectSingleComponent,
		DiscountPipe,
		BlogStatusPipe,
		SanitizeHtmlPipe,
	],
	imports: [...modules, EditorModule],
	providers: [{ provide: NZ_I18N, useValue: nl_NL }],
})
export class SharedModule {}
