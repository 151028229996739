import { Component } from '@angular/core';
import * as ClaimCategoryActions from '@app/modules/claim-categories/actions/claim-category-api.actions';
import { ClaimCategoryUpdateModel } from '@app/modules/claim-categories/models';
import * as ClaimCategory from '@app/modules/claim-categories/reducers';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-claim-category-detail',
	templateUrl: './claim-category-detail.component.html',
	styleUrls: ['./claim-category-detail.component.less'],
})
export class ClaimCategoryDetailComponent {
	loading$ = this.store.select(ClaimCategory.selectDetailLoading);
	errors$ = this.store.select(ClaimCategory.selectDetailErrors);
	claimCategory$ = this.store.select(ClaimCategory.selectDetailClaimCategory);

	constructor(private readonly store: Store<State>) {}

	submit(claimCategory: ClaimCategoryUpdateModel) {
		this.store.dispatch(ClaimCategoryActions.Update({ claimCategory }));
	}
}
