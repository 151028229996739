import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'truncateText'})
export class TruncateTextPipe implements PipeTransform{
    transform(rawText: string, maxLength: number = 100): string {
        if (!rawText || rawText.length <= maxLength) {
            return rawText;
        } 
        else {
            return rawText.slice(0, maxLength)+'...'
        }
    }
}
