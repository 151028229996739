import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { blogCategoryClearGuard, blogCategoryExistsGuard } from '@app/modules/blog-category/guards';
import { BlogCategoryDetailComponent } from '@app/modules/blog-category/pages/detail/blog-category-detail.component';
import { BlogCategoryOverviewComponent } from '@app/modules/blog-category/pages/overview/blog-category-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: BlogCategoryOverviewComponent },
    { path: 'toevoegen', component: BlogCategoryDetailComponent, canActivate: [ blogCategoryClearGuard ] },
    { path: 'bewerken/:id', component: BlogCategoryDetailComponent, canActivate: [ blogCategoryExistsGuard ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BlogCategoryRoutingModule { }
