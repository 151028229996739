import { Component, Input } from '@angular/core';
import { ActivityUpdateModel } from '@app/modules/activity/models';

@Component({
    selector: 'app-activity-view-card',
    templateUrl: './activity-view-card.component.html',
})
export class ActivityViewCardComponent {
    @Input({ required: true }) activity!: ActivityUpdateModel;
    @Input() loading = false;
}
