import { IdModel } from "@app/shared/models/common/id.model";
import { ContentBlockType } from "@app/shared/models/content/content-block-type.enum";

export interface ContentBlock extends IdModel {
    content: string;
    type: ContentBlockType;
    sequenceNumber: number;
    label: string;
}

export interface ContentBlockModel {
    contentBlocks: ContentBlock[];
}

export const serializeContentBlocks = (contentBlockModel: ContentBlockModel): ContentBlockModel => {
    return {
        ...contentBlockModel, contentBlocks: contentBlockModel.contentBlocks.map((contentBlock) => {
            return { ...contentBlock, content: JSON.stringify(contentBlock.content) }
        })
    };
}