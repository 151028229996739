import { LOCALE_ID, NgModule } from '@angular/core';
import { MobileTopbarComponent } from '@app/mobile/shared/components/topbar/mobile-topbar.component';
import { MobileRoutingModule } from '@app/mobile/mobile-routing.module';
import { MobileAuthEffects } from '@app/mobile/modules/auth/effects/mobile-auth.effects';
import { authFeatureKey, reducers } from '@app/mobile/modules/auth/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { NzFormModule } from 'ng-zorro-antd/form';
import { StoreModule } from '@ngrx/store';

const IMPORTS = [
	SharedModule,
	MobileRoutingModule,
	NzFormModule,
	MobileTopbarComponent,
	StoreModule.forFeature(authFeatureKey, reducers),
	EffectsModule.forFeature([MobileAuthEffects]),
];

@NgModule({
	imports: [IMPORTS],
	providers: [
		{ provide: LOCALE_ID, useValue: "nl-NL" }
	],
})
export class MobileModule {}
