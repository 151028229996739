import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import { NoteType, NoteTypeIdNames } from '@app/modules/note/enum/note-type.enum';
import { NoteCreateModel } from '@app/modules/note/models/note-create.model';
import { NoteItemModel } from '@app/modules/note/models/note-overview.model';
import { NoteUpdateModel } from '@app/modules/note/models/note-update.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NoteService {
    private readonly endpoints = {
        getAll: 'Note/GetAll',
        create: 'Note/Create',
        setImportant: 'Note/SetImportant',
        delete: 'Note/Delete?id=:id',
        update: 'Note/Update',
    };

    constructor(private readonly httpService: HttpService) {}

    getActivityNotesList(noteTypeId: number, noteType: NoteType): Observable<NoteItemModel[]> {
        return this.httpService.get<NoteItemModel[]>(
            this.endpoints.getAll +
                `?${NoteTypeIdNames[noteType]}=${noteTypeId.toString()}&NoteType=${noteType.toString()}`,
        );
    }

    create(request: NoteCreateModel): Observable<NoteCreateModel> {
        if (request.noteType === NoteType.ActivityWaitlist) {
            request = { ...request, activityId: request.activityId };
        } else if (request.noteType === NoteType.Relation) {
            request = { ...request, relationId: request.noteTypeId };
        }
        return this.httpService.post<NoteCreateModel>(this.endpoints.create, request);
    }

    update(request: NoteUpdateModel): Observable<NoteUpdateModel> {
        return this.httpService.put<NoteUpdateModel>(this.endpoints.update, request);
    }

    setImportant(noteId: number): Observable<NoteCreateModel> {
        return this.httpService.put<NoteCreateModel>(this.endpoints.setImportant, { id: noteId });
    }

    delete(id: number): Observable<void> {
        return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
    }
}
