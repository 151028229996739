import { Component, Input } from '@angular/core';
import { RelationUpdateModel } from '@app/modules/relation/models';

@Component({
    selector: 'app-relation-view-card',
    templateUrl: './relation-view-card.component.html',
})
export class RelationViewCardComponent {
    @Input({ required: true }) relation!: RelationUpdateModel;
    @Input() loading = false;
}
