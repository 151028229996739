<div class="page-header">
	<a routerLink="/gebruikers/overzicht" class="page-header__back">
		<i class="fa-regular fa-chevron-left"></i>
	</a>
	<h1 class="page-header__title">{{ 'users.title' | translate }}</h1>
	<div class="page-header__actions"></div>
</div>

<app-user-detail-form
	[user]="user$ | async"
	[userRoles]="userRoles$ | async"
	(submitted)="submit($event)"
	[errors]="errors$ | async"
	[loading]="loading$ | async" 
	[currentUser]="currentUser$ | async"
	[userExists]="(userExists$ | async)!"
	(downloadSignature)="downloadSignature($event)"
	(resetUserExists)="resetUserExists()"
/>
