import { Directive, Input, OnInit } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";

@Directive()
export abstract class AbstractBlockComponent implements OnInit {
    @Input({required: true}) isReadOnly: boolean = false;
    @Input() errors: { [key: string]: string[] } = {};
    @Input() formGroupName!: string;
    formGroup!: FormGroup;

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit() {
        if (this.controlContainer) {
            if (this.formGroupName) {
                this.formGroup = this.controlContainer.control!.get(this.formGroupName) as FormGroup;
            } else {
                console.warn('Missing FormControlName directive from host element of the component');
            }
        } else {
            console.warn('Can\'t find parent FormGroup directive');
        }
    }
}