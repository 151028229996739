import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '@app/shared/models/auth/user.model';
import * as BlogReactionActions from '@app/modules/blog-reaction/actions/blog-reaction-api.actions';
import {
  BlogReactionCreateModel,
  BlogReactionUpdateModel,
} from '@app/modules/blog-reaction/models';
import { State } from '@app/reducers';
import {
  ClaimTypes,
  ClaimValues,
  Constants,
} from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import {
  isFormValid,
  processBackendValidators,
} from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-blog-reaction-detail-form',
  templateUrl: './blog-reaction-detail-form.component.html',
  styleUrls: ['./blog-reaction-detail-form.component.less'],
})
export class BlogReactionDetailFormComponent implements OnInit, OnChanges {
  update = false;
  readonly?: boolean;

  @Input() loading = false;
  @Input() user: UserModel | null = null;
  @Input() errors: { [key: string]: string[] } = {};
  @Input({ required: true }) blogId: number | undefined;
  @Input({ required: true }) parentReactionId: number | undefined;
  @Input({ required: true }) blogReaction: BlogReactionUpdateModel | null =
    null;

  @Output() submitted = new EventEmitter<
    BlogReactionCreateModel | BlogReactionUpdateModel
  >();

  formGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    showOnline: new FormControl<boolean>(true),
    name: new FormControl<string>('', [
      CustomValidators.required,
      Validators.maxLength(100),
    ]),
    userName: new FormControl<string | null>(
      { value: null, disabled: true },
      CustomValidators.required
    ),
    emailAddress: new FormControl<string>('', [
      CustomValidators.required,
      Validators.pattern(Constants.EmailExpression),
      Validators.maxLength(100),
    ]),
    reaction: new FormControl<string>('', [CustomValidators.required]),
    sendSubreactionNotification: new FormControl<boolean>(false),
    sendReactionNotification: new FormControl<boolean>(false),
  });

  constructor(
    private readonly claimService: ClaimService,
    private readonly store: Store<State>
  ) {}

  async ngOnInit() {
    await this.fillForm();
  }

  onQueryParamsChange() {
    // Ignore this call. Table is filled with property of detail object and not with a separate call.
  }

  async ngOnChanges(changes: SimpleChanges) {
    processBackendValidators(changes, this.formGroup);

    if(changes["blogreaction"] !== null) {
      await this.fillForm();
    }
  }

  submit() {
    if (isFormValid(this.formGroup)) {
      this.update ? this.submitUpdate() : this.submitCreate();
    }
  }

  private async fillForm() {
    this.update = !!this.blogReaction;

    if (
      this.update &&
      !(await this.claimService.hasAny([
        {
          claimType: ClaimTypes.BlogReactions,
          claimValues: [ClaimValues.Update],
        },
      ]))
    ) {
      this.formGroup.disable();
      this.readonly = true;
    } else {
      this.readonly = false;
    }
    if (this.update) {
      this.formGroup.patchValue({
        name: this.blogReaction?.name,
        showOnline: this.blogReaction?.showOnline,
        userName: this.blogReaction?.fullName,
        emailAddress: this.blogReaction?.emailAddress,
        reaction: this.blogReaction?.reaction,
        sendSubreactionNotification:
          this.blogReaction?.sendSubReactionNotification,
        sendReactionNotification: this.blogReaction?.sendReactionNotification,
      });

      this.formGroup.controls.id.setValidators([Validators.required]);
      this.formGroup.controls.id.setValue(
        (this.blogReaction as BlogReactionUpdateModel).id
      );
    } else {
      this.formGroup.controls.name.setValue(this.user!.fullName!);
      this.formGroup.controls.emailAddress.setValue(this.user!.email);
      this.formGroup.controls.userName.setValue(this.user!.fullName);
    }
  }

  private submitCreate() {
    this.submitted.emit({
      name: this.formGroup.controls.name.value!,
      emailAddress: this.formGroup.controls.emailAddress.value ?? '',
      reaction: this.formGroup.controls.reaction.value!,
      fullName: this.formGroup.controls.userName.value!,
      userId: this.user!.id,
      blogId: this.blogId!,
      showOnline: this.formGroup.controls.showOnline.value ?? false,
      sendReactionNotification:
        this.formGroup.controls.sendReactionNotification.value ?? false,
      sendSubReactionNotification:
        this.formGroup.controls.sendSubreactionNotification.value ?? false,
      parentReactionId: this.parentReactionId,
    });
  }

  onDeleteConfirm(id: number): void {
    this.store.dispatch(BlogReactionActions.DeleteFromDetailPage({ id: id, parentReactionId: this.blogReaction!.id }));
  }

  onChangeShowOnline(event: { id: number; showOnline: boolean; }): void {
    this.store.dispatch(
      BlogReactionActions.UpdateShowOnlineFromDetailPage({
        id: event.id,
        showOnline: event.showOnline,
      })
    );
  }

  private submitUpdate() {
    this.submitted.emit({
      id: this.formGroup.controls.id.value,
      name: this.formGroup.controls.name.value!,
      emailAddress: this.formGroup.controls.emailAddress.value ?? '',
      reaction: this.formGroup.controls.reaction.value!,
      fullName: this.formGroup.controls.userName.value!,
      userId: this.blogReaction!.userId,
      blogId: this.blogId!,
      showOnline: this.formGroup.controls.showOnline.value ?? false,
      sendReactionNotification:
        this.formGroup.controls.sendReactionNotification.value ?? false,
      sendSubReactionNotification:
        this.formGroup.controls.sendSubreactionNotification.value ?? false,
      parentReactionId: this.parentReactionId,
    } as BlogReactionUpdateModel);
  }
}
