import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@app/core/services/token/token.service';
import * as AuthActions from '@app/modules/auth/actions/auth.actions';
import { AuthService } from '@app/modules/auth/services/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from '@src/environments/environment';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Login),
      exhaustMap((action) =>
        this.authService.login(action.loginRequest).pipe(
          map((response) =>
            AuthActions.LoginSuccess({ loginResponse: response })
          ),
          catchError((error) =>
            of(AuthActions.LoginError({ response: error.error }))
          )
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginSuccess),
        tap((action) => {
          this.tokenService.setToken(action.loginResponse.token);
          this.tokenService.setMobileLogin(false);
          this.router.navigate(['dashboard/overzicht']);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Logout),
        tap(() => {
          this.tokenService.removeToken();
          this.router.navigate(['auth/login']);
        })
      ),
    { dispatch: false }
  );

  $getCurrentUser = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.GetCurrentUser),
      exhaustMap(() =>
        this.authService.currentUser().pipe(
          map((response) =>
            AuthActions.GetCurrentUserSuccess({ user: response })
          ),
          catchError(() => of(AuthActions.GetCurrentUserError()))
        )
      )
    )
  );

  $requestPasswordReset = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.RequestPasswordReset),
      exhaustMap((action) => {
        const passwordResetUrl =
          environment.backoffice.baseUrl + '/auth/wachtwoord-herstellen';
        return this.authService
          .requestPasswordReset(action.email, passwordResetUrl)
          .pipe(
            map(() => AuthActions.RequestPasswordResetSuccess()),
            catchError((error) =>
              of(
                AuthActions.RequestPasswordResetError({ response: error.error })
              )
            )
          );
      })
    )
  );

  $resetPassword = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.ResetPassword),
      exhaustMap((action) =>
        this.authService
          .resetPassword(action.email, action.password, action.token)
          .pipe(
            map(() => AuthActions.ResetPasswordSuccess()),
            catchError((error) =>
              of(AuthActions.ResetPasswordError({ response: error.error }))
            )
          )
      )
    )
  );
}
