import { ErrorResponse } from "@app/core/models/error-response.model";
import { SlugStatus } from "@app/modules/page/enums/slug-status";
import {
  PageChangeSequenceModel,
  PageChangeVisibilityModel,
  PageCreateModel,
  PageTableItemModel
} from "@app/modules/page/models";
import { PageUpdateModel } from "@app/modules/page/models/page-update.model";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";

export const GetOverview = createAction("[Page] GetOverview");
export const GetOverviewError = createAction("[Page] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Page] GetOverviewSuccess", props<TableDataModel<PageTableItemModel>>());
export const ResetOverview = createAction("[Page] ResetOverview");

export const FilterOverview = createAction("[Page] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[Page] Clear");

export const Get = createAction("[Page] Get", props<{ id: number }>());
export const GetError = createAction("[Page] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Page] GetSuccess", props<PageUpdateModel>());

export const Update = createAction("[Page] Update", props<PageUpdateModel>());
export const UpdateError = createAction("[Page] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Page] UpdateSuccess", props<PageUpdateModel>());

export const Create = createAction("[Page] Create", props<PageCreateModel>());
export const CreateError = createAction("[Page] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Page] CreateSuccess", props<PageUpdateModel>());

export const ChangeSequence = createAction("[Page] ChangeSequence", props<PageChangeSequenceModel>());
export const ChangeSequenceError = createAction("[Page] ChangeSequenceError", props<{ response: ErrorResponse }>());
export const ChangeSequenceSuccess = createAction("[Page] ChangeSequenceSuccess", props<{ parentId: number }>());

export const GetChildPages = createAction("[Page] GetChildPages", props<{ parentId: number }>());
export const GetChildPagesError = createAction("[Page] GetChildPagesError", props<{ response: ErrorResponse }>());
export const GetChildPagesSuccess = createAction("[Page] GetChildPagesSuccess", props<TableDataModel<PageTableItemModel>>());
export const ResetChildPages = createAction("[Page] ResetChildPages", props<{ parentId: number }>());

export const DeleteChildPage = createAction("[Page] DeleteChildPage", props<{ parentId: number, id: number }>());
export const DeleteChildPageError = createAction("[Page] DeleteChildPageError", props<{ response: ErrorResponse }>());
export const DeleteChildPageSuccess = createAction("[Page] DeleteChildPageSuccess", props<{ parentId: number }>());

export const ChildPagesFilterOverview = createAction("[Page] ChildPagesFilterOverview", props<{ parentId: number, sorting: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Delete = createAction("[Page] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Page] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Page] DeleteSuccess");

export const ChangeVisibility = createAction("[Page] ChangeVisibility", props<PageChangeVisibilityModel>());
export const ChangeVisibilityError = createAction("[Page] ChangeVisibilityError", props<{ response: ErrorResponse }>());
export const ChangeVisibilitySuccess = createAction("[Page] ChangeVisibilitySuccess");

export const NewSlugAsRedirectExists = createAction("[Page] RedirectFromExists", props<{ model: PageCreateModel|PageUpdateModel }>())
export const RedirectFromExistsError = createAction("[Page] RedirectFromExistsError", props<{ response: ErrorResponse }>());
export const NewSlugAsRedirectExistsSuccess = createAction("[Page] RedirectFromExistsSuccess", props<{ response: SlugStatus }>());
export const ResetRedirectExists = createAction("[Page] ResetRedirectExists");
