import { KeyValue } from '@angular/common';
import * as AuthActions from '@app/modules/auth/actions/auth.actions';
import { TokenModel } from '@app/shared/models/auth/token.model';
import { UserModel } from '@app/shared/models/auth/user.model';
import { ClaimTypes } from '@app/shared/models/common/constants';
import { createReducer, on } from '@ngrx/store';
import { jwtDecode } from 'jwt-decode';

export interface State {
	user: UserModel | null;
	token: TokenModel | null;
	claims: KeyValue<string, string[]>[] | null;
}

const initialState: State = {
	user: null,
	token: null,
	claims: null,
};

export const reducer = createReducer(
	initialState,
	on(AuthActions.LoginSuccess, (state, { loginResponse }) => ({
		...state,
		user: loginResponse.user,
		token: loginResponse.token,
		claims: getClaimsFromToken(loginResponse.token.accessToken),
	})),
	on(AuthActions.Logout, () => initialState),
	on(AuthActions.GetCurrentUserSuccess, (state, { user }) => ({ ...state, user })),
);

export const getUser = (state: State) => state.user;
export const getToken = (state: State) => state.token;
export const getClaims = (state: State) => state.claims;

function getClaimsFromToken(accessToken: string): any {
	const decodedToken = jwtDecode(accessToken) as any;
	let claims: KeyValue<string, string[]>[] = [];
	const keys = Object.keys(decodedToken);
	const claimTypeValues = Object.values(ClaimTypes) as string[];
	for (const key of keys) {
		if (claimTypeValues.includes(key)) {
			claims = [...claims, { key, value: decodedToken[key] }];
		}
	}
	return claims;
}
