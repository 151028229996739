import { SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { ErrorResponse } from '@app/core/models';
import CustomValidators from '@app/shared/validators/custom.validator';
import { TranslateService } from '@ngx-translate/core';

export const getErrorsForFormControl = (
  field: string,
  errors: { [key: string]: string[] }
) => {
  const lowerCaseKey = Object.keys(errors).find(
    (key) => key.toLowerCase() === field.toLowerCase()
  );
  return lowerCaseKey ? errors[lowerCaseKey] : [];
};

export const updateValidatorsOnFormControl = (
  control: FormControl,
  field: string,
  errors: { [key: string]: string[] }
) => {
  const validators = getErrorsForFormControl(field, errors);

  if (!validators.length) {
    if (!control.valid) {
      control.updateValueAndValidity();
    }

    return;
  }

  // This will make sure ng-zorro shows the error tooltip
  control.markAsDirty();

  validators
    .map((validator) => validator.toLowerCase())
    // This will prevent the back-end and front-end showing the same message
    .filter(
      (validator) =>
        validator !== 'required' ||
        (validator === 'required' &&
          !control!.hasValidator(CustomValidators.required))
    )
    .forEach((validator) => control.setErrors({ [validator]: true }));
};

export const processBackendValidators = (
  changes: SimpleChanges,
  formGroup: FormGroup
) => {
  if (
    Object.prototype.hasOwnProperty.call(changes, 'errors') &&
    !!changes['errors']?.currentValue
  ) {
    for (const field in formGroup.controls) {
      updateValidatorsOnFormControl(
        formGroup.get(field) as FormControl,
        field,
        changes['errors']?.currentValue
      );
    }
  }
};

export const isFormValid = (formGroup: FormGroup | FormArray) => {
  if (formGroup.valid || formGroup.disabled) {
    return true;
  } else {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);

      if (control instanceof FormArray || control instanceof FormGroup) {
        isFormValid(control);
      }

      control!.markAsTouched();
      control!.updateValueAndValidity();
    });

    return false;
  }
};

export const setValidators = (
  control: AbstractControl,
  validators: ValidatorFn[]
) => {
  control.setValidators(validators);
  control.updateValueAndValidity();
};

export const clearValidators = (control: AbstractControl) => {
  control.clearValidators();
  control.updateValueAndValidity();
};

export function BuildErrorString(
  error: ErrorResponse,
  translate: TranslateService
) {
  let buildString: string = '';

  if (!('errors' in error)) {
    return translate.instant('error.unknown');
  }
  Object.entries(error.errors).forEach((error_key) => {
    error_key[1].forEach((error_value) => {
      buildString.length != 0 && (buildString += '\n');
      buildString += translate.instant(
        `error.${error_key[0].toLowerCase()}-${error_value.toLowerCase()}`
      );
    });
  });
  return buildString;
}
