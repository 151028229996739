import { Component, Input } from "@angular/core";
import { RelationUpdateModel } from "@app/modules/relation/models";
import { BillingInformationTypeOptions } from "@app/shared/enums/billing-information.type";

@Component({
    selector: 'app-relation-view-info',
    templateUrl: './relation-view-info.component.html',
})
export class RelationViewInfoComponent {
    readonly?: boolean;

    billingInformationType = BillingInformationTypeOptions

    @Input({ required: true }) relation!: RelationUpdateModel;
    @Input() errors: { [key: string]: string[] } = {};
    @Input() loading = false;
}
