import { NgModule } from '@angular/core';
import { CountryDetailFormComponent } from '@app/modules/countries/components/country-detail-form/country-detail-form.component';
import { CountryOverviewTableComponent } from '@app/modules/countries/components/country-overview-table/country-overview-table.component';
import { CountryRoutingModule } from '@app/modules/countries/country-routing.module';
import { CountryEffects } from '@app/modules/countries/effects/country.effects';
import { CountryDetailComponent } from '@app/modules/countries/pages/detail/country-detail.component';
import { CountryOverviewComponent } from '@app/modules/countries/pages/overview/country-overview.component';
import { countryFeatureKey, reducers } from '@app/modules/countries/reducers';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';

const COMPONENTS = [
	CountryDetailFormComponent,
	CountryOverviewTableComponent,
	CountryDetailComponent,
	CountryOverviewComponent,
];

const IMPORTS = [
	SharedModule,
	CountryRoutingModule,
	NzDividerModule,
	StoreModule.forFeature(countryFeatureKey, reducers),
	EffectsModule.forFeature([CountryEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class CountryModule {}
