import { NgModule } from '@angular/core';
import { UserDetailFormComponent } from '@app/modules/users/components/user-detail-form/user-detail-form.component';
import { UserOverviewTableComponent } from '@app/modules/users/components/user-overview-table/user-overview-table.component';
import { UserEffects } from '@app/modules/users/effects/user.effects';
import { UserDetailComponent } from '@app/modules/users/pages/detail/user-detail.component';
import { UserOverviewComponent } from '@app/modules/users/pages/overview/user-overview.component';
import { reducers, userFeatureKey } from '@app/modules/users/reducers';
import { UserRoutingModule } from '@app/modules/users/user-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';

const COMPONENTS = [
    UserDetailFormComponent,
    UserOverviewTableComponent,
    UserOverviewComponent,
    UserDetailComponent
];

const IMPORTS = [
	SharedModule,
	UserRoutingModule,
	NzDividerModule,
	NzTagModule,
	NzTabsModule,
	StoreModule.forFeature(userFeatureKey, reducers),
	EffectsModule.forFeature([UserEffects]),
];

@NgModule({
	declarations: [COMPONENTS],
	imports: [IMPORTS],
	providers: [],
})
export class UsersModule {}
