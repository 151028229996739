import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { userClearGuard, userExistsGuard } from '@app/modules/users/guards';
import { UserDetailComponent } from '@app/modules/users/pages/detail/user-detail.component';
import { UserOverviewComponent } from '@app/modules/users/pages/overview/user-overview.component';

const routes: Routes = [
	{ path: '', redirectTo: 'overzicht', pathMatch: 'full' },
	{ path: 'overzicht', component: UserOverviewComponent },
	{ path: 'toevoegen', component: UserDetailComponent, canActivate: [userClearGuard] },
	{ path: 'bewerken/:id', component: UserDetailComponent, canActivate: [userExistsGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class UserRoutingModule {}
