import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CountryChangeSequenceModel, CountryTableItemModel } from '@app/modules/countries/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';

@Component({
	selector: 'app-country-overview-table',
	templateUrl: './country-overview-table.component.html',
})
export class CountryOverviewTableComponent implements OnInit{
	@Input({ required: true }) data!: TableStateModel<CountryTableItemModel>;

	@Output() delete = new EventEmitter<number>();
	@Output() onQueryParamsChange = new EventEmitter<any>();
	@Output() changeSequence = new EventEmitter<CountryChangeSequenceModel>();
	@Output() onResetOverview = new EventEmitter<void>();

	ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
	draggingEnabled = false;
	readonly?: boolean;

    constructor(private readonly claimService: ClaimService) {}

	async ngOnInit(){
		if(!await this.claimService.hasAny([{ claimType: ClaimTypes.Country, claimValues: [ClaimValues.Update] }])) {
            this.readonly = true;
        } else {
            this.readonly = false;
        }
	}

	drop(event: CdkDragDrop<string[]>): void {
		this.changeSequence.emit({
			id: event.item.data.id,
			newSequenceNumber: event.currentIndex,
		});
	}

	draggingChanged() {
        if(this.draggingEnabled) {
            this.onQueryParamsChange.emit({
                pageIndex: 1,
                pageSize: -1,
                sort: [{key: 'sequenceNumber', value: 'ascend'}],
            });
        }
        else {
            this.onResetOverview.emit();
        }
    }
}