import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NewsChangeSequenceModel, NewsTableItemModel } from '@app/modules/news/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { ClaimService } from '@app/shared/services/claim.service';

@Component({
	selector: 'app-news-overview-table',
	templateUrl: './news-overview-table.component.html',
})
export class NewsOverviewTableComponent implements OnInit{
	@Input({ required: true }) data!: TableStateModel<NewsTableItemModel>;
	

	@Output() delete = new EventEmitter<number>();
	@Output() queryParamsChange = new EventEmitter<NzTableQueryParams>();
	@Output() changeSequence = new EventEmitter<NewsChangeSequenceModel>();
	@Output() resetOverview = new EventEmitter<void>();
	@Output() changeShowOnlineEmitter = new EventEmitter<{
		id: number;
		showOnline: boolean;
	}>();

	ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
	draggingEnabled = false;
	readonly?: boolean;

    constructor(private readonly claimService: ClaimService) {}

	async ngOnInit(){
		if(!await this.claimService.hasAny([{ claimType: ClaimTypes.News, claimValues: [ClaimValues.Update] }])) {
            this.readonly = true;
        } else {
            this.readonly = false;
        }
	}

	drop(event: CdkDragDrop<string[]>): void {
		this.changeSequence.emit({
			id: event.item.data.id,
			newSequenceNumber: event.currentIndex,
		});
	}

	draggingChanged() {
        if(this.draggingEnabled) {
            this.queryParamsChange.emit({
                pageIndex: 1,
                pageSize: -1,
                sort: [{key: 'sequenceNumber', value: "ascend"}],
				filter: []
            });
        }
        else {
            this.resetOverview.emit();
        }
    }

	changeShowOnline(id: number, showOnline: boolean) {
		this.changeShowOnlineEmitter.emit({ id, showOnline });
	}
}