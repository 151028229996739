import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { Claim } from '@app/shared/models/common/claim.model';
import { ClaimService } from '@app/shared/services/claim.service';

@Directive({
  selector: '[claimDirective]',
})
export class ClaimDirective implements OnChanges, AfterViewInit {
  @Input({ required: true }) claims!: Claim[];

  private DISABLED = 'disabled';
  private APP_DISABLED = 'app-disabled';
  private TAB_INDEX = 'tabindex';
  private TAG_ANCHOR = 'a';

  constructor(private eleRef: ElementRef, private renderer: Renderer2, private claimService: ClaimService) { }

  async ngOnChanges() {
    await this.disableElement(this.eleRef.nativeElement);
  }

  async ngAfterViewInit() {
    await this.disableElement(this.eleRef.nativeElement);
  }

  private async disableElement(element: any) {
    if (!(await this.claimService.hasAny(this.claims))) {
      if (!element.hasAttribute(this.DISABLED)) {
        this.renderer.setAttribute(element, this.APP_DISABLED, '');
        this.renderer.setAttribute(element, this.DISABLED, 'true');

        if (element.tagName.toLowerCase() === this.TAG_ANCHOR) {
          this.renderer.setAttribute(element, this.TAB_INDEX, '-1');
        }
      }
    } 
    
    if (element.children) {
      for (let ele of element.children) {
        this.disableElement(ele);
      }
    }
  }
}