import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Logout } from '@app/mobile/modules/auth/actions/mobile-auth.actions';
import * as Auth from '@app/mobile/modules/auth/reducers';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  standalone: true,
  selector: 'app-mobile-topbar',
  templateUrl: './mobile-topbar.component.html',
  styleUrls: ['./mobile-topbar.component.less'],
  imports: [CommonModule, NzButtonModule, TranslateModule],
})
export class MobileTopbarComponent {
  constructor(private readonly store: Store<Auth.State>) {}

  logout(): void {
    this.store.dispatch(Logout());
  }
}
