import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserRoleUpdateModel, UserRoleCreateModel } from '@app/modules/user-roles/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { isFormValid, processBackendValidators } from '@app/shared/utilities/validation.utilities';
import CustomValidators from '@app/shared/validators/custom.validator';

@Component({
	selector: 'app-user-role-detail-form',
	templateUrl: './user-role-detail-form.component.html',
	styleUrls: ['./user-role-detail-form.component.less']
})
export class UserRoleDetailFormComponent implements OnInit, OnChanges{
	update = false;
	readonly?: boolean;

	@Input() loading = false;
	@Input() errors: { [key: string]: string[] } = {};
	@Input({ required: true }) userRole: UserRoleUpdateModel | null = null;

	@Output() submitted = new EventEmitter<UserRoleCreateModel | UserRoleUpdateModel>();

	formGroup = new UntypedFormGroup({
		id: new FormControl<string | null>(null),
		name: new FormControl<string>('', [CustomValidators.required]),
	});
    roleClaims: { type: string; values: { value: string; active: boolean }[] }[] | undefined;
	
	constructor(private readonly claimService: ClaimService) {}

    async ngOnInit() {
        this.update = !!this.userRole?.id;
		
		if(this.update && !await this.claimService.hasAny([{ claimType: ClaimTypes.UserRole, claimValues: [ClaimValues.Update] }])) {
            this.formGroup.disable();
            this.readonly = true;
        } else {
            this.readonly = false;
        }

		this.formGroup.patchValue({
			name: this.userRole?.name,
		});

		if (this.update) {
			this.formGroup.controls['id'].setValidators([CustomValidators.required]);
			this.formGroup.controls['id'].setValue((this.userRole as UserRoleUpdateModel).id);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		processBackendValidators(changes, this.formGroup);

		for (const claim of this.userRole?.roleClaims ?? []) {
			this.formGroup.addControl(`${claim.type}:${claim.value}`, new FormControl<boolean>(claim.active));
		}

        const types = this.userRole?.roleClaims.map((claim) => claim.type).filter((value, index, self) => self.indexOf(value) === index);
        this.roleClaims = types?.map((type) => {
            return {
                type: type,
                values: this.userRole?.roleClaims.filter((claim) => claim.type === type).map((claim) => {
                    return {
                        value: claim.value,
                        active: claim.active,
                    };
                })!,
            };
        });

		for (const claim of this.roleClaims ?? []) {
			const createClaimValue = claim.values.find(x => x.value === ClaimValues.Create);
			const updateClaimValue = claim.values.find(x => x.value === ClaimValues.Update);
			const deleteClaimValue = claim.values.find(x => x.value === ClaimValues.Delete);

			if (createClaimValue?.active || updateClaimValue?.active || deleteClaimValue?.active || claim.type === ClaimTypes.AssetManager) {
				const readControl = this.formGroup.get(`${claim.type}:${ClaimValues.Read}`);
        		readControl?.setValue(true)
				readControl?.disable();
			}
		}
	}

	translationKeyType(type: string): string {
		return `claim-categories.types.${type}`;
	}

	translationKeyValue(value: string): string {
		return `claim-categories.values.${value}`;
	}

	submit() {
		if (isFormValid(this.formGroup)) {
			this.update ? this.submitUpdate() : this.submitCreate();
		}
	}

	private submitCreate() {
		this.submitted.emit({
			name: this.formGroup.controls['name'].value ?? '',
			roleClaims: Object.keys(this.formGroup.controls)
				.filter((key) => key !== 'name' && key !== 'id')
				.map((key) => ({
					type: key.split(':')[0],
					value: key.split(':')[1],
					active: this.formGroup.controls[key].value,
				})),
		});
	}

	private submitUpdate() {
		this.submitted.emit({
			id: this.formGroup.controls['id'].value,
			name: this.formGroup.controls['name'].value ?? '',
			roleClaims: Object.keys(this.formGroup.controls)
				.filter((key) => key !== 'name' && key !== 'id')
				.map((key) => ({
					type: key.split(':')[0],
					value: key.split(':')[1],
					active: this.formGroup.controls[key].value,
				})),
		});
	}

	protected checkClaim(claimIndex: number, claimValueIndex: number) {
		const claim = this.roleClaims![claimIndex];
		const claimValue = claim.values[claimValueIndex];

		if (claimValue.value === ClaimValues.Read || claim.type === ClaimTypes.AssetManager) return;

		const createControl = this.formGroup.get(`${claim.type}:${ClaimValues.Create}`);
		const readControl = this.formGroup.get(`${claim.type}:${ClaimValues.Read}`);
		const updateControl = this.formGroup.get(`${claim.type}:${ClaimValues.Update}`);
		const deleteControl = this.formGroup.get(`${claim.type}:${ClaimValues.Delete}`);
		const shouldHaveReadClaim = createControl?.value || updateControl?.value || deleteControl?.value;

		readControl?.setValue(shouldHaveReadClaim  ? shouldHaveReadClaim : readControl?.value);
		shouldHaveReadClaim ? readControl?.disable() : readControl?.enable();
	}
}
