<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>{{ (!!formGroup.get('id')?.value ? 'faq.detail.edit-title' : 'faq.detail.add-title') | translate }}</h4>
        <nz-form-item>
            <nz-form-label [nzSpan]="24">{{ 'faq.fields.online' | translate }}</nz-form-label>
            <label nz-checkbox [nzValue]="faq?.online" formControlName="online"></label>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="24">{{ 'faq-categories.fields.category' | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="CategoryValidationTemplate">
                <nz-select class="w-100" nzPlaceHolder="{{ 'faq-categories.fields.category' | translate }}"
                    formControlName="faqCategoryId">
                    <nz-option *ngFor="let category of faqCategories" [nzLabel]="category.value"
                        [nzValue]="category.key"></nz-option>
                </nz-select>
                <ng-template #CategoryValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'faqCategoryId'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ 'faq.fields.question' | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="questionValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.question" />

                <ng-template #questionValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'question'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item class="mb-15">
            <nz-form-label [nzSpan]="24" nzRequired>{{ 'faq.fields.answer' | translate }}</nz-form-label>
            <nz-form-control class="column-reverse" [nzSpan]="24">
                <app-input-validation [control]="formGroup.controls.answer" [field]="'answer'"
                    [errors]="errors"></app-input-validation>

                <app-content-block-overview *ngIf="readonly !== undefined" nz-input ngDefaultControl
                    [isReadOnly]="readonly" [availableContentTypes]="availableContentTypes" [errors]="errors"
                    formArrayName="answer"></app-content-block-overview>
            </nz-form-control>
        </nz-form-item>
        <div class="button-container">
            <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
                {{ 'common.save' | translate }}
            </button>
        </div>
    </nz-card>
</form>