import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'blogStatusPipe'})
export class BlogStatusPipe implements PipeTransform{
    transform(uploadDate: Date | null, online: boolean): string {
        if (!online) {
            return 'blog.status.offline';
        } 
        if (uploadDate == null){
            return 'blog.status.draft'
        }
        if (new Date(uploadDate) >= new Date()){
            return 'blog.status.published-on-date'
        } 
        return 'blog.status.published'
    }
}
