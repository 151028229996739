import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { PageOverviewComponent } from "@app/modules/page/pages/overview/page-overview.component";

import { PageDetailComponent } from "@app/modules/page/pages/detail/page-detail.component";
import { PageRoutingModule } from "@app/modules/page/page-routing.module";

import { DragDropModule } from '@angular/cdk/drag-drop'

import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { PageOverviewTableComponent } from "@app/modules/page/components/page-overview-table/page-overview-table.component";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { reducers, pageFeatureKey } from "@app/modules/page/reducers";
import { PageEffects } from "@app/modules/page/effects/page.effects";
import { PageDetailFormComponent } from "@app/modules/page/components/page-detail-form/page-detail-form.component";
import { ChildPagesOverviewTableComponent } from "@app/modules/page/components/child-pages-overview-table/child-pages-overview-table.component";

const COMPONENTS = [
    PageOverviewComponent,
    PageDetailComponent,
    PageOverviewTableComponent,
    PageDetailFormComponent,
    ChildPagesOverviewTableComponent
];

const IMPORTS = [
    SharedModule,
    PageRoutingModule,
    DragDropModule,
    NzTableModule,
    NzPopconfirmModule,
    NzBadgeModule,
    NzUploadModule,
    NzTagModule,
    NzRadioModule,
    NzDatePickerModule,
    NzSelectModule,
    NzDividerModule,
    NzDropDownModule,
    StoreModule.forFeature(pageFeatureKey, reducers),
    EffectsModule.forFeature([PageEffects])
];

@NgModule({
    declarations: [COMPONENTS],
    imports: [IMPORTS],
    providers: [],
})

export class PageModule { }
