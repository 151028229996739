<h1>Demo overview</h1>
<div nz-row>
    <div nz-col nzFlex="auto">
        <nz-card>
            Test
        </nz-card>
    </div>
    <div nz-col nzSpan="6">
        <nz-card>
            Test
        </nz-card>
    </div>

    <div nz-col nzSpan="24">
        <nz-card>
            Test
        </nz-card>
    </div>
</div>

<nz-card class="mt-lg">
    <section class="section demo-color">
        <h2>Colors</h2>
        <div class="color-blocks">
            <div class="color-block color-block-1"></div>
            <div class="color-block color-block-2"></div>
            <div class="color-block color-block-3"></div>
            <div class="color-block color-block-4"></div>
            <div class="color-block color-block-5"></div>
            <div class="color-block color-block-6"></div>
            <div class="color-block color-block-7"></div>
            <div class="color-block color-block-8"></div>
            <div class="color-block color-block-9"></div>
            <div class="color-block color-block-10"></div>
        </div>

        <div class="color-blocks">
            <div class="color-block color-block-hover"></div>
            <div class="color-block color-block-active"></div>
            <div class="color-block color-block-outline"></div>
        </div>
    </section>

    <section class="section demo-grid">
        <h2>Grid</h2>
        <nz-alert class="mb-sm" nzType="info" nzMessage="24 columns" [nzDescription]="templateGridInfo" nzShowIcon></nz-alert>
        <div nz-row nzSpan="24">
            <div nz-col nzSpan="24">
                <div class="demo-col-inner">col-24</div>
            </div>
        </div>
        <div nz-row class="mt-md">
            <div nz-col nzSpan="12">
                <div class="demo-col-inner">col-12</div>
            </div>
            <div nz-col nzSpan="12">
                <div class="demo-col-inner">col-12</div>
            </div>
        </div>
        <div nz-row class="mt-md">
            <div nz-col nzSpan="8">
                <div class="demo-col-inner">col-8</div>
            </div>
            <div nz-col nzSpan="8">
                <div class="demo-col-inner">col-8</div>
            </div>
            <div nz-col nzSpan="8">
                <div class="demo-col-inner">col-8</div>
            </div>
        </div>
        <div nz-row class="mt-md">
            <div nz-col nzSpan="6">
                <div class="demo-col-inner">col-6</div>
            </div>
            <div nz-col nzSpan="6">
                <div class="demo-col-inner">col-6</div>
            </div>
            <div nz-col nzSpan="6">
                <div class="demo-col-inner">col-6</div>
            </div>
            <div nz-col nzSpan="6">
                <div class="demo-col-inner">col-6</div>
            </div>
        </div>
    </section>

    <section class="section">
        <h2>Spacing utility classes</h2>
        <p>
            We have a few spacing utility classes, these are not from NG-Zorro, but custom created based on the spacings
            we set up.<br />
            They work like <a href="https://getbootstrap.com/docs/5.3/utilities/spacing/#margin-and-padding"
                target="_blank">bootstrap spacing classes</a>.
        </p>
        <p>Minor difference is that we do not work with 0 till 5 but from <span nz-typography><code>xss</code></span>
            till <span nz-typography><code>xxl</code></span></p>

        <nz-divider nzText="Alternatively" nzOrientation="left"></nz-divider>
        <p>You could also use the NG-Zorro <a href="https://ng.ant.design/components/space/en#ng-content"
                target="_blank">space component</a></p>
    </section>

    <section class="section">
        <h2>Alerts</h2>
        <nz-alert class="mb-sm" nzType="info" nzMessage="Info Text" nzShowIcon></nz-alert>
        <nz-alert class="mb-sm" nzType="success" nzMessage="Success Text" nzShowIcon></nz-alert>
        <nz-alert class="mb-sm" nzType="warning" nzMessage="Warning Text" nzShowIcon></nz-alert>
        <nz-alert class="mb-sm" nzType="error" nzMessage="Error Text" nzShowIcon></nz-alert>
    </section>

    <section class="section">
        <h2>Table</h2>
        <nz-table #demoTable [nzData]="tableData.list" nzTableLayout="fixed">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Date</th>
                    <th>Age</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of demoTable.data">
                    <td>{{ data.name }}</td>
                    <td>{{ data.address }}</td>
                    <td>{{ data.date | date }}</td>
                    <td>{{ data.age }}</td>
                    <td>
                        <div class="table-actions">
                            <button nzDanger nz-popconfirm nz-button nzOkDanger nzType="text" nzShape="circle"
                                nzPopconfirmTitle="Are you sure you want to delete this?" nzOkText="Delete"
                                [nzIcon]="templateIconDelete" nzPopconfirmPlacement="left"
                                (nzOnConfirm)="onDeleteConfirm(data.name)">
                                <i class="fa-regular fa-trash-can"></i>
                            </button>
                            <button nz-button nzType="text" nzShape="circle" nz-dropdown [nzDropdownMenu]="menu"
                                nzTrigger="click"><i class="fa-regular fa-ellipsis"></i></button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </section>

    <section class="section">
        <h2>Upload</h2>
        <nz-upload nzType="drag" [nzMultiple]="false" nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76" (nzChange)="uploadHandle($event)" >
            <i class="fa-regular fa-upload"></i> Upload
        </nz-upload>
    </section>
</nz-card>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
        <li nz-menu-item>1st menu item</li>
        <li nz-menu-item>2nd menu item</li>
        <li nz-menu-item>3rd menu item</li>
        <li nz-menu-item nzDanger>4th danger item</li>
    </ul>
</nz-dropdown-menu>

<ng-template #templateGridInfo>
    <p>NG-Zorro's grid is based on a 24 column based grid system. For more information see the <a
            href="https://ng.ant.design/components/grid/en#ng-content" target="_blank">documentation</a></p>
</ng-template>

<ng-template #templateIconDelete>
    <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>