import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as MaterialActions from '@app/modules/materials/actions/material-api.actions';
import * as Material from '@app/modules/materials/reducers';
import { MaterialService } from '@app/modules/materials/services/material.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class MaterialEffects {
  constructor(
    private actions$: Actions,
    private materialService: MaterialService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<Material.State>
  ) {}

  /**
   * Get effects
   */
  loadMaterials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(Material.selectMaterialOverviewPagination),
        this.store.select(Material.selectMaterialOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.materialService.getAll(sorting, pagination).pipe(
          map((response) => MaterialActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(MaterialActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadMaterialsFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.FilterOverview),
      map(() => MaterialActions.GetOverview())
    )
  );

  loadMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.Get),
      exhaustMap((action) =>
        this.materialService.get(action.id).pipe(
          map((response) => MaterialActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(MaterialActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.Create),
      exhaustMap((action) =>
        this.materialService.create(action).pipe(
          map((material) => MaterialActions.CreateSuccess(material)),
          catchError((response: { error: ErrorResponse }) =>
            of(MaterialActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createMaterialSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.CreateSuccess),
        tap((material) =>
          this.translate
            .get('notification.add', { item: material.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.Update),
      exhaustMap((action) =>
        this.materialService.update(action).pipe(
          map((material) => MaterialActions.UpdateSuccess(material)),
          catchError((response: { error: ErrorResponse }) =>
            of(MaterialActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateMaterialSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.UpdateSuccess),
        tap((material) =>
          this.translate
            .get('notification.save', { item: material.name })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateMaterialSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.CreateSuccess, MaterialActions.UpdateSuccess),
        tap(() => this.router.navigate(['materialen']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.Delete),
      exhaustMap((action) =>
        this.materialService.delete(action.id).pipe(
          map(() => MaterialActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(MaterialActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteMaterialSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.DeleteSuccess),
      map(() => MaterialActions.GetOverview())
    )
  );

  deleteMaterialSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * SetQuotation effects
   */
  setQuotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.UpdateQuotation),
      exhaustMap((action) =>
        this.materialService.quotation(action.id, action.inQuotation).pipe(
          map((quotation) => MaterialActions.UpdateQuotationSuccess(quotation)),
          catchError((response: { error: ErrorResponse }) =>
            of(
              MaterialActions.UpdateQuotationError({ response: response.error })
            )
          )
        )
      )
    )
  );

  setQuotationSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.UpdateQuotationSuccess),
      map(() => MaterialActions.GetOverview())
    )
  );

  setQuotationSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MaterialActions.UpdateQuotationSuccess),
        tap(() =>
          this.translate
            .get('materials.notification.save')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  materialErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MaterialActions.GetOverviewError,
          MaterialActions.DeleteError,
          MaterialActions.UpdateQuotationError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
