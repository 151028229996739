import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as ActivityApiActions from '@app/modules/activity/actions/activity-api.actions';
import { ActivityTemplateModalComponent } from '@app/modules/activity/components/activity-template-modal/activity-template-modal.component';
import * as Activity from '@app/modules/activity/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { Store } from '@ngrx/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
    selector: 'app-activity-overview',
    templateUrl: './activity-overview.component.html',
    styleUrls: ['./activity-overview.component.less'],
})
export class ActivityOverviewComponent implements OnInit {
    tableData$ = this.store.select(Activity.selectActivityOverviewState);
    templateData$ = this.store.select(Activity.selectActivityTemplates);
    activityTypes$ = this.store.select(Activity.selectActivityTemplateActivityTypes);
    selectedActityTypeId$ = this.store.select(Activity.selectActivityOverviewActivityTypeId);

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    sort: TableSortingModel | undefined;
    destroyRef = inject(DestroyRef);

    initalSort = true

    constructor(private readonly store: Store, private readonly modalService: NzModalService) {}

    onDeleteConfirm(id: number): void {
        this.store.dispatch(ActivityApiActions.Delete({ id }));
    }

    ngOnInit(): void {
        this.store.dispatch(ActivityApiActions.GetAllActivityTemplates());
        this.store.dispatch(ActivityApiActions.GetActivityTypes());
        this.store
            .select(Activity.selectActivityOverviewSorting)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((sort) => {
                this.sort = sort;
            });
    }

    /**
     * Trigger the loading of data whenever the table changes.
     * The default pageSize, index and sort options are based on the TableStateModel.
     * @param params
     */
    onQueryParamsChange(params: NzTableQueryParams) {
        const thenBy = this.initalSort ? [{propertyName: "StartDate", order: "DESC"}] as TableSortingModel[] : undefined
        this.initalSort = false

        const { pageSize, pageIndex, sort } = params;
        const currentSort = sort.find((item) => item.value !== null);
        const sortField = currentSort?.key ?? null;
        const sortOrder = currentSort?.value ?? null;

        this.store.dispatch(
            ActivityApiActions.FilterOverview({
                pagination: {
                    page: pageIndex,
                    pageSize,
                },
                sorting: {
                    propertyName: sortField ?? '',
                    order: sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
                },
                thenBy
            }),
        );
    }

    onResetOverview() {
        this.store.dispatch(ActivityApiActions.ResetOverview());
    }

    createActivity() {
        this.modalService.create({
            nzContent: ActivityTemplateModalComponent,
            nzData: { templateNames: this.templateData$ },
        });
    }

    onCategoryChange(activityTypeId: number) {
        this.store.dispatch(ActivityApiActions.SetActiveActivityTypeId({ activityTypeId }));
        this.dispatchOverview();
    }

    onChangeOnline(event: any) {
        this.store.dispatch(
            ActivityApiActions.UpdateShowOnline({
                id: event.id,
                online: event.online,
            }),
        );
    }

    onShowOldActivities(event: any) {
        this.store.dispatch(ActivityApiActions.DisplayOldActivities({ displayOld: event }));
        this.dispatchOverview();
    }

    private dispatchOverview() {
        this.store.dispatch(
            ActivityApiActions.FilterOverview({
                pagination: { page: 1, pageSize: 10 },
                sorting: this.sort,
            }),
        );
    }
}
