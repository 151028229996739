import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import { ActivityService } from '@app/modules/activity/services/activity.service';
import { ActivityTypeService } from '@app/modules/activity-type/services/activity-type.service';
import * as DiscountCodeActions from '@app/modules/discount-codes/actions/discount-code-api.actions';
import * as DiscountCode from '@app/modules/discount-codes/reducers';
import { DiscountCodeService } from '@app/modules/discount-codes/services/discount-code.service';
import { RelationService } from '@app/modules/relation/service/relation.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class DiscountCodeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly discountCodeService: DiscountCodeService,
    private readonly activityService: ActivityService,
    private readonly activityTypeService: ActivityTypeService,
    private readonly relationService: RelationService,
    private readonly messageService: NzMessageService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly store: Store<DiscountCode.State>
  ) {}

  /**
   * Get effects
   */
  loadDiscountCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(DiscountCode.selectDiscountCodeOverviewPagination),
        this.store.select(DiscountCode.selectDiscountCodeOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.discountCodeService.getAll(sorting, pagination).pipe(
          map((response) => DiscountCodeActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(
              DiscountCodeActions.GetOverviewError({ response: response.error })
            )
          )
        )
      )
    )
  );

  loadDiscountCodesFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.FilterOverview),
      map(() => DiscountCodeActions.GetOverview())
    )
  );

  loadDiscountCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.Get),
      exhaustMap((action) =>
        this.discountCodeService.get(action.id).pipe(
          map((response) => DiscountCodeActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(DiscountCodeActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createDiscountCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.Create),
      exhaustMap((action) =>
        this.discountCodeService.create(action).pipe(
          map((discountCode) =>
            DiscountCodeActions.CreateSuccess(discountCode)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(DiscountCodeActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createDiscountCodeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiscountCodeActions.CreateSuccess),
        tap((discountCode) =>
          this.translate
            .get('notification.add', { item: discountCode.code })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateDiscountCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.Update),
      exhaustMap((action) =>
        this.discountCodeService.update(action).pipe(
          map((discountCode) =>
            DiscountCodeActions.UpdateSuccess(discountCode)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(DiscountCodeActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateDiscountCodeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiscountCodeActions.UpdateSuccess),
        tap((discountCode) =>
          this.translate
            .get('notification.save', { item: discountCode.code })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateDiscountCodeSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DiscountCodeActions.CreateSuccess,
          DiscountCodeActions.UpdateSuccess
        ),
        tap(() => this.router.navigate(['kortingscodes']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteDiscountCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.Delete),
      exhaustMap((action) =>
        this.discountCodeService.delete(action.id).pipe(
          map(() => DiscountCodeActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(DiscountCodeActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteDiscountCodeSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.DeleteSuccess),
      map(() => DiscountCodeActions.GetOverview())
    )
  );

  deleteDiscountCodeSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiscountCodeActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change active effects
   */
  changeActiveDiscountCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.ChangeActive),
      exhaustMap((action) =>
        this.discountCodeService.changeActive(action.id, action.active).pipe(
          map((discountCode) =>
            DiscountCodeActions.ChangeActiveSuccess(discountCode)
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              DiscountCodeActions.ChangeActiveError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  changeActiveSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiscountCodeActions.ChangeActiveSuccess),
        tap((discountCode) =>
          this.translate
            .get('notification.save', { item: discountCode.code })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  changeActiveSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.ChangeActiveSuccess),
      map(() => DiscountCodeActions.GetOverview())
    )
  );

  /**
   * Get activities effects
   */
  GetActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.GetActivities),
      exhaustMap(() =>
        this.activityService.getAll().pipe(
          map((activities) =>
            DiscountCodeActions.GetActivitiesSuccess({ activities })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              DiscountCodeActions.GetActivitiesError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  /**
   * Get activity types effects
   */
  GetActivityTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.GetActivityTypes),
      exhaustMap(() =>
        this.activityTypeService.getAll().pipe(
          map((activityTypes) =>
            DiscountCodeActions.GetActivityTypesSuccess({ activityTypes })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              DiscountCodeActions.GetActivityTypesError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  /**
   * Get relations effects
   */
  GetRelations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiscountCodeActions.GetRelations),
      exhaustMap(() =>
        this.relationService.getAll().pipe(
          map((relations) =>
            DiscountCodeActions.GetRelationsSuccess({ relations })
          ),
          catchError((response: { error: ErrorResponse }) =>
            of(
              DiscountCodeActions.GetRelationsError({
                response: response.error,
              })
            )
          )
        )
      )
    )
  );

  discountCodeErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DiscountCodeActions.GetOverviewError,
          DiscountCodeActions.ChangeActiveError,
          DiscountCodeActions.DeleteError,
          DiscountCodeActions.GetActivityTypesError,
          DiscountCodeActions.GetRelationsError,
          DiscountCodeActions.ChangeActiveError
        ),
        tap((error) =>
          this.messageService.error(
            BuildErrorString(error.response, this.translate)
          )
        )
      ),
    { dispatch: false }
  );
}
