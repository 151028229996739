<nz-table
    #overviewTable
    [nzData]="data.list"
    [nzLoading]="data.loading"
    [nzFrontPagination]="false"
    nzTableLayout="fixed">
    <thead>
        <tr>
            <th>
                {{ 'activity-relation.fields.name' | translate }}
            </th>
            <th>
                {{ 'activity-relation.fields.email' | translate }}
            </th>
            <th>
                {{ 'activity-relation.fields.kind' | translate }}
            </th>
            <th>
                {{ 'activity-relation.fields.status' | translate }}
            </th>
            <th>
                {{ 'activity-relation.fields.payment' | translate }}
            </th>
            <th
                nzWidth="120px"
                claimDirective
                [claims]="[
                    {
                        claimType: ClaimTypes.Activity,
                        claimValues: [ClaimValues.Read, ClaimValues.Delete]
                    }
                ]"></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let row of data.list; let i = index">
            <tr cdkDrag [cdkDragData]="row" [cdkDragDisabled]="!draggingEnabled">
                <td>
                    <a
                        [routerLink]="['/relaties/detail', row.relationId]"
                        target="_blank"
                        *ngIf="participantActivityStatus.Reservation !== row.participantStatus">
                        {{ row.index }} {{ row.fullName | truncateText }}
                    </a>
                    <span *ngIf="participantActivityStatus.Reservation === row.participantStatus">
                        {{ row.index }} {{ participantStatusMapping[row.participantStatus] | translate }}
                    </span>
                </td>
                <td>
                    <a [href]="'mailto:' + row.email" *ngIf="row.email">
                        <i class="fa-regular fa-envelope"></i>
                        &nbsp;{{ row.email | truncateText }}
                    </a>
                </td>
                <td>{{ ActivityAvailableParticipantsMapping[row.role] | translate }}</td>
                <td>
                    {{
                        participantActivityStatus.Reservation !== row.participantStatus
                            ? (participantStatusMapping[row.participantStatus] | translate)
                            : ''
                    }}
                </td>
                <td>
                    <i class="{{ paymentStatusMapping[row.paymentStatus] }}"></i>
                    {{ row.invoiceNumber }}
                </td>
                <td>
                    <div class="table-actions">
                        <span *ngIf="waitlist">
                            <i class="fa-solid fa-flag"></i>
                            <i class="fa-regular fa-flag"></i>
                        </span>
                        <button
                            claimDirective
                            [claims]="[
                                {
                                    claimType: ClaimTypes.Activity,
                                    claimValues: [ClaimValues.Read]
                                }
                            ]"
                            nz-button
                            [routerLink]=""
                            nzType="text"
                            (click)="this.getRelation.emit(row.id)"
                            (keyup)="this.getRelation.emit(row.id)"
                            nzShape="circle">
                            <i
                                *ngIf="participantActivityStatus.Reservation !== row.participantStatus"
                                class="fa-regular fa-pen"></i>
                            <i
                                *ngIf="participantActivityStatus.Reservation === row.participantStatus"
                                class="fa-solid fa-arrow-right-arrow-left"></i>
                        </button>
                        <button
                            claimDirective
                            [claims]="[
                                {
                                    claimType: ClaimTypes.Activity,
                                    claimValues: [ClaimValues.Delete]
                                }
                            ]"
                            nz-button
                            nzDanger
                            nzType="text"
                            nzShape="circle"
                            nz-popconfirm
                            nzOkDanger
                            [nzPopconfirmTitle]="'notification.delete' | translate"
                            [nzOkText]="'common.delete' | translate"
                            [nzCancelText]="'common.cancel' | translate"
                            [nzIcon]="templateIconDelete"
                            nzPopconfirmPlacement="left"
                            (nzOnConfirm)="
                                delete.emit({
                                    id: row.id,
                                    isReservation: participantActivityStatus.Reservation === row.participantStatus
                                })
                            ">
                            <i class="fa-regular fa-trash-can"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </ng-container>
    </tbody>
</nz-table>
<ng-template #templateIconDelete>
    <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
