<form nz-form [formGroup]="formGroup">
	<nz-card>
		<h4>
			{{
				(!!formGroup.get('id')?.value ? 'discount-code.detail.edit-title' : 'discount-code.detail.add-title')
					| translate
			}}
		</h4>

		<app-select-single
			[placeholder]="'discount-code.fields.relation-name' | translate"
			[control]="formGroup.controls.relationdId"
			[loading]="loading"
			[items]="relations"
			[allowClear]="true"
			field="relationId"
			[errors]="errors"
			label="{{ 'discount-code.fields.relation-name' | translate }}"
			[required]="false">
		</app-select-single>

		<nz-form-item class="form-checkbox">
			<nz-form-label [nzSpan]="24">{{ 'discount-code.fields.active' | translate }}</nz-form-label>
			<label nz-checkbox [nzValue]="discountCode?.active" formControlName="active"></label>
			<span class="ant-form-item-extra" *ngIf="discountCode?.active">{{ 'discount-code.fields.active-info' | translate }}</span>
		</nz-form-item>

		<nz-form-item>
			<nz-form-label [nzSpan]="24" nzRequired>{{ 'discount-code.fields.discount-usage' | translate }}</nz-form-label>
			<nz-select
				style="width: 100%"
				[formControl]="formGroup.controls.discountUsage"
				nzPlaceHolder="{{ 'discount-code.fields.discount-usage' | translate }}">
				<nz-option
					*ngFor="let discountUsage of DiscountUsage | enumKeyValue"
					[nzLabel]="'discount-code.enums.usage.' + discountUsage.key.toLowerCase() | translate"
					[nzValue]="discountUsage.value"></nz-option>
			</nz-select>
		</nz-form-item>

		<nz-form-item>
			<nz-form-label [nzSpan]="24" nzRequired>{{ 'discount-code.fields.discount-type' | translate }}</nz-form-label>
			<nz-select
				style="width: 100%"
				[formControl]="formGroup.controls.discountType"
				nzPlaceHolder="{{ 'discount-code.fields.discount-type' | translate }}">
				<nz-option
					*ngFor="let discountType of DiscountType | enumKeyValue"
					[nzLabel]="'discount-code.enums.type.' + discountType.key.toLowerCase() | translate"
					[nzValue]="discountType.value"></nz-option>
			</nz-select>
		</nz-form-item>

		<currency-input
			*ngIf="formGroup.controls.discountType.value === DiscountType.Amount"
			[(control)]="formGroup.controls.discountAmount"
			[errors]="errors"
			[fieldName]="'discountAmount'"
			[title]="'discount-code.fields.discount-amount' | translate"
			class="ant-form-item" />

		<nz-form-item *ngIf="formGroup.controls.discountType.value === DiscountType.Percentage">
			<nz-form-label [nzSpan]="24" nzRequired>{{
				'discount-code.fields.discount-percentage' | translate
			}}</nz-form-label>
			<nz-form-control [nzSpan]="24" [nzErrorTip]="discountPercentageValidationTemplate">
				<input type="number" nz-input [formControl]="formGroup.controls.discountPercentage" />

				<ng-template #discountPercentageValidationTemplate let-control>
					<app-input-validation
						[control]="control"
						[field]="'discountPercentage'"
						[errors]="errors"></app-input-validation>
				</ng-template>
			</nz-form-control>
		</nz-form-item>

		<nz-form-item>
			<nz-form-label [nzSpan]="24" nzRequired>{{ 'discount-code.fields.code' | translate }}</nz-form-label>
			<nz-form-control [nzSpan]="24" [nzErrorTip]="codeValidationTemplate" [nzExtra]="codeGenerationTemplate">
				<input nz-input [formControl]="formGroup.controls.code" />

				<ng-template #codeGenerationTemplate let-control>
					<p>{{ 'discount-code.fields.code-generate-info' | translate }}</p>
					<a *ngIf="!this.readonly" (click)="generateDiscountCode()">{{ 'discount-code.fields.code-generate' | translate }}</a>
				</ng-template>
				<ng-template #codeValidationTemplate let-control>
					<app-input-validation [control]="control" [field]="'code'" [errors]="errors"></app-input-validation>
				</ng-template>
			</nz-form-control>
		</nz-form-item>

		<nz-form-item>
			<nz-form-label [nzSpan]="24" nzRequired>{{ 'discount-code.fields.valid-from' | translate }}</nz-form-label>
			<nz-form-control [nzSpan]="24" [nzErrorTip]="startDateValidationTemplate">
				<nz-date-picker [formControl]="formGroup!.controls.startDate" [nzFormat]="dateFormat"></nz-date-picker>

				<ng-template #startDateValidationTemplate let-control>
					<app-input-validation [control]="control" [field]="'startDate'" [errors]="errors"></app-input-validation>
				</ng-template>
			</nz-form-control>
		</nz-form-item>

		<nz-form-item>
			<nz-form-label [nzSpan]="24" nzRequired>{{ 'discount-code.fields.valid-to' | translate }}</nz-form-label>
			<nz-form-control [nzSpan]="24" [nzErrorTip]="endDateValidationTemplate">
				<nz-date-picker [formControl]="formGroup!.controls.endDate" [nzFormat]="dateFormat"></nz-date-picker>

				<ng-template #endDateValidationTemplate let-control>
					<app-input-validation [control]="control" [field]="'endDate'" [errors]="errors"></app-input-validation>
				</ng-template>
			</nz-form-control>
		</nz-form-item>

		<app-select-single
			[disabled]="formGroup.controls.activityTypeId.value !== null"
			[placeholder]="'discount-code.fields.activity-name' | translate"
			[control]="formGroup.controls.activityId"
			[loading]="loading"
			[items]="activities"
			[allowClear]="true"
			label="{{ 'discount-code.fields.activity-name' | translate }}"
			[errors]="errors"
			field="activityId"
			[required]="false">
		</app-select-single>

		<app-select-single
			[disabled]="formGroup.controls.activityId.value !== null"
			[placeholder]="'discount-code.fields.activity-type-name' | translate"
			[control]="formGroup.controls.activityTypeId"
			[loading]="loading"
			[items]="activityTypes"
			[allowClear]="true"
			label="{{ 'discount-code.fields.activity-type-name' | translate }}"
			[errors]="errors"
			field="activityTypeId"
			[required]="false">
		</app-select-single>

		<div class="button-container">
			<button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
				{{ 'common.save' | translate }}
			</button>
		</div>	
	</nz-card>
</form>
