import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRoleTableItemModel } from '@app/modules/user-roles/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
	selector: 'app-user-role-overview-table',
	templateUrl: './user-role-overview-table.component.html',
})
export class UserRoleOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<UserRoleTableItemModel>;

	@Output() delete = new EventEmitter<string>();
	@Output() onQueryParamsChange = new EventEmitter<any>();

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

    getTranslationKey(count: number): string {
        const baseKey = "user-roles.notification.delete-"
        switch (count) {
            case 0:
                return baseKey + 'none';
            case 1:
                return baseKey + 'singular';
            default:
                return baseKey + 'plural';
        }
    }
}