import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RequestOptions } from '@app/core/models/request-options.model';
import { CustomRequestOptions } from '@app/core/services/http/custom-request.options';
import { TokenService } from '@app/core/services/token/token.service';
import * as AuthActions from '@app/modules/auth/actions/auth.actions';
import { Store } from '@ngrx/store';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, Observable, firstValueFrom, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class HttpService extends HttpClient {
	private readonly refreshTokenEndpoint: string = 'backofficeuser/refresh';
	private tokenRefreshPromise: null | Promise<Object | undefined> = null;

	private xhrCreations = 0;
	private xhrResolutions = 0;
	loadingState = new BehaviorSubject<boolean>(false);

	/**
	 * Initializes a new instance of the HttpService class.
	 * @param {handler} HttpHandler The HttpHandler.
	 * @param {CustomRequestOptions} defaultOptions The default request options.
	 * @param {TokenService} tokenService The token service.
	 */
	constructor(
		handler: HttpHandler,
		private readonly defaultOptions: CustomRequestOptions,
		private readonly tokenService: TokenService,
		private readonly store: Store,
		private readonly router: Router
	) {
		super(handler);
	}

	/**
	 * Performs a request with `get` http method.
	 * @param {string} url The url.
	 * @param {RequestOptions|undefined} options The request options.
	 */
	override get<T>(url: string, options: any = {}): Observable<T> {
		this.onStart();
        const fullUrl = this.getFullUrl(url);

		return this.executeRequest<T>(fullUrl, () => {
			return this.pipeResponse<T>(super.get<T>(fullUrl, this.getRequestOptions(options)));
		});
	}

	/**
	 * Performs a request with `put` http method.
	 * @param {string} url The url.
	 * @param {RequestOptions|undefined} options The request options.
	 */
	override put<T>(url: string, body: any): Observable<T> {
		this.onStart();
        const fullUrl = this.getFullUrl(url);

		return this.executeRequest<T>(fullUrl, () => {
			return this.pipeResponse<T>(super.put<T>(fullUrl, this.serialize(body), this.getRequestOptions()));
		});
	}

	/**
	 * Performs a request with `post` http method.
	 * @param {string} url The url.
	 * @param {RequestOptions|undefined} options The request options.
	 */
	override post<T>(url: string, body: any = {}): Observable<T> {
		this.onStart();

        const fullUrl = this.getFullUrl(url);

		return this.executeRequest<T>(fullUrl, () => {
			return this.pipeResponse<T>(super.post<T>(fullUrl, this.serialize(body), this.getRequestOptions()));
		});
	}

	/**
	 * Posts a file.
	 * @param {string} url The url.
	 * @param {FormData} body The body.
	 */
	postFile<T>(url: string, body: FormData): Observable<T> {
        const fullUrl = this.getFullUrl(url);

		return this.executeRequest<T>(fullUrl, () => this.pipeResponse<T>(super.post<T>(fullUrl, body)));
	}

	/**
	 * Puts a file.
	 * @param {string} url The url.
	 * @param {FormData} body The body.
	 */
	putFile<T>(url: string, body: FormData): Observable<T> {
        const fullUrl = this.getFullUrl(url);

		return this.executeRequest<T>(fullUrl, () => this.pipeResponse<T>(super.post<T>(fullUrl, body)));
	}

	/**
	 * Get the full url.
	 * @param {string} url The request url.
	 */
	getFullUrl(url: string): string {
		if (url.startsWith('http')) {
			return url;
		}

		if (url.startsWith('/assets')) {
			return `${environment.backoffice.baseUrl}${url}`;
		}

		const separator = !environment.api.baseUrl.endsWith('/') ? '/' : '';

		return `${environment.api.baseUrl}${separator}${url}`;
	}

	/**
	 * Performs a request with `delete` http method.
	 * @param {string} url The url.
	 * @param {RequestOptions|undefined} options The request options.
	 */
	override delete<T>(url: string): Observable<T> {
		this.onStart();

        const fullUrl = this.getFullUrl(url);

		return this.executeRequest<T>(fullUrl, () =>
			this.pipeResponse<T>(super.delete<T>(fullUrl, this.getRequestOptions()))
		);
	}

	/**
	 * Applies the onSuccess, onError and onComplete methods to the response.
	 * @param {Observable<T>} response The response.
	 */
	private pipeResponse<T>(response: Observable<T>): Observable<T> {
		return response.pipe(
			catchError(this.onCatch),
			tap(
				() => this.onSuccess(),
				(error: any) => {
					const routePreFix = this.tokenService.getMobileLogin() ? '/mobiel' : '';
					if (error.status === 401) { this.store.dispatch(AuthActions.Logout()); }
					if (error.status === 403) { this.router.navigate([`${routePreFix}/forbidden`]); }
					if (error.status === 500) { this.router.navigate([`${routePreFix}/internal-server-error`]); }
					return this.onError(error);
				}
			),
			finalize(() => this.onComplete())
		);
	}

	/**
	 * Executes the request.
	 * @param {Observable<Response>} request The request.
	 */
	private executeRequest<T>(url: string, request: () => Observable<T>): Observable<T> {
		if (!this.isLoginMethod(url) && this.tokenService.hasToken() && this.tokenService.shouldRefreshToken()) {
			return this.requestRefreshToken().pipe(
				tap((response: any) => {
					this.tokenService.setToken(response);
				}),
				mergeMap(() => request()),
				finalize(() => (this.tokenRefreshPromise = null))
			);
		}
		return request().pipe(map((response) => response));
	}

	private requestRefreshToken(): Observable<Object | undefined> {
		if (this.tokenRefreshPromise == null) {
			this.tokenRefreshPromise = firstValueFrom(
				this.pipeResponse(
					super
						.post(
							this.getFullUrl(this.refreshTokenEndpoint),
							{ refreshToken: this.tokenService.getToken().refreshToken },
							this.getRequestOptions()
						)
						.pipe(
							map((response) => {
								return response;
							}),
							catchError(() => {
								this.store.dispatch(AuthActions.Logout());
								return throwError(() => 'Token expired');
							})
						)
				)
			);
		}

		return of(this.tokenRefreshPromise).pipe(
			mergeMap((promise) => {
				return promise.then((value) => value);
			})
		);
	}

    private isLoginMethod(url: string): boolean
    {
        return url.toLowerCase().includes('backofficeuser/login');
    }

	/**
	 * Gets the request options.
	 * @param {RequestOptions|undefined} options The request options.
	 */
	private getRequestOptions(options: any = {}): RequestOptions {
		return {
			...this.setAuthorizationHeader(this.defaultOptions),
			...options,
		};
	}

	/**
	 * Sets the authorization header.
	 * @param {RequestOptions} options The request options.
	 */
	private setAuthorizationHeader(options: RequestOptions): RequestOptions {
		return {
			...options,
			...{
				headers: {
					...options.headers,
					...{
						Authorization: `Bearer ${this.tokenService.getToken()?.accessToken}`,
					},
				},
			},
		};
	}

	/**
	 * Perform actions when the request is successful.
	 * @param {Response} response The response.
	 */
	private onSuccess(): any {}

	/**
	 * Perform actions when the request has errored.
	 * @param {Response} response The error response.
	 */
	private onError(response: Response): void {
		console.error('Request error => ', response);
	}

	/**
	 * Perform actions when the request has errored.
	 * @param {any} error The error.
	 * @param {Observable<any>} caught The error observable.
	 */
	private onCatch(error: any): Observable<any> {
		return throwError(() => error);
	}

	/**
	 * Perform actions when the request is completed.
	 */
	private onComplete(): void {
		this.onEnd();
	}

	/**
	 * Serialize the provided data.
	 * @param {any} data The provided data.
	 */
	private serialize(data: any): string {
		return JSON.stringify(data);
	}

	/**
	 * Updates the loading observable before a request.
	 */
	private onStart(): void {
		this.xhrCreations++;
		this.updateLoadingState();
	}

	/**
	 * Updates the loading observable after a request.
	 */
	private onEnd(): void {
		this.xhrResolutions++;
		this.updateLoadingState();
	}

	/**
	 * Update loading state.
	 */
	private updateLoadingState(): void {
		this.loadingState.next(this.isLoading());
	}

	/**
	 * Whether one or more xhr requests are active.
	 * @returns {boolean} true if one or more xhr requests are active.
	 */
	private isLoading(): boolean {
		return this.xhrResolutions < this.xhrCreations;
	}
}
