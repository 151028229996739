import * as ClaimCategoryActions from '@app/modules/claim-categories/actions/claim-category-api.actions';
import { ClaimCategoryUpdateModel } from '@app/modules/claim-categories/models';

import { createReducer, on } from '@ngrx/store';

export const initialState: State = {
	claimCategory: null,
	loading: false,
	errors: {},
};

export interface State {
	claimCategory: ClaimCategoryUpdateModel | null;
	loading: boolean;
	errors: { [key: string]: string[] };
}

export const reducer = createReducer(
	initialState,
	on(ClaimCategoryActions.Clear, () => ({ ...initialState })),

	on(ClaimCategoryActions.Get, (state) => ({ ...state, loading: true })),
	on(ClaimCategoryActions.GetSuccess, (state, { claimCategory }) => ({ ...state, claimCategory, loading: false })),
	on(ClaimCategoryActions.GetError, (state, { response }) => ({
		...state,
		loading: false,
		errors: response.errors || {},
	})),

	on(ClaimCategoryActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
	on(ClaimCategoryActions.UpdateSuccess, (state, { claimCategory }) => ({ ...state, claimCategory, loading: false })),
	on(ClaimCategoryActions.UpdateError, (state, { response }) => ({
		...state,
		loading: false,
		errors: response.errors || {},
	}))
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getClaimCategory = (state: State) => state.claimCategory;
