import { Component } from '@angular/core';
import * as Material from '@app/modules/materials/reducers';
import { Store } from '@ngrx/store';
import * as MaterialApiActions from '@app/modules/materials/actions/material-api.actions';
import { State } from '@app/reducers';
import {
  MaterialCreateModel,
  MaterialUpdateModel,
} from '@app/modules/materials/models';

@Component({
  selector: 'app-material-detail',
  templateUrl: './material-detail.component.html',
  styleUrls: ['./material-detail.component.less'],
})
export class MaterialDetailComponent {
  loading$ = this.store.select(Material.selectDetailLoading);
  errors$ = this.store.select(Material.selectDetailErrors);
  Material$ = this.store.select(Material.selectDetailMaterial);

  constructor(private readonly store: Store<State>) {}

  submit(material: MaterialCreateModel | MaterialUpdateModel) {
    this.store.dispatch(
      material.hasOwnProperty('id')
        ? MaterialApiActions.Update(material as MaterialUpdateModel)
        : MaterialApiActions.Create(material)
    );
  }
}
