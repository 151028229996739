import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClaimCategoryTableItemModel } from '@app/modules/claim-categories/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
	selector: 'app-claim-category-overview-table',
	templateUrl: './claim-category-overview-table.component.html',
})
export class ClaimCategoryOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<ClaimCategoryTableItemModel>;

	@Output() onQueryParamsChange = new EventEmitter<any>();

	ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;

	translationKeyType(type: string): string {
		return `claim-categories.types.${type}`;
	}

	translationKeyValue(value: string): string {
		return `claim-categories.values.${value}`;
	}
}
