import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/services/http/http.service';
import {
    ActivityCreateModel,
    ActivityTableItemModel,
    ActivityUpdateModel,
} from '@app/modules/activity/models';
import { ActivityAvailableParticipantsModel } from '@app/modules/activity/models/activity-available-participants.model';
import { UpdateAvailableParticipantsModel } from '@app/modules/activity/models/update-activity-available-participants.model';
import { ActivityGenerateCode } from '@app/modules/activity/models/activity-generate-code.model';
import { TableDataModel } from '@app/shared/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TablePaginationModel } from '@app/shared/models/table/table-pagination.model';
import { TableSortingModel } from '@app/shared/models/table/table-sorting.model';
import { parse } from '@app/shared/utilities/url-search-params-parser';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    private readonly endpoints = {
        get: 'Activity/:id',
        overview: 'Activity/GetOverview',
        update: 'Activity/Update',
        updateParticipants: 'Activity/UpdateParticipants',
        create: 'Activity/Create',
        delete: 'Activity/Delete?id=:id',
        changeSequence: 'Activity/ChangeSequence/',
        getAll: 'Activity/GetAll',
        generateCode: 'Activity/GenerateCode',
        changeOnline: 'Activity/ChangeOnline',
        searchRelations: 'Activity/GetRelationKeyValue'
    };

    constructor(private readonly httpService: HttpService) {}

    getOverview(
        sorting: TableSortingModel,
        pagination: TablePaginationModel,
        activityId: number,
        displayOld: boolean,
        sortingThen?: TableSortingModel[]
    ): Observable<TableDataModel<ActivityTableItemModel>> {
        const paginationParams = parse(pagination, 'pagination');
        const sortingParams = parse(sorting, 'sorting');
        const activityIdParam = `activityTypeId=${activityId}`;
        const displayOldActivities = `displayOld=${displayOld}`;
        const sortingThenParams = sortingThen ? `&${parse(sortingThen, 'ThenBy')}` : ""

        return this.httpService.get<TableDataModel<ActivityTableItemModel>>(
            this.endpoints.overview + `?${paginationParams}&${sortingParams}&${activityIdParam}&${displayOldActivities}${sortingThenParams}`,
        );
    }

    getAll(): Observable<KeyValueModel[]> {
        return this.httpService.get<KeyValueModel[]>(this.endpoints.getAll);
    }

    get(id: number): Observable<ActivityUpdateModel> {
        return this.httpService.get<ActivityUpdateModel>(
            this.endpoints.get.replace(':id', id.toString()),
        );
    }

    update(request: ActivityUpdateModel): Observable<ActivityUpdateModel> {
        return this.httpService.post<ActivityUpdateModel>(this.endpoints.update, request);
    }

    updateParticipants(request: UpdateAvailableParticipantsModel): Observable<ActivityAvailableParticipantsModel> {
        return this.httpService.put<ActivityAvailableParticipantsModel>(this.endpoints.updateParticipants, request);
    }

    create(request: ActivityCreateModel): Observable<ActivityUpdateModel> {
        return this.httpService.post<ActivityUpdateModel>(this.endpoints.create, request);
    }

    generateCode(request: ActivityGenerateCode): Observable<{ code: string }> {
        return this.httpService.post<{ code: string }>(this.endpoints.generateCode, request);
    }

    delete(id: number): Observable<void> {
        return this.httpService.delete<void>(this.endpoints.delete.replace(':id', id.toString()));
    }

    changeOnline(id: number, online: boolean): Observable<any> {
        return this.httpService.put<any>(this.endpoints.changeOnline, {
            id,
            online,
        });
    }

    getRelations(searchQuery: string|null, skipCount: number, activityId: number|null): Observable<KeyValueModel[]> {

        const skipCountParam = `skipCount=${skipCount}`
        const searchQueryParam = searchQuery ? `&searchQuery=${searchQuery}` : '';
        const activityIdParam = activityId ? `&activityId=${activityId}` : '';

        return this.httpService.get<KeyValueModel[]>(this.endpoints.searchRelations+ 
            `?${skipCountParam}${searchQueryParam}${activityIdParam}`,);
    }
}
