import * as FAQActions from '@app/modules/faq/actions/faq.actions';
import { FAQTableItemModel } from '@app/modules/faq/models/faq-table.model';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { TableOverviewModel } from '@app/shared/models/table/table-overview.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { applySortingOrDefault } from '@app/shared/utilities/overview-reducer.utilities';
import { createReducer, on } from '@ngrx/store';

const initialState: State = { 
	...new TableOverviewModel<FAQTableItemModel>('online'),
	faqCategoryId: -1,
	faqCategories: null,
};

export interface State extends TableStateModel<FAQTableItemModel> {
	faqCategoryId: number | null;
	faqCategories: KeyValueModel[] | null;
}

export const reducer = createReducer(
	initialState,
	on(FAQActions.GetOverview, (state) => ({ ...state, loading: true })),
	on(FAQActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
	on(FAQActions.GetOverviewError, (state) => ({ ...state, loading: false })),

	on(FAQActions.FilterOverview, (state, action) => ({
		...state,
		sorting: applySortingOrDefault(initialState, action.sorting),
		pagination: {
			...state.pagination,
			page: action.pagination.page,
			pageSize: action.pagination.pageSize,
		},
	})),

	on(FAQActions.ResetOverview, (state) => ({ ...initialState, faqCategoryId: state.faqCategoryId, faqCategories: state.faqCategories })),

	on(FAQActions.SetActiveFAQCategoryId, (state, { faqCategoryId }) => ({ ...state, faqCategoryId })),

	on(FAQActions.GetFAQCategories, (state) => ({ ...state, loading: true })),
	on(FAQActions.GetFAQCategoriesSuccess, (state, { faqCategories }) => ({ ...state, faqCategories, loading: false })),
	on(FAQActions.GetFAQCategoriesError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;
export const getFAQCategoryId = (state: State) => state.faqCategoryId;
export const getFAQCategories = (state: State) => state.faqCategories;
