import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { blogClearGuard, blogExistsGuard } from '@app/modules/blogs/guards';
import { BlogDetailComponent } from '@app/modules/blogs/pages/detail/blog-detail.component';
import { BlogOverviewComponent } from '@app/modules/blogs/pages/overview/blog-overview.component';
import { BlogReactionsModule } from '@app/modules/blog-reaction/blog-reaction.module';

const routes: Routes = [
  { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
  { path: 'overzicht', component: BlogOverviewComponent },
  {
    path: 'toevoegen',
    component: BlogDetailComponent,
    canActivate: [blogClearGuard],
  },
  {
    path: 'bewerken/:id',
    component: BlogDetailComponent,
    canActivate: [blogExistsGuard],
  },
  {
    path: ':blogId/blog-reacties', 
    loadChildren: () => BlogReactionsModule 
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlogRoutingModule {}
