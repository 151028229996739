<div class="page-header">
  <a routerLink="/paginas/overzicht" class="page-header__back">
    <i class="fa-regular fa-chevron-left"></i>
  </a>
  <h1 class="page-header__title">{{ "pages.title" | translate }}</h1>
  <div class="page-header__actions"></div>
</div>

<app-page-detail-form
  [page]="page$ | async"
  [childPagesData]="childPagesData$ | async"
  (submitted)="submit($event)"
  (modalSubmitted)="modalSubmit($event)"
  [errors]="errors$ | async"
  [loading]="loading$ | async"
  [parentId]="parentId"
  [redirectExists$]="redirectExists$"
  (resetRedirectExists)="resetRedirectExists()"
/>
