import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { getErrorsForFormControl } from '@app/shared/utilities/validation.utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-back-end-validation',
  templateUrl: './back-end-validation.component.html',
  styleUrls: ['./back-end-validation.component.less'],
})
export class BackEndValidationComponent {
  validators: string[] = [];

  @Input({ required: true }) field = '';

  @Input() control:
    | AbstractControl
    | FormControl
    | FormArray
    | FormGroup
    | null = null;

  @Input() set errors(errors: { [key: string]: string[] }) {
    const inputErrors = {...errors};

    // Duplicate is also handled by the front-end validation
    if (Object.hasOwn(inputErrors, 'Email') && inputErrors['Email'].some(x => x === 'DUPLICATE')) 
    {
        inputErrors['Email'] = inputErrors['Email'].filter(x => x !== 'DUPLICATE');
    }

    this.validators = getErrorsForFormControl(this.field, inputErrors);
  }

  constructor(private readonly translate: TranslateService) {}

  getErrorKey(validator: string) {
    const splitError = validator.split(',');
    const otherParams: { [key: number]: string } = {};

    if (splitError.length > 1) {
      splitError.slice(1, splitError.length).forEach((element, index) => {
        otherParams[index] = element;
      });
    }
    return this.translate.instant(
      `error.${this.field}-${splitError[0].toLowerCase()}`,
      otherParams
    );
  }
}
