import * as FAQActions from '@app/modules/faq/actions/faq.actions'
import { FAQUpdateModel } from '@app/modules/faq/models';
import { KeyValueModel } from '@app/shared/models/common/key-value.model';
import { createReducer, on } from "@ngrx/store";

export const initialState: State = {
    faq: null,
    loading: false,
    errors: {},
    faqCategoryId: null,
	faqCategories: null
}

export interface State {
    faq: FAQUpdateModel | null,
    loading: boolean,
    errors: { [key: string]: string[] },
    faqCategoryId: number | null,
	faqCategories: KeyValueModel[] | null
}

export const reducer = createReducer(initialState,
    on(FAQActions.Clear, (state) => ({ ...initialState, faqCategoryId: state.faqCategoryId })),

    on(FAQActions.Get, (state) => ({ ...state, loading: true })),
    on(FAQActions.GetSuccess, (state, faq) => ({ ...state, faq, loading: false })),
    on(FAQActions.GetError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(FAQActions.Update, (state) => ({ ...state, loading: true, errors: {} })),
    on(FAQActions.UpdateSuccess, (state, faq) => ({ ...state, faq, loading: false })),
    on(FAQActions.UpdateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(FAQActions.Create, (state) => ({ ...state, loading: true, errors: {} })),
    on(FAQActions.CreateSuccess, (state, faq) => ({ ...state, faq, loading: false })),
    on(FAQActions.CreateError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),

    on(FAQActions.SetActiveFAQCategoryId, (state, { faqCategoryId }) => ({ ...state, faqCategoryId })),

	on(FAQActions.GetFAQCategories, (state) => ({ ...state, loading: true })),
	on(FAQActions.GetFAQCategoriesSuccess, (state, { faqCategories }) => ({ ...state, faqCategories, loading: false })),
	on(FAQActions.GetFAQCategoriesError, (state, { response }) => ({ ...state, loading: false, errors: response.errors || {} })),
);

export const getLoading = (state: any) => state.loading;
export const getErrors = (state: any) => state.errors;
export const getFAQ = (state: State) => state.faq;
export const getFAQCategoryId = (state: State) => state.faqCategoryId;
export const getFAQCategories = (state: State) => state.faqCategories;