<nz-form-item class="mb-15">
    <nz-form-label [nzSpan]="24" [nzRequired]="required">
        {{ (label ? label : "common.asset") | translate }}
    </nz-form-label>
        
    <nz-form-control [nzSpan]="24">
        <div *ngIf="!isReadOnly" class="button-container">
            <button nz-button nzType="primary" (click)="addAsset()"
                [disabled]="!!formGroup.value.url">{{ "common.add" | translate }}</button>
        </div>

        <app-input-validation [control]="formGroup" [field]="fieldName" [errors]="errors"/>

        <nz-list nzBordered>
            <nz-list-item *ngIf="!!formGroup.value.url">
                <a [href]="formGroup.value.url" target="_blank"><i class="fa-regular fa-download"></i> {{ formGroup.value.name }}</a>

                <button nz-button nzType="text" class="error" (click)="removeAsset()" *ngIf="!isReadOnly">
                    <i class="fa-regular fa-trash-can"></i>
                    {{ "common.delete" | translate }}
                </button>
            </nz-list-item>
            <nz-list-footer *ngIf="!formGroup.value.url">
                {{ "common.assets-placeholder" | translate }}
            </nz-list-footer>
        </nz-list>
    </nz-form-control>
</nz-form-item>