import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CountryModel } from '@app/modules/countries/models';

@Component({
	selector: 'app-phone-number-input',
    styleUrl: './phone-number-input.component.less',
	templateUrl: './phone-number-input.component.html',
})
export class PhoneNumberInputComponent {
	@Input() label = '';
	@Input() placeholder = '';

	@Input({ required: true }) phoneNumberField = '';
	@Input({ required: true }) phoneCodeField = '';
    @Input({ required: true }) phoneNumberControl?: FormControl<string>;
    @Input({ required: true }) phoneCodeControl?: FormControl<string>;
    @Input({ required: true }) set countries(countries: CountryModel[]) {
        this.phoneCodes = countries.map((country) => ({
            key: country.phoneCode,
            value: `+${country.phoneCode}`
        })) || [];
    }

	@Input({ required: true }) errors: { [key: string]: string[] } = {};
	
	@Input() required = false;
	@Input() disabled = false;

    phoneCodes: { key: string, value: string }[] = [];
}
