import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import * as FAQCategory from '@app/modules/faq-categories/reducers';
import * as FAQCategoryApiActions from '@app/modules/faq-categories/actions/faq-category-api.actions';
import { FAQCategoryChangeSequenceModel } from '@app/modules/faq-categories/models/faq-category-change-sequence.model';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';

@Component({
  selector: 'app-faq-category-overview',
  templateUrl: './faq-category-overview.component.html',
  styleUrl: './faq-category-overview.component.less'
})
export class FAQCategoryOverviewComponent {
	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;

	tableData$ = this.store.select(FAQCategory.selectFaqCategoryOverviewState);

	constructor(private readonly store: Store) {}

	onDeleteConfirm(id: number): void {
		this.store.dispatch(FAQCategoryApiActions.Delete({ id: id }));
	}

	/**
	 * Trigger the loading of data whenever the table changes.
	 * The default pageSize, index and sort options are based on the TableStateModel.
	 * @param params
	 */
	onQueryParamsChange(params: NzTableQueryParams) {
		const { pageSize, pageIndex, sort } = params;
		const currentSort = sort.find((item) => item.value !== null);
		const sortField = (currentSort?.key) ?? null;
		const sortOrder = (currentSort?.value) ?? null;

		this.store.dispatch(
			FAQCategoryApiActions.FilterOverview({
				pagination: {
					page: pageIndex,
					pageSize,
				},
				sorting: {
					propertyName: sortField ?? '',
					order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
				},
			})
		);
	}

	onResetOverview() {
        this.store.dispatch(FAQCategoryApiActions.ResetOverview());
    }

	onSequenceChange(request: FAQCategoryChangeSequenceModel) {
		this.store.dispatch(FAQCategoryApiActions.ChangeSequence(request));
	}
}
