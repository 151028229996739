import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as ActivityActions from '@app/modules/activity/actions/activity-api.actions';
import * as Activity from '@app/modules/activity/reducers';
import { ActivityService } from '@app/modules/activity/services/activity.service';
import { ActivityLocationService } from '@app/modules/activity-location/services/activity-location.service';
import { ActivityTemplateService } from '@app/modules/activity-template/services/activity-template.service';
import { ActivityTypeService } from '@app/modules/activity-type/services/activity-type.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, debounceTime, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class ActivityEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly activityService: ActivityService,
        private readonly activityTemplateServer: ActivityTemplateService,
        private readonly activityTypeService: ActivityTypeService,
        private readonly activityLocationservice: ActivityLocationService,
        private readonly messageService: NzMessageService,
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly store: Store<Activity.State>,
    ) {}

    /**
     * Get effects
     */
    loadActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.GetOverview),
            concatLatestFrom(() => [
                this.store.select(Activity.selectActivityOverviewPagination),
                this.store.select(Activity.selectActivityOverviewSorting),
                this.store.select(Activity.selectChosenActivityType),
                this.store.select(Activity.selectDisplayOld),
                this.store.select(Activity.selectActivityOverviewSortingThen)
            ]),
            exhaustMap(([, pagination, sorting, activityTypeId, displayOld, sortingThen]) =>
                this.activityService.getOverview(sorting, pagination, activityTypeId, displayOld, sortingThen).pipe(
                    map((response) => ActivityActions.GetOverviewSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.GetOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadActivitiesFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.FilterOverview),
            map(() => ActivityActions.GetOverview()),
        ),
    );

    loadActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.Get),
            exhaustMap((action) =>
                this.activityService.get(action.id).pipe(
                    map((response) => ActivityActions.GetSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.GetError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadActivityTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.GetTemplate),
            exhaustMap((action) =>
                this.activityTemplateServer.get(action.id).pipe(
                    map((response) => ActivityActions.GetTemplateSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.GetError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create effects
     */
    createActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.Create),
            exhaustMap((action) =>
                this.activityService.create(action).pipe(
                    map((activity) => ActivityActions.CreateSuccess(activity)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.CreateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    createActivitySuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityActions.CreateSuccess),
                tap((activity) =>
                    this.translate
                        .get('notification.add', { item: activity.name })
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    /**
     * Update effects
     */
    updateActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.Update),
            exhaustMap((action) =>
                this.activityService.update(action).pipe(
                    map((activity) => ActivityActions.UpdateSuccess(activity)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.UpdateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    updateActivitySuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityActions.UpdateSuccess),
                tap((activity) =>
                    this.translate
                        .get('notification.save', { item: activity.name })
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    updateParticipantsActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.UpdateParticipants),
            exhaustMap((action) =>
                this.activityService.updateParticipants(action).pipe(
                    map((activityParticipants) =>
                        ActivityActions.UpdateParticipantsSuccess(activityParticipants),
                    ),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.UpdateParticipantsError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Create update redirects & store clear
     */
    createUpdateActivitySuccessRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityActions.CreateSuccess, ActivityActions.UpdateSuccess),
                tap(() => this.router.navigate(['activiteiten'])),
            ),
        { dispatch: false },
    );

    /**
     * Delete effects
     */
    deleteActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.Delete),
            exhaustMap((action) =>
                this.activityService.delete(action.id).pipe(
                    map(() => ActivityActions.DeleteSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.DeleteError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    deleteActivitySuccessReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.DeleteSuccess),
            map(() => ActivityActions.GetOverview()),
        ),
    );

    deleteActivitySuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityActions.DeleteSuccess),
                tap(() =>
                    this.translate
                        .get('notification.delete-success')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    activityErrorToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityActions.DeleteError, ActivityActions.GetOverviewError),
                tap((error) => this.messageService.error(BuildErrorString(error.response, this.translate))),
            ),
        { dispatch: false },
    );

    loadActivityTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.GetAllActivityTemplates),
            exhaustMap(() =>
                this.activityTemplateServer.getAll().pipe(
                    map((activityTemplates) => ActivityActions.GetAllActivityTemplatesSuccess({ activityTemplates })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.GetAllActivityTemplatesError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /** *
     * Get activity types
     */
    loadActivityTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.GetActivityTypes),
            exhaustMap(() =>
                this.activityTypeService.getCodesAll().pipe(
                    map((activityTypes) => ActivityActions.GetActivityTypesSuccess({ activityTypes })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.GetActivityTypesError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /** *
     * Get activity locations
     */
    loadActivityLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.GetActivityLocations),
            exhaustMap(() =>
                this.activityLocationservice.getAll().pipe(
                    map((activityLocations) => ActivityActions.GetActivityLocationsSuccess({ activityLocations })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.GetActivityLocationsError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    checkCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.CheckCode),
            exhaustMap((request) =>
                this.activityService.generateCode(request.code).pipe(
                    map((code) => ActivityActions.CheckCodeSuccess(code)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.CheckCodeError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Change active effects
     */
    changeActiveActivity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.UpdateShowOnline),
            exhaustMap((action) =>
                this.activityService.changeOnline(action.id, action.online).pipe(
                    map((Activity) => ActivityActions.UpdateShowOnlineSuccess(Activity)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.UpdateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    changeActiveSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ActivityActions.UpdateShowOnlineSuccess),
                tap((Activity) =>
                    this.translate
                        .get('notification.save', { item: Activity.name })
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    changeActiveSuccessReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.UpdateShowOnlineSuccess),
            map(() => ActivityActions.GetOverview()),
        ),
    );

    /**
     * getRelations
     */
    getRelations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.GetRelations),
            debounceTime(350),
            exhaustMap((action) =>
                this.activityService.getRelations(action.searchQuery, action.skipCount, action.activityId).pipe(
                    map((relations) => ActivityActions.GetRelationsSuccess({ relations })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(ActivityActions.GetRelationsError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );
}
