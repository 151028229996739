import { Component } from '@angular/core';
import * as FAQCategoryApiActions from '@app/modules/faq-categories/actions/faq-category-api.actions';
import { FAQCategoryCreateModel } from '@app/modules/faq-categories/models/faq-category-create.model';
import { FAQCategoryUpdateModel } from '@app/modules/faq-categories/models/faq-category-update.model';
import * as FAQCategory from '@app/modules/faq-categories/reducers';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-faq-category-detail',
	templateUrl: './faq-category-detail.component.html',
	styleUrl: './faq-category-detail.component.less',
})
export class FAQCategoryDetailComponent {
	loading$ = this.store.select(FAQCategory.selectDetailLoading);
	errors$ = this.store.select(FAQCategory.selectDetailErrors);
	faqCategory$ = this.store.select(FAQCategory.selectDetailFaqCategory);

	constructor(private readonly store: Store<State>) {}

	submit(faqCategory: FAQCategoryCreateModel | FAQCategoryUpdateModel) {
		this.store.dispatch(
			faqCategory.hasOwnProperty('id')
				? FAQCategoryApiActions.Update(faqCategory as FAQCategoryUpdateModel)
				: FAQCategoryApiActions.Create(faqCategory)
		);
	}
}
