import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateChildFn,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import * as BlogActions from '@app/modules/blogs/actions/blog-api.actions';
import { BlogService } from '@app/modules/blogs/services/blog.service';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { catchError, map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const blogService = inject(BlogService);
  const router = inject(Router);
  const store = inject(Store<State>);

  return blogService.get(route.params['id'] ?? route.params['blogId']).pipe(
    map((blog) => BlogActions.GetSuccess(blog)),
    tap((action: any) => store.dispatch(action)),
    map((blog) => !!blog),
    catchError(() => {
      return router.navigate(['/not-found']);
    })
  );
};

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
