import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { discountCodeClearGuard, discountCodeExistsGuard } from '@app/modules/discount-codes/guards';
import { DiscountCodeDetailComponent } from '@app/modules/discount-codes/pages/detail/discount-code-detail.component';
import { DiscountCodeOverviewComponent } from '@app/modules/discount-codes/pages/overview/discount-code-overview.component';

const routes: Routes = [
	{ path: '', redirectTo: 'overzicht', pathMatch: 'full' },
	{ path: 'overzicht', component: DiscountCodeOverviewComponent },
	{ path: 'toevoegen', component: DiscountCodeDetailComponent, canActivate: [ discountCodeClearGuard ] },
	{ path: 'bewerken/:id', component: DiscountCodeDetailComponent, canActivate: [ discountCodeExistsGuard ] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DiscountCodeRoutingModule {}
