import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as RelationTypeOverview from './relation-type-overview.reducer'
import * as RelationTypeDetailPage from './relation-type-detail-page.reducer'

export const relationTypeFeatureKey = 'relationType';

export interface RelationTypeState {
    relationTypeOverview: RelationTypeOverview.State,
    relationTypeDetail: RelationTypeDetailPage.State,
}

export interface State {
    relationType: RelationTypeState
}

const map: ActionReducerMap<RelationTypeState, Action> = {
    relationTypeOverview: RelationTypeOverview.reducer,
    relationTypeDetail: RelationTypeDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: RelationTypeState, action: Action) => combineReducers(map)(state, action);

const selectRelationTypeState = createFeatureSelector<RelationTypeState>(relationTypeFeatureKey);

export const selectState = createSelector(selectRelationTypeState, state => state)
export const selectRelationTypeOverviewState = createSelector(selectState, state => state.relationTypeOverview)

export const selectRelationTypeOverviewLoading = createSelector(selectRelationTypeOverviewState, RelationTypeOverview.getLoading)
export const selectRelationTypeOverviewList = createSelector(selectRelationTypeOverviewState, RelationTypeOverview.getList)
export const selectRelationTypeOverviewPagination = createSelector(selectRelationTypeOverviewState, RelationTypeOverview.getPagination)
export const selectRelationTypeOverviewSorting = createSelector(selectRelationTypeOverviewState, RelationTypeOverview.getSorting)

export const selectRelationTypeDetailState = createSelector(selectState, state => state.relationTypeDetail);

export const selectDetailLoading = createSelector(selectRelationTypeDetailState, RelationTypeDetailPage.getLoading);
export const selectDetailRelationType = createSelector(selectRelationTypeDetailState, RelationTypeDetailPage.getRelationType);
export const selectDetailErrors = createSelector(selectRelationTypeDetailState, RelationTypeDetailPage.getErrors);