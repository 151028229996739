import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as NewsOverview from './news-overview.reducer'
import * as NewsDetailPage from './news-detail.reducer'

export const newsFeatureKey = 'news';

export interface NewsState {
    newsOverview: NewsOverview.State,
    newsDetail: NewsDetailPage.State,
}

export interface State {
    news: NewsState
}

const map: ActionReducerMap<NewsState, Action> = {
    newsOverview: NewsOverview.reducer,
    newsDetail: NewsDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: NewsState, action: Action) => combineReducers(map)(state, action);

const selectNewsState = createFeatureSelector<NewsState>(newsFeatureKey);

export const selectState = createSelector(selectNewsState, state => state)
export const selectNewsOverviewState = createSelector(selectState, state => state.newsOverview)

export const selectNewsOverviewLoading = createSelector(selectNewsOverviewState, NewsOverview.getLoading)
export const selectNewsOverviewList = createSelector(selectNewsOverviewState, NewsOverview.getList)
export const selectNewsOverviewPagination = createSelector(selectNewsOverviewState, NewsOverview.getPagination)
export const selectNewsOverviewSorting = createSelector(selectNewsOverviewState, NewsOverview.getSorting)

export const selectNewsDetailState = createSelector(selectState, state => state.newsDetail);

export const selectDetailLoading = createSelector(selectNewsDetailState, NewsDetailPage.getLoading);
export const selectDetailNews = createSelector(selectNewsDetailState, NewsDetailPage.getNews);
export const selectDetailErrors = createSelector(selectNewsDetailState, NewsDetailPage.getErrors);