import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import * as NewsActions from '@app/modules/news/actions/news-api.actions';
import * as News from '@app/modules/news/reducers';
import { NewsService } from '@app/modules/news/services/news.service';
import { BuildErrorString } from '@app/shared/utilities/validation.utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, exhaustMap, catchError, of, tap } from 'rxjs';

@Injectable()
export class NewsEffects {
  constructor(
    private actions$: Actions,
    private newsService: NewsService,
    private messageService: NzMessageService,
    private translate: TranslateService,
    private router: Router,
    private store: Store<News.State>
  ) {}

  /**
   * Get effects
   */
  loadMultNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.GetOverview),
      concatLatestFrom(() => [
        this.store.select(News.selectNewsOverviewPagination),
        this.store.select(News.selectNewsOverviewSorting),
      ]),
      exhaustMap(([, pagination, sorting]) =>
        this.newsService.getAll(sorting, pagination).pipe(
          map((response) => NewsActions.GetOverviewSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(NewsActions.GetOverviewError({ response: response.error }))
          )
        )
      )
    )
  );

  loadNewsFilterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.FilterOverview),
      map(() => NewsActions.GetOverview())
    )
  );

  loadNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.Get),
      exhaustMap((action) =>
        this.newsService.get(action.id).pipe(
          map((response) => NewsActions.GetSuccess(response)),
          catchError((response: { error: ErrorResponse }) =>
            of(NewsActions.GetError({ response: response.error }))
          )
        )
      )
    )
  );

  /**
   * Create effects
   */
  createNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.Create),
      exhaustMap((action) =>
        this.newsService.create(action).pipe(
          map((news) => NewsActions.CreateSuccess(news)),
          catchError((response: { error: ErrorResponse }) =>
            of(NewsActions.CreateError({ response: response.error }))
          )
        )
      )
    )
  );

  createNewsSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActions.CreateSuccess),
        tap((news) =>
          this.translate
            .get('notification.add', { item: news.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Update effects
   */
  updateNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.Update),
      exhaustMap((action) =>
        this.newsService.update(action).pipe(
          map((news) => NewsActions.UpdateSuccess(news)),
          catchError((response: { error: ErrorResponse }) =>
            of(NewsActions.UpdateError({ response: response.error }))
          )
        )
      )
    )
  );

  updateNewsSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActions.UpdateSuccess),
        tap((news) =>
          this.translate
            .get('notification.save', { item: news.title })
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Create update redirects & store clear
   */
  createUpdateNewsSuccessRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActions.CreateSuccess, NewsActions.UpdateSuccess),
        tap(() => this.router.navigate(['nieuws']))
      ),
    { dispatch: false }
  );

  /**
   * Delete effects
   */
  deleteNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.Delete),
      exhaustMap((action) =>
        this.newsService.delete(action.id).pipe(
          map(() => NewsActions.DeleteSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(NewsActions.DeleteError({ response: response.error }))
          )
        )
      )
    )
  );

  deleteNewsSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.DeleteSuccess),
      map(() => NewsActions.GetOverview())
    )
  );

  deleteNewsSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActions.DeleteSuccess),
        tap(() =>
          this.translate
            .get('notification.delete-success')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  /**
   * Change sequence effects
   */
  changeSequenceNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.ChangeSequence),
      exhaustMap((action) =>
        this.newsService.changeSequence(action).pipe(
          map(() => NewsActions.ChangeSequenceSuccess()),
          catchError((response: { error: ErrorResponse }) =>
            of(NewsActions.ChangeSequenceError({ response: response.error }))
          )
        )
      )
    )
  );

  changeSequenceSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.ChangeSequenceSuccess),
      map(() => NewsActions.GetOverview())
    )
  );

  // Change showonline effects

  setShowOnline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.UpdateShowOnline),
      exhaustMap((action) =>
        this.newsService.showOnline(action).pipe(
          map((showOnline) => NewsActions.UpdateShowOnlineSuccess(showOnline)),
          catchError((response: { error: ErrorResponse }) =>
            of(NewsActions.UpdateShowOnlineError({ response: response.error }))
          )
        )
      )
    )
  );

  setShowOnlineSuccessReload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NewsActions.UpdateShowOnlineSuccess),
      map(() => NewsActions.GetOverview())
    )
  );

  setShowOnlineSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NewsActions.UpdateShowOnlineSuccess),
        tap(() =>
          this.translate
            .get('news.notification.save')
            .subscribe((translatedMessage: string) =>
              this.messageService.success(translatedMessage)
            )
        )
      ),
    { dispatch: false }
  );

  newsErrorToast$ = createEffect(
		() =>
		  this.actions$.pipe(
			ofType(NewsActions.GetOverviewError, NewsActions.DeleteError, NewsActions.UpdateShowOnlineError, NewsActions.ChangeSequenceError),
			tap((error) => this.messageService.error(BuildErrorString(error.response, this.translate)))
		  ),
		{ dispatch: false }
	  );

}
