import { ErrorResponse } from "@app/core/models/error-response.model";
import { ClaimCategoryTableItemModel, ClaimCategoryUpdateModel } from "@app/modules/claim-categories/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";


export const GetOverview = createAction("[ClaimCategory] GetOverview");
export const GetOverviewError = createAction("[ClaimCategory] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[ClaimCategory] GetOverviewSuccess", props<TableDataModel<ClaimCategoryTableItemModel>>());

export const FilterOverview = createAction("[ClaimCategory] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[ClaimCategory] Clear");

export const Get = createAction("[ClaimCategory] Get", props<{ claimType: string, value: string }>());
export const GetError = createAction("[ClaimCategory] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[ClaimCategory] GetSuccess", props<{claimCategory: ClaimCategoryUpdateModel}>());

export const Update = createAction("[ClaimCategory] Update", props<{claimCategory: ClaimCategoryUpdateModel}>());
export const UpdateError = createAction("[ClaimCategory] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[ClaimCategory] UpdateSuccess", props<{claimCategory: ClaimCategoryUpdateModel}>());
