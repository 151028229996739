<nz-form-item>
	<nz-form-label *ngIf="label" [nzSpan]="24" [nzRequired]="required">{{ label }}</nz-form-label>
    <nz-input-group nzCompact>
        <nz-form-control [nzErrorTip]="PhoneCodeValidationTemplate" class="phone-code">
            <nz-select [formControl]="phoneCodeControl" [nzAllowClear]="true" [nzDisabled]="disabled">
                <ng-container *ngFor="let phoneCode of phoneCodes">
                    <nz-option [nzValue]="phoneCode.key" [nzLabel]="phoneCode.value"></nz-option>
                </ng-container>
            </nz-select>

            <ng-template #PhoneCodeValidationTemplate let-control>
                <app-input-validation [control]="control" [field]="phoneCodeField" [errors]="errors"></app-input-validation>
            </ng-template>
        </nz-form-control>
            
        <nz-form-control [nzErrorTip]="PhoneNumberValidationTemplate" class="phone-number">
            <input type="text" nz-input [formControl]="phoneNumberControl"/>

            <ng-template #PhoneNumberValidationTemplate let-control>
                <app-input-validation [control]="control" [field]="phoneNumberField" [errors]="errors"></app-input-validation>
            </ng-template>
        </nz-form-control>
    </nz-input-group>
</nz-form-item>