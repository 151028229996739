<div class="page-header">
  <div class="page-header__actions">
    <a
      claimDirective
      [claims]="[
        {
          claimType: ClaimTypes.Page,
          claimValues: [ClaimValues.Create]
        }
      ]"
      [routerLink]="'/paginas/toevoegen/' + parentId"
      nz-button
      nzType="primary"
    >
      {{ "common.add" | translate }}
      <i class="fa-regular fa-plus"></i>
    </a>
  </div>
</div>

<nz-table
  #overviewTable
  [nzData]="data.list"
  [nzPageIndex]="data.pagination.page"
  [nzPageSize]="data.pagination.pageSize"
  [nzTotal]="data.pagination.totalItems ?? 0"
  [nzFrontPagination]="false"
  [nzShowPagination]="!this.draggingEnabled"
  (nzQueryParams)="onQueryParamsChange.emit($event)"
  nzTableLayout="fixed"
>
  <thead>
    <tr>
      <th
        class="text-center sequenceColumn"
        nzCellControl="sequenceNumber"
        nzColumnKey="sequenceNumber"
        [nzShowSort]="false"
        claimDirective
        nzWidth="90px"
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Update]
          }
        ]"
      >
        <label
          nz-checkbox
          [(ngModel)]="draggingEnabled"
          (ngModelChange)="draggingChanged()"
        ></label>
      </th>
			<th
				nzWidth="90px"
				[nzShowSort]="false"
				[nzSortFn]="true"
        [nzShowSort]="!draggingEnabled"
        nzColumnKey="isVisible"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Update]
          }
        ]"
      >
        {{ "common.is-visible" | translate }}
      </th>
      <th
        [nzSortFn]="true"
        [nzShowSort]="true"
        nzColumnKey="title"
        [nzSortOrder]="'ascend'"
        [nzShowSort]="!draggingEnabled"
      >
        {{ "common.title" | translate }}
      </th>
      <th
        nzWidth="140px"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Read, ClaimValues.Delete]
          }
        ]"
      ></th>
    </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="drop($event)">
    <tr
      *ngFor="let row of overviewTable.data"
      cdkDrag
      [cdkDragData]="row"
      [cdkDragDisabled]="!draggingEnabled"
    >
      <td
        nzCellControl="sequenceNumber"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Update]
          }
        ]"
        class="text-center sequenceColumn"
      >
        <i class="fa-solid fa-grip-dots-vertical" cdkDragHandle></i>
      </td>
      <td
        nzAlign="center"
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Update]
          }
        ]"
      >
        <label
          nz-checkbox
          [nzChecked]="row?.isVisible"
          (nzCheckedChange)="changeVisible(row.id, !row.isVisible)"
        ></label>
      </td>
      <td>{{ row.title | truncateText }}</td>
      <td
        claimDirective
        [claims]="[
          {
            claimType: ClaimTypes.Page,
            claimValues: [ClaimValues.Read, ClaimValues.Delete]
          }
        ]"
      >
        <div class="table-actions">
          <a
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Page,
                claimValues: [ClaimValues.Read]
              }
            ]"
            [href]="row.previewLink"
            target="_blank"
            nz-button
            nzType="text"
            nzShape="circle"
          >
            <i class="fa-regular fa-magnifying-glass"></i>
          </a>
          <a
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Page,
                claimValues: [ClaimValues.Read]
              }
            ]"
            [routerLink]="['/paginas/bewerken/', row.id]"
            nz-button
            nzType="text"
            nzShape="circle"
          >
            <i class="fa-regular fa-pen"></i>
          </a>
          <button
            claimDirective
            [claims]="[
              {
                claimType: ClaimTypes.Page,
                claimValues: [ClaimValues.Delete]
              }
            ]"
            nz-button
            nzDanger
            nzType="text"
            nzShape="circle"
            nz-popconfirm
            nzOkDanger
            [nzPopconfirmTitle]="'notification.delete' | translate"
            [nzOkText]="'common.delete' | translate"
            [nzCancelText]="'common.cancel' | translate"
            [nzIcon]="templateIconDelete"
            nzPopconfirmPlacement="left"
            (nzOnConfirm)="delete.emit(row.id)"
          >
            <i class="fa-regular fa-trash-can"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<ng-template #templateIconDelete>
  <i nz-typography nzType="danger" class="fa-regular fa-trash-can"></i>
</ng-template>
