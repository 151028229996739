import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FAQTableItemModel } from '@app/modules/faq/models/faq-table.model';
import { TableStateModel } from '@app/shared/models/table/table-state.model';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FAQChangeSequenceModel } from '@app/modules/faq/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { ClaimService } from '@app/shared/services/claim.service';
import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
	selector: 'app-faq-overview-table',
	templateUrl: './faq-overview-table.component.html',
})
export class FAQOverviewTableComponent implements OnInit{
	@Input({ required: true }) data!: TableStateModel<FAQTableItemModel>;
	@Input({ required: true }) categoryId!: number;

	@Output() delete = new EventEmitter<number>();
	@Output() onQueryParamsChange = new EventEmitter<any>();
	@Output() changeSequence = new EventEmitter<FAQChangeSequenceModel>();
	@Output() onChangeOnline = new EventEmitter<{ id: number; online: boolean }>();
	@Output() onResetOverview = new EventEmitter<void>();
	@Output() changeDragDrop = new EventEmitter<boolean>;

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;
	draggingEnabled = false;
	readonly?: boolean;

    constructor(private readonly claimService: ClaimService) {}

	async ngOnInit(){
		if(!await this.claimService.hasAny([{ claimType: ClaimTypes.FAQ, claimValues: [ClaimValues.Update] }])) {
            this.readonly = true;
        } else {
            this.readonly = false;
        }
	}

	drop(event: CdkDragDrop<string[]>): void {
		this.changeSequence.emit({
			id: event.item.data.id,
			newSequenceNumber: event.currentIndex,
		});
	}

	changeOnline(id: number, online: boolean) {
		this.onChangeOnline.emit({ id, online });
	}

	draggingChanged() {
		this.changeDragDrop.emit(!this.draggingEnabled)
		if (this.draggingEnabled) {
			this.onQueryParamsChange.emit({
				pageIndex: 1,
				pageSize: -1,
				sort: [{key: 'sequenceNumber', value:'ascend'}],
				filter: [{}]
			} as NzTableQueryParams);
		} else {
			this.onResetOverview.emit();
		}
	}
}
