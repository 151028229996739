export enum Type {
    Client = 1,
    ContactPerson = 2,
    Creditor = 3,
    InCompany = 4,
}

export const TypeLabels: Record<number, string> = {
    [Type.Client]: 'relation-type.enum.type.client',
    [Type.ContactPerson]: 'relation-type.enum.type.contactperson',
    [Type.Creditor]: 'relation-type.enum.type.creditor',
    [Type.InCompany]: 'relation-type.enum.type.incompany',
}