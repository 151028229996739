import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { newsClearGuard, newsExistsGuard } from '@app/modules/news/guards';
import { NewsDetailComponent } from '@app/modules/news/pages/detail/news-detail.component';
import { NewsOverviewComponent } from '@app/modules/news/pages/overview/news-overview.component';

const routes: Routes = [
	{ path: '', redirectTo: 'overzicht', pathMatch: 'full' },
	{ path: 'overzicht', component: NewsOverviewComponent },
	{ path: 'toevoegen', component: NewsDetailComponent, canActivate: [newsClearGuard] },
	{ path: 'bewerken/:id', component: NewsDetailComponent, canActivate: [newsExistsGuard] },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class NewsRoutingModule {}