import * as RelationTypeApiActions from "@app/modules/relation-type/actions/relation-type-api.actions"
import { RelationTypeTableItemModel } from "@app/modules/relation-type/models/relation-type-table.model"
import { TableOverviewModel } from "@app/shared/models/table/table-overview.model"
import { TableStateModel } from "@app/shared/models/table/table-state.model"
import { applySortingOrDefault } from "@app/shared/utilities/overview-reducer.utilities"
import { createReducer, on } from "@ngrx/store"

const initialState: State = new TableOverviewModel<RelationTypeTableItemModel>('name');

export interface State extends TableStateModel<RelationTypeTableItemModel> { }

export const reducer = createReducer(initialState,
    on(RelationTypeApiActions.GetOverview, (state) => ({ ...state, loading: true })),
    on(RelationTypeApiActions.GetOverviewSuccess, (state, response) => ({ ...state, ...response, loading: false })),
    on(RelationTypeApiActions.GetOverviewError, (state) => ({ ...state, loading: false })),
    on(RelationTypeApiActions.FilterOverview, (state, { sorting, pagination }) => ({ 
        ...state, 
        sorting: applySortingOrDefault(initialState, sorting),
        pagination: { 
            ...state.pagination, 
            page: pagination.page,
            pageSize: pagination.pageSize
        } 
    })),
    on(RelationTypeApiActions.ResetOverview,  () => ({ ...initialState }))
)

export const getLoading = (state: State) => state.loading;
export const getList = (state: State) => state.list;
export const getPagination = (state: State) => state.pagination;
export const getSorting = (state: State) => state.sorting;