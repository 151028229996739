import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    RouterStateSnapshot,
} from '@angular/router';
import * as BlogApiActions from '@app/modules/blogs/actions/blog-api.actions';
import { State } from '@app/reducers';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export const canActivate: CanActivateFn = (route, state) => {
  const store = inject(Store<State>);

  return of(true).pipe(
    map(() => BlogApiActions.Clear()),
    tap((action: any) => store.dispatch(action)),
    map(() => true)
  );
};

export const canActivateChild: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => canActivate(route, state);
