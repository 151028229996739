import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as ActivityTemplateApiActions from '@app/modules/activity-template/actions/activity-template-api.actions';
import * as ActivityTemplate from '@app/modules/activity-template/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { take } from 'rxjs';

@Component({
    selector: 'app-activity-template-overview',
    templateUrl: './activity-template-overview.component.html',
    styleUrls: ['./activity-template-overview.component.less'],
})
export class ActivityTemplateOverviewComponent {
    tableData$ = this.store.select(ActivityTemplate.selectActivityTemplateOverviewState);
    activityTypes$ = this.store.select(ActivityTemplate.selectActivityTemplateActivityTypes);
    selectedactivityTypeId$ = this.store.select(ActivityTemplate.selectActivityTemplateActivityTypeId);

    ClaimTypes = ClaimTypes;
    ClaimValues = ClaimValues;
    activityTypeId = -1;

    constructor(private readonly store: Store) {
        this.store.dispatch(ActivityTemplateApiActions.GetActivityTypes());
        this.selectedactivityTypeId$.pipe(takeUntilDestroyed()).subscribe((typeId) => {
            this.activityTypeId = typeId ?? -1;
        });
	}

    onDeleteConfirm(id: number): void {
        this.store.dispatch(ActivityTemplateApiActions.Delete({ id }));
    }

    /**
     * Trigger the loading of data whenever the table changes.
     * The default pageSize, index and sort options are based on the TableStateModel.
     * @param params
     */
    onQueryParamsChange(params: NzTableQueryParams) {
        const { pageSize, pageIndex, sort } = params;
        const currentSort = sort.find((item) => item.value !== null);
        const sortField = (currentSort?.key) ?? null;
        const sortOrder = (currentSort?.value) ?? null;

        this.store.dispatch(
            ActivityTemplateApiActions.FilterOverview({
				activityTypeId: this.activityTypeId,
                pagination: {
                    page: pageIndex,
                    pageSize,
                },
                sorting: {
                    propertyName: sortField ?? '',
                    order: !!sortField ? (sortOrder === 'ascend' ? 'ASC' : 'DESC') : '',
                },
            }),
        );
    }

    onResetOverview() {
        this.store.dispatch(ActivityTemplateApiActions.ResetOverview());
    }

    onCategoryChange(activityTypeId: number) {
        this.store.dispatch(ActivityTemplateApiActions.SetActiveActivityTypeId({ activityTypeId }));

        this.store
            .select(ActivityTemplate.selectActivityTemplateOverviewSorting)
            .pipe(take(1))
            .subscribe((sort) => {
                this.store.dispatch(
                    ActivityTemplateApiActions.FilterOverview({
                        activityTypeId,
                        pagination: { page: 1, pageSize: 10 },
                        sorting: sort,
                    }),
                );
            });
    }
}
