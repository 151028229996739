import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { selectUser } from '@app/modules/auth/reducers';
import * as BlogReactionApiActions from '@app/modules/blog-reaction/actions/blog-reaction-api.actions';
import {
  BlogReactionCreateModel,
  BlogReactionUpdateModel,
} from '@app/modules/blog-reaction/models';
import * as BlogReaction from '@app/modules/blog-reaction/reducers';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-blog-reaction-detail',
  templateUrl: './blog-reaction-detail.component.html',
  styleUrls: ['./blog-reaction-detail.component.less'],
})
export class BlogReactionDetailComponent {
  tableData$ = this.store.select(BlogReaction.selectBlogReactionOverviewState);
  loading$ = this.store.select(BlogReaction.selectDetailLoading);
  errors$ = this.store.select(BlogReaction.selectDetailErrors);
  blogReaction$ = this.store.select(BlogReaction.selectDetailBlogReaction);
  user$ = this.store.select(selectUser);

  ClaimTypes = ClaimTypes;
  ClaimValues = ClaimValues;

  blogId: number | undefined;
  reactionId: number | undefined;
  addSubReaction: boolean = false;

  constructor(
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.blogId = params['blogId'];
      this.reactionId = params['id'];

      this.addSubReaction = false;
      this.blogReaction$.subscribe((blogReaction) => {
        if (blogReaction) {
          if (!blogReaction!.parentReactionId) {
            this.addSubReaction = true;
          }
        }
      });
    });
  }

  submit(blogreaction: BlogReactionCreateModel | BlogReactionUpdateModel) {
    this.store.dispatch(
      blogreaction.hasOwnProperty('id')
        ? BlogReactionApiActions.Update(blogreaction as BlogReactionUpdateModel)
        : BlogReactionApiActions.Create(blogreaction)
    );
  }
}
