import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { reviewClearGuard, reviewExistsGuard } from '@app/modules/review/guards';
import { ReviewDetailComponent } from '@app/modules/review/pages/detail/review-detail.component';
import { ReviewOverviewComponent } from '@app/modules/review/pages/overview/review-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: ReviewOverviewComponent },
    { path: 'toevoegen', component: ReviewDetailComponent, canActivate: [ reviewClearGuard ] },
    { path: 'bewerken/:id', component: ReviewDetailComponent, canActivate: [ reviewExistsGuard ] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ReviewRoutingModule { }
