import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { faqCategoryClearGuard, faqCategoryExistsGuard } from '@app/modules/faq-categories/guards';
import { FAQCategoryDetailComponent } from '@app/modules/faq-categories/pages/detail/faq-category-detail.component';
import { FAQCategoryOverviewComponent } from '@app/modules/faq-categories/pages/overview/faq-category-overview.component';

const routes: Routes = [
    { path: '', redirectTo: 'overzicht', pathMatch: 'full' },
    { path: 'overzicht', component: FAQCategoryOverviewComponent },
    { path: 'toevoegen', component: FAQCategoryDetailComponent, canActivate: [faqCategoryClearGuard] },
    { path: 'bewerken/:id', component: FAQCategoryDetailComponent, canActivate: [faqCategoryExistsGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FAQCategoryRoutingModule {}