import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from '@app/core/models/error-response.model';
import { CountryService } from '@app/modules/countries/services/country.service';
import { RelationTypeService } from '@app/modules/relation-type/services/relation-type.service';
import * as RelationActions from '@app/modules/relation/actions/relation-api.actions';
import * as Relation from '@app/modules/relation/reducers';
import { RelationService } from '@app/modules/relation/service/relation.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';

@Injectable()
export class RelationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly relationService: RelationService,
        private readonly store: Store<Relation.State>,
        private readonly router: Router,
        private readonly messageService: NzMessageService,
        private readonly translate: TranslateService,
        private readonly relationTypeService: RelationTypeService,
        private readonly countryService: CountryService,
    ) {}

    /**
     * Create effects
     */
    createRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.Create),
            exhaustMap((action) =>
                this.relationService.create(action).pipe(
                    map((relation) => RelationActions.CreateSuccess(relation)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.CreateError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    createRelationSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActions.CreateSuccess),
                tap(() =>
                    this.translate
                        .get('relation.notification.add')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    createRelationSuccessRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActions.CreateSuccess),
                tap(() => this.router.navigate(['relaties'])),
            ),
        { dispatch: false },
    );

    /**
     * Get effects
     */
    loadRelations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.GetOverview),
            concatLatestFrom(() => [
                this.store.select(Relation.selectRelationOverviewPagination),
                this.store.select(Relation.selectRelationOverviewSorting),
            ]),
            exhaustMap(([, pagination, sorting]) =>
                this.relationService.getOverview(sorting, pagination).pipe(
                    map((response) => RelationActions.GetOverviewSuccess(response)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.GetOverviewError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loadRelationsFilterChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.FilterOverview),
            map(() => RelationActions.GetOverview()),
        ),
    );

    /**
     * ChangeActive effects
     */
    changeActive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.ChangeActive),
            exhaustMap((action) =>
                this.relationService.active(action.id).pipe(
                    map((relation) => RelationActions.ChangeActiveSuccess(relation)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.ChangeActiveError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    changeActiveSuccessReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.ChangeActiveSuccess),
            map(() => RelationActions.GetOverview()),
        ),
    );

    changeActiveSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActions.ChangeActiveSuccess),
                tap(() =>
                    this.translate
                        .get('relation.notification.save')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    /**
     * Get relation types
     */
    loadRelationTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.GetRelationTypes),
            exhaustMap(() =>
                this.relationTypeService.getAll().pipe(
                    map((relationTypes) => RelationActions.GetRelationTypesSuccess({ relationTypes })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.GetRelationTypesError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Get countries
     */
    loadCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.GetCountries),
            exhaustMap(() =>
                this.countryService.getAll().pipe(
                    map((countries) => RelationActions.GetCountriesSuccess({ countries })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.GetCountriesError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Get by email
     */
    loadGetByEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.GetByEmail),
            exhaustMap((action) =>
                this.relationService.getByEmail(action.email).pipe(
                    map((duplicatedRelation) => RelationActions.GetByEmailSuccess(duplicatedRelation)),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.GetByEmailError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    /**
     * Resend welcome mail
     */

    resendWelcomeMail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.ResendWelcomeMail),
            concatLatestFrom(() => [this.store.select(Relation.selectRelationId)]),
            exhaustMap(([, action]) =>
                this.relationService.resendWelcomeMail(action).pipe(
                    map(() => RelationActions.ResendWelcomeMailSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.ResendWelcomeMailError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    resendWelcomeMailToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActions.ResendWelcomeMailSuccess),
                tap(() =>
                    this.translate
                        .get('relation.notification.resend-welcome-mail-success')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    resendWelcomeMailErrorToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActions.ResendWelcomeMailError),
                tap(() =>
                    this.translate
                        .get('relation.notification.error')
                        .subscribe((translatedMessage: string) => this.messageService.error(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    /**
     * login as relation
     */

    loginAsRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.LoginAsRelation),
            concatLatestFrom(() => [this.store.select(Relation.selectRelationId)]),
            exhaustMap(([, action]) =>
                this.relationService.loginAsRelation(action).pipe(
                    map((link) => RelationActions.LoginAsRelationSuccess({ link: link.link })),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.LoginAsRelationError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    loginAsRelationToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActions.LoginAsRelationSuccess),
                tap((link) => window.open(link.link, '_blank')),
            ),
        { dispatch: false },
    );

    loginAsRelationErrorToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActions.LoginAsRelationError),
                tap(() =>
                    this.translate
                        .get('relation.notification.error')
                        .subscribe((translatedMessage: string) => this.messageService.error(translatedMessage)),
                ),
            ),
        { dispatch: false },
    );

    /**
     * Delete effects
     */
    deleteRelation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.Delete),
            concatLatestFrom(() => [this.store.select(Relation.selectRelationId)]),
            exhaustMap(([, action]) =>
                this.relationService.delete(action).pipe(
                    map(() => RelationActions.DeleteSuccess()),
                    catchError((response: { error: ErrorResponse }) =>
                        of(RelationActions.DeleteError({ response: response.error })),
                    ),
                ),
            ),
        ),
    );

    deleteRelationSuccessReload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RelationActions.DeleteSuccess),
            map(() => RelationActions.GetOverview()),
        ),
    );

    deleteRelationSuccessToast$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RelationActions.DeleteSuccess),
                tap(() => {
                    this.router.navigate(['relaties']);
                    this.translate
                        .get('notification.delete-success')
                        .subscribe((translatedMessage: string) => this.messageService.success(translatedMessage));
                }),
            ),
        { dispatch: false },
    );
}
