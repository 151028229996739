import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as CountryOverview from './country-overview.reducer'
import * as CountryDetailPage from './country-detail.reducer'

export const countryFeatureKey = 'country';

export interface CountryState {
    countryOverview: CountryOverview.State,
    countryDetail: CountryDetailPage.State,
}

export interface State {
    country: CountryState
}

const map: ActionReducerMap<CountryState, Action> = {
    countryOverview: CountryOverview.reducer,
    countryDetail: CountryDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: CountryState, action: Action) => combineReducers(map)(state, action);

const selectCountryState = createFeatureSelector<CountryState>(countryFeatureKey);

export const selectState = createSelector(selectCountryState, state => state)
export const selectCountryOverviewState = createSelector(selectState, state => state.countryOverview)

export const selectCountryOverviewLoading = createSelector(selectCountryOverviewState, CountryOverview.getLoading)
export const selectCountryOverviewList = createSelector(selectCountryOverviewState, CountryOverview.getList)
export const selectCountryOverviewPagination = createSelector(selectCountryOverviewState, CountryOverview.getPagination)
export const selectCountryOverviewSorting = createSelector(selectCountryOverviewState, CountryOverview.getSorting)

export const selectCountryDetailState = createSelector(selectState, state => state.countryDetail);

export const selectDetailLoading = createSelector(selectCountryDetailState, CountryDetailPage.getLoading);
export const selectDetailCountry = createSelector(selectCountryDetailState, CountryDetailPage.getCountry);
export const selectDetailErrors = createSelector(selectCountryDetailState, CountryDetailPage.getErrors);