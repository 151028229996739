<div class="page-header">
	<h1 class="page-header__title">{{ 'users.title' | translate }}</h1>
	<div class="page-header__actions">
		<a
			claimDirective
			[claims]="[
				{
					claimType: ClaimTypes.BackOfficeUser,
					claimValues: [ClaimValues.Create]
				}
			]"
			routerLink="/gebruikers/toevoegen"
			nz-button
			nzType="primary">
			{{ 'common.add' | translate }}
			<i class="fa-regular fa-plus"></i>
		</a>
	</div>
</div>

<nz-select
	nzMode="multiple"
	nzShowSearch
	nzAllowClear
	*ngIf="selectedUserTypes"
	[(ngModel)]="selectedUserTypes"
	(ngModelChange)="onUserTypesChange($event)"
	nzPlaceHolder="{{ 'users.fields.type' | translate }}">
	<nz-option
		*ngFor="let userType of UserType | enumKeyValue"
		[nzLabel]="'users.enums.userType.' + userType.key | translate"
		[nzValue]="userType.value">
	</nz-option>
</nz-select>

<app-user-overview-table
	[data]="(tableData$ | async)!"
	(delete)="onDeleteConfirm($event)"
	(onChangeActive)="onChangeActive($event)"
	(onQueryParamsChange)="onQueryParamsChange($event)"
	[currentUser]="currentUser$ | async" />
