<form nz-form [formGroup]="formGroup">
    <nz-card>
        <h4>
            {{
            (!!formGroup.get("id")?.value
            ? "email-templates.detail.edit-title"
            : "email-templates.detail.add-title"
            ) | translate
            }}
        </h4>
        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{
                "email-templates.fields.relationTypeId" | translate
                }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="relationTypeIdValidationTemplate">
                <nz-select nzPlaceHolder="{{ 'placeholder.all-options' | translate }}"
                    [formControl]="formGroup.controls.relationTypeId">
                    <nz-option *ngFor="let relationType of relationTypes" [nzLabel]="relationType.value"
                        [nzValue]="relationType.key"></nz-option>
                </nz-select>
                <ng-template #relationTypeIdValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'relationTypeId'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "email-templates.fields.emailTemplateType" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="emailTemplateTypeValidationTemplate">
                <nz-select nzPlaceHolder="{{ 'email-templates.fields.emailTemplateType' | translate }}" formControlName="emailTemplateType">
                    <nz-option *ngFor="let emailTemplateType of EmailTemplateType | enumKeyValue" [nzLabel]="'email-templates.enums.emailTemplateType.' + emailTemplateType.key| translate" [nzValue]="emailTemplateType.value"></nz-option>
                </nz-select>
                <ng-template #emailTemplateTypeValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'emailTemplateType'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "email-templates.fields.templateName" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="templateNameValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.templateName" />

                <ng-template #templateNameValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'templateName'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "email-templates.fields.priority" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="priorityValidationTemplate">
                <nz-select nzPlaceHolder="{{ 'email-templates.fields.priority' | translate }}" formControlName="priority">
                    <nz-option *ngFor="let emailPriority of EmialPriority | enumKeyValue" [nzLabel]="'email-templates.enums.emailPriority.' + emailPriority.key| translate" [nzValue]="emailPriority.value"></nz-option>
                </nz-select>
                <ng-template #priorityValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'emailPriority'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24">{{ "email-templates.fields.showHeaderImage" | translate }}</nz-form-label>
            <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
            <label nz-checkbox [formControl]="formGroup.controls.showHeaderImage"></label>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "email-templates.fields.description" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="descriptionValidationTemplate">
                <textarea nz-input [formControl]="formGroup.controls.description" rows="4"></textarea>

                <ng-template #descriptionValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'description'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <h5>{{ "email-templates.detail.message-settings-title" | translate }}</h5>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ 'email-templates.fields.senderEmail' | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="6" [nzErrorTip]="senderEmailValidationTemplate">
                <nz-input-group [nzAddOnAfter]="addOnEmailSuffices">
                    <input type="text" nz-input [formControl]="formGroup.controls.senderEmail" />
                </nz-input-group>
                <ng-template #senderEmailValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'senderEmail'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>

            <ng-template #addOnEmailSuffices>
                <nz-select [formControl]="formGroup.controls.senderEmailDomain">
                    <nz-option nzValue="ivsw.nl" nzLabel="&#64;ivsw.nl"></nz-option>
                    <nz-option nzValue="ivsw-company.nl" nzLabel="&#64;ivsw-company.nl"></nz-option>
                </nz-select>
            </ng-template>

        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{ "email-templates.fields.subject" | translate }}</nz-form-label>
            <nz-form-control [nzSpan]="24" [nzErrorTip]="subjectValidationTemplate">
                <input nz-input [formControl]="formGroup.controls.subject" />

                <ng-template #subjectValidationTemplate let-control>
                    <app-input-validation [control]="control" [field]="'subject'"
                        [errors]="errors"></app-input-validation>
                </ng-template>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>{{
                "email-templates.fields.message" | translate
            }}</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <app-input-validation
                [control]="formGroup.controls.message"
                [field]="'message'"
                [errors]="errors"
                >
                </app-input-validation>

                <app-editor
                *ngIf="readonly !== undefined"
                [control]="formGroup.controls.message"
                [isDraggable]="false"
                [isReadOnly]="readonly"
                >
                </app-editor>
            </nz-form-control>
        </nz-form-item>

        <app-asset-overview *ngIf="readonly !== undefined" ngDefaultControl [formArray]="formGroup.controls.assets" [errors]="errors" [fieldName]="'emailAssets'" [isReadOnly]="readonly">
        </app-asset-overview>

        <div class="button-container">
            <button *ngIf="!readonly" nz-button nzType="primary" (click)="submit()">
                {{ "common.save" | translate }}
            </button>
        </div>
    </nz-card>
</form>