import { ErrorResponse } from "@app/core/models/error-response.model";
import { RedirectCreateModel, RedirectTableItemModel, RedirectUpdateModel } from "@app/modules/redirects/models";
import { TableDataModel } from "@app/shared/models";
import { TableSortingModel } from "@app/shared/models/table/table-sorting.model";
import { createAction, props } from "@ngrx/store";


export const GetOverview = createAction("[Redirect] GetOverview");
export const GetOverviewError = createAction("[Redirect] GetOverviewError", props<{ response: ErrorResponse }>());
export const GetOverviewSuccess = createAction("[Redirect] GetOverviewSuccess", props<TableDataModel<RedirectTableItemModel>>());

export const FilterOverview = createAction("[Redirect] FilterOverview", props<{ sorting?: TableSortingModel, pagination: { page: number, pageSize: number } }>());

export const Clear = createAction("[Redirect] Clear");

export const Get = createAction("[Redirect] Get", props<{ id: number }>());
export const GetError = createAction("[Redirect] GetError", props<{ response: ErrorResponse }>());
export const GetSuccess = createAction("[Redirect] GetSuccess", props<RedirectUpdateModel>());

export const Update = createAction("[Redirect] Update", props<RedirectUpdateModel>());
export const UpdateError = createAction("[Redirect] UpdateError", props<{ response: ErrorResponse }>());
export const UpdateSuccess = createAction("[Redirect] UpdateSuccess", props<RedirectUpdateModel>());

export const Create = createAction("[Redirect] Create", props<RedirectCreateModel>());
export const CreateError = createAction("[Redirect] CreateError", props<{ response: ErrorResponse }>());
export const CreateSuccess = createAction("[Redirect] CreateSuccess", props<RedirectUpdateModel>());

export const Delete = createAction("[Redirect] Delete", props<{ id: number }>());
export const DeleteError = createAction("[Redirect] DeleteError", props<{ response: ErrorResponse }>());
export const DeleteSuccess = createAction("[Redirect] DeleteSuccess");

export const SlugExists = createAction("[Redirect] SlugExists", props<RedirectCreateModel | RedirectUpdateModel>())
export const SlugExistsError = createAction("[Redirect] SlugExistsError", props<{ response: ErrorResponse }>());
export const SlugExistsSuccess = createAction("[Redirect] SlugExistsSuccess", props<{ response: boolean }>());
export const ResetSlugExists = createAction("[Redirect] ResetSlugExists");