import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorResponse } from "@app/core/models/error-response.model";
import * as FAQCategoryActions from "@app/modules/faq-categories/actions/faq-category-api.actions"
import * as FAQCategory from '@app/modules/faq-categories/reducers'
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { map, exhaustMap, catchError, of, tap } from "rxjs";
import { FAQCategoryService } from "@app/modules/faq-categories/services/faq-category.service";
import { BuildErrorString } from "@app/shared/utilities/validation.utilities";

@Injectable()
export class FAQCategoryEffects {

    constructor(
        private actions$: Actions,
        private faqCategoryService: FAQCategoryService,
        private messageService: NzMessageService,
        private translate: TranslateService,
        private router: Router,
        private store: Store<FAQCategory.State>
    ) {
    }

    /**
     * Get effects
     */
    loadFaqCategories$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.GetOverview),
        concatLatestFrom(() => [this.store.select(FAQCategory.selectFaqCategoryOverviewPagination), this.store.select(FAQCategory.selectFaqCategoryOverviewSorting)]),
        exhaustMap(([, pagination, sorting]) => this.faqCategoryService.getOverview(sorting, pagination)
            .pipe(
                map(response => FAQCategoryActions.GetOverviewSuccess(response)),
                catchError((response: { error: ErrorResponse }) => of(FAQCategoryActions.GetOverviewError({ response: response.error })))
            )
        )
    ));

    loadFaqCategoriesFilterChange$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.FilterOverview),
        map(() => FAQCategoryActions.GetOverview())
    ));

    loadFaqCategory$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.Get),
        exhaustMap((action) => this.faqCategoryService.get(action.id)
            .pipe(
                map(response => FAQCategoryActions.GetSuccess(response)),
                catchError((response: { error: ErrorResponse }) => of(FAQCategoryActions.GetError({ response: response.error })))
            )
        )
    ));

    /**
     * Create effects
     */
    createFaqCategory$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.Create),
        exhaustMap((action) => this.faqCategoryService.create(action)
            .pipe(
                map((faqCategory) => FAQCategoryActions.CreateSuccess(faqCategory)),
                catchError((response: { error: ErrorResponse }) => of(FAQCategoryActions.CreateError({ response: response.error })))
            )
        )
    ));

    createFaqCategorySuccessToast$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.CreateSuccess),
        tap((faqCategory) => this.translate.get('notification.add', { item: faqCategory.category }).subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)))
    ), { dispatch: false });

    /**
     * Update effects
     */
    updateFaqCategory$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.Update),
        exhaustMap((action) => this.faqCategoryService.update(action)
            .pipe(
                map((faqCategory) => FAQCategoryActions.UpdateSuccess(faqCategory)),
                catchError((response: { error: ErrorResponse }) => of(FAQCategoryActions.UpdateError({ response: response.error })))
            )
        )
    ));

    updateFaqCategorySuccessToast$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.UpdateSuccess),
        tap((faqCategory) => this.translate.get('notification.save', { item: faqCategory.category }).subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)))
    ), { dispatch: false });

    /**
     * Create update redirects & store clear
     */
    createUpdateFaqCategorySuccessRedirect$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.CreateSuccess, FAQCategoryActions.UpdateSuccess),
        tap(() => this.router.navigate(['/faq-categorieen/overzicht']))
    ), { dispatch: false });

    /**
     * Delete effects
     */
    deleteFaqCategory$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.Delete),
        exhaustMap((action) => this.faqCategoryService.delete(action.id)
            .pipe(
                map(() => FAQCategoryActions.DeleteSuccess()),
                catchError((response: { error: ErrorResponse }) => of(FAQCategoryActions.DeleteError({ response: response.error })))
            )
        )
    ));

    deleteFaqCategorySuccessReload$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.DeleteSuccess),
        map(() => FAQCategoryActions.GetOverview())
    ));

    deleteFaqCategorySuccessToast$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.DeleteSuccess),
        tap(() => this.translate.get('notification.delete-success').subscribe((translatedMessage: string) => this.messageService.success(translatedMessage)))
    ), { dispatch: false });

    /**
     * Change sequence effects
     */
    changeSequenceFaqCategory$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.ChangeSequence),
        exhaustMap((action) => this.faqCategoryService.changeSequence(action)
            .pipe(
                map(() => FAQCategoryActions.ChangeSequenceSuccess()),
                catchError((response: { error: ErrorResponse }) => of(FAQCategoryActions.ChangeSequenceError({ response: response.error })))
            )
        )
    ));

    changeSequenceSuccessReload$ = createEffect(() => this.actions$.pipe(
        ofType(FAQCategoryActions.ChangeSequenceSuccess),
        map(() => FAQCategoryActions.GetOverview())
    ));

    faqCategoryErrorToast$ = createEffect(
		() =>
		  this.actions$.pipe(
			ofType(FAQCategoryActions.GetOverviewError, FAQCategoryActions.DeleteError, FAQCategoryActions.ChangeSequenceError),
			tap((error) => this.messageService.error(BuildErrorString(error.response, this.translate)))
		  ),
		{ dispatch: false }
	  );

}