<nz-select
    [(ngModel)]="selectedValue"
    (nzScrollToBottom)="loadMore()"
    (nzOnSearch)="inputSearch($event)"
    (ngModelChange)="inputChange($event)"
    nzAllowClear
    nzPlaceHolder="{{'activity.fields.choose-relation' | translate }}"
    nzShowSearch
    nzSize="large"
    class="w-100">
    <nz-option-group nzLabel="{{ 'activity.fields.waitlist' | translate }}" *ngIf="waitlistList.length > 0">
        <nz-option 
        *ngFor="let entry of waitlistList" [nzValue]="entry.id" nzCustomContent [nzLabel]="entry.name">
            <nz-tag>{{ entry.type }}</nz-tag>
            {{ entry.name }}
        </nz-option>
    </nz-option-group>

    <nz-option-group
        nzLabel="{{ 'activity.fields.waitlist-comparable' | translate }}"
        *ngIf="waitlistComparableList.length > 0">
        <nz-option
            *ngFor="let entry of waitlistComparableList"
            [nzValue]="entry.id"
            nzCustomContent
            [nzLabel]="entry.name">
            <nz-tag>{{ entry.type }}</nz-tag>
            {{ entry.name }}
        </nz-option>
    </nz-option-group>

    <nz-option-group nzLabel="{{ 'activity.fields.relations' | translate }}" *ngIf="relationsList.length > 0">
        <nz-option *ngFor="let entry of relationsList" [nzValue]="entry.key" nzCustomContent [nzLabel]="entry.value">
            {{ entry.value }}
        </nz-option>
    </nz-option-group>
</nz-select>
