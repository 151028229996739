import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RedirectTableItemModel } from '@app/modules/redirects/models';
import { ClaimTypes, ClaimValues } from '@app/shared/models/common/constants';
import { TableStateModel } from '@app/shared/models/table/table-state.model';

@Component({
	selector: 'app-redirect-overview-table',
	templateUrl: './redirect-overview-table.component.html',
})
export class RedirectOverviewTableComponent {
	@Input({ required: true }) data!: TableStateModel<RedirectTableItemModel>;

	@Output() delete = new EventEmitter<number>();
	@Output() onQueryParamsChange = new EventEmitter<any>();

	ClaimTypes = ClaimTypes;
	ClaimValues = ClaimValues;
}