<div class="page-header">
  <a
    [routerLink]="['/blogs', blogId, 'blog-reacties', 'overzicht']"
    class="page-header__back"
  >
    <i class="fa-regular fa-chevron-left"></i>
  </a>
  <h1 class="page-header__title">{{ "blog-reactions.title" | translate }}</h1>
  <div class="page-header__actions"></div>
  <div class="page-header__actions" *ngIf="this.addSubReaction">
    <a
      claimDirective
      [claims]="[
        {
          claimType: ClaimTypes.BlogReactions,
          claimValues: [ClaimValues.Create]
        }
      ]"
      [routerLink]="[
        '/blogs',
        blogId,
        'blog-reacties',
        'toevoegen',
        reactionId
      ]"
      nz-button
      nzType="primary"
    >
      {{ "blog-reactions.detail.add" | translate }}
      <i class="fa-regular fa-plus"></i>
    </a>
  </div>
</div>

<app-blog-reaction-detail-form
  [blogReaction]="blogReaction$ | async"
  (submitted)="submit($event)"
  [errors]="errors$ | async"
  [loading]="loading$ | async"
  [user]="user$ | async"
  [blogId]="blogId!"
  [parentReactionId]="reactionId"
/>
