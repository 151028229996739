export enum BillingInformationType {
    MyCompany = 1,
    Employer = 2,
    Therapist = 3,
    Other = 4
}
export const BillingInformationTypeOptions: Record<number, string> = {
    [BillingInformationType.MyCompany]: "relation.enums.billing-information-type.my-company",
    [BillingInformationType.Employer]: "relation.enums.billing-information-type.employer",
    [BillingInformationType.Therapist]: "relation.enums.billing-information-type.therapist",
    [BillingInformationType.Other]: "relation.enums.billing-information-type.other",
};
