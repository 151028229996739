import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as DiscountCodeOverview from './discount-code-overview.reducer'
import * as DiscountCodeDetailPage from './discount-code-detail-page.reducer'

export const discountCodeFeatureKey = 'discountCode';

export interface DiscountCodeState {
    discountCodeOverview: DiscountCodeOverview.State,
    discountCodeDetail: DiscountCodeDetailPage.State,
}

export interface State {
    discountCode: DiscountCodeState
}

const map: ActionReducerMap<DiscountCodeState, Action> = {
    discountCodeOverview: DiscountCodeOverview.reducer,
    discountCodeDetail: DiscountCodeDetailPage.reducer,
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: DiscountCodeState, action: Action) => combineReducers(map)(state, action);

const selectDiscountCodeState = createFeatureSelector<DiscountCodeState>(discountCodeFeatureKey);

export const selectState = createSelector(selectDiscountCodeState, state => state)
export const selectDiscountCodeOverviewState = createSelector(selectState, state => state.discountCodeOverview)

export const selectDiscountCodeOverviewLoading = createSelector(selectDiscountCodeOverviewState, DiscountCodeOverview.getLoading)
export const selectDiscountCodeOverviewList = createSelector(selectDiscountCodeOverviewState, DiscountCodeOverview.getList)
export const selectDiscountCodeOverviewPagination = createSelector(selectDiscountCodeOverviewState, DiscountCodeOverview.getPagination)
export const selectDiscountCodeOverviewSorting = createSelector(selectDiscountCodeOverviewState, DiscountCodeOverview.getSorting)

export const selectDiscountCodeDetailState = createSelector(selectState, state => state.discountCodeDetail);

export const selectDetailLoading = createSelector(selectDiscountCodeDetailState, DiscountCodeDetailPage.getLoading);
export const selectDetailDiscountCode = createSelector(selectDiscountCodeDetailState, DiscountCodeDetailPage.getDiscountCode);
export const selectDetailErrors = createSelector(selectDiscountCodeDetailState, DiscountCodeDetailPage.getErrors);
export const selectDetailActivities = createSelector(selectDiscountCodeDetailState, DiscountCodeDetailPage.getActivities);
export const selectDetailRelations = createSelector(selectDiscountCodeDetailState, DiscountCodeDetailPage.getRelations);
export const selectDetailActivityTypes = createSelector(selectDiscountCodeDetailState, DiscountCodeDetailPage.getActivityTypes);