import { Action, ActionReducerMap, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store'
import * as EmailTemplateOverview from './email-template-overview.reducer'
import * as EmailTemplateDetail from './email-template-detail.reducer'

export const emailTemplateFeatureKey = 'emailTemplate';

export interface EmailTemplateState {
    emailTemplateOverview: EmailTemplateOverview.State,
    emailTemplateDetail: EmailTemplateDetail.State
}

export interface State {
    emailTemplate: EmailTemplateState
}

const map: ActionReducerMap<EmailTemplateState, Action> = {
    emailTemplateOverview: EmailTemplateOverview.reducer,
    emailTemplateDetail: EmailTemplateDetail.reducer
}

/** Provide reducer in AoT-compilation happy way */
export const reducers = (state: EmailTemplateState, action: Action) => combineReducers(map)(state, action);

const selectEmailTemplateState = createFeatureSelector<EmailTemplateState>(emailTemplateFeatureKey);

export const selectState = createSelector(selectEmailTemplateState, state => state)

export const selectEmailTemplateOverviewState = createSelector(selectState, state => state.emailTemplateOverview)
export const selectEmailTemplateOverviewLoading = createSelector(selectEmailTemplateOverviewState, EmailTemplateOverview.getLoading)
export const selectEmailTemplateOverviewList = createSelector(selectEmailTemplateOverviewState, EmailTemplateOverview.getList)
export const selectEmailTemplateOverviewPagination = createSelector(selectEmailTemplateOverviewState, EmailTemplateOverview.getPagination)
export const selectEmailTemplateOverviewSorting = createSelector(selectEmailTemplateOverviewState, EmailTemplateOverview.getSorting)
export const selectEmailTemplateOverviewEmailTemplateType = createSelector(selectEmailTemplateOverviewState, EmailTemplateOverview.getEmailTemplateType)

export const selectEmailTemplateDetailState = createSelector(selectState, state => state.emailTemplateDetail);
export const selectDetailLoading = createSelector(selectEmailTemplateDetailState, EmailTemplateDetail.getLoading);
export const selectDetailEmailTemplate = createSelector(selectEmailTemplateDetailState, EmailTemplateDetail.getEmailTemplate);
export const selectDetailDefaultEmailTemplate = createSelector(selectEmailTemplateDetailState, EmailTemplateDetail.getDefaultEmailTemplate);
export const selectDetailErrors = createSelector(selectEmailTemplateDetailState, EmailTemplateDetail.getErrors);
export const selectDetailRelationTypes = createSelector(selectEmailTemplateDetailState, EmailTemplateDetail.getRelationTypes);
export const selectDetailEmailTemplateType = createSelector(selectEmailTemplateDetailState, EmailTemplateDetail.getEmailTemplateType);